import { TextButton } from '@/components/atoms/Buttons/TextButton';
import { Path } from '@/constants/Router/path';
import { BREAKPOINT, theme } from '@/theme/theme';
import { Box, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import { BalanceInquiryHeading } from './BalanceInquiryHeading';
import { FundingInfoBoard } from './FundingInfoBoard';

const Wrapper = styled(Box)(() => {
  const { breakpoints } = theme;
  return {
    margin: '0 auto',
    maxWidth: '100%',
    [breakpoints.up(BREAKPOINT)]: {
      padding: '0 40px',
    },
    [breakpoints.up('lg')]: {
      padding: '0 130px',
    },
  };
});

const HeadingWrapper = styled(Box)(() => {
  const { breakpoints } = theme;

  return {
    paddingTop: 40,
    margin: '0 auto',

    [breakpoints.down('sm')]: {
      padding: '24px 16px 0',
    },
  };
});

const LinksWrapper = styled(Box)(() => {
  const { breakpoints } = theme;

  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: 40,

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      paddingBottom: 24,
    },
  };
});

const LinkWrapper = styled(Box)(() => {
  const { breakpoints } = theme;
  return {
    marginTop: 17,
    marginRight: 20,

    [breakpoints.down('sm')]: {
      marginRight: 0,
    },
  };
});

export const CurrentFundingStatus = ({
  monthlyIncentiveRatio,
  unitAmount,
  monthlyUnit,
  bonusUnit,
  isListed,
}: {
  monthlyIncentiveRatio: number;
  unitAmount: number;
  monthlyUnit: number;
  bonusUnit: number;
  isListed: boolean;
}) => {
  const navigate = useNavigate();

  const formatedToday = format(new Date(), 'yyyy/MM/dd (E) HH:mm', {
    locale: ja,
  });

  return (
    <Wrapper>
      <HeadingWrapper>
        <BalanceInquiryHeading
          title="現在の積立状況"
          subtitle={`${formatedToday} 現在`}
        />
      </HeadingWrapper>
      <FundingInfoBoard
        monthlyIncentiveRatio={monthlyIncentiveRatio}
        unitAmount={unitAmount}
        monthlyUnit={monthlyUnit}
        bonusUnit={bonusUnit}
      />
      <LinksWrapper>
        <LinkWrapper>
          <TextButton icon="right" onClick={() => navigate(Path.PAY)}>
            積立口数を変更する
          </TextButton>
        </LinkWrapper>
        {isListed && (
          <LinkWrapper>
            <TextButton icon="right" onClick={() => navigate(Path.PART)}>
              一部を引き出す
            </TextButton>
          </LinkWrapper>
        )}
      </LinksWrapper>
    </Wrapper>
  );
};
