/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  PrimaryTableBodyTdMulti,
  PrimaryTableBodyTdSub,
} from '@/components/atoms/Table/PrimaryTableBodyTdMulti';
import { PrimaryTableBodyTdSingle } from '@/components/atoms/Table/PrimaryTableBodyTdSingle';
import { TextWithFlowIcon } from '@/components/molecules/Flow/TextWithFlowIcon';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { PrimaryTable } from '@/components/molecules/Table/PrimaryTable';
import { UserInfo } from '@/components/molecules/Table/UserInfo';
import { HomeHeader } from '@/components/organism/HomeHeader';
import { styled, Typography } from '@mui/material';
import { FC } from 'react';
import { WarmingDeadLine } from '@/components/organism/Apply/WarmingDeadLine';
import { BREAKPOINT } from '@/theme/theme';
import { PrimaryTableBodyTdSingleWithSpNotRed } from '@/components/atoms/Table/PrimaryTableBodyTdSingleWithSpNotRed';
import { PrimaryAndSecondaryButtons } from '@/components/molecules/Buttons/PrimaryAndSecondaryButtons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Eventsmemberdeadlines } from '@/types/api/eventsmemberdeadlines';
import { Association } from '@/types/api/association';
import { AssociationContribution } from '@/types/api/associationContribution';
import { UserContribution } from '@/types/api/userContribution';
import { User } from '@/types/api/user';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { appClient } from '@/services';
import { Path } from '@/constants/Router/path';
import { useSnackbarMessage } from '@/hooks';
import { numberFormat } from '@/utils/numberFormat';
import { Associations } from '@/types/api/associations';
import { ErrorResponse } from '@/types/api/error';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const Wrraper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    marginBottom: '80px',
    padding: '14px 0',
    [breakpoints.up(BREAKPOINT)]: {
      padding: '14px 40px',
    },
    [breakpoints.up('lg')]: {
      padding: '14px 130px',
    },
  };
});

const ContentWrraper = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
});

const Header = styled('div')({ position: 'relative', width: '100%' });

type LocationState = {
  user: User;
  association: Association;
  associationContribution: AssociationContribution;
  userContribution: UserContribution;
  eventsmemberdeadlines: Eventsmemberdeadlines;
  value: number;
  editUnitAmount: number;
  monthlyIncentive: number;
  isBonus: boolean;
  bonusMultiplierDesignationType:
    | 'UNIT_NUMBER'
    | 'MAGNIFICATION'
    | 'FIXED_MAGNIFICATION';
  isBonusToggle: boolean;
  bonusUnitAmount: number;
  bonusContributionAmount: number;
  bonusIncentive: number;
  bonusRate: number;
};

// パンクズ
const links = [
  {
    path: 'TOP',
    to: Path.HOME,
  },
  {
    path: '口数を変更する',
    to: Path.PAY,
  },
];

export const PayConfirm: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { showMessage } = useSnackbarMessage();
  const state = location.state as LocationState;
  useHelmetHandler({
    title: '口数変更(給与) 入力内容確認',
  });

  const goNext = async () => {
    try {
      const body = {
        applicationDate: new Date().toString(),
        applicationType: 'MONTHLY_UNIT' as Associations['applicationType'],
        applicationMonthlyUnit: {
          monthlyUnit: Number(state.value),
          isBonusUnit: state.isBonus,
          bonusContributionUnit: state.isBonus
            ? Number(state.bonusUnitAmount)
            : 0,
          bonusRate: state.isBonus ? Number(state.bonusRate) : 0,
        },
      };

      const response = await appClient.applications.createApplication(body);

      if (response) {
        navigate(Path.PAY_COMPLETE, {
          state: {
            applicationType: response.applicationType,
            applicationId: response.applicationId,
            applicationDate: response.applicationDate,
          },
        });
      }
    } catch (e) {
      showMessage({
        open: true,
        messageType: 'error',
        messages: [(e as ErrorResponse).data.message],
      });
    }
  };

  const navigateToPrint = () => {
    navigate(`/print`, {
      state: {
        ...state,
        title: '口数を変更する',
        contentTitle: '新たな口数設定',
        type: 'MONTHLY',
        contributionAmount: state.bonusContributionAmount,
        bonusContributionAmount: Number(state.bonusUnitAmount),
      },
    });
  };

  const renderBonusUnitOrRate = () => {
    switch (state.associationContribution.bonusMultiplierDesignationType) {
      case 'UNIT_NUMBER':
        return `${numberFormat(state.bonusUnitAmount)}口`;
      case 'MAGNIFICATION':
        return `${state.bonusRate}倍`;
      case 'FIXED_MAGNIFICATION':
        return `${state.associationContribution.bonusMultiplierDetails[0]}倍`;
      default:
        return '';
    }
  };

  return (
    <Wrraper>
      <Header>
        <HomeHeader
          links={links}
          title="口数を変更する"
          subTitle="入力内容確認"
          sx={{ width: '100%' }}
          printOnClick={() => navigateToPrint()}
        />
      </Header>
      <TextWithFlowIcon flowOn={1} widthFull />
      <ContentWrraper>
        <WarmingDeadLine
          eventsmemberdeadlines={state.eventsmemberdeadlines}
          target="MONTHLY_UNIT"
        />
        <UserInfo
          mochikabukaiCode={state.user.associationCd}
          mochikabukaiName={state.association.associationName}
          nameKanji={state.user.nameKanji}
          nameKana={state.user.nameKana}
          zipcode={state.user.zipcode}
          address1={state.user.address1}
          address2={state.user.address2}
          address3={state.user.address3}
          tel={state.user.tel}
          employeeCd={state.user.employeeCd}
          editable
        />
        <BasicTable
          boxSx={{ width: '100%' }}
          title="現在の口数設定"
          type="contribution"
          unitAmount={state.associationContribution.unitAmount}
          monthlyUnit={state.userContribution.monthlyUnit}
          bonusUnit={state.userContribution.bonusUnit}
          investmentDigit={state.association.investmentDigit}
        />
        <PrimaryTable title="新たな口数設定">
          {state.associationContribution.unitAmount && (
            <PrimaryTableBodyTdSingleWithSpNotRed
              title="1口あたり金額"
              value={`${numberFormat(
                state.associationContribution.unitAmount
              )}円`}
              isRegular
            />
          )}
          <PrimaryTableBodyTdSingleWithSpNotRed
            title="奨励金率"
            value={`${state.associationContribution.monthlyIncentiveRatio}%`}
            isRegular
          />
          <PrimaryTableBodyTdMulti title="給与" subTitle="※毎月の拠出" row={3}>
            <PrimaryTableBodyTdSub title="口数">
              <Typography variant="body-main/regular">
                {numberFormat(state.value)}口
              </Typography>
            </PrimaryTableBodyTdSub>
          </PrimaryTableBodyTdMulti>
          <PrimaryTableBodyTdSingle>
            <PrimaryTableBodyTdSub title="拠出金額">
              <Typography variant="body-main/regular">
                {numberFormat(state.editUnitAmount)}円
              </Typography>
            </PrimaryTableBodyTdSub>
          </PrimaryTableBodyTdSingle>
          <PrimaryTableBodyTdSingle>
            <PrimaryTableBodyTdSub title="奨励金額">
              <Typography variant="body-main/regular">
                {state.associationContribution.monthlyIncentiveRatio === 0 &&
                state.associationContribution.monthlyIncentiveFixedAmount !== 0
                  ? numberFormat(
                      state.associationContribution.monthlyIncentiveFixedAmount
                    )
                  : numberFormat(
                      state.monthlyIncentive,
                      {
                        maximumFractionDigits: 0,
                      },
                      true
                    )}
                円
              </Typography>
            </PrimaryTableBodyTdSub>
          </PrimaryTableBodyTdSingle>
          {state.associationContribution.bonusReserveClassification !==
            'NONE' && (
            <>
              <PrimaryTableBodyTdMulti title="賞与" row={4}>
                <PrimaryTableBodyTdSub title="拠出">
                  <Typography variant="body-main/regular">
                    {state.isBonus ? 'する' : 'しない'}
                  </Typography>
                </PrimaryTableBodyTdSub>
              </PrimaryTableBodyTdMulti>
              {state.isBonus && (
                <>
                  <PrimaryTableBodyTdSingle>
                    <PrimaryTableBodyTdSub
                      title={
                        state.bonusMultiplierDesignationType ===
                        'FIXED_MAGNIFICATION'
                          ? '倍率'
                          : '口数'
                      }
                    >
                      <Typography variant="body-main/regular">
                        {renderBonusUnitOrRate()}
                      </Typography>
                    </PrimaryTableBodyTdSub>
                  </PrimaryTableBodyTdSingle>
                  <PrimaryTableBodyTdSingle>
                    <PrimaryTableBodyTdSub title="拠出金額">
                      <Typography variant="body-main/regular">
                        {numberFormat(state.bonusContributionAmount)}円
                      </Typography>
                    </PrimaryTableBodyTdSub>
                  </PrimaryTableBodyTdSingle>
                  <PrimaryTableBodyTdSingle>
                    <PrimaryTableBodyTdSub title="奨励金額">
                      <Typography variant="body-main/regular">
                        {numberFormat(state.bonusIncentive)}円
                      </Typography>
                    </PrimaryTableBodyTdSub>
                  </PrimaryTableBodyTdSingle>
                </>
              )}
            </>
          )}
        </PrimaryTable>
        <PrimaryAndSecondaryButtons>
          <SecondaryButton
            sx={{
              display: 'flex',
              margin: 'auto',
              borderRadius: '28px',
              padding: '16px 24px',
            }}
            onClick={() =>
              navigate(Path.PAY, { state: { inputValue: state.value } })
            }
          >
            入力へ戻る
          </SecondaryButton>
          <PrimaryButton
            sx={{
              borderRadius: '28px',
              padding: '16px 24px',
            }}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={() => goNext()}
          >
            申請する
          </PrimaryButton>
        </PrimaryAndSecondaryButtons>
      </ContentWrraper>
    </Wrraper>
  );
};
