import { Box, Link, styled, Theme, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { ReactComponent as FAQIcon } from '@/assets/faq.svg';
import { question } from '@/constants/Apply/FAQ';
import { useNavigate } from 'react-router-dom';
import { TextButton } from '@/components/atoms/Buttons/TextButton';
import faqBanner from '@/assets/faqBanner.png';
import { BREAKPOINT } from '@/theme/theme';
import { AccordionBox } from './AccordionBox';

const Wrraper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    [breakpoints.down('sm')]: {
      display: 'block',
    },
  };
});

const Content = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    flexShrink: 2,
    marginRight: '32px',
    [breakpoints.down('sm')]: {
      marginRight: '0',
    },
  };
});

const Banner = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'none',
    position: 'fixed',
    top: '65px',
    right: '0',
    zIndex: '10',
    img: {
      width: '32px',
    },
    [breakpoints.down(BREAKPOINT)]: {
      display: 'block',
    },
  };
});

const Title = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '16px',
});

const FAQArea = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '352px',
    flexShrink: 1,
    borderLeft: '1px solid #EEEEEE',
    paddingLeft: '32px',
    boxSizing: 'border-box',
    [breakpoints.down('sm')]: {
      width: '100%',
      borderLeft: 'none',
      borderTop: '1px solid #EEEEEE',
      paddingLeft: '12px',
      paddingRight: '12px',
      paddingTop: '24px',
      marginTop: '24px',
    },
  };
});

interface Props {
  pageName: 'pay' | 'resume' | 'recess' | 'withdrawal' | 'part';
  children: ReactNode;
}

export const FAQ: FC<Props> = ({ pageName, children }) => {
  const navigate = useNavigate();
  return (
    <Wrraper>
      <Banner>
        <Link href="#FaqArea">
          <img src={faqBanner} alt="faqBanner" />
        </Link>
      </Banner>
      <Content>{children}</Content>
      <FAQArea id="FaqArea">
        <Title>
          <FAQIcon />
          <Typography variant="body-main/bold">よくあるご質問</Typography>
        </Title>
        {question[pageName].map((item) => (
          <AccordionBox question={item} />
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <TextButton
            icon="right"
            sx={{ color: 'secondary.main' }}
            wrapperSx={{
              path: { stroke: (theme: Theme) => theme.palette.secondary.main },
            }}
            onClick={() => navigate('/faq')}
          >
            よくあるご質問一覧へ
          </TextButton>
        </Box>
      </FAQArea>
    </Wrraper>
  );
};
