import { FC } from 'react';
import { Box, Typography, SvgIcon, Theme, styled } from '@mui/material';
import { ReactComponent as WarningIcon } from '@/assets/warming.svg';
import { ReactComponent as CautionIcon } from '@/assets/caution.svg';

interface SimulationErrorMessageWithIconProps {
  message: string;
  icon?: 'CautionIcon' | 'WarningIcon';
}

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  marginTop: 24,
});

const Message = styled(Typography)(({ theme }) => {
  const { typography, palette } = theme;

  return {
    ...typography['body-main/bold'],
    color: palette.states.error,
    marginLeft: 6,
  };
});

export const SimulationErrorMessageWithIcon: FC<
  SimulationErrorMessageWithIconProps
> = ({ message, icon = 'CautionIcon' }) => (
  <Wrapper>
    <SvgIcon sx={{ fill: (theme: Theme) => theme.palette.states.error }}>
      {icon === 'WarningIcon' ? <WarningIcon /> : <CautionIcon />}
    </SvgIcon>
    <Message>{message}</Message>
  </Wrapper>
);
