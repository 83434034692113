import { TextWithFlowIcon } from '@/components/molecules/Flow/TextWithFlowIcon';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { UserInfo } from '@/components/molecules/Table/UserInfo';
import { HomeHeader } from '@/components/organism/HomeHeader';
import { Box, styled, Theme, useMediaQuery } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FAQ } from '@/components/organism/Apply/Faq';
import { WarmingDeadLine } from '@/components/organism/Apply/WarmingDeadLine';
import { BREAKPOINT } from '@/theme/theme';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { PrimaryTable } from '@/components/molecules/Table/PrimaryTable';
import { PrimaryTableBodyTdMulti } from '@/components/atoms/Table/PrimaryTableBodyTdMulti';
import { SelectBox } from '@/components/atoms/Input/SelectBox';
import { reasonsForRecess } from '@/constants/choicesForSelectBox';
import { useAuthUser } from '@/hooks/useAuth';
import { appClient } from '@/services';
import { ConvertAssociation } from '@/services/convert/association';
import { ConvertAssociationContribution } from '@/services/convert/associationContribution';
import { ConvertEventsmemberdeadlines } from '@/services/convert/eventsmemberdeadlines';
import { ConvertUserContribution } from '@/services/convert/userContribution';
import { Association } from '@/types/api/association';
import { AssociationContribution } from '@/types/api/associationContribution';
import { Eventsmemberdeadlines } from '@/types/api/eventsmemberdeadlines';
import { UserContribution } from '@/types/api/userContribution';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { TextInput } from '@/components/atoms/Input/TextInput';
import { Path } from '@/constants/Router/path';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const Wrraper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    marginBottom: '80px',
    padding: '14px 0',
    [breakpoints.up(BREAKPOINT)]: {
      padding: '14px 40px',
    },
    [breakpoints.up('lg')]: {
      padding: '14px 130px',
    },
  };
});

const ContentWrraper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
});

// パンクズ
const links = [
  {
    path: 'TOP',
    to: Path.HOME,
  },
  {
    path: '休止する',
    to: Path.RECESS,
  },
];

// タイトルの説明文
const description = [
  '持株会を休止し、給与(毎月の拠出)と賞与の積立を休止します。',
];

export type Reason = 'LEAVE_ABSENCE' | 'FAMILY_MATTERS' | 'ECONOMIC' | 'OTHER';

type LocationState = {
  reason: Reason;
  otherReasons: string;
};

export const RecessIndex: FC = () => {
  const isPc = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(BREAKPOINT)
  );
  useHelmetHandler({
    title: '休会 入力画面',
  });

  const navigate = useNavigate();
  const [reason, setReason] = useState<Reason | ''>('');
  const [otherReasons, setOtherReasons] = useState<string>('');
  const [association, setAssociation] = useState<Association>();
  const [userContribution, setUserContribution] = useState<UserContribution>();
  const [associationContribution, setAssociationContribution] =
    useState<AssociationContribution>();
  const [eventsmemberdeadlines, setEventsmemberdeadlines] =
    useState<Eventsmemberdeadlines>();

  const location = useLocation();
  const state = location.state as LocationState;

  // 【API-A-0909】資産情報取得
  const { user } = useAuthUser();

  // 【API-A-1002】持株会（事務局）拠出情報取得
  const { data: findAssociationContribution } = useSWR(
    '/api/associations/{associationCd}/contributions',
    () => appClient.associations.findAssociationContribution()
  );

  // 【API-A-1001】持株会（事務局）情報
  const { data: findAssociationById } = useSWR(
    '/api/associations/{associationCd}',
    () => appClient.associations.findAssociationById()
  );

  // 【API-A-1001】持株会（事務局）情報
  const { data: findAssociationEventMemberDeadlines } = useSWR(
    '/api/associations/{associationCd}/eventsmemberdeadlines',
    () => appClient.associations.findAssociationEventMemberDeadlines()
  );

  // 【API-A-0905】拠出情報取得
  const { data: findUserContribution } = useSWR('/api/users/contribution', () =>
    appClient.users.findUserContribution()
  );

  // 【API-A-0909】資産情報取得
  const { data: userAsset } = useSWR('/api/users/assets', () =>
    appClient.users.findUserAsset()
  );

  useEffect(() => {
    setAssociation(ConvertAssociation(findAssociationById));
    setAssociationContribution(
      ConvertAssociationContribution(findAssociationContribution)
    );
    setEventsmemberdeadlines(
      ConvertEventsmemberdeadlines(
        findAssociationEventMemberDeadlines?.eventmemberdeadlines
      )
    );
    setUserContribution(ConvertUserContribution(findUserContribution));
  }, [
    findAssociationById,
    findAssociationContribution,
    findAssociationEventMemberDeadlines,
    findUserContribution,
  ]);

  useEffect(() => {
    if (state) {
      setReason(state.reason);
      setOtherReasons(state.otherReasons);
    }
  }, []);

  if (
    association &&
    associationContribution &&
    userContribution &&
    eventsmemberdeadlines &&
    userAsset
  ) {
    return (
      <Wrraper>
        <HomeHeader links={links} title="休止する" description={description} />
        <TextWithFlowIcon flowOn={0} widthFull />
        <FAQ pageName="recess">
          <ContentWrraper>
            <WarmingDeadLine
              eventsmemberdeadlines={eventsmemberdeadlines}
              target="RECESS"
            />
            <UserInfo
              mochikabukaiCode={user.associationCd ?? ''}
              mochikabukaiName={association.associationName ?? ''}
              nameKanji={user.nameKanji ?? ''}
              nameKana={user.nameKana ?? ''}
              zipcode={user.zipcode ?? ''}
              address1={user.address1 ?? ''}
              address2={user.address2 ?? ''}
              address3={user.address3 ?? ''}
              tel={user.tel ?? ''}
              employeeCd={user.employeeCd ?? ''}
              editable
            />
            <BasicTable
              boxSx={{ width: '100%' }}
              title="現在の積立状況"
              type="recess"
              stocksNumber={userAsset.stocksNumber}
              unitAmount={associationContribution.unitAmount}
              monthlyUnit={userContribution.monthlyUnit}
              bonusUnit={userContribution.bonusUnit}
              investmentDigit={association.investmentDigit}
            />
            <PrimaryTable title="休止理由">
              <PrimaryTableBodyTdMulti title="休止理由">
                <SelectBox
                  items={reasonsForRecess}
                  width={isPc ? '320px' : '255px'}
                  value={reason}
                  onChange={(event) => {
                    setReason(event.target.value as Reason);
                    if (event.target.value !== 'OTHER') {
                      setOtherReasons('');
                    }
                  }}
                />
                {reason === 'OTHER' && (
                  <Box sx={{ marginTop: '16px' }}>
                    <TextInput
                      name="otherReasons"
                      value={otherReasons}
                      onChange={(event) => setOtherReasons(event.target.value)}
                    />
                  </Box>
                )}
              </PrimaryTableBodyTdMulti>
            </PrimaryTable>
            <PrimaryButton
              sx={{
                padding: '16px 40px',
                borderRadius: '28px',
                alignSelf: 'center',
              }}
              onClick={() =>
                navigate(Path.RECESS_CONFIRM, {
                  state: {
                    user,
                    association,
                    associationContribution,
                    userContribution,
                    eventsmemberdeadlines,
                    stocksNumber: userAsset.stocksNumber,
                    reason,
                    otherReasons,
                  },
                })
              }
            >
              確認へ進む
            </PrimaryButton>
          </ContentWrraper>
        </FAQ>
      </Wrraper>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
