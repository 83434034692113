import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { Path } from '@/constants/Router/path';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  applicationType:
    | 'MONTHLY_UNIT'
    | 'BONUS_UNIT'
    | 'PART'
    | 'RECESS'
    | 'RESUME'
    | 'WITHDRAWAL'
    | 'ADMISSION'
    | 'USER';
}

export const ApplicationBtn: FC<Props> = ({ applicationType }) => {
  const navigate = useNavigate();

  switch (applicationType) {
    case 'MONTHLY_UNIT': {
      return (
        <PrimaryButton
          sx={{ height: '40px', width: '190px', borderRadius: '28px' }}
          onClick={() => navigate(Path.PAY)}
        >
          口数変更申請へ進む
        </PrimaryButton>
      );
    }
    case 'BONUS_UNIT': {
      return (
        <PrimaryButton
          sx={{ height: '40px', width: '190px', borderRadius: '28px' }}
          onClick={() => navigate(Path.BONUS)}
        >
          口数変更申請へ進む
        </PrimaryButton>
      );
    }
    case 'PART': {
      return (
        <PrimaryButton
          sx={{ height: '40px', width: '190px', borderRadius: '28px' }}
          onClick={() => navigate(Path.PART)}
        >
          一部引出申請へ進む
        </PrimaryButton>
      );
    }
    case 'RECESS': {
      return (
        <PrimaryButton
          sx={{ height: '40px', width: '190px', borderRadius: '28px' }}
          onClick={() => navigate(Path.RECESS)}
        >
          休会申請へ進む
        </PrimaryButton>
      );
    }
    case 'RESUME': {
      return (
        <PrimaryButton
          sx={{ height: '40px', width: '190px', borderRadius: '28px' }}
          onClick={() => navigate(Path.RESUME)}
        >
          再開申請へ進む
        </PrimaryButton>
      );
    }
    case 'WITHDRAWAL': {
      return (
        <PrimaryButton
          sx={{ height: '40px', width: '190px', borderRadius: '28px' }}
          onClick={() => navigate(Path.WITHDRAWAL)}
        >
          退会申請へ進む
        </PrimaryButton>
      );
    }
    default: {
      return null;
    }
  }
};
