/* eslint-disable  */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { RecoilRoot } from 'recoil';
import { SWRConfig } from 'swr';
import { App } from '@/App';
import { BrowserRouter } from 'react-router-dom';
import { AuthUserProvider } from '@/hooks/useAuth';
import { ReactHelmet } from './Helmet';
import { theme } from './theme/theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <React.Suspense fallback={<p>Loading...</p>}>
      <RecoilRoot>
        <ReactHelmet />
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <SWRConfig value={{ refreshInterval: 0 }}>
              <AuthUserProvider>
                <App />
              </AuthUserProvider>
            </SWRConfig>
          </BrowserRouter>
        </ThemeProvider>
      </RecoilRoot>
    </React.Suspense>
  </React.StrictMode>
);
