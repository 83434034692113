import { SxProps, Theme, Typography, styled, Button } from '@mui/material';
import { FC, useRef } from 'react';
import { ReactComponent as Print } from '@/assets/print.svg';

interface ButtonProps {
  // クリック時の処理
  onClick?: () => void;
  sx?: SxProps<Theme>;
}

const MuiButton = styled(Button)(({ theme }) => {
  const { palette, typography } = theme;
  return {
    ...typography['body-main/medium'],
    background: palette.system.button,
    color: palette.secondary.main,
    padding: '8px 20px',
    boxShadow: 'none',
    '&:hover': {
      background: palette.system.button,
      opacity: ' 0.75',
      boxShadow: 'none',
    },
    '&:disabled': {
      background: palette.system.inactive,
      color: palette.system.placeholder,
    },
  };
});

// Disable time, could be different compared to other buttons. Therefore, separate const
const sleepTime = 2000;

export const PrintButton: FC<ButtonProps> = ({ onClick, sx }) => {
  const buttonRef = useRef() as React.MutableRefObject<HTMLButtonElement>;

  return (
    <MuiButton
      ref={buttonRef}
      // onClick={() => {
      //   if (onClick) onClick();
      // }}
      sx={sx}
      onClick={() => {
        if (onClick) {
          if (buttonRef.current.disabled) return;
          buttonRef.current.disabled = true;
          if (onClick) onClick();
          setTimeout(() => {
            buttonRef.current.disabled = false;
          }, sleepTime);
        }
      }}
    >
      <Print />
      <Typography
        variant="body-sub/medium"
        sx={{
          marginLeft: '6px',
          color: 'secondary.text',
        }}
      >
        申請内容を印刷する
      </Typography>
    </MuiButton>
  );
};
