/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { BREAKPOINT, theme } from '@/theme/theme';
import { formatDateTime } from '@/utils/dateFunctions';
import { numberFormat } from '@/utils/numberFormat';
import { Box, Typography, styled } from '@mui/material';
import { BalanceInquiryHeading } from './BalanceInquiryHeading';
import { GeneralInfoPart } from './GeneralInfoPart';
import { PrintBackgroundFix } from './TransactionHistory';

const Wrapper = styled(Box)(() => {
  const { breakpoints, palette } = theme;

  return {
    backgroundColor: palette.system.background,
    width: '100%',
    padding: '40px 0',
    marginTop: 32,

    [breakpoints.down('sm')]: {
      marginTop: 24,
      padding: '24px 0',
      maxWidth: '100%',
    },

    '@media print': {
      width: '100%',
      padding: '40px 0',
      marginTop: 32,
      ...PrintBackgroundFix,
    },
  };
});

const InfoWrapper = styled(Box)(() => {
  const { breakpoints } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '0 auto',
    width: '100%',
    padding: '0 16px',
    [breakpoints.up(BREAKPOINT)]: {
      padding: '0 40px',
    },
    [breakpoints.up('lg')]: {
      padding: '0 130px',
    },

    '@media print': {
      padding: '0 16px',
    },
  };
});

const InfoBox = styled(Box)(() => {
  const { breakpoints, palette } = theme;

  return {
    borderRadius: 12,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.system.separator,
    display: 'flex',
    margin: '8px 16px 0',
    backgroundColor: palette.system.white,
    overflow: 'hidden',
    flexDirection: 'column',
    [breakpoints.up(BREAKPOINT)]: {
      flexDirection: 'row',
      margin: '16px 40px',
    },
    [breakpoints.up('lg')]: {
      flexDirection: 'row',
      margin: '16px 130px',
    },

    '@media print': {
      flexDirection: 'row',
      margin: '8px 16px 0',
      ...PrintBackgroundFix,
    },
  };
});

const Subtitle = styled(Typography)(() => {
  const { breakpoints, typography, palette } = theme;

  return {
    ...typography['body-main/regular'],
    color: palette.system['text-light'],

    [breakpoints.down('sm')]: {
      marginTop: 8,
    },

    '@media print': {
      marginTop: 0,
    },
  };
});

type Props = {
  date: Date;
  stocksNumber: number;
  marketValue: number;
  acquisitionAmount: number;
  valuationGainLoss: number;
  targetDate: string;
  complementaryClosingPrice: number;
  investmentDigit: number;
};

export const GeneralInfo = ({
  date,
  stocksNumber,
  marketValue,
  acquisitionAmount,
  valuationGainLoss,
  targetDate,
  complementaryClosingPrice,
  investmentDigit,
}: Props) => {
  const getTargetDate = () => {
    if (targetDate) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      return (
        targetDate.replace('-', '').slice(0, 4) +
        '年' +
        Number(targetDate.replace('-', '').slice(4, 6)) +
        '月'
      );
    }
    const today = new Date();
    if (today.getMonth() !== 0) {
      return today.getFullYear() + '年' + today.getMonth() + '月';
    }
    return today.getFullYear() - 1 + '年12月';
  };

  const kanmaChange = (value: string) => {
    let kanma;
    if (value.includes('.')) {
      const valueArr = value.split('.');
      const kanmaInt = valueArr[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      const cutDigit = valueArr[1].substring(0, investmentDigit);
      // eslint-disable-next-line prefer-template
      kanma = kanmaInt + '.' + cutDigit;
    } else {
      kanma = value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    }
    return kanma;
  };

  return (
    <Wrapper>
      <InfoWrapper>
        <BalanceInquiryHeading
          title="ご投資状況"
          subtitle={`${formatDateTime(date.toString())} 現在`}
        />
      </InfoWrapper>
      <InfoBox>
        <GeneralInfoPart
          isHeader
          title="現在の株数"
          description={kanmaChange(String(stocksNumber))}
          descriptionSymbol="株"
          onClick={() => {}}
        />
        <GeneralInfoPart
          isHeader={false}
          title="時価評価額※"
          description={marketValue.toLocaleString(undefined, {
            maximumFractionDigits: investmentDigit,
          })}
          descriptionSymbol="円"
          onClick={() => {}}
          PopoverText="現在株数×株価で算出されます。"
        />
        <GeneralInfoPart
          isHeader={false}
          title="取得金額"
          description={numberFormat(acquisitionAmount)}
          descriptionSymbol="円"
          onClick={() => {}}
          PopoverText="現在株数×平均取得単価で算出されます。"
        />
        <GeneralInfoPart
          isHeader={false}
          title="評価損益"
          description={numberFormat(valuationGainLoss)}
          descriptionSymbol="円"
          color={theme.palette.states.main}
          onClick={() => {}}
          PopoverText="時価評価額(現在株数×株価)ー取得金額(現在株数×平均取得単価)で算出されます。"
        />
      </InfoBox>
      <InfoWrapper sx={{ marginTop: 2 }}>
        <Subtitle>
          ※時価評価額は{getTargetDate()}
          の終値、
          {Math.floor(complementaryClosingPrice).toLocaleString()}
          円で計算しています
        </Subtitle>
      </InfoWrapper>
    </Wrapper>
  );
};
