import { styled, TableRow, TableCell } from '@mui/material';

interface TableCellProps {
  children: React.ReactNode;
}

const StyledTableRow = styled(TableRow)(({ theme }) => {
  const { palette } = theme;
  return {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:nth-of-type(even)': {
      background: palette.system['background-light'],
    },
  };
});

export const PrimaryTableBodyTdSingle = ({ children }: TableCellProps) => (
  <StyledTableRow>
    <TableCell
      colSpan={2}
      sx={{
        borderBottom: 'none',
      }}
    >
      {children}
    </TableCell>
  </StyledTableRow>
);
