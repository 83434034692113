/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SimulationService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * 【API-A-0702】シミュレーション実行
   * シミュレーションを実行する
   *
   * @param requestBody update simulation
   * @returns any application response
   * @throws ApiError
   */
  public simulate(requestBody: {
    /**
     * 毎月の積立額
     */
    monthlyAmount: number;
    /**
     * ボーナス時の積立額
     */
    bonusAmount: number;
    /**
     * ボーナス月1
     */
    bonusMonth1: number;
    /**
     * ボーナス月2
     */
    bonusMonth2: number;
    /**
     * 積立期間from
     */
    accumulationFrom: string;
    /**
     * 積立期間to
     */
    accumulationTo: string;
  }): CancelablePromise<{
    /**
     * 拠出金累計
     */
    cumulativeContribution?: number;
    /**
     * 現在の評価額
     */
    marketValue?: number;
    /**
     * 差額
     */
    differenceValue?: number;
    /**
     * 奨励金累計
     */
    incentivesTotal?: number;
    /**
     * 配当金累計
     */
    dividendTotal?: number;
    /**
     * 積立額累計
     */
    reserveTotal?: number;
    /**
     * 取得株数
     */
    acquiredSharesNum?: number;
    candlestick?: Array<{
      /**
       * 日付
       */
      targetDate?: string;
      /**
       * 始値
       */
      startingPrice?: number;
      /**
       * 高値
       */
      highPrice?: number;
      /**
       * 安値
       */
      lowPrice?: number;
      /**
       * 終値
       */
      endPrice?: number;
    }>;
    lineGraph?: Array<{
      /**
       * 横軸値（日付）
       */
      horizontalValue?: string;
      /**
       * 縦軸値1（拠出金）
       */
      verticalValue1?: number;
      /**
       * 縦軸値2（評価額）
       */
      verticalValue2?: number;
    }>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/simulate',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }
}
