// その1
import { styled, Link, useMediaQuery, Theme } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { ReactComponent as Oops } from '@/assets/oops.svg';
import { ReactComponent as RightArrow } from '@/assets/rightArrow.svg';
import homePic from '@/assets/homePic.png';
import { Path } from '@/constants/Router/path';
import type { UserSecuritiesAccounts } from '@/components/pages/MyPage/hooks/model';
import { bankAccountHGuideExceptionHandler } from '@/utils/validate/bankAccountGuideExceptionHandler';

const BREAKPOINT = 'sm';

const Wrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    [breakpoints.down(BREAKPOINT)]: {
      flexDirection: 'column',
      justifyContent: 'none',
      gap: '24px',
    },
  };
});

const WelcomeBoard = styled('div')(({ theme }) => {
  const { typography, palette, breakpoints } = theme;
  return {
    ...typography.h5,
    color: palette.system['text-light'],
    width: '30%',
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
    },
  };
});

const Space = styled('span')({
  marginRight: '1em',
});

const RightBox = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
    },
  };
});

export const Messages = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    [breakpoints.down(BREAKPOINT)]: {
      marginBottom: '16px',
    },
  };
});

const TopAlert = styled('div')(({ theme }) => {
  const { breakpoints, palette } = theme;
  return {
    width: '100%',
    backgroundColor: palette.system['background-light'],
    borderRadius: '12px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    padding: '16px 24px',
    display: 'flex',
    gap: '12px',
    justifyContent: 'space-between',
    [breakpoints.down(BREAKPOINT)]: {
      padding: '16px',
      flexDirection: 'column',
      gap: '16px',
    },
    [breakpoints.down('md')]: {
      width: '100%',
    },
  };
});

const AlertLeft = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  justifyContent: 'space-between',
  width: '100%',
});

const LeftTop = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
    [breakpoints.down(BREAKPOINT)]: {
      gap: '16px',
    },
  };
});

const NeedAccount = styled('div')(({ theme }) => {
  const { breakpoints, palette, typography } = theme;
  return {
    display: 'flex',
    gap: '10px',
    color: palette.secondary.main,
    ...typography.h6,
    alignItems: 'center',
    [breakpoints.down(BREAKPOINT)]: {
      gap: '6px',
    },
  };
});

const NeedAccountText = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-sub/regular'],
  };
});

const LeftBottom = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
});

const MuiLink = styled(Link)(({ theme }) => {
  const { palette, typography } = theme;
  return {
    ...typography['body-main/medium'],
    color: palette.primary.main,
    display: 'flex',
    gap: '12px',
    textDecoration: 'none',
    alignItems: 'center',
  };
});

const Border = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    paddingTop: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.13)',
    [breakpoints.up(BREAKPOINT)]: {
      display: 'none',
    },
  };
});

const AlertRight = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '210px',
    height: '100%',
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  };
});

const Img = styled('img')({
  width: '210px',
  height: '146px',
});

export const HighLevelSx = {
  backgroundColor: '#FFF2F2',
  color: '#D20B0B',
};

export const MiddleLevelSx = {
  backgroundColor: '#FFEDE3',
  color: '#ED5045',
};

export const LowLevelSx = {
  backgroundColor: '#E0F4F5',
  color: '#0B5D61',
};

export type WelcomeProps = {
  fullName: string;
  isListedClassification: boolean;
  isSecuritiesAccountLoading: boolean;
  children?: React.ReactNode;
  userSecuritiesAccounts: UserSecuritiesAccounts | undefined;
  associationCd: string;
};

export const Welcome: FC<WelcomeProps> = ({
  fullName = '',
  isSecuritiesAccountLoading = false,
  isListedClassification,
  children,
  userSecuritiesAccounts,
  associationCd,
}) => {
  const isSp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(BREAKPOINT)
  );

  const renderUserWithoutBankAccountGuide = useCallback(() => {
    if (bankAccountHGuideExceptionHandler(associationCd)) {
      return (
        <NeedAccountText>
          口座開設手続きに関しては事務局までご連絡下さい。
        </NeedAccountText>
      );
    }

    return (
      <MuiLink href={Path.ACCOUNTING_OPEN} target="_blank">
        口座をお持ちでない方はこちらから開設
        <RightArrow />
      </MuiLink>
    );
  }, [associationCd]);

  return (
    <Wrapper>
      <WelcomeBoard>
        ようこそ
        <Space />
        {fullName}様
      </WelcomeBoard>

      <RightBox>
        {children}
        {userSecuritiesAccounts?.branchCode === '' &&
          isListedClassification &&
          !isSecuritiesAccountLoading && (
            <TopAlert>
              <AlertLeft>
                <LeftTop>
                  <NeedAccount>
                    <Oops />
                    証券口座が必要になります！
                  </NeedAccount>
                  <NeedAccountText>
                    証券口座は退会時に100株以上保有している場合や、株式を一部引出する際に必須となります。
                    口座の開設に時間がかかる場合がございますので、お早めの口座開設をお願い致します。
                  </NeedAccountText>
                </LeftTop>
                {isSp && (
                  <AlertRight>
                    <Img src={homePic} />
                  </AlertRight>
                )}
                <LeftBottom>
                  {renderUserWithoutBankAccountGuide()}
                  <MuiLink href={Path.MYPAGE}>
                    口座をお持ちの方はこちらから登録(マイページ)
                    <RightArrow />
                  </MuiLink>

                  <Border />
                </LeftBottom>
              </AlertLeft>
              {!isSp && (
                <AlertRight>
                  <Img src={homePic} />
                </AlertRight>
              )}
            </TopAlert>
          )}
      </RightBox>
    </Wrapper>
  );
};
