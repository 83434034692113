import React, { createContext, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';
import { useNavigate } from 'react-router-dom';
import { Path } from '@/constants/Router/path';
// eslint-disable-next-line import/no-cycle
import { useSessionOutModal } from '@/hooks/useSessionOutModal';
import {
  appClient,
  authToken,
  AUTH_TOKEN,
  UserDomainService,
} from '@/services';
import { OpenAPI } from '@/services/apiServices/core/OpenAPI';
import type { FindUser } from '@/types/api/users';
import type { CognitoAuthType } from '@/types/api/auth';

export type Token = {
  AccessToken?: string | undefined;
  ExpiresIn?: number | undefined;
  IdToken?: string | undefined;
  RefreshToken?: string | undefined;
  TokenType?: string | undefined;
};

type Context = ReturnType<typeof useProvideAuth>;

/**
 * userModel
 * @param data
 */
export const userModel = (data: FindUser) =>
  ({
    memberId: data.memberId || null,
    userId: data.userId || '',
    email: data.email || '',
    associationCd: data.associationCd || '',
    memberCd: data.memberCd || '',
    userStatus: data.userStatus || '',
    companyCd: data.companyCd || '',
    officeCd: data.officeCd || '',
    affiliationCd: data.affiliationCd || '',
    employeeCd: data.employeeCd || '',
    nameKanji: data.nameKanji || '',
    nameKana: data.nameKana || '',
    birthday: data.birthday || '',
    zipcode: data.zipcode || '',
    address1: data.address1 || '',
    address2: data.address2 || '',
    address3: data.address3 || '',
    telType: data.telType || '',
    tel: data.tel || '',
    joinDt: data.joinDt || null,
    withdrawalDt: data.withdrawalDt || null,
  } as FindUser);

const authContext = createContext({} as Context);

const useProvideAuth = () => {
  const navigate = useNavigate();
  const { handleSessionOutModalOpen, handleSessionOutModalClose } =
    useSessionOutModal();
  const [user, setUser] = useState<FindUser>({} as FindUser);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    localStorage.getItem(AUTH_TOKEN) !== null
  );
  // 最終ログイン日時
  const [lastAuthTime, setLastAuthTime] = useState<string>('');

  //  新規入会承認 済みor前
  const [isApprovedForJoiningAssociation, setIsApprovedForJoiningAssociation] =
    useState<boolean>(false);

  //  新規入会承認 済みor前
  const [isPersonalInformationEntered, setIsPersonalInformationEntered] =
    useState<boolean>(false);

  /**
   * handleSetToken
   * @param token
   */
  const handleSetToken = (token: Token) => {
    if (token.IdToken) {
      const jsonString = JSON.stringify(token);
      localStorage.setItem(AUTH_TOKEN, jsonString);
      const idToken = `Bearer ${token.IdToken}`;
      // api clientのヘッダーにトークンを設定
      appClient.request.config.HEADERS = { Authorization: idToken };
      if (OpenAPI.HEADERS !== undefined) {
        OpenAPI.HEADERS = { Authorization: idToken };
      }
      setTimeout(() => {
        setIsAuthenticated(true);
      }, 1000);
    }
  };

  const { data, error, mutate } = useSWR<FindUser, Error & { status: number }>(
    authToken() ? '/api/users' : null,
    () => appClient.users.findUser()
  );

  useEffect(() => {
    // ログインしてない場合は、ログインページへ遷移
    if (
      !isAuthenticated &&
      window &&
      !UserDomainService.locationPublicRoutesPath()
    ) {
      navigate('/login');
    }
  }, [!isAuthenticated]);

  useEffect(() => {
    if (data) {
      setUser(userModel(data));
      handleSessionOutModalClose();
      // 最終ログイン時間をセット
      // apiになかったのでcognitoから取得
      const getAuthToken = localStorage.getItem(AUTH_TOKEN);
      if (getAuthToken) {
        const pToken = JSON.parse(getAuthToken) as CognitoAuthType;
        setLastAuthTime(UserDomainService.lastAuthDateTime(pToken.IdToken));
      }
    }
    // 認証切れの場合session out modalを表示(401のみ)
    if (error && error.status === 401) {
      // ログイン画面周りは情報を削除してapi callを止める
      if (UserDomainService.locationPublicRoutesPath()) {
        setUser({} as FindUser);
        setIsAuthenticated(false);
        localStorage.removeItem(AUTH_TOKEN);
        handleSessionOutModalClose();
      } else {
        handleSessionOutModalOpen();
      }
    }
  }, [data, error]);

  useEffect(() => {
    if (user && UserDomainService.isApprovedForJoiningAssociation(user)) {
      setIsApprovedForJoiningAssociation(true);
    }
    if (user && UserDomainService.isPersonalInformationEntered(user)) {
      setIsPersonalInformationEntered(true);
    }
  }, [user]);

  /**
   * handleLogout
   */
  const handleLogout = async () => {
    // api clientのヘッダーからトークンを削除
    appClient.request.config.HEADERS = { Authorization: '' };
    setUser({} as FindUser);
    setIsAuthenticated(false);
    localStorage.removeItem(AUTH_TOKEN);
    setIsApprovedForJoiningAssociation(false);
    setIsPersonalInformationEntered(false);
    setLastAuthTime('');
    await appClient.users.userSignOut();
    navigate(Path.LOGIN);
  };

  /**
   * updateUser
   * ユーザーの最新情報更新取得
   * ※useSWRのmutateを使用
   * ※他のapiで401が出た時もこちらを使用して確認する
   */
  const updateUser = async () => {
    await mutate();
  };

  return {
    user,
    setUser,
    setIsAuthenticated,
    lastAuthTime,
    updateUser,
    isApprovedForJoiningAssociation,
    isPersonalInformationEntered,
    handleLogout,
    handleSetToken,
  };
};
/**
 * useAuthUser
 */
export const useAuthUser = () => useContext(authContext);
/**
 * AuthUserProvider
 * @param children
 * @constructor
 */
export const AuthUserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
