import { loaderAtom } from '@/recoil/atom/loaderAtom';
import { useRecoilState } from 'recoil';

/**
 * useLoader
 * loading component control custom hooks
 * use api request
 * @example
 * ```ts
 *  const {
 *  handleLoaderOpen,
 *  handleLoaderClose } = useLoader()
 * ```
 * @category ReactHooks
 * @returns loaderOpen, handleLoaderOpen, handleLoaderClose
 */
export const useLoader = () => {
  // usage Recoil
  const [loaderOpen, setLoaderOpen] = useRecoilState<boolean>(loaderAtom);

  /**
   * handleLoaderOpen
   */
  const handleLoaderOpen = () => {
    setLoaderOpen(true);
  };

  /**
   * handleLoaderClose
   */
  const handleLoaderClose = () => {
    setLoaderOpen(false);
  };

  return { loaderOpen, handleLoaderOpen, handleLoaderClose };
};
