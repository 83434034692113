export const question = {
  pay: [
    {
      question: '何日までに申請すれば、当月の処理となりますか？',
      answer:
        '毎月申請の締日を事務局が設定しており、その日までの申請については当月に申請を処理致します。 締日を過ぎた申請については来月での処理となります。申請期限は持株会によって異なりますので、申請締日をご確認ください。',
    },
    {
      question: '口数は何口まで申請できますか？',
      answer:
        '口数の上限設定は持株会によって異なりますので、持株会のルール（「規約」と言います。）をご確認ください。 また、上限口数以上の入力はできない仕様となっております。',
    },
  ],
  resume: [
    {
      question: '何日までに申請すれば、当月の処理となりますか？',
      answer:
        '毎月申請の締日を事務局が設定しており、その日までの申請については当月に申請を処理致します。 締日を過ぎた申請については来月での処理となります。申請期限は持株会によって異なりますので、申請締日をご確認ください。',
    },
    {
      question: '再開を希望しています。どうすればいいですか？',
      answer: '各種お手続きより、再開の申請をしてください。',
    },
  ],
  recess: [
    {
      question: '何日までに申請すれば、当月の処理となりますか？',
      answer:
        '毎月申請の締日を事務局が設定しており、その日までの申請については当月に申請を処理致します。 締日を過ぎた申請については来月での処理となります。申請期限は持株会によって異なりますので、申請締日をご確認ください。',
    },
    {
      question: '休止の期限はありますか？',
      answer: '休止する事由が消滅する迄となり、特に期限はありません。',
    },
    {
      question: '休止中でも引出できますでしょうか？',
      answer: '休止したまま引出できます。',
    },
    {
      question: '休止中でも退会できますでしょうか？',
      answer: '休止したまま退会が可能です。',
    },
  ],
  withdrawal: [
    {
      question: '何日までに申請すれば、当月の処理となりますか？',
      answer:
        '毎月申請の締日を事務局が設定しており、その日までの申請については当月に申請を処理致します。 締日を過ぎた申請については来月での処理となります。申請期限は持株会によって異なりますので、申請締日をご確認ください。',
    },
    {
      question: '退会希望ですが、どうすればいいですか？',
      answer:
        '退会申込みから申請してください。 なお、100株以上（単元株式）保有している場合は東海東京証券の証券口座が必要になります。別途「口座開設」にてお手続きをお願いします。※引出先の口座について：特定口座を開設されている場合は特定口座へ株式が引出されます。一般口座のみ開設されている会員様においては一般口座への引出しとなります。',
    },
    {
      question: '退会精算金はいつ振り込みされますか？',
      answer:
        '100株以上（単元株式以上）保有されている場合は東海東京証券の証券口座が必須となり、すべて現金で精算する事はできません。100株未満の株式につきましては、現金換算し返金します。',
    },
    {
      question:
        '退会する予定ですが、証券口座を開設せずにすべて現金で精算する事はできますか？',
      answer:
        '100株以上（単元株式以上）保有されている場合は東海東京証券の証券口座が必須となり、すべて現金で精算する事はできません。100株未満の株式につきましては、現金換算し返金します。',
    },
    {
      question:
        '退会する予定ですが、証券口座を開設せずにすべて現金で精算する事はできますか？',
      answer: '100株以上（単元株式以上）保有している場合は必須です。',
    },
    {
      question: '証券口座の開設は必須ですか？',
      answer: '100株以上（単元株式以上）保有している場合は必須です。',
    },
  ],
  part: [
    {
      question: '何日までに申請すれば、当月の処理となりますか？',
      answer:
        '毎月申請の締日を事務局が設定しており、その日までの申請については当月に申請を処理致します。 締日を過ぎた申請については来月での処理となります。申請期限は持株会によって異なりますので、申請締日をご確認ください。',
    },
    {
      question: '引出とはどういう意味ですか？',
      answer:
        '持株会で保有している株式を個人名義に変更する手続きのことで、持株会から会員様の口座へ株式を移管します。その為、引出申請を行うためには、東海東京証券の証券口座が必要になります。',
    },
    {
      question: '引出を希望しています。どうすればいいですか？',
      answer:
        '東海東京証券に証券口座開設後、引出の申請をしてください。 ※引出先の口座について：特定口座を開設されている場合は特定口座へ株式が引出されます。一般口座のみ開設されている会員様においては一般口座への引出しとなります。',
    },
    {
      question: '一般口座しか持っていないのですが、特定口座に引出したいです。',
      answer:
        '東海東京証券に証券口座開設後、引出の申請をしてください。 ※引出先の口座について：特定口座を開設されている場合は特定口座へ株式が引出されます。一般口座のみ開設されている会員様においては一般口座への引出しとなります。',
    },
  ],
};
