import { FC } from 'react';
import { Box, styled } from '@mui/material';
import { theme } from '@/theme/theme';
import Chart from 'react-apexcharts';

const Wrapper = styled(Box)(() => {
  const { breakpoints } = theme;
  return {
    width: '50%',

    [breakpoints.down('sm')]: {
      width: '100%',
    },
  };
});

export type CandlestickItem = {
  endPrice: number;
  highPrice: number;
  lowPrice: number;
  startingPrice: number;
  targetDate: string;
};

export type GraphCandleStickProps = {
  candlestickData: Array<CandlestickItem>;
};

export const GraphCandleStick: FC<GraphCandleStickProps> = ({
  candlestickData,
}) => {
  const options = {
    data: candlestickData.map((item) => ({
      x: new Date(item.targetDate),
      y: [item.startingPrice, item.highPrice, item.lowPrice, item.endPrice],
    })),
  };
  const series = [options];
  return (
    <Wrapper>
      <Chart
        series={series}
        type="candlestick"
        options={{
          chart: {
            type: 'candlestick',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          xaxis: {
            type: 'datetime',
            labels: {
              format: 'yy/MM',
              style: {
                fontSize: '14px',
              },
            },
            tickAmount: 5,
          },
          yaxis: {
            show: true,
            labels: {
              show: true,
              align: 'right',
            },
            opposite: true,
          },
          grid: {
            padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
          },
          stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: ['#2a2a2a'],
          },
          plotOptions: {
            candlestick: {
              colors: {
                upward: theme.palette.states.done,
                downward: theme.palette.states.error,
              },
            },
          },
        }}
        width="100%"
      />
    </Wrapper>
  );
};
