import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  Validate,
} from 'react-hook-form';

type ValidationReturnType = Validate<
  FieldPathValue<FieldValues, FieldPath<FieldValues>>
>;

const PasswordNumberMatch = /[0-9]/g;
const PasswordSmallMatch = /[a-z]/g;
const PasswordBigMatch = /[A-Z]/g;
const PasswordSymbolMatch = /[.?^$*?!@#%&/,><':;|_~`\]})[{(=+-]/g;
// const alphabetAndNumber = /^([a-zA-Z0-9]+)$/;

export const validatePassword: ValidationReturnType = (text: string) => {
  const checkText = text?.replace(
    /[0-9]|[a-z]|[A-Z]|[.?^$*?!@#%&/,><':;|_~`\]})[{(=+-]/g,
    ''
  );
  if (checkText.length > 0) {
    return false;
  }
  return !!(
    text &&
    text.length !== 0 &&
    text.length >= 8 &&
    text.match(PasswordNumberMatch) &&
    text.match(PasswordSmallMatch) &&
    text.match(PasswordBigMatch) &&
    text.match(PasswordSymbolMatch)
  );
};
