import { BREAKPOINT } from '@/theme/theme';
import { TextInputSettings } from '@/types/components/TextInput';
import { Theme } from '@mui/material';

const setWidth = (spWidth: number, pcWidth: number) => ({
  sx: [
    {
      width: `${spWidth}px`,
    },
    (theme: Theme) => ({
      [theme.breakpoints.up(BREAKPOINT)]: {
        width: `${pcWidth}px`,
      },
    }),
  ],
});

export const Settings: TextInputSettings = {
  /**
   * 持株会入会
   */
  '/signup/join': {
    common: setWidth(343, 376),
    associationCd: {
      placeholder: '例：123456',
    },
    associationPassword: {
      state: {
        type: 'password',
      },
      placeholder: '例：12345678901',
    },
  },
  /**
   * アカウント登録
   */
  '/signup/account': {
    common: setWidth(343, 516),
    loginId: {
      placeholder: '例：abcdef@mail.com',
    },
    loginPassword: {
      state: {
        type: 'password',
        showIcon: true,
      },
      placeholder: 'パスワードを入力',
    },
    reloginPassword: {
      state: {
        type: 'password',
        showIcon: true,
      },
      placeholder: 'パスワードを入力',
    },
  },
  /**
   * メールアドレス確認
   */
  '/signup/mail': {
    common: setWidth(311, 345),
    mail: {
      placeholder: '例：abcdef@mail.com',
    },
  },
  /**
   * 多要素認証
   */
  '/signup/mfa': {
    common: setWidth(343, 376),
    mfaCode: {
      placeholder: '認証コードを入力',
    },
  },
  /**
   * 会員情報入力
   */
  '/signup/user': {
    common: setWidth(160, 160),
    nameKanji: {
      placeholder: '例：田中',
      ...setWidth(196, 240),
    },
    nameKanji2: {
      placeholder: '例：太郎',
      ...setWidth(196, 240),
    },
    nameKana: {
      placeholder: '例：タナカ',
      ...setWidth(196, 240),
    },
    nameKana2: {
      placeholder: '例：タロウ',
      ...setWidth(196, 240),
    },
    zip: {
      placeholder: '例：1036129',
    },
    address1: {
      placeholder: '例：中央区',
      ...setWidth(196, 240),
    },
    address2: {
      placeholder: '例：中央区',
      ...setWidth(343, 564),
    },
    address3: {
      placeholder: '例：日本橋2丁目5-1',
      ...setWidth(343, 564),
    },
    tel: {
      placeholder: '例：12345678901',
    },
    employeeCode: {
      placeholder: '例：1234',
      ...setWidth(160, 200),
    },
  },
  /**
   * 拠出設定
   */
  '/signup/contribution': {
    common: setWidth(80, 80),
    pay: {
      placeholder: '例：10',
    },
    bonus: {
      placeholder: '例：20',
    },
  },
  /**
   * ログイン
   */
  '/login': {
    common: setWidth(343, 376),
    loginId: {
      placeholder: '例：abcdef@mail.com',
    },
    password: {
      state: {
        type: 'password',
        showIcon: true,
      },
      placeholder: 'パスワードを入力',
    },
  },
  /**
   * 多要素認証
   */
  '/login/mfa': {
    common: setWidth(343, 376),
    mfaCode: {
      placeholder: '認証コードを入力',
    },
  },
  /**
   * メールアドレスを忘れた場合の本人確認
   */
  '/login/mail': {
    common: setWidth(343, 160),
    associationCd: {
      placeholder: '例：123456',
    },
    employeeCd: {
      placeholder: '例：1234',
    },
  },
  /**
   * パスワードを忘れた場合の本人確認
   */
  '/login/password': {
    common: setWidth(343, 160),
    mailAddress: {
      placeholder: '例：abcdef@mail.com',
      ...setWidth(343, 376),
    },
    associationCd: {
      placeholder: '例：123456',
    },
    employeeCd: {
      placeholder: '例：1234',
    },
  },
  /**
   * パスワードを忘れた場合のパスワード変更
   */
  '/login/password/change': {
    common: {
      state: {
        type: 'password',
        showIcon: true,
      },
      placeholder: 'パスワードを入力',
      ...setWidth(343, 376),
    },
    password1: {},
    password2: {},
  },
  /**
   * 既存新規の本人確認
   */
  '/existing-login/user/': {
    common: setWidth(343, 376),
    employeeCd: {
      placeholder: '例：1234',
    },
    employeePassword: {
      state: {
        type: 'password',
        showIcon: true,
      },
      placeholder: 'パスワードを入力',
    },
  },
  /**
   * 既存新規の会員登録
   */
  '/existing-login/user': {
    common: setWidth(343, 376),
    loginId: {
      placeholder: '例：abcdef@mail.com',
    },
    loginPassword: {
      state: {
        type: 'password',
        showIcon: true,
      },
      placeholder: 'パスワードを入力',
    },
    reLoginPassword: {
      state: {
        type: 'password',
        showIcon: true,
      },
      placeholder: 'パスワードを入力',
    },
  },
  /**
   * 口数変更（給与）
   */
  '/pay': {
    common: setWidth(80, 80),
    payUnit: {
      placeholder: '999',
    },
  },
  /**
   * 口数変更（賞与）
   */
  '/bonus': {
    common: setWidth(80, 80),
    bonusUnit: {
      placeholder: '999',
    },
  },
  /**
   * 一部引出
   */
  '/part': {
    common: setWidth(80, 80),
    payUnit: {
      placeholder: '1',
    },
    branchCode: {
      placeholder: '例：123',
      ...setWidth(116, 116),
    },
    branchName: {
      placeholder: '例：〇〇支店',
      ...setWidth(196, 196),
    },
    accountNumber: {
      placeholder: '例：1234567',
      ...setWidth(116, 116),
    },
  },
  '/recess': {
    common: setWidth(343, 376),
    otherReasons: {
      placeholder: '休止理由を入力してください',
    },
  },
  /**
   * 退会
   */
  '/withdrawal/input': {
    common: setWidth(116, 116),
    securitiesCode: {
      placeholder: '例：1234',
    },
    securitiesDepartmentName: {
      placeholder: '例：〇〇支店',
      ...setWidth(196, 196),
    },
    securitiesAccountNumber: {
      placeholder: '例：1234567',
    },
    branchCode: {
      placeholder: '例：123',
    },
    branchName: {
      placeholder: '例：〇〇支店',
      ...setWidth(196, 196),
    },
    accountNumber: {
      placeholder: '例：1234567',
    },
    'generalAccount.institutionCode': {
      placeholder: '例：1234',
    },
    'generalAccount.institutionName': {
      placeholder: '例：〇〇銀行',
      ...setWidth(196, 196),
    },
    'generalAccount.bankBranchCode': {
      placeholder: '例：123',
    },
    'generalAccount.bankBranchName': {
      placeholder: '例：〇〇支店',
      ...setWidth(196, 196),
    },
    'generalAccount.accountNumber': {
      placeholder: '例：1234567',
    },
    accountYuchoSymbol: {
      placeholder: '例：12345',
    },
    accountYuchoNumber: {
      placeholder: '例：12345678',
      ...setWidth(121, 128),
    },
    otherReasons: {
      placeholder: '退会理由を入力してください',
      ...setWidth(343, 376),
    },
  },
  /**
   * シミュレーション
   */
  '/simulation': {
    common: setWidth(80, 80),
    payUnit: {
      placeholder: '999',
    },
    bonusUnit: {
      placeholder: '999',
    },
  },
  /**
   * マイページ会員情報変更
   */
  '/mypage/user': {
    common: setWidth(160, 160),
    nameKanji: {
      placeholder: '例：田中 太郎',
      ...setWidth(343, 564),
    },
    nameKana: {
      placeholder: '例：タナカ タロウ',
      ...setWidth(343, 564),
    },
    zip: {
      placeholder: '例：1036129',
    },
    address1: {
      placeholder: '例：東京都',
      ...setWidth(196, 240),
    },
    address2: {
      placeholder: '例：中央区',
      ...setWidth(343, 564),
    },
    address3: {
      placeholder: '例：日本橋2丁目5-1',
      ...setWidth(343, 564),
    },
    tel: {
      placeholder: '例：12345678901',
    },
  },
  /**
   * マイページ会員情報変更
   */
  '/mypage/securities': {
    common: setWidth(343, 116),
    departmentCode: {
      placeholder: '例：000',
      ...setWidth(183, 116),
    },
    departmentName: {
      placeholder: '例：〇〇支店',
      ...setWidth(343, 200),
    },
    accountNumber: {
      placeholder: '例：12345678901',
      ...setWidth(343, 343),
    },
  },
  /**
   * マイページログインID変更
   */
  '/mypage/id': {
    common: setWidth(343, 564),
    email: {
      placeholder: '例：abcdef@mail.com',
    },
  },
  /**
   * マイページログインID変更MFA
   */
  '/mypage/id/mfa': {
    common: setWidth(343, 611),
    mfaCode: {
      placeholder: '認証コードを入力',
    },
  },
  /**
   * マイページパスワード変更
   */
  '/mypage/password': {
    common: {
      ...setWidth(343, 240),
      state: {
        type: 'password',
        showIcon: true,
      },
      placeholder: 'パスワードを入力',
    },
    password: {},
    repassword: {},
  },
  /**
   * マイページパスワード認証
   */
  '/mypage/auth': {
    common: {
      ...setWidth(343, 240),
      state: {
        type: 'password',
        showIcon: true,
      },
      placeholder: 'パスワードを入力',
    },
    password: {},
  },
};
