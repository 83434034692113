import { atom } from 'recoil';

export const HelmetTitleState = atom({
  key: 'HelmetTitle',
  default: 'NexStock',
});

export const HelmetDescriptionState = atom({
  key: 'HelmetDescription',
  default: '',
});
