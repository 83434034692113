import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
  /**
   * 項目のラベル
   */
  label: string;
  /**
   * 項目の値
   */
  value: string | number;
  /**
   * 下線の有無
   */
  underline: boolean;
  /**
   * カラム数
   */
  column?: 1 | 2;
  /**
   * 「給与」かどうか
   */
  isPayUnit?: boolean;
  /**
   * 「賞与」かどうか
   */
  isBonusUnit?: boolean;
  /**
   * PCかどうか
   */
  isPc?: boolean;
  /**
   * 給与の拠出金額
   */
  payValue?: string;
  /**
   * 賞与の拠出金額
   */
  bonusValue?: string;
}

interface DlProps {
  /**
   * 「給与」かどうか
   */
  isPayUnit?: boolean;
  /**
   * 「賞与」かどうか
   */
  isBonusUnit?: boolean;
  /**
   * 子コンポーネント(dt & dd)
   */
  children: ReactNode;
}

interface DtProps {
  /**
   * 「給与」かどうか
   */
  isPayUnit?: boolean;
  /**
   * 項目のラベル
   */
  children: ReactNode;
}

interface DdProps {
  /**
   * 給与の拠出金額
   */
  payValue?: string;
  /**
   * 賞与の拠出金額
   */
  bonusValue?: string;
  /**
   * 項目の値
   */
  children: ReactNode;
}

const Dd: FC<DdProps> = ({ payValue, bonusValue, children }) => {
  const value = payValue || bonusValue;

  if (value) {
    return (
      <Typography
        component="dd"
        variant="body-main/regular"
        sx={{ color: 'system.text-normal' }}
      >
        <Typography
          component="p"
          variant="body-main/regular"
          sx={{ color: 'system.text-normal' }}
        >
          {`口数\u3000${typeof children === 'string' ? children : ''}口`}
        </Typography>
        <Typography
          component="p"
          variant="body-main/regular"
          sx={{ color: 'system.text-normal' }}
        >
          {`拠出金額\u3000${value}円`}
        </Typography>
      </Typography>
    );
  }
  return (
    <Typography
      component="dd"
      variant="body-main/regular"
      sx={{ color: 'system.text-normal' }}
    >
      {children}
    </Typography>
  );
};

const Dt: FC<DtProps> = ({ isPayUnit, children }) => {
  if (isPayUnit) {
    return (
      <Typography
        component="dt"
        variant="body-sub/bold"
        sx={{ color: 'system.text-normal', minWidth: '144px' }}
      >
        <Typography
          component="p"
          variant="body-sub/bold"
          sx={{ color: 'system.text-normal' }}
        >
          給与
        </Typography>
        <Typography
          component="p"
          variant="caption/regular"
          sx={{ color: 'system.text-light' }}
        >
          ※毎月の拠出
        </Typography>
      </Typography>
    );
  }
  return (
    <Typography
      component="dt"
      variant="body-sub/bold"
      sx={{ color: 'system.text-normal', minWidth: '144px' }}
    >
      {children}
    </Typography>
  );
};

const Dl: FC<DlProps> = ({ isPayUnit, isBonusUnit, children }) => (
  <Box
    component="dl"
    sx={{
      width: '100%',
      height: isPayUnit || isBonusUnit ? '69px' : '41px',
      minWidth: '343px',
      margin: 0,
      display: 'flex',
    }}
  >
    {children}
  </Box>
);

export const UserContributionDlContainer: FC<Props> = (props) => {
  const {
    label,
    value,
    underline,
    column = 2,
    isPayUnit = false,
    isBonusUnit = false,
    isPc,
    payValue,
    bonusValue,
  } = props;

  return (
    <Box
      sx={{
        width: isPc && column === 2 ? '392px' : '100%',
        maxHeight: '69px',
        flexGrow: 1,
        borderBottom: underline ? 1 : 0,
        borderColor: 'system.separator-light',
      }}
    >
      <Dl isPayUnit={isPayUnit} isBonusUnit={isBonusUnit}>
        <Dt isPayUnit={isPayUnit}>{label}</Dt>
        <Dd payValue={payValue} bonusValue={bonusValue}>
          {value}
        </Dd>
      </Dl>
    </Box>
  );
};
