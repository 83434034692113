import { FC } from 'react';
import { Typography, Box, styled } from '@mui/material';
import { KeyboardArrowDownIcon } from '@/assets/KeyboardArrowDownIcon';

interface WarningTextProps {
  text: string | string[];
}

const WarningTextContainer = styled(Box)(({ theme }) => {
  const { breakpoints, palette } = theme;

  return {
    position: 'relative',
    padding: '16px 18px',
    margin: '0 auto',
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.primary.normal,
    width: 582,
    background: palette.primary.light,
    [breakpoints.down('sm')]: {
      width: 343,
    },
  };
});

const Text = styled(Typography)(({ theme }) => {
  const { palette, typography } = theme;
  return {
    ...typography['body-main/bold'],
    color: palette.primary.normal,
    textAlign: 'center',
  };
});

const IconBox = styled(Box)(({ theme }) => {
  const { palette } = theme;

  return {
    position: 'absolute',
    width: 20,
    height: 20,
    color: palette.primary.normal,
    left: 'calc(50% - 10px)',
    bottom: -14,
  };
});

export const WarningText: FC<WarningTextProps> = ({ text }) => {
  const renderText = () => {
    if (typeof text === 'string') return <Text>{text}</Text>;
    return text.map((item) => <Text key={item}>{item}</Text>);
  };
  return (
    <WarningTextContainer>
      <>{renderText()}</>
      <IconBox>
        <KeyboardArrowDownIcon />
      </IconBox>
    </WarningTextContainer>
  );
};
