import { Theme, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { HeaderPc } from './HeaderPc';
import { HeaderSp } from './HeaderSp';

export const Header: FC = () => {
  const isPc = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return isPc ? <HeaderPc /> : <HeaderSp />;
};
