/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { TextWithFlowIcon } from '@/components/molecules/Flow/TextWithFlowIcon';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { UserInfo } from '@/components/molecules/Table/UserInfo';
import { HomeHeader } from '@/components/organism/HomeHeader';
import { styled, Typography } from '@mui/material';
import { FC } from 'react';
import { WarmingDeadLine } from '@/components/organism/Apply/WarmingDeadLine';
import { BREAKPOINT } from '@/theme/theme';
import { PrimaryAndSecondaryButtons } from '@/components/molecules/Buttons/PrimaryAndSecondaryButtons';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { Association } from '@/types/api/association';
import { AssociationContribution } from '@/types/api/associationContribution';
import { Eventsmemberdeadlines } from '@/types/api/eventsmemberdeadlines';
import { User } from '@/types/api/user';
import { UserContribution } from '@/types/api/userContribution';
import { useNavigate, useLocation } from 'react-router-dom';
import { Path } from '@/constants/Router/path';
import { useSnackbarMessage } from '@/hooks';
import { appClient } from '@/services';
import { Associations } from '@/types/api/associations';
import { ErrorResponse } from '@/types/api/error';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const Wrraper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    marginBottom: '80px',
    padding: '14px 0',
    [breakpoints.up(BREAKPOINT)]: {
      padding: '14px 40px',
    },
    [breakpoints.up('lg')]: {
      padding: '14px 130px',
    },
  };
});

const ContentWrraper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '32px',
});

// パンクズ
const links = [
  {
    path: 'TOP',
    to: Path.HOME,
  },
  {
    path: '再開する',
    to: Path.RESUME,
  },
];

type LocationState = {
  user: User;
  association: Association;
  associationContribution: AssociationContribution;
  userContribution: UserContribution;
  eventsmemberdeadlines: Eventsmemberdeadlines;
  stocksNumber: number;
};

export const ResumeConfirm: FC = () => {
  const navigate = useNavigate();
  useHelmetHandler({
    title: '再開 入力内容確認',
  });

  const location = useLocation();
  const state = location.state as LocationState;
  const { showMessage } = useSnackbarMessage();

  const goNext = async () => {
    try {
      const body = {
        applicationDate: new Date().toString(),
        applicationType: 'RESUME' as Associations['applicationType'],
      };
      const response = await appClient.applications.createApplication(body);

      if (response) {
        navigate(Path.RESUME_COMPLETE, {
          state: {
            applicationType: response.applicationType,
            applicationId: response.applicationId,
            applicationDate: response.applicationDate,
          },
        });
      }
    } catch (e) {
      showMessage({
        open: true,
        messageType: 'error',
        messages: [(e as ErrorResponse).data.message],
      });
    }
  };

  const navigateToPrint = () => {
    navigate(`/print`, {
      state: {
        ...state,
        title: '再開する',
        contentTitle: '現在の積立状況',
        type: 'RESUME',
      },
    });
  };

  return (
    <Wrraper>
      <HomeHeader
        links={links}
        title="再開する"
        subTitle="入力内容確認"
        sx={{ width: '100%' }}
        printOnClick={() => navigateToPrint()}
      />
      <TextWithFlowIcon flowOn={1} widthFull />
      <ContentWrraper>
        <WarmingDeadLine
          eventsmemberdeadlines={state.eventsmemberdeadlines}
          target="RESUME"
        />
        <UserInfo
          mochikabukaiCode={state.user.associationCd}
          mochikabukaiName={state.association.associationName}
          nameKanji={state.user.nameKanji}
          nameKana={state.user.nameKana}
          zipcode={state.user.zipcode}
          address1={state.user.address1}
          address2={state.user.address2}
          address3={state.user.address3}
          tel={state.user.tel}
          employeeCd={state.user.employeeCd}
          editable
        />
        <BasicTable
          boxSx={{ width: '100%' }}
          title="現在の積立状況"
          type="recess"
          stocksNumber={state.stocksNumber}
          unitAmount={state.associationContribution.unitAmount}
          monthlyUnit={state.userContribution.monthlyUnit}
          bonusUnit={state.userContribution.bonusUnit}
          investmentDigit={state.association.investmentDigit}
        />
        <Typography variant="body-main/regular">
          ※休止前の口数で再開されます。再開時口数の変更を行う際は口数変更の申請をお願いします。
        </Typography>
        <PrimaryAndSecondaryButtons>
          <SecondaryButton
            sx={{
              display: 'flex',
              margin: 'auto',
              borderRadius: '28px',
              padding: '16px 24px',
            }}
            onClick={() => navigate(Path.RESUME)}
          >
            入力へ戻る
          </SecondaryButton>
          <PrimaryButton
            sx={{
              borderRadius: '28px',
              padding: '16px 24px',
            }}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={() => goNext()}
          >
            申請する
          </PrimaryButton>
        </PrimaryAndSecondaryButtons>
      </ContentWrraper>
    </Wrraper>
  );
};
