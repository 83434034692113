/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { PrimaryTableBodyTdMulti } from '@/components/atoms/Table/PrimaryTableBodyTdMulti';
import { TextWithFlowIcon } from '@/components/molecules/Flow/TextWithFlowIcon';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { PrimaryTable } from '@/components/molecules/Table/PrimaryTable';
import { UserInfo } from '@/components/molecules/Table/UserInfo';
import { HomeHeader } from '@/components/organism/HomeHeader';
import { styled, Typography } from '@mui/material';
import { FC } from 'react';
import { WarmingDeadLine } from '@/components/organism/Apply/WarmingDeadLine';
import { BREAKPOINT } from '@/theme/theme';
import { PrimaryAndSecondaryButtons } from '@/components/molecules/Buttons/PrimaryAndSecondaryButtons';
import { Warming } from '@/components/organism/Apply/Warming';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { Association } from '@/types/api/association';
import { AssociationContribution } from '@/types/api/associationContribution';
import { Eventsmemberdeadlines } from '@/types/api/eventsmemberdeadlines';
import { User } from '@/types/api/user';
import { UserContribution } from '@/types/api/userContribution';
import { useLocation, useNavigate } from 'react-router-dom';
import { Path } from '@/constants/Router/path';
import { numberFormat } from '@/utils/numberFormat';
import { useSnackbarMessage } from '@/hooks';
import { appClient } from '@/services';
import { Associations } from '@/types/api/associations';
import { ErrorResponse } from '@/types/api/error';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const Wrraper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    marginBottom: '80px',
    padding: '14px 0',
    [breakpoints.up(BREAKPOINT)]: {
      padding: '14px 40px',
    },
    [breakpoints.up('lg')]: {
      padding: '14px 130px',
    },
  };
});

const ContentWrraper = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
});

const Header = styled('div')({ position: 'relative', width: '100%' });

type LocationState = {
  user: User;
  association: Association;
  associationContribution: AssociationContribution;
  userContribution: UserContribution;
  eventsmemberdeadlines: Eventsmemberdeadlines;
  stocksNumber: number;
  payUnit: number;
  branchCode: string;
  branchName: string;
  accountNumber: string;
  findSecuritiesAccount: Partial<{
    branchCd: string;
    branchName: string;
    accountNumber: string;
    fixedFlg: boolean;
  }>;
};

// パンクズ
const links = [
  {
    path: 'TOP',
    to: Path.HOME,
  },
  {
    path: '一部を引き出す',
    to: Path.PART,
  },
];

export const PartConfirm: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { showMessage } = useSnackbarMessage();
  const state = location.state as LocationState;
  useHelmetHandler({
    title: '一部引出 入力内容確認',
  });

  const goNext = async () => {
    try {
      const { branchCode, branchName, accountNumber } = state;
      const body = {
        applicationDate: new Date().toString(),
        applicationType: 'PART' as Associations['applicationType'],
        applicationPart: {
          sharesNum: state.payUnit * 100,
        },
      };

      if (
        !state.findSecuritiesAccount.branchCd &&
        branchCode &&
        branchName &&
        accountNumber
      ) {
        await appClient.users.createSecuritiesAccount({
          branchCode: branchCode.toString(),
          branchName: state.branchName,
          accountNumber: accountNumber.toString(),
        });
      }

      const response = await appClient.applications.createApplication(body);

      if (response) {
        navigate(Path.PART_COMPLETE, {
          state: {
            applicationType: response.applicationType,
            applicationId: response.applicationId,
            applicationDate: response.applicationDate,
          },
        });
      }
    } catch (e) {
      showMessage({
        open: true,
        messageType: 'error',
        messages: [(e as ErrorResponse).data.message],
      });
    }
  };

  const navigateToPrint = () => {
    navigate(`/print`, {
      state: {
        ...state,
        title: '一部を引き出す',
        contentTitle: '引出株数',
        type: 'PART',
      },
    });
  };

  return (
    <Wrraper>
      <Header>
        <HomeHeader
          links={links}
          title="一部を引き出す"
          subTitle="入力内容確認"
          sx={{ width: '100%' }}
          printOnClick={() => navigateToPrint()}
        />
      </Header>
      <TextWithFlowIcon flowOn={1} widthFull />
      <ContentWrraper>
        <WarmingDeadLine
          eventsmemberdeadlines={state.eventsmemberdeadlines}
          target="PART"
        />
        <UserInfo
          mochikabukaiCode={state.user.associationCd}
          mochikabukaiName={state.association.associationName}
          nameKanji={state.user.nameKanji}
          nameKana={state.user.nameKana}
          zipcode={state.user.zipcode}
          address1={state.user.address1}
          address2={state.user.address2}
          address3={state.user.address3}
          tel={state.user.tel}
          employeeCd={state.user.employeeCd}
          editable
        />
        <BasicTable
          title="証券口座"
          type="securities"
          name="東海東京証券"
          departmentCode="******"
          departmentName="******"
          accountNumber="******"
          boxSx={{ padding: '0' }}
        />
        <PrimaryTable title="引出株数">
          <PrimaryTableBodyTdMulti title="現在の保有株数" spWidth="130px">
            <Typography variant="body-main/regular">
              {state.stocksNumber.toLocaleString(undefined, {
                maximumFractionDigits: state.association.investmentDigit,
              })}
            </Typography>
          </PrimaryTableBodyTdMulti>
          <PrimaryTableBodyTdMulti title="引出株数" spWidth="130px">
            <Typography variant="body-main/regular">
              {numberFormat(state.payUnit * 100)}
            </Typography>
          </PrimaryTableBodyTdMulti>
          <PrimaryTableBodyTdMulti title="残り株数" spWidth="130px">
            <Typography variant="body-main/regular">
              {numberFormat(state.stocksNumber - Number(state.payUnit) * 100, {
                maximumFractionDigits: state.association.investmentDigit,
              })}
            </Typography>
          </PrimaryTableBodyTdMulti>
        </PrimaryTable>
        <Warming />
        <PrimaryAndSecondaryButtons>
          <SecondaryButton
            sx={{
              display: 'flex',
              margin: 'auto',
              borderRadius: '28px',
              padding: '16px 24px',
            }}
            onClick={() =>
              navigate(Path.PART, {
                state: {
                  payUnit: state.payUnit,
                  branchCode: state.branchCode,
                  branchName: state.branchName,
                  accountNumber: state.accountNumber,
                },
              })
            }
          >
            入力へ戻る
          </SecondaryButton>
          <PrimaryButton
            sx={{
              borderRadius: '28px',
              padding: '16px 24px',
            }}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={() => goNext()}
          >
            申請する
          </PrimaryButton>
        </PrimaryAndSecondaryButtons>
      </ContentWrraper>
    </Wrraper>
  );
};
