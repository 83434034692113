/* eslint-disable */
import { FC, useCallback, useEffect } from 'react';
import { formatDateTime } from '@/utils/dateFunctions';
import { Box, styled } from '@mui/material';
import {
  LeftSide,
  ApplicationTitle,
  RequestStatus,
  RightSide,
} from '@/components/organism/Home/RequestStatus';
import { StatusLabel } from '@/components/organism/Home/StatusLabel';
import { Application } from '@/types/api/application';
import { ApplicationsDomainService } from '@/services';

const BREAKPOINT = 'sm';

export const RequestStatusWrapper = styled('div')(({ theme }) => {
  const { breakpoints, palette } = theme;
  return {
    padding: '40px',
    width: '100%',
    backgroundColor: palette.system.background,
    [breakpoints.down(BREAKPOINT)]: {
      padding: '24px 16px',
    },
    [breakpoints.up('lg')]: {
      padding: '40px 130px',
    },
  };
});

const ApplicationRow = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

type Props = {
  applications: Application[];
};

/**
 * RequestStatusFeature
 * @param applications
 * @constructor
 */
// @ts-ignore
export const RequestStatusFeature: FC<Props> = ({ applications }) => {
  const today = new Date().toLocaleString();
  const formatedDate = formatDateTime(today);
  useEffect(() => {
    console.log(applications);
  });

  const renderApplications = useCallback(() => {
    if (!applications) return null;
    return applications.map((application) => (
      <ApplicationRow>
        <LeftSide key={application?.applicationId}>
          <StatusLabel applicationStatus={application?.applicationStatus} />
          <ApplicationTitle>
            {ApplicationsDomainService.convertApplicationTypeText(
              application?.applicationType
            )}
          </ApplicationTitle>
        </LeftSide>
        <RightSide>
          申請日時：{formatDateTime(application?.applicationDate)}
        </RightSide>
      </ApplicationRow>
    ));
  }, [applications]);

  if (applications === null) return null;

  if (applications.length > 0) {
    return (
      <RequestStatusWrapper>
        <RequestStatus date={formatedDate} applications={applications}>
          {renderApplications()}
        </RequestStatus>
      </RequestStatusWrapper>
    );
  }
  return null;
};
/**
 *
 */
