import { FC } from 'react';
import { Box, styled, Typography, useTheme } from '@mui/material';
import {
  BreadCrumbs,
  Props as BreadCrumbsProps,
} from '@/components/molecules/Title/BreadCrumbs';
import { TextWithFlowIcon } from '@/components/molecules/Flow/TextWithFlowIcon';
import { BREAKPOINT } from '@/theme/theme';
import { PrimaryAndSecondaryButtons } from '@/components/molecules/Buttons/PrimaryAndSecondaryButtons';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { Path } from '@/constants/Router/path';
import { useUserApplicationStatus } from '@/hooks/useUserApplicationStatus';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';

const Wrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.system.background,
  padding: '14px 0 80px',
  [theme.breakpoints.up(BREAKPOINT)]: {
    padding: '14px 40px',
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '14px 130px',
  },
}));

const Contents = styled(Box)(({ theme }) => ({
  width: '750px',
  margin: '75px auto 0',
  padding: '24px',
  background: theme.palette.system.main,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down(BREAKPOINT)]: {
    width: '100%',
    margin: '25px auto 0',
  },
}));

const MuiBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: '16px',
  gap: '24px',
  background: theme.palette.system.background,
  borderRadius: '8px',
  marginBottom: '40px',
  [theme.breakpoints.down(BREAKPOINT)]: {
    flexDirection: 'column',
    gap: '8px',
    marginBottom: '24px',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  marginBottom: '32px',
  [theme.breakpoints.down(BREAKPOINT)]: { marginBottom: '24px' },
}));

interface Props {
  id?: number;
  category?:
    | 'MONTHLY_UNIT'
    | 'BONUS_UNIT'
    | 'PART'
    | 'RECESS'
    | 'RESUME'
    | 'WITHDRAWAL'
    | 'ADMISSION'
    | 'USER';
  date?: string;
}

const type = {
  MONTHLY_UNIT: '口数変更',
  BONUS_UNIT: '口数変更',
  PART: '一部引出',
  RECESS: '休止',
  RESUME: '再開',
  WITHDRAWAL: '退会',
  ADMISSION: '新規会員登録',
  USER: '会員情報変更',
};

export const Complete: FC<Props & BreadCrumbsProps> = ({
  links,
  id,
  category,
  date,
}) => {
  const { breakpoints } = useTheme();
  const navigate = useNavigate();
  const { updateApplicationsState } = useUserApplicationStatus();
  return (
    <Wrapper>
      <Box display="flex">
        <BreadCrumbs links={links} sx={{ marginLeft: '10px' }} />
      </Box>
      <TextWithFlowIcon flowOn={2} sx={{ marginTop: '20px' }} widthFull />
      <Contents>
        <Title variant="h4">申請が完了しました</Title>
        <MuiBox>
          {category && (
            <Box>
              <Typography variant="body-main/medium">申請種別</Typography>
              <Typography
                variant="body-main/regular"
                sx={{ marginLeft: '8px' }}
              >
                {type[category]}
              </Typography>
            </Box>
          )}
          {id && (
            <Box>
              <Typography variant="body-main/medium">申請ID</Typography>
              <Typography
                variant="body-main/regular"
                sx={{ marginLeft: '8px' }}
              >
                {id}
              </Typography>
            </Box>
          )}
          {date && (
            <Box>
              <Typography variant="body-main/medium">申請日時</Typography>
              <Typography
                variant="body-main/regular"
                sx={{ marginLeft: '8px' }}
              >
                {format(new Date(date.slice(0, 10)), 'yyyy/MM/dd (E)', {
                  locale: ja,
                })}
              </Typography>
            </Box>
          )}
        </MuiBox>
        <Box sx={{ alignSelf: 'center', marginBottom: '40px' }}>
          <Typography variant="body-main/regular">申請状況は</Typography>
          <Typography variant="body-main/bold">TOP</Typography>
          <Typography variant="body-main/regular">もしくは</Typography>
          <Typography variant="body-main/bold">申請履歴</Typography>
          <Typography variant="body-main/regular">で確認できます。</Typography>
        </Box>
        <PrimaryAndSecondaryButtons
          sx={{
            gap: '16px',
            'button:first-of-type': {
              marginRight: 0,
            },
            [breakpoints.down(BREAKPOINT)]: {
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              'button:first-of-type': {
                marginBottom: 0,
              },
            },
          }}
        >
          <SecondaryButton
            sx={{
              display: 'flex',
              margin: 'auto',
              borderRadius: '28px',
              padding: '16px 24px',
              marginRight: 0,
            }}
            onClick={() => {
              updateApplicationsState();
              navigate(Path.HOME);
            }}
          >
            TOP
          </SecondaryButton>
          <PrimaryButton
            sx={{
              borderRadius: '28px',
              padding: '16px 24px',
            }}
            onClick={() => {
              updateApplicationsState();
              navigate(Path.APPLICATIONS);
            }}
          >
            申請履歴
          </PrimaryButton>
          <PrimaryButton
            sx={{
              borderRadius: '28px',
              padding: '16px 24px',
            }}
            onClick={() => {
              updateApplicationsState();
              navigate(Path.PAY);
            }}
          >
            口数変更を行う
          </PrimaryButton>
        </PrimaryAndSecondaryButtons>
      </Contents>
    </Wrapper>
  );
};
