import { ReactComponent as ArrowIcon } from '@/assets/keyboardArrowDownIcon.svg';
import {
  MenuItem,
  MenuItemProps,
  Select,
  SelectChangeEvent,
  SelectProps,
  styled,
  SvgIconProps,
  FormHelperText,
  Box,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';

export interface SelectBoxProps extends SelectProps {
  items: MenuItemProps<'li', { key: string; value: string | number }>[];
  width: '80px' | '160px' | '232px' | '255px' | '320px';
  backgroundColor?: string;
  helperText?: string;
}

const MuiSelect = styled(Select)(({ theme }) => {
  const { typography, palette } = theme;

  return {
    ...typography['body-main/medium'],
    padding: '8px',
    height: '40px',

    '&&> .MuiSelect-select.MuiOutlinedInput-input': {
      padding: '0 16px 0 0',
    },
    '&> fieldset': {
      border: '1px solid',
      borderColor: palette.system.placeholder,
      borderRadius: '4px',
    },
    '&.Mui-disabled': {
      opacity: 0.4,
    },
  };
});

const MuiOption = styled(MenuItem)(({ theme }) => {
  const { typography, palette } = theme;
  return {
    ...typography['body-main/medium'],
    color: palette.system['text-normal'],
  };
});

const CustomSvgIcon: FC<SvgIconProps> = (props) => <ArrowIcon {...props} />;

export const SelectBox = (props: SelectBoxProps) => {
  const {
    items,
    width,
    backgroundColor,
    disabled,
    value = '',
    helperText,
    error = false,
    sx,
    ...rest
  } = props;
  const [selectedValue, setSelectedValue] = useState(value);
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    if (typeof event.target.value === 'string') {
      setSelectedValue(event.target.value);
    }
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <Box position="relative">
      <MuiSelect
        displayEmpty
        value={selectedValue}
        IconComponent={CustomSvgIcon}
        sx={{ width, backgroundColor, ...sx }}
        error={error}
        onChange={handleChange}
        disabled={disabled}
        {...rest}
      >
        {items.map((item) => {
          const { key } = item;
          return (
            <MuiOption key={key} value={key}>
              {item.value}
            </MuiOption>
          );
        })}
      </MuiSelect>
      {error && (
        <FormHelperText sx={{ color: '#D20B0B' }}>{helperText}</FormHelperText>
      )}
    </Box>
  );
};
