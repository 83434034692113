import { FC, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material';
import { HomeHeader } from '@/components/organism/HomeHeader';
import { ApplicationMiddle } from '@/components/organism/Application/ApplicationShow/ApplicationMiddle';
import { ApplicationBottom } from '@/components/organism/Application/ApplicationShow/ApplicationBottom';
import { BREAKPOINT } from '@/theme/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { appClient } from '@/services';
import { useAuthUser } from '@/hooks/useAuth';
import { useUserApplicationStatus } from '@/hooks/useUserApplicationStatus';
import { ConvertAssociation } from '@/services/convert/association';
import { FindAssociationById } from '@/types/api/associations';
import { Path } from '@/constants/Router/path';
import { BasicModal } from '@/components/molecules/Modal/BasicModal.tsx';
import { ApplicationCancel } from '@/components/molecules/Modal/BasicModal.tsx/ApplicationCancel';
import { CancelComplete } from '@/components/molecules/Modal/BasicModal.tsx/CancelComplate';
import { FindApplicationById } from '@/types/api/applications';
import { BountyCalculation } from '@/utils/calculation';
import { AssociationContribution } from '@/types/api/associationContribution';
import { numberFormat } from '@/utils/numberFormat';
import { ConvertAssociationContribution } from '@/services/convert/associationContribution';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const Wrapper = styled('div')(({ theme }) => {
  const { breakpoints, palette } = theme;
  return {
    padding: '33px 60px 80px',
    width: '100%',
    backgroundColor: palette.system.background,
    display: 'flex',
    gap: '24px',
    flexDirection: 'column',
    [breakpoints.down(BREAKPOINT)]: {
      padding: '24px 0px 60px',
    },
    [breakpoints.up('lg')]: {
      padding: '33px 230px 80px',
    },
  };
});

export const Application: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, toggleIsModalOpen] = useState<boolean>(false);
  const [isCompleteModalOpen, toggleIsCompleteModalOpen] =
    useState<boolean>(false);
  const [association, setAssociation] = useState<FindAssociationById>();
  const [application, setApplication] = useState<FindApplicationById>();
  const [id] = useState<number>(Number(location.pathname.split('/')[2]));
  const { updateApplicationsState } = useUserApplicationStatus();
  useHelmetHandler({
    title: '申請一覧 詳細',
  });

  // 【API-A-0502】申請情報取得
  const { data: findApplicationById } = useSWR(
    '/api/applications/{applicationId}',
    () => appClient.applications.findApplicationById(id)
  );
  // 【API-A-0909】資産情報取得
  const { user } = useAuthUser();

  // 【API-A-1001】持株会（事務局）情報
  const { data: findAssociationById } = useSWR(
    '/api/associations/{associationCd}',
    () => appClient.associations.findAssociationById()
  );

  // 【API-A-1002】持株会（事務局）拠出情報取得
  const { data: findAssociationContribution } = useSWR(
    '/api/associations/{associationCd}/contributions',
    () => appClient.associations.findAssociationContribution()
  );

  const { data: findUserContribution } = useSWR('/api/users/contribution', () =>
    appClient.users.findUserContribution()
  );

  // 【API-A-0909】資産情報取得
  const { data: userAsset } = useSWR('/api/users/assets', () =>
    appClient.users.findUserAsset()
  );

  const goNext = () => {
    toggleIsModalOpen(false);
    toggleIsCompleteModalOpen(true);
  };

  useEffect(() => {
    setAssociation(ConvertAssociation(findAssociationById));
    setApplication(findApplicationById);
  }, [findAssociationById, findApplicationById]);

  const isApplying = useMemo(() => {
    let result = true;

    if (
      application &&
      application.officeUpdateUserName &&
      application.officeUpdateUserName !== ''
    )
      return false;
    result = application
      ? application.applicationStatus === 'APPLYING' ||
        application.applicationStatus === 'PREPARATION'
      : false;
    return result;
  }, [application]);

  const getApplicationType = () => {
    switch (application?.applicationType) {
      case 'MONTHLY_UNIT':
        return 'MONTHLY';
      case 'BONUS_UNIT':
        return 'BONUS';
      default:
        return application?.applicationType;
    }
  };

  const getPassingTitle = () => {
    switch (getApplicationType()) {
      case 'BONUS':
        return {
          title: '口数を変更する',
          contentTitle: '新たな口数設定',
        };
      case 'MONTHLY':
        return {
          title: '口数を変更する',
          contentTitle: '新たな口数設定',
        };
      case 'PART':
        return {
          title: '一部を引き出す',
          contentTitle: '引出株数',
        };
      case 'RECESS':
        return {
          title: '休止する',
          contentTitle: '休止理由',
        };
      case 'RESUME':
        return {
          title: '再開する',
          contentTitle: '現在の積立状況',
        };
      case 'WITHDRAWAL':
        return {
          title: '退会する',
          contentTitle: '',
        };
      default:
        return '';
    }
  };

  const getIncentiveAmount = (
    _associationContribution: AssociationContribution
  ) =>
    BountyCalculation(
      'BONUS',
      _associationContribution,
      application?.applicationMonthlyUnit?.bonusContributionUnit || 0
    );

  const navigateToPrint = () => {
    const titleObj = getPassingTitle();
    navigate(`/print`, {
      state: {
        association,
        user,
        value: application?.applicationMonthlyUnit?.monthlyUnit,
        isBonus:
          application?.applicationMonthlyUnit?.bonusContributionUnit !== 0,
        bonusUnitAmount:
          application?.applicationMonthlyUnit?.bonusContributionUnit,
        bonusContributionAmount:
          application?.applicationMonthlyUnit?.bonusContributionUnit,
        bonusIncentive: numberFormat(
          getIncentiveAmount(
            ConvertAssociationContribution(
              findAssociationContribution
            ) as AssociationContribution
          ),
          { maximumFractionDigits: 0 },
          true
        ),
        bonusRate: application?.applicationMonthlyUnit?.bonusRate,
        application,
        associationContribution: findAssociationContribution,
        userContribution: findUserContribution,
        stocksNumber: (userAsset?.stocksNumber || 0).toLocaleString(undefined, {
          maximumFractionDigits: association?.investmentDigit,
        }),
        payUnit: application?.applicationPart?.sharesNum,
        type: getApplicationType(),
        ...titleObj,
      },
    });
  };

  if (application && association && user) {
    return (
      <Wrapper>
        <HomeHeader
          sx={{ gap: '0px' }}
          links={[
            { path: 'TOP', to: Path.HOME },
            { path: '申請履歴', to: Path.APPLICATIONS },
            {
              path: '申請詳細',
              to: `${Path.APPLICATIONS}/${id}`,
            },
          ]}
        />
        {application.applicationType &&
          application.applicationDate &&
          application.applicationStatus && (
            <ApplicationMiddle
              id={id}
              applicationType={application.applicationType}
              applicationDate={application.applicationDate}
              proxyApplicantUserName={application.proxyApplicantUserName}
              officeUpdateUserName={application.officeUpdateUserName}
              applicationStatus={application.applicationStatus}
              isApplying={isApplying}
              rejectComment={application.rejectComment}
              officeJudgeDt={application.officeJudgeDt}
              modalOpen={() => toggleIsModalOpen(true)}
              printOnClick={() => navigateToPrint()}
            />
          )}
        {application.applicationType && (
          <ApplicationBottom
            association={association}
            user={user}
            isApplying={isApplying}
            applicationType={application.applicationType}
            applicationBonusUnit={application.applicationBonusUnit}
            applicationMonthlyUnit={application.applicationMonthlyUnit}
            applicationPart={application.applicationPart}
            applicationRecess={application.applicationRecess}
            applicationResume={application.applicationResume}
            applicationWithdrawal={application.applicationWithdrawal}
            modalOpen={() => toggleIsModalOpen(true)}
          />
        )}
        <BasicModal open={isModalOpen}>
          <ApplicationCancel
            id={id}
            applicationDate={application.applicationDate ?? ''}
            applicationType={application.applicationType}
            goNext={goNext}
            onCancel={() => toggleIsModalOpen(false)}
          />
        </BasicModal>
        <BasicModal open={isCompleteModalOpen}>
          <CancelComplete
            onClick={() => {
              updateApplicationsState();
              navigate(Path.APPLICATIONS);
            }}
          />
        </BasicModal>
      </Wrapper>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
