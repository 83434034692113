import { styled, useMediaQuery, Theme } from '@mui/material';
import { FC } from 'react';
import { BREAKPOINT } from '@/theme/theme';
import { formatDateTime } from '@/utils/dateFunctions';
import { ApplicationBadge } from './ApplicationBadge';

const Id = styled('div')(({ theme }) => {
  const { breakpoints, typography, palette } = theme;
  return {
    width: '14%',
    ...typography['body-main/regular'],
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
      ...typography['caption/regular'],
      color: palette.system['text-light'],
    },
  };
});

const Type = styled('div')(({ theme }) => {
  const { breakpoints, typography } = theme;
  return {
    width: '54%',
    ...typography['body-main/regular'],
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
      ...typography['body-main/bold'],
    },
  };
});

const Date = styled('div')(({ theme }) => {
  const { breakpoints, typography, palette } = theme;
  return {
    width: '25%',
    ...typography['body-main/regular'],
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
      ...typography['caption/regular'],
      color: palette.system['text-light'],
    },
  };
});

const Status = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    [breakpoints.up(BREAKPOINT)]: { width: '7%' },
    [breakpoints.down(BREAKPOINT)]: {
      width: '56px',
    },
  };
});

const TableColumn = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    cursor: 'pointer',
    display: 'flex',
    gap: '24px',
    alignItems: 'center',
    padding: '16px 24px',
    width: '100%',
    borderTop: 'solid 1px rgba(0, 0, 0, 0.1)',
    [breakpoints.down(BREAKPOINT)]: {
      justifyContent: 'space-between',
      gap: '0px',
      alignItems: 'start',
      borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
      borderTop: 0,
    },
  };
});

const LeftData = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    [breakpoints.down(BREAKPOINT)]: {
      display: 'flex',
      gap: '2px',
      flexDirection: 'column',
    },
  };
});

interface Props {
  id: string;
  applicationType:
    | 'MONTHLY_UNIT'
    | 'BONUS_UNIT'
    | 'PART'
    | 'RECESS'
    | 'RESUME'
    | 'WITHDRAWAL'
    | 'ADMISSION'
    | 'USER';
  applicationDate: string;
  applicationStatus:
    | 'PREPARATION'
    | 'APPLYING'
    | 'OFFICER_APPROVED'
    | 'BACKOFFICER_APPROVED'
    | 'COMPLETED'
    | 'OFFICER_REJECTED'
    | 'BACKOFFICER_REJECTED'
    | 'CANCEL';
  onClick: () => void;
}

const type = {
  MONTHLY_UNIT: '口数変更',
  BONUS_UNIT: '口数変更',
  PART: '一部引出',
  RECESS: '休止',
  RESUME: '再開',
  WITHDRAWAL: '退会',
  ADMISSION: '新規会員登録',
  USER: '会員情報変更',
};

const status = {
  PREPARATION: 1,
  APPLYING: 1,
  OFFICER_APPROVED: 2,
  BACKOFFICER_APPROVED: 2,
  COMPLETED: 3,
  OFFICER_REJECTED: 4,
  BACKOFFICER_REJECTED: 4,
  CANCEL: 5,
};

export const ApplicationColumn: FC<Props> = ({
  id,
  applicationType,
  applicationDate,
  applicationStatus,
  onClick,
}) => {
  const isSp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(BREAKPOINT)
  );
  const backgroundColor =
    applicationStatus === 'APPLYING'
      ? 'system.white'
      : 'system.background-light';
  const sx = { backgroundColor };

  return (
    <TableColumn sx={sx} onClick={onClick}>
      {isSp ? (
        <>
          <LeftData>
            <Id>
              {isSp && <>申請ID: </>}
              {id}
            </Id>
            <Type>{type[applicationType]}</Type>
            <Date>{formatDateTime(applicationDate)}</Date>
          </LeftData>
          <Status>
            <ApplicationBadge status={status[applicationStatus]} />
          </Status>
        </>
      ) : (
        <>
          <Id>{id}</Id>
          <Type>{type[applicationType]}</Type>
          <Date>{formatDateTime(applicationDate)}</Date>
          <Status>
            <ApplicationBadge status={status[applicationStatus]} />
          </Status>
        </>
      )}
    </TableColumn>
  );
};
