/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthorizationService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * 【API-A-0101】持株会員サインアップ
   * 新規会員サインアップ
   *
   * @param requestBody auth signup request
   * @returns any auth signup response
   * @throws ApiError
   */
  public authSignup(requestBody: {
    /**
     * 持株会コード
     */
    associationCd: string;
    /**
     * 持株会パスワード
     */
    associationPassword: string;
    /**
     * ログインID
     */
    loginId: string;
    /**
     * パスワード
     */
    loginPassword: string;
  }): CancelablePromise<{
    /**
     * CognitoのSession
     */
    Session?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/auth/signup',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
        401: `unauthorized`,
        409: `conflict`,
      },
    });
  }

  /**
   * 【API-A0211】他社移行会員サインアップ
   * 他社移行会員初回ログイン・サインアップ
   *
   * @param requestBody auth transition signup request
   * @returns any auth transition signup response
   * @throws ApiError
   */
  public authTransitionSignup(requestBody: {
    /**
     * 会員ID
     */
    memberId: number;
    /**
     * ユーザーID
     */
    userId: string;
    /**
     * 社員コード
     */
    employeeCd: string;
    /**
     * 社員(仮)パスワード
     */
    employeePassword: string;
    /**
     * ログインID
     */
    loginId: string;
    /**
     * パスワード
     */
    loginPassword: string;
  }): CancelablePromise<{
    /**
     * CognitoのSession
     */
    Session?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/auth/transition/signup',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-A-0204】メールアドレス確認
   * 会員IDを確認する
   *
   * @param requestBody auth confirm request
   * @returns any auto confirm response
   * @throws ApiError
   */
  public authConfirm(requestBody: {
    /**
     * 持株会コード
     */
    associationCd: string;
    /**
     * 社員コード
     */
    employeeCd: string;
    /**
     * 生年月日
     */
    birthday: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/auth/confirm',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-A-0203】パスワード再設定
   * パスワードを再設定の認証
   *
   * @param requestBody auth identification request
   * @returns any auto identification response
   * @throws ApiError
   */
  public authidentification(requestBody: {
    /**
     * メールアドレス
     */
    loginId: string;
    /**
     * 持株会コード
     */
    associationCd: string;
    /**
     * 社員コード
     */
    employeeCd: string;
    /**
     * 生年月日
     */
    birthday: string;
  }): CancelablePromise<{
    /**
     * CognitoのSession
     */
    Session?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/auth/identification',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-A-0206】パスワード更新
   * パスワードを更新する（未ログイン）
   *
   * @param requestBody auth password reset request
   * @returns any application response
   * @throws ApiError
   */
  public authPasswordReset(requestBody: {
    /**
     * メールアドレス
     */
    mail: string;
    /**
     * newパスワード
     */
    newpassword: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/auth/password-reset',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-A-0201】持株会員ログイン
   * 会員ログイン
   *
   * @param requestBody auth signin request
   * @returns any auth signin response
   * @throws ApiError
   */
  public authSignin(requestBody: {
    /**
     * ログインID
     */
    loginId: string;
    /**
     * パスワード
     */
    loginPassword: string;
  }): CancelablePromise<{
    /**
     * CognitoのSession
     */
    Session?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/auth/signin',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
        408: `request timeout`,
      },
    });
  }

  /**
   * 【API-A-0202】二要素認証
   * 会員ログインの二要素認証
   *
   * @param requestBody auth mfa confirm request
   * @returns any auth mfa confirm response
   * @throws ApiError
   */
  public authMfaConfirm(requestBody: {
    /**
     * ログインID
     */
    loginId: string;
    /**
     * パスワード
     */
    mfaCode: string;
    /**
     * CognitoのSession
     */
    mfaSession: string;
  }): CancelablePromise<{
    /**
     * アクセストークン
     */
    AccessToken?: string;
    /**
     * 時間
     */
    ExpiresIn?: number;
    /**
     * Idトークン
     */
    IdToken?: string;
    /**
     * RefreshTokenトークン
     */
    RefreshToken?: string;
    /**
     * トークン種別
     */
    TokenType?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/auth/mfa-confirm',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
        401: `unauthorized`,
      },
    });
  }

  /**
   * 【API-A-0113】URL検証
   * 他者・移行会員のURLを検証する
   *
   * @param token token
   * @returns any auth url confirm response
   * @throws ApiError
   */
  public authUrlConfirm(token: string): CancelablePromise<{
    /**
     * 会員ID
     */
    memberId?: number;
    /**
     * ユーザーID
     */
    userId?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/auth/url-confirm/{token}',
      path: {
        token: token,
      },
      errors: {
        400: `bad request`,
        401: `unauthorized`,
      },
    });
  }
}
