import { FC } from 'react';
import { useFormContext, SubmitHandler } from 'react-hook-form';
import {
  UserFormValues as FormValues,
  SignUpUserFormAddValues,
} from '@/types/components/SignUpUser';

import { SignUpUserConfirmContentOrganism } from '@/components/organism/SignUp/SignUpUserConfirm';

type UserFormProps = FormValues & SignUpUserFormAddValues;
/**
 * SignUpUserConfirmFeature
 * @param handleConfirmSubmit
 * @constructor
 */
export const SignUpUserConfirmFeature: FC<{
  handleConfirmSubmit: (data: UserFormProps) => void;
}> = ({ handleConfirmSubmit }) => {
  // useFormContextでSignUpUserIndex.tsxで定義したFormProviderを使用
  const { handleSubmit, getValues } = useFormContext<UserFormProps>();
  const values = getValues(); // 入力データを取得
  /**
   * onSubmit
   * @param data
   */
  const onSubmit: SubmitHandler<UserFormProps> = (data: UserFormProps) => {
    handleConfirmSubmit(data);
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit)}>
      <SignUpUserConfirmContentOrganism
        values={values}
        association={{
          associationCd: values.associationCd,
          associationName: values.associationName,
        }}
      />
    </form>
  );
};
