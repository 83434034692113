import { Path } from './Router/path';

export const questions = [
  {
    title: '各種申請について',
    questions: [
      {
        question: '何日までに申請すれば、当月の処理となりますか？',
        answers: [
          '毎月申請の締日を事務局が設定しており、その日までの申請については当月に申請を処理致します。',
          '締日を過ぎた申請については来月での処理となります。申請期限は持株会によって異なりますので、申請締日をご確認ください。',
        ],
      },
    ],
  },
  {
    title: '口数変更について',
    questions: [
      {
        question: '口数は何口まで申請できますか？',
        answers: [
          '口数の上限設定は持株会によって異なりますので、持株会のルール（「規約」と言います。）をご確認ください。',
          'また、上限口数以上の入力はできない仕様となっております。',
        ],
      },
    ],
  },
  {
    title: '一部引き出しについて',
    questions: [
      {
        question: '引出とはどういう意味ですか？',
        answers: [
          '持株会で保有している株式を個人名義に変更する手続きのことで、持株会から会員様の口座へ株式を移管します。',
          'その為、引出申請を行うためには、東海東京証券の証券口座が必要になります。',
        ],
      },
      {
        question: '引出を希望しています。どうすればいいですか？',
        answers: [
          '東海東京証券に証券口座開設後、引出の申請をしてください。',
          '※引出先の口座について：特定口座を開設されている場合は特定口座へ株式が引出されます。一般口座のみ開設されている会員様においては一般口座への引出しとなります。',
        ],
        link: Path.ACCOUNTING_OPEN,
        linkTitle: '口座の開設はこちら',
        linkCheck: false,
      },
      {
        question:
          '一般口座しか持っていないのですが、特定口座に引出したいです。',
        answers: [
          'ご自身の口座のある店舗に連絡し、特定口座開設の御手続きをしてください。',
        ],
        link: Path.STORE_NAVIGATION,
        linkTitle: '店舗案内はこちら',
        linkCheck: false,
      },
    ],
  },
  {
    title: '休止について',
    questions: [
      {
        question: '休止を希望しています。どうすればいいですか？',
        answers: ['各種お手続きより、休止の申請をしてください。'],
        link: Path.RECESS,
        linkTitle: '休止する',
        linkCheck: true,
      },
      {
        question: '休止の期限はありますか？',
        answers: ['休止する事由が消滅する迄となり、特に期限はありません。'],
      },
      {
        question: '休止中でも引出できますでしょうか？',
        answers: ['休止したまま引出できます。'],
      },
      {
        question: '休止中でも退会できますでしょうか？',
        answers: ['休止したまま退会が可能です。'],
      },
    ],
  },
  {
    title: '再開について',
    questions: [
      {
        question: '再開を希望しています。どうすればいいですか？',
        answers: ['各種お手続きより、再開の申請をしてください。'],
        link: Path.RESUME,
        linkTitle: '再開する',
        linkCheck: true,
      },
    ],
  },
  {
    title: '退会について',
    questions: [
      {
        question: '退会希望ですが、どうすればいいですか？',
        answers: [
          '退会申込みから申請して下さい。',
          'なお、100株以上（単元株式）保有している場合は東海東京証券の証券口座が必要になります。別途「口座開設」にてお手続きをお願いします。',
          '※引出先の口座について：特定口座を開設されている場合は特定口座へ株式が引出されます。一般口座のみ開設されている会員様においては一般口座への引出しとなります。',
        ],
        linkTitle: '口座の開設はこちら',
        link: Path.ACCOUNTING_OPEN,
        linkCheck: false,
        caution:
          '注意：一旦退会を行うと原則再入会ができません。持株会を再開される可能性がある場合は休止の手続きをお願いします。',
      },
      {
        question: '退会精算金はいつ振り込みされますか？',
        answers: [
          '通常は買付日に退会精算処理を行いますが、具体的な日にちにつきましては事務局にお問い合わせ下さい。',
        ],
        link: Path.CONTACT,
        linkTitle: 'お問い合わせ',
        linkCheck: false,
      },
      {
        question:
          '退会する予定ですが、証券口座を開設せずにすべて現金で精算する事はできますか？',
        answers: [
          '100株以上（単元株式以上）保有されている場合は東海東京証券の証券口座が必須となり、すべて現金で精算する事はできません。',
          '100株未満の株式につきましては、現金換算し返金します。',
        ],
      },
      {
        question:
          '100株以上（単元株式以上）保有しておらず、口座を開設していない為退会申請ができないのですがどうすればいいですか？',
        answers: [
          '事務局にお問い合わせください。事務局より未満株退会用に入力する口座情報をお伝えさせていただきます。',
        ],
      },
      {
        question: '証券口座の開設は必須ですか？',
        answers: ['100株以上（単元株式以上）保有している場合は必須です。'],
        linkTitle: '口座の開設はこちら',
        link: Path.ACCOUNTING_OPEN,
        linkCheck: false,
      },
    ],
  },
  {
    title: '口座開設について',
    questions: [
      {
        question: '口座を開設したいのですが、手続き方法を教えてください。',
        answers: ['以下のリンクより開設手続きを行ってください。'],
        linkTitle: '口座の開設はこちら',
        link: Path.ACCOUNTING_OPEN,
        linkCheck: false,
      },
      {
        question:
          '他の証券会社に口座があるので、そちらの口座に引出を希望しますので手続き方法を教えてください。',
        answers: [
          '持株会から株式を引出しする場合は、東海東京証券の証券口座が必要となります。他の証券会社の口座に直接引出する事はできません。',
          'なお、東海東京証券の証券口座へ引出しした後、他の証券会社の口座へ移管する事は可能です。ただし、移管手数料が必要となりますので、ご留意ください。',
          '移管の手続きを行う際は引出し手続きが完了後、ご自身の口座がある店舗へご連絡ください。',
        ],
        linkTitle: '店舗案内はこちら',
        link: Path.STORE_NAVIGATION,
        linkCheck: false,
      },
      {
        question: '口座は何日で開設できますか？',
        answers: [
          '書類に不備がなければ５営業日ほどで開設が完了します。書類に不備がある場合は日数を要します。',
        ],
      },
      {
        question: '口座管理料はかかりますか？',
        answers: [
          '持株会の会員の間は口座管理料は免除となります。',
          'なお、退職により持株会を退会する場合は口座管理料の免除対象から外れる事があります。',
        ],
      },
      {
        question: '口座の有無を確認したいのですが、連絡先を教えてください。',
        answers: ['持株事務局へ連絡してください。'],
        linkTitle: 'お問い合わせ',
        link: Path.CONTACT,
        linkCheck: false,
      },
    ],
  },
  {
    title: '売却について',
    questions: [
      {
        question:
          '持株会で保有している株式を売却したいと思いますがどうすればいいでしょうか？',
        answers: [
          'まず、持株会で保有している株式を個人名義に変更する必要があります。東海東京証券に証券口座開設後、引出の申請をしてください。引出しが完了し、個人名義に変わりましたら売却が可能となります。',
          '※引出先の口座について：特定口座を開設されている場合は特定口座へ株式が引出されます。一般口座のみ開設されている会員様においては一般口座への引出しとなります。',
        ],
        linkTitle: '口座の開設はこちら',
        link: Path.ACCOUNTING_OPEN,
        linkCheck: false,
      },
      {
        question: '株式を売却する時はどこに連絡すればいいですか？',
        answers: [
          'ご自身の証券口座がある店舗へご連絡ください。',
          'なお、インサイダー情報の関係で、売却時に会社への申請が必要となる場合があります。社内ルールをご確認ください。',
        ],
      },
    ],
  },
  {
    title: '登録情報の変更・確認について',
    questions: [
      {
        question: 'ログインIDを確認したい',
        answers: [
          'マイページより確認をお願いします。',
          '※再度本人確認が必要となります。',
        ],
        linkTitle: 'マイページ',
        link: Path.MYPAGE,
        linkCheck: false,
      },
      {
        question: 'パスワードを変更したい',
        answers: [
          'マイページより確認をお願いします。',
          '※再度本人確認が必要となります。',
        ],
        linkTitle: 'マイページ',
        link: Path.MYPAGE,
        linkCheck: false,
      },
      {
        question: '住所を変更したい',
        answers: [
          '住所変更の申請をお出しください。',
          '承認後システムに反映されます。',
        ],
        linkTitle: 'マイページ',
        link: Path.MYPAGE,
        linkCheck: false,
      },
      {
        question: '証券口座の情報を確認したい',
        answers: [
          'マイページより確認をお願いします。',
          '※再度本人確認が必要となります。',
        ],
        linkTitle: 'マイページ',
        link: Path.MYPAGE,
        linkCheck: false,
      },
    ],
  },
];
