/* eslint-disable */
import { useRoutes } from 'react-router-dom';
import { useAuthUser } from '@/hooks/useAuth';
import { isObjectEmpty } from '@/utils/empty';
import { routes } from './constants/Router/routes';

export const App = () => {
  const { user } = useAuthUser();

  const routesResult = isObjectEmpty(user) ? routes.public : routes.private;

  const element = useRoutes(routesResult);

  return element;
};
