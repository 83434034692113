/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ReportsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * 【API-A-0801】持分精算書リスト取得
   * 持分精算書のリストを取得する
   *
   * @returns any equity settlement form response
   * @throws ApiError
   */
  public listEquitySettlementForm(): CancelablePromise<
    {
      reports?: Array<{
        /**
         * 持株会コード
         */
        associationCd?: string;
        /**
         * 会員コード
         */
        memberCd?: string;
        /**
         * 約定年月日
         */
        contractYmd?: string;
      }>;
    } & {
      /**
       * 現在ページ番号
       */
      currentPage?: number;
      /**
       * 総ページ数
       */
      totalPage?: number;
      /**
       * １ページあたり件数
       */
      perPageSize?: number;
      /**
       * 総件数
       */
      totalSize?: number;
    }
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/reports/equitySettlementForms',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-A-0802】配当金支払証明書兼支払通知書（リスト）取得
   * 配当金支払証明書兼支払通知書（リスト）を取得する
   *
   * @returns any dividend payment certificate response
   * @throws ApiError
   */
  public listDividendPaymentCertificate(): CancelablePromise<
    {
      reports?: Array<{
        /**
         * 対象年
         */
        targetYear?: number;
        /**
         * メンバーID
         */
        memberId?: number;
        /**
         * 配当金支払い証明書ID
         */
        dividendPaymentCertificateReportId?: number;
      }>;
    } & {
      /**
       * 現在ページ番号
       */
      currentPage?: number;
      /**
       * 総ページ数
       */
      totalPage?: number;
      /**
       * １ページあたり件数
       */
      perPageSize?: number;
      /**
       * 総件数
       */
      totalSize?: number;
    }
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/reports/dividendPaymentCertificate',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-A-0802】帳票DL_持分精算書_説明（役・従）
   * 帳票DL【会員用】持分精算書_説明（役・従）
   *
   * @param targetYear 対象年
   *
   * @param targetMonth 対象月
   *
   * @param targetDay 対象日
   *
   * @returns binary equity settlement form response
   * @returns any unexpected error
   * @throws ApiError
   */
  public equitySettlementFormReportsDl(
    targetYear: string,
    targetMonth: string,
    targetDay: string
  ): CancelablePromise<
    | Blob
    | {
        /**
         * Error code
         */
        code: number;
        /**
         * Error message
         */
        message: string;
      }
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/reports/equitySettlementForm/{targetYear}/{targetMonth}/{targetDay}/download',
      path: {
        targetYear: targetYear,
        targetMonth: targetMonth,
        targetDay: targetDay,
      },
      headers: {
        Accept: 'application/pdf',
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-A-0802】帳票DL_配当金支払証明書兼支払通知書_説明
   * 帳票DL【会員用】配当金支払証明書兼支払通知書_説明
   *
   * @param dividendPaymentCertificateReportId 配当金支払い証明書ID
   *
   * @returns binary equity settlement form response
   * @returns any unexpected error
   * @throws ApiError
   */
  public dividendPaymentCertificateReportsDl(
    dividendPaymentCertificateReportId: number
  ): CancelablePromise<
    | Blob
    | {
        /**
         * Error code
         */
        code: number;
        /**
         * Error message
         */
        message: string;
      }
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/reports/dividendPaymentCertificate/{dividendPaymentCertificateReportId}/download',
      path: {
        dividendPaymentCertificateReportId: dividendPaymentCertificateReportId,
      },
      headers: {
        Accept: 'application/pdf',
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-A-0802】帳票DL_退会者精算書_説明
   * 帳票DL【会員用】帳票DL【会員用】退会者精算書_説明
   *
   * @param reportType 帳票種別
   * - settlement: 退会時精算金
   * - dividend: 退会後配当金
   *
   * @returns binary equity settlement form response
   * @returns any unexpected error
   * @throws ApiError
   */
  public withdrawalSettlementFormReportsDl(
    reportType: string
  ): CancelablePromise<
    | Blob
    | {
        /**
         * Error code
         */
        code: number;
        /**
         * Error message
         */
        message: string;
      }
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/reports/withdrawalSettlementForm/{reportType}/download',
      path: {
        reportType: reportType,
      },
      headers: {
        Accept: 'application/pdf',
      },
      errors: {
        400: `bad request`,
      },
    });
  }
}
