import { FC } from 'react';
import { theme } from '@/theme/theme';
import { Box, Typography, styled } from '@mui/material';
import { ReactComponent as Question } from '@/assets/questionPopOver.svg';
import { PopoverWithText } from '../Popover/PopoverWithText';
import { PrintBackgroundFix } from './TransactionHistory';

interface GeneralInfoPartProps {
  isHeader: boolean;
  title: string;
  description: string;
  descriptionSymbol?: string;
  onClick: () => void;
  color?: string;
  PopoverText?: string;
}

const Wrapper = styled(Box)(() => {
  const { breakpoints, palette } = theme;

  return {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: palette.system['separator-light'],
    borderStyle: 'solid',

    '&:last-of-type': {
      borderRightWidth: 0,
    },

    [breakpoints.down('sm')]: {
      flexDirection: 'row',
      borderRightWidth: 0,
      borderBottomWidth: 1,

      '&:last-of-type': {
        borderBottomWidth: 0,
      },
    },

    '@media print': {
      flexDirection: 'column',
      borderWidth: 0,
      borderRightWidth: 1,

      '&:last-of-type': {
        borderRightWidth: 0,
      },
    },
  };
});

const HeaderWrapper = styled(Box)(() => {
  const { breakpoints, palette } = theme;

  return {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '23px 0',
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: palette.system['separator-light'],
    borderStyle: 'solid',

    [breakpoints.down('sm')]: {
      flexDirection: 'row',
      borderBottomWidth: 1,
    },

    '@media print': {
      flexDirection: 'column',
      borderBottomWidth: 0,
      borderRightWidth: 1,
    },
  };
});

const TitleWrapper = styled(Box)(() => {
  const { breakpoints, palette } = theme;

  return {
    flex: 4.5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#EFFBFC',
    flexDirection: 'row',
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.system['separator-light'],

    [breakpoints.down('sm')]: {
      padding: '17px 16px',
      borderBottomWidth: 0,
      borderRightWidth: 1,
      justifyContent: 'flex-start',
    },

    '@media print': {
      padding: 0,
      borderWidth: 0,
      borderBottomWidth: 1,
      justifyContent: 'center',
      ...PrintBackgroundFix,
    },
  };
});

const DescriptionWrapper = styled(Box)(() => {
  const { breakpoints } = theme;

  return {
    flex: 5.5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [breakpoints.down('sm')]: {
      padding: '17px 16px',
      justifyContent: 'flex-end',
    },

    '@media print': {
      padding: 0,
      justifyContent: 'center',
    },
  };
});

const HeaderTitleWrapper = styled(Box)(() => {
  const { breakpoints } = theme;

  return {
    marginBottom: 16,

    [breakpoints.down('sm')]: {
      marginBottom: 0,
      marginRight: 8,
    },

    '@media print': {
      marginBottom: 16,
      marginRight: 0,
    },
  };
});

const Title = styled(Typography)(() => {
  const { breakpoints, typography } = theme;

  return {
    ...typography.h6,
    color: '#0B5D61',
    marginRight: 11,
    [breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '150%',
      marginRight: 5,
    },

    '@media print': {
      lineHeight: '100%',
      marginRight: 11,
      fontSize: 18,
    },
  };
});

const Description = styled(Typography)(() => {
  const { breakpoints, typography, palette } = theme;

  return {
    ...typography.h4,
    fontWeight: 700,
    fontSize: 30,
    color: palette.system['text-normal'],

    [breakpoints.down('sm')]: {
      fontSize: 22,
      lineHeight: '100%',
    },

    '@media print': {
      fontSize: 30,
    },
  };
});

const SymbolWrapper = styled(Box)({
  marginLeft: 2,
});

const Symbol = styled(Box)(() => {
  const { palette, breakpoints } = theme;

  return {
    fontSize: 20,
    lineHeight: '170%',
    fontWeight: 700,
    color: palette.system['text-normal'],

    [breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '190%',
    },

    '@media print': {
      fontSize: 20,
      lineHeight: '170%',
    },
  };
});

export const GeneralInfoPart: FC<GeneralInfoPartProps> = ({
  isHeader,
  title,
  description,
  descriptionSymbol,
  color,
  PopoverText,
}) => {
  const renderContent = () => {
    if (isHeader) {
      return (
        <HeaderWrapper>
          <HeaderTitleWrapper sx={{ color: '#0B5D61', fontWeight: '500' }}>
            {title}
          </HeaderTitleWrapper>
          <Description>
            {description}
            {descriptionSymbol && (
              <SymbolWrapper component="span">
                <Symbol component="span">{descriptionSymbol}</Symbol>
              </SymbolWrapper>
            )}
          </Description>
        </HeaderWrapper>
      );
    }

    return (
      <Wrapper>
        <TitleWrapper>
          <Title>{title}</Title>
          <PopoverWithText icon={<Question />}>{PopoverText}</PopoverWithText>
        </TitleWrapper>
        <DescriptionWrapper>
          <Description sx={{ color }}>
            {description}
            {descriptionSymbol && (
              <SymbolWrapper component="span">
                <Symbol component="span" sx={{ color }}>
                  {descriptionSymbol}
                </Symbol>
              </SymbolWrapper>
            )}
          </Description>
        </DescriptionWrapper>
      </Wrapper>
    );
  };

  return renderContent();
};
