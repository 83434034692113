import {
  PrimaryTableBodyTdMulti,
  PrimaryTableBodyTdSub,
} from '@/components/atoms/Table/PrimaryTableBodyTdMulti';
import { PrimaryTableBodyTdSingle } from '@/components/atoms/Table/PrimaryTableBodyTdSingle';
import { TextWithFlowIcon } from '@/components/molecules/Flow/TextWithFlowIcon';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { PrimaryTable } from '@/components/molecules/Table/PrimaryTable';
import { UserInfo } from '@/components/molecules/Table/UserInfo';
import { HomeHeader } from '@/components/organism/HomeHeader';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from '@mui/material';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { FAQ } from '@/components/organism/Apply/Faq';
import { WarmingDeadLine } from '@/components/organism/Apply/WarmingDeadLine';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { PrimaryTableBodyTdSingleWithSpNotRed } from '@/components/atoms/Table/PrimaryTableBodyTdSingleWithSpNotRed';
import { TextInput } from '@/components/atoms/Input/TextInput';
import { BREAKPOINT } from '@/theme/theme';
import { Association } from '@/types/api/association';
import { useAuthUser } from '@/hooks/useAuth';
import { appClient } from '@/services';
import { ConvertAssociation } from '@/services/convert/association';
import { ConvertAssociationContribution } from '@/services/convert/associationContribution';
import { ConvertEventsmemberdeadlines } from '@/services/convert/eventsmemberdeadlines';
import { ConvertUserContribution } from '@/services/convert/userContribution';
import { AssociationContribution } from '@/types/api/associationContribution';
import { Eventsmemberdeadlines } from '@/types/api/eventsmemberdeadlines';
import { UserContribution } from '@/types/api/userContribution';
import useSWR from 'swr';
import { Path } from '@/constants/Router/path';
import { useLocation, useNavigate } from 'react-router-dom';
import { numberFormat } from '@/utils/numberFormat';
import {
  BonusFixedMultiplierCalculation,
  BountyCalculation,
} from '@/utils/calculation';
import { IsNumeric } from '@/utils/stringProcessor';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const Wrraper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    marginBottom: '80px',
    padding: '14px 0',
    [breakpoints.up(BREAKPOINT)]: {
      padding: '14px 40px',
    },
    [breakpoints.up('lg')]: {
      padding: '14px 130px',
    },
  };
});

const MuiBox = styled(Box)(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down(BREAKPOINT)]: {
      display: 'block',
    },
  };
});

const MuiRadioGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'row',
});

const ContentWrraper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
});

// パンクズ
const links = [
  {
    path: 'TOP',
    to: Path.HOME,
  },
  {
    path: '口数を変更する (賞与)',
    to: Path.BONUS,
  },
];

// タイトルの説明文
const description = ['賞与の積立口数を変更します。'];

type LocationState = {
  isBonus: boolean;
  bonusUnit: number;
  bonusRate: number;
};

export const BonusIndex: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationState;

  const [isBonus, toggleIsBonus] = useState<boolean>(true);
  const [bonusUnit, setBonusUnit] = useState<string>();
  const [bonusRate, setBonusRate] = useState<number>(0);
  const [association, setAssociation] = useState<Association>();
  const [userContribution, setUserContribution] = useState<UserContribution>();
  const [associationContribution, setAssociationContribution] =
    useState<AssociationContribution>();
  const [eventsmemberdeadlines, setEventsmemberdeadlines] =
    useState<Eventsmemberdeadlines>();
  useHelmetHandler({
    title: '口数変更(賞与) 入力画面',
  });

  // 【API-A-0909】資産情報取得
  const { user } = useAuthUser();

  // 【API-A-1002】持株会（事務局）拠出情報取得
  const { data: findAssociationContribution } = useSWR(
    '/api/associations/{associationCd}/contributions',
    () => appClient.associations.findAssociationContribution()
  );

  // 【API-A-1001】持株会（事務局）情報
  const { data: findAssociationById } = useSWR(
    '/api/associations/{associationCd}',
    () => appClient.associations.findAssociationById()
  );

  // 【API-A-1001】持株会（事務局）情報
  const { data: findAssociationEventMemberDeadlines } = useSWR(
    '/api/associations/{associationCd}/eventsmemberdeadlines',
    () => appClient.associations.findAssociationEventMemberDeadlines()
  );

  // 【API-A-0905】拠出情報取得
  const { data: findUserContribution } = useSWR('/api/users/contribution', () =>
    appClient.users.findUserContribution()
  );

  const editUnitAmount = useMemo(() => {
    if (!associationContribution || !userContribution) {
      return 0;
    }
    if (
      associationContribution.bonusMultiplierDesignationType === 'UNIT_NUMBER'
    ) {
      return Number(bonusUnit) * associationContribution.unitAmount;
    }
    return (
      userContribution.monthlyUnit *
      bonusRate *
      associationContribution.unitAmount
    );
  }, [bonusUnit, bonusRate, associationContribution, userContribution]);

  useEffect(() => {
    setAssociation(ConvertAssociation(findAssociationById));
    setAssociationContribution(
      ConvertAssociationContribution(findAssociationContribution)
    );
    setEventsmemberdeadlines(
      ConvertEventsmemberdeadlines(
        findAssociationEventMemberDeadlines?.eventmemberdeadlines
      )
    );
    setUserContribution(ConvertUserContribution(findUserContribution));
  }, [
    findAssociationById,
    findAssociationContribution,
    findAssociationEventMemberDeadlines,
    findUserContribution,
  ]);

  useEffect(() => {
    if (state) {
      setBonusUnit(state.bonusUnit.toString());
      toggleIsBonus(state.isBonus);
      setBonusRate(state.bonusRate);
    } else if (userContribution) {
      setBonusUnit(userContribution.bonusUnit.toString());
      setBonusRate(userContribution.bonusRate);
      if (userContribution.bonusUnit === null) {
        toggleIsBonus(false);
      }
    }
  }, [userContribution]);

  useEffect(() => {
    if (!isBonus) {
      setBonusUnit('');
      setBonusRate(0);
    }
  }, [isBonus]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let amount = bonusUnit;
    if (IsNumeric(e.target.value)) {
      amount = Math.floor(
        Math.min(
          Number(e.target.value),
          associationContribution?.maxBonusUnit ?? 0
        )
      ).toString();
    } else if (e.target.value === '') {
      amount = undefined;
    }
    setBonusUnit(amount || '');
  };

  const isDisabled = !(
    (Number(bonusUnit) !== 0 &&
      Number(bonusUnit) !==
        (userContribution ? userContribution.bonusUnit : 0)) ||
    !isBonus
  );

  const getIncentiveAmount = (
    _associationContribution: AssociationContribution,
    _userContribution: UserContribution
  ) => {
    // In case of Fixed magnification
    if (
      _associationContribution.bonusMultiplierDesignationType ===
      'FIXED_MAGNIFICATION'
    )
      return BonusFixedMultiplierCalculation(
        'BONUS',
        _associationContribution,
        Number(_userContribution.monthlyUnit)
      );
    // In case bonusIncentiveRatio is 0 and bonusIncentiveFixedAmount is not  0
    if (
      _associationContribution.bonusIncentiveRatio === 0 &&
      _associationContribution.bonusIncentiveFixedAmount !== 0
    )
      return _associationContribution.bonusIncentiveFixedAmount;

    // default return
    return BountyCalculation(
      'BONUS',
      _associationContribution,
      Number(bonusUnit)
    );
  };

  if (
    association &&
    associationContribution &&
    userContribution &&
    eventsmemberdeadlines
  ) {
    return (
      <Wrraper>
        <HomeHeader
          links={links}
          title="口数を変更する (賞与)"
          description={description}
        />
        <TextWithFlowIcon flowOn={0} widthFull />
        <FAQ pageName="pay">
          <ContentWrraper>
            <WarmingDeadLine
              eventsmemberdeadlines={eventsmemberdeadlines}
              target="BONUS_UNIT"
            />
            <UserInfo
              mochikabukaiCode={user.associationCd ?? ''}
              mochikabukaiName={association.associationName ?? ''}
              nameKanji={user.nameKanji ?? ''}
              nameKana={user.nameKana ?? ''}
              zipcode={user.zipcode ?? ''}
              address1={user.address1 ?? ''}
              address2={user.address2 ?? ''}
              address3={user.address3 ?? ''}
              tel={user.tel ?? ''}
              employeeCd={user.employeeCd ?? ''}
              editable
            />
            <BasicTable
              boxSx={{ width: '100%' }}
              title="現在の口数設定"
              type="contribution"
              unitAmount={associationContribution.unitAmount}
              monthlyUnit={userContribution.monthlyUnit}
              bonusUnit={userContribution.bonusUnit}
              investmentDigit={association.investmentDigit}
            />
            <PrimaryTable title="新たな口数設定">
              <PrimaryTableBodyTdSingleWithSpNotRed
                title="1口あたり金額"
                value={`${numberFormat(associationContribution.unitAmount)}円`}
              />
              <PrimaryTableBodyTdSingleWithSpNotRed
                title="奨励金率"
                value={`${associationContribution.monthlyIncentiveRatio}%`}
              />
              <PrimaryTableBodyTdMulti spWidth="80px" title="賞与" row={4}>
                <PrimaryTableBodyTdSub title="拠出">
                  <FormControl>
                    <MuiRadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="yes"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        checked={isBonus === true}
                        control={<Radio />}
                        label="する"
                        onClick={() => toggleIsBonus(true)}
                      />
                      <FormControlLabel
                        checked={isBonus === false}
                        control={<Radio />}
                        label="しない"
                        onClick={() => toggleIsBonus(false)}
                      />
                    </MuiRadioGroup>
                  </FormControl>
                </PrimaryTableBodyTdSub>
              </PrimaryTableBodyTdMulti>
              {isBonus && (
                <>
                  <PrimaryTableBodyTdSingle>
                    <PrimaryTableBodyTdSub
                      title={
                        associationContribution.bonusMultiplierDesignationType ===
                        'FIXED_MAGNIFICATION'
                          ? '倍率'
                          : '口数'
                      }
                    >
                      {associationContribution.bonusMultiplierDesignationType ===
                        'UNIT_NUMBER' && (
                        <MuiBox>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TextInput
                              name="bonusUnit"
                              value={bonusUnit}
                              onChange={(e) => handleChange(e)}
                            />
                            <Typography
                              sx={{ marginLeft: '8px', marginRight: '30px' }}
                              variant="body-sub/regular"
                            >
                              口
                            </Typography>
                          </Box>
                          <Typography
                            variant="body-sub/regular"
                            sx={{ color: 'system.text-light' }}
                          >
                            ※最大{associationContribution.maxBonusUnit}口
                          </Typography>
                        </MuiBox>
                      )}
                      {associationContribution.bonusMultiplierDesignationType ===
                        'MAGNIFICATION' && (
                        <MuiBox>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <MuiRadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue="yes"
                              name="radio-buttons-group"
                            >
                              {associationContribution.bonusMultiplierDetails.map(
                                (i) => (
                                  <FormControlLabel
                                    value={i}
                                    control={<Radio />}
                                    checked={i === bonusRate}
                                    label={`${i}倍`}
                                    onClick={() => {
                                      setBonusRate(i);
                                      setBonusUnit(
                                        (
                                          userContribution.monthlyUnit * i
                                        ).toString()
                                      );
                                    }}
                                  />
                                )
                              )}
                            </MuiRadioGroup>
                          </Box>
                        </MuiBox>
                      )}
                      {associationContribution.bonusMultiplierDesignationType ===
                        'FIXED_MAGNIFICATION' && (
                        <MuiBox>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                              sx={{ marginLeft: '8px', marginRight: '16px' }}
                              variant="body-sub/bold"
                            >
                              {
                                associationContribution
                                  .bonusMultiplierDetails[0]
                              }
                              倍
                            </Typography>
                          </Box>
                          <Typography
                            variant="body-sub/regular"
                            sx={{ color: 'system.text-light' }}
                          >
                            ※給与（毎月の拠出）対比
                          </Typography>
                        </MuiBox>
                      )}
                    </PrimaryTableBodyTdSub>
                  </PrimaryTableBodyTdSingle>
                  {associationContribution.bonusMultiplierDesignationType ===
                    'UNIT_NUMBER' && (
                    <>
                      <PrimaryTableBodyTdSingle>
                        <PrimaryTableBodyTdSub title="拠出金額">
                          <Typography variant="body-main/bold">
                            {numberFormat(
                              Number(bonusUnit) *
                                associationContribution.unitAmount
                            )}
                            円
                          </Typography>
                        </PrimaryTableBodyTdSub>
                      </PrimaryTableBodyTdSingle>
                      <PrimaryTableBodyTdSingle>
                        <PrimaryTableBodyTdSub title="奨励金額">
                          <Typography variant="body-main/bold">
                            {numberFormat(
                              getIncentiveAmount(
                                associationContribution,
                                userContribution
                              ),
                              { maximumFractionDigits: 0 },
                              true
                            )}
                            円
                          </Typography>
                        </PrimaryTableBodyTdSub>
                      </PrimaryTableBodyTdSingle>
                    </>
                  )}
                </>
              )}
            </PrimaryTable>
            <PrimaryButton
              sx={{
                padding: '16px 40px',
                borderRadius: '28px',
                alignSelf: 'center',
              }}
              onClick={() =>
                navigate(Path.BONUS_CONFIRM, {
                  state: {
                    user,
                    association,
                    associationContribution,
                    userContribution,
                    eventsmemberdeadlines,
                    bonusUnit,
                    isBonus,
                    bonusRate,
                    editUnitAmount,
                    bonusMultiplierDesignationType:
                      associationContribution.bonusMultiplierDesignationType,
                    monthlyIncentive: BountyCalculation(
                      'BONUS',
                      associationContribution,
                      Number(bonusUnit)
                    ),
                  },
                })
              }
              disabled={isDisabled}
            >
              確認へ進む
            </PrimaryButton>
          </ContentWrraper>
        </FAQ>
      </Wrraper>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
