import { FC } from 'react';
import { Box, Typography, styled } from '@mui/material';

interface ResultInfoSmallProps {
  leading?: string;
  trailing?: string;
}

const Wrapper = styled(Box)(({ theme }) => {
  const { breakpoints } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    width: '25%',
    padding: 16,
    borderWidth: 0,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.2)',

    '&:first-of-type': {
      borderLeftWidth: 0,
    },

    [breakpoints.down('sm')]: {
      flexDirection: 'row',
      padding: '21px 16px',
      width: '100%',
      borderLeftWidth: 0,
      justifyContent: 'space-between',
    },
  };
});

const LeadingWrapper = styled(Box)(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',

    [breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  };
});

const Leading = styled(Typography)(({ theme }) => {
  const { typography, palette } = theme;

  return {
    ...typography['body-sub/medium'],
    color: palette.system['text-normal'],
    textAlign: 'left',
  };
});

const TrailingWrapper = styled(Box)(({ theme }) => {
  const { breakpoints } = theme;

  return {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    [breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  };
});

const Trailing = styled(Typography)(({ theme }) => {
  const { typography, palette } = theme;

  return {
    ...typography['body-main/regular'],
    fontSize: 20,
    color: palette.system['text-normal'],
    textAlign: 'right',
  };
});

export const ResultInfoSmall: FC<ResultInfoSmallProps> = ({
  leading,
  trailing,
}) => (
  <Wrapper>
    {leading && (
      <LeadingWrapper>
        <Leading>{leading}</Leading>
      </LeadingWrapper>
    )}
    {trailing && (
      <TrailingWrapper>
        <Trailing>{trailing}</Trailing>
      </TrailingWrapper>
    )}
  </Wrapper>
);
