import type { Association } from '@/types/api/association';
import { UserSecuritiesAccounts } from '@/types/api/userSecuritiesAccounts';

export const associationModel = (data: {
  associationCd?: string;
  associationName?: string;
  stockCode?: string;
  associationType?: number;
  associationRepresentative?: string;
  associationTel?: string;
  associationEmail?: string;
  associationZipcode?: string;
  associationAddressCd?: string;
  deploymentAddress1?: string;
  deploymentAddress2?: string;
  supplementAddress1?: string;
  supplementAddress2?: string;
  investmentDigit?: number;
  dividendProcessingClassification?: string;
  withdrawalSettlementDigit?: number;
  withdrawalAllSettlementUsageClassification?: string;
  withdrawalLessThanSettlementUsageClassification?: string;
  withdrawalAddPurchaseUsageClassification?: string;
  associationStatus?: string;
  dissolutionYmd?: string;
  openedFlg?: boolean;
  listedClassification?: string;
  stockOfficialNameKanji?: string;
  settlementCnt?: number;
  settlementMonth?: number;
}) =>
  ({
    associationCd: data.associationAddressCd || '',
    associationName: data.associationName || '',
    stockCode: data.stockCode || '',
    associationType: data.associationType || 0,
    associationRepresentative: data.associationRepresentative || '',
    associationTel: data.associationTel || '',
    associationEmail: data.associationEmail || '',
    associationZipcode: data.associationZipcode || '',
    associationAddressCd: data.associationAddressCd || '',
    deploymentAddress1: data.deploymentAddress1 || '',
    deploymentAddress2: data.deploymentAddress2 || '',
    supplementAddress1: data.supplementAddress1 || '',
    supplementAddress2: data.supplementAddress2 || '',
    investmentDigit: data.investmentDigit || 0,
    dividendProcessingClassification:
      data.dividendProcessingClassification || '',
    withdrawalSettlementDigit: data.withdrawalSettlementDigit || 0,
    withdrawalAllSettlementUsageClassification:
      data.withdrawalAllSettlementUsageClassification || '',
    withdrawalLessThanSettlementUsageClassification:
      data.withdrawalLessThanSettlementUsageClassification || '',
    withdrawalAddPurchaseUsageClassification:
      data.withdrawalAddPurchaseUsageClassification || '',
    associationStatus: data.associationStatus || '',
    dissolutionYmd: data.dissolutionYmd || '',
    openedFlg: data.openedFlg || false,
    listedClassification: data.listedClassification || '',
    stockOfficialNameKanji: data.stockOfficialNameKanji || '',
    settlementCnt: data.settlementCnt || 0,
    settlementMonth: data.settlementMonth || 0,
  } as Association);

/**
 * userSecuritiesAccountsModel
 * @param data
 */
export const userSecuritiesAccountsModel = (data: {
  branchCode?: string;
  branchName?: string;
  accountNumber?: string;
  fixedFlg?: boolean;
}) =>
  ({
    branchCode: data.branchCode || '',
    branchName: data.branchName || '',
    accountNumber: data.accountNumber || '',
    fixedFlg: data.fixedFlg || false,
  } as UserSecuritiesAccounts);
