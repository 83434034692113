import { PrintPdf } from '@/components/organism/Pdf';
import { Application } from '@/components/pages/Application/Application';
import { Applications } from '@/components/pages/Application/Applications';
import { ApplicationsIndex } from '@/components/pages/Application/ApplicationsIndex';
import { Assets } from '@/components/pages/Asset/Asset';
import { Bonus } from '@/components/pages/Bonus/Bonus';
import { BonusComplete } from '@/components/pages/Bonus/BonusComplete';
import { BonusConfirm } from '@/components/pages/Bonus/BonusConfirm';
import { BonusIndex } from '@/components/pages/Bonus/BonusIndex';
import { BootStrapPage } from '@/components/pages/Bootstrap/BootStrap';
import { Contact } from '@/components/pages/Contact';
import { NotFound } from '@/components/pages/Error/NotFound';
import { ExistingLoginIndex } from '@/components/pages/ExistingLogin/ExistingLoginIndex';
import { ExistingLoginUser } from '@/components/pages/ExistingLogin/ExistingLoginUser';
import { FAQ } from '@/components/pages/FAQ';
import { Guide } from '@/components/pages/Guide/Guide';
import { Home } from '@/components/pages/Home/Home';
import { LoginError } from '@/components/pages/Login/LoginError';
import { LoginIndex } from '@/components/pages/Login/LoginIndex';
import { LoginMail } from '@/components/pages/Login/LoginMail';
import { LoginMailComplete } from '@/components/pages/Login/LoginMailComplete';
import { LoginMailIndex } from '@/components/pages/Login/LoginMailIndex';
import { LoginMfa } from '@/components/pages/Login/LoginMfa';
import { LoginPassword } from '@/components/pages/Login/LoginPassword';
import { LoginPasswordChange } from '@/components/pages/Login/LoginPasswordChange';
import { LoginPasswordComplete } from '@/components/pages/Login/LoginPasswordComplete';
import { LoginPasswordIndex } from '@/components/pages/Login/LoginPasswordIndex';
import { LoginPasswordMfa } from '@/components/pages/Login/LoginPasswordMfa';
import { MyPage } from '@/components/pages/MyPage/MyPage';
import { MyPageAuth } from '@/components/pages/MyPage/MyPageAuth';
import { MyPageId } from '@/components/pages/MyPage/MyPageId';
import { MyPageIdMfa } from '@/components/pages/MyPage/MyPageIdMfa';
import { MyPageIndex } from '@/components/pages/MyPage/MyPageIndex';
import { MyPageLogout } from '@/components/pages/MyPage/MyPageLogout';
import { MyPagePassword } from '@/components/pages/MyPage/MyPagePassword';
import { MyPageSecurities } from '@/components/pages/MyPage/MyPageSecurities';
import { MyPageUser } from '@/components/pages/MyPage/MyPageUser';
import { Part } from '@/components/pages/Part/Part';
import { PartComplete } from '@/components/pages/Part/PartComplete';
import { PartConfirm } from '@/components/pages/Part/PartConfirm';
import { PartIndex } from '@/components/pages/Part/PartIndex';
import { Pay } from '@/components/pages/Pay/Pay';
import { PayComplete } from '@/components/pages/Pay/PayComplete';
import { PayConfirm } from '@/components/pages/Pay/PayConfirm';
import { PayIndex } from '@/components/pages/Pay/PayIndex';
import { Recess } from '@/components/pages/Recess/Recess';
import { RecessComplete } from '@/components/pages/Recess/RecessComplete';
import { RecessConfirm } from '@/components/pages/Recess/RecessConfirm';
import { RecessIndex } from '@/components/pages/Recess/RecessIndex';
import { Report } from '@/components/pages/Report/Report';
import { Resume } from '@/components/pages/Resume/Resume';
import { ResumeComplete } from '@/components/pages/Resume/ResumeComplete';
import { ResumeConfirm } from '@/components/pages/Resume/ResumeConfirm';
import { ResumeIndex } from '@/components/pages/Resume/ResumeIndex';
import { Service } from '@/components/pages/Service/Service';
import { SignUpAccount } from '@/components/pages/SignUp/SignUpAccount';
import { SignUpContribution } from '@/components/pages/SignUp/SignUpContribution';
import { SignUpContributionComplete } from '@/components/pages/SignUp/SignUpContributionComplete';
import { SignUpContributionConfirm } from '@/components/pages/SignUp/SignUpContributionConfirm';
import { SignUpContributionIndex } from '@/components/pages/SignUp/SignUpContributionIndex';
import { SignUpIndex } from '@/components/pages/SignUp/SignUpIndex';
import { SignUpMail } from '@/components/pages/SignUp/SignUpMail';
import { SignUpMfa } from '@/components/pages/SignUp/SignUpMfa';
import { SignUpUser } from '@/components/pages/SignUp/SignUpUser';
import { SignUpUserComplete } from '@/components/pages/SignUp/SignUpUserComplete';
import { SignUpUserConfirm } from '@/components/pages/SignUp/SignUpUserConfirm';
import { SignUpUserIndex } from '@/components/pages/SignUp/SignUpUserIndex';
import { Simulation } from '@/components/pages/Simulation/Simulation';
import { Structure } from '@/components/pages/Structure/Structure';
import { Withdrawal } from '@/components/pages/Withdrawal/Withdrawal';
import { WithdrawalComplete } from '@/components/pages/Withdrawal/WithdrawalComplete';
import { WithdrawalConfirm } from '@/components/pages/Withdrawal/WithdrawalConfirm';
import { WithdrawalIndex } from '@/components/pages/Withdrawal/WithdrawalIndex';
import { WithdrawalInput } from '@/components/pages/Withdrawal/WithdrawalInput';
import { Layout } from '@/components/templates/Layout';
import { LoginLayout } from '@/components/templates/LoginLayout';

import { Navigate } from 'react-router-dom';

import { Path } from './path';

export const routes = {
  public: [
    /**
     * 新規入会
     */
    {
      path: '/signup',
      element: <LoginLayout />,
      children: [
        /**
         * トップ
         */
        {
          index: true,
          element: <SignUpIndex />,
        },
        /**
         * アカウント登録
         */
        {
          path: 'account',
          element: <SignUpAccount />,
        },
        /**
         * メールアドレス確認
         */
        {
          path: 'mail',
          element: <SignUpMail />,
        },
        /**
         * 多要素認証
         */
        {
          path: 'mfa',
          element: <SignUpMfa />,
        },
        /**
         * 会員情報入力
         */
        {
          path: 'user',
          element: <SignUpUser />,
          children: [
            /**
             * 入力画面
             */
            {
              index: true,
              element: <SignUpUserIndex />,
            },
            /**
             * 確認
             */
            {
              path: 'confirm',
              element: <SignUpUserConfirm />,
            },
            /**
             * 完了
             */
            {
              path: 'complete',
              element: <SignUpUserComplete />,
            },
          ],
        },
        /**
         * 拠出設定
         */
        {
          path: 'contribution',
          element: <SignUpContribution />,
          children: [
            /**
             * 入力画面
             */
            {
              index: true,
              element: <SignUpContributionIndex />,
            },
            /**
             * 確認
             */
            {
              path: 'confirm',
              element: <SignUpContributionConfirm />,
            },
            /**
             * 完了
             */
            {
              path: 'complete',
              element: <SignUpContributionComplete />,
            },
          ],
        },
      ],
    },

    /**
     * ログイン
     */
    {
      path: '/login',
      element: <LoginLayout />,
      children: [
        /**
         * ログイントップ
         */
        {
          index: true,
          element: <LoginIndex />,
        },
        /**
         * 多要素認証
         */
        {
          path: 'mfa',
          element: <LoginMfa />,
        },
        /**
         * メールアドレスを忘れた場合
         */
        {
          path: 'mail',
          element: <LoginMail />,
          children: [
            /**
             * 本人確認
             */
            {
              index: true,
              element: <LoginMailIndex />,
            },
            /**
             * 完了
             */
            {
              path: 'complete',
              element: <LoginMailComplete />,
            },
          ],
        },
        /**
         * パスワードを忘れた場合
         */
        {
          path: 'password',
          element: <LoginPassword />,
          children: [
            /**
             * 本人確認
             */
            {
              index: true,
              element: <LoginPasswordIndex />,
            },
            /**
             * 多要素認証画面
             */
            {
              path: 'mfa',
              element: <LoginPasswordMfa />,
            },
            /**
             * 変更
             */
            {
              path: 'change',
              element: <LoginPasswordChange />,
            },
            /**
             * 完了
             */
            {
              path: 'complete',
              element: <LoginPasswordComplete />,
            },
          ],
        },
      ],
    },
    /**
     * 既存新規ログイン
     */
    {
      path: '/existing-login/user',
      element: <LoginLayout />,
      children: [
        /**
         * 会員登録
         */
        {
          index: true,
          element: <ExistingLoginUser />,
        },
        /**
         * ログイントップ
         */
        {
          path: ':token',
          element: <ExistingLoginIndex />,
        },
      ],
    },
    /**
     * BootStrapPage
     */
    { path: '/', element: <BootStrapPage /> },
    /**
     * NotFound
     */
    { path: Path.NOT_FOUND, element: <NotFound /> },
    /**
     * Error
     */
    {
      // localhost/error
      path: Path.ERROR,
      element: <LoginLayout />,
      children: [
        /**
         * エラー起こった場合
         */
        {
          index: true,
          element: <LoginError />,
        },
      ],
    },
  ],
  private: [
    /**
     * Test
     */
    {
      path: '/print',
      element: <PrintPdf />,
    },
    /**
     * トップ
     */
    {
      path: Path.HOME,
      element: <Layout isOption />,
      children: [
        /**
         * ホーム
         */
        { index: true, element: <Home /> },
        /**
         * 資産状況
         */
        { path: Path.ASSETS, element: <Assets /> },
        /**
         * 口数変更（給与）
         */
        {
          path: Path.PAY,
          element: <Pay />,
          children: [
            { index: true, element: <PayIndex /> },
            /**
             * 確認
             */
            {
              path: 'confirm',
              element: <PayConfirm />,
            },
            /**
             * 完了
             */
            {
              path: 'complete',
              element: <PayComplete />,
            },
          ],
        },
        /**
         * 口数変更（賞与）
         */
        {
          path: Path.BONUS,
          element: <Bonus />,
          children: [
            { index: true, element: <BonusIndex /> },
            /**
             * 確認
             */
            {
              path: 'confirm',
              element: <BonusConfirm />,
            },
            /**
             * 完了
             */
            {
              path: 'complete',
              element: <BonusComplete />,
            },
          ],
        },
        /**
         * 一部引出
         */
        {
          path: Path.PART,
          element: <Part />,
          children: [
            { index: true, element: <PartIndex /> },
            /**
             * 確認
             */
            {
              path: 'confirm',
              element: <PartConfirm />,
            },
            /**
             * 完了
             */
            {
              path: 'complete',
              element: <PartComplete />,
            },
          ],
        },
        /**
         * 休止
         */
        {
          path: Path.RECESS,
          element: <Recess />,
          children: [
            { index: true, element: <RecessIndex /> },
            /**
             * 確認
             */
            {
              path: 'confirm',
              element: <RecessConfirm />,
            },
            /**
             * 完了
             */
            {
              path: 'complete',
              element: <RecessComplete />,
            },
          ],
        },
        /**
         * 再開
         */
        {
          path: Path.RESUME,
          element: <Resume />,
          children: [
            { index: true, element: <ResumeIndex /> },
            /**
             * 確認
             */
            {
              path: 'confirm',
              element: <ResumeConfirm />,
            },
            /**
             * 完了
             */
            {
              path: 'complete',
              element: <ResumeComplete />,
            },
          ],
        },
        /**
         * 退会
         */
        {
          path: Path.WITHDRAWAL,
          element: <Withdrawal />,
          children: [
            { index: true, element: <WithdrawalIndex /> },
            /**
             * 警告
             */
            {
              path: 'input',
              element: <WithdrawalInput />,
            },
            /**
             * 確認
             */
            {
              path: 'confirm',
              element: <WithdrawalConfirm />,
            },
            /**
             * 完了
             */
            {
              path: 'complete',
              element: <WithdrawalComplete />,
            },
          ],
        },
        /**
         * 申請一覧
         */
        {
          path: Path.APPLICATIONS,
          element: <Applications />,
          children: [
            /**
             * 一覧トップ
             */
            { index: true, element: <ApplicationsIndex /> },
            /**
             * 詳細
             */
            { path: ':id', element: <Application /> },
          ],
        },
        /**
         * シミュレーション
         */
        { path: Path.SIMULATION, element: <Simulation /> },
        /**
         * 電子交付サービス
         */
        { path: Path.REPORT, element: <Report /> },
        /**
         * マイページ
         */
        {
          path: Path.MYPAGE,
          element: <MyPage />,
          children: [
            /**
             * トップ
             */
            { index: true, element: <MyPageIndex /> },
            /**
             * 会員情報変更
             */
            { path: 'user', element: <MyPageUser /> },
            /**
             * 証券口座情報変更
             */
            { path: 'securities', element: <MyPageSecurities /> },
            /**
             * ログインID変更
             */
            { path: 'id', element: <MyPageId /> },
            /**
             * ログインID変更MFA
             */
            { path: 'id/mfa', element: <MyPageIdMfa /> },
            /**
             * パスワード変更
             */
            { path: 'password', element: <MyPagePassword /> },
            /**
             * パスワード認証
             */
            { path: 'auth', element: <MyPageAuth /> },
            /**
             * ログアウト
             */
            { path: 'logout', element: <MyPageLogout /> },
          ],
        },
        /**
         * サービス
         */
        { path: Path.SERVICE, element: <Service /> },
        /**
         * よくあるご質問
         */
        { path: Path.FAQ, element: <FAQ /> },
        /**
         * お問い合わせ
         */
        { path: Path.CONTACT, element: <Contact /> },
        /**
         * ご利用ガイド
         */
        { path: Path.GUIDE, element: <Guide /> },
        /**
         * 持株会の仕組み
         */
        { path: Path.STRUCTURE, element: <Structure /> },
      ],
    },
    { path: '*', element: <Navigate to="." /> },
    /**
     * NotFound
     */
    { path: Path.NOT_FOUND, element: <NotFound /> },
    /**
     * Error
     */
    {
      // localhost/error
      path: Path.ERROR,
      element: <LoginLayout />,
      children: [
        /**
         * エラー起こった場合
         */
        {
          index: true,
          element: <LoginError />,
        },
      ],
    },
  ],
};
