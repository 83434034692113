import { FC } from 'react';
import { theme, BREAKPOINT } from '@/theme/theme';
import { Box, Typography, styled, useMediaQuery } from '@mui/material';

interface FundingInfoGroupProps {
  title: string;
  subtitle?: string;
  numberOfWords: string;
  amountTitle: string;
  amount: string;
  monthlyIncentive: string;
}

const Wrapper = styled(Box)(() => {
  const { breakpoints, palette } = theme;
  return {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 12,

    [breakpoints.down('sm')]: {
      borderWidth: 0,
      borderBottomWidth: 1,
      borderStyle: 'solid',
      borderColor: palette.system.separator,

      '&:last-of-type': {
        borderBottomWidth: 0,
      },
    },
  };
});

const ItemWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

const ItemTitleWrapper = styled(Box)({
  width: 144,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
});

const ItemTitleText = styled(Typography)(() => {
  const { breakpoints, typography, palette } = theme;

  return {
    ...typography['body-main/bold'],
    color: palette.system['text-normal'],

    [breakpoints.down('sm')]: {
      ...typography['body-sub/bold'],
    },
  };
});

const ItemTitleSubText = styled(Typography)(() => {
  const { typography, palette } = theme;

  return {
    ...typography['caption/regular'],
    color: palette.system['text-light'],
  };
});

const ItemValueWrapper = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
});

const ValueText = styled(Typography)(() => {
  const { typography, palette } = theme;

  return {
    ...typography['body-main/regular'],
    color: palette.system['text-normal'],
  };
});

export const FundingInfoGroup: FC<FundingInfoGroupProps> = ({
  title,
  subtitle,
  numberOfWords,
  amountTitle,
  amount,
  monthlyIncentive,
}) => {
  const isPc = useMediaQuery(() => theme.breakpoints.up(BREAKPOINT));
  return (
    <Wrapper>
      <ItemWrapper>
        <ItemTitleWrapper>
          <ItemTitleText>{title}</ItemTitleText>
          {subtitle && <ItemTitleSubText>{subtitle}</ItemTitleSubText>}
        </ItemTitleWrapper>
      </ItemWrapper>
      <ItemWrapper>
        <ItemValueWrapper>
          <ValueText>口数 {numberOfWords}口</ValueText>
          {isPc ? (
            <ValueText>
              {amountTitle} {`${amount}円`} {`(奨励金${monthlyIncentive}円)`}
            </ValueText>
          ) : (
            <>
              <ValueText>
                {amountTitle} {`${amount}円`}
              </ValueText>
              <ValueText paddingBottom={title === '給与' ? '16px' : '0px'}>
                奨励金 {`${monthlyIncentive}円`}
              </ValueText>
            </>
          )}
        </ItemValueWrapper>
      </ItemWrapper>
    </Wrapper>
  );
};
