import { TextButton } from '@/components/atoms/Buttons/TextButton';
import { TextInput } from '@/components/atoms/Input/TextInput';
import { LoginTitleWithUnderline } from '@/components/molecules/Login/LoginTitleWithUnderline';
import { LoginWideBox } from '@/components/molecules/Login/LoginWideBox';
import { SignUpUpper } from '@/components/organism/SignUp/SignUpUpper';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { BREAKPOINT } from '@/theme/theme';
import { Box, styled, Typography } from '@mui/material';
import { FC } from 'react';

const SignUpLower = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0px 32px',
  gap: '40px',
  width: '100%',
  [theme.breakpoints.down(BREAKPOINT)]: {
    padding: '32px 16px',
    gap: '32px',
  },
}));

const SignUpLowerInner = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: 0,
  gap: '24px',
  width: '100%',
});

const NormalTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography['body-main/regular'],
  color: theme.palette.system['text-normal'],
  textAlign: 'left',
  '& span': {
    ...theme.typography['body-main/bold'],
    color: theme.palette.secondary.main,
    display: 'block',
  },
}));

const MailBox = styled(Box)(({ theme }) => ({
  background: theme.palette.system['background-light'],
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '24px',
  gap: '24px',
  width: '100%',
  height: '260px',
}));

const MailTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography['body-main/bold'],
  fontFamily: 'Noto Sans JP',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '150%',
}));

const MailText = styled(Typography)(({ theme }) => ({
  ...theme.typography['body-sub/regular'],
  color: theme.palette.system['text-light'],
}));

const MailFormBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  gap: '16px',
});

const MailFormt = styled(Typography)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: 0,
  gap: '24px',
  width: '100%',
});

const MailFormLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography['body-sub/bold'],
  color: theme.palette.system['text-normal'],
  height: '40px',
  display: 'flex',
  alignItems: 'center',
}));

const MailInputArea = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: 0,
  gap: '8px',
});

const MailFormDescription = styled(Typography)(({ theme }) => ({
  ...theme.typography['caption/regular'],
  color: theme.palette.system['text-light'],
}));

export const SignUpMail: FC = () => {
  useHelmetHandler({
    title: '本人メールアドレス確認',
  });

  return (
    <LoginWideBox>
      <SignUpUpper isSignUp flowOn={0} />
      <SignUpLower>
        <SignUpLowerInner />
        <LoginTitleWithUnderline isLogin title="本人メールアドレス確認" />
        <NormalTypography>
          入力されたメールアドレス宛にメールを送信しました。
          <span>
            メール本文のURLをクリックして、メールアドレス確認を行ってください。
          </span>
        </NormalTypography>
        <MailBox>
          <MailTitle>※メールが届かない場合</MailTitle>
          <MailText>
            メールが届かない場合は、下のフォームにメールアドレスを再入力し、「再送信する」ボタンを押してください。
          </MailText>
          <MailFormBox>
            <MailFormt>
              <MailFormLabel>メールアドレス</MailFormLabel>
              <MailInputArea>
                <TextInput
                  name="mail"
                  //   onChange={handleChangeLoginPassword}
                />
                <MailFormDescription>
                  ※メールアドレスはログインIDとして使用されます。
                </MailFormDescription>
              </MailInputArea>
            </MailFormt>
            <TextButton>再送信する</TextButton>
          </MailFormBox>
        </MailBox>
      </SignUpLower>
    </LoginWideBox>
  );
};
