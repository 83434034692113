import { FC } from 'react';
import { Procedures } from '@/components/organism/Home/Procedures';
import { Eventsmemberdeadlines } from '@/types/api/eventsmemberdeadlines';

type Props = {
  eventsmemberdeadlines: Eventsmemberdeadlines;
};

/**
 * ProceduresFeature
 * @param isListedClassification
 * @constructor
 */
export const ProceduresFeature: FC<Props> = ({ eventsmemberdeadlines }) => (
  <Procedures eventsmemberdeadlines={eventsmemberdeadlines} />
);
