import { Box, styled } from '@mui/material';
import { FC } from 'react';
import { ReactComponent as CautionIcon } from '@/assets/caution.svg';
import { BREAKPOINT } from '@/theme/theme';

interface InputProps {
  // Errorメッセージ（改行に対応するため配列で渡す）
  errorMessage?: string[];
  isBorder?: boolean;
}

const ErrBox = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '8px',
  gap: '8px',
  border: '1px solid',
  borderColor: theme.palette.states.error,
  borderRadius: '4px',
  width: '100%',
  [theme.breakpoints.down(BREAKPOINT)]: {
    alignItems: 'flex-start',
    padding: 0,
    gap: '4px',
    border: 'none',
  },
}));

const IconWrapper = styled('p')({
  width: '24px',
  height: '24px',
  margin: 0,
});

const MessageBox = styled('p')(({ theme }) => ({
  ...theme.typography['body-main/medium'],
  color: theme.palette.states.error,
  margin: 0,
  textAlign: 'left',
}));

const Message = styled('span')({
  display: 'block',
});

export const ErrMessageWithIcon: FC<InputProps> = ({
  errorMessage,
  isBorder = false,
}) => (
  <ErrBox
    sx={{
      border: isBorder ? '1px solid palette.states.error' : 'none',
    }}
  >
    <IconWrapper>
      <CautionIcon />
    </IconWrapper>
    <MessageBox>
      {errorMessage?.map((message) => (
        <Message key={message}>{message}</Message>
      ))}
    </MessageBox>
  </ErrBox>
);
