import { UserContribution } from '@/types/api/userContribution';

export const ConvertUserContribution = (
  data:
    | {
        associationCd?: string;
        memberCd?: string;
        contributionStartDate?: string;
        monthlyUnit?: number;
        monthlyRecessStartDate?: string;
        monthlyRecessEndDate?: string;
        bonusUnit?: number;
        bonusRecessClassification?: string;
        bonusRate?: number;
      }
    | undefined
) =>
  data
    ? ({
        associationCd: data.associationCd || '',
        memberCd: data.memberCd || '',
        contributionStartDate: data.contributionStartDate || '',
        monthlyUnit: data.monthlyUnit || 0,
        monthlyRecessStartDate: data.monthlyRecessStartDate || '',
        monthlyRecessEndDate: data.monthlyRecessEndDate || '',
        bonusUnit: data.bonusUnit || 0,
        bonusRecessClassification: data.bonusRecessClassification || '',
        bonusRate: data.bonusRate || 0,
      } as UserContribution)
    : undefined;
