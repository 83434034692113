/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AssociationsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * 【API-A-1001】持株会（事務局）情報
   * 持株会（事務局）情報を取得する
   *
   * @returns any association response
   * @throws ApiError
   */
  public findAssociationById(): CancelablePromise<{
    /**
     * 持株会コード
     */
    associationCd?: string;
    /**
     * 持株会名
     */
    associationName?: string;
    /**
     * 銘柄コード
     */
    stockCode?: string;
    /**
     * 持株会種類
     */
    associationType?: number;
    /**
     * 担当者氏名（漢字）
     */
    associationRepresentative?: string;
    /**
     * 電話番号（番号）
     */
    associationTel?: string;
    /**
     * メールアドレス
     */
    associationEmail?: string;
    /**
     * 郵便番号
     */
    associationZipcode?: string;
    /**
     * 住所コード
     */
    associationAddressCd?: string;
    /**
     * 展開住所１（漢字）
     */
    deploymentAddress1?: string;
    /**
     * 展開住所２（漢字）
     */
    deploymentAddress2?: string;
    /**
     * 補足住所１（漢字）
     */
    supplementAddress1?: string;
    /**
     * 補足住所２（漢字）
     */
    supplementAddress2?: string;
    /**
     * 投資桁数
     */
    investmentDigit?: number;
    /**
     * 配当金処理区分
     */
    dividendProcessingClassification?: string;
    /**
     * 退会精算桁数
     */
    withdrawalSettlementDigit?: number;
    /**
     * 退会全部精算利用区分
     */
    withdrawalAllSettlementUsageClassification?: string;
    /**
     * 退会未満株精算利用区分
     */
    withdrawalLessThanSettlementUsageClassification?: string;
    /**
     * 退会買増利用区分
     */
    withdrawalAddPurchaseUsageClassification?: string;
    /**
     * 持株会ステータス
     */
    associationStatus?: string;
    /**
     * 解散年月日
     */
    dissolutionYmd?: string;
    /**
     * 事務局開通済フラグ
     */
    openedFlg?: boolean;
    /**
     * 上場区分
     */
    listedClassification?: string;
    /**
     * 銘柄名正式漢字
     */
    stockOfficialNameKanji?: string;
    /**
     * 決算回数
     */
    settlementCnt?: number;
    /**
     * 決算月
     */
    settlementMonth?: number;
    /**
     * 市場種別
     * - TOKYO:東京
     * - NAGOYA:名古屋
     *
     */
    marketType?: 'TOKYO' | 'NAGOYA';
    /**
     * シミュレーション可否フラグ
     */
    allowSimulationFlg?: boolean;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/associations',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-A-1002】持株会（事務局）拠出情報取得
   * 持株会（事務局）拠出情報取得を取得する
   *
   * @returns any association contribution response
   * @throws ApiError
   */
  public findAssociationContribution(): CancelablePromise<{
    /**
     * 持株会コード
     */
    associationCd?: string;
    /**
     * １口当たりの積立金額
     */
    unitAmount?: number;
    /**
     * 月例拠出有無区分
     */
    monthlyUnitClassification?: string;
    /**
     * 月例拠出上限口数
     */
    maxMonthlyUnit?: number;
    /**
     * 月例買付日算出区分
     */
    monthlyPurchaceCalculationClassification?: string;
    /**
     * 賞与拠出有無区分
     */
    bonusUnitClassification?: string;
    /**
     * 賞与拠出上限口数
     */
    maxBonusUnit?: number;
    /**
     * 賞与算出方式
     * - NONE：初期値
     * - AMONT：定額
     * - RATE：定倍
     *
     */
    bonusCalculationMethod?: 'NONE' | 'AMONT' | 'RATE';
    /**
     * 賞与倍数
     */
    bonusMultiple?: number;
    /**
     * 賞与月別指定1
     */
    bonusMonth1?: string;
    /**
     * 賞与月別指定2
     */
    bonusMonth2?: string;
    /**
     * 賞与月別指定3
     */
    bonusMonth3?: string;
    /**
     * 賞与月別指定4
     */
    bonusMonth4?: string;
    /**
     * 賞与月別指定5
     */
    bonusMonth5?: string;
    /**
     * 賞与月別指定6
     */
    bonusMonth6?: string;
    /**
     * 賞与月別指定7
     */
    bonusMonth7?: string;
    /**
     * 賞与月別指定8
     */
    bonusMonth8?: string;
    /**
     * 賞与月別指定9
     */
    bonusMonth9?: string;
    /**
     * 賞与月別指定10
     */
    bonusMonth10?: string;
    /**
     * 賞与月別指定11
     */
    bonusMonth11?: string;
    /**
     * 賞与月別指定12
     */
    bonusMonth12?: string;
    /**
     * 月例奨励金拠出方式
     * - NONE：初期値
     * - MONTHLY：月例･賞与拠出時
     * - HARF_YEAR：半年間拠出金累計
     * - YEAR：一年間拠出金累計
     * - COMMISSION：約定手数料按分
     * - INCENTIVE：段階別奨励金
     *
     */
    monthlyIncentiveMethod?:
      | 'NONE'
      | 'MONTHLY'
      | 'HARF_YEAR'
      | 'YEAR'
      | 'COMMISSION'
      | 'INCENTIVE';
    /**
     * 月例奨励金算出種類
     * - NONE：初期値
     * - AMONT：定率
     * - RATE：定額
     *
     */
    monthlyIncentiveCalculationType?: 'NONE' | 'AMONT' | 'RATE';
    /**
     * 拠出合計算出基準月
     */
    monthlyIncentiveCalculationBaseMonth?: string;
    /**
     * 月例奨励金比率
     */
    monthlyIncentiveRatio?: number;
    /**
     * 月例奨励金定額
     */
    monthlyIncentiveFixedAmount?: number;
    /**
     * 月例奨励金上限
     */
    monthlyIncentiveUpperLimit?: number;
    /**
     * 賞与奨励金算出種類
     * - NONE：初期値
     * - AMONT：定率
     * - RATE：定額
     *
     */
    bonusIncentiveCalculationType?: 'NONE' | 'AMONT' | 'RATE';
    /**
     * 賞与奨励金比率
     */
    bonusIncentiveRatio?: number;
    /**
     * 賞与奨励金定額
     */
    bonusIncentiveFixedAmount?: number;
    /**
     * 賞与奨励金上限
     */
    bonusIncentiveUpperLimit?: number;
    /**
     * 賞与積立有無区分
     * - PURCHASE：買付
     * - RESERVE：積立
     * - NONE：なし
     *
     */
    bonusReserveClassification?: 'PURCHASE' | 'RESERVE' | 'NONE';
    /**
     * 賞与倍率指定種別
     * - UNIT_NUMBER：口数指定
     * - MAGNIFICATION：倍率指定
     * - FIXED_MAGNIFICATION：定倍指定
     *
     */
    bonusMultiplierDesignationType?:
      | 'UNIT_NUMBER'
      | 'MAGNIFICATION'
      | 'FIXED_MAGNIFICATION';
    /**
     * 賞与倍率詳細
     */
    bonusMultiplierDetails?: Array<number>;
    /**
     * 月例買付日利用種別
     * - STAR：STARⅣの買付日を使用する
     * - WEB：事務局で個別設定する
     *
     */
    monthlyPurchaseDateUsageType?: 'STAR' | 'WEB';
    /**
     * 月例奨励金特殊条件
     */
    monthlyIncentiveSpecialConditions?: string;
    /**
     * 賞与奨励金特殊条件
     */
    bonusIncentiveSpecialConditions?: string;
    /**
     * 口数変更月別指定1
     */
    unitMonth1?: string;
    /**
     * 口数変更月別指定2
     */
    unitMonth2?: string;
    /**
     * 口数変更月別指定3
     */
    unitMonth3?: string;
    /**
     * 口数変更月別指定4
     */
    unitMonth4?: string;
    /**
     * 口数変更月別指定5
     */
    unitMonth5?: string;
    /**
     * 口数変更月別指定6
     */
    unitMonth6?: string;
    /**
     * 口数変更月別指定7
     */
    unitMonth7?: string;
    /**
     * 口数変更月別指定8
     */
    unitMonth8?: string;
    /**
     * 口数変更月別指定9
     */
    unitMonth9?: string;
    /**
     * 口数変更月別指定10
     */
    unitMonth10?: string;
    /**
     * 口数変更月別指定11
     */
    unitMonth11?: string;
    /**
     * 口数変更月別指定12
     */
    unitMonth12?: string;
    /**
     * 入会月別指定1
     */
    newUserMonth1?: string;
    /**
     * 入会月別指定2
     */
    newUserMonth2?: string;
    /**
     * 入会月別指定3
     */
    newUserMonth3?: string;
    /**
     * 入会月別指定4
     */
    newUserMonth4?: string;
    /**
     * 入会月別指定5
     */
    newUserMonth5?: string;
    /**
     * 入会月別指定6
     */
    newUserMonth6?: string;
    /**
     * 入会月別指定7
     */
    newUserMonth7?: string;
    /**
     * 入会月別指定8
     */
    newUserMonth8?: string;
    /**
     * 入会月別指定9
     */
    newUserMonth9?: string;
    /**
     * 入会月別指定10
     */
    newUserMonth10?: string;
    /**
     * 入会月別指定11
     */
    newUserMonth11?: string;
    /**
     * 入会月別指定12
     */
    newUserMonth12?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/associations/contributions',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-A-1003】持株会（事務局）イベント締日一覧取得
   * 持株会（事務局）イベント締日一覧を取得する
   *
   * @returns any association event memberdeadlines response
   * @throws ApiError
   */
  public findAssociationEventMemberDeadlines(): CancelablePromise<{
    eventmemberdeadlines?: Array<{
      /**
       * 申請種別
       * - MONTHLY_UNIT: 口数変更
       * - BONUS_UNIT: 口数変更（非推奨）
       * - PART: 一部引出
       * - RECESS: 休会
       * - RESUME: 再開
       * - WITHDRAWAL: 退会
       * - ADMISSION: 新規会員登録
       * - USER: 会員情報変更
       *
       */
      applicationType?:
        | 'MONTHLY_UNIT'
        | 'BONUS_UNIT'
        | 'PART'
        | 'RECESS'
        | 'RESUME'
        | 'WITHDRAWAL'
        | 'ADMISSION'
        | 'USER';
      memberDeadlines?: Array<string>;
    }>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/associations/eventsmemberdeadlines',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-A-1004】持株会（事務局）問い合わせ情報取得
   * 持株会（事務局）問い合わせ情報取得を取得する
   *
   * @returns any association contact response
   * @throws ApiError
   */
  public findAssociationContact(): CancelablePromise<{
    /**
     * 持株会コード
     */
    associationCd?: string;
    /**
     * 担当者部署
     */
    inquiryDepartment?: string;
    /**
     * 担当者氏名（漢字）
     */
    inquiryRepresentative?: string;
    /**
     * 電話番号（内線）
     */
    inquiryTelExtension?: string;
    /**
     * 電話番号（外線）
     */
    inquiryTelOutsideLine?: string;
    /**
     * email
     */
    inquiryTelEmail?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/associations/contacts',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-A-1005】持株会（事務局）振込先口座情報取得
   * 持株会（事務局）振込先口座情報を取得する
   *
   * @returns any office association account response
   * @throws ApiError
   */
  public findOfficeAssociationAccount(): CancelablePromise<{
    /**
     * 金融機関名
     */
    financialInstitutionName?: string;
    /**
     * 支店コード
     */
    bankBranchCd?: string;
    /**
     * 支店名
     */
    bankBranchName?: string;
    /**
     * アカウント種別
     * - SAVINGS: 普通
     * - CURRENT: 当座
     * - OTHER: 貯蓄
     *
     */
    accountType?: 'SAVINGS' | 'CURRENT' | 'OTHER';
    /**
     * 口座名義
     */
    accountName?: string;
    /**
     * 口座番号
     */
    accountNumber?: string;
    /**
     * 金融機関種類
     * - YUCHO: ゆうちょ銀行
     * - OTHER: ゆうちょ銀行以外
     *
     */
    institutionType?: 'YUCHO' | 'OTHER';
    /**
     * 記号（ゆうちょ銀行）
     */
    accountYuchoSymbol?: string;
    /**
     * 番号（ゆうちょ銀行）
     */
    accountYuchoNumber?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/associations/officeassociationsaccounts',
      errors: {
        400: `bad request`,
      },
    });
  }
}
