import { useEffect, useState } from 'react';

const MOBILE_AGENT = /Android|BlackBerry|iPhone|iPad|iPod/i;
const IOS_AGENT = /iPhone|iPad|iPod/i;
const ANDROID_AGENT = /Android|BlackBerry/i;

export const useDeviceDetect = () => {
  const [isUAMobile, setIsUAMobile] = useState(false);
  const [isUAIos, setIsUAIos] = useState(false);
  const [isUAAndroid, setIsUAAndroid] = useState(false);

  useEffect(() => {
    if (window) {
      const userAgent =
        typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
      setIsUAIos(Boolean(userAgent.match(IOS_AGENT)));
      setIsUAAndroid(Boolean(userAgent.match(ANDROID_AGENT)));
      const mobile = Boolean(userAgent.match(MOBILE_AGENT));
      setIsUAMobile(mobile);
    }
  }, []);

  return { isUAMobile, isUAIos, isUAAndroid };
};
