import useSWR from 'swr';
import { appClient } from '@/services';
import { operations } from '@/types/api/openapi/schema';
import { ConvertAssociationContribution } from '@/services/convert/associationContribution';

export type AssociationContributionRes =
  operations['findAssociationContribution']['responses']['200']['content']['application/json'];

export type AssociationContributionError =
  operations['findAssociationContribution']['responses']['400']['content']['application/json'];

/**
 * useFindAssociationContribution
 *【API-A-1002】持株会（事務局）拠出情報取得
 * 持株会（事務局）拠出情報取得を取得する
 * @param initAssociationCd
 */
export const useFindAssociationContribution = () => {
  // 【API-A-1002】持株会（事務局）拠出情報取得
  const { data, error } = useSWR<
    AssociationContributionRes,
    AssociationContributionError
  >('/api/associations/{associationCd}/contributions', () =>
    appClient.associations.findAssociationContribution()
  );

  return {
    isLoading: !data && !error,
    data: ConvertAssociationContribution(data),
    error,
  };
};
