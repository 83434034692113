import { BREAKPOINT } from '@/theme/theme';
import { styled } from '@mui/material';
import { FC } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ReactComponent as QuestionMark } from '../../../assets/question.svg';

const SubTitle = styled('h5')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography.h5,
    margin: '0px',
  };
});

const WellVisitedFAQs = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '100%',
    padding: '24px 32px 18px 32px',
    backgroundColor: '#F7F6F6',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    [breakpoints.down(BREAKPOINT)]: {
      padding: '24px 16px 24px',
      borderRadius: '0px',
      whiteSpace: 'nowrap',
      overflowX: 'scroll',
    },
  };
});

const QuestionsWrap = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
    [breakpoints.down(BREAKPOINT)]: {
      flexWrap: 'nowrap',
    },
  };
});

const QuestionWrap = styled('div')({
  padding: '10px 24px',
  backgroundColor: '#FFFFFF',
  borderRadius: '22px',
  display: 'flex',
  gap: '8px',
  marginBottom: '8px',
  cursor: 'pointer',
});

const Question = styled('span')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-main/regular'],
  };
});

const questions = [
  '口数は何口まで申請できますか？',
  '引出とはどういう意味ですか？',
  '休止を希望しています。どうすればいいですか？',
  '他の証券会社に口座があるので、そちらの口座に引出を希望しますので手続き方法を教えてください。',
  '口座は何日で開設できますか？',
  'パスワードを変更したい',
  '住所を変更したい',
];

export const SharedContact: FC = () => (
  <WellVisitedFAQs>
    <SubTitle>閲覧数の多いFAQ</SubTitle>
    <QuestionsWrap>
      {questions.map((q) => (
        <AnchorLink
          href={`#${q}`}
          offset="150"
          style={{ textDecoration: 'none', color: '#222222' }}
        >
          <QuestionWrap key={q}>
            <QuestionMark />
            <Question>{q}</Question>
          </QuestionWrap>
        </AnchorLink>
      ))}
    </QuestionsWrap>
  </WellVisitedFAQs>
);
