import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
  /**
   * 項目のラベル
   */
  label: string;
  /**
   * 項目の値
   */
  value: string | number;
  /**
   * 下線の有無
   */
  underline: boolean;
  /**
   * PCかどうか
   */
  isPc?: boolean;
}

interface DlProps {
  /**
   * 子コンポーネント(dt & dd)
   */
  children: ReactNode;
}

interface DtProps {
  /**
   * 項目のラベル
   */
  children: ReactNode;
}

interface DdProps {
  /**
   * 項目の値
   */
  children: ReactNode;
}

const Dd: FC<DdProps> = ({ children }) => (
  <Typography
    component="dd"
    variant="body-main/regular"
    sx={{ color: 'system.text-normal' }}
  >
    {children}
  </Typography>
);

const Dt: FC<DtProps> = ({ children }) => (
  <Typography
    component="dt"
    variant="body-sub/bold"
    sx={{ color: 'system.text-normal', minWidth: '144px' }}
  >
    {children}
  </Typography>
);

const Dl: FC<DlProps> = ({ children }) => (
  <Box
    component="dl"
    sx={{
      width: '100%',
      height: '41px',
      minWidth: '343px',
      margin: 0,
      display: 'flex',
    }}
  >
    {children}
  </Box>
);

export const SecuritiesAccountDlContainer: FC<Props> = (props) => {
  const { label, value, underline, isPc } = props;

  return (
    <Box
      sx={{
        width: isPc ? '40%' : '100%',
        maxHeight: '69px',
        flexGrow: 1,
        borderBottom: underline ? 1 : 0,
        borderColor: 'system.separator-light',
      }}
    >
      <Dl>
        <Dt>{label}</Dt>
        <Dd>{value}</Dd>
      </Dl>
    </Box>
  );
};
