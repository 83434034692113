import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { MyPagePasswordOrganism } from '@/components/organism/MyPage/MyPagePassword';
import { MyPagePasswordFormValues as FormValues } from '@/types/components/MyPagePassword';
import { appClient } from '@/services';
import { Path } from '@/constants/Router/path';
import { useLoader, useSnackbarMessage } from '@/hooks';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { useMaskoutUser } from './hooks/useMaskoutUser';

export const MyPagePassword: FC = () => {
  const navigate = useNavigate();
  const { password } = useMaskoutUser();
  const { showMessage } = useSnackbarMessage();
  const { handleLoaderOpen, handleLoaderClose } = useLoader();
  useHelmetHandler({
    title: 'マイページ パスワード変更',
  });

  /**
   * handleSubmit
   * @param data
   */
  const handleSubmit = async (data: FormValues) => {
    try {
      handleLoaderOpen();
      await appClient.users.updateUserNewpassword({
        oldpassword: password,
        newpassword: data.repassword,
      });
      handleLoaderClose();
      showMessage({ open: true, messages: ['パスワードを変更しました'] });
      navigate(`${Path.MYPAGE}`);
    } catch (e) {
      handleLoaderClose();
      console.error(e);
      showMessage({
        open: true,
        messageType: 'error',
        messages: ['パスワードを変更に失敗しました'],
      });
    }
  };
  return (
    <MyPagePasswordOrganism
      oldPassword={password}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      handleSubmit={(data) => handleSubmit(data)}
    />
  );
};
