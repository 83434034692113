/* eslint-disable */
import { FC, useEffect, useState } from 'react';
import { useMediaQuery } from '@/hooks';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import {
  Box,
  Typography,
  Input,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { appClient } from '@/services';
import { ConvertAssociationContribution } from '@/services/convert/associationContribution';
import useSWR from 'swr';
import { AssociationContribution } from '@/types/api/associationContribution';
import { numberFormat } from '@/utils/numberFormat';
import {
  Label,
  MuiBox,
  Row,
  SignUpContributionOrganism,
  SubLabel,
  Title,
  Value,
} from '@/components/organism/SignUp/SignUpContribution';
import { useLocation, useNavigate } from 'react-router-dom';
import { Path } from '@/constants/Router/path';
import { BountyCalculation } from '@/utils/calculation';
import { IsNumeric } from '@/utils/stringProcessor';
import { theme } from '@/theme/theme';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

type LocationState = {
  isBonusUnit: boolean;
  monthlyUnit: number;
  bonusUnit: number;
  bonusRate: number;
};

export const SignUpContributionIndex: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationState;
  const { isPc } = useMediaQuery();

  const [isBonusUnit, toggleIsBonusUnit] = useState<boolean>(true);
  const [bonusRate, setBonusRate] = useState<number>(0);
  const [monthlyUnit, setMonthlyUnit] = useState<number>(0);
  const [bonusUnit, setBonusUnit] = useState<number>(0);

  const [associationContribution, setAssociationContribution] =
    useState<AssociationContribution>();

  useHelmetHandler({
    title: '持株会入会登録 拠出設定入力',
  });
  // 【API-A-1002】持株会（事務局）拠出情報取得○○○○○○
  const { data: findAssociationContribution } = useSWR(
    '/api/associations/{associationCd}/contributions',
    () => appClient.associations.findAssociationContribution()
  );

  const { data: associationData } = useSWR(['findAssociation'], () =>
    appClient.associations.findAssociationById()
  );

  useEffect(() => {
    setAssociationContribution(
      ConvertAssociationContribution(findAssociationContribution)
    );
    setBonusRate(findAssociationContribution?.bonusMultiplierDetails?.[0] ?? 0);
  }, [findAssociationContribution]);

  useEffect(() => {
    if (state) {
      setMonthlyUnit(state.monthlyUnit);
      setBonusUnit(state.bonusUnit);
      toggleIsBonusUnit(state.isBonusUnit);
      setBonusRate(state.bonusRate);
    }
  }, [state]);

  useEffect(() => {
    if (
      associationContribution?.bonusReserveClassification === 'NONE' ||
      associationContribution?.bonusUnitClassification === '2'
    ) {
      toggleIsBonusUnit(false);
    }
  }, [associationContribution]);

  const isDisabled = !(Number(monthlyUnit) !== 0);

  if (associationContribution && associationData) {
    return (
      <>
        {associationData.associationName && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '40px',
              marginTop: '32px',
            }}
          >
            <Typography
              sx={{
                ...theme.typography.h6,
                color: theme.palette.system['text-light'],
              }}
            >
              {associationData.associationName}
            </Typography>
          </Box>
        )}
        <SignUpContributionOrganism>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: isPc ? '32px' : '32px 16px',
              gap: '16px',
              backgroundColor: 'system.white',
              borderRadius: isPc ? '0 0 12px 12px' : '0',
            }}
          >
            <Title isPc={isPc} />
            <Row>
              <Label>1口あたり金額</Label>
              <Typography
                variant="body-main/bold"
                sx={{ color: 'system/text-normal' }}
              >
                {numberFormat(associationContribution.unitAmount)}円
              </Typography>
            </Row>
            <Row>
              <Label>奨励金率</Label>
              <Typography
                variant="body-main/bold"
                sx={{ color: 'system/text-normal' }}
              >
                {associationContribution.monthlyIncentiveRatio}%
              </Typography>
            </Row>
            <Row>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Label>給与</Label>
                <Typography
                  variant="caption/regular"
                  sx={{ color: 'system.text-light' }}
                >
                  ※毎月の拠出
                </Typography>
              </Box>
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <SubLabel>口数</SubLabel>
                  <Input
                    name="pay"
                    value={monthlyUnit}
                    onChange={(event) => {
                      if (event.target.value === '') {
                        setMonthlyUnit(0);
                        setBonusUnit(0);
                      }
                      if (!IsNumeric(event.target.value)) return;
                      const _monthlyUnit = Math.min(
                        Number(event.target.value),
                        associationContribution.maxMonthlyUnit
                      );
                      setMonthlyUnit(_monthlyUnit);
                      setBonusUnit(bonusRate * _monthlyUnit);
                    }}
                  />
                  <Typography
                    variant="body-sub/regular"
                    sx={{ color: 'system.text-normal' }}
                  >
                    口
                  </Typography>
                  <Typography
                    variant="body-sub/regular"
                    sx={{ color: 'system.text-light' }}
                  >
                    ※最大{associationContribution.maxMonthlyUnit}口
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <SubLabel>拠出金額</SubLabel>
                  <Value>
                    {numberFormat(
                      monthlyUnit * associationContribution.unitAmount
                    )}
                  </Value>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <SubLabel>奨励金額</SubLabel>
                  <Value>
                    {numberFormat(
                      BountyCalculation(
                        'MONTHLY',
                        associationContribution,
                        monthlyUnit ?? 0
                      ),
                      { maximumFractionDigits: 0 },
                      true
                    )}
                  </Value>
                </Box>
              </Box>
            </Row>
            {associationContribution.bonusReserveClassification === 'NONE' ||
            associationContribution.bonusUnitClassification === '2' ? null : (
              <Row>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Label>賞与</Label>
                </Box>
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
                >
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                  >
                    <SubLabel>拠出</SubLabel>
                    <RadioGroup row={isPc || false}>
                      <FormControlLabel
                        checked={isBonusUnit === true}
                        control={<Radio />}
                        label="する"
                        onClick={() => toggleIsBonusUnit(true)}
                      />
                      <FormControlLabel
                        checked={isBonusUnit === false}
                        control={<Radio />}
                        label="しない"
                        onClick={() => toggleIsBonusUnit(false)}
                      />
                    </RadioGroup>
                  </Box>
                  {isBonusUnit && (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <SubLabel>口数</SubLabel>
                        {associationContribution.bonusMultiplierDesignationType ===
                          'UNIT_NUMBER' && (
                          <MuiBox>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Input
                                name="bonusUnit"
                                value={bonusUnit}
                                onChange={(event) => {
                                  if (event.target.value === '') {
                                    setBonusUnit(0);
                                  }
                                  if (!IsNumeric(event.target.value)) return;
                                  const _bonusUnit = Math.min(
                                    Number(event.target.value),
                                    associationContribution.maxBonusUnit
                                  );
                                  setBonusUnit(_bonusUnit);
                                }}
                              />
                              <Typography
                                sx={{ marginLeft: '8px', marginRight: '30px' }}
                                variant="body-sub/regular"
                              >
                                口
                              </Typography>
                            </Box>
                            <Typography
                              variant="body-sub/regular"
                              sx={{ color: 'system.text-light' }}
                            >
                              ※最大{associationContribution.maxBonusUnit}口
                            </Typography>
                          </MuiBox>
                        )}
                        {associationContribution.bonusMultiplierDesignationType ===
                          'MAGNIFICATION' && (
                          <MuiBox>
                            <RadioGroup row={isPc || false}>
                              {associationContribution.bonusMultiplierDetails.map(
                                (i) => (
                                  <FormControlLabel
                                    value={i}
                                    control={<Radio />}
                                    checked={bonusRate === i}
                                    label={`${i}倍`}
                                    onClick={() => {
                                      setBonusRate(i);
                                      setBonusUnit(i * monthlyUnit);
                                    }}
                                  />
                                )
                              )}
                            </RadioGroup>
                          </MuiBox>
                        )}
                        {associationContribution.bonusMultiplierDesignationType ===
                          'FIXED_MAGNIFICATION' && (
                          <MuiBox>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              {bonusRate * monthlyUnit}
                              <Typography
                                sx={{ marginLeft: '8px', marginRight: '30px' }}
                                variant="body-sub/regular"
                              >
                                口
                              </Typography>
                            </Box>
                            <Typography
                              variant="body-sub/regular"
                              sx={{ color: 'system.text-light' }}
                            >
                              ※給与（毎月の拠出）の
                              {
                                associationContribution
                                  .bonusMultiplierDetails[0]
                              }
                              倍となります
                            </Typography>
                          </MuiBox>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <SubLabel>拠出金額</SubLabel>
                        <Value>
                          {associationContribution.bonusMultiplierDesignationType ===
                          'UNIT_NUMBER'
                            ? numberFormat(
                                bonusUnit * associationContribution.unitAmount
                              )
                            : numberFormat(
                                bonusRate *
                                  monthlyUnit *
                                  associationContribution.unitAmount
                              )}
                        </Value>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <SubLabel>奨励金額</SubLabel>
                        <Value>
                          {associationContribution.bonusMultiplierDesignationType ===
                          'UNIT_NUMBER'
                            ? numberFormat(
                                BountyCalculation(
                                  'BONUS',
                                  associationContribution,
                                  bonusUnit
                                ),
                                { maximumFractionDigits: 0 },
                                true
                              )
                            : numberFormat(
                                BountyCalculation(
                                  'BONUS',
                                  associationContribution,
                                  bonusRate * monthlyUnit ?? 0
                                ),
                                { maximumFractionDigits: 0 },
                                true
                              )}
                        </Value>
                      </Box>
                    </>
                  )}
                </Box>
              </Row>
            )}
            <PrimaryButton
              sx={{
                width: isPc ? '130px' : '100%',
                height: isPc ? '40px' : '56px',
                padding: '20px, 24px, 20px, 26px',
                mt: isPc ? '24px' : '16px',
                borderRadius: '28px',
              }}
              onClick={() =>
                navigate(Path.CONTRIBUTION_CONFIRM, {
                  state: {
                    unitAmount: associationContribution.unitAmount,
                    isBonusUnit,
                    monthlyUnit,
                    bonusUnit,
                    bonusRate,
                    associationContribution,
                    bonusMultiplierDesignationType:
                      associationContribution.bonusMultiplierDesignationType,
                    monthlyIncentiveRatio:
                      associationContribution.monthlyIncentiveRatio,
                    associationData,
                  },
                })
              }
              disabled={isDisabled}
            >
              確認へ進む
            </PrimaryButton>
          </Box>
        </SignUpContributionOrganism>
      </>
    );
  }

  return <></>;
};
