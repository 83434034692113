/* eslint-disable @typescript-eslint/no-misused-promises */
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { SignUpUserConfirmOrganism } from '@/components/organism/SignUp/SignUpUserConfirm';

import { Path } from '@/constants/Router/path';
import { appClient } from '@/services';
import type {
  UserFormValues as FormValues,
  SignUpUserFormAddValues,
} from '@/types/components/SignUpUser';

import { SignUpUserConfirmFeature } from '@/features/SignUp/SignUpUserConfirmFeature';
import { SimulationErrorMessageWithIcon } from '@/components/molecules/Message/SimulationErrorMessageWithIcon';
import { theme } from '@/theme/theme';
import { SetupUser, SetupUserError } from '@/types/api/user';

type UserFormProps = FormValues & SignUpUserFormAddValues;
// const errMessage =
//   'すでに持株会に登録されています。持株会事務局にお問い合わせください。';

export const SignUpUserConfirm: FC = () => {
  const location = useLocation();
  const { state } = location;
  const {
    associationData: { associationName },
  } = location.state as {
    associationData: {
      associationCd: string;
      associationName: string;
    };
  };
  // const { handleLoaderOpen, handleLoaderClose } = useLoader();
  const navigate = useNavigate();
  const [errMessage, setErrMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const { getValues } = useFormContext<FormValues>();
  // 入力データを取得
  const values = getValues();
  useEffect(() => {
    if (!values.nameKanji || values.nameKanji === '') {
      // ユーザー情報がない場合はユーザー登録画面に遷移
      navigate(Path.CREATE_USER_INPUT);
    }
  }, [values]);
  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, [isError]);
  /**
   * handleSubmit
   * @param data FormValues
   */
  const handleSubmit = async (data: UserFormProps) => {
    // handleLoaderOpen();
    try {
      const body = {
        associationCd: data.associationCd,
        nameKanji: `${data.nameKanji} ${data.nameKanji2}`,
        nameKana: `${data.nameKana} ${data.nameKana2}`,
        birthday: `${data.year}-${data.month}-${data.day}`,
        zipcode: data.zip,
        address1: data.address1,
        address2: data.address2,
        address3: data.address3,
        tel: data.tel,
        telType: data.telType,
        employeeCd: data.employeeCode,
      };
      const res = (await appClient.users.setupUser(body)) as
        | SetupUser
        | SetupUserError;
      if (res === null) {
        navigate(Path.CREATE_USER_COMPLETE, { state });
      } else {
        setErrMessage(res.message as string);
        setIsError(true);
      }
      // handleLoaderClose();
    } catch (e) {
      console.error(e);
      // handleLoaderClose();
    }
  };

  return (
    <>
      {associationName && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '40px',
            marginTop: '32px',
          }}
        >
          <Typography
            sx={{
              ...theme.typography.h6,
              color: theme.palette.system['text-light'],
            }}
          >
            {associationName}
          </Typography>
        </Box>
      )}
      <SignUpUserConfirmOrganism>
        {isError && (
          <Box pb={5} pt={2} bgcolor="#fff">
            <SimulationErrorMessageWithIcon
              message={errMessage}
              icon="WarningIcon"
            />
          </Box>
        )}
        <SignUpUserConfirmFeature handleConfirmSubmit={handleSubmit} />
      </SignUpUserConfirmOrganism>
    </>
  );
};
