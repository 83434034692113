import { FC } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { theme } from '@/theme/theme';

interface MemberInfoGroupRowProps {
  title: string;
  value: string;
}

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

const TitleWrapper = styled(Box)({
  width: 104,
});

const Title = styled(Typography)(() => {
  const { typography, palette } = theme;

  return {
    ...typography['body-sub/regular'],
    color: palette.system['text-light'],
  };
});

const ValueWrapper = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const Value = styled(Typography)(() => {
  const { typography, palette } = theme;

  return {
    ...typography['body-sub/regular'],
    color: palette.system['text-normal'],
  };
});

export const MemberInfoGroupRow: FC<MemberInfoGroupRowProps> = ({
  title,
  value,
}) => (
  <Wrapper>
    <TitleWrapper>
      <Title>{title}</Title>
    </TitleWrapper>
    <ValueWrapper>
      <Value>{value}</Value>
    </ValueWrapper>
  </Wrapper>
);
