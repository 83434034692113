/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import useSWR from 'swr';
import { appClient } from '@/services';
import { operations } from '@/types/api/openapi/schema';

export type ListEquitySettlementForm =
  operations['listEquitySettlementForm']['responses']['200']['content']['application/json'];

export type ListDividendPaymentCertificate =
  operations['listDividendPaymentCertificate']['responses']['200']['content']['application/json'];

/**
 * useFindAssociationContribution
 *【API-A-1002】持株会（事務局）拠出情報取得
 * 持株会（事務局）拠出情報取得を取得する
 * @param initAssociationCd
 */
export const useListEquitySettlementForm = () => {
  // 【API-A-0801】持分精算書リスト取得
  const { data, mutate, error } = useSWR<ListEquitySettlementForm, Error>(
    `/api/reports/listEquitySettlementForm'`,
    () => appClient.reports.listEquitySettlementForm()
  );
  return {
    data,
    mutate,
    error,
  };
};

export const useListDividendPaymentCertificate = () => {
  // 【API-A-0802】配当金支払証明書兼支払通知書（リスト）取得
  const { data, mutate, error } = useSWR<ListDividendPaymentCertificate, Error>(
    `/api/reports/listDividendPaymentCertificate'`,
    () => appClient.reports.listDividendPaymentCertificate()
  );
  return {
    data,
    mutate,
    error,
  };
};
