import { FC } from 'react';
import { Box, Typography, styled, SelectChangeEvent } from '@mui/material';
import { MonthOrYearSelection } from './MonthOrYearSelection';

interface AccumulationPeriodSelectionFieldProps {
  title: string;
  error: boolean;
  onChangeStartYear?: (e: SelectChangeEvent<any>) => void;
  onChangeStartMonth?: (e: SelectChangeEvent<any>) => void;
  onChangeEndYear?: (e: SelectChangeEvent<any>) => void;
  onChangeEndMonth?: (e: SelectChangeEvent<any>) => void;
}

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 0',
});

const Title = styled(Typography)(({ theme }) => {
  const { typography, palette } = theme;

  return {
    ...typography['body-main/medium'],
    fontSize: 22,
    color: palette.system['text-normal'],
    marginBottom: 16,
    marginLeft: 16,
  };
});

const FieldsWrapper = styled(Box)(({ theme }) => {
  const { breakpoints } = theme;

  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  };
});

const FieldsWrapperSet = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

const SeperatorSymbol = styled(Typography)(({ theme }) => {
  const { typography, palette, breakpoints } = theme;

  return {
    ...typography['body-sub/regular'],
    color: palette.system['text-normal'],
    marginLeft: 16,

    [breakpoints.down('sm')]: {
      margin: '8px 0 8px 16px',
    },
  };
});

export const AccumulationPeriodSelectionField: FC<
  AccumulationPeriodSelectionFieldProps
> = ({
  title,
  error,
  onChangeStartYear,
  onChangeStartMonth,
  onChangeEndYear,
  onChangeEndMonth,
}) => (
  <Wrapper>
    <Title>{title}</Title>
    <FieldsWrapper>
      <FieldsWrapperSet>
        <MonthOrYearSelection
          disabled={error}
          trail="年"
          type="year"
          onChange={onChangeStartYear}
        />
        <MonthOrYearSelection
          disabled={error}
          trail="月"
          type="month"
          onChange={onChangeStartMonth}
        />
      </FieldsWrapperSet>
      <SeperatorSymbol>〜</SeperatorSymbol>
      <FieldsWrapperSet>
        <MonthOrYearSelection
          disabled={error}
          trail="年"
          type="year"
          onChange={onChangeEndYear}
        />
        <MonthOrYearSelection
          disabled={error}
          trail="月"
          type="month"
          onChange={onChangeEndMonth}
        />
      </FieldsWrapperSet>
    </FieldsWrapper>
  </Wrapper>
);
