import { theme } from '@/theme/theme';
import { LocationState } from '@/components/pages/Withdrawal/WithdrawalInput';
import { Box, Typography, styled } from '@mui/material';
import { CSSProperties, FC } from 'react';
import { FindApplicationById } from '@/types/api/applications';
import { InfoRow } from '../infoRow';
import { accountType, reason } from './consts';

interface PdfContentProps {
  data: LocationState;
  requestParams: FindApplicationById['applicationWithdrawal'];
}

const Container = styled(Box)(() => ({
  marginTop: 24,
}));

const ContainerTitle = styled(Typography)(() => ({
  ...theme.typography.h2,
  fontWeight: 500,
  paddingBottom: 12,
  borderWidth: 0,
  fontSize: 26,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: '#000000',
  [theme.breakpoints.up('sm')]: {
    fontSize: 26,
  },
  '@media print': {
    ...theme.typography.h4,
    fontWeight: 500,
    paddingBottom: 12,
    borderWidth: 0,
    fontSize: 21,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    [theme.breakpoints.up('sm')]: {
      fontSize: 21,
    },
  },
}));

const ContainerContent = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  '@media print': {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
}));

const ContainerContenstWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 20,
  '@media print': {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
  },
}));

const ContainerBorderStyle: CSSProperties = {
  borderRadius: '12px',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '#000000',
  padding: '16px',
};

export const WithdrawalContent: FC<PdfContentProps> = ({
  data,
  requestParams,
}) => {
  const isInstitutionOther = (): boolean => {
    if (!requestParams?.institutionType) return false;
    return requestParams?.institutionType === 'OTHER';
  };

  const renderSettlementClassification = () => {
    if (data.association.withdrawalAllSettlementUsageClassification === '1')
      return null;
    return (
      <Container
        sx={{
          ...ContainerBorderStyle,
          marginTop: '20px',
          '@media print': {
            padding: '12px',
            borderRadius: '6px',
          },
        }}
      >
        <ContainerTitle>精算方法</ContainerTitle>
        <ContainerContenstWrapper>
          <ContainerContent>
            <InfoRow
              key="売買単位-withdrawal"
              title={['売買単位', '未満株式持分']}
              content={
                requestParams?.isAdditionalOption
                  ? [
                      '臨時拠出により買増し',
                      `買増株数 ${requestParams.addPurchaseSharesNum ?? 0}株`,
                    ]
                  : '持株会に売却（単元株以上は証券口座に移管）'
              }
              disableSeparator
            />
          </ContainerContent>
        </ContainerContenstWrapper>
      </Container>
    );
  };

  const getBankAccountColumnOne = () => {
    if (isInstitutionOther()) {
      return (
        <>
          <InfoRow
            key="金融機関コード-withdrawal"
            title="金融機関コード"
            content={requestParams?.generalAccount?.institutionCode || ''}
          />
          <InfoRow
            key="金融機関名-withdrawal"
            title="金融機関名"
            content={requestParams?.generalAccount?.institutionName || ''}
          />
          <InfoRow
            key="支店コード-withdrawal"
            title="支店コード"
            content={requestParams?.generalAccount?.bankBranchCode || ''}
            disableSeparator
          />
        </>
      );
    }
    return (
      <InfoRow
        title="店番"
        content={requestParams?.accountYuchoSymbol || '店番'}
        disableSeparator
      />
    );
  };

  const getBankAccountColumnTwo = () => {
    if (isInstitutionOther()) {
      return (
        <>
          <InfoRow
            key="支店名-withdrawal"
            title="支店名"
            content={requestParams?.generalAccount?.bankBranchName || ''}
          />
          <InfoRow
            key="種別-withdrawal"
            title="種別"
            content={
              accountType[
                requestParams?.generalAccount?.accountType || 'SAVINGS'
              ]
            }
          />
          <InfoRow
            key="口座番号-withdrawal"
            title="口座番号"
            content={requestParams?.generalAccount?.accountNumber || ''}
            disableSeparator
          />
        </>
      );
    }
    return (
      <InfoRow
        key="番号-withdrawal"
        title="番号"
        content={requestParams?.accountYuchoNumber || ''}
        disableSeparator
      />
    );
  };

  return (
    <>
      <Container
        sx={{
          ...ContainerBorderStyle,
          marginTop: '20px',
          '@media print': {
            padding: '12px',
            borderRadius: '6px',
          },
        }}
      >
        <ContainerTitle>退会理由</ContainerTitle>
        <ContainerContenstWrapper>
          <ContainerContent>
            <InfoRow
              key="退会理由-withdrawal"
              title="退会理由"
              content={
                requestParams?.reason === 'OTHER'
                  ? [reason.OTHER, requestParams.otherReasons as string]
                  : reason[requestParams?.reason || 'LEAVE_ABSENCE']
              }
              disableSeparator
            />
          </ContainerContent>
        </ContainerContenstWrapper>
      </Container>
      {renderSettlementClassification()}
      <Container
        sx={{
          ...ContainerBorderStyle,
          marginTop: '20px',
          '@media print': {
            padding: '12px',
            borderRadius: '6px',
          },
        }}
      >
        <ContainerTitle>精算金振込口座(会員名義口座)</ContainerTitle>
        <ContainerContenstWrapper>
          <ContainerContent key="ContainerContentFirst-withdrawal">
            {getBankAccountColumnOne()}
          </ContainerContent>
          <ContainerContent key="ContainerContentSecond-withdrawal">
            {getBankAccountColumnTwo()}
          </ContainerContent>
        </ContainerContenstWrapper>
      </Container>
    </>
  );
};
