// その5
import { styled, Theme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';
import { ReactComponent as GuideImg } from '@/assets/guide.svg';
import { ReactComponent as StructureImg } from '@/assets/structure.svg';
import { ReactComponent as SimulationImg } from '@/assets/simulation.svg';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';

import { Path } from '@/constants/Router/path';

const BREAKPOINT = 'sm';

const Wrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '100%',
    display: 'flex',
    gap: '20px',
    [breakpoints.down(BREAKPOINT)]: {
      gap: '40px',
      flexDirection: 'column',
    },
  };
});

const Block = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: '16px',
    justifyContent: 'space-between',
    [breakpoints.down(BREAKPOINT)]: {
      gap: '24px',
    },
  };
});

const TopItems = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
    gap: '24px',
    [breakpoints.down(BREAKPOINT)]: {
      gap: '24px',
    },
  };
});

const Sentence = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const Title = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography.h4,
  };
});

const Description = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-main/medium'],
  };
});

export const Guide: FC = () => {
  const isSp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(BREAKPOINT)
  );
  const width = isSp ? '100%' : '120px';
  const height = isSp ? '56px' : '40px';
  const sx = { width, height };
  const navigate = useNavigate();

  const move = (link: string) => {
    navigate(link);
  };

  return (
    <Wrapper>
      <Block>
        <TopItems>
          <GuideImg />
          <Sentence>
            <Title>ご利用ガイド</Title>
            <Description>NexStocksの使い方をご説明します！</Description>
          </Sentence>
        </TopItems>
        <PrimaryButton sx={sx} onClick={() => move(Path.GUIDE)}>
          詳しく見る
        </PrimaryButton>
      </Block>
      <Block>
        <TopItems>
          <StructureImg />
          <Sentence>
            <Title>持株会の仕組み</Title>
            <Description>
              持株会のしくみや、持株会投資のメリット・デメリットなどについて学べます。
            </Description>
          </Sentence>
        </TopItems>
        <PrimaryButton sx={sx} onClick={() => move(Path.STRUCTURE)}>
          詳しく見る
        </PrimaryButton>
      </Block>
      <Block>
        <TopItems>
          <SimulationImg />
          <Sentence>
            <Title>積立シミュレーション</Title>
            <Description>
              過去の積立シミュレーションを参考に今後の投資額を決めましょう！
            </Description>
          </Sentence>
        </TopItems>
        <PrimaryButton sx={sx} onClick={() => move(Path.SIMULATION)}>
          詳しく見る
        </PrimaryButton>
      </Block>
    </Wrapper>
  );
};
