import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { appClient, AUTH_TOKEN } from '@/services';
import { useLoader, useSnackbarMessage } from '@/hooks';
import { useAuthUser, Token } from '@/hooks/useAuth';
import { MyPageIdMfaOrganism } from '@/components/organism/MyPage/MyPageIdMfa';
import { UpdateUserLoginid, UserLoginIdConfirm } from '@/types/api/users';

export const MyPageIdMfa: FC = () => {
  const location = useLocation();
  const { data } = location.state as {
    data: {
      email: UpdateUserLoginid['email'] | UserLoginIdConfirm['newMail'];
    };
  };
  const { handleLogout } = useAuthUser();
  const { showMessage } = useSnackbarMessage();
  const { handleLoaderOpen, handleLoaderClose } = useLoader();

  /**
   * handleSubmit
   * @param formData
   */
  const handleSubmit = async (
    formData: Pick<UserLoginIdConfirm, 'mfaCode'>
  ) => {
    const tokens = JSON.parse(localStorage.getItem(AUTH_TOKEN) ?? '') as Token;
    const AccessToken = tokens.AccessToken || '';
    const requestBody: UserLoginIdConfirm = {
      newMail: data.email,
      mfaCode: formData.mfaCode,
      token: AccessToken,
    };
    console.log(requestBody); // dummy
    try {
      handleLoaderOpen();
      await appClient.users.findUser(); // dummy
      // TODO
      // Swaggerを取り込んだらdummyを削除しコメントイン
      // await appClient.users.userLoginIdConfirm(requestBody);
      handleLoaderClose();
      void handleLogout();
      return;
    } catch (e) {
      handleLoaderClose();
      console.error(e);
      showMessage({
        open: true,
        messageType: 'error',
        messages: ['ログインID変更に失敗しました'],
      });
    }
  };
  return (
    <MyPageIdMfaOrganism
      newMail={data.email}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      handleSubmit={handleSubmit}
    />
  );
};
