import { BREAKPOINT } from '@/theme/theme';
import { styled, SxProps, Theme } from '@mui/material';
import { FC, ReactNode } from 'react';

interface LoginProps {
  sx?: SxProps<Theme>;
  children: ReactNode;
}

const MuiBox = styled('div')(({ theme }) => ({
  width: '440px',
  margin: '90px auto 111px',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '32px',
  gap: '24px',
  borderRadius: '12px',
  background: theme.palette.system.main,
  [theme.breakpoints.down(BREAKPOINT)]: {
    width: '100%',
    padding: '32px 16px',
    borderRadius: '0',
    margin: '16px auto 60px',
  },
}));

export const LoginBox: FC<LoginProps> = ({ sx, children }) => (
  <MuiBox sx={sx}>{children}</MuiBox>
);
