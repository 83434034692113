/* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unsafe-assignment */
import { Box, Snackbar, Alert, styled } from '@mui/material';
import { FC } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import {
  useLoader,
  useLogoutModal,
  useSessionOutModal,
  useSnackbarMessage,
} from '@/hooks';
import { LoadingProgress } from '@/components/molecules/Loading/LoadingProgress';
import { Logout } from '@/components/molecules/Modal/BasicModal.tsx/Logout';
import { BasicModal } from '@/components/molecules/Modal/BasicModal.tsx';
import { Path } from '@/constants/Router/path';
import { SessionOut } from '@/components/molecules/Modal/BasicModal.tsx/Sessionout';
import { ScrollToTop } from '@/components/molecules/Scroll';
import { FooterTextWithPageTop } from '../molecules/Footer/FooterTextWithPageTop';
import { Header } from '../organism/Header';

interface LayoutProps {
  isOption?: boolean;
}

const Main = styled('main')(({ theme }) => {
  const { palette, breakpoints } = theme;
  return {
    width: '100%',
    background: palette.system.main,
    flexGrow: '1',
    position: 'relative',
    [breakpoints.up('md')]: {
      minHeight: '600px',
    },
  };
});

const MuiBox = styled(Box)(({ theme }) => {
  const { breakpoints } = theme;
  return {
    minHeight: '100vh',
    paddingTop: '64px',
    display: 'flex',
    flexDirection: 'column',

    '@media print': {
      padding: 0,
    },
    [breakpoints.up('md')]: {
      paddingTop: '134px',

      '@media print': {
        padding: 0,
      },
    },
  };
});

const Wrapper = styled('div')(({ theme }) => {
  const { palette } = theme;
  return {
    width: '100vw',
    minHeight: '100vh',
    background: palette.system.main,
  };
});

const isPageTop = (pathname: string) => {
  if (
    pathname === Path.BONUS_COMPLETE ||
    pathname === Path.PART_COMPLETE ||
    pathname === Path.PAY_COMPLETE ||
    pathname === Path.RECESS_COMPLETE ||
    pathname === Path.RESUME_COMPLETE ||
    pathname === Path.WITHDRAWAL ||
    pathname === Path.WITHDRAWAL_COMPLETE ||
    pathname === '/mypage/auth' ||
    pathname === 'mypage/user' ||
    pathname === '/mypage/securities' ||
    pathname === '/mypage/id' ||
    pathname === '/mypage/password'
  ) {
    return false;
  }
  return true;
};

export const Layout: FC<LayoutProps> = ({ isOption }) => {
  const { loaderOpen } = useLoader();
  const location = useLocation();
  const { logoutModal, handleClickLogout, handleLogoutModalClose } =
    useLogoutModal();
  const { sessionOutModal, handleClickUserLogout } = useSessionOutModal();
  const { snackbarMessage, hideMessage } = useSnackbarMessage();

  return (
    <Wrapper>
      <ScrollToTop />
      <Header />
      <MuiBox>
        <Main id="#top">
          <Outlet />
        </Main>
        <FooterTextWithPageTop
          isOption={isOption}
          isPageTop={isPageTop(location.pathname)}
        />
      </MuiBox>
      <LoadingProgress isOpen={loaderOpen} />
      <BasicModal open={logoutModal}>
        <Logout
          handleClickCancel={handleLogoutModalClose}
          handleClickLogout={handleClickLogout}
        />
      </BasicModal>
      <BasicModal open={sessionOutModal}>
        <SessionOut handleClickLogout={handleClickUserLogout} />
      </BasicModal>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarMessage.open}
        autoHideDuration={3000}
        onClose={hideMessage}
      >
        <Alert
          onClose={hideMessage}
          severity={snackbarMessage.messageType}
          sx={{ width: '100%' }}
        >
          {snackbarMessage.messages[0]}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};
