import {
  IconButton,
  Popover,
  styled,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FC, ReactElement, ReactNode, useState } from 'react';

interface PopOverProps {
  children?: ReactNode;
  text?: string;
  icon?: ReactElement;
}

const Wrapper = styled('div')({
  display: 'inline',
});

const MuiIconButton = styled(IconButton)({
  padding: '0px',
  '&:hover': {
    background: 'none',
  },
});

const MuiTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography['caption/regular'],
  borderBottom: `1px solid`,
  borderColor: theme.palette.system['background-light'],
}));

const MuiPopover = styled(Popover)(({ theme }) => ({
  '.MuiPopover-paper': {
    boxShadow: 'none',
    background: theme.palette.system['background-light'],
    borderRadius: '8px',
    borderBottom: `1px solid`,
    borderColor: theme.palette.system.separator,
  },
}));

export const PopoverWithText: FC<PopOverProps> = (props) => {
  const { children, text, icon } = props;

  const isPc = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Wrapper>
      {icon && (
        <MuiIconButton
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {icon}
        </MuiIconButton>
      )}
      {text && (
        <Typography
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {text}
        </Typography>
      )}

      <MuiPopover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: isPc ? 'center' : 'top',
          horizontal: isPc ? 'center' : 'center',
        }}
        transformOrigin={{
          vertical: isPc ? 'bottom' : 'bottom',
          horizontal: isPc ? 'left' : 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <MuiTypography sx={{ p: 1 }}>{children}</MuiTypography>
      </MuiPopover>
    </Wrapper>
  );
};
