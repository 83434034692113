import {
  styled,
  TableRow,
  TableCell,
  useMediaQuery,
  Theme,
} from '@mui/material';
import { FC } from 'react';
import { ReactComponent as Question } from '@/assets/questionPopOver.svg';
import { PopoverWithText } from '@/components/molecules/Popover/PopoverWithText';
import { numberFormat } from '@/utils/numberFormat';

const BREAKPOINT = 'sm';

const Table = styled(TableRow)(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    boxShadow: 'inset 0px 0px 0px rgba(0, 0, 0, 0.1)',
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '12px',
    justifyContent: 'center',
    '&:last-child td, &:last-child th': {
      borderBottom: '0px solid black',
    },
    [breakpoints.down(BREAKPOINT)]: {
      flexDirection: 'column',
    },
  };
});

const MainCell = styled(TableCell)(({ theme }) => {
  const { palette, breakpoints } = theme;
  return {
    background: palette.system.white,
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '23px 0',
    borderRadius: '12px 0 0 12px',
    [breakpoints.down(BREAKPOINT)]: {
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'baseline',
      gap: '8px',
      borderRadius: '12px 12px 0 0px',
    },
  };
});

const SubCell = styled(TableCell)(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&.MuiTableCell-root': {
      padding: '0',
    },
    borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
    [breakpoints.down(BREAKPOINT)]: {
      flexDirection: 'row',
      width: '100%',
      borderLeft: '0px solid rgba(0, 0, 0, 0.1)',
      borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    },
  };
});

const TopCell = styled(TableRow)(({ theme }) => {
  const { typography, breakpoints } = theme;
  return {
    ...typography.h6,
    width: '100%',
    backgroundColor: '#EFFBFC',
    padding: '15px 0px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    color: '#0B5D61',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    justifyContent: 'center',
    [breakpoints.down(BREAKPOINT)]: {
      ...typography['body-sub/medium'],
      width: '40%',
      justifyContent: 'left',
      borderBottom: '0px solid rgba(0, 0, 0, 0.1)',
      borderRight: '1px solid rgba(0, 0, 0, 0.1)',
      padding: '17px 0 17px 1rem',
    },
  };
});

const BottomCell = styled(TableRow)(({ theme }) => {
  const { typography, breakpoints } = theme;
  return {
    ...typography.h6,
    display: 'flex',
    gap: '2px',
    alignItems: 'flex-end',
    padding: '16px 0 0 0',
    [breakpoints.down(BREAKPOINT)]: {
      padding: '0',
      width: '60%',
      justifyContent: 'right',
      paddingRight: '1rem',
    },
  };
});

const CurrentStocksTitle = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography.h6,
    color: '#0B5D61',
  };
});

const CurrentStocksCell = styled(TableRow)(({ theme }) => {
  const { typography, breakpoints } = theme;
  return {
    ...typography.h6,
    display: 'flex',
    gap: '2px',
    alignItems: 'flex-end',
    padding: '16px 0 0 0',
    [breakpoints.down(BREAKPOINT)]: {
      padding: '0',
      justifyContent: 'right',
      paddingRight: '1rem',
    },
  };
});

const Number = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    fontSize: '30px',
    fontWeight: 700,
    [breakpoints.down(BREAKPOINT)]: {
      fontSize: '20px',
    },
  };
});

const Unit = styled('span')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    fontSize: '20px',
    fontWeight: 700,
    [breakpoints.down(BREAKPOINT)]: {
      fontSize: '14px',
    },
  };
});

interface Props {
  stocksNumber: number;
  marketValue: number;
  acquisitionAmount: number;
  valuationGainLoss: number;
  investmentDigit: number;
}

export const AssetsTable: FC<Props> = ({
  stocksNumber,
  marketValue,
  acquisitionAmount,
  valuationGainLoss,
  investmentDigit,
}) => {
  const isSp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(BREAKPOINT)
  );

  const kanmaChange = (value: string) => {
    let kanma;
    if (value.includes('.')) {
      const valueArr = value.split('.');
      const kanmaInt = valueArr[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      const cutDigit = valueArr[1].substring(0, investmentDigit);
      // eslint-disable-next-line prefer-template
      kanma = kanmaInt + '.' + cutDigit;
    } else {
      kanma = value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    }
    return kanma;
  };

  return (
    <Table>
      <MainCell rowSpan={1}>
        <CurrentStocksTitle>現在の株数</CurrentStocksTitle>
        <CurrentStocksCell>
          <Number>
            {kanmaChange(String(stocksNumber))} <Unit>株</Unit>
          </Number>
        </CurrentStocksCell>
      </MainCell>
      <SubCell>
        <TopCell>
          時価評価額※
          <PopoverWithText icon={<Question />}>
            現在株数×株価で算出されます。
          </PopoverWithText>
        </TopCell>
        <BottomCell>
          <Number>
            {marketValue.toLocaleString(undefined, {
              maximumFractionDigits: investmentDigit,
            })}
            <Unit>円</Unit>
          </Number>
        </BottomCell>
      </SubCell>
      <SubCell>
        <TopCell>
          取得金額
          <PopoverWithText icon={<Question />}>
            現在株数×平均取得単価で算出されます。
          </PopoverWithText>
        </TopCell>
        <BottomCell>
          <Number>
            {numberFormat(acquisitionAmount, { maximumFractionDigits: 0 })}
            <Unit>円</Unit>
          </Number>
        </BottomCell>
      </SubCell>
      <SubCell>
        {isSp ? (
          <TopCell sx={{ borderRadius: '0 0 0 12px' }}>
            評価損益{' '}
            <PopoverWithText icon={<Question />}>
              時価評価額(現在株数×株価)ー取得金額(現在株数×平均取得単価)で算出されます。
            </PopoverWithText>
          </TopCell>
        ) : (
          <TopCell sx={{ borderRadius: '0 12px 0 0' }}>
            評価損益{' '}
            <PopoverWithText icon={<Question />}>
              時価評価額(現在株数×株価)ー取得金額(現在株数×平均取得単価)で算出されます。
            </PopoverWithText>
          </TopCell>
        )}
        <BottomCell>
          <Number sx={{ color: '#EE3E3E' }}>
            {numberFormat(valuationGainLoss, { maximumFractionDigits: 0 })}
            <Unit>円</Unit>
          </Number>
        </BottomCell>
      </SubCell>
    </Table>
  );
};
