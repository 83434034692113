import { FC } from 'react';
import { theme } from '@/theme/theme';
import { Box, styled } from '@mui/material';
import 'chartjs-adapter-date-fns';
import { ja } from 'date-fns/locale';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
  TimeScale,
  TimeSeriesScale,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { GraphDataGenerator } from '@/utils/graphDataGenerator';
import { GraphText } from './GraphText';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
  TimeScale,
  TimeSeriesScale
);

export type LineGraphItem = {
  horizontalValue: number;
  // 縦軸値1（拠出金）
  verticalValue1: number;
  // 縦軸値2（評価額）
  verticalValue2: number;
};

interface GraphBarProps {
  startDate: string;
  endDate: string;
  yMin: number;
  yMax: number;
  lineGraphData: Array<LineGraphItem>;
}

const Wrapper = styled(Box)(() => {
  const { breakpoints } = theme;
  return {
    position: 'relative',
    width: '50%',
    paddingBottom: 15,

    [breakpoints.down('sm')]: {
      width: '100%',
      paddingBottom: 0,
    },
  };
});

const TextContainer = styled(Box)({
  position: 'absolute',
  top: 17,
  left: 27,
  zIndex: 2,
});

export const GraphBar: FC<GraphBarProps> = ({
  startDate,
  endDate,
  yMin,
  yMax,
  lineGraphData,
}) => {
  const barList = lineGraphData.map((item: LineGraphItem) => ({
    x: String(item.horizontalValue),
    y: item.verticalValue1,
  }));

  const lineList = lineGraphData.map((item: LineGraphItem) => ({
    x: String(item.horizontalValue),
    y: item.verticalValue2,
  }));

  const GraphBarData = {
    datasets: [
      GraphDataGenerator('Line', lineList),
      GraphDataGenerator('Bar', barList),
    ],
  };
  return (
    <Wrapper>
      <TextContainer>
        <GraphText circleColor={theme.palette.primary.main} text="拠出金" />
        <GraphText
          circleColor={theme.palette.states.done as string}
          text="評価額"
        />
      </TextContainer>
      <Chart
        type="bar"
        data={GraphBarData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            screenX: {
              position: 'bottom',
              type: 'timeseries',
              time: {
                unit: 'year',
                round: 'month',
                displayFormats: {
                  year: 'yy/MM',
                },
              },
              adapters: {
                date: {
                  locale: ja,
                },
              },
              grid: {
                offset: false,
              },
              bounds: 'data',
              min: startDate,
              max: endDate,
            },
            screenY: {
              type: 'linear',
              position: 'right',
              axis: 'y',
              min: yMin,
              max: yMax,
            },
            screenLeft: {
              display: false,
              ticks: {
                display: false,
              },
            },
          },
        }}
      />
    </Wrapper>
  );
};
