// その2
import { styled, Theme, useMediaQuery } from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { Application } from '@/types/api/application';
import { Path } from '@/constants/Router/path';

const BREAKPOINT = 'sm';

const Wrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    [breakpoints.down(BREAKPOINT)]: {
      gap: '24px',
    },
  };
});

const TitleWrapper = styled('div')({
  display: 'flex',
  gap: '16px',
  width: '100%',
  alignItems: 'center',
});

const PcTitleWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const Title = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography.h3,
  };
});

const Date = styled('div')(({ theme }) => {
  const { typography, palette } = theme;
  return {
    ...typography['body-main/regular'],
    display: 'flex',
    alignItems: 'flex-end',
    color: palette.system['text-light'],
  };
});

const MiddleWrapper = styled('div')(({ theme }) => {
  const { breakpoints, palette } = theme;
  return {
    backgroundColor: palette.system.white,
    border: '1px solid rgba(0, 0, 0, 0.13)',
    borderRadius: '8px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '15px 27px',
    [breakpoints.down(BREAKPOINT)]: {
      flexDirection: 'column',
      gap: '8px',
      padding: '16px 24px',
    },
  };
});

export const LeftSide = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    gap: '25px',
    [breakpoints.down(BREAKPOINT)]: {
      gap: '18px',
    },
  };
});

export const ApplicationTitle = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-main/regular'],
  };
});

export const RightSide = styled('div')(({ theme }) => {
  const { typography, palette } = theme;
  return {
    ...typography['body-main/regular'],
    color: palette.system['text-light'],
  };
});

interface Props {
  date: string;
  children?: React.ReactNode;
  applications: Application[];
}

export const RequestStatus: FC<Props> = ({ date, children, applications }) => {
  const isSp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(BREAKPOINT)
  );
  const width = isSp ? '100%' : '152px';
  const height = isSp ? '56px' : '40px';
  const borderRadius = isSp ? '28px' : '20px';
  const sx = { width, height, borderRadius };
  const navigate = useNavigate();

  const move = () => {
    navigate(Path.APPLICATIONS);
  };

  return (
    <Wrapper>
      {isSp ? (
        <>
          <TitleWrapper>
            <Title>申請状況</Title>
            <Date>{date}現在</Date>
          </TitleWrapper>
          {applications.length === 0 && (
            <Date>処理中のお手続きはありません。</Date>
          )}
        </>
      ) : (
        <>
          <PcTitleWrapper>
            <TitleWrapper>
              <Title>申請状況</Title>
              <Date>{date}現在</Date>
            </TitleWrapper>

            <PrimaryButton sx={sx} onClick={() => move()}>
              申請履歴
            </PrimaryButton>
          </PcTitleWrapper>
          {applications.length === 0 && (
            <Date>処理中のお手続きはありません。</Date>
          )}
        </>
      )}
      {applications !== null && <MiddleWrapper>{children}</MiddleWrapper>}

      {isSp && (
        <PrimaryButton sx={sx} onClick={() => move()}>
          申請履歴
        </PrimaryButton>
      )}
    </Wrapper>
  );
};
