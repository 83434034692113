/* eslint-disable react/require-default-props */
import { styled, Link as MuiLink } from '@mui/material';
import { KeyboardArrowUpIcon } from '@/assets/KeyboardArrowUpIcon';
import { BREAKPOINT } from '@/theme/theme';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Path } from '@/constants/Router/path';
import { ReactComponent as Logo } from '../../atoms/Footer/assets/footer-logo.svg';

interface FooterProps {
  // ページトップの表示
  isPageTop?: boolean;
  // ロゴ・リンクの表示
  isOption?: boolean;
}

const FooterWrapper = styled('div')(({ theme }) => {
  const { palette } = theme;
  return {
    width: '100%',
    height: 'auto',
    padding: '0 8px 24px',
    gap: '24px',
    background: palette.system.main,
    marginTop: 'auto',

    '@media print': {
      display: 'none',
    },
  };
});

const PageTop = styled('div')(({ theme }) => {
  const { palette, typography } = theme;
  return {
    ...typography['body-sub/regular'],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    gap: '8px',
    background: palette.system['background-light'],
    borderBottom: '1px solid',
    borderColor: palette.system['separator-light'],
  };
});

const FooterInnerWrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '922px',
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
    },
    paddingTop: '24px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  };
});

const FooterLogoArea = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0',
    gap: '64px',
    [breakpoints.down(BREAKPOINT)]: {
      flexDirection: 'column',
      gap: '0',
    },
  };
});

const List = styled('ul')({
  listStyle: 'none',
  display: 'flex',
  justifyContent: 'flex-start',
});

const ListChild = styled('li')(({ theme }) => {
  const { palette, typography } = theme;
  return {
    ...typography['body-sub/regular'],
    marginRight: '8px',
    borderRight: '1px solid',
    borderColor: palette.system.inactive,
    height: '21px',
    paddingRight: '4px',
    '&:last-child': {
      border: 'none',
    },
    '& a': {
      textDecoration: 'none',
    },
  };
});

const FooterLower = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: '0px',
    gap: '16px',
    [breakpoints.down(BREAKPOINT)]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      gap: '4px',
    },
  };
});

const FooterTextArea = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0px',
  gap: '2px',
});

const FooterText = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    fontFamily: 'Noto Sans JP',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '10px',
    lineHeight: '14px',
    color: '#222',
    width: '610px',
    [breakpoints.down('md')]: {
      width: '100%',
    },
  };
});

const Copyright = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['caption/regular'],
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: '11px',
    transform: 'scale(0.8)',
    transformOrigin: 'left',
    whiteSpace: 'nowrap',
  };
});

const StyledLink = styled(Link)(({ theme }) => {
  const { palette } = theme;
  return {
    color: palette.system['text-normal'],
  };
});

export const FooterTextWithPageTop: FC<FooterProps> = ({
  isOption = false,
  isPageTop = false,
}) => (
  <FooterWrapper>
    {isPageTop && (
      <MuiLink href="#top">
        <PageTop>
          <KeyboardArrowUpIcon />
          ページトップへ
        </PageTop>
      </MuiLink>
    )}
    <FooterInnerWrapper>
      <FooterLogoArea>
        {isOption && <Logo />}
        {isOption && (
          <List>
            <ListChild>
              <StyledLink to={Path.GUIDE}>ご利用ガイド</StyledLink>
            </ListChild>
            <ListChild>
              <StyledLink to={Path.CONTACT}>お問い合わせ</StyledLink>
            </ListChild>
          </List>
        )}
      </FooterLogoArea>
      <FooterLower>
        <FooterTextArea>
          <FooterText>
            商号等：東海東京証券株式会社&emsp;金融商品取引業者&emsp;東海財務局長（金商）第１４０号
          </FooterText>
          <FooterText>
            加入協会：日本証券業協会、一般社団法人金融先物取引業協会、一般社団法人第二種金融商品取引業協会、一般社団法人日本投資顧問業協会、一般社団法人日本ＳＴＯ協会
          </FooterText>
        </FooterTextArea>
        <Copyright>
          Copyright&thinsp;©&thinsp;2022&thinsp;Tokai&thinsp;Tokyo&thinsp;Securities&thinsp;CO.,LTD.&thinsp;ALL&thinsp;RIGHTS&thinsp;RESERVED.
        </Copyright>
      </FooterLower>
    </FooterInnerWrapper>
  </FooterWrapper>
);
