import { FC, useCallback, useEffect, useState } from 'react';
import { useFormContext, SubmitHandler } from 'react-hook-form';
import { UserFormValues as FormValues } from '@/types/components/SignUpUser';

import { useGetAddressApi } from '@/hooks';
import {
  Association,
  FormContents,
  SelectBirthDayValue,
} from '@/components/organism/SignUp/SignUpUser';

import { isObjectEmpty } from '@/utils/empty';
import { getDayList } from '@/utils/dateFunctions';

interface FeatureProps {
  associationData: Association;
  handleFormSubmit: (data: FormValues) => void;
}

/**
 * SignUpUserFormFeature
 * @param handleFormSubmit
 * @constructor
 */
export const SignUpUserFormFeature: FC<FeatureProps> = ({
  associationData,
  handleFormSubmit,
}) => {
  // const { handleLoaderOpen, handleLoaderClose } = useLoader();

  // [GetAddressApi]郵便番号から住所を取得するカスタムフック
  const { addressData, getAddress } = useGetAddressApi();

  // react-hooks-form
  // useFormContextでSignUpUserIndex.tsxで定義したFormProviderを使用
  const {
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    watch,
  } = useFormContext<
    FormValues & {
      agree: boolean;
      associationCd: string;
      associationName: string;
    }
  >();

  // フォームの入力値を取得
  const selectNameKanji = watch('nameKanji');
  const selectNameKanji2 = watch('nameKanji2');
  const selectNameKana = watch('nameKana');
  const selectNameKana2 = watch('nameKana2');
  const selectPrefValue = watch('address1');
  const selectAgreeValue = watch('agree');
  const selectBirthYearValue = watch('year');
  const selectBirthMonthValue = watch('month');
  const selectBirthDayValue = watch('day');
  const selectTelTypeValue = watch('telType') || 'MOBILE';
  const selectTelValue = watch('tel');
  const selectEmployeeCode = watch('employeeCode');

  const [dayList, setDayList] = useState([{ key: '', value: '' }]);
  const [selectBirthValue, setSelectBirthValue] = useState<SelectBirthDayValue>(
    {
      year: selectBirthYearValue,
      month: selectBirthMonthValue,
      day: selectBirthDayValue,
    }
  );

  const isFormFilled = useCallback(() => {
    const result = isValid && selectAgreeValue;
    return result;
  }, [
    selectAgreeValue,
    isValid,
    selectNameKanji,
    selectNameKanji2,
    selectNameKana,
    selectNameKana2,
    selectPrefValue,
    selectBirthYearValue,
    selectBirthMonthValue,
    selectBirthDayValue,
    selectTelTypeValue,
    selectTelValue,
    selectEmployeeCode,
  ]);

  useEffect(() => {
    if (addressData && addressData.length > 0) {
      setValue('address1', addressData[0].pref);
      setValue('address2', addressData[0].city + addressData[0].town);
      setValue('address3', '');
    }
  }, [addressData]);

  useEffect(() => {
    if (associationData) {
      setValue('associationCd', associationData.associationCd || '');
      setValue('associationName', associationData.associationName || '');
    }
  }, [associationData]);

  useEffect(() => {
    setSelectBirthValue({
      year: selectBirthYearValue,
      month: selectBirthMonthValue,
      day: selectBirthDayValue,
    });
  }, [selectBirthYearValue, selectBirthMonthValue, selectBirthDayValue]);

  useEffect(() => {
    const dayHolder = getValues().day;
    setValue('day', '');
    setDayList(
      getDayList(Number(selectBirthYearValue), Number(selectBirthMonthValue))
    );
    setValue('day', dayHolder);
  }, [selectBirthYearValue, selectBirthMonthValue]);

  /**
   * onSubmit
   * @param data
   */
  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
    if (!isObjectEmpty(errors)) {
      console.error(errors);
    }
    handleFormSubmit(data);
  };

  /**
   * handleGetAddress
   */
  const handleGetAddress = () => {
    const { zip } = watch();
    if (zip === '') return;
    getAddress(zip);
  };

  // if (!associationData) handleLoaderOpen();
  // if (associationData) handleLoaderClose();
  // todo::エラー処理
  // if (associationResError) return <div>error</div>;

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContents
        association={associationData}
        selectPrefValue={selectPrefValue}
        selectBirthValue={selectBirthValue}
        selectTelTypeValue={selectTelTypeValue}
        isAgree={selectAgreeValue}
        dayList={dayList}
        getAddress={handleGetAddress}
        isFormFilled={isFormFilled()}
      />
    </form>
  );
};
