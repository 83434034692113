import { LoginBox } from '@/components/molecules/Login/LoginBox';
import { LoginTitleWithUnderline } from '@/components/molecules/Login/LoginTitleWithUnderline';
import { FC, useState } from 'react';
import { Box, FormHelperText, styled, Typography } from '@mui/material';
import { LabelWithDetail } from '@/components/molecules/Login/LabelWithDetail';
import { months, years, days } from '@/constants/choicesForSelectBox';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { appClient } from '@/services';
import { Path } from '@/constants/Router/path';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { LoginLabelWithTextInput } from '@/components/molecules/Login/LoginLabelWithTextInput';
import { ErrMessageWithIcon } from '@/components/molecules/Message/ErrMessageWithIcon';
import { LoginSelect } from '@/components/molecules/Login/LoginSelect';
import { ValidateIsNumAndAlphabet } from '@/utils/stringProcessor';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const NormalTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography['body-main/regular'],
  color: theme.palette.system['text-normal'],
  textAlign: 'left',
}));

const LargeLabelWithInput = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0px',
  gap: '8px',
  width: '100%',
});

const SelectWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: '0px',
  gap: '8px',
  width: '100%',
});

const SelectField = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0px',
  gap: '8px',
  width: '160px',
});

const SelectBoxTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography['body-sub/regular'],
  color: theme.palette.system['text-normal'],
}));

// エラーメッセージ
const errorMessage = [
  '本人確認ができませんでした。入力内容が正しいかご確認ください。',
];

// フォーム
type FormInputState = {
  mailAddress: string;
  associationCd: string;
  employeeCd: string;
  year: string;
  month: string;
  day: string;
};

// バリデーションルール
export const schema = yup.object({
  mailAddress: yup
    .string()
    .required('入力してください')
    .email('有効なメールアドレスを入力してください'),
  associationCd: yup.string().required('入力してください'),
  employeeCd: yup.string().required('入力してください'),
  year: yup.string().required(),
  month: yup.string().required(),
  day: yup.string().required(),
});

export const LoginPasswordIndex: FC = () => {
  const navigate = useNavigate();
  const [isError, setIsError] = useState<boolean>(false);
  useHelmetHandler({
    title: 'パスワード再設定 メールアドレス入力',
  });

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormInputState>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormInputState) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const response = await appClient.authorization.authidentification({
        loginId: data.mailAddress,
        associationCd: data.associationCd,
        employeeCd: data.employeeCd,
        birthday: `${`${data.year}-${data.month}-${data.day}`}`,
      });

      if (response.Session) {
        // 多用書認証画面へ遷移する
        navigate(Path.LOGIN_PASSWORD_MFA, {
          state: {
            loginId: data.mailAddress,
            associationCd: data.associationCd,
            employeeCd: data.employeeCd,
            birthday: `${`${data.year}-${data.month}-${data.day}`}`,
            mfaSession: response.Session,
          },
        });
      }
    } catch {
      // エラー
      setIsError(true);
    }
    // 画面遷移
  };

  const AssociationCd = watch('associationCd');
  const EmployeeCd = watch('employeeCd');

  return (
    <LoginBox>
      <LoginTitleWithUnderline isLogin title="パスワード再設定" />
      <NormalTypography>
        パスワードを再設定するためには本人確認が必要です。以下の項目を入力してください。
      </NormalTypography>
      {isError && <ErrMessageWithIcon errorMessage={errorMessage} isBorder />}
      <LoginLabelWithTextInput
        label="メールアドレス"
        annotation=""
        error={'mailAddress' in errors}
        helperText={errors.mailAddress?.message}
        placeholder="例：abcdef@mail.com"
        {...register('mailAddress')}
      />
      <LoginLabelWithTextInput
        label="持株会コード"
        annotation=""
        error={'associationCd' in errors}
        helperText={errors.associationCd?.message}
        placeholder="例：123456"
        value={AssociationCd || ''}
        {...register('associationCd')}
        onChange={(e) => {
          if (
            (ValidateIsNumAndAlphabet(e.target.value) &&
              e.target.value.length <= 6) ||
            e.target.value === ''
          )
            setValue('associationCd', e.target.value);
        }}
      />
      <LoginLabelWithTextInput
        label="社員コード"
        annotation=""
        error={'employeeCd' in errors}
        helperText={errors.employeeCd?.message}
        placeholder="例：1234"
        value={EmployeeCd || ''}
        {...register('employeeCd')}
        onChange={(e) => {
          if (
            (ValidateIsNumAndAlphabet(e.target.value) &&
              e.target.value.length <= 10) ||
            e.target.value === ''
          )
            setValue('employeeCd', e.target.value);
        }}
      />
      <LargeLabelWithInput>
        <LabelWithDetail label="生年月日" />
        <SelectWrapper>
          <SelectField>
            <LoginSelect
              items={years}
              defaultValue="1990"
              error={'year' in errors}
              {...register('year')}
            />
            <SelectBoxTypography>年</SelectBoxTypography>
          </SelectField>
          <SelectField>
            <LoginSelect
              items={months}
              defaultValue="01"
              error={'month' in errors}
              {...register('month')}
            />
            <SelectBoxTypography>月</SelectBoxTypography>
          </SelectField>
          <SelectField>
            <LoginSelect
              items={days}
              defaultValue="01"
              error={'day' in errors}
              {...register('day')}
            />
            <SelectBoxTypography>日</SelectBoxTypography>
          </SelectField>
        </SelectWrapper>

        {(errors.year || errors.month || errors.day) && (
          <FormHelperText sx={{ color: '#D20B0B' }}>
            選択してください
          </FormHelperText>
        )}
      </LargeLabelWithInput>
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <PrimaryButton
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleSubmit(onSubmit)}
        >
          送信する
        </PrimaryButton>
      </Box>
    </LoginBox>
  );
};
