import { FC } from 'react';
import { theme } from '@/theme/theme';
import { Box, Typography, styled, Divider, SxProps } from '@mui/material';

const Wrapper = styled(Box)(() => ({
  padding: '8px 0',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 8,
  position: 'relative',

  '@media print': {
    padding: '8px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
    position: 'relative',
  },
}));

const Text = styled(Typography)(() => ({
  ...theme.typography.h5,
  fontWeight: 400,
  lineHeight: '30px',

  '@media print': {
    ...theme.typography.h5,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
  },
}));

const InfoRowTitle = styled(Box)(() => ({
  width: 160,
  minWidth: 160,
  alignSelf: 'flex-start',
  flexDirection: 'column',
  gap: 4,

  '@media print': {
    width: 120,
    minWidth: 120,
    alignSelf: 'flex-start',
    flexDirection: 'column',
    gap: 4,
  },
}));

const InfoRowContent = styled(Box)(() => ({
  display: 'flex',
  gap: 4,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',

  '@media print': {
    display: 'flex',
    gap: 4,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
}));

const Separator = styled(Divider)(() => ({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  '@media print': {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

interface InfoRowProps {
  title: string | string[];
  content: string | string[];
  disableSeparator?: boolean;
  sxProps?: SxProps;
}

export const InfoRow: FC<InfoRowProps> = ({
  title,
  content,
  disableSeparator,
  sxProps,
}) => {
  const renderTitle = () => {
    if (typeof title === 'string') return <Text>{title}</Text>;
    return title.map((item) => <Text key={item}>{item}</Text>);
  };

  const renderContent = () => {
    if (typeof content === 'string') return <Text>{content}</Text>;
    return content.map((item) => <Text key={item}>{item}</Text>);
  };

  return (
    <Wrapper sx={{ ...sxProps }}>
      <InfoRowTitle>{renderTitle()}</InfoRowTitle>
      <InfoRowContent>{renderContent()}</InfoRowContent>
      {!disableSeparator && <Separator />}
    </Wrapper>
  );
};
