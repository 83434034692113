import { Link, styled, Typography, Box } from '@mui/material';
import { FC, useState, useEffect } from 'react';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails, {
  AccordionDetailsProps,
} from '@mui/material/AccordionDetails';
import { BREAKPOINT } from '@/theme/theme';
import { TextButton } from '@/components/atoms/Buttons/TextButton';
import { ReactComponent as RightArrow } from '@/assets/linkArrow.svg';
import { useAuthUser } from '@/hooks/useAuth';
import { Path } from '@/constants/Router/path';
import { ReactComponent as QMark } from '../../../../assets/question.svg';
import { ReactComponent as AMark } from '../../../../assets/answer.svg';
import { ReactComponent as PlusButton } from '../../../../assets/plusButton.svg';
import { ReactComponent as MinusButton } from '../../../../assets/minusButton.svg';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    sx={{
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    }}
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => {
  const { breakpoints } = theme;
  return {
    margin: '0px',
    '&:not(:last-child)': {
      boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.2)',
    },
    '&:last-child': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    [breakpoints.down(BREAKPOINT)]: {
      padding: '0 16px',
    },
  };
});

const AccordionSummaryOpen = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<MinusButton />} {...props} />
))({
  flexDirection: 'row',
  gap: '19px',
  padding: '0px',
  marginLeft: '16px',
  marginRight: '19px',
});

const AccordionSummaryClose = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<PlusButton />} {...props} />
))({
  flexDirection: 'row',
  gap: '19px',
  padding: '0px',
  marginLeft: '16px',
  marginRight: '19px',
});

const AccordionDetails = styled((props: AccordionDetailsProps) => (
  <MuiAccordionDetails {...props} />
))(() => ({
  padding: '0px',
  marginLeft: '16px',
}));

const AnswerWrap = styled('div')({
  margin: '0px',
  height: '24px',
});

const QuestionWrap = styled('div')({
  margin: '0px',
  height: '24px',
});

const QuestionText = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-main/medium'],
  };
});

const AnswerTextsWrap = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

const AnswerTextWrap = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-sub/regular'],
  };
});

const LinkTextWrap = styled(Link)(({ theme }) => {
  const { palette } = theme;
  return {
    color: palette.system.link,
    textDecorationColor: palette.system.link,
  };
});

const CautionTextWrap = styled('div')(({ theme }) => {
  const { typography, palette } = theme;
  return {
    ...typography['body-sub/bold'],
    color: palette.states.error,
  };
});

interface Question {
  question: string;
  answers: string[];
  link?: string;
  linkTitle?: string;
  caution?: string;
  linkCheck?: boolean;
}
interface Props {
  question: Question;
}

export const AccordionBox: FC<Props> = ({ question }) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [url, setURL] = useState<string>('');
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      event.preventDefault();
      setExpanded(newExpanded ? panel : false);
    };
  const { user } = useAuthUser();

  useEffect(() => {
    if (url !== '') {
      if (url.match('https://')) {
        window.open(url, '_blank');
      } else {
        window.open(url, '_self');
      }
    }
  }, [url]);

  const createContent: FC<Question> = (questionData) => {
    let returnParam = null;

    if (questionData.link && questionData.linkCheck === false) {
      returnParam = (
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <TextButton onClick={() => setURL(String(questionData.link))}>
            <LinkTextWrap>{questionData.linkTitle}</LinkTextWrap>
          </TextButton>
          <RightArrow />
        </Box>
      );
    } else if (
      questionData.link === Path.RESUME &&
      user.userStatus === 'RECESS'
    ) {
      returnParam = (
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <TextButton onClick={() => setURL(String(questionData.link))}>
            <LinkTextWrap>{questionData.linkTitle}</LinkTextWrap>
          </TextButton>
          <RightArrow />
        </Box>
      );
    } else if (
      questionData.link === Path.RECESS &&
      user.userStatus === 'NORMAL'
    ) {
      returnParam = (
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <TextButton onClick={() => setURL(String(questionData.link))}>
            <LinkTextWrap>{questionData.linkTitle}</LinkTextWrap>
          </TextButton>
          <RightArrow />
        </Box>
      );
    }

    return returnParam;
  };

  return (
    <Accordion
      expanded={expanded === question.question}
      onChange={handleChange(question.question)}
    >
      {expanded === question.question ? (
        <AccordionSummaryOpen>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              padding: '18px 0 19px 0',
            }}
          >
            <QuestionWrap id={question.question}>
              <QMark />
            </QuestionWrap>
            <QuestionText>{question.question}</QuestionText>
          </Typography>
        </AccordionSummaryOpen>
      ) : (
        <AccordionSummaryClose>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              padding: '18px 0 19px 0',
            }}
          >
            <QuestionWrap id={question.question}>
              <QMark />
            </QuestionWrap>
            <QuestionText>{question.question}</QuestionText>
          </Typography>
        </AccordionSummaryClose>
      )}
      <AccordionDetails>
        <Typography
          sx={{
            display: 'flex',
            gap: '8px',
            paddingRight: '16px',
            alignItems: 'start',
            paddingBottom: '19px',
          }}
        >
          <AnswerWrap>
            <AMark />
          </AnswerWrap>
          <AnswerTextsWrap>
            {question.answers.map((answer) => (
              <AnswerTextWrap>{answer}</AnswerTextWrap>
            ))}
            {createContent(question)}
            {question.caution && (
              <CautionTextWrap>{question.caution}</CautionTextWrap>
            )}
          </AnswerTextsWrap>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
