import { styled } from '@mui/material';
import { FC, ReactNode } from 'react';

interface InputProps {
  label: string;
  bold?: boolean;
  flat?: boolean;
  children: ReactNode;
}

const ItemArea = styled('div', {
  shouldForwardProp: (prop) => prop !== 'flat',
})<{ flat?: boolean }>(({ flat }) => ({
  display: flat ? 'flex' : 'block',
  margin: '8px 0',
  width: '100%',
}));

const ItemName = styled('div')({
  width: '100%',
  textAlign: 'left',
});

const Value = styled('div')({
  width: '100%',
});

const Label = styled('p', {
  shouldForwardProp: (prop) => prop !== 'bold',
})<{ bold?: boolean }>(({ bold }) => ({
  fontWeight: bold ? 'bold' : 'normal',
  margin: '8px 0',
}));

export const LabelAndTextInput: FC<InputProps> = ({
  label,
  children,
  flat = false,
  bold = false,
}) => (
  <ItemArea flat={flat}>
    <ItemName>
      <Label bold={bold}>{label}</Label>
    </ItemName>
    <Value>{children}</Value>
  </ItemArea>
);
