import { FC } from 'react';
import { PrimaryTable } from '@/components/molecules/Table/PrimaryTable';
import { PrimaryTableBodyTdMulti } from '@/components/atoms/Table/PrimaryTableBodyTdMulti';
import { Typography } from '@mui/material';
import { FindApplicationById } from '@/types/api/applications';
import { FindAssociationById } from '@/types/api/associations';
import { AdditionalOption } from './AdditionalOption';

interface Props {
  applicationWithdrawal: FindApplicationById['applicationWithdrawal'];
  association: FindAssociationById;
}

const accountType = {
  SAVINGS: '普通',
  CURRENT: '当座',
  OTHER: '貯蓄',
};

const labelReason = {
  LEAVE_ABSENCE: '退職',
  FAMILY_MATTERS: '家庭の事情',
  ECONOMIC: '経済的理由',
  OTHER: 'その他（理由を記入）',
};

export const Withdrawal: FC<Props> = ({
  applicationWithdrawal,
  association,
}) => {
  const renderOption = () => {
    if (applicationWithdrawal?.isAdditionalOption) {
      return (
        <AdditionalOption
          addPurchaseSharesNum={applicationWithdrawal.addPurchaseSharesNum}
          transferAmount={applicationWithdrawal.transferAmount}
          remarks={applicationWithdrawal.remarks}
          institutionType={applicationWithdrawal.institutionType}
        />
      );
    }
    if (
      applicationWithdrawal?.isAdditionalOption === false &&
      association.withdrawalLessThanSettlementUsageClassification === '1'
    ) {
      return (
        <PrimaryTable title="精算方法">
          <PrimaryTableBodyTdMulti
            title="売買単位"
            SecondaryTitle="未満株式持分"
          >
            持株会に売却（単元株以上は証券口座に移管）
          </PrimaryTableBodyTdMulti>
        </PrimaryTable>
      );
    }
    return undefined;
  };
  return (
    <>
      <PrimaryTable title="退会申請">
        <PrimaryTableBodyTdMulti title="退会理由">
          <Typography sx={{ dispaly: 'block' }}>
            {labelReason[applicationWithdrawal?.reason ?? 'LEAVE_ABSENCE']}
          </Typography>
          <Typography sx={{ dispaly: 'block' }}>
            {applicationWithdrawal?.otherReasons}
          </Typography>
        </PrimaryTableBodyTdMulti>
      </PrimaryTable>
      {renderOption()}
      <PrimaryTable title="精算金振込口座(会員名義口座)">
        {applicationWithdrawal?.institutionType === 'OTHER' &&
        applicationWithdrawal.generalAccount ? (
          <>
            <PrimaryTableBodyTdMulti title="金融機関コード">
              {applicationWithdrawal.generalAccount.institutionCode}
            </PrimaryTableBodyTdMulti>
            <PrimaryTableBodyTdMulti title="金融機関名">
              {applicationWithdrawal.generalAccount.institutionName}
            </PrimaryTableBodyTdMulti>
            <PrimaryTableBodyTdMulti title="支店コード">
              {applicationWithdrawal.generalAccount.bankBranchCode}
            </PrimaryTableBodyTdMulti>
            <PrimaryTableBodyTdMulti title="支店名">
              {applicationWithdrawal.generalAccount.bankBranchName}
            </PrimaryTableBodyTdMulti>
            <PrimaryTableBodyTdMulti title="種別">
              {
                accountType[
                  applicationWithdrawal?.generalAccount.accountType ?? 'SAVINGS'
                ]
              }
            </PrimaryTableBodyTdMulti>
            <PrimaryTableBodyTdMulti title="口座番号">
              {applicationWithdrawal.generalAccount.accountNumber}
            </PrimaryTableBodyTdMulti>
          </>
        ) : (
          <>
            <PrimaryTableBodyTdMulti title="金融機関の種類">
              ゆうちょ銀行
            </PrimaryTableBodyTdMulti>
            <PrimaryTableBodyTdMulti title="店番・番号">
              {applicationWithdrawal?.accountYuchoSymbol}-
              {applicationWithdrawal?.accountYuchoNumber}
            </PrimaryTableBodyTdMulti>
          </>
        )}
      </PrimaryTable>
    </>
  );
};
