import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Stack,
  SvgIcon,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FC, ReactElement } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useController,
  useForm,
  useFormState,
} from 'react-hook-form';
import * as yup from 'yup';
import { BREAKPOINT, theme } from '@/theme/theme';
import {
  MyPageIdMfaProps as Props,
  MyPageIdLabelProps as LabelProps,
} from '@/types/components/MyPageId';
import { ReactComponent as Icon } from '@/assets/mail.svg';

import { UserLoginIdConfirm } from '@/types/api/users';
import { TextInput } from '@/components/atoms/Input/TextInput';
import { TextButton } from '@/components/atoms/Buttons/TextButton';
import { useNavigate } from 'react-router-dom';
import { appClient } from '@/services';

const FIELD_NAME = 'mfaCode' as keyof Pick<UserLoginIdConfirm, 'mfaCode'>;

const Title: FC = () => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      mb: '24px',
      gap: '4px',
    }}
  >
    <SvgIcon sx={{ fill: (t: Theme) => t.palette.system.placeholder }}>
      <Icon />
    </SvgIcon>
    <Typography variant="h4" sx={{ color: 'system.text-normal' }}>
      ログインID変更
    </Typography>
  </Box>
);

const Label: FC<LabelProps> = ({ isPc, labelCaption }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: isPc ? 'column' : 'row',
      alignItems: isPc ? 'flex-start' : 'center',
      p: '0',
      gap: isPc ? '0' : '4px',
    }}
  >
    <Typography
      component="span"
      variant="body-main/bold"
      sx={{ color: 'system.text-normal' }}
    >
      {labelCaption}
    </Typography>
  </Box>
);

const MfaCode: FC = () => {
  const {
    field: { ref, ...rest },
  } = useController({ name: FIELD_NAME });
  const errors = useFormState().errors as {
    [x: string]: { message: string };
  };
  return (
    <TextInput
      {...rest}
      error={!!errors[FIELD_NAME]}
      helperText={errors[FIELD_NAME]?.message}
      inputRef={ref}
    />
  );
};

const LabelLoginId: FC<LabelProps & { children: ReactElement }> = ({
  isPc,
  labelCaption,
  children,
  underline = false,
}) => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: isPc ? 'row' : 'column',
      alignItems: isPc ? 'center' : 'flex-start',
      pb: underline ? '16px' : '0',
      gap: isPc ? '24px' : '8px',
      borderBottom: underline ? '1px solid' : '0',
      borderColor: 'system.separator-light',
    }}
  >
    <Label isPc={isPc} labelCaption={labelCaption} />
    {children}
  </Box>
);

const Contents: FC<Props> = (props) => {
  const navigate = useNavigate();
  const { isPc, newMail } = props;
  return (
    <Stack
      sx={{
        width: '100%',
        padding: isPc ? 4 : '24px 16px',
        gap: isPc ? 5 : 3,
        backgroundColor: 'system.white',
        borderRadius: isPc ? 3 : '0',
      }}
    >
      <Stack gap={1}>
        <Typography variant="body-main/regular">
          以下のメールアドレスにメールを送信しました：
        </Typography>
        <Typography variant="body-main/medium">{newMail}</Typography>
      </Stack>
      <Stack>
        <Typography variant="body-main/regular">
          新しいログインID(メールアドレス)宛に送信された認証コードを下の入力欄に入力してください。
        </Typography>
        <Typography
          variant="body-main/bold"
          color={theme.palette.secondary.normal}
        >
          認証が完了すると自動でログアウトし、新しいIDでログインする画面に移動します。
        </Typography>
      </Stack>
      <Stack alignItems="center" gap={3}>
        <LabelLoginId isPc={isPc} labelCaption="認証コード">
          <MfaCode />
        </LabelLoginId>
        <PrimaryButton type="submit" sx={{ width: isPc ? 104 : '100%' }}>
          認証する
        </PrimaryButton>
      </Stack>
      <Stack
        alignItems="center"
        p={3}
        gap={3}
        borderRadius={1}
        sx={{ backgroundColor: theme.palette.system['background-light'] }}
      >
        <Typography width="100%" variant="body-main/bold">
          ※認証コードが届かない場合
        </Typography>
        <Typography
          width="100%"
          variant="body-sub/regular"
          color={theme.palette.system['text-light']}
        >
          メールアドレスが間違っていた場合は、「新しいログインIDの入力に戻る」ボタンから1つ前の画面に戻って正しいメールアドレスを再度入力してください。
        </Typography>
        <TextButton
          onClick={() => navigate('/mypage/id')}
          sx={{ fontSize: 16 }}
        >
          新しいログインIDの入力に戻る
        </TextButton>
        <Typography
          width="100%"
          variant="body-sub/regular"
          color={theme.palette.system['text-light']}
        >
          正しいメールアドレスにも関わらず認証コードが届かなかった場合は、「認証コードを再送信する」ボタンからコードを再送信してください。
        </Typography>
        <TextButton
          onClick={() => {
            void appClient.users.updateUserLoginid({ email: newMail });
          }}
          sx={{ fontSize: 16 }}
        >
          認証コードを再送信する
        </TextButton>
      </Stack>
    </Stack>
  );
};

const Wrapper: FC<Props> = ({ isPc, newMail }) => (
  <Box sx={{ maxWidth: '780px', margin: '0 auto' }}>
    <Title />
    <Contents isPc={isPc} newMail={newMail} />
  </Box>
);

export const MyPageIdMfaOrganism: FC<{
  newMail: UserLoginIdConfirm['newMail'];
  handleSubmit: (data: Pick<UserLoginIdConfirm, 'mfaCode'>) => void;
}> = ({ newMail, handleSubmit }) => {
  const isPc = useMediaQuery((t: Theme) => t.breakpoints.up(BREAKPOINT));
  const schema: yup.SchemaOf<Pick<UserLoginIdConfirm, 'mfaCode'>> = yup.object({
    [FIELD_NAME]: yup.string().required('入力してください'),
  });
  const methods = useForm<Pick<UserLoginIdConfirm, 'mfaCode'>>({
    defaultValues: {
      [FIELD_NAME]: '',
    },
    resolver: yupResolver(schema),
  });
  const onSubmit: SubmitHandler<Pick<UserLoginIdConfirm, 'mfaCode'>> = (
    data
  ) => {
    handleSubmit(data);
  };

  return (
    <Box
      sx={{
        bgcolor: 'system.background',
        padding: {
          xs: '24px 0 86px',
          sm: '48px 40px 386px',
          md: '48px 40px 386px',
          lg: '48px 130px 386px',
        },
      }}
    >
      <FormProvider {...methods}>
        {/**
         * lintエラー公式未対応
         * @see https://react-hook-form.com/api/useform/handlesubmit
         */}
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Wrapper isPc={isPc} newMail={newMail} />
        </form>
      </FormProvider>
    </Box>
  );
};
