/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
import { USER } from '@/constants';
import { isObjectEmpty } from '@/utils/empty';
import { Path, PUBLIC_PATHS } from '@/constants/Router/path';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import type { FindUser } from '@/types/api/users';
import type { DecodeCognitoToken } from '@/types/api/auth';

export class UserDomainService {
  /**
   * isApprovedForJoiningAssociation
   * 新規入会承認 済みか判定
   * @param user
   * @returns {boolean} [true: 新規入会承認後(済み) false: 新規入会承認前]
   */
  public static isApprovedForJoiningAssociation(user: FindUser): boolean {
    if (
      isObjectEmpty(user) ||
      user.userStatus === USER.STATUS.PERSONAL_INFORMATION_ENTERED ||
      user.userStatus === USER.STATUS.BEING_ENROLLMENT_PROCESSED
    ) {
      return false;
    }
    return true;
  }

  /**
   * isPersonalInformationEntered
   * 個人情報入力 済みか判定
   * @param user
   * @returns {boolean} [true: 個人情報入力後(済み) false: 個人情報入力前]
   */
  public static isPersonalInformationEntered(user: FindUser): boolean {
    if (
      isObjectEmpty(user) ||
      user.userStatus === USER.STATUS.PERSONAL_INFORMATION_ENTERED
    ) {
      return false;
    }
    return true;
  }

  /**
   * isStatusForRecess
   * @description 休止中（userStatus：RECESS）の場合
   * 休止・月例口数変更・賞与口数変更 申請不可
   * @param user
   */
  public static isStatusForRecess(user: FindUser): boolean {
    if (isObjectEmpty(user) || user.userStatus !== USER.STATUS.RECESS)
      return false;
    return true;
  }

  /**
   * isStatusForNormal
   * @description 通常（userStatus：NORMAL）の場合
   * 再開 申請不可
   * @param user
   */
  public static isStatusForNormal(user: FindUser): boolean {
    if (isObjectEmpty(user) || user.userStatus !== USER.STATUS.NORMAL)
      return false;
    return true;
  }

  /**
   * isStatusForBeingEnrollmentProcessed
   * @description 入会申請中（userStatus：BEING_ENROLLMENT_PROCESSED）の場合
   * 休止・再開・一部引出・退会 申請不可
   * @param user
   */
  public static isStatusForBeingProcessedEnrollment(user: FindUser): boolean {
    if (
      isObjectEmpty(user) ||
      user.userStatus !== USER.STATUS.BEING_ENROLLMENT_PROCESSED
    )
      return false;
    return true;
  }

  /**
   * getFullName
   * 新規入会承認前までは、名前の部分は、「新規入会者様」と表示する
   * @param user
   * @returns {string} [名前 or 新規入会者様]
   */
  public static getFullName(user: FindUser): string {
    const newMemberName = '新規入会者';
    if (isObjectEmpty(user)) return newMemberName;
    if (!this.isApprovedForJoiningAssociation(user)) {
      return newMemberName;
    }
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${user.nameKanji ?? ''}`;
  }

  /**
   * convertUserStatusText
   * 会員ステータス
   */
  public static convertUserStatusText(user: FindUser): string {
    switch (user.userStatus) {
      case USER.STATUS.VARIFIED_MAIL:
      case USER.STATUS.PERSONAL_INFORMATION_ENTERED:
      case USER.STATUS.BEING_ENROLLMENT_PROCESSED:
      case USER.STATUS.NORMAL:
      case USER.STATUS.BEING_RECESSMENT_PROCESSED:
      case USER.STATUS.BEING_WITHDRAWAL_PROCESSED:
      case USER.STATUS.DEREGISTRATION:
      case USER.STATUS.REJECT_ENROLLMENT:
      case USER.STATUS.UNREGISTERED:
        return '稼働中';
      case USER.STATUS.RECESS:
      case USER.STATUS.BEING_RESUMEMENT_PROCESSED:
        return '休止中';
      case USER.STATUS.WITHDRAWN:
        return '退会済';
      default:
        return '';
    }
  }

  /**
   * convertUserStatusDetailText
   * 会員ステータス
   * - VARIFIED_MAIL : メール認証済み
   * - PERSONAL_INFORMATION_ENTERED : 個人情報入力済み
   * - BEING_ENROLLMENT_PROCESSED : 入会申請中
   * - NORMAL : 通常
   * - BEING_RECESSMENT_PROCESSED : 休止申請中
   * - RECESS : 休止中
   * - BEING_RESUMEMENT_PROCESSED : 再開申請中
   * - BEING_WITHDRAWAL_PROCESSED : 退会申請中
   * - WITHDRAWN : 退会済み
   * - DEREGISTRATION : 登録抹消
   * - REJECT_ENROLLMENT : 入会却下
   * - UNREGISTERED : ログイン前
   */
  public static convertUserStatusDetailText(user: FindUser): string {
    switch (user.userStatus) {
      case USER.STATUS.VARIFIED_MAIL:
        return 'メール認証済み';
      case USER.STATUS.PERSONAL_INFORMATION_ENTERED:
        return '個人情報入力済み';
      case USER.STATUS.BEING_ENROLLMENT_PROCESSED:
        return '入会申請中';
      case USER.STATUS.BEING_RECESSMENT_PROCESSED:
        return '休止申請中';
      case USER.STATUS.BEING_RESUMEMENT_PROCESSED:
        return '再開申請中';
      case USER.STATUS.BEING_WITHDRAWAL_PROCESSED:
        return '退会申請中';
      case USER.STATUS.DEREGISTRATION:
        return '登録抹消';
      case USER.STATUS.REJECT_ENROLLMENT:
        return '入会却下';
      case USER.STATUS.UNREGISTERED:
        return 'ログイン前';
      default:
        return '';
    }
  }

  /**
   * locationPublicRoutesPath
   */
  public static locationPublicRoutesPath(): boolean {
    const whiteList: string[] = PUBLIC_PATHS;
    const locationPath: string = window.location.pathname;
    return (
      whiteList.includes(locationPath) ||
      // pathに任意の文字列が入りそうなため、別途対応
      locationPath.includes(Path.EXISTING_LOGIN)
    );
  }

  /**
   * parseJwt
   * jwtをparseする
   * @param token
   * @private
   */
  private static parseJwt(token: string): DecodeCognitoToken {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );

    return JSON.parse(jsonPayload) as DecodeCognitoToken;
  }

  /**
   * lastAuthDateTime
   * @param jwtIdToken
   * @returns {string} 最終ログイン日時 (yyyy年MM月dd日(eee) HH:mm)
   */
  public static lastAuthDateTime(jwtIdToken: string): string {
    const decoded = this.parseJwt(jwtIdToken);
    return format(new Date(decoded.iat * 1000), 'yyyy年MM月dd日(eee) HH:mm', {
      locale: ja,
    });
  }
}
