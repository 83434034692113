import { FC } from 'react';
import { Box, SelectChangeEvent, styled } from '@mui/material';
import { MonthOrYearSelection } from './MonthOrYearSelection';

interface BonusMonthSelectionFieldProps {
  error: boolean;
  onChangeBonusMonth1?: (e: SelectChangeEvent<any>) => void;
  onChangeBonusMonth2?: (e: SelectChangeEvent<any>) => void;
}

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 0',
});

const FieldsWrapper = styled(Box)(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  };
});

export const BonusMonthSelectionField: FC<BonusMonthSelectionFieldProps> = ({
  error,
  onChangeBonusMonth1,
  onChangeBonusMonth2,
}) => (
  <Wrapper>
    <FieldsWrapper>
      <MonthOrYearSelection
        disabled={error}
        trail="月と"
        type="month"
        onChange={onChangeBonusMonth1}
      />
      <MonthOrYearSelection
        disabled={error}
        trail="月"
        marginTop={1}
        type="month"
        onChange={onChangeBonusMonth2}
      />
    </FieldsWrapper>
  </Wrapper>
);
