/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useEffect } from 'react';
import useSWR from 'swr';
import { appClient } from '@/services';
import { ConvertAssociationContribution } from '@/services/convert/associationContribution';
import {
  associationModel,
  userContributionModel,
  userSecuritiesAccountsModel,
} from './model';

import type { UserSecuritiesAccounts } from './model';

export const useMyPageHooks = () => {
  const [userSecuritiesAccounts, setUserSecuritiesAccounts] = useState<
    UserSecuritiesAccounts | undefined
  >(undefined);

  // 【API-A-1002】持株会（事務局）拠出情報取得
  const { data: findAssociationContribution } = useSWR(
    '/api/associations/{associationCd}/contributions',
    () => appClient.associations.findAssociationContribution()
  );

  // 【API-A-0906】証券口座情報取得
  const {
    data: userSecuritiesAccountsData,
    error: userSecuritiesAccountsError,
    mutate: userSecuritiesAccountsMutate,
  } = useSWR('/api/securitiesaccounts', () =>
    appClient.users.findSecuritiesAccount()
  );

  // 【API-A-1001】持株会（事務局）情報
  const { data: associationData, error: associationError } = useSWR(
    '/api/associations/{associationCd}',
    () => appClient.associations.findAssociationById()
  );

  // 【API-A-0905】拠出情報取得
  const { data: userContributionData, error: userContributionError } = useSWR(
    '/api/users/contributions',
    () => appClient.users.findUserContribution()
  );

  useEffect(() => {
    if (userSecuritiesAccountsData) {
      const securitiesAccount = userSecuritiesAccountsModel(
        userSecuritiesAccountsData
      );
      setUserSecuritiesAccounts(securitiesAccount);
    }
  }, [userSecuritiesAccountsData]);

  // data fetching
  const userSecuritiesAccountsLoading =
    !userSecuritiesAccountsData && !userSecuritiesAccountsError;
  const associationLoading = !associationData && !associationError;

  return {
    // state関連
    userSecuritiesAccounts,
    // loading関連
    isLoading: userSecuritiesAccountsLoading && associationLoading,
    // api取得関連
    association: associationData
      ? associationModel(associationData)
      : associationData,
    userSecuritiesAccountsMutate,
    userContribution: userContributionData
      ? userContributionModel(userContributionData)
      : userContributionData,
    associationContribution: findAssociationContribution
      ? ConvertAssociationContribution(findAssociationContribution)
      : findAssociationContribution,
    // error関連
    userSecuritiesAccountsError,
    associationError,
    userContributionError,
  };
};
