import { atom } from 'recoil';

export const PaginationPageState = atom({
  key: 'paginationPageState',
  default: 1,
});

export const PaginationPerPageSizeState = atom({
  key: 'paginationPerPageSizeState',
  default: 10,
});

export const PaginationTotalPageState = atom({
  key: 'paginationTotalPageState',
  default: 0,
});

export const PaginationTotalSizeState = atom({
  key: 'paginationTotalSizeState',
  default: 0,
});
