import { FC } from 'react';
import { PrimaryTable } from '@/components/molecules/Table/PrimaryTable';
import { PrimaryTableBodyTdMulti } from '@/components/atoms/Table/PrimaryTableBodyTdMulti';
import { PrimaryTableBodyTdSingleWithSpNotRed } from '@/components/atoms/Table/PrimaryTableBodyTdSingleWithSpNotRed';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { appClient } from '@/services';
import useSWR from 'swr';
import { numberFormat } from '@/utils/numberFormat';
import { FindApplicationById } from '@/types/api/applications';

interface Props {
  applicationPart: FindApplicationById['applicationPart'];
}

export const Part: FC<Props> = ({ applicationPart }) => {
  // 【API-A-0909】資産情報取得
  const { data: userAsset } = useSWR('/api/users/assets', () =>
    appClient.users.findUserAsset()
  );

  const { data: associationData } = useSWR(['findAssociation'], () =>
    appClient.associations.findAssociationById()
  );

  if (userAsset?.stocksNumber) {
    return (
      <>
        <BasicTable
          title="証券口座"
          name="東海東京証券"
          departmentCode="******"
          departmentName="******"
          accountNumber="******"
          type="securities"
        />
        <PrimaryTable title="引出株数">
          <PrimaryTableBodyTdSingleWithSpNotRed
            title="現在の保有株数"
            value={userAsset.stocksNumber.toLocaleString(undefined, {
              maximumFractionDigits: associationData?.investmentDigit,
            })}
          />
          <PrimaryTableBodyTdMulti title="引出株数">
            {numberFormat(applicationPart?.sharesNum || 0)}株
          </PrimaryTableBodyTdMulti>
          <PrimaryTableBodyTdMulti title="残る株数">
            {numberFormat(applicationPart?.leftSharesNum || 0)}株
          </PrimaryTableBodyTdMulti>
        </PrimaryTable>
      </>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
