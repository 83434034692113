import { FC, useEffect, useState, useCallback } from 'react';
import { Box, styled, Theme, Typography } from '@mui/material';
import { HomeHeader } from '@/components/organism/HomeHeader';
import { ApplicationTable } from '@/components/organism/Application/ApplicationIndex/ApplicationTable';
import { BREAKPOINT } from '@/theme/theme';
import { appClient } from '@/services';
// import useSWR from 'swr';
import { Application } from '@/types/api/application';
import { Path } from '@/constants/Router/path';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
  PaginationPageState,
  PaginationPerPageSizeState,
  PaginationTotalPageState,
  PaginationTotalSizeState,
} from '@/recoil/atom/pagination';
import { ApplicationSortKeyState } from '@/recoil/atom/application';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const TopWrapper = styled('div')(({ theme }) => {
  const { breakpoints, palette } = theme;
  return {
    padding: '33px 60px',
    width: '100%',
    backgroundColor: palette.system.background,
    [breakpoints.down(BREAKPOINT)]: {
      padding: '24px 16px',
    },
    [breakpoints.up('lg')]: {
      padding: '33px 230px',
    },
  };
});

const description = [
  'これまでにあなたが申し込んだすべての申請履歴を見ることができます。',
  '※処理前の申請を取り消す場合には、申請をクリックして詳細画面から手続きしてください。',
];

export const ApplicationsIndex: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [applications, setApplications] = useState<Application[]>();
  const sortKey = useRecoilValue(ApplicationSortKeyState);
  const page = useRecoilValue(PaginationPageState);
  const [perPageSize, setPerPageSize] = useRecoilState(
    PaginationPerPageSizeState
  );
  const [, setTotalPage] = useRecoilState(PaginationTotalPageState);
  const [totalSize, setTotalSize] = useRecoilState(PaginationTotalSizeState);
  useHelmetHandler({
    title: '申請一覧 トップ',
  });

  const getListApplications = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await appClient.applications.listApplications(
        page,
        perPageSize,
        sortKey
      );
      if (res) {
        setPerPageSize(res.perPageSize || 10);
        setTotalPage(res.totalPage || 0);
        setTotalSize(res.totalSize || 0);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setApplications(res.applications);
      }
      setIsLoading(false);
    } catch (e) {
      // Handle getListApplications error
      setIsLoading(false);
    }
  }, [page, perPageSize, sortKey]);

  const renderContent = useCallback(
    () =>
      applications && applications.length > 0 ? (
        <ApplicationTable applications={applications} />
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '450px',
            background: (theme: Theme) => theme.palette.system.background,
          }}
        >
          <Typography variant="body-main/medium">申請はありません。</Typography>
          <Typography variant="body-main/medium">
            各種お手続き内の申請を行うと、このページに追加されます。
          </Typography>
        </Box>
      ),
    [applications]
  );

  useEffect(() => {
    void getListApplications();
  }, [getListApplications]);

  return (
    <>
      <TopWrapper>
        <HomeHeader
          title="申請履歴"
          description={description}
          links={[
            { path: 'TOP', to: Path.HOME },
            { path: '申請履歴', to: Path.APPLICATIONS },
          ]}
        />
      </TopWrapper>
      {isLoading && totalSize === 0 ? null : renderContent()}
    </>
  );
};
