import { LoginTitleWithUnderline } from '@/components/molecules/Login/LoginTitleWithUnderline';
import { LoginWideBox } from '@/components/molecules/Login/LoginWideBox';
import { SignUpUpper } from '@/components/organism/SignUp/SignUpUpper';
import { Box, styled, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PopoverWithText } from '@/components/molecules/Popover/PopoverWithText';
import { ReactComponent as QuestionMarkIcon } from '@/assets/questionMarkIcon.svg';
import { TextButton } from '@/components/atoms/Buttons/TextButton';
import { appClient } from '@/services';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { Path } from '@/constants/Router/path';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { LoginLabelWithTextInput } from '@/components/molecules/Login/LoginLabelWithTextInput';
import { ErrMessageWithIcon } from '@/components/molecules/Message/ErrMessageWithIcon';
import { useAuthUser } from '@/hooks/useAuth';
import { USER } from '@/constants';
import { BREAKPOINT } from '@/theme/theme';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const SignUpLower = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0px 32px',
  gap: '40px',
  width: '100%',
  [theme.breakpoints.down(BREAKPOINT)]: {
    padding: '32px 16px',
    gap: '32px',
  },
}));

const SignUpLowerInner = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: 0,
  gap: '24px',
  width: '100%',
});

const NormalTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography['body-main/regular'],
  color: theme.palette.system['text-normal'],
  textAlign: 'left',
  '& span': {
    ...theme.typography['body-main/bold'],
    color: theme.palette.secondary.main,
    display: 'block',
  },
}));

const MfaBox = styled(Box)(({ theme }) => ({
  background: theme.palette.system['background-light'],
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '24px',
  gap: '24px',
  width: '100%',
  height: '170px',
}));

const MfaTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography['body-main/bold'],
  fontFamily: 'Noto Sans JP',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '150%',
}));

const MfaText = styled(Typography)(({ theme }) => ({
  ...theme.typography['body-sub/regular'],
  color: theme.palette.system['text-light'],
  textAlign: 'left',
}));

const MfaLink = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  gap: '16px',
  width: '100%',
});

const MfaFormBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '8px',
  gap: '24px',
  width: '100%',
  [theme.breakpoints.down(BREAKPOINT)]: {
    padding: 0,
    gap: '24px',
    alignItems: 'flex-start',
    height: '152px',
    '& button': {
      width: '100%',
    },
  },
}));

const MfaFormt = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: 0,
  gap: '24px',
  width: '100%',
  [theme.breakpoints.down(BREAKPOINT)]: {
    flexDirection: 'column',
    gap: '8px',
  },
}));

const errorMessage = [
  '認証コードが正しくありません。入力内容をご確認ください。',
];

type FormInputState = {
  mfaCode: string;
};

type LoginState = {
  loginId: string;
  loginPassword: string;
  mfaSession: string;
};

// バリデーションルール
export const schema = yup.object({
  mfaCode: yup.string().required('入力してください'),
});

export const SignUpMfa: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LoginState;
  const { handleSetToken } = useAuthUser();
  const [isError, setIsError] = useState(false);
  useHelmetHandler({
    title: '持株会入会登録 多要素認証 認証完了',
  });

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<FormInputState>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  });

  // Mfaコード再送信
  const handleMfaCodeSubmit = async () => {
    try {
      const response = await appClient.authorization.authSignin({
        loginId: state.loginId,
        loginPassword: state.loginPassword,
      });
      setIsError(false);
      if (response.Session !== undefined) {
        state.mfaSession = response.Session;
        setValue('mfaCode', '');
      }
    } catch {
      // エラー
      if (state === null) {
        navigate(Path.SIGNUP_JOIN);
      }
    }
  };

  const onSubmit = async (data: FormInputState) => {
    // バリデーションチェック
    try {
      const response = await appClient.authorization.authMfaConfirm({
        loginId: state.loginId,
        mfaCode: data.mfaCode,
        mfaSession: state.mfaSession,
      });
      // トークンをセット
      if (response.IdToken) {
        const idToken = `Bearer ${response.IdToken}`;
        // api clientのヘッダーにトークンを設定
        appClient.request.config.HEADERS = { Authorization: idToken };
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const data = await appClient.users.findUser();
        if (data) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          switch (data.userStatus) {
            case USER.STATUS.VARIFIED_MAIL:
              // 会員入力画面へ
              navigate(Path.CREATE_USER_INPUT);
              break;
            case USER.STATUS.PERSONAL_INFORMATION_ENTERED:
              // 拠出画面へ
              navigate(Path.CONTRIBUTION_INPUT);
              break;
            default:
              // localstorageにも保存させる
              handleSetToken(response);
              // 会員画面へ ここへはこない想定
              navigate(Path.HOME, { replace: true });
              break;
          }
        }
      } else {
        // エラー
        setIsError(true);
      }
    } catch (e) {
      // エラー
      setIsError(true);
    }
  };

  useEffect(() => {
    // stateがない場合は戻る
    if (state === null) {
      navigate(Path.SIGNUP_JOIN);
    }
  }, []);

  return (
    <LoginWideBox>
      <SignUpUpper isSignUp flowOn={1} />
      <SignUpLower>
        <SignUpLowerInner />
        <LoginTitleWithUnderline isLogin title="多要素認証">
          <PopoverWithText icon={<QuestionMarkIcon />}>
            第三者からのアクセスを防ぎ、セキュリティを強化するために必要な認証です。
          </PopoverWithText>
        </LoginTitleWithUnderline>
        <NormalTypography>
          メールアドレス宛に認証コードを送信しました。
          <br />
          メールに掲載されたコードを下の入力欄に入力してください。
        </NormalTypography>
        <MfaFormBox>
          {isError && <ErrMessageWithIcon errorMessage={errorMessage} />}
          <MfaFormt>
            <LoginLabelWithTextInput
              label="認証コード"
              annotation=""
              error={'mfaCode' in errors}
              helperText={errors.mfaCode?.message}
              placeholder="認証コードを入力"
              flat
              {...register('mfaCode')}
            />
          </MfaFormt>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <PrimaryButton
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleSubmit(onSubmit)}
            >
              送信する
            </PrimaryButton>
          </Box>
        </MfaFormBox>
        <MfaBox>
          <MfaTitle>※認証コードが届かない場合</MfaTitle>
          <MfaText>
            認証コードが届かない場合は、下の「認証コードを再送信する」ボタンからコードを再送信してください。
          </MfaText>
          <MfaLink
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleMfaCodeSubmit}
          >
            <TextButton>認証コードを再送信する</TextButton>
          </MfaLink>
        </MfaBox>
      </SignUpLower>
    </LoginWideBox>
  );
};
