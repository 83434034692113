import { useState } from 'react';
import useSWR, { Fetcher } from 'swr';
import { PostcodeJPService } from '@/services';
import { PostCodeAddress } from '@/types/api/postCodeAddress';

export type Address = PostCodeAddress[] | undefined;

/**
 * useGetAddressApi
 * use api request for post code jp
 */
export const useGetAddressApi = () => {
  const [postCode, setPostCode] = useState<string>('');

  const fetcher: Fetcher<Address | undefined> = () =>
    PostcodeJPService.getAddress(postCode);
  const {
    data: addressData,
    error: addressError,
    mutate: addressMutate,
  } = useSWR<Address, Error>(['/api/postcode', postCode], fetcher);

  /**
   * setPostCode
   * @param code {string} post code
   */
  const getAddress = (code: string): void => {
    setPostCode(code);
  };

  return {
    isLoading: !addressData && !addressError,
    addressData,
    addressError,
    addressMutate,
    getAddress,
  };
};
