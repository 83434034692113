import { theme } from '@/theme/theme';
import { Box, styled, SvgIcon } from '@mui/material';
import { ReactComponent as DropdownArrow } from '@/assets/dropdownArrow.svg';
import { ResultInfoBig } from '@/components/molecules/Simulation/ResultInfoBig';
import { ResultCaptions } from '@/components/molecules/Simulation/ResultCaptions';
import { ResultInfoSmall } from '@/components/molecules/Simulation/ResultInfoSmall';
import {
  GraphBar,
  LineGraphItem,
} from '@/components/molecules/Simulation/GraphBar';
import {
  CandlestickItem,
  GraphCandleStick,
} from '@/components/molecules/Simulation/GraphCandleStick';

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 40,
  width: '100%',
});

const InfoWrapper = styled(Box)({
  width: '100%',
  marginTop: 8,
  borderRadius: 8,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'rgba(0, 0, 0, 0.2)',
  overflow: 'hidden',
});

const CaptionsWrapper = styled(Box)(() => {
  const { breakpoints } = theme;

  return {
    marginTop: 28,
    width: '100%',

    [breakpoints.down('sm')]: {
      display: 'none',
    },
  };
});

const ResultInfoGroup = styled(Box)(() => {
  const { breakpoints } = theme;

  return {
    display: 'flex',
    flexDirection: 'row',

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  };
});

const GraphGroup = styled(Box)(() => {
  const { breakpoints } = theme;
  return {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  };
});

export type SimulateResultProps = {
  cumulativeContribution: number;
  marketValue: number;
  differenceValue: number;
  incentivesTotal: number;
  dividendTotal: number;
  reserveTotal: number;
  acquiredSharesNum: number;
  candlestick: CandlestickItem[];
  lineGraph: LineGraphItem[];
};

type SimulationResultProps = {
  simulation: SimulateResultProps;
  accumulationFrom: string; // date
  accumulationTo: string; // date
};

export const SimulationResult = ({
  simulation,
  accumulationFrom,
  accumulationTo,
}: SimulationResultProps) => {
  const valuationPercent =
    (simulation.marketValue / simulation.cumulativeContribution) * 100;
  const scaleMax = Math.ceil(
    Math.max(...simulation.lineGraph.map((p) => p.verticalValue1)) >
      Math.max(...simulation.lineGraph.map((p) => p.verticalValue2))
      ? Math.max(...simulation.lineGraph.map((p) => p.verticalValue1))
      : Math.max(...simulation.lineGraph.map((p) => p.verticalValue2))
  );
  return (
    <Wrapper>
      <SvgIcon
        sx={{
          width: 24,
          height: 24,
          fill: () => theme.palette.states.error,
        }}
      >
        <DropdownArrow />
      </SvgIcon>
      <SvgIcon
        sx={{
          width: 24,
          height: 24,
          marginTop: -1.5,
          fill: () => theme.palette.states.error,
        }}
      >
        <DropdownArrow />
      </SvgIcon>
      <CaptionsWrapper>
        <ResultCaptions
          caption=""
          captionBold="※シミュレーションのため正確な積立額とは多少異なります。"
        />
      </CaptionsWrapper>
      <InfoWrapper>
        <ResultInfoGroup>
          <ResultInfoBig
            title="拠出金累計"
            titleExtension="(積立合計金額)"
            amount={simulation.cumulativeContribution.toLocaleString()}
            border={false}
            captions={false}
          />
          <ResultInfoBig
            title="積立期間終了時点の評価額"
            amount={simulation.marketValue.toLocaleString()}
            amountExtension={`${
              simulation.differenceValue.toLocaleString() || 0
            }円(${valuationPercent.toFixed(2)}%)`}
            border
            captions
          />
        </ResultInfoGroup>
        <ResultInfoGroup>
          <ResultInfoSmall
            leading="奨励金累計"
            trailing={`${simulation.incentivesTotal.toLocaleString()}円`}
          />
          <ResultInfoSmall
            leading="配当金累計"
            trailing={`${simulation.dividendTotal.toLocaleString()}円`}
          />
          <ResultInfoSmall
            leading="積立額累計"
            trailing={`${simulation.reserveTotal.toLocaleString()}円`}
          />
          <ResultInfoSmall
            leading="取得株数"
            trailing={`${simulation.acquiredSharesNum.toLocaleString()}株`}
          />
        </ResultInfoGroup>
      </InfoWrapper>
      <GraphGroup>
        {simulation.candlestick && (
          <GraphCandleStick candlestickData={simulation.candlestick} />
        )}
        {simulation.lineGraph && (
          <GraphBar
            startDate={accumulationFrom}
            endDate={accumulationTo}
            yMin={0}
            yMax={
              Math.ceil(scaleMax / 10 ** (scaleMax.toString().length - 2)) *
              10 ** (scaleMax.toString().length - 2)
            }
            lineGraphData={simulation.lineGraph}
          />
        )}
      </GraphGroup>
    </Wrapper>
  );
};
