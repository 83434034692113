import { FC } from 'react';
import { styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { BREAKPOINT } from '@/theme/theme';

const Wrap = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    margin: '40px 0 32px',
    [breakpoints.down(BREAKPOINT)]: {
      flexDirection: 'column',
      gap: '24px',
      margin: '16px 16px 40px',
      alignItems: 'unset',
    },
  };
});

const Description = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-main/regular'],
  };
});

export const ContactDescription: FC = () => {
  const navigate = useNavigate();
  const handleClickToFAQs = () => {
    navigate('/faq');
  };
  return (
    <Wrap>
      <Description>
        お問い合わせの前に、よくあるご質問もご確認ください。
      </Description>
      <PrimaryButton onClick={handleClickToFAQs}>
        よくあるご質問一覧はこちら
      </PrimaryButton>
    </Wrap>
  );
};
