import toshin from '@/assets/toshinNoMado.png';
import moneque from '@/assets/moneque.png';
import direct from '@/assets/direct.png';
// import cheerSyoken from '@/assets/cheerSyoken.png';

export const services = [
  {
    name: '投信の窓口',
    description: [
      '投資信託に関するお悩みを投資信託の専門家である「ファンドソムリエ」に無料でご相談いただけます。ご相談は対面の他、WEB相談もご用意し、お客様の期待リターンを実現させるピッタリな投資信託をご紹介いたします。',
    ],
    link: 'https://toushin-no-madoguchi.jp/?utm_source=ot&utm_medium=li&utm_campaign=2022052744',
    img: toshin,
  },
  {
    name: 'マニーク',
    description: [
      'マニークは保険の見直しだけではなく、住宅ローンや資産形成などもトータルにご提案いたします。幅広い分野に精通した専門スタッフが相談から契約、アフターフォローまで、様々なお金のお悩みにワンストップでお応えします。',
    ],
    link: 'https://moneque.tokaitokyo.co.jp/?utm_source=ot&utm_medium=li&utm_campaign=2022052745',
    img: moneque,
  },
  {
    name: 'かんたんダイレクトサービス',
    description: [
      'ネット取引をしながらコールセンターに相談もできるサービスです。国内株式手数料の割引に加え、取引に応じて商品券等に交換できる東海東京ポイントが貯まります。投資情報ルームでは、証券アナリストが発行するマーケットレポートを無料でご覧いただけます。',
    ],
    link: 'https://www.tokaitokyo.co.jp/kantan/?utm_source=ot&utm_medium=li&utm_campaign=2022052746',
    img: direct,
  },
  {
    name: 'CHEER証券',
    description: [
      `スマホで簡単口座開設、ワンコインでいつでもどこでも取引が可能なので`,
      `始めやすく、続けやすい、投資アプリです！`,
    ],
    link: 'https://www.cheer-sec.co.jp/',
    img: 'https://www.cheer-sec.co.jp/promo/img/ex_tt_employee_stock_ownership_01.png',
  },
];
