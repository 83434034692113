import { FC } from 'react';
import { MyPageIdOrganism } from '@/components/organism/MyPage/MyPageId';
import { useAuthUser } from '@/hooks/useAuth';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { useLoader, useSnackbarMessage } from '@/hooks';
import { appClient } from '@/services';
import { useNavigate } from 'react-router-dom';
import { UpdateUserLoginid } from '@/types/api/users';

export const MyPageId: FC = () => {
  const { user } = useAuthUser();
  const { showMessage } = useSnackbarMessage();
  const { handleLoaderOpen, handleLoaderClose } = useLoader();
  const navigate = useNavigate();

  useHelmetHandler({
    title: 'マイページ ログインID変更',
  });

  /**
   * handleSubmit
   * @param data
   */
  const handleSubmit = async (data: UpdateUserLoginid) => {
    try {
      handleLoaderOpen();
      await appClient.users.updateUserLoginid({
        email: data.email,
      });
      handleLoaderClose();
      navigate('/mypage/id/mfa', { state: { data } });
    } catch (e) {
      handleLoaderClose();
      console.error(e);
      showMessage({
        open: true,
        messageType: 'error',
        messages: ['ログインID変更に失敗しました'],
      });
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  return <MyPageIdOrganism user={user} handleSubmit={handleSubmit} />;
};
