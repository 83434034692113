/* eslint-disable react/destructuring-assignment */
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { styled, Typography, Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { TextButton } from '@/components/atoms/Buttons/TextButton';
import { ReactComponent as LogOut } from '@/assets/logout.svg';
import { ReactComponent as User } from '@/assets/user.svg';
import { ReactComponent as Kuchisuhenkou } from '@/assets/kuchisuhenkou.svg';
import { ReactComponent as Ichibuhikidasi } from '@/assets/ichibuhikidasi.svg';
import { ReactComponent as Taikai } from '@/assets/taikai.svg';
import { ReactComponent as Kyusi } from '@/assets/kyusi.svg';
import { ReactComponent as Resume } from '@/assets/resume.svg';
import { ReactComponent as Arrow } from '@/assets/arrow.svg';
import logo from '@/assets/logo.png';
import { useAuthUser } from '@/hooks/useAuth';
import { useLogoutModal, useFindAssociationById } from '@/hooks';
import { useHomeHooks } from '@/components/pages/Home/hooks/useHomeHooks';

import { Path } from '@/constants/Router/path';
import useSWR from 'swr';
import { appClient } from '@/services';
import { ConvertAssociationContribution } from '@/services/convert/associationContribution';
import { AssociationContribution } from '@/types/api/associationContribution';
import { useUserApplicationStatus } from '@/hooks/useUserApplicationStatus';

const LayoutHeader = styled('header')(({ theme }) => ({
  position: 'fixed',
  width: '100%',
  top: 0,
  zIndex: 10,
  backgroundColor: theme.palette.system.main,

  '@media print': {
    display: 'none',
  },
}));

const Img = styled('img')({
  width: '125px',
  cursor: 'pointer',
});

const UserMenuArea = styled('div')({
  display: 'flex',
  'button:first-of-type': {
    marginRight: '18px',
  },
});

const LogoArea = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 0',
    [breakpoints.up('md')]: {
      padding: '20px 40px',
    },
    [breakpoints.up('lg')]: {
      padding: '20px 130px',
    },
  };
});

const LinkArea = styled('div')(({ theme }) => {
  const { breakpoints, palette } = theme;
  return {
    display: 'flex',
    background: palette.system['background-light'],
    borderTop: `1px solid`,
    borderBottom: `1px solid`,
    borderColor: palette.system['separator-light'],
    justifyContent: 'space-between',
    'button:first-of-type': {
      marginRight: '18px',
    },
    [breakpoints.up('md')]: {
      padding: '14px 40px',
    },
    [breakpoints.up('lg')]: {
      padding: '14px 130px',
    },
  };
});

const SubLinkArea = styled('div', {
  shouldForwardProp: (prop) => prop !== 'show',
})<{ show?: boolean }>(({ show, theme }) => ({
  display: show ? 'flex' : 'none',
  borderBottom: '1px solid',
  borderColor: theme.palette.system['separator-light'],
  width: '100%',
  height: '96px',
  justifyContent: 'center',
  alignItems: 'center',
  'button:first-of-type': {
    marginRight: '18px',
  },
}));

const SubLink = styled(Link)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 40px',
  color: theme.palette.system['text-normal'],
  textAlign: 'center',
  textDecoration: 'none',
  '&:hover': {
    background: theme.palette.system['background-light'],
  },
  '&:last-child': {
    marginRight: '0',
  },
  span: {
    marginLeft: '8px',
  },
}));

const MuiLink = styled(Link)(({ theme }) => {
  const { palette } = theme;
  return {
    color: palette.system['text-normal'],
    textDecoration: 'none',
    '&:hover': {
      color: palette.primary.main,
    },
    '&:last-child': {
      marginRight: '0',
    },
  };
});

const HoverMenu = styled('p', {
  shouldForwardProp: (prop) => prop !== 'show',
})<{ show?: boolean }>(({ show, theme }) => ({
  color: show
    ? theme.palette.primary.main
    : theme.palette.system['text-normal'],
  textDecoration: 'none',
  cursor: 'pointer',
  margin: 0,
  svg: {
    transform: show && 'rotate(180deg)',
  },
  path: {
    stroke: show
      ? theme.palette.primary.main
      : theme.palette.system['text-normal'],
  },
  span: {
    marginLeft: '8px',
  },
}));

export const HeaderPc = () => {
  const navigate = useNavigate();
  const { isPersonalInformationEntered, user } = useAuthUser();
  const { associationData } = useFindAssociationById(user.associationCd ?? '');
  const { handleLogoutModalOpen } = useLogoutModal();
  const [menu, setMenu] = useState<null | 'procedure' | 'guide'>(null);
  const [, setShowChangeLot] = useState<boolean>(false);
  const { isListedClassification } = useHomeHooks();

  const [associationContribution, setAssociationContribution] =
    useState<AssociationContribution>();

  // 【API-A-1002】持株会（事務局）拠出情報取得
  const { data: findAssociationContribution } = useSWR(
    '/api/associations/{associationCd}/contributions',
    () => appClient.associations.findAssociationContribution()
  );

  const {
    notOperatePay,
    notOperatePart,
    notOperateRecess,
    notOperateWithdrawal,
  } = useUserApplicationStatus();

  useEffect(() => {
    setAssociationContribution(
      ConvertAssociationContribution(findAssociationContribution)
    );
  }, [findAssociationContribution]);

  const isNotOperatePay =
    notOperatePay ||
    associationContribution?.monthlyUnitClassification !== '1' ||
    !(
      user.userStatus === 'NORMAL' ||
      user.userStatus === 'BEING_RESUMEMENT_PROCESSED'
    );
  const isNotOperatePart =
    notOperatePart ||
    !(
      user.userStatus === 'NORMAL' ||
      user.userStatus === 'BEING_RECESSMENT_PROCESSED' ||
      user.userStatus === 'RECESS' ||
      user.userStatus === 'BEING_RESUMEMENT_PROCESSED'
    );
  const isNotOpreateRecess =
    notOperateRecess || !(user.userStatus === 'NORMAL');
  const isNotOpreateWithdrawal =
    notOperateWithdrawal ||
    !(user.userStatus === 'NORMAL' || user.userStatus === 'RECESS');

  const isNotBeingEnrolled = user.userStatus !== 'BEING_ENROLLMENT_PROCESSED';

  return (
    <LayoutHeader>
      <LogoArea onMouseEnter={() => setMenu(null)}>
        <Img src={logo} alt="Logo" onClick={() => navigate(Path.HOME)} />
        <UserMenuArea>
          {isPersonalInformationEntered && (
            <SecondaryButton>
              <User />
              <MuiLink to={Path.MYPAGE}>
                <Typography variant="body-sub/medium" sx={{ ml: '6px' }}>
                  マイページ
                </Typography>
              </MuiLink>
            </SecondaryButton>
          )}
          <TextButton
            sx={{ textDecoration: ' none' }}
            onClick={handleLogoutModalOpen}
          >
            <LogOut />
            <Typography variant="body-sub/medium" sx={{ ml: '6px' }}>
              ログアウト
            </Typography>
          </TextButton>
        </UserMenuArea>
      </LogoArea>
      <LinkArea onMouseEnter={() => setShowChangeLot(false)}>
        <MuiLink to={Path.HOME} onMouseEnter={() => setMenu(null)}>
          <Typography variant="body-sub/medium">TOP</Typography>
        </MuiLink>
        <HoverMenu
          show={menu === 'guide'}
          onMouseEnter={() => setMenu('guide')}
        >
          <Typography variant="body-sub/medium" sx={{ mr: '8px' }}>
            初めての方へ
          </Typography>
          <Arrow />
        </HoverMenu>
        <MuiLink to={Path.APPLICATIONS} onMouseEnter={() => setMenu(null)}>
          <Typography variant="body-sub/medium">申請履歴</Typography>
        </MuiLink>
        {isNotBeingEnrolled && (
          <MuiLink to={Path.ASSETS} onMouseEnter={() => setMenu(null)}>
            <Typography variant="body-sub/medium">資産状況</Typography>
          </MuiLink>
        )}
        <HoverMenu
          show={menu === 'procedure'}
          onMouseEnter={() => setMenu('procedure')}
        >
          <Typography variant="body-sub/medium" sx={{ mr: '8px' }}>
            各種お手続き
          </Typography>
          <Arrow />
        </HoverMenu>
        <MuiLink to={Path.REPORT} onMouseEnter={() => setMenu(null)}>
          <Typography variant="body-sub/medium">電子交付サービス</Typography>
        </MuiLink>
        {associationData &&
        associationData?.listedClassification === '1' &&
        !!associationData.allowSimulationFlg ? (
          <MuiLink to={Path.SIMULATION}>
            <Typography variant="body-sub/medium">
              積立シミュレーション
            </Typography>
          </MuiLink>
        ) : (
          <Box sx={{ opacity: '0.3' }}>
            <Typography variant="body-sub/medium">
              積立シミュレーション
            </Typography>
          </Box>
        )}
        <MuiLink to={Path.SERVICE}>
          <Typography variant="body-sub/medium">サービス</Typography>
        </MuiLink>
        <MuiLink to={Path.FAQ}>
          <Typography variant="body-sub/medium">よくあるご質問</Typography>
        </MuiLink>
      </LinkArea>
      <SubLinkArea
        show={!!menu}
        onMouseLeave={() => {
          setMenu(null);
          setShowChangeLot(false);
        }}
      >
        {menu === 'guide' ? (
          <>
            <SubLink to={Path.GUIDE}>
              <Typography variant="body-sub/medium">
                会員向けご利用ガイド
              </Typography>
            </SubLink>
            <SubLink to={Path.STRUCTURE}>
              <Typography variant="body-sub/medium">持株会のしくみ</Typography>
            </SubLink>
          </>
        ) : (
          <>
            <SubLink
              to={isNotOperatePay ? '#' : Path.PAY}
              sx={{
                opacity: isNotOperatePay ? 0.5 : 1,
                pointerEvents: isNotOperatePay ? 'none' : undefined,
              }}
            >
              <Kuchisuhenkou />
              <Typography
                variant="body-sub/medium"
                sx={{ ml: '8px', m: '8px' }}
              >
                口数を変更する
              </Typography>
            </SubLink>
            {isListedClassification && (
              <SubLink
                onMouseEnter={() => setShowChangeLot(false)}
                to={isNotOperatePart ? '#' : Path.PART}
                sx={{
                  opacity: isNotOperatePart ? 0.5 : 1,
                  pointerEvents: isNotOperatePart ? 'none' : undefined,
                }}
              >
                <Ichibuhikidasi />
                <Typography variant="body-sub/medium" sx={{ ml: '8px' }}>
                  一部を引き出す
                </Typography>
              </SubLink>
            )}
            {/* eslint-disable-next-line no-nested-ternary */}
            {user.userStatus === 'RECESS' ? (
              <SubLink
                to={Path.RESUME}
                sx={{
                  opacity: 1,
                  pointerEvents: undefined,
                }}
              >
                <Resume />
                <Typography variant="body-sub/medium" sx={{ ml: '8px' }}>
                  再開する
                </Typography>
              </SubLink>
            ) : (
              <SubLink
                to={isNotOpreateRecess ? '#' : Path.RECESS}
                sx={{
                  opacity: isNotOpreateRecess ? 0.5 : 1,
                  pointerEvents: isNotOpreateRecess ? 'none' : undefined,
                }}
              >
                <Kyusi />
                <Typography variant="body-sub/medium" sx={{ ml: '8px' }}>
                  休止する
                </Typography>
              </SubLink>
            )}
            <SubLink
              to={isNotOpreateWithdrawal ? '#' : Path.WITHDRAWAL}
              sx={{
                opacity: isNotOpreateWithdrawal ? 0.5 : 1,
                pointerEvents: isNotOpreateWithdrawal ? 'none' : undefined,
              }}
            >
              <Taikai />
              <Typography variant="body-sub/medium" sx={{ ml: '8px' }}>
                退会する
              </Typography>
            </SubLink>
          </>
        )}
      </SubLinkArea>
    </LayoutHeader>
  );
};
