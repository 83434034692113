/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { HomeHeader } from '@/components/organism/HomeHeader';
import { ReportTable } from '@/components/organism/Report/ReportTable';
import { Path } from '@/constants/Router/path';
import { useSnackbarMessage } from '@/hooks';
import {
  useListDividendPaymentCertificate,
  useListEquitySettlementForm,
} from '@/hooks/api/useReports';
import { useAuthUser } from '@/hooks/useAuth';
import { appClient } from '@/services';
import { BREAKPOINT } from '@/theme/theme';
import { styled } from '@mui/material';
import { FC } from 'react';

const Wrapper = styled('div')(({ theme }) => {
  const { breakpoints, palette } = theme;
  return {
    width: '100%',
    padding: '32px 40px 80px',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    backgroundColor: palette.system.background,
    [breakpoints.down(BREAKPOINT)]: {
      padding: '24px 0px 80px',
      gap: '24px',
    },
    [breakpoints.up('lg')]: {
      padding: '32px 230px 80px',
      gap: '24px',
    },
  };
});

const description = ['各種帳票をpdfとしてダウンロードできます。'];

export const Report: FC = () => {
  const { showMessage } = useSnackbarMessage();
  const { data: dataDividend } = useListDividendPaymentCertificate();
  const { data: dataEquity } = useListEquitySettlementForm();
  const { user } = useAuthUser();

  const downloadEquitySettlementFormReport = async (
    year: string,
    month: string,
    day: string
  ) => {
    try {
      const response = await appClient.reports.equitySettlementFormReportsDl(
        year,
        month,
        day
      );
      if (response) {
        // download
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const blob = new Blob([response], {
          type: 'application/pdf; charset=utf-8',
        });
        const url = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        document.body.appendChild(anchor);
        anchor.download = 'download.pdf';
        anchor.href = url;
        anchor.click();
        anchor.remove();
        URL.revokeObjectURL(url);
      } else {
        showMessage({
          open: true,
          messageType: 'error',
          messages: ['PDFダウンロードに失敗しました'],
        });
      }
    } catch (e) {
      showMessage({
        open: true,
        messageType: 'error',
        messages: ['PDFダウンロードに失敗しました'],
      });
    }
  };

  const downloadWithdrawalSettlementFormReport = async (
    reportType: 'settlement' | 'dividend'
  ) => {
    const response = await appClient.reports.withdrawalSettlementFormReportsDl(
      reportType
    );
    try {
      if (response) {
        // download
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const blob = new Blob([response], {
          type: 'application/pdf; charset=utf-8',
        });
        const url = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        document.body.appendChild(anchor);
        anchor.download = 'download.pdf';
        anchor.href = url;
        anchor.click();
        anchor.remove();
        URL.revokeObjectURL(url);
      } else {
        showMessage({
          open: true,
          messageType: 'error',
          messages: ['PDFダウンロードに失敗しました'],
        });
      }
    } catch (e) {
      showMessage({
        open: true,
        messageType: 'error',
        messages: ['PDFダウンロードに失敗しました'],
      });
    }
  };

  const downloadDividendPaymentCertificateReport = async (reportId: number) => {
    try {
      const response =
        await appClient.reports.dividendPaymentCertificateReportsDl(
          reportId || 0
        );
      if (response) {
        // download
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const blob = new Blob([response], {
          type: 'application/pdf; charset=utf-8',
        });
        const url = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        document.body.appendChild(anchor);
        anchor.download = 'download.pdf';
        anchor.href = url;
        anchor.click();
        anchor.remove();
        URL.revokeObjectURL(url);
      } else {
        showMessage({
          open: true,
          messageType: 'error',
          messages: ['PDFダウンロードに失敗しました'],
        });
      }
    } catch (e) {
      showMessage({
        open: true,
        messageType: 'error',
        messages: ['PDFダウンロードに失敗しました'],
      });
    }
  };

  return (
    <Wrapper>
      <HomeHeader
        title="電子交付サービス"
        links={[
          { path: 'TOP', to: Path.HOME },
          { path: '電子交付サービス', to: Path.REPORT },
        ]}
        description={description}
      />
      <ReportTable
        dataDividend={dataDividend}
        dataEquity={dataEquity}
        isWithdrawn={user.userStatus === 'WITHDRAWN'}
        downloadEquitySettlementFormReport={downloadEquitySettlementFormReport}
        downloadDividendPaymentCertificateReport={
          downloadDividendPaymentCertificateReport
        }
        downloadWithdrawalSettlementFormReport={
          downloadWithdrawalSettlementFormReport
        }
      />
    </Wrapper>
  );
};
