/* eslint-disable @typescript-eslint/no-unsafe-call */
import useSWR from 'swr';
import { appClient } from '@/services';
import { ConvertAssociation } from '@/services/convert/association';
import type { FindAssociationById } from '@/types/api/associations';

/**
 * useFindAssociationById
 * 【API-A-1001】持株会（事務局）情報
 * 持株会（事務局）情報を取得する
 * @param associationCd
 */
export const useFindAssociationById = (associationCd: string) => {
  const { data, error } = useSWR<FindAssociationById, Error>(
    associationCd === '' ? null : `/api/associations/${associationCd}`,
    () => appClient.associations.findAssociationById()
  );

  return {
    associationData: ConvertAssociation(data),
    isLoading: !data && !error,
    isAssociationError: error,
  };
};
