import { FC } from 'react';
import { PrimaryTable } from '@/components/molecules/Table/PrimaryTable';
import { PrimaryTableBodyTdMulti } from '@/components/atoms/Table/PrimaryTableBodyTdMulti';
import { Typography } from '@mui/material';
import { FindApplicationById } from '@/types/api/applications';

interface Props {
  applicationRecess: FindApplicationById['applicationRecess'];
}

export const Recess: FC<Props> = ({ applicationRecess }) => {
  const renderReason = (
    reason:
      | Exclude<FindApplicationById['applicationRecess'], undefined>['reason']
      | undefined
  ) => {
    switch (reason) {
      case 'LEAVE_ABSENCE': {
        return '休職/退職';
      }
      case 'FAMILY_MATTERS': {
        return '家族理由';
      }
      case 'ECONOMIC': {
        return '経済理由';
      }
      case 'OTHER': {
        return 'その他';
      }
      default: {
        return null;
      }
    }
  };

  return (
    <PrimaryTable title="休止理由">
      <PrimaryTableBodyTdMulti title="休止理由">
        <Typography sx={{ display: 'block' }}>
          {renderReason(applicationRecess?.reason)}
        </Typography>
        <Typography sx={{ display: 'block' }}>
          {applicationRecess?.otherReasons}
        </Typography>
      </PrimaryTableBodyTdMulti>
    </PrimaryTable>
  );
};
