import { Box, styled, SxProps, Theme } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface LinkProps {
  // リンク先名
  path: string;
  // リンク先
  to: string;
}

export interface Props {
  links: LinkProps[];
  // スタイル
  sx?: SxProps<Theme>;
}

const CurrentPath = styled('span')({
  fontSize: '14px',
});

const Slash = styled('span')({
  fontSize: '14px',
  margin: '0 4px;',
});

const MuiLink = styled(Link)(({ theme }) => ({
  fontSize: '14px',
  textDecorationColor: theme.palette.primary.normal,
  color: theme.palette.primary.normal,
}));

export const BreadCrumbs: FC<Props> = ({ links, sx }) => (
  <>
    {links.map((link, index) => (
      <Box display="flex" sx={sx} key={link.path}>
        {index !== 0 && <Slash>/</Slash>}
        {index === links.length - 1 ? (
          <CurrentPath>{link.path}</CurrentPath>
        ) : (
          <MuiLink to={link.to}>{link.path}</MuiLink>
        )}
      </Box>
    ))}
  </>
);
