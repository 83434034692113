import { useHomeHooks } from '@/components/pages/Home/hooks/useHomeHooks';
import { useEffect, useState } from 'react';
import { ApplicationsDomainService, UserDomainService } from '@/services';
import { APPLICATION } from '@/constants';
import { ListApplicationsItem } from '@/types/api/applications';
import { isObjectEmpty } from '@/utils/empty';
import { useAuthUser } from './useAuth';

export const useUserApplicationStatus = () => {
  const { applications, isListedClassification, updateApplications } =
    useHomeHooks();
  const { user, updateUser } = useAuthUser();
  const [applicationType, setApplicationType] = useState({
    hasRecess: false,
    hasResume: false,
    hasMonthlyUnit: false,
    hasBonusUnit: false,
    hasPart: false,
    hasWithdrawal: false,
  });

  const [userStatus, setUserStatus] = useState({
    hasRecess: false,
    hasNormal: false,
    hasBeingProcessedEnrollment: false,
    isRecess: false, // 休止
  });
  const [notOperatePay, setNotOperatePay] = useState<boolean>(false);
  const [notOperateBonus, setNotOperateBonus] = useState<boolean>(false);
  const [notOperateRecess, setNotOperateRecess] = useState<boolean>(false);
  const [notOperatePart, setNotOperatePart] = useState<boolean>(false);
  const [notOperateWithdrawal, setNotOperateWithdrawal] =
    useState<boolean>(false);

  useEffect(() => {
    setApplicationType({
      hasRecess: false,
      hasResume: false,
      hasMonthlyUnit: false,
      hasBonusUnit: false,
      hasPart: false,
      hasWithdrawal: false,
    });
    if (
      Array.isArray(applications?.applications) &&
      applications?.applications &&
      applications.applications.length > 0
    ) {
      setApplicationType({
        ...applicationType,
        hasRecess: ApplicationsDomainService.isApplicationDisabled(
          applications?.applications,
          APPLICATION.TYPE.RECESS as ListApplicationsItem['applicationType']
        ),
        hasResume: ApplicationsDomainService.isApplicationDisabled(
          applications?.applications,
          APPLICATION.TYPE.RESUME as ListApplicationsItem['applicationType']
        ),
        hasMonthlyUnit: ApplicationsDomainService.isApplicationDisabled(
          applications?.applications,
          APPLICATION.TYPE
            .MONTHLY_UNIT as ListApplicationsItem['applicationType']
        ),
        hasBonusUnit: ApplicationsDomainService.isApplicationDisabled(
          applications?.applications,
          APPLICATION.TYPE.BONUS_UNIT as ListApplicationsItem['applicationType']
        ),
        hasPart: ApplicationsDomainService.isApplicationDisabled(
          applications?.applications,
          APPLICATION.TYPE.PART as ListApplicationsItem['applicationType']
        ),
        hasWithdrawal: ApplicationsDomainService.isApplicationDisabled(
          applications?.applications,
          APPLICATION.TYPE.WITHDRAWAL as ListApplicationsItem['applicationType']
        ),
      });
    }
  }, [applications]);

  useEffect(() => {
    if (!isObjectEmpty(user)) {
      setUserStatus({
        ...userStatus,
        hasRecess: UserDomainService.isStatusForRecess(user),
        hasNormal: UserDomainService.isStatusForNormal(user),
        hasBeingProcessedEnrollment:
          UserDomainService.isStatusForBeingProcessedEnrollment(user),
        isRecess: user.userStatus === 'RECESS',
      });
    }
    if (isObjectEmpty(user)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateUser();
    }
  }, [user]);

  useEffect(() => {
    if (!!userStatus && !!applicationType) {
      // 給与口数を変更不可
      setNotOperatePay(
        userStatus.hasRecess ||
          applicationType.hasRecess ||
          applicationType.hasMonthlyUnit ||
          user.userStatus === 'BEING_WITHDRAWAL_PROCESSED' ||
          user.userStatus === 'WITHDRAWN' ||
          user.userStatus === 'BEING_RECESSMENT_PROCESSED'
      );
      // 賞与口数を変更不可
      setNotOperateBonus(
        userStatus.hasRecess ||
          applicationType.hasRecess ||
          applicationType.hasBonusUnit ||
          user.userStatus === 'BEING_WITHDRAWAL_PROCESSED' ||
          user.userStatus === 'WITHDRAWN' ||
          user.userStatus === 'BEING_RECESSMENT_PROCESSED'
      );
      // 一部引き出し不可(非上場または入会申請中)
      setNotOperatePart(
        !isListedClassification ||
          applicationType.hasPart ||
          userStatus.hasBeingProcessedEnrollment ||
          user.userStatus === 'BEING_WITHDRAWAL_PROCESSED' ||
          user.userStatus === 'WITHDRAWN'
      );
      // 休止不可
      setNotOperateRecess(
        userStatus.hasRecess ||
          userStatus.hasBeingProcessedEnrollment ||
          applicationType.hasRecess ||
          applicationType.hasResume ||
          user.userStatus === 'BEING_WITHDRAWAL_PROCESSED' ||
          user.userStatus === 'WITHDRAWN' ||
          user.userStatus === 'BEING_RECESSMENT_PROCESSED'
      );
      // 退会不可
      setNotOperateWithdrawal(
        userStatus.hasBeingProcessedEnrollment ||
          applicationType.hasWithdrawal ||
          user.userStatus === 'BEING_WITHDRAWAL_PROCESSED' ||
          user.userStatus === 'WITHDRAWN' ||
          user.userStatus === 'BEING_RECESSMENT_PROCESSED'
      );
    }
  }, [userStatus, applicationType, isListedClassification]);

  const updateApplicationsState = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateApplications();
  };

  return {
    notOperateBonus,
    notOperatePart,
    notOperatePay,
    notOperateRecess,
    notOperateWithdrawal,
    updateApplicationsState,
  };
};
