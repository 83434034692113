import { TextWithFlowIcon } from '@/components/molecules/Flow/TextWithFlowIcon';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { UserInfo } from '@/components/molecules/Table/UserInfo';
import { HomeHeader } from '@/components/organism/HomeHeader';
import { styled, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FAQ } from '@/components/organism/Apply/Faq';
import { WarmingDeadLine } from '@/components/organism/Apply/WarmingDeadLine';
import { BREAKPOINT } from '@/theme/theme';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { useAuthUser } from '@/hooks/useAuth';
import { appClient } from '@/services';
import { ConvertAssociation } from '@/services/convert/association';
import { ConvertAssociationContribution } from '@/services/convert/associationContribution';
import { ConvertEventsmemberdeadlines } from '@/services/convert/eventsmemberdeadlines';
import { ConvertUserContribution } from '@/services/convert/userContribution';
import { Association } from '@/types/api/association';
import { AssociationContribution } from '@/types/api/associationContribution';
import { Eventsmemberdeadlines } from '@/types/api/eventsmemberdeadlines';
import { UserContribution } from '@/types/api/userContribution';
import useSWR from 'swr';
import { useNavigate } from 'react-router-dom';
import { Path } from '@/constants/Router/path';
import { PrimaryAndSecondaryButtons } from '@/components/molecules/Buttons/PrimaryAndSecondaryButtons';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const Wrraper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    marginBottom: '80px',
    padding: '14px 0',
    [breakpoints.up(BREAKPOINT)]: {
      padding: '14px 40px',
    },
    [breakpoints.up('lg')]: {
      padding: '14px 130px',
    },
  };
});

const ContentWrraper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
});

// パンクズ
const links = [
  {
    path: 'TOP',
    to: Path.HOME,
  },
  {
    path: '再開する',
    to: Path.RESUME,
  },
];

// タイトルの説明文
const description = [
  '持株会の休止をやめ、給与(毎月の拠出)と賞与の拠出を再開します。',
];

export const ResumeIndex: FC = () => {
  const navigate = useNavigate();
  useHelmetHandler({
    title: '再開 入力画面',
  });
  const [association, setAssociation] = useState<Association>();
  const [userContribution, setUserContribution] = useState<UserContribution>();
  const [associationContribution, setAssociationContribution] =
    useState<AssociationContribution>();
  const [eventsmemberdeadlines, setEventsmemberdeadlines] =
    useState<Eventsmemberdeadlines>();

  // 【API-A-0909】資産情報取得
  const { user } = useAuthUser();

  // 【API-A-1002】持株会（事務局）拠出情報取得
  const { data: findAssociationContribution } = useSWR(
    '/api/associations/{associationCd}/contributions',
    () => appClient.associations.findAssociationContribution()
  );

  // 【API-A-1001】持株会（事務局）情報
  const { data: findAssociationById } = useSWR(
    '/api/associations/{associationCd}',
    () => appClient.associations.findAssociationById()
  );

  // 【API-A-1001】持株会（事務局）情報
  const { data: findAssociationEventMemberDeadlines } = useSWR(
    '/api/associations/{associationCd}/eventsmemberdeadlines',
    () => appClient.associations.findAssociationEventMemberDeadlines()
  );

  // 【API-A-0905】拠出情報取得
  const { data: findUserContribution } = useSWR('/api/users/contribution', () =>
    appClient.users.findUserContribution()
  );

  // 【API-A-0909】資産情報取得
  const { data: userAsset } = useSWR('/api/users/assets', () =>
    appClient.users.findUserAsset()
  );

  useEffect(() => {
    setAssociation(ConvertAssociation(findAssociationById));
    setAssociationContribution(
      ConvertAssociationContribution(findAssociationContribution)
    );
    setEventsmemberdeadlines(
      ConvertEventsmemberdeadlines(
        findAssociationEventMemberDeadlines?.eventmemberdeadlines
      )
    );
    setUserContribution(ConvertUserContribution(findUserContribution));
  }, [
    findAssociationById,
    findAssociationContribution,
    findAssociationEventMemberDeadlines,
    findUserContribution,
  ]);

  if (
    association &&
    associationContribution &&
    userContribution &&
    eventsmemberdeadlines &&
    userAsset
  ) {
    return (
      <Wrraper>
        <HomeHeader links={links} title="再開する" description={description} />
        <TextWithFlowIcon flowOn={0} widthFull />
        <FAQ pageName="resume">
          <ContentWrraper>
            <WarmingDeadLine
              eventsmemberdeadlines={eventsmemberdeadlines}
              target="RESUME"
            />
            <UserInfo
              mochikabukaiCode={user.associationCd ?? ''}
              mochikabukaiName={association.associationName ?? ''}
              nameKanji={user.nameKanji ?? ''}
              nameKana={user.nameKana ?? ''}
              zipcode={user.zipcode ?? ''}
              address1={user.address1 ?? ''}
              address2={user.address2 ?? ''}
              address3={user.address3 ?? ''}
              tel={user.tel ?? ''}
              employeeCd={user.employeeCd ?? ''}
              editable
            />
            <BasicTable
              boxSx={{ width: '100%' }}
              title="現在の積立状況"
              type="recess"
              stocksNumber={userAsset.stocksNumber}
              unitAmount={associationContribution.unitAmount}
              monthlyUnit={userContribution.monthlyUnit}
              bonusUnit={userContribution.bonusUnit}
              investmentDigit={association.investmentDigit}
            />
            <Typography variant="body-main/regular">
              ※休止前の口数で再開されます。再開時口数の変更を行う際は口数変更の申請をお願いします。
            </Typography>
            <PrimaryAndSecondaryButtons>
              <PrimaryButton
                sx={{
                  padding: '16px 40px',
                  borderRadius: '28px',
                  alignSelf: 'center',
                }}
                onClick={() =>
                  navigate(Path.RESUME_CONFIRM, {
                    state: {
                      user,
                      association,
                      associationContribution,
                      userContribution,
                      eventsmemberdeadlines,
                      stocksNumber: userAsset.stocksNumber,
                    },
                  })
                }
              >
                確認画面へ進む
              </PrimaryButton>
            </PrimaryAndSecondaryButtons>
          </ContentWrraper>
        </FAQ>
      </Wrraper>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
