import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { BREAKPOINT } from '@/theme/theme';
import { Eventsmemberdeadlines } from '@/types/api/eventsmemberdeadlines';
import { Box, styled, SxProps, Theme, Typography } from '@mui/material';
import { FC } from 'react';

const styleContainer: SxProps<Theme> = [
  {
    width: 'calc(100vw - 32px)',
    textAlign: 'center',
    p: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: '80vh',
    overflowY: 'scroll',
  },
  (theme) => ({
    [theme.breakpoints.up(BREAKPOINT)]: {
      width: '540px',
    },
  }),
];

const DeadLineNow = styled('div')({
  width: '100%',
});

const StyledTable = styled('table')(({ theme }) => {
  const { palette } = theme;
  return {
    width: '100%',
    border: '1px solid',
    borderColor: palette.system.separator,
    borderCollapse: 'collapse',
    textAlign: 'center',
  };
});

const StyledTr = styled('tr')(({ theme }) => {
  const { palette } = theme;
  return {
    borderBottom: '1px solid',
    borderColor: palette.system.separator,
    ':first-child': {
      border: 'none',
    },
  };
});

const MonthTitle = styled('th')(({ theme }) => {
  const { palette } = theme;
  return {
    width: '48px',
    borderRight: '1px solid',
    borderColor: palette.system.separator,
    background: '#E0F4F5',
  };
});

const DateTitle = styled('th')({
  background: '#E0F4F5',
});

const MonthArea = styled('td')(({ theme }) => {
  const { palette } = theme;
  return {
    borderRight: '1px solid',
    borderColor: palette.system.separator,
  };
});

const DateArea = styled('td')({});

const DeadLineNowTitle = styled('div')(({ theme }) => {
  const { palette } = theme;
  return {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    background: palette.secondary.normal,
    color: palette.system.main,
    fontWeight: 700,
    fontSize: '20px',
    padding: '16px',
  };
});

const DeadLineNowContent = styled('div')(({ theme }) => {
  const { palette } = theme;
  return {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    background: '#EFFBFC',
    color: palette.system['text-normal'],
    fontWeight: 700,
    fontSize: '20px',
    padding: '16px',
  };
});

const VariousDeadLine = styled('div')(({ theme }) => {
  const { palette } = theme;
  return {
    width: '100%',
    border: '1px solid',
    borderColor: palette.system.separator,
    borderRadius: '8px',
    padding: '16px',
    marginTop: '24px',
  };
});

type Props = {
  deadLine: string;
  onClick: () => void;
  eventsmemberdeadlines: Eventsmemberdeadlines;
};

const getDay = (i: number) => {
  switch (i) {
    case 0:
      return '日';
    case 1:
      return '月';
    case 2:
      return '火';
    case 3:
      return '水';
    case 4:
      return '木';
    case 5:
      return '金';
    case 6:
      return '土';
    default:
      return '';
  }
};

export const DeadLine: FC<Props> = ({
  onClick,
  deadLine,
  eventsmemberdeadlines,
}) => {
  const montlyDaedLine = eventsmemberdeadlines
    .find((i) => i.applicationType === 'MONTHLY_UNIT')
    ?.memberDeadlines.map((i) => new Date(i));

  const etcDaedLine = eventsmemberdeadlines
    .find((i) => i.applicationType === 'RESUME')
    ?.memberDeadlines.map((i) => new Date(i));

  return (
    <Box sx={styleContainer}>
      <DeadLineNow>
        <DeadLineNowTitle>現在の受付締日</DeadLineNowTitle>
        <DeadLineNowContent>{deadLine}</DeadLineNowContent>
      </DeadLineNow>
      <Typography
        variant="body-sub/regular"
        sx={{ alignSelf: 'baseline', marginTop: '8px' }}
      >
        ※受付締日を超えた申請は、次回の受付となります。
      </Typography>
      <VariousDeadLine>
        <Typography variant="h6" component="h6" sx={{ alignSelf: 'baseline' }}>
          各申請ごとの受付締日
        </Typography>
        <Box sx={{ display: 'flex', marginTop: '16px' }}>
          <Box sx={{ width: '50%', marginRight: '8px', textAlign: 'left' }}>
            <Typography
              variant="body-main/medium"
              sx={{ color: 'system.table' }}
            >
              口数変更
            </Typography>
            <StyledTable>
              <StyledTr>
                <MonthTitle>月</MonthTitle>
                <DateTitle>日</DateTitle>
              </StyledTr>
              {montlyDaedLine?.map((i) => (
                <StyledTr>
                  <MonthArea>{i.getMonth() + 1}</MonthArea>
                  <DateArea>{`${i.getDate()}(${getDay(i.getDay())})`}</DateArea>
                </StyledTr>
              ))}
            </StyledTable>
          </Box>
          <Box sx={{ width: '50%', marginLeft: '8px', textAlign: 'left' }}>
            <Typography
              variant="body-main/medium"
              sx={{ color: 'system.table' }}
            >
              一部引出・休止・再開・退会
            </Typography>
            <StyledTable>
              <StyledTr>
                <MonthTitle>月</MonthTitle>
                <DateTitle>日</DateTitle>
              </StyledTr>
              {etcDaedLine?.map((i) => (
                <StyledTr>
                  <MonthArea>{i.getMonth() + 1}</MonthArea>
                  <DateArea>{`${i.getDate()}(${getDay(i.getDay())})`}</DateArea>
                </StyledTr>
              ))}
            </StyledTable>
          </Box>
        </Box>
      </VariousDeadLine>
      <SecondaryButton
        sx={{
          width: '120px',
          height: '56px',
          borderRadius: '28px',
          marginTop: '24px',
        }}
        onClick={onClick}
      >
        とじる
      </SecondaryButton>
    </Box>
  );
};
