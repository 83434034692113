/* eslint-disable @typescript-eslint/no-unsafe-call */
import { BasicModal } from '@/components/molecules/Modal/BasicModal.tsx';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { TextInput } from '@/components/atoms/Input/TextInput';
import { Path } from '@/constants/Router/path';
import { validatePassword } from '@/utils/validate/validation';
import {
  Box,
  SvgIcon,
  Typography,
  useMediaQuery,
  SxProps,
  Theme,
} from '@mui/material';
import { FC, useState, useEffect } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useController,
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BREAKPOINT, theme } from '@/theme/theme';
import { ReactComponent as Icon } from '@/assets/key.svg';
import {
  MyPagePasswordFormValues as FormValues,
  MyPagePasswordProps as Props,
  MyPagePasswordLabelArrayProps as LabelProps,
} from '@/types/components/MyPagePassword';

const Title: FC = () => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      mb: '24px',
      gap: '4px',
      [theme.breakpoints.down(BREAKPOINT)]: {
        ml: '21px',
      },
    }}
  >
    <SvgIcon sx={{ fill: () => theme.palette.system.placeholder }}>
      <Icon />
    </SvgIcon>
    <Typography variant="h4" sx={{ color: 'system.text-normal' }}>
      パスワード変更
    </Typography>
  </Box>
);

const Label: FC<LabelProps> = ({ isPc, labelCaption }) => (
  <Box
    sx={{
      minWidth: isPc ? '140px' : '100%',
      display: 'flex',
      flexDirection: isPc ? 'column' : 'row',
      alignItems: 'flex-start',
      p: '0',
      gap: isPc ? '0' : '4px',
    }}
  >
    {labelCaption.map((i) => (
      <Typography
        component="span"
        variant="body-main/bold"
        sx={{ color: 'system.text-normal' }}
        key={i}
      >
        {i}
      </Typography>
    ))}
  </Box>
);

const styleContainer: SxProps<Theme> = [
  {
    width: '343px',
    height: '184px',
    textAlign: 'center',
    p: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    outline: '0px',
  },
  () => ({
    [theme.breakpoints.up(BREAKPOINT)]: {
      width: '520px',
      height: '176px',
      p: '32px',
    },
  }),
];

const Password: FC<{ name: string; methods?: UseFormReturn<FormValues> }> = ({
  name,
  methods,
}) => {
  const {
    field: { ref, ...rest },
  } = useController({ name });

  return (
    <Box
      sx={{
        [theme.breakpoints.down(BREAKPOINT)]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      }}
      display="flex"
      alignItems="center"
      gap="8px"
      flexDirection="row"
    >
      <TextInput
        {...rest}
        inputRef={ref}
        error={
          name === 'password'
            ? !!(
                methods?.watch('password') !== '' &&
                !validatePassword(methods?.watch('password'))
              )
            : !!(
                methods?.watch('repassword') &&
                methods.watch('password') !== methods.watch('repassword')
              )
        }
        sx={{
          height: 'auto',
          [theme.breakpoints.down(BREAKPOINT)]: {
            width: '100%',
          },
        }}
      />
      {name === 'password' &&
        methods?.watch('password') !== '' &&
        !validatePassword(methods?.watch('password')) && (
          <Typography
            variant="caption/regular"
            color={theme.palette.states.error}
          >
            パスワードの形式が正しくありません
          </Typography>
        )}
      {name === 'repassword' &&
        methods?.watch('repassword') &&
        methods.watch('password') !== methods.watch('repassword') && (
          <Typography
            variant="caption/regular"
            color={theme.palette.states.error}
          >
            入力されたパスワードが一致しません。
          </Typography>
        )}
    </Box>
  );
};

const LabelPassword: FC<LabelProps & Props> = ({
  isPc,
  labelCaption,
  children,
  underline = false,
}) => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: isPc ? 'row' : 'column',
      alignItems: 'flex-start',
      pb: underline ? '16px' : '0',
      gap: isPc ? '24px' : '8px',
      borderBottom: underline ? '1px solid' : '0',
      borderColor: 'system.separator-light',
    }}
  >
    <Label isPc={isPc} labelCaption={labelCaption} />
    {children}
  </Box>
);

const PrimarySecondary: FC<Props> = (props) => {
  const { isPc, methods } = props;
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: '100%',
        mt: isPc ? 'calc(40px - 16px)' : '0', // gap分を減算
      }}
    >
      <Box
        sx={{
          margin: '0 auto',
          width: isPc ? '389px' : '100%',
          display: 'flex',
          flexDirection: isPc ? 'row-reverse' : 'column',
          alignItems: isPc ? 'center' : 'flex-start',
          padding: '0px',
          gap: '16px',
        }}
      >
        <PrimaryButton
          type="submit"
          sx={{
            width: isPc ? '210px' : '100%',
            height: '56px',
            padding: '20px, 24px, 20px, 26px',
            borderRadius: '28px',
          }}
          disabled={
            !methods?.watch('password') ||
            !methods.watch('repassword') ||
            methods.watch('password') !== methods.watch('repassword') ||
            !validatePassword(methods.watch('password'))
          }
        >
          パスワードを変更する
        </PrimaryButton>
        <SecondaryButton
          sx={{
            width: isPc ? '162px' : '100%',
            height: '56px',
            padding: '20px, 24px, 20px, 26px',
            borderRadius: '28px',
          }}
          onClick={() => navigate('/mypage')}
        >
          変更せずに戻る
        </SecondaryButton>
      </Box>
    </Box>
  );
};

const Contents: FC<Props> = (props) => {
  const { isPc, methods } = props;
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: isPc ? 'center' : 'flex-start',
        padding: isPc ? '32px' : '24px 16px',
        gap: isPc ? '16px' : '24px',
        backgroundColor: 'system.white',
        borderRadius: isPc ? '12px' : '0',
      }}
    >
      <LabelPassword isPc={isPc} labelCaption={['現在のパスワード']} underline>
        <Typography
          component="span"
          variant="body-main/regular"
          sx={{ color: 'system.text-normal' }}
        >
          **********
        </Typography>
      </LabelPassword>
      <LabelPassword isPc={isPc} labelCaption={['新しいパスワード']} underline>
        <Typography
          component="span"
          variant="body-main/regular"
          sx={{ color: 'system.text-normal', width: '100%' }}
        >
          <Password name="password" methods={methods} />
          <Typography
            component="p"
            variant="caption/regular"
            sx={{ color: 'system.text-light', mt: '4px' }}
          >
            ※半角英数字8桁以上
            <br />
            ※大文字、小文字、数字、記号をそれぞれ1文字以上使用してください。使用可能な記号は以下の通りです
            <br />
            {
              '^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ ` = + -'
            }
            <br />
            ※自身の名前、生年月日、電話番号、連番など推測しやすい文字列は使用しないでください
          </Typography>
        </Typography>
      </LabelPassword>
      <LabelPassword isPc={isPc} labelCaption={['新しいパスワード', '再入力']}>
        <Typography component="span" sx={{ width: '100%' }}>
          <Password name="repassword" methods={methods} />
        </Typography>
      </LabelPassword>
      <PrimarySecondary isPc={isPc} methods={methods} />
    </Box>
  );
};

const Wrapper: FC<Props> = ({ isPc, methods }) => (
  <Box sx={{ maxWidth: isPc ? '780px' : '100%', margin: '0 auto' }}>
    <Title />
    <Contents isPc={isPc} methods={methods} />
  </Box>
);

export const MyPagePasswordOrganism: FC<{
  oldPassword: string;
  handleSubmit: (data: FormValues) => void;
}> = ({ handleSubmit, oldPassword }) => {
  const isPc = useMediaQuery(() => theme.breakpoints.up(BREAKPOINT));
  const [isPassword, setIsPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const methods = useForm<FormValues>({
    defaultValues: {
      password: '',
      repassword: '',
    },
  });
  const onSubmit: SubmitHandler<FormValues> = (data) => {
    handleSubmit(data);
  };

  useEffect(() => {
    if (oldPassword === '') setIsPassword(true);
  }, [oldPassword]);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'system.background',
          padding: {
            xs: '24px 0 86px',
            sm: '48px 40px 386px',
            md: '48px 40px 386px',
            lg: '48px 130px 386px',
          },
        }}
      >
        <FormProvider {...methods}>
          {/**
           * lintエラー公式未対応
           * @see https://react-hook-form.com/api/useform/handlesubmit
           */}
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Wrapper isPc={isPc} methods={methods} />
          </form>
        </FormProvider>
      </Box>

      <BasicModal open={isPassword}>
        <Box sx={styleContainer}>
          <Typography
            component="p"
            variant="body-main/regular"
            sx={{ color: 'system.text-normal' }}
          >
            再度パスワード認証を行う必要があります。
            <br />
            マイページTOPから再度操作を行なってください。
          </Typography>
          <PrimaryButton onClick={() => navigate(`${Path.MYPAGE}`)}>
            マイページTOPへ移動
          </PrimaryButton>
        </Box>
      </BasicModal>
    </>
  );
};
