/**
 * '0000-00-00' => '0000年`00月00日'
 */
export const formatDate = (date: string) =>
  date.replace(/(\d+)-(\d+)-(\d+)/g, `$1年$2月$3日`);

/**
 * '0000-00-00T00:00:00.825483084+09:00' => '0000年00月00日(日)00:00'
 */
export const formatDateTime = (dateString: string) => {
  const d = new Date(dateString);
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();
  const hour = d.getHours();
  let minute = d.getMinutes().toString();
  if (minute.length === 1) {
    minute = `0${minute}`;
  }

  const dayMap: { [index: number]: string } = {
    0: '日',
    1: '月',
    2: '火',
    3: '水',
    4: '木',
    5: '金',
    6: '土',
  };
  const day = dayMap[d.getDay()];
  // return example: 2022年2月8日 (月) 00:00 現在
  return `${year}年${month}月${date}日 (${day}) ${hour}:${minute}`;
};

/**
 * '000000000' => Date object
 */
export const formatStrToDate = (dateString: string) => {
  const year = Number(dateString.substring(0, 4));
  const month = Number(dateString.substring(4, 6));
  const day = Number(dateString.substring(6, 8));
  const date = new Date(year, month - 1, day);
  return date;
};

/**
 * '000000' => '00/00'
 */
export const formatYYMM = (dateString: string) =>
  `${dateString.slice(2, 4)}/${dateString.slice(4, 6)}`;

// NUM=値 LEN=桁数
function zeroPadding(NUM: number, LEN: number) {
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  return (Array(LEN).join('0') + NUM).slice(-LEN);
}
/**
 * 年と月から存在する日数の配列を返す関数
 * @param year
 * @param month
 * @returns
 */
export const getDayList = (year: number, month: number) => {
  const date = new Date(year, month, 0);
  const dayCount = date.getDate();
  return Array.from({ length: dayCount }, (_, i) => ({
    key: String(zeroPadding(i + 1, 2)),
    value: String(1 + i),
  }));
};
