import { theme } from '@/theme/theme';
import { Box, styled, Typography } from '@mui/material';
import { CSSProperties, useEffect, useState } from 'react';
import logo from '@/assets/logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { LocationState } from '@/components/pages/Withdrawal/WithdrawalInput';
import { formatDateTime } from '@/utils/dateFunctions';
import { useDeviceDetect } from '@/hooks/useDeviceDetect';
import { PrintSelectedContent } from '@/utils/printHelper';
import { InfoRow } from './infoRow';
import {
  RecessContent,
  MonthlyAndBonusContent,
  PartContent,
  RecessResumeContent,
  WithdrawalContent,
} from './content';

const Wrapper = styled(Box)(() => ({
  padding: '30px 60px',
  minWidth: 1320,
  maxWidth: 1320,
  margin: '0 auto',
  boxSizing: 'border-box',

  '@page': {
    size: 'A4 portrait',
  },

  '@media print': {
    padding: '20px 60px',
    minWidth: 0,
    maxWidth: 1320,
    margin: '0 auto',
    boxSizing: 'border-box',
  },
}));

const HeaderContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  '@media print': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const Img = styled('img')({
  width: '125px',

  '@media print': {
    width: '125px',
  },
});

const Title = styled(Typography)(() => ({
  ...theme.typography.h3,
  fontSize: 34,
  fontWeight: 500,
  textAlign: 'left',
  marginTop: 48,
  [theme.breakpoints.up('sm')]: {
    fontSize: 34,
  },

  '@media print': {
    ...theme.typography.h3,
    fontSize: 24,
    fontWeight: 500,
    textAlign: 'left',
    marginTop: 48,
    [theme.breakpoints.up('sm')]: {
      fontSize: 24,
    },
  },
}));

export const Text = styled(Typography)(() => ({
  ...theme.typography.h5,
  fontWeight: 400,
  lineHeight: '30px',

  '@media print': {
    ...theme.typography.h5,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
  },
}));

const Container = styled(Box)(() => ({
  marginTop: 24,

  '@media print': {
    marginTop: 24,
  },
}));

const ContainerTitle = styled(Typography)(() => ({
  ...theme.typography.h2,
  fontWeight: 500,
  paddingBottom: 12,
  borderWidth: 0,
  fontSize: 26,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: '#000000',
  [theme.breakpoints.up('sm')]: {
    fontSize: 26,
  },

  '@media print': {
    ...theme.typography.h2,
    fontWeight: 500,
    paddingBottom: 12,
    borderWidth: 0,
    fontSize: 21,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    [theme.breakpoints.up('sm')]: {
      fontSize: 21,
    },
  },
}));

const ContainerContent = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',

  '@media print': {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      maxWidth: '48%',
    },
  },
}));

const ContainerContenstWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 20,

  '@media print': {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
  },
}));

const HelperTextContainer = styled(Box)(() => ({
  marginTop: 24,
  '@media print': {
    marginTop: 24,
  },
}));

const ContainerConclusionStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  gap: '40px',
};

const ConculusionFrame = styled(Box)(() => ({
  padding: 10,
  width: 640,
  height: 150,
  borderWidth: 1,
  borderColor: '#000000',
  borderStyle: 'solid',
  maxWidth: 640,

  '@media print': {
    padding: 10,
    width: 380,
    height: 90,
    borderWidth: 1,
    borderColor: '#000000',
    borderStyle: 'solid',
    maxWidth: 320,
  },
}));

const SignContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,

  '@media print': {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
}));

const SignFrame = styled(Box)(() => ({
  width: 330,
  height: 150,
  borderWidth: 1,
  borderColor: '#000000',
  borderStyle: 'solid',
  position: 'relative',

  '@media print': {
    width: 200,
    height: 90,
    borderWidth: 1,
    borderColor: '#000000',
    borderStyle: 'solid',
    position: 'relative',
  },
}));

const SignFrameLineVertical = styled(Box)(() => ({
  width: 1,
  backgroundColor: '#000000',
  height: 149,
  position: 'absolute',

  '@media print': {
    borderWidth: 0,
    borderLeftWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000',
    height: 90,
    position: 'absolute',
  },
}));

const SignFrameLineHorizontal = styled(Box)(() => ({
  height: 1,
  backgroundColor: '#000000',
  width: 330,
  position: 'absolute',

  '@media print': {
    height: 1,
    backgroundColor: '#000000',
    width: 330,
    position: 'absolute',
    [theme.breakpoints.up('xs')]: {
      borderWidth: 0,
      borderTopWidth: 1,
      borderStyle: 'solid',
      borderColor: '#000',
      width: 200,
      position: 'absolute',
    },
  },
}));

const PRINT_PAGE_PATH = '/print';

export const PrintPdf = () => {
  const navigate = useNavigate();
  const { isUAIos } = useDeviceDetect();
  const [isOpenPrintWindow, setIsOpenPrintWindow] = useState<boolean>(false);
  const [isPrint, setIsPrint] = useState<boolean>(false);
  const location = useLocation();
  const state = location.state as LocationState;

  const goBack = () => {
    navigate(-1);
  };

  const openPrintWindow = () => {
    PrintSelectedContent();
    setTimeout(
      () => {
        setIsOpenPrintWindow(true);
      },
      isUAIos ? 3000 : 700
    );
  };

  const selectContent = () => {
    switch (state.type) {
      case 'MONTHLY':
        return (
          <MonthlyAndBonusContent
            title={state.contentTitle || ''}
            data={{ ...state, bonusUnit: state.value }}
            type="MONTHLY"
          />
        );
      case 'BONUS':
        return (
          <MonthlyAndBonusContent
            title={state.contentTitle || ''}
            data={state}
            type="BONUS"
          />
        );
      case 'PART':
        return <PartContent title={state.contentTitle || ''} data={state} />;
      case 'RECESS':
        return (
          <RecessContent
            title={state.contentTitle || ''}
            data={state}
            reasonKey={state.application?.applicationRecess?.reason}
          />
        );
      case 'RESUME':
        return (
          <RecessResumeContent title={state.contentTitle || ''} data={state} />
        );
      case 'WITHDRAWAL':
        return (
          <WithdrawalContent
            data={state}
            requestParams={
              state.application?.applicationWithdrawal || state.requestParams
            }
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    // 2度Print Windowがたちあがるってしまうための対策
    setIsPrint(true);
    if (location.pathname === PRINT_PAGE_PATH && isPrint) {
      openPrintWindow();
    }
  }, [location, isPrint]);

  useEffect(() => {
    if (isOpenPrintWindow) {
      goBack();
    }
  }, [isOpenPrintWindow]);

  return (
    <Wrapper id="pdf_content">
      <HeaderContainer>
        <Img src={logo} alt="Logo" />
        <Text>{formatDateTime(new Date().toString())}</Text>
      </HeaderContainer>
      <Title>{state.title || ''}</Title>
      <Container>
        <ContainerTitle>会員情報</ContainerTitle>
        <ContainerContenstWrapper>
          <ContainerContent>
            <InfoRow
              key="nameKanji"
              title="氏名（漢字）"
              content={state.user.nameKanji}
            />
            <InfoRow
              key="nameKana"
              title="氏名（カナ）"
              content={state.user.nameKana}
            />
            <InfoRow
              key="address"
              title="住所"
              content={[
                state.user.address1,
                state.user.address2,
                state.user.address3,
                state.user.address4,
              ]}
            />
          </ContainerContent>
          <ContainerContent>
            <InfoRow key="tel" title="電話番号" content={state.user.tel} />
            <InfoRow
              key="memberCd"
              title="社員コード"
              content={state.user.employeeCd}
            />
          </ContainerContent>
        </ContainerContenstWrapper>
      </Container>

      <HelperTextContainer>
        <Text>
          本申請はインサイダー情報（未公表の重要事実）を知りながら行うものではありません。
        </Text>
      </HelperTextContainer>
      {selectContent()}
      <Container
        sx={{
          ...ContainerConclusionStyle,
          marginTop: '40px',
          '@media print': {
            // padding: '12px',
            borderRadius: '6px',
          },
        }}
      >
        <ConculusionFrame>
          <Text>摘要</Text>
        </ConculusionFrame>
        <SignContainer>
          <Text>検印</Text>
          <SignFrame>
            <SignFrameLineHorizontal
              sx={{
                left: 0,
                top: 40,
                '@media print': {
                  left: 0,
                  top: 28,
                },
              }}
            />
            <SignFrameLineVertical
              key="SignFrameLineVerticalLine-1"
              sx={{
                left: 109,
                top: 0,
                '@media print': {
                  left: 69,
                  top: 0,
                },
              }}
            />
            <SignFrameLineVertical
              key="SignFrameLineVerticalLine-2"
              sx={{
                left: 219,
                top: 0,
                '@media print': {
                  left: 138,
                  top: 0,
                },
              }}
            />
          </SignFrame>
        </SignContainer>
      </Container>
    </Wrapper>
  );
};
