export class AssociationDomainService {
  /**
   * isListedClassification
   * 上場区分が1（上場の場合）の場合trueを返す(一部引出あり)
   * 上場区分が1でない（非上場の場合）場合falseを返す(一部引出なし)
   * @param association {Association}
   */
  public static isListedClassification(association: {
    associationCd?: string;
    associationName?: string;
    stockCode?: string;
    associationType?: number;
    associationRepresentative?: string;
    associationTel?: string;
    associationEmail?: string;
    associationZipcode?: string;
    associationAddressCd?: string;
    deploymentAddress1?: string;
    deploymentAddress2?: string;
    supplementAddress1?: string;
    supplementAddress2?: string;
    investmentDigit?: number;
    dividendProcessingClassification?: string;
    withdrawalSettlementDigit?: number;
    withdrawalAllSettlementUsageClassification?: string;
    withdrawalLessThanSettlementUsageClassification?: string;
    withdrawalAddPurchaseUsageClassification?: string;
    associationStatus?: string;
    dissolutionYmd?: string;
    openedFlg?: boolean;
    listedClassification?: string;
    stockOfficialNameKanji?: string;
    settlementCnt?: number;
    settlementMonth?: number;
  }): boolean {
    return association.listedClassification === '1';
  }

  /**
   * calcContributionAmount
   * @param unitAmount
   * @param inputPay
   * @return number | "-"
   */
  public static calcContributionAmount(
    unitAmount: number,
    inputPay: number
  ): number {
    return unitAmount * inputPay;
  }

  public static calcIncentiveAmount(
    contributionAmount: number,
    incentiveRatio: number
  ): number {
    return Math.floor(contributionAmount / incentiveRatio);
  }

  /**
   * calcBonusMagnificationType
   * 倍率指定の場合の賞与拠出金額を計算する
   * @param unitAmount
   * @param inputPay
   * @param bonusRateValue
   */
  public static calcBonusMagnificationType(
    unitAmount: number,
    inputPay: number,
    bonusRateValue: number
  ): number {
    return unitAmount * bonusRateValue * inputPay;
  }

  /**
   * calcBonusFixedMagnificationType
   * 定倍指定(口数固定)の場合の賞与拠出金額を計算する
   * FIXED_MAGNIFICATION
   * @param unitAmount
   * @param inputPay
   * @param bonusFixedMagnification
   */
  public static calcBonusFixedMagnificationType(
    unitAmount: number,
    inputPay: number,
    bonusFixedMagnification: number
  ): number {
    return unitAmount * bonusFixedMagnification * inputPay;
  }
}
