import { TextWithFlowIcon } from '@/components/molecules/Flow/TextWithFlowIcon';

import { styled, Typography, Theme } from '@mui/material';
import { FC } from 'react';

interface Props {
  flowOn: number;
  isSignUp?: boolean;
}

const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '32px 0',
  gap: '16px',
  height: '152px',
  background: theme.palette.system['background-light'],
  borderTopLeftRadius: '12px',
  borderTopRightRadius: '12px',
}));

export const SignUpUpper: FC<Props> = ({ flowOn, isSignUp = false }) => (
  <Wrapper>
    <Typography
      variant="h6"
      sx={{ color: (theme: Theme) => theme.palette.system['text-light'] }}
    >
      入会申請の流れ
    </Typography>
    <TextWithFlowIcon flowOn={flowOn} isSignUp={isSignUp} />
  </Wrapper>
);
