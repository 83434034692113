/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/ban-ts-comment */
import useSWR from 'swr';
import {
  appClient,
  AssociationDomainService,
  UserDomainService,
} from '@/services';
import { useEffect, useState } from 'react';
import { associationModel } from '@/features/Home/hooks/model';
// import { useSessionOutModal } from '@/hooks';
import { isObjectEmpty } from '@/utils/empty';
import { useAuthUser } from '@/hooks/useAuth';
import {
  userAssetModel,
  UserSecuritiesAccounts,
  userSecuritiesAccountsModel,
} from './model';

export type UserStatusType = {
  hasRecess: boolean;
  hasNormal: boolean;
  hasBeingProcessedEnrollment: boolean;
  isRecess: boolean;
};

export const useHomeHooks = () => {
  // const { handleSessionOutModalOpen } = useSessionOutModal();
  // 上場: true or 非上場: false
  const [isListedClassification, setIsListedClassification] =
    useState<boolean>(false);
  const [userStatus, setUserStatus] = useState<UserStatusType>({
    hasRecess: false,
    hasNormal: false,
    hasBeingProcessedEnrollment: false,
    isRecess: false, // 休止
  });

  const { user, updateUser } = useAuthUser();

  const [userSecuritiesAccounts, setUserSecuritiesAccounts] = useState<
    UserSecuritiesAccounts | undefined
  >(undefined);

  // 【API-A-0909】資産情報取得
  const { data: userAsset, error: userAssetsError } = useSWR(
    '/api/users/assets',
    () => appClient.users.findUserAsset()
  );

  // 【API-A-0501】申請履歴一覧取得
  const {
    data: applications,
    error: applicationsError,
    mutate,
  } = useSWR('/api/applications', () =>
    appClient.applications.listApplications(1, 10, 'APPLICATION_ID_DESC')
  );

  // 【API-A-1001】持株会（事務局）情報
  const { data: associationData, error: associationError } = useSWR(
    '/api/associations/{associationCd}',
    () => appClient.associations.findAssociationById()
  );

  // 【API-A-0906】証券口座情報取得
  const { data: userSecuritiesAccountsData } = useSWR(
    '/api/securitiesaccounts',
    () => appClient.users.findSecuritiesAccount()
  );

  useEffect(() => {
    if (associationData) {
      const association = associationModel(associationData);
      setIsListedClassification(
        AssociationDomainService.isListedClassification(association)
      );
    }
  }, [associationData]);

  useEffect(() => {
    if (userAssetsError) {
      // handleSessionOutModalOpen();
    }
  }, [userAssetsError, applicationsError, associationError]);
  useEffect(() => {
    if (!isObjectEmpty(user)) {
      setUserStatus({
        ...userStatus,
        hasRecess: UserDomainService.isStatusForRecess(user),
        hasNormal: UserDomainService.isStatusForNormal(user),
        hasBeingProcessedEnrollment:
          UserDomainService.isStatusForBeingProcessedEnrollment(user),
        isRecess: user.userStatus === 'RECESS',
      });
    }
    if (isObjectEmpty(user)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateUser();
    }
  }, [user]);

  useEffect(() => {
    if (userSecuritiesAccountsData) {
      const securitiesAccount = userSecuritiesAccountsModel(
        userSecuritiesAccountsData
      );
      setUserSecuritiesAccounts(securitiesAccount);
    }
  }, [userSecuritiesAccountsData]);

  const updateApplications = async () => {
    await mutate();
  };

  return {
    // state関連
    isListedClassification,
    userSecuritiesAccounts,
    // loading関連
    isLoadingUserAsset: !userAsset && !userAssetsError,
    isAssociationLoading: !associationData && !associationError,
    // api取得関連
    userAsset: userAsset ? userAssetModel(userAsset) : userAsset,
    applications, // applicationModel(applications),
    associationData,
    updateApplications,
    // error関連
    userAssetsError,
    applicationsError,
    associationError,
    userStatus,
  };
};
