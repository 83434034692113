/* eslint-disable  */
import { TextButton } from '@/components/atoms/Buttons/TextButton';
import {
  contributionLabels,
  loginLabels,
  passwordLabels,
  secretariatsLabel,
  securitiesLabels,
  userLabels,
} from '@/constants/BasicTable/tableLabels';
import {
  BasicTableBodyProps,
  BasicTableBoxProps,
  BasicTableHeaderProps,
  BasicTableProps,
} from '@/types/components/BasicTable';
import {
  Box,
  Link,
  styled,
  SvgIcon,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FC } from 'react';
import { formatStrToDate } from '@/utils/dateFunctions';
import { BREAKPOINT } from '@/theme/theme';
import { useNavigate } from 'react-router-dom';
import { MypageDlContainer } from './MypageDl';
import { SecuritiesAccountDlContainer } from './SecuritiesAccountDl';
import { UserContributionDlContainer } from './UserContributionDl';
import Banner from '@/assets/bnr.png';
import { UserDomainService } from '@/services';
import { useAuthUser } from '@/hooks/useAuth';
import { Path } from '@/constants/Router/path';
import { numberFormat } from '@/utils/numberFormat';
import { bankAccountHGuideExceptionHandler } from '@/utils/validate/bankAccountGuideExceptionHandler';
import { Calculations } from '@/utils/calculation';
import { AssociationContribution } from '@/types/api/associationContribution';
import { UserContribution } from '@/types/api/userContribution';

const Image = styled('img')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '390px',
    margin: '16px 0',
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
    },
  };
});

const MuiBox: FC<BasicTableBoxProps> = (props) => {
  const { boxSx = [], children } = props;

  return (
    <Box
      sx={[
        {
          minWidth: '375px',
          borderRadius: '0',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          gap: '16px',
          backgroundColor: 'system.white',
          paddingX: '16px',
          paddingY: '24px',
        },
        (theme: Theme) => ({
          [theme.breakpoints.up(BREAKPOINT)]: {
            padding: '16px',
            border: '1px solid',
            borderColor: 'system.separator-light',
            borderRadius: '12px',
          },
        }),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        ...(Array.isArray(boxSx) ? boxSx : [boxSx]),
      ]}
    >
      {children}
    </Box>
  );
};

const MuiHeader: FC<Pick<BasicTableHeaderProps, 'headerSx' | 'children'>> = (
  props
) => {
  const { headerSx = [], children } = props;

  return (
    <Box
      sx={[
        {
          width: '100%',
          height: '35px',
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '1px solid',
          borderColor: 'primary.normal',
          padding: '0 12px',
        },
        (theme: Theme) => ({
          [theme.breakpoints.up(BREAKPOINT)]: {
            padding: 0,
          },
        }),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        ...(Array.isArray(headerSx) ? headerSx : [headerSx]),
      ]}
    >
      {children}
    </Box>
  );
};

const Header: FC<BasicTableHeaderProps> = ({
  title,
  type,
  buttonCaption,
  icon,
  headerSx,
  path = '/',
}) => {
  const variant = type.includes('mypage') ? 'h5' : 'h4';
  const navigate = useNavigate();

  return (
    <MuiHeader headerSx={headerSx}>
      {icon ? (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <SvgIcon
            sx={{ fill: (theme: Theme) => theme.palette.system.placeholder }}
          >
            {icon}
          </SvgIcon>
          <Typography variant={variant} sx={{ color: `system.text-normal` }}>
            {title}
          </Typography>
        </Box>
      ) : (
        <Typography variant={variant} sx={{ color: `system.text-normal` }}>
          {title}
        </Typography>
      )}
      {buttonCaption && (
        <TextButton
          icon={type.includes('mypage') ? 'right' : undefined}
          onClick={() => navigate(path)}
        >
          {buttonCaption}
        </TextButton>
      )}
    </MuiHeader>
  );
};

const MuiBody: FC<Pick<BasicTableBodyProps, 'bodySx' | 'children'>> = (
  props
) => {
  const { bodySx = [], children } = props;

  return (
    <Box
      sx={[
        {
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '0 16px',
          gap: '12px',
        },
        (theme: Theme) => ({
          [theme.breakpoints.up(BREAKPOINT)]: {
            flexDirection: 'row',
            padding: '0',
            gap: '12px',
            flexWrap: 'wrap',
          },
        }),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        ...(Array.isArray(bodySx) ? bodySx : [bodySx]),
      ]}
    >
      {children}
    </Box>
  );
};

const Body: FC<BasicTableBodyProps> = (props) => {
  const { bodySx, type } = props;
  const { user } = useAuthUser();
  const isPc = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(BREAKPOINT)
  );

  switch (type) {
    case 'securities': {
      const { name, departmentCode, departmentName, accountNumber } = props;

      return (
        <MuiBody bodySx={bodySx}>
          <SecuritiesAccountDlContainer
            label="金融機関名"
            value={name}
            underline
            isPc={isPc}
          />
          <SecuritiesAccountDlContainer
            label="部店名"
            value={departmentName as unknown as string}
            underline
            isPc={isPc}
          />
          <SecuritiesAccountDlContainer
            label="部店コード"
            value={departmentCode as unknown as string}
            underline
            isPc={isPc}
          />
          <SecuritiesAccountDlContainer
            label="口座番号"
            value={accountNumber as unknown as number}
            underline
            isPc={isPc}
          />
          <Typography
            component="p"
            variant="body-main/regular"
            sx={{ color: 'system.text-normal' }}
          >
            ※特定口座をお持ちの方は、自動的に特定口座へ入庫されます。
          </Typography>
        </MuiBody>
      );
    }
    case 'contribution':
    case 'reserve':
    case 'recess': {
      const { stocksNumber, unitAmount, monthlyUnit, bonusUnit } = props;
      const payValue = unitAmount * monthlyUnit;
      const bonusValue = unitAmount * bonusUnit;

      return (
        <MuiBody bodySx={bodySx}>
          {stocksNumber !== undefined && (
            <UserContributionDlContainer
              label="現在の保有株数"
              value={`${stocksNumber.toLocaleString(undefined, {
                maximumFractionDigits: props.investmentDigit,
              })}株`}
              underline
              column={1}
              isPc={isPc}
            />
          )}
          <UserContributionDlContainer
            label="1口あたり金額"
            value={`${unitAmount.toLocaleString()}円`}
            underline
            column={1}
            isPc={isPc}
          />
          <UserContributionDlContainer
            label="給与"
            value={monthlyUnit.toLocaleString()}
            underline={!isPc}
            isPayUnit
            isPc={isPc}
            payValue={payValue.toLocaleString()}
          />
          <UserContributionDlContainer
            label="賞与"
            value={bonusUnit.toLocaleString()}
            underline={!isPc}
            isBonusUnit
            isPc={isPc}
            bonusValue={bonusValue.toLocaleString()}
          />
        </MuiBody>
      );
    }
    /**
     * マイページ
     */
    case 'mypageUser': {
      const {
        nameKanji,
        nameKana,
        birthday,
        zipcode,
        address1,
        address2,
        address3,
        telType,
        tel,
        employeeCd,
        isApplyingUser,
      } = props;

      return (
        <MuiBody bodySx={bodySx}>
          <MypageDlContainer
            label={userLabels.name}
            value={`${nameKanji}`}
            underline
          />
          <MypageDlContainer
            label={userLabels.nameKane}
            value={`${nameKana}`}
            underline
          />
          <MypageDlContainer
            label={userLabels.birthday}
            value={
              !birthday || birthday === '****-**-**'
                ? '****年**月**日'
                : `${birthday.substring(0, 4)}年${Number(
                    birthday.substring(5, 7)
                  )}月${Number(birthday.substring(8, 10))}日`
            }
            underline
          />
          <MypageDlContainer
            label={userLabels.address}
            value={`〒${zipcode}`}
            subValue={`${address1}${address2}${address3}`}
            underline
          />
          <MypageDlContainer
            label={userLabels.tel}
            value={telType ?? 'HOME'}
            subValue={tel}
            underline
          />
          <MypageDlContainer
            label={userLabels.employeeCode}
            value={employeeCd ?? ''}
            underline={isApplyingUser}
          />
          {isApplyingUser && (
            <Box
              width="100%"
              p={1}
              borderRadius={1}
              sx={{ backgroundColor: '#E0F4F5' }}
            >
              <Typography variant="body-sub/regular" color="secondary.text">
                会員情報変更を申請しました。受理されるまでお待ちください。
              </Typography>
            </Box>
          )}
        </MuiBody>
      );
    }
    case 'mypageSecurities': {
      const {
        name,
        departmentCode,
        departmentName,
        accountNumber,
        associationCd,
      } = props;
      if (!departmentCode && !departmentName && !accountNumber) {
        // 口座なし
        return (
          <MuiBody bodySx={bodySx}>
            {bankAccountHGuideExceptionHandler(associationCd) ? (
              <Typography>
                口座開設手続きに関しては事務局までご連絡下さい。
              </Typography>
            ) : (
              <>
                <Typography>
                  口座をお持ちでない方は、こちらから開設できます。
                  <br />
                  なお、東海東京FH持株会の方はこちらから口座開設できません。事務局へ連絡して下さい。
                </Typography>
                <Link href={Path.ACCOUNTING_OPEN} target="_blank">
                  <Image src={Banner} alt="Banner" />
                </Link>
              </>
            )}
          </MuiBody>
        );
      }

      return (
        <MuiBody bodySx={bodySx}>
          <MypageDlContainer
            label={securitiesLabels.name}
            value={name}
            underline
          />
          <MypageDlContainer
            label={securitiesLabels.departmentCode}
            value={departmentCode as unknown as number}
            underline
          />
          <MypageDlContainer
            label={securitiesLabels.departmentName}
            value={departmentName as unknown as string}
            underline
          />
          <MypageDlContainer
            label={securitiesLabels.accountNumber}
            value={accountNumber as unknown as number}
            underline={false}
          />
        </MuiBody>
      );
    }
    case 'mypageContribution': {
      const {
        contributionStartDate,
        monthlyRecessStartDate,
        unitAmount,
        monthlyUnit,
        bonusUnit,
        // @ts-ignore
        monthlyIncentiveRatio,
        associationContribution,
        memberContribution,
      } = props;
      const cStartDate = formatStrToDate(contributionStartDate);
      const pStartDate = formatStrToDate(monthlyRecessStartDate);
      const payValue = unitAmount * monthlyUnit;
      const bonusUnitFixedMagnification = Calculations.bonusUnitAmount(
        { ...associationContribution } as AssociationContribution,
        { ...memberContribution } as UserContribution
      );
      const correctBonusUnit =
        associationContribution?.bonusMultiplierDesignationType ===
        'FIXED_MAGNIFICATION'
          ? bonusUnitFixedMagnification
          : bonusUnit;
      const bonusValue = unitAmount * correctBonusUnit;
      // 奨励金
      const monthlyIncentiveAmount = numberFormat(
        associationContribution?.monthlyIncentiveRatio === 0 &&
          associationContribution?.monthlyIncentiveFixedAmount !== 0
          ? associationContribution?.monthlyIncentiveFixedAmount
          : payValue * (monthlyIncentiveRatio / 100),
        { maximumFractionDigits: 0 }
      );
      const bonusIncentiveAmount = numberFormat(
        associationContribution?.bonusIncentiveRatio === 0 &&
          associationContribution?.bonusIncentiveFixedAmount !== 0
          ? associationContribution?.bonusIncentiveFixedAmount
          : bonusValue * (monthlyIncentiveRatio / 100),
        { maximumFractionDigits: 0 }
      );

      const isRecess = UserDomainService.isStatusForRecess(user);
      const statusText = UserDomainService.convertUserStatusText(user);
      const statusDetailText =
        UserDomainService.convertUserStatusDetailText(user);

      return (
        <MuiBody bodySx={bodySx}>
          <MypageDlContainer
            label={contributionLabels.status}
            value={`${statusText}${
              statusDetailText ? `(${statusDetailText})` : ''
            }`}
            underline
          />
          {!isRecess ? (
            <>
              <MypageDlContainer
                label={contributionLabels.contributionStartDate}
                value={`${cStartDate.getFullYear()}年${
                  cStartDate.getMonth() + 1
                }月${cStartDate.getDate()}日`}
                underline
              />
              <MypageDlContainer
                label={contributionLabels.monthlyIncentiveRatio}
                value={`${monthlyIncentiveRatio}%`}
                underline
              />
              <MypageDlContainer
                label={
                  <span>
                    {contributionLabels.payUnit}
                    <br />
                    (毎月の拠出)
                  </span>
                }
                value={`給与\u3000${monthlyUnit}口\u3000${payValue.toLocaleString()}円\u3000(奨励金${monthlyIncentiveAmount.toLocaleString()}円)`}
                underline
              />
              <MypageDlContainer
                label={'賞与'}
                value={`賞与\u3000${correctBonusUnit}口\u3000${bonusValue.toLocaleString()}円\u3000(奨励金${bonusIncentiveAmount.toLocaleString()}円)`}
                underline={false}
              />
            </>
          ) : (
            <MypageDlContainer
              label={contributionLabels.payRecessEndDate}
              value={`${pStartDate.getFullYear()}年${
                pStartDate.getMonth() + 1
              }月${pStartDate.getDate()}日`}
              underline
            />
          )}
        </MuiBody>
      );
    }
    case 'mypageLogin': {
      const { loginId } = props;
      return (
        <MuiBody bodySx={bodySx}>
          <MypageDlContainer
            label={loginLabels.loginId}
            subLabel="※メールアドレス"
            value={loginId}
            underline={false}
          />
        </MuiBody>
      );
    }
    case 'mypagePassword': {
      const { password } = props;
      return (
        <MuiBody bodySx={bodySx}>
          <MypageDlContainer
            label={passwordLabels.password}
            value={password}
            underline={false}
          />
        </MuiBody>
      );
    }
    case 'mypageSecretariats': {
      // const { companyName, mochikabukaiCode, mochikabukaiName } = props;
      const { mochikabukaiCode, mochikabukaiName } = props;
      return (
        <MuiBody bodySx={bodySx}>
          {/** 会社名 不要一旦コメントアウト
          <MypageDlContainer
            label={secretariatsLabel.companyName}
            value={companyName}
            underline
          />**/}
          <MypageDlContainer
            label={secretariatsLabel.mochikabukaiCode}
            value={mochikabukaiCode}
            underline={!isPc}
            isPc={isPc}
            column={2}
          />
          <MypageDlContainer
            label={secretariatsLabel.mochikabukaiName}
            value={mochikabukaiName}
            underline={false}
            isPc={isPc}
            column={2}
          />
        </MuiBody>
      );
    }
    default: {
      return null;
    }
  }
};

export const BasicTable: FC<BasicTableProps> = (props) => {
  const { boxSx, headerSx, bodySx, title, icon, buttonCaption, path, ...rest } =
    props;

  return (
    <MuiBox boxSx={boxSx}>
      <Header
        title={title}
        icon={icon}
        buttonCaption={buttonCaption}
        headerSx={headerSx}
        path={path}
        {...rest}
      />
      <Body bodySx={bodySx} {...rest} />
    </MuiBox>
  );
};
