import {
  PrimaryTableBodyTdMulti,
  PrimaryTableBodyTdSub,
} from '@/components/atoms/Table/PrimaryTableBodyTdMulti';
import { PrimaryTableBodyTdSingle } from '@/components/atoms/Table/PrimaryTableBodyTdSingle';
import { TextWithFlowIcon } from '@/components/molecules/Flow/TextWithFlowIcon';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { PrimaryTable } from '@/components/molecules/Table/PrimaryTable';
import { UserInfo } from '@/components/molecules/Table/UserInfo';
import { HomeHeader } from '@/components/organism/HomeHeader';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from '@mui/material';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { FAQ } from '@/components/organism/Apply/Faq';
import { WarmingDeadLine } from '@/components/organism/Apply/WarmingDeadLine';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { PrimaryTableBodyTdSingleWithSpNotRed } from '@/components/atoms/Table/PrimaryTableBodyTdSingleWithSpNotRed';
import { TextInput } from '@/components/atoms/Input/TextInput';
import { BREAKPOINT } from '@/theme/theme';
import { ConvertAssociation } from '@/services/convert/association';
import { ConvertAssociationContribution } from '@/services/convert/associationContribution';
import { ConvertEventsmemberdeadlines } from '@/services/convert/eventsmemberdeadlines';
import { ConvertUserContribution } from '@/services/convert/userContribution';
import useSWR from 'swr';
import { appClient } from '@/services';
import { useAuthUser } from '@/hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import { Association } from '@/types/api/association';
import { AssociationContribution } from '@/types/api/associationContribution';
import { Eventsmemberdeadlines } from '@/types/api/eventsmemberdeadlines';
import { UserContribution } from '@/types/api/userContribution';
import { Path } from '@/constants/Router/path';
import { numberFormat } from '@/utils/numberFormat';
import { BountyCalculation, Calculations } from '@/utils/calculation';
import { IsNumeric } from '@/utils/stringProcessor';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const Wrraper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    marginBottom: '80px',
    padding: '14px 0',
    [breakpoints.up(BREAKPOINT)]: {
      padding: '14px 40px',
    },
    [breakpoints.up('lg')]: {
      padding: '14px 130px',
    },
  };
});

const MuiBox = styled(Box)(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down(BREAKPOINT)]: {
      display: 'block',
    },
  };
});

const ContentWrraper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
});

const MuiRadioGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'row',
});

// パンクズ
const links = [
  {
    path: 'TOP',
    to: Path.HOME,
  },
  {
    path: '口数を変更する',
    to: Path.PAY,
  },
];

// タイトルの説明文
const description = ['給与(毎月の拠出)の積立口数を変更します。'];

type LocationState = {
  inputValue: number;
};

export const PayIndex: FC = () => {
  const navigate = useNavigate();
  /**
   * @value is Salary (給与)
   * @value2 is Bonus (賞与)
   */
  const [isBonusToggle, setIsBonusToggle] = useState<boolean>(true);
  const [value, setValue] = useState<string>();
  const [value2, setValue2] = useState<string>();
  const [value2Rate, setValue2Rate] = useState<number>(1);
  const [association, setAssociation] = useState<Association>();
  const [userContribution, setUserContribution] = useState<UserContribution>();
  const [associationContribution, setAssociationContribution] =
    useState<AssociationContribution>();
  const [eventsmemberdeadlines, setEventsmemberdeadlines] =
    useState<Eventsmemberdeadlines>();

  useHelmetHandler({
    title: '口数変更(給与) 入力画面',
  });

  const location = useLocation();
  const state = location.state as LocationState;

  // 【API-A-0909】資産情報取得
  const { user } = useAuthUser();

  // 【API-A-1002】持株会（事務局）拠出情報取得
  const { data: findAssociationContribution } = useSWR(
    '/api/associations/{associationCd}/contributions',
    () => appClient.associations.findAssociationContribution()
  );

  // 【API-A-1001】持株会（事務局）情報
  const { data: findAssociationById } = useSWR(
    '/api/associations/{associationCd}',
    () => appClient.associations.findAssociationById()
  );

  // 【API-A-1001】持株会（事務局）情報
  const { data: findAssociationEventMemberDeadlines } = useSWR(
    '/api/associations/{associationCd}/eventsmemberdeadlines',
    () => appClient.associations.findAssociationEventMemberDeadlines()
  );

  // 【API-A-0905】拠出情報取得
  const { data: findUserContribution } = useSWR('/api/users/contribution', () =>
    appClient.users.findUserContribution()
  );

  useEffect(() => {
    setAssociation(ConvertAssociation(findAssociationById));
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const associationContributionInstance = ConvertAssociationContribution(
      findAssociationContribution
    );
    setAssociationContribution(associationContributionInstance);
    setEventsmemberdeadlines(
      ConvertEventsmemberdeadlines(
        findAssociationEventMemberDeadlines?.eventmemberdeadlines
      )
    );
    const memberContributionInstance =
      ConvertUserContribution(findUserContribution);
    setUserContribution(memberContributionInstance);
    setIsBonusToggle(findUserContribution?.bonusUnit !== 0);
    if (
      associationContributionInstance?.bonusMultiplierDesignationType ===
      'MAGNIFICATION'
    )
      setValue2Rate(memberContributionInstance?.bonusRate || 1);
  }, [
    findAssociationById,
    findAssociationContribution,
    findAssociationEventMemberDeadlines,
    findUserContribution,
  ]);

  useEffect(() => {
    if (state) {
      setValue(state.inputValue.toString());
    } else if (userContribution) {
      setValue(userContribution.monthlyUnit.toString());
      switch (associationContribution?.bonusMultiplierDesignationType) {
        case 'UNIT_NUMBER':
          setValue2(userContribution.bonusUnit.toString());
          break;
        case 'MAGNIFICATION':
          setValue2(userContribution.monthlyUnit.toString());
          break;
        case 'FIXED_MAGNIFICATION':
          setValue2(
            (
              userContribution.monthlyUnit *
              associationContribution.bonusMultiplierDetails[0]
            ).toString()
          );
          setValue2Rate(associationContribution.bonusMultiplierDetails[0]);
          break;
        default:
          break;
      }
    }
  }, [userContribution]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    isBonus?: boolean
  ) => {
    let amount = value;
    if (IsNumeric(e.target.value)) {
      amount = Math.floor(
        Math.min(
          Number(e.target.value),
          (!isBonus
            ? associationContribution?.maxMonthlyUnit
            : associationContribution?.maxBonusUnit) ?? 0
        )
      ).toString();
    } else if (e.target.value === '') {
      amount = undefined;
    }
    if (isBonus) setValue2(amount || '');
    else {
      setValue(amount || '');
      if (
        associationContribution?.bonusMultiplierDesignationType ===
        'FIXED_MAGNIFICATION'
      )
        setValue2(
          (
            Number(amount) * associationContribution.bonusMultiplierDetails[0]
          ).toString()
        );
      if (
        associationContribution?.bonusMultiplierDesignationType ===
        'MAGNIFICATION'
      )
        setValue2((Number(amount) * value2Rate).toString());
    }
  };

  const editUnitAmount = useMemo(
    () =>
      associationContribution
        ? Number(value) * associationContribution.unitAmount
        : 0,
    [value, associationContribution]
  );

  const editBonusUnitAMount = useMemo(
    () =>
      associationContribution
        ? Number(value2) * associationContribution.unitAmount
        : 0,
    [value2, associationContribution]
  );

  const isDisabled = (): boolean => {
    if (isBonusToggle)
      switch (associationContribution?.bonusMultiplierDesignationType) {
        case 'UNIT_NUMBER':
        case 'MAGNIFICATION':
          // eslint-disable-next-line no-case-declarations
          const result =
            (Number(value) !== 0 &&
              Number(value) !==
                (userContribution ? userContribution.monthlyUnit : 0)) ||
            (Number(value2) !== 0 &&
              Number(value2) !==
                (userContribution ? userContribution.bonusUnit : 0));

          return !result;
        case 'FIXED_MAGNIFICATION':
          return !(
            (Number(value) !== 0 &&
              Number(value) !==
                (userContribution ? userContribution.monthlyUnit : 0)) ||
            isBonusToggle !== (findUserContribution?.bonusUnit !== 0)
          );
        default:
          return true;
      }
    else {
      const result =
        (Number(value) !== 0 &&
          Number(value) !==
            (userContribution ? userContribution.monthlyUnit : 0)) ||
        isBonusToggle !== (findUserContribution?.bonusUnit !== 0);

      return !result;
    }
  };

  const getIncentiveAmount = (
    _associationContribution: AssociationContribution,
    _userContribution: UserContribution
  ) => {
    let unit = Number(value2);
    // In case bonusIncentiveRatio is 0 and bonusIncentiveFixedAmount is not  0
    if (
      _associationContribution.bonusIncentiveRatio === 0 &&
      _associationContribution.bonusIncentiveFixedAmount !== 0
    )
      return _associationContribution.bonusIncentiveFixedAmount;

    if (
      _associationContribution.bonusMultiplierDesignationType !== 'UNIT_NUMBER'
    ) {
      unit =
        _associationContribution.bonusMultiplierDesignationType ===
        'MAGNIFICATION'
          ? Number(value || 0) * value2Rate
          : Number(value || 0) *
            _associationContribution.bonusMultiplierDetails[0];
    }
    // default return
    return BountyCalculation('BONUS', _associationContribution, unit);
  };

  const getContributionAmount = (
    _associationContribution: AssociationContribution
  ) => {
    switch (_associationContribution.bonusMultiplierDesignationType) {
      case 'UNIT_NUMBER':
        return numberFormat(editBonusUnitAMount);
      case 'FIXED_MAGNIFICATION':
        return numberFormat(
          editUnitAmount * _associationContribution.bonusMultiplierDetails[0]
        );
      case 'MAGNIFICATION':
        return numberFormat(editUnitAmount * value2Rate);
      default:
        return '';
    }
  };

  const renderBonusUnitOrRateInput = (
    _associationContribution: AssociationContribution,
    _userContribution: UserContribution
  ) => {
    switch (_associationContribution.bonusMultiplierDesignationType) {
      case 'UNIT_NUMBER':
        return (
          <MuiBox>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextInput
                name="payUnit"
                value={value2}
                onChange={(e) => handleChange(e, true)}
              />
              <Typography
                sx={{ marginLeft: '8px', marginRight: '30px' }}
                variant="body-sub/regular"
              >
                口
              </Typography>
            </Box>
            <Typography
              variant="body-sub/regular"
              sx={{ color: 'system.text-light' }}
            >
              ※最大
              {numberFormat(_associationContribution.maxBonusUnit)}口
            </Typography>
          </MuiBox>
        );
      case 'MAGNIFICATION':
        return (
          <MuiBox>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <MuiRadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="yes"
                name="radio-buttons-group"
              >
                {_associationContribution.bonusMultiplierDetails.map((i) => (
                  <FormControlLabel
                    value={i}
                    control={<Radio />}
                    checked={i === value2Rate}
                    label={`${i}倍`}
                    onClick={() => {
                      setValue2Rate(i);
                      setValue2((Number(value) * i).toString());
                    }}
                  />
                ))}
              </MuiRadioGroup>
            </Box>
          </MuiBox>
        );
      case 'FIXED_MAGNIFICATION':
        return (
          <MuiBox>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{ marginLeft: '8px', marginRight: '16px' }}
                variant="body-sub/bold"
              >
                {_associationContribution.bonusMultiplierDetails[0]}倍
              </Typography>
            </Box>
            <Typography
              variant="body-sub/regular"
              sx={{ color: 'system.text-light' }}
            >
              ※給与（毎月の拠出）対比
            </Typography>
          </MuiBox>
        );
      default:
        return null;
    }
  };

  if (
    association &&
    associationContribution &&
    userContribution &&
    eventsmemberdeadlines
  ) {
    return (
      <Wrraper>
        <HomeHeader
          links={links}
          title="口数を変更する"
          description={description}
        />
        <TextWithFlowIcon flowOn={0} widthFull />
        <FAQ pageName="pay">
          <ContentWrraper>
            <WarmingDeadLine
              eventsmemberdeadlines={eventsmemberdeadlines}
              target="MONTHLY_UNIT"
            />
            <UserInfo
              mochikabukaiCode={user.associationCd ?? ''}
              mochikabukaiName={association.associationName ?? ''}
              nameKanji={user.nameKanji ?? ''}
              nameKana={user.nameKana ?? ''}
              zipcode={user.zipcode ?? ''}
              address1={user.address1 ?? ''}
              address2={user.address2 ?? ''}
              address3={user.address3 ?? ''}
              tel={user.tel ?? ''}
              employeeCd={user.employeeCd ?? ''}
              editable
            />
            <BasicTable
              boxSx={{ width: '100%' }}
              title="現在の口数設定"
              type="contribution"
              unitAmount={associationContribution.unitAmount}
              monthlyUnit={userContribution.monthlyUnit}
              bonusUnit={
                associationContribution.bonusMultiplierDesignationType ===
                'FIXED_MAGNIFICATION'
                  ? Calculations.bonusUnitAmount(
                      associationContribution,
                      userContribution
                    )
                  : userContribution.bonusUnit
              }
              investmentDigit={association.investmentDigit}
            />
            <PrimaryTable title="新たな口数設定">
              <PrimaryTableBodyTdSingleWithSpNotRed
                title="1口あたり金額"
                value={`${numberFormat(associationContribution.unitAmount)}円`}
              />
              <PrimaryTableBodyTdSingleWithSpNotRed
                title="奨励金率"
                value={`${associationContribution.monthlyIncentiveRatio}%`}
              />
              <PrimaryTableBodyTdMulti
                title="給与"
                subTitle="※毎月の拠出"
                row={3}
              >
                <PrimaryTableBodyTdSub title="口数">
                  <MuiBox>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TextInput
                        name="payUnit"
                        value={value}
                        onChange={(e) => handleChange(e)}
                      />
                      <Typography
                        sx={{ marginLeft: '8px', marginRight: '30px' }}
                        variant="body-sub/regular"
                      >
                        口
                      </Typography>
                    </Box>
                    <Typography
                      variant="body-sub/regular"
                      sx={{ color: 'system.text-light' }}
                    >
                      ※最大
                      {numberFormat(associationContribution.maxMonthlyUnit)}口
                    </Typography>
                  </MuiBox>
                </PrimaryTableBodyTdSub>
              </PrimaryTableBodyTdMulti>
              <PrimaryTableBodyTdSingle>
                <PrimaryTableBodyTdSub title="拠出金額">
                  <Typography variant="body-main/bold">
                    {numberFormat(editUnitAmount)}円
                  </Typography>
                </PrimaryTableBodyTdSub>
              </PrimaryTableBodyTdSingle>
              <PrimaryTableBodyTdSingle>
                <PrimaryTableBodyTdSub title="奨励金額">
                  <Typography variant="body-main/bold">
                    {associationContribution.monthlyIncentiveRatio === 0 &&
                    associationContribution.monthlyIncentiveFixedAmount !== 0
                      ? numberFormat(
                          associationContribution.monthlyIncentiveFixedAmount
                        )
                      : numberFormat(
                          BountyCalculation(
                            'MONTHLY',
                            associationContribution,
                            Number(value)
                          ),
                          { maximumFractionDigits: 0 }
                        )}
                    円
                  </Typography>
                </PrimaryTableBodyTdSub>
              </PrimaryTableBodyTdSingle>
              {associationContribution.bonusReserveClassification !==
                'NONE' && (
                <>
                  <PrimaryTableBodyTdMulti spWidth="80px" title="賞与" row={4}>
                    <PrimaryTableBodyTdSub title="拠出">
                      <FormControl>
                        <MuiRadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="yes"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            checked={isBonusToggle === true}
                            control={<Radio />}
                            label="する"
                            onClick={() => {
                              if (
                                associationContribution.bonusMultiplierDesignationType ===
                                'UNIT_NUMBER'
                              )
                                setValue2(
                                  (
                                    value2 ||
                                    findUserContribution?.bonusUnit ||
                                    0
                                  ).toString()
                                );
                              if (
                                associationContribution.bonusMultiplierDesignationType ===
                                'MAGNIFICATION'
                              ) {
                                setValue2Rate(
                                  associationContribution
                                    .bonusMultiplierDetails[0]
                                );
                                setValue2(
                                  (
                                    Number(
                                      value ||
                                        findUserContribution?.monthlyUnit ||
                                        0
                                    ) * value2Rate
                                  ).toString()
                                );
                              }
                              if (
                                associationContribution.bonusMultiplierDesignationType ===
                                'FIXED_MAGNIFICATION'
                              ) {
                                setValue2(
                                  (
                                    Number(
                                      value ||
                                        findUserContribution?.monthlyUnit ||
                                        0
                                    ) * value2Rate
                                  ).toString()
                                );
                              }
                              setIsBonusToggle(true);
                            }}
                          />
                          <FormControlLabel
                            checked={isBonusToggle === false}
                            control={<Radio />}
                            label="しない"
                            onClick={() => {
                              setValue2('0');
                              setIsBonusToggle(false);
                            }}
                          />
                        </MuiRadioGroup>
                      </FormControl>
                    </PrimaryTableBodyTdSub>
                  </PrimaryTableBodyTdMulti>
                  {isBonusToggle && (
                    <>
                      <PrimaryTableBodyTdSingle>
                        <PrimaryTableBodyTdSub
                          title={
                            associationContribution.bonusMultiplierDesignationType ===
                            'FIXED_MAGNIFICATION'
                              ? '倍率'
                              : '口数'
                          }
                        >
                          {renderBonusUnitOrRateInput(
                            associationContribution,
                            userContribution
                          )}
                        </PrimaryTableBodyTdSub>
                      </PrimaryTableBodyTdSingle>
                      <PrimaryTableBodyTdSingle>
                        <PrimaryTableBodyTdSub title="拠出金額">
                          <Typography variant="body-main/bold">
                            {getContributionAmount(associationContribution)}円
                          </Typography>
                        </PrimaryTableBodyTdSub>
                      </PrimaryTableBodyTdSingle>
                      <PrimaryTableBodyTdSingle>
                        <PrimaryTableBodyTdSub title="奨励金額">
                          <Typography variant="body-main/bold">
                            {numberFormat(
                              getIncentiveAmount(
                                associationContribution,
                                userContribution
                              ),
                              { maximumFractionDigits: 0 },
                              true
                            )}
                            円
                          </Typography>
                        </PrimaryTableBodyTdSub>
                      </PrimaryTableBodyTdSingle>
                    </>
                  )}
                </>
              )}
            </PrimaryTable>

            <PrimaryButton
              sx={{
                display: 'flex',
                margin: 'auto',
                borderRadius: '28px',
                padding: '16px 24px',
              }}
              onClick={() =>
                navigate(Path.PAY_CONFIRM, {
                  state: {
                    user,
                    association,
                    associationContribution,
                    userContribution,
                    eventsmemberdeadlines,
                    value,
                    editUnitAmount,
                    monthlyIncentive: BountyCalculation(
                      'MONTHLY',
                      associationContribution,
                      Number(value)
                    ),
                    isBonus: isBonusToggle,
                    bonusUnitAmount: value2,
                    bonusContributionAmount: getContributionAmount(
                      associationContribution
                    ),
                    bonusIncentive: numberFormat(
                      getIncentiveAmount(
                        associationContribution,
                        userContribution
                      ),
                      { maximumFractionDigits: 0 },
                      true
                    ),
                    bonusRate:
                      associationContribution.bonusMultiplierDesignationType ===
                      'UNIT_NUMBER'
                        ? 0
                        : value2Rate,
                  },
                })
              }
              disabled={isDisabled()}
            >
              確認へ進む
            </PrimaryButton>
          </ContentWrraper>
        </FAQ>
      </Wrraper>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
