import { FC, useEffect, useState } from 'react';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { FindApplicationById } from '@/types/api/applications';
import { AssociationContribution } from '@/types/api/associationContribution';
import { Association } from '@/types/api/association';
import useSWR from 'swr';
import { appClient } from '@/services';
import { ConvertAssociationContribution } from '@/services/convert/associationContribution';
import { ConvertAssociation } from '@/services/convert/association';

interface Props {
  applicationResume: FindApplicationById['applicationResume'];
}

export const Restart: FC<Props> = ({ applicationResume }) => {
  const [associationContribution, setAssociationContribution] =
    useState<AssociationContribution>();
  const [association, setAssociation] = useState<Association>();

  // 【API-A-1002】持株会（事務局）拠出情報取得
  const { data: findAssociationContribution } = useSWR(
    '/api/associations/{associationCd}/contributions',
    () => appClient.associations.findAssociationContribution()
  );

  const { data: findAssociationById } = useSWR(
    '/api/associations/{associationCd',
    () => appClient.associations.findAssociationById()
  );

  useEffect(() => {
    setAssociationContribution(
      ConvertAssociationContribution(findAssociationContribution)
    );
  }, [findAssociationContribution]);

  useEffect(() => {
    setAssociation(ConvertAssociation(findAssociationById));
  }, [findAssociationById]);

  return (
    <BasicTable
      title="現在の積立状況"
      type="recess"
      stocksNumber={applicationResume?.sharesNum}
      unitAmount={associationContribution?.unitAmount ?? 0}
      monthlyUnit={applicationResume?.monthlyUnit ?? 0}
      bonusUnit={applicationResume?.bonusUnit ?? 0}
      investmentDigit={association?.investmentDigit ?? 0}
    />
  );
};
