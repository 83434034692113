import { FC } from 'react';
import { BonusMonthSelectionField } from '@/components/molecules/Simulation/BonusMonthSelectionField';
import { MonthSelectionTitle } from '@/components/molecules/Simulation/MonthSelection';
import { Box, SelectChangeEvent, styled } from '@mui/material';
import { AccumulationPeriodSelectionField } from '@/components/molecules/Simulation/AccumulationPeriodSelectionField';

interface MonthSelectionRowProps {
  title: string;
  subtitle?: string;
  type: 'AccumulationPeriod' | 'BonusMonth';
  error: boolean;
  onChangeBonusMonth1?: (e: SelectChangeEvent<any>) => void;
  onChangeBonusMonth2?: (e: SelectChangeEvent<any>) => void;
  onChangeStartYear?: (e: SelectChangeEvent<any>) => void;
  onChangeStartMonth?: (e: SelectChangeEvent<any>) => void;
  onChangeEndYear?: (e: SelectChangeEvent<any>) => void;
  onChangeEndMonth?: (e: SelectChangeEvent<any>) => void;
}

const Wrapper = styled(Box)(({ theme }) => {
  const { palette, breakpoints } = theme;

  return {
    marginTop: 4,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: palette.system.background,
    width: '100%',

    [breakpoints.down('sm')]: {
      margin: '4px auto 0',
    },
  };
});

export const MonthSelectionRow: FC<MonthSelectionRowProps> = ({
  title,
  subtitle,
  type,
  error,
  onChangeBonusMonth1,
  onChangeBonusMonth2,
  onChangeStartYear,
  onChangeStartMonth,
  onChangeEndYear,
  onChangeEndMonth,
}) => (
  <Wrapper>
    <MonthSelectionTitle title={title} />
    {type === 'BonusMonth' && (
      <BonusMonthSelectionField
        error={error}
        onChangeBonusMonth1={onChangeBonusMonth1}
        onChangeBonusMonth2={onChangeBonusMonth2}
      />
    )}
    {type === 'AccumulationPeriod' && subtitle && (
      <AccumulationPeriodSelectionField
        title={subtitle}
        error={error}
        onChangeStartYear={onChangeStartYear}
        onChangeStartMonth={onChangeStartMonth}
        onChangeEndYear={onChangeEndYear}
        onChangeEndMonth={onChangeEndMonth}
      />
    )}
  </Wrapper>
);
