import { BREAKPOINT, theme } from '@/theme/theme';
import { formatDateTime } from '@/utils/dateFunctions';
import { numberFormat } from '@/utils/numberFormat';
import { Box, styled } from '@mui/material';
import { BalanceInquiryHeading } from './BalanceInquiryHeading';
import { ItemInfoRow } from './InvestmentInfoRow';

const Wrapper = styled(Box)(() => {
  const { breakpoints } = theme;
  return {
    marginTop: 32,
    [breakpoints.up(BREAKPOINT)]: {
      padding: '0 40px',
    },
    [breakpoints.up('lg')]: {
      padding: '0 130px',
    },
  };
});

const GroupManager = styled(Box)(() => {
  const { breakpoints } = theme;

  return {
    margin: 0,
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: 16,
      justifyContent: undefined,
      width: '100%',
    },

    '@media print': {
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginTop: 20,
    },
  };
});

const Group = styled(Box)(() => {
  const { breakpoints } = theme;

  return {
    flex: 1,
    flexDirection: 'column',
    marginLeft: 24,

    '&:first-of-type': {
      marginLeft: 0,
    },

    [breakpoints.down('sm')]: {
      marginTop: 8,
      marginLeft: 0,
      '&:first-of-type': {
        marginTop: 0,
      },
    },

    '@media print': {
      marginLeft: 24,
      marginTop: 0,

      '&:first-of-type': {
        marginLeft: 0,
      },
    },
  };
});

const GroupItem = styled(Box)(() => {
  const { breakpoints, palette } = theme;

  return {
    flex: 1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.system.separator,
    borderRadius: 4,
    marginTop: 24,

    '&:first-of-type': {
      marginTop: 0,
    },

    [breakpoints.down('sm')]: {
      borderRadius: 0,
      marginTop: 8,
      borderRightWidth: 0,
      borderLeftWidth: 0,

      '&:first-of-type': {
        marginTop: 0,
      },
    },

    '@media print': {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: palette.system.separator,
      borderRadius: 4,
      marginTop: 24,

      '&:first-of-type': {
        marginTop: 0,
      },
    },
  };
});

type Props = {
  date: Date;
  stocksNumber: number;
  oldStock: number;
  firstNewStock: number;
  secondNewStock: number;
  carriedForwardAmount: number;
  cumulativeDeposit: number;
  cumulativeContribution: number;
  cumulativeIncentives: number;
  cumulativeDividend: number;
  cumulativeInterest: number;
  cumulativeOtherDeposits: number;
  cumulativeWithdrawal: number;
  cumulativeOfficeExpenses: number;
  withdrawSharesCnt: number;
  purchaseAvarageUnitPrice: number;
  investmentDigit?: number;
};

export const CurrentInvestmentStatus = ({
  date,
  stocksNumber,
  oldStock,
  firstNewStock,
  secondNewStock,
  carriedForwardAmount,
  cumulativeDeposit,
  cumulativeContribution,
  cumulativeIncentives,
  cumulativeDividend,
  cumulativeInterest,
  cumulativeOtherDeposits,
  cumulativeWithdrawal,
  cumulativeOfficeExpenses,
  withdrawSharesCnt,
  purchaseAvarageUnitPrice,
  investmentDigit = 0,
}: Props) => (
  <Wrapper>
    <BalanceInquiryHeading
      title="現在のご投資状況 "
      subtitle={`${formatDateTime(date.toString())} 現在`}
      sx={{ padding: { xs: '0 16px', sm: 0 } }}
    />
    <GroupManager>
      <Group>
        <GroupItem>
          <ItemInfoRow
            headerText="ご投資明細現在高"
            headerSymbol="(株)"
            value={stocksNumber.toLocaleString(undefined, {
              maximumFractionDigits: investmentDigit,
            })}
            isHeader
          />
          <ItemInfoRow
            headerText="旧株"
            headerSymbol="(株)"
            value={oldStock.toLocaleString(undefined, {
              maximumFractionDigits: investmentDigit,
            })}
            isHeader={false}
          />
          <ItemInfoRow
            headerText="第一新株"
            headerSymbol="(株)"
            isHeader={false}
            value={firstNewStock.toLocaleString(undefined, {
              maximumFractionDigits: investmentDigit,
            })}
          />
          <ItemInfoRow
            headerText="第二新株"
            headerSymbol="(株)"
            value={secondNewStock.toLocaleString(undefined, {
              maximumFractionDigits: investmentDigit,
            })}
            isHeader={false}
          />
        </GroupItem>
        <GroupItem>
          <ItemInfoRow
            headerText="繰越金額"
            headerSymbol="(円)"
            value={numberFormat(carriedForwardAmount)}
            isHeader
          />
        </GroupItem>
      </Group>
      <Group>
        <GroupItem>
          <ItemInfoRow
            headerText="入金合計"
            headerSymbol="(円)"
            value={numberFormat(cumulativeDeposit)}
            isHeader
          />
          <ItemInfoRow
            headerText="拠出金"
            headerSymbol="(円)"
            value={numberFormat(cumulativeContribution)}
            isHeader={false}
          />
          <ItemInfoRow
            headerText="奨励金"
            headerSymbol="(円)"
            value={numberFormat(cumulativeIncentives)}
            isHeader={false}
          />
          <ItemInfoRow
            headerText="配当金"
            headerSymbol="(円)"
            value={numberFormat(cumulativeDividend)}
            isHeader={false}
          />
          <ItemInfoRow
            headerText="利息"
            headerSymbol="(円)"
            value={numberFormat(cumulativeInterest)}
            isHeader={false}
          />
          <ItemInfoRow
            headerText="その他入金"
            headerSymbol="(円)"
            value={numberFormat(cumulativeOtherDeposits)}
            isHeader={false}
          />
        </GroupItem>
      </Group>
      <Group>
        <GroupItem>
          <ItemInfoRow
            headerText="出金累計"
            headerSymbol="(円)"
            value={numberFormat(cumulativeWithdrawal)}
            isHeader
          />
          <ItemInfoRow
            headerText="事務経費"
            headerSymbol="(円)"
            value={numberFormat(cumulativeOfficeExpenses)}
            isHeader={false}
          />
        </GroupItem>
        <GroupItem>
          <ItemInfoRow
            headerText="ご投資明細"
            headerSymbol="(株)"
            value={(stocksNumber + withdrawSharesCnt).toLocaleString(
              undefined,
              {
                maximumFractionDigits: investmentDigit,
              }
            )}
            isHeader
          />
          <ItemInfoRow
            headerText="引出株数"
            headerSymbol="(株)"
            value={withdrawSharesCnt.toLocaleString(undefined, {
              maximumFractionDigits: investmentDigit,
            })}
            isHeader={false}
          />
          <ItemInfoRow
            headerText="平均買付価格"
            headerSymbol="(円)"
            value={numberFormat(purchaseAvarageUnitPrice)}
            isHeader
          />
        </GroupItem>
      </Group>
    </GroupManager>
  </Wrapper>
);
