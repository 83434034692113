import { PrimaryTableBodyTdMulti } from '@/components/atoms/Table/PrimaryTableBodyTdMulti';
import { TextWithFlowIcon } from '@/components/molecules/Flow/TextWithFlowIcon';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { PrimaryTable } from '@/components/molecules/Table/PrimaryTable';
import { UserInfo } from '@/components/molecules/Table/UserInfo';
import { HomeHeader } from '@/components/organism/HomeHeader';
import { Box, styled, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FAQ } from '@/components/organism/Apply/Faq';
import { WarmingDeadLine } from '@/components/organism/Apply/WarmingDeadLine';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { BREAKPOINT, theme } from '@/theme/theme';
import { TextInput } from '@/components/atoms/Input/TextInput';
import { AccountOpeningTip } from '@/components/organism/Apply/AccountOpeningTip';
import { Warming } from '@/components/organism/Apply/Warming';
import { useHomeHooks } from '@/components/pages/Home/hooks/useHomeHooks';
import { useAuthUser } from '@/hooks/useAuth';
import { appClient } from '@/services';
import { ConvertAssociation } from '@/services/convert/association';
import { ConvertAssociationContribution } from '@/services/convert/associationContribution';
import { ConvertEventsmemberdeadlines } from '@/services/convert/eventsmemberdeadlines';
import { ConvertUserContribution } from '@/services/convert/userContribution';
import { ConvertSecuritiesAccount } from '@/services/convert/securitiesAccount';
import { Association } from '@/types/api/association';
import { AssociationContribution } from '@/types/api/associationContribution';
import { Eventsmemberdeadlines } from '@/types/api/eventsmemberdeadlines';
import { UserContribution } from '@/types/api/userContribution';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { FindSecuritiesAccount } from '@/types/api/users';
import { Path } from '@/constants/Router/path';
import { numberFormat } from '@/utils/numberFormat';
import { IsNumeric } from '@/utils/stringProcessor';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { useSnackbarMessage } from '@/hooks';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const Wrraper = styled('div')(() => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    marginBottom: '80px',
    padding: '14px 0',
    [breakpoints.up(BREAKPOINT)]: {
      padding: '14px 40px',
    },
    [breakpoints.up('lg')]: {
      padding: '14px 130px',
    },
  };
});

const ContentWrraper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
});

const MuiBox = styled(Box)(() => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down(BREAKPOINT)]: {
      display: 'block',
    },
  };
});

// パンクズ
const links = [
  {
    path: 'TOP',
    to: Path.HOME,
  },
  {
    path: '一部を引き出す',
    to: Path.PART,
  },
];

// タイトルの説明文
const description = [
  'これまでに積み立てた株のうち、一部を引き出します。売却には',
  <Typography variant="body-main/bold" sx={{ color: 'system.text-normal' }}>
    社内ルール（規定）
  </Typography>,
  'がありますので必ず社内でご確認ください。',
  <br />,
  '（引出には証券口座が必要です。）',
];

type LocationState = {
  payUnit: number;
  branchCode: string;
  branchName: string;
  accountNumber: string;
};

export const PartIndex: FC = () => {
  const navigate = useNavigate();
  const { showMessage } = useSnackbarMessage();
  const [payUnit, setPayUnit] = useState<string>();
  const [branchCode, setBranchCode] = useState('');
  const [branchName, setBranchName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [association, setAssociation] = useState<Association>();
  const [userContribution, setUserContribution] = useState<UserContribution>();
  const [associationContribution, setAssociationContribution] =
    useState<AssociationContribution>();
  const [eventsmemberdeadlines, setEventsmemberdeadlines] =
    useState<Eventsmemberdeadlines>();
  const [securitiesAccount, setSecuritiesAccount] =
    useState<FindSecuritiesAccount>();

  useHelmetHandler({
    title: '一部引出 入力画面',
  });

  const location = useLocation();
  const state = location.state as LocationState;
  const { isListedClassification } = useHomeHooks();

  useEffect(() => {
    if (
      association &&
      association.listedClassification &&
      association.listedClassification !== '1'
    ) {
      navigate(`/`);
    }
  }, [association]);

  // 【API-A-0909】資産情報取得
  const { user } = useAuthUser();

  // 【API-A-1002】持株会（事務局）拠出情報取得
  const { data: findAssociationContribution } = useSWR(
    '/api/associations/{associationCd}/contributions',
    () => appClient.associations.findAssociationContribution()
  );

  // 【API-A-1001】持株会（事務局）情報
  const { data: findAssociationById } = useSWR(
    '/api/associations/{associationCd}',
    () => appClient.associations.findAssociationById()
  );

  // 【API-A-1001】持株会（事務局）情報
  const { data: findAssociationEventMemberDeadlines } = useSWR(
    '/api/associations/{associationCd}/eventsmemberdeadlines',
    () => appClient.associations.findAssociationEventMemberDeadlines()
  );

  // 【API-A-0906】証券口座情報取得
  const { data: findSecuritiesAccount } = useSWR(
    '/api/users/securitiesaccounts',
    () => appClient.users.findSecuritiesAccount()
  );

  // 【API-A-0905】拠出情報取得
  const { data: findUserContribution } = useSWR('/api/users/contribution', () =>
    appClient.users.findUserContribution()
  );

  // 【API-A-0909】資産情報取得
  const { data: userAsset } = useSWR('/api/users/assets', () =>
    appClient.users.findUserAsset()
  );

  const validatePayUnit = (): boolean => {
    let result = false;
    if (userAsset?.stocksNumber && payUnit && Number(payUnit) > 0) {
      const { stocksNumber } = userAsset;
      result = stocksNumber - Number(payUnit) * 100 >= 0;
    }
    return result;
  };

  const retrieveBranchName = async () => {
    try {
      if (branchCode) {
        const res = await appClient.users.findBranch(branchCode);
        if (res.branchName) setBranchName(res.branchName);
        else {
          throw Error('Branch name is nor valid');
        }
      }
    } catch (e) {
      // Handle error
      showMessage({
        open: true,
        messages: ['部店コードが存在しません'],
        messageType: 'warning',
      });
    }
  };

  const creatSecuritiesAccount = () => {
    let returnParam = null;

    if (isListedClassification) {
      if (
        !securitiesAccount ||
        ((securitiesAccount.accountNumber || '') === '' &&
          (securitiesAccount.branchCd || '') === '' &&
          (securitiesAccount.branchName || '') === '')
      ) {
        returnParam = (
          <>
            <PrimaryTable title="証券口座">
              <PrimaryTableBodyTdMulti
                title="金融機関名"
                row={1}
                spWidth="130px"
              >
                <Typography variant="body-main/bold">東海東京証券</Typography>
              </PrimaryTableBodyTdMulti>
              <PrimaryTableBodyTdMulti
                title="部店コード"
                subTitle="※半角数字3桁"
                row={1}
                spWidth="130px"
              >
                <TextInput
                  name="branchCode"
                  value={branchCode}
                  onChange={(event) => {
                    if (!event.target.value) {
                      setBranchCode(event.target.value);
                    }
                    if (
                      IsNumeric(event.target.value) &&
                      event.target.value.length <= 3 &&
                      event.target.value.length > 0
                    ) {
                      setBranchCode(event.target.value);
                    }
                  }}
                />
                <SecondaryButton
                  onClick={() => {
                    void retrieveBranchName();
                  }}
                  sx={{
                    marginLeft: '16px',
                    minWidth: '138px',
                    fontSize: '14px',
                  }}
                >
                  部店名を自動で入力
                </SecondaryButton>
              </PrimaryTableBodyTdMulti>
              <PrimaryTableBodyTdMulti title="部店名" row={1} spWidth="130px">
                {branchName === '' ? (
                  <Typography
                    component="p"
                    variant="body-main/regular"
                    sx={{ color: 'system.text-light' }}
                  >
                    部店名が表示されます
                  </Typography>
                ) : (
                  <Typography component="p" variant="body-main/regular">
                    {branchName}
                  </Typography>
                )}
              </PrimaryTableBodyTdMulti>
              <PrimaryTableBodyTdMulti
                title="口座番号"
                subTitle="※半角数字7桁"
                row={1}
                spWidth="130px"
              >
                <TextInput
                  name="accountNumber"
                  value={accountNumber}
                  onChange={(event) => {
                    if (!event.target.value) {
                      setAccountNumber(event.target.value);
                    }
                    if (
                      IsNumeric(event.target.value) &&
                      event.target.value.length <= 7
                    ) {
                      setAccountNumber(event.target.value);
                    }
                  }}
                />
              </PrimaryTableBodyTdMulti>
            </PrimaryTable>
            <AccountOpeningTip />
          </>
        );
      } else {
        returnParam = (
          <BasicTable
            title="証券口座"
            type="securities"
            name="東海東京証券"
            departmentCode={securitiesAccount.branchCd ?? ''}
            departmentName={securitiesAccount.branchName ?? ''}
            accountNumber={securitiesAccount.accountNumber ?? ''}
          />
        );
      }
    }

    return returnParam;
  };

  useEffect(() => {
    setAssociation(ConvertAssociation(findAssociationById));
    setAssociationContribution(
      ConvertAssociationContribution(findAssociationContribution)
    );
    setEventsmemberdeadlines(
      ConvertEventsmemberdeadlines(
        findAssociationEventMemberDeadlines?.eventmemberdeadlines
      )
    );
    setUserContribution(ConvertUserContribution(findUserContribution));
  }, [
    findAssociationById,
    findAssociationContribution,
    findAssociationEventMemberDeadlines,
    findUserContribution,
  ]);

  useEffect(() => {
    setSecuritiesAccount(ConvertSecuritiesAccount(findSecuritiesAccount));
    setBranchCode(findSecuritiesAccount?.branchCd ?? '');
    setBranchName(findSecuritiesAccount?.branchName ?? '');
    setAccountNumber(findSecuritiesAccount?.accountNumber ?? '');
  }, [findSecuritiesAccount]);

  useEffect(() => {
    if (state) {
      setPayUnit(state.payUnit.toString());
      setBranchCode(state.branchCode);
      setBranchName(state.branchName);
      setAccountNumber(state.accountNumber);
    }
  }, []);

  if (
    association &&
    association.listedClassification === '1' &&
    associationContribution &&
    userContribution &&
    eventsmemberdeadlines &&
    userAsset
  ) {
    const isDisabled = () => {
      let returnParam = false;

      if (isListedClassification) {
        if (
          !payUnit ||
          Number(payUnit) === 0 ||
          branchCode.length < 3 ||
          accountNumber.length < 7 ||
          branchName === ''
        ) {
          returnParam = true;
        }
      } else if (!payUnit || Number(payUnit) === 0) {
        returnParam = true;
      }

      return returnParam;
    };

    return (
      <Wrraper>
        <HomeHeader
          links={links}
          title="一部を引き出す"
          description={description}
        />
        <TextWithFlowIcon flowOn={0} widthFull />
        <FAQ pageName="part">
          <ContentWrraper>
            <WarmingDeadLine
              eventsmemberdeadlines={eventsmemberdeadlines}
              target="PART"
            />
            <UserInfo
              mochikabukaiCode={user.associationCd ?? ''}
              mochikabukaiName={association.associationName ?? ''}
              nameKanji={user.nameKanji ?? ''}
              nameKana={user.nameKana ?? ''}
              zipcode={user.zipcode ?? ''}
              address1={user.address1 ?? ''}
              address2={user.address2 ?? ''}
              address3={user.address3 ?? ''}
              tel={user.tel ?? ''}
              employeeCd={user.employeeCd ?? ''}
              editable
            />
            <BasicTable
              boxSx={{ width: '100%' }}
              title="現在の積立状況"
              type="contribution"
              unitAmount={associationContribution.unitAmount}
              monthlyUnit={userContribution.monthlyUnit}
              bonusUnit={userContribution.bonusUnit}
              investmentDigit={association.investmentDigit}
            />
            {creatSecuritiesAccount()}
            {userAsset.stocksNumber && userAsset.stocksNumber > 0 && (
              <PrimaryTable title="引出株数">
                <PrimaryTableBodyTdMulti title="現在の保有株数" spWidth="130px">
                  <Typography variant="body-main/bold">
                    {userAsset.stocksNumber.toLocaleString(undefined, {
                      maximumFractionDigits: association.investmentDigit,
                    })}
                  </Typography>
                </PrimaryTableBodyTdMulti>
                <PrimaryTableBodyTdMulti title="引出株数" spWidth="130px">
                  <MuiBox>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TextInput
                        name="payUnit"
                        value={payUnit}
                        onChange={(event) => {
                          let amount = payUnit;
                          if (IsNumeric(event.target.value)) {
                            const userMaxAvailableUnits =
                              (userAsset.stocksNumber as number) / 100;
                            amount = Math.floor(
                              Math.min(
                                Number(event.target.value),
                                userMaxAvailableUnits
                              )
                            ).toString();
                          } else if (event.target.value === '') {
                            amount = undefined;
                          }
                          setPayUnit(amount || '');
                        }}
                        onKeyDown={(e) => {
                          if (e.code === 'Minus') e.preventDefault();
                        }}
                      />
                      <Typography
                        sx={{ marginLeft: '8px', marginRight: '30px' }}
                        variant="body-sub/regular"
                      >
                        00株
                      </Typography>
                    </Box>
                    <Typography
                      variant="body-sub/regular"
                      sx={{ color: 'system.text-light' }}
                    >
                      ※100株単位
                    </Typography>
                  </MuiBox>
                </PrimaryTableBodyTdMulti>
                <PrimaryTableBodyTdMulti title="残り株数" spWidth="130px">
                  <Typography
                    variant="body-main/bold"
                    sx={{ fontSize: '20px' }}
                  >
                    {payUnit
                      ? numberFormat(
                          userAsset.stocksNumber - Number(payUnit) * 100,
                          { maximumFractionDigits: association.investmentDigit }
                        )
                      : numberFormat(userAsset.stocksNumber)}
                  </Typography>
                </PrimaryTableBodyTdMulti>
              </PrimaryTable>
            )}
            <Warming />
            <PrimaryButton
              sx={{
                padding: '16px 40px',
                borderRadius: '28px',
                alignSelf: 'center',
              }}
              onClick={() => {
                if (validatePayUnit()) {
                  navigate(Path.PART_CONFIRM, {
                    state: {
                      user,
                      association,
                      associationContribution,
                      userContribution,
                      eventsmemberdeadlines,
                      findSecuritiesAccount,
                      stocksNumber: userAsset.stocksNumber || 0,
                      payUnit: String(Number(payUnit || 0)),
                      branchCode,
                      branchName,
                      accountNumber,
                    },
                  });
                }
              }}
              disabled={isDisabled()}
            >
              確認へ進む
            </PrimaryButton>
          </ContentWrraper>
        </FAQ>
      </Wrraper>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
