import { FC } from 'react';
import { Typography, styled, Grid } from '@mui/material';

interface SimulationHeaderTextProps {
  title: string;
  description: string;
  xs?: string;
  sm?: string;
}

const HeaderTitle = styled(Typography)(({ theme }) => {
  const { palette, typography } = theme;

  return {
    ...typography['body-sub/regular'],
    color: palette.system['text-light'],
  };
});

const HeaderContent = styled(Typography)(({ theme }) => {
  const { palette, typography } = theme;

  return {
    ...typography['body-main/bold'],
    color: palette.system['text-normal'],
    marginLeft: 16,
  };
});
const Wrapper = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 0,
});

export const SimulationHeaderText: FC<SimulationHeaderTextProps> = ({
  title,
  description,
  xs,
  sm,
}) => (
  <Wrapper
    item
    sx={{
      flexBasis: { xs, sm },
      maxWidth: { xs, sm },
    }}
  >
    <HeaderTitle>{title}</HeaderTitle>
    <HeaderContent>{description}</HeaderContent>
  </Wrapper>
);
