import { FC, useEffect, useState } from 'react';
import { useAuthUser } from '@/hooks/useAuth';
import { useLoader } from '@/hooks';
import { MyPageIndexOrganism } from '@/components/organism/MyPage/MyPageIndex';

import { appClient } from '@/services';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { useMyPageHooks } from './hooks/useMyPageHooks';

export const MyPageIndex: FC = () => {
  const [isApplyingUser, setIsApplyingUser] = useState(false);
  const [applyingUserData, setApplyingUserData] = useState({});
  const { user, lastAuthTime } = useAuthUser();
  const { handleLoaderOpen, handleLoaderClose } = useLoader();
  const {
    isLoading,
    userSecuritiesAccounts,
    association,
    userContribution,
    associationContribution,
  } = useMyPageHooks();
  useHelmetHandler({
    title: 'マイページ トップ',
  });

  if (isLoading) handleLoaderOpen();
  if (!isLoading) handleLoaderClose();

  const fetchApplyingUserData = async () => {
    /**
     * 直近5件の申請データから会員情報変更申請があるか判定
     */
    const res = await appClient.applications.listApplications(
      1,
      5,
      'APPLICATION_ID_DESC'
    );
    const foundApplication = res.applications?.find(
      (application) =>
        application.applicationType === 'USER' &&
        (application.applicationStatus === 'APPLYING' ||
          application.applicationStatus === 'OFFICER_APPROVED' ||
          application.applicationStatus === 'BACKOFFICER_APPROVED')
    );

    if (foundApplication) {
      setIsApplyingUser(true);

      if (foundApplication.applicationId) {
        const data = await appClient.applications.findApplicationById(
          foundApplication.applicationId
        );
        setApplyingUserData(data.applicationUser ?? {});
      }
    }
  };

  useEffect(() => {
    void fetchApplyingUserData();
  }, []);

  return (
    <MyPageIndexOrganism
      user={user}
      lastAuthTime={lastAuthTime}
      userSecuritiesAccounts={userSecuritiesAccounts}
      userContribution={userContribution}
      association={association}
      associationContribution={associationContribution}
      isApplyingUser={isApplyingUser}
      applyingUserData={applyingUserData}
    />
  );
};
