import { FC, useState, useEffect } from 'react';
import { ReactComponent as KeyboardArrowRightIcon } from '@/assets/keyboardArrowRightIcon.svg';
import {
  styled,
  Box,
  Typography,
  AppBar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as MinusIcon } from '@/assets/minusIcon.svg';
import { ReactComponent as PlusIcon } from '@/assets/plusIcon.svg';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { ReactComponent as MypageIcon } from '@/assets/mypageIcon.svg';
import { TextButton } from '@/components/atoms/Buttons/TextButton';
import { ReactComponent as CloseIcon } from '@/assets/closeIcon.svg';
import { beginners } from '@/constants/Header/common';
import logo from '@/assets/logo.png';
import { ReactComponent as MenuSpIcon } from '@/assets/menuSpIcon.svg';
import { ReactComponent as LogoutIcon } from '@/assets/logoutIcon.svg';
import { useAuthUser } from '@/hooks/useAuth';
import { Path } from '@/constants/Router/path';
import { useLogoutModal, useFindAssociationById } from '@/hooks';
import { useHomeHooks } from '@/components/pages/Home/hooks/useHomeHooks';
import useSWR from 'swr';
import { appClient } from '@/services';
import { ConvertAssociationContribution } from '@/services/convert/associationContribution';
import { AssociationContribution } from '@/types/api/associationContribution';
import { useUserApplicationStatus } from '@/hooks/useUserApplicationStatus';

const drawerWidth = 311;
interface DrowerProps {
  window?: () => Window;
}

const Img = styled('img')({
  width: '125px',
  cursor: 'pointer',
});

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  borderBottom: `1px solid ${theme.palette.system['separator-light']}`,
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<KeyboardArrowRightIcon />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.system.white,
  flexDirection: 'row',
  padding: '16px',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-content': {
    padding: '0',
    margin: '0',
  },
}));

const AccordionSummaryClose = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<PlusIcon />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.system.white,
  flexDirection: 'row',
  padding: '16px',
  '& .MuiAccordionSummary-content': {
    padding: '0',
    margin: '0',
  },
}));

const AccordionSummaryOpen = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<MinusIcon />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.system.white,
  flexDirection: 'row',
  padding: '16px',
  color: theme.palette.system['text-light'],
  '& .MuiAccordionSummary-content': {
    padding: '0',
    margin: '0',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  background: theme.palette.system.background,
  padding: '0',
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  borderTop: `1px solid ${theme.palette.system['separator-light']}`,
}));

const MuiTitleTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography['body-sub/medium'],
}));

const MuiList = styled(List)({
  justifyContent: 'space-between',
  padding: '0',
});

const MuiListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  borderBottom: `1px solid ${theme.palette.system['separator-light']}`,
  '&:last-child': {
    border: 0,
  },
  '&:disabled': {
    opacity: 0.2,
  },
}));

const MuiListItemButton = styled(ListItemButton)({
  padding: '16px 16px 16px 24px',
  justifyContent: 'space-between',
  '&:hover': {
    background: 'none',
  },
});

const MuiListItemText = styled(ListItemText)(({ theme }) => ({
  margin: '0',
  span: {
    ...theme.typography['body-sub/medium'],
    color: theme.palette.system['text-normal'],
  },
}));

const MuiListItemIcon = styled(ListItemIcon)({
  minWidth: 'initial',
});

const ButtonArea = styled(Box)({
  width: '100%',
  padding: '30px 16px 16px 16px',
});

const StyledCloseIcon = styled(CloseIcon)({
  opacity: 0,
  color: '#fff',
  animation: 'fadein 2s ease forwards',
  '@keyframes fadein': {
    '10%': { opacity: 0.5 },
    '100%': { opacity: 1 },
  },
});

export const HeaderSp: FC = (drowerProps: DrowerProps) => {
  const navigate = useNavigate();
  const { user, isPersonalInformationEntered } = useAuthUser();
  const { handleLogoutModalOpen } = useLogoutModal();
  const { associationData } = useFindAssociationById(user.associationCd ?? '');
  const { isListedClassification } = useHomeHooks();
  // eslint-disable-next-line react/destructuring-assignment
  const { window } = drowerProps;
  const [mobileOpen, setMobileOpen] = useState(false);

  const {
    notOperatePay,
    notOperatePart,
    notOperateRecess,
    notOperateWithdrawal,
  } = useUserApplicationStatus();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [expanded, setExpanded] = useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      event.preventDefault();
      setExpanded(newExpanded ? panel : false);
    };

  const handleNavigate = (path: string) => {
    navigate(path, { replace: true });
    setMobileOpen(false);
  };

  const [associationContribution, setAssociationContribution] =
    useState<AssociationContribution>();

  // 【API-A-1002】持株会（事務局）拠出情報取得
  const { data: findAssociationContribution } = useSWR(
    '/api/associations/{associationCd}/contributions',
    () => appClient.associations.findAssociationContribution()
  );

  useEffect(() => {
    setAssociationContribution(
      ConvertAssociationContribution(findAssociationContribution)
    );
  }, [findAssociationContribution]);

  const isNotOperatePay =
    notOperatePay ||
    associationContribution?.monthlyUnitClassification !== '1' ||
    !(
      user.userStatus === 'NORMAL' ||
      user.userStatus === 'BEING_RESUMEMENT_PROCESSED'
    );
  const isNotOperatePart =
    !isListedClassification ||
    notOperatePart ||
    !(
      user.userStatus === 'NORMAL' ||
      user.userStatus === 'BEING_RECESSMENT_PROCESSED' ||
      user.userStatus === 'RECESS' ||
      user.userStatus === 'BEING_RESUMEMENT_PROCESSED'
    );
  const isNotOpreateRecess =
    notOperateRecess || !(user.userStatus === 'NORMAL');
  const isNotOpreateWithdrawal =
    notOperateWithdrawal ||
    !(user.userStatus === 'NORMAL' || user.userStatus === 'RECESS');

  const isNotBeingEnrolled = user.userStatus !== 'BEING_ENROLLMENT_PROCESSED';

  const drawer = (
    <div>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        style={{ paddingTop: '16px' }}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          onClick={() => handleNavigate(Path.HOME)}
        >
          <MuiTitleTypography>TOP</MuiTitleTypography>
        </AccordionSummary>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        {expanded === 'panel2' ? (
          <AccordionSummaryOpen
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <MuiTitleTypography>初めての方へ</MuiTitleTypography>
          </AccordionSummaryOpen>
        ) : (
          <AccordionSummaryClose
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <MuiTitleTypography>初めての方へ</MuiTitleTypography>
          </AccordionSummaryClose>
        )}
        <AccordionDetails>
          <MuiList>
            {beginners.map((item) => (
              <MuiListItem key={item.key}>
                <MuiListItemButton onClick={() => handleNavigate(item.to)}>
                  <MuiListItemText primary={item.value} />
                  <MuiListItemIcon>
                    <KeyboardArrowRightIcon />
                  </MuiListItemIcon>
                </MuiListItemButton>
              </MuiListItem>
            ))}
          </MuiList>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
      >
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          onClick={() => handleNavigate(Path.APPLICATIONS)}
        >
          <MuiTitleTypography>申請履歴</MuiTitleTypography>
        </AccordionSummary>
      </Accordion>
      {isNotBeingEnrolled && (
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            aria-controls="panel4d-content"
            id="panel4d-header"
            onClick={() => handleNavigate(Path.ASSETS)}
          >
            <MuiTitleTypography>資産状況</MuiTitleTypography>
          </AccordionSummary>
        </Accordion>
      )}
      <Accordion
        expanded={expanded === 'panel5'}
        onChange={handleChange('panel5')}
      >
        {expanded === 'panel5' ? (
          <AccordionSummaryOpen
            aria-controls="panel5d-content"
            id="panel5d-header"
          >
            <MuiTitleTypography>各種お手続き</MuiTitleTypography>
          </AccordionSummaryOpen>
        ) : (
          <AccordionSummaryClose
            aria-controls="panel5d-content"
            id="panel5d-header"
          >
            <MuiTitleTypography>各種お手続き</MuiTitleTypography>
          </AccordionSummaryClose>
        )}
        <AccordionDetails>
          <MuiList>
            <MuiListItem>
              <MuiListItemButton
                onClick={() => handleNavigate(isNotOperatePay ? '#' : Path.PAY)}
                sx={{
                  opacity: isNotOperatePay ? 0.5 : 1,
                  pointerEvents: isNotOperatePay ? 'none' : undefined,
                }}
              >
                <MuiListItemText primary="口数を変更する" />
                <MuiListItemIcon>
                  <KeyboardArrowRightIcon />
                </MuiListItemIcon>
              </MuiListItemButton>
            </MuiListItem>
            <MuiListItem>
              <MuiListItemButton
                onClick={() =>
                  handleNavigate(isNotOperatePart ? '#' : Path.PART)
                }
                sx={{
                  opacity: isNotOperatePart ? 0.5 : 1,
                  pointerEvents: isNotOperatePart ? 'none' : undefined,
                }}
              >
                <MuiListItemText primary="一部を引き出す" />
                <MuiListItemIcon>
                  <KeyboardArrowRightIcon />
                </MuiListItemIcon>
              </MuiListItemButton>
            </MuiListItem>
            {/* eslint-disable-next-line no-nested-ternary */}
            {user.userStatus === 'RECESS' ? (
              <MuiListItem>
                <MuiListItemButton
                  onClick={() => handleNavigate(Path.RESUME)}
                  sx={{
                    opacity: 1,
                    pointerEvents: undefined,
                  }}
                >
                  <MuiListItemText primary="再開する" />
                  <MuiListItemIcon>
                    <KeyboardArrowRightIcon />
                  </MuiListItemIcon>
                </MuiListItemButton>
              </MuiListItem>
            ) : (
              <MuiListItem>
                <MuiListItemButton
                  onClick={() =>
                    handleNavigate(isNotOpreateRecess ? '#' : Path.RECESS)
                  }
                  sx={{
                    opacity: isNotOpreateRecess ? 0.5 : 1,
                    pointerEvents: isNotOpreateRecess ? 'none' : undefined,
                  }}
                >
                  <MuiListItemText primary="休止する" />
                  <MuiListItemIcon>
                    <KeyboardArrowRightIcon />
                  </MuiListItemIcon>
                </MuiListItemButton>
              </MuiListItem>
            )}
            <MuiListItem>
              <MuiListItemButton
                onClick={() =>
                  handleNavigate(isNotOpreateWithdrawal ? '#' : Path.WITHDRAWAL)
                }
                sx={{
                  opacity: isNotOpreateWithdrawal ? 0.5 : 1,
                  pointerEvents: isNotOpreateWithdrawal ? 'none' : undefined,
                }}
              >
                <MuiListItemText primary="退会する" />
                <MuiListItemIcon>
                  <KeyboardArrowRightIcon />
                </MuiListItemIcon>
              </MuiListItemButton>
            </MuiListItem>
          </MuiList>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel6'}
        onChange={handleChange('panel6')}
      >
        {/* TODO:一旦電子交付サービスボタンを無効化し遷移できないように */}
        <AccordionSummary
          aria-controls="panel6d-content"
          id="panel6d-header"
          onClick={() => handleNavigate(Path.REPORT)}
        >
          <MuiTitleTypography>電子交付サービス</MuiTitleTypography>
        </AccordionSummary>
        <AccordionSummary
          aria-controls="panel6d-content"
          id="panel6d-header"
          sx={{ opacity: 0.5, pointerEvents: 'none' }}
        >
          <MuiTitleTypography>電子交付サービス</MuiTitleTypography>
        </AccordionSummary>
      </Accordion>
      {associationData?.listedClassification === '1' &&
        !!associationData.allowSimulationFlg && (
          <Accordion
            expanded={expanded === 'panel7'}
            onChange={handleChange('panel7')}
          >
            <AccordionSummary
              aria-controls="panel7d-content"
              id="panel7d-header"
              onClick={() => handleNavigate(Path.SIMULATION)}
            >
              <MuiTitleTypography>積立シミュレーション</MuiTitleTypography>
            </AccordionSummary>
          </Accordion>
        )}
      <Accordion
        expanded={expanded === 'panel8'}
        onChange={handleChange('panel8')}
      >
        <AccordionSummary
          aria-controls="panel8d-content"
          id="panel8d-header"
          onClick={() => handleNavigate(Path.SERVICE)}
        >
          <MuiTitleTypography>サービス</MuiTitleTypography>
        </AccordionSummary>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel9'}
        onChange={handleChange('panel9')}
      >
        <AccordionSummary
          aria-controls="panel9d-content"
          id="panel9d-header"
          onClick={() => handleNavigate(Path.FAQ)}
        >
          <MuiTitleTypography>よくあるご質問</MuiTitleTypography>
        </AccordionSummary>
      </Accordion>
      <ButtonArea>
        {isPersonalInformationEntered && (
          <SecondaryButton
            sx={{ width: '100%' }}
            onClick={() => handleNavigate(Path.MYPAGE)}
          >
            <MypageIcon />
            マイページ
          </SecondaryButton>
        )}
        <TextButton
          sx={{ width: '100%', marginTop: '16px' }}
          onClick={handleLogoutModalOpen}
        >
          <LogoutIcon />
          ログアウト
        </TextButton>
      </ButtonArea>
    </div>
  );

  const MuiAppBar = styled(AppBar)(({ theme }) => {
    const { palette } = theme;
    return {
      background: palette.system.main,
      boxShadow: 'none',
    };
  });

  const MuiToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: 'initial',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '16px',
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    borderBottom: `1px solid ${theme.palette.system['separator-light']}`,
  }));

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      {mobileOpen && (
        <TextButton
          onClick={handleDrawerToggle}
          sx={{
            position: 'absolute',
            top: 22,
            left: 0,
            zIndex: 1201,
          }}
        >
          <StyledCloseIcon />
        </TextButton>
      )}
      <MuiAppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <MuiToolbar>
          <Img src={logo} alt="Logo" onClick={() => navigate(Path.HOME)} />
          {/* <SpLogo onClick={() => navigate(Path.HOME)} /> */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuSpIcon />
          </IconButton>
        </MuiToolbar>
      </MuiAppBar>

      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          anchor="right"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { md: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      {/* <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
      </Box> */}
    </>
  );
};
