import React, { FC } from 'react';
import { AccumulationField } from '@/components/molecules/Simulation/AccumulationField';
import { Box, styled } from '@mui/material';

interface AccumulationRowProps {
  error: boolean;
  monthly: number;
  bonus: number;
  onChangeMonthlyAmount: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeBonusAmount: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Wrapper = styled(Box)(({ theme }) => {
  const { breakpoints } = theme;

  return {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: 4,

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      margin: '4px auto 0',
    },
  };
});

export const AccumulationRow: FC<AccumulationRowProps> = ({
  error,
  monthly,
  bonus,
  onChangeMonthlyAmount,
  onChangeBonusAmount,
}) => (
  <Wrapper>
    <AccumulationField
      title="毎月の積立額"
      value={monthly / 1000}
      onChange={onChangeMonthlyAmount}
      disabled={error}
    />
    <AccumulationField
      title="ボーナス時の積立額"
      value={bonus / 1000}
      onChange={onChangeBonusAmount}
      disabled={error}
    />
  </Wrapper>
);
