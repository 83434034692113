/* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/restrict-template-expressions */
import { Box, styled, Typography } from '@mui/material';
import { FC } from 'react';

import {
  SignUpUserConfirmProps as Props,
  SignUpUserConfirmContainerProps as ContainerProps,
  SignUpUserConfirmLabelAndTextProps as LATProps,
} from '@/types/components/SignUpUserConfirm';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { useMediaQuery } from '@/hooks';
import { theme } from '@/theme/theme';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { SignUpUpper } from './SignUpUpper';

const Title: FC = () => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '16px',
      mt: '8px',
    }}
  >
    <Typography variant="h3" sx={{ color: 'system.text-normal' }}>
      会員情報入力内容確認
    </Typography>
    <Typography
      variant="body-main/regular"
      sx={{ color: 'system.text-normal' }}
    >
      入力内容を確認してください。
    </Typography>
  </Box>
);

const Category: FC<Props> = ({ children }) => (
  <Typography
    variant="h5"
    sx={{
      color: 'system/text-normal',
      width: '100%',
      borderBottom: '1px solid red',
      pb: '12px',
      mt: '24px',
    }}
  >
    {children}
  </Typography>
);

const Label: FC<Props> = ({ children }) => (
  <Typography
    component="span"
    variant="body-main/bold"
    sx={{
      color: 'system.text-normal',
      width: '144px',
    }}
  >
    {children}
  </Typography>
);

const Text: FC<Props> = ({ children }) => (
  <Typography
    component="p"
    variant="body-main/regular"
    sx={{ color: 'system/text-normal' }}
  >
    {children}
  </Typography>
);

const LabelAndText: FC<LATProps> = ({ label, text }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      gap: '8px',
    }}
  >
    <Label>{label}</Label>
    <Box>
      {text.map((str) => (
        <Text key={str}>{str}</Text>
      ))}
    </Box>
  </Box>
);

const PrimarySecondary: FC<Props & { buttonType: 'submit' | 'button' }> = ({
  isPc,
  buttonType = 'submit',
}) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: isPc ? '320px' : '100%',
        display: 'flex',
        flexDirection: isPc ? 'row-reverse' : 'column',
        alignItems: isPc ? 'center' : 'flex-start',
        p: '0px',
        mt: '24px',
        gap: '16px',
      }}
    >
      <PrimaryButton
        type={buttonType}
        sx={{
          width: isPc ? '184px' : '100%',
          height: isPc ? '40px' : '56px',
          padding: isPc ? '8px 20px' : '20px, 24px, 20px, 26px',
          borderRadius: isPc ? '20px' : '28px',
        }}
      >
        会員情報を登録する
      </PrimaryButton>
      <SecondaryButton
        sx={{
          width: isPc ? '120px' : '100%',
          height: isPc ? '40px' : '56px',
          padding: isPc ? '8px 20px' : '20px, 24px, 20px, 26px',
          borderRadius: isPc ? '20px' : '28px',
        }}
        onClick={() => navigate('/signup/user')}
      >
        入力に戻る
      </SecondaryButton>
    </Box>
  );
};

const Container: FC<ContainerProps> = ({ children, underline = false }) => (
  <Box
    sx={{
      width: '100%',
      pb: underline ? '16px' : '0',
      borderBottom: underline ? '1px solid' : '0',
      borderColor: 'system.separator-light',
    }}
  >
    {children}
  </Box>
);
type AssociationType = {
  association: {
    associationCd: string;
    associationName: string;
  };
};

export const SignUpUserConfirmContentOrganism: FC<Props & AssociationType> = ({
  values,
  association,
}) => {
  const { isPc } = useMediaQuery();
  // @ts-ignore
  const { nameKanji, nameKanji2, nameKana, nameKana2, year, month, day, zip } =
    values;
  // @ts-ignore
  const { address1, address2, address3, employeeCode } = values;

  const InsiderText = styled(Typography)(() => ({
    ...theme.typography['body-sub/regular'],
    color: theme.palette.system['text-light'],
    marginTop: 24,
  }));

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: isPc ? '32px' : '32px 16px',
        gap: '16px',
        backgroundColor: 'system.white',
        borderRadius: isPc ? '0 0 12px 12px' : '0',
      }}
    >
      <Title />
      <Category>持株会情報</Category>
      <Container underline>
        <LabelAndText label="持株会コード" text={[association.associationCd]} />
      </Container>
      <Container underline>
        <LabelAndText label="持株会名" text={[association.associationName]} />
      </Container>
      <Category>会員情報</Category>
      <Container underline>
        <LabelAndText
          label="氏名（漢字）"
          text={[`${nameKanji} ${nameKanji2}`]}
        />
      </Container>
      <Container underline>
        <LabelAndText
          label="氏名（カナ）"
          text={[`${nameKana} ${nameKana2}`]}
        />
      </Container>
      <Container underline>
        <LabelAndText
          label="生年月日"
          text={[`${year}年 ${Number(month)}月 ${Number(day)}日`]}
        />
      </Container>
      <Container underline>
        <LabelAndText
          label="住所"
          text={[`〒${zip}`, `${address1}${address2}${address3}`]}
        />
      </Container>
      <Container underline>
        <LabelAndText
          label="電話番号"
          text={[
            values?.telType === 'MOBILE' ? '携帯' : '自宅',
            `${values?.tel}`,
          ]}
        />
      </Container>
      <Container underline>
        <LabelAndText label="社員コード" text={[`${employeeCode}`]} />
      </Container>
      <Category>Web利用規約同意確認</Category>
      <Container underline>
        <Typography
          variant="body-main/regular"
          sx={{ color: 'system.text-normal' }}
        >
          Web利用規約に同意する
        </Typography>
      </Container>
      <InsiderText>
        本申請はインサイダー情報（未公表の重要事実）を知りながら行うものではありません。
      </InsiderText>
      <PrimarySecondary isPc={isPc} buttonType="submit" />
    </Box>
  );
};

export const SignUpUserConfirmOrganism: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  useHelmetHandler({
    title: '持株会入会登録_会員情報確認',
  });

  return (
    <Box
      sx={{
        bgcolor: 'system.background-dark',
        padding: {
          xs: '0 0 60px',
          sm: '0 40px 60px',
          md: '0 40px 60px',
          lg: '0 130px 60px',
        },
      }}
    >
      <Box sx={{ maxWidth: '780px', margin: '0 auto' }}>
        <SignUpUpper isSignUp flowOn={2} />
        {children}
      </Box>
    </Box>
  );
};
