import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { BREAKPOINT } from '@/theme/theme';
import {
  Box,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  Stack,
} from '@mui/material';
import { FC } from 'react';

const styleContainer: SxProps<Theme> = [
  {
    width: '343px',
    height: '268px',
    textAlign: 'center',
    p: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
  },
  (theme) => ({
    [theme.breakpoints.up(BREAKPOINT)]: {
      width: '520px',
      height: '196px',
      p: '32px',
    },
  }),
];

interface LogoutProps {
  handleClickCancel: () => void;
  handleClickLogout: () => void;
}

export const Logout: FC<LogoutProps> = ({
  handleClickCancel,
  handleClickLogout,
}) => {
  const isPc = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(BREAKPOINT)
  );
  const primaryWidth = isPc ? '152px' : '295px';
  const secondaryWidth = isPc ? '120px' : '295px';
  const height = isPc ? '40px' : '56px';
  const borderRadius = isPc ? '20px' : '28px';
  const primarySx = { width: primaryWidth, height, borderRadius };
  const secondarySx = { width: secondaryWidth, height, borderRadius };

  return (
    <Box sx={styleContainer}>
      <Typography variant="h5" sx={{ color: 'primary.normal' }}>
        ログアウト
      </Typography>
      <Typography
        component="p"
        variant="body-main/regular"
        sx={{ color: 'system.text-normal' }}
      >
        ログアウトしますか？
      </Typography>
      {isPc ? (
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <SecondaryButton sx={secondarySx} onClick={handleClickCancel}>
            キャンセル
          </SecondaryButton>
          <PrimaryButton sx={primarySx} onClick={handleClickLogout}>
            ログアウトする
          </PrimaryButton>
        </Box>
      ) : (
        <Stack gap="16px">
          <SecondaryButton sx={secondarySx} onClick={handleClickCancel}>
            キャンセル
          </SecondaryButton>
          <PrimaryButton sx={primarySx} onClick={handleClickLogout}>
            ログアウトする
          </PrimaryButton>
        </Stack>
      )}
    </Box>
  );
};
