/* eslint-disable @typescript-eslint/no-unsafe-call */
import { FC, Suspense, useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { useLoader } from '@/hooks';
import { formatDateTime } from '@/utils/dateFunctions';
import { AssetsStatus } from '@/components/organism/Home/AssetsStatus';
import { Guide } from '@/components/organism/Home/Guide';
import { Questions } from '@/components/organism/Home/Questions';
import { Services } from '@/components/organism/Home/Services';
import { ProceduresFeature } from '@/features/Home/ProceduresFeature';
import { RequestStatusFeature } from '@/features/Home/RequestStatusFeature';
import { WelcomeFeature } from '@/features/Home/WelcomeFeature';
import { useAuthUser } from '@/hooks/useAuth';
import { ConvertEventsmemberdeadlines } from '@/services/convert/eventsmemberdeadlines';
import { Eventsmemberdeadlines } from '@/types/api/eventsmemberdeadlines';
import useSWR from 'swr';
import { appClient } from '@/services';
import { FindAssociationEventMemberDeadlines } from '@/types/api/association';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { useHomeHooks } from './hooks/useHomeHooks';

const BREAKPOINT = 'sm';

const WelcomeWrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    padding: '32px 40px 40px',
    width: '100%',
    [breakpoints.down(BREAKPOINT)]: {
      padding: '24px 16px',
    },
    [breakpoints.up('lg')]: {
      padding: '32px 130px 40px',
    },
  };
});

const AssetsStatusWrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    padding: '40px',
    width: '100%',
    [breakpoints.down(BREAKPOINT)]: {
      padding: '24px 16px',
    },
    [breakpoints.up('lg')]: {
      padding: '40px 130px',
    },
  };
});

const ProceduresWrapper = styled('div')(({ theme }) => {
  const { breakpoints, palette } = theme;
  return {
    padding: '40px',
    width: '100%',
    backgroundColor: palette.system.background,
    [breakpoints.down(BREAKPOINT)]: {
      padding: '24px 16px',
    },
    [breakpoints.up('lg')]: {
      padding: '40px 130px',
    },
  };
});

const GuideWrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    padding: '80px 40px 32px',
    width: '100%',
    [breakpoints.down(BREAKPOINT)]: {
      padding: '40px 16px',
    },
    [breakpoints.up('lg')]: {
      padding: '80px 130px 32px',
    },
  };
});

const QuestionsWrapper = styled('div')(({ theme }) => {
  const { breakpoints, palette } = theme;
  return {
    padding: '60px 40px',
    width: '100%',
    backgroundColor: palette.system.background,
    [breakpoints.down(BREAKPOINT)]: {
      padding: '24px 16px',
    },
    [breakpoints.up('lg')]: {
      padding: '60px 130px',
    },
  };
});

const ServicesWrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    padding: '40px 40px 80px',
    width: '100%',
    [breakpoints.down(BREAKPOINT)]: {
      padding: '24x 16px',
    },
    [breakpoints.up('lg')]: {
      padding: '40px 130px 40px',
    },
  };
});

export const Home: FC = () => {
  const { updateUser } = useAuthUser();
  const today = new Date().toLocaleString();
  const formatedDate = formatDateTime(today);
  useHelmetHandler({
    title: 'トップ',
  });

  const { loaderOpen, handleLoaderOpen, handleLoaderClose } = useLoader();
  const [eventsmemberdeadlines, setEventsmemberdeadlines] =
    useState<Eventsmemberdeadlines>();

  // 【API-A-1001】持株会（事務局）情報
  const {
    data: findAssociationEventMemberDeadlines,
    error: findAssociationEventMemberDeadlinesError,
  } = useSWR<FindAssociationEventMemberDeadlines, Error>(
    '/api/associations/{associationCd}/eventsmemberdeadlines',
    () => appClient.associations.findAssociationEventMemberDeadlines()
  );

  const {
    // state関連
    isListedClassification,
    userSecuritiesAccounts,
    // loading関連
    // isLoadingUserAsset,
    isAssociationLoading,
    // api取得関連
    userAsset,
    applications,
    associationData,
  } = useHomeHooks();

  // 取れないデータがあった時何度も表示するのでコメントアウト
  // if (isLoadingUserAsset && !loaderOpen) handleLoaderOpen();
  // if (!isLoadingUserAsset && loaderOpen) handleLoaderClose();

  useEffect(() => {
    if (findAssociationEventMemberDeadlines) {
      setEventsmemberdeadlines(
        ConvertEventsmemberdeadlines(
          findAssociationEventMemberDeadlines?.eventmemberdeadlines
        )
      );
    }
    if (findAssociationEventMemberDeadlinesError) {
      // user stateを更新してsession切れか確認する
      updateUser().finally(() => {});
    }
  }, [
    findAssociationEventMemberDeadlines,
    findAssociationEventMemberDeadlinesError,
  ]);

  const getNewApplications = () => {
    let counter = 0;
    const filteredApplications = applications?.applications
      ?.sort((a, b) =>
        (a?.applicationDate || '') > (b?.applicationDate || '') ? -1 : 1
      )
      .filter((application) => {
        if (application.applicationStatus !== 'COMPLETED' && counter < 3) {
          counter += 1;
          return application;
        }
        return null;
      });
    return filteredApplications || null;
  };

  if (eventsmemberdeadlines) {
    if (eventsmemberdeadlines && loaderOpen) handleLoaderClose();
    return (
      <>
        <WelcomeWrapper>
          <WelcomeFeature
            isAssociationLoading={isAssociationLoading}
            isListedClassification={isListedClassification}
            userSecuritiesAccounts={userSecuritiesAccounts}
            associationCd={associationData?.associationCd ?? ''}
          />
        </WelcomeWrapper>

        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <RequestStatusFeature applications={getNewApplications()} />

        <AssetsStatusWrapper>
          <Suspense fallback={<p>Loading...</p>}>
            {userAsset && (
              <AssetsStatus
                date={formatedDate}
                asset={userAsset}
                investmentDigit={associationData?.investmentDigit || 0}
              />
            )}
          </Suspense>
        </AssetsStatusWrapper>
        <ProceduresWrapper>
          <ProceduresFeature eventsmemberdeadlines={eventsmemberdeadlines} />
        </ProceduresWrapper>
        <GuideWrapper>
          <Guide />
        </GuideWrapper>
        <QuestionsWrapper>
          <Questions />
        </QuestionsWrapper>
        <ServicesWrapper>
          <Services />
        </ServicesWrapper>
      </>
    );
  }
  if (!eventsmemberdeadlines && !loaderOpen) handleLoaderOpen();
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
