import React, { ChangeEvent, FC } from 'react';
import { Box, Typography, TextField, styled } from '@mui/material';
import { theme } from '@/theme/theme';
import { IsNumeric } from '@/utils/stringProcessor';

interface AccumulationFieldProps {
  title: string;
  value: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const Wrapper = styled(Box)(() => {
  const { breakpoints } = theme;
  return {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#EFFBFC',
    borderRadius: 8,
    overflow: 'hidden',

    '&:first-of-type': {
      marginRight: 4,

      [breakpoints.down('sm')]: {
        marginRight: 0,
      },
    },

    '&:last-child': {
      [breakpoints.down('sm')]: {
        marginTop: 4,
      },
    },

    [breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
  };
});

const TitleWrapper = styled(Box)(() => {
  const { palette, breakpoints } = theme;

  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: palette.secondary.normal,

    [breakpoints.down('sm')]: {
      width: 96,
    },
  };
});

const Title = styled(Typography)(() => {
  const { palette, typography, breakpoints } = theme;

  return {
    ...typography.h5,
    fontWeight: 700,
    color: palette.system.white,
    padding: '12px 0',
    textAlign: 'center',

    [breakpoints.down('sm')]: {
      ...typography['body-sub/bold'],
      lineHeight: '20px',
    },
  };
});

const FieldWrapper = styled(Box)(() => {
  const { breakpoints } = theme;

  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '26px 0',

    [breakpoints.down('sm')]: {
      padding: '16px',
    },
  };
});

const FieldInput = styled(TextField)({
  ...theme.typography['body-main/medium'],
  color: theme.palette.system['text-normal'],
  width: 80,

  '& .Mui-disabled': {
    opacity: 0.4,
  },
});

const FieldDecoration = styled(Typography)(() => {
  const { typography, palette } = theme;
  return {
    ...typography['body-main/medium'],
    fontSize: 20,
    color: palette.system['text-normal'],
    marginLeft: 8,
  };
});

export const AccumulationField: FC<AccumulationFieldProps> = ({
  title,
  value,
  onChange,
  disabled,
}) => (
  <Wrapper>
    <TitleWrapper>
      <Title>{title}</Title>
    </TitleWrapper>
    <FieldWrapper>
      <FieldInput
        value={value}
        disabled={disabled}
        onChange={(e) => {
          if (IsNumeric(e.target.value) || e.target.value === '')
            onChange(e as ChangeEvent<HTMLInputElement>);
        }}
        InputProps={{
          sx: {
            input: {
              ...theme.typography['body-main/bold'],
              fontSize: 20,
              color: theme.palette.system['text-normal'],
              lineHeight: '20px',
              height: '20px',
              textAlign: 'right',
            },
            'input[type=number]::-webkit-inner-spin-button': {
              WebkitAppearance: 'none',
            },
            'input[type=number]::-webkit-outer-spin-button': {
              WebkitAppearance: 'none',
            },
          },
        }}
        // type="number"
      />
      <FieldDecoration>,000円</FieldDecoration>
    </FieldWrapper>
  </Wrapper>
);
