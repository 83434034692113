import { HelmetDescriptionState, HelmetTitleState } from '@/recoil/atom/helmet';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

export type HelmetHandlerParams = {
  title: string;
  description?: string;
};

export const useHelmetHandler = (params: HelmetHandlerParams) => {
  const [, setMetaTitle] = useRecoilState(HelmetTitleState);
  const [, setMetaDescription] = useRecoilState(HelmetDescriptionState);

  useEffect(() => {
    const { title, description } = params;
    setMetaTitle(title);
    if (description) setMetaDescription(description);
  }, [params, setMetaTitle, setMetaDescription]);

  return null;
};
