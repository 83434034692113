import type { Association } from '@/types/api/association';
import type { UserContribution } from '@/types/api/userContribution';

export const associationModel = (data: {
  associationCd?: string;
  associationName?: string;
  stockCode?: string;
  associationType?: number;
  associationRepresentative?: string;
  associationTel?: string;
  associationEmail?: string;
  associationZipcode?: string;
  associationAddressCd?: string;
  deploymentAddress1?: string;
  deploymentAddress2?: string;
  supplementAddress1?: string;
  supplementAddress2?: string;
  investmentDigit?: number;
  dividendProcessingClassification?: string;
  withdrawalSettlementDigit?: number;
  withdrawalAllSettlementUsageClassification?: string;
  withdrawalLessThanSettlementUsageClassification?: string;
  withdrawalAddPurchaseUsageClassification?: string;
  associationStatus?: string;
  dissolutionYmd?: string;
  openedFlg?: boolean;
  listedClassification?: string;
  stockOfficialNameKanji?: string;
  settlementCnt?: number;
  settlementMonth?: number;
}) =>
  ({
    associationCd: data.associationCd || '',
    associationName: data.associationName || '',
    stockCode: data.stockCode || '',
    associationType: data.associationType || 0,
    associationRepresentative: data.associationRepresentative || '',
    associationTel: data.associationTel || '',
    associationEmail: data.associationEmail || '',
    associationZipcode: data.associationZipcode || '',
    associationAddressCd: data.associationAddressCd || '',
    deploymentAddress1: data.deploymentAddress1 || '',
    deploymentAddress2: data.deploymentAddress2 || '',
    supplementAddress1: data.supplementAddress1 || '',
    supplementAddress2: data.supplementAddress2 || '',
    investmentDigit: data.investmentDigit || 0,
    dividendProcessingClassification:
      data.dividendProcessingClassification || '',
    withdrawalSettlementDigit: data.withdrawalSettlementDigit || 0,
    withdrawalAllSettlementUsageClassification:
      data.withdrawalAllSettlementUsageClassification || '',
    withdrawalLessThanSettlementUsageClassification:
      data.withdrawalLessThanSettlementUsageClassification || '',
    withdrawalAddPurchaseUsageClassification:
      data.withdrawalAddPurchaseUsageClassification || '',
    associationStatus: data.associationStatus || '',
    dissolutionYmd: data.dissolutionYmd || '',
    openedFlg: data.openedFlg || false,
    listedClassification: data.listedClassification || '',
    stockOfficialNameKanji: data.stockOfficialNameKanji || '',
    settlementCnt: data.settlementCnt || 0,
    settlementMonth: data.settlementMonth || 0,
  } as Association);

export type UserSecuritiesAccounts = {
  /** @description 部店コード */
  branchCode: string;
  /** @description 部店名 */
  branchName: string;
  /** @description 口座番号 */
  accountNumber: string;
  /** @description 確認 */
  fixedFlg: boolean;
};

/**
 * userSecuritiesAccountsModel
 * @param data
 */
export const userSecuritiesAccountsModel = (data: {
  branchCd?: string;
  branchName?: string;
  accountNumber?: string;
}) =>
  ({
    branchCode: data.branchCd || '',
    branchName: data.branchName || '',
    accountNumber: data.accountNumber || '',
  } as UserSecuritiesAccounts);

export const userContributionModel = (data: {
  /**
   * 持株会コード
   */
  associationCd?: string;
  /**
   * 会員コード
   */
  memberCd?: string;
  /**
   * 拠出開始年月日
   */
  contributionStartDate?: string;
  /**
   * 月例拠出口数
   */
  monthlyUnit?: number;
  /**
   * 月例休止開始年月日
   */
  monthlyRecessStartDate?: string;
  /**
   * 月例休止終了年月日
   */
  monthlyRecessEndDate?: string;
  /**
   * 賞与拠出口数
   */
  bonusUnit?: number;
  /**
   * 賞与拠出休止区分
   */
  bonusRecessClassification?: string;
}) =>
  ({
    associationCd: data.associationCd || '',
    memberCd: data.memberCd || '',
    contributionStartDate: data.contributionStartDate || '',
    monthlyUnit: data.monthlyUnit || 0,
    monthlyRecessStartDate: data.monthlyRecessStartDate || '',
    monthlyRecessEndDate: data.monthlyRecessEndDate || '',
    bonusUnit: data.bonusUnit || 0,
    bonusRecessClassification: data.bonusRecessClassification || '',
  } as UserContribution);
