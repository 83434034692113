import { styled } from '@mui/material';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { FooterTextWithPageTop } from '../molecules/Footer/FooterTextWithPageTop';

import { LoginHeader } from '../organism/LoginHeader';

const Main = styled('main')(({ theme }) => {
  const { palette } = theme;
  return {
    width: '100%',
    background: palette.system['background-dark'],
    flexGrow: '1',
    position: 'relative',
  };
});

const Layout = styled('div')(({ theme }) => {
  const { palette } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    minHeight: '100vh',
    background: palette.system['background-dark'],
  };
});

export const LoginLayout: FC = () => (
  <Layout>
    <LoginHeader />
    <Main>
      <Outlet />
    </Main>
    <FooterTextWithPageTop isOption={false} isPageTop={false} />
  </Layout>
);
