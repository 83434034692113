import { HomeHeader } from '@/components/organism/HomeHeader';
import { Box, styled, Theme, Typography, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { BREAKPOINT } from '@/theme/theme';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { Path } from '@/constants/Router/path';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const Wrraper = styled('div')(({ theme }) => {
  const { breakpoints, palette } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    background: palette.system.background,
    padding: '14px 0 80px',
    [breakpoints.up(BREAKPOINT)]: {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      padding: '14px 40px',
    },
    [breakpoints.up('lg')]: {
      padding: '14px 130px',
    },
  };
});

const ButtonArea = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'inline-flex',
    marginTop: '24px',
    alignSelf: 'center',
    gap: '16px',
    [breakpoints.down(BREAKPOINT)]: {
      display: 'flex',
      flexDirection: 'column',
    },
  };
});

const Contents = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    [breakpoints.down(BREAKPOINT)]: {
      display: 'block',
    },
  };
});
const MuiBox = styled(Box)(({ theme }) => {
  const { palette, breakpoints } = theme;
  return {
    width: '780px',
    alignSelf: 'center',
    padding: '24px',
    textAlign: 'center',
    background: palette.system.main,
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
      marginTop: '24px',
    },
  };
});

// パンクズ
const links = [
  {
    path: 'TOP',
    to: Path.HOME,
  },
  {
    path: '退会する',
    to: Path.WITHDRAWAL,
  },
];

// タイトルの説明文
const description = [
  '持株会から退会をします。単元株以上の株式は、証券口座に移管され、株で移管できない分は精算金として銀行口座に振り込まれます。',
];
export const WithdrawalIndex: FC = () => {
  const navigate = useNavigate();
  const isPc = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(BREAKPOINT)
  );
  useHelmetHandler({
    title: '退会 トップ',
  });

  return (
    <Wrraper>
      <HomeHeader links={links} title="退会する" description={description} />
      <Contents sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
        <MuiBox>
          <Typography
            component="p"
            variant="body-main/medium"
            sx={{ color: '#D20B0B' }}
          >
            一度退会を行うと原則再入会ができません。
          </Typography>
          <Typography
            component="p"
            variant="body-main/medium"
            sx={{ color: '#D20B0B' }}
          >
            持株会を再開される可能性がある場合は休止の手続きをお願いします。
          </Typography>
          <ButtonArea>
            <SecondaryButton
              sx={{
                padding: '16px 24px',
                borderRadius: '28px',
                width: isPc ? 'auto' : '100%',
              }}
              onClick={() => navigate(Path.HOME)}
            >
              topに戻る
            </SecondaryButton>
            <PrimaryButton
              sx={{
                padding: '16px 24px',
                borderRadius: '28px',
                width: isPc ? 'auto' : '100%',
              }}
              onClick={() => navigate(Path.RECESS)}
            >
              休止申請を行う
            </PrimaryButton>
            <PrimaryButton
              sx={{
                padding: '16px 24px',
                borderRadius: '28px',
                width: isPc ? 'auto' : '100%',
              }}
              onClick={() => navigate(Path.WITHDRAWAL_INPUT)}
            >
              退会申請を行う
            </PrimaryButton>
          </ButtonArea>
        </MuiBox>
      </Contents>
    </Wrraper>
  );
};
