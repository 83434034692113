/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class HealthCheckService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @deprecated
   * 【API-A-0000】ヘルスチェック
   * ヘルスチェック
   *
   * @returns any info response
   * @throws ApiError
   */
  public healthCheck(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/helthcheck',
      errors: {
        400: `bad request`,
      },
    });
  }
}
