import { FC } from 'react';
import { Box, Typography, styled } from '@mui/material';

interface GraphTextProps {
  circleColor: string;
  text: string;
}

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

const Circle = styled(Box)({
  width: 16,
  height: 16,
  borderRadius: 8,
  marginRight: 4,
});

const Text = styled(Typography)(({ theme }) => {
  const { typography, palette } = theme;

  return {
    ...typography['body-sub/regular'],
    color: palette.system['text-normal'],
  };
});

export const GraphText: FC<GraphTextProps> = ({ circleColor, text }) => (
  <Wrapper>
    <Circle
      sx={{
        backgroundColor: circleColor,
      }}
    />
    <Text>{text}</Text>
  </Wrapper>
);
