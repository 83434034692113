import { Box, styled, Theme, Typography } from '@mui/material';
import { FC } from 'react';
import Banner from '@/assets/bnr.png';
import { ReactComponent as Dot } from '@/assets/dot.svg';
import { BREAKPOINT } from '@/theme/theme';
import { TextButton } from '@/components/atoms/Buttons/TextButton';
import { Path } from '@/constants/Router/path';
import { useNavigate } from 'react-router-dom';

const Wrraper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '100%',
    [breakpoints.down(BREAKPOINT)]: {
      padding: '0 16px',
    },
  };
});

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.system['text-normal'],
  paddingLeft: '12px',
  borderLeft: 'solid 2px #FC5145',
  marginBottom: '16px',
}));

const Image = styled('img')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '390px',
    margin: '16px 0',
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
    },
  };
});

const SubTitle = styled('div')({
  display: 'flex',
  margin: '16px 0',
  svg: {
    marginTop: '4px',
    marginRight: '4px',
  },
});

export const AccountOpeningTip: FC = () => {
  const navigate = useNavigate();
  const onClickHandleB = () => {
    window.open(Path.ACCOUNTING_OPEN, '_blank');
  };
  return (
    <Wrraper>
      <Title variant="h5">証券口座をお持ちでない方へ</Title>
      <Typography component="p" variant="body-main/regular">
        一部引出のためには口座の開設が必要です。バナーより口座開設の手続きを行ってください。
      </Typography>
      <Typography component="p" variant="body-main/regular">
        ※開設まで1ヵ月ほどかかる場合があります。
      </Typography>
      <TextButton onClick={() => onClickHandleB()}>
        <Image src={Banner} alt="Banner" />
      </TextButton>
      <Title variant="h5">
        口座番号が分からない、口座を持っているか分からない方へ
      </Title>
      <SubTitle>
        <Dot />
        <Box>
          <Typography variant="body-main/regular" sx={{ display: 'block' }}>
            口座を持っているが、口座番号が分からない
          </Typography>
          <Typography variant="body-main/bold" sx={{ display: 'block' }}>
            →口座を作成した部支店へお問い合わせください。
          </Typography>
        </Box>
      </SubTitle>
      <SubTitle>
        <Dot />
        <Box>
          <Typography variant="body-main/regular" sx={{ display: 'block' }}>
            自分が口座を持っているか分からない、覚えていない
          </Typography>
          <Typography variant="body-main/bold" sx={{ display: 'block' }}>
            →持株会事務局へお問い合わせください。
          </Typography>
        </Box>
      </SubTitle>
      <TextButton
        icon="right"
        sx={{ color: 'primary.normal' }}
        wrapperSx={{
          path: { stroke: (theme: Theme) => theme.palette.primary.normal },
        }}
        onClick={() => navigate('/faq')}
      >
        事務局への問い合わせ
      </TextButton>
    </Wrraper>
  );
};
