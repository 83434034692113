import { styled, Typography } from '@mui/material';
import { FC } from 'react';

interface Props {
  // ログイン
  label: string;
  // 説明文
  description?: string;
}

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0px',
  gap: '4px',
});

const Label = styled(Typography)(({ theme }) => ({
  ...theme.typography['body-main/bold'],
  color: theme.palette.system['text-normal'],
}));

const Description = styled('div')(({ theme }) => ({
  ...theme.typography['caption/regular'],
  color: theme.palette.system['text-light'],
}));

export const LabelWithDetail: FC<Props> = ({ label, description }) => (
  <Wrapper>
    <Label>{label}</Label>
    {description && <Description>{description}</Description>}
  </Wrapper>
);
