import { FC } from 'react';
import { styled } from '@mui/material';
import { HomeHeader } from '@/components/organism/HomeHeader';
import { Services } from '@/components/organism/Service/Services';
import { Path } from '@/constants/Router/path';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const BREAKPOINT = 'sm';

const TopWrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '100%',
    padding: '32px 40px',
    [breakpoints.down(BREAKPOINT)]: {
      padding: '24px 16px',
    },
    [breakpoints.up('lg')]: {
      padding: '33px 230px',
    },
  };
});

const BottomWrapper = styled('div')(({ theme }) => {
  const { breakpoints, palette } = theme;
  return {
    width: '100%',
    padding: '33px 40px 80px',
    backgroundColor: palette.system.background,
    [breakpoints.down(BREAKPOINT)]: {
      padding: '24px 16px 60px',
    },
    [breakpoints.up('lg')]: {
      padding: '33px 230px 80px',
    },
  };
});

export const Service: FC = () => {
  useHelmetHandler({
    title: 'サービス紹介',
  });

  return (
    <>
      <TopWrapper>
        <HomeHeader
          title="サービス"
          description={[
            '会員の皆さまにおすすめのサービスをご紹介します。(外部サイトへのリンクです)',
            'NexStocksと合わせて、ぜひご活用ください。',
          ]}
          links={[
            { path: 'TOP', to: Path.HOME },
            { path: 'サービス', to: Path.SERVICE },
          ]}
        />
      </TopWrapper>
      <BottomWrapper>
        <Services />
      </BottomWrapper>
    </>
  );
};
