/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import { theme } from '@/theme/theme';
import { Box, styled } from '@mui/material';
import { MemberInfoGroupRow } from './MemberInfoGroupRow';

interface MemberInfoGroupProps {
  data: string[][];
}

const Wrapper = styled(Box)(() => {
  const { breakpoints, palette } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    marginRight: 8,

    '&:last-of-type': {
      marginRight: 0,
    },

    [breakpoints.down('sm')]: {
      width: '100%',
      marginRight: 0,
      borderWidth: 0,
      borderBottomWidth: 1,
      borderStyle: 'solid',
      borderColor: palette.system.separator,

      '&:last-of-type': {
        borderBottomWidth: 0,
      },
    },
  };
});

const RowWrapper = styled(Box)(() => {
  const { palette } = theme;

  return {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    padding: '8px 0',
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.system.separator,

    '&:last-of-type': {
      borderBottomWidth: 0,
    },
  };
});

export const MemberInfoGroup: FC<MemberInfoGroupProps> = ({ data }) => (
  <Wrapper>
    {data.map((row, index) => (
      <RowWrapper key={`${row[0]}_${row[1]}_${index}`}>
        <MemberInfoGroupRow title={row[0]} value={row[1]} />
      </RowWrapper>
    ))}
  </Wrapper>
);
