import { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MyPageAuthOrganism } from '@/components/organism/MyPage/MyPageAuth';
import { MyPageAuthFormValues as FormValues } from '@/types/components/MyPageAuth';
import { Path } from '@/constants/Router/path';
import { useLoader } from '@/hooks';

import { appClient } from '@/services';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { useMaskoutUser } from './hooks/useMaskoutUser';

/**
 * MyPageAuth
 * @constructor
 */
export const MyPageAuth: FC = () => {
  const [searchParams] = useSearchParams();
  const next = searchParams.get('next');
  const navigate = useNavigate();
  const { handleSetMaskoutTarget, setPassword } = useMaskoutUser();
  const { handleLoaderOpen, handleLoaderClose } = useLoader();
  useHelmetHandler({
    title: 'マイページ 変更画面認証',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * handleSubmit
   * 成功後Contextに値をセットする
   * @param data
   */
  const handleSubmit = async (data: FormValues) => {
    try {
      handleLoaderOpen();
      // todo:: apiができたら実装する
      const body = {
        password: data.password,
      };
      await appClient.users.userPasswordConfirm(body);
      handleLoaderClose();
      // 証券口座orUse情報
      handleSetMaskoutTarget(next);
      // passwordをセットする
      setPassword(data.password);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      navigate(`${Path.MYPAGE}/${next}`);
    } catch (e) {
      handleLoaderClose();
      console.error(e);
      // todo:: errorのUIを表示する
      alert('Passwordが違います');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  return <MyPageAuthOrganism handleSubmit={(data) => handleSubmit(data)} />;
};
