import { FC } from 'react';

export const KeyboardArrowUpIcon: FC = (props) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.5 9.5L9 2L16.5 9.5" stroke="#666666" strokeWidth="2" />
  </svg>
);
