import { SimulationHeaderText } from '@/components/molecules/Simulation/SimulationHeaderText';
import { Grid, styled } from '@mui/material';
import React from 'react';

const Wrapper = styled(Grid)(({ theme }) => {
  const { palette, breakpoints } = theme;
  return {
    background: palette.system.white,
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: 8,
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.2)',
    padding: '13.5px 0',
    marginTop: 24,

    [breakpoints.down('sm')]: {
      maxWidth: '100%',
      margin: '24px auto 0',
    },
  };
});

interface SimulationHeaderProps {
  stockCode: string;
  market: 'TOKYO' | 'NAGOYA';
  bountyRate: number;
}

const marketName = {
  TOKYO: '東京',
  NAGOYA: '名古屋',
};

export const SimulationHeader: React.FC<SimulationHeaderProps> = ({
  stockCode,
  market,
  bountyRate,
}) => (
  <Wrapper container>
    <SimulationHeaderText
      title="銘柄コード"
      description={stockCode}
      xs="67%"
      sm="16.5%"
    />
    <SimulationHeaderText
      title="市場"
      description={marketName[market]}
      xs="41%"
      sm="16.5%"
    />
    <SimulationHeaderText
      title="奨励金率"
      description={`${bountyRate}%`}
      xs="41%"
      sm="16.5%"
    />
  </Wrapper>
);
