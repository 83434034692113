import { FC } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { ResultCaptions } from './ResultCaptions';

interface ResultInfoBigProps {
  title: string;
  titleExtension?: string;
  amount: string;
  amountExtension?: string;
  border: boolean;
  captions: boolean;
}

const Wrapper = styled(Box)(({ theme }) => {
  const { breakpoints } = theme;
  return {
    padding: 16,
    backgroundColor: '#EDFBFC',
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.2)',
    borderWidth: 0,

    [breakpoints.down('sm')]: {
      width: '100%',
    },
  };
});

const Row = styled(Box)(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',

    [breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      margin: 'unset',
    },
  };
});

const TitleWrapper = styled(Box)(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 16,

    [breakpoints.down('sm')]: {
      flex: 4,
      flexDirection: 'column',
      justifyContent: 'center',
      marginRight: 0,
    },
  };
});

const InfoWrapper = styled(Box)(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',

    [breakpoints.down('sm')]: {
      flex: 6,
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
  };
});

const Title = styled(Typography)(({ theme }) => {
  const { typography, palette, breakpoints } = theme;

  return {
    ...typography['body-main/medium'],
    color: palette.system['text-normal'],
    lineHeight: '39px',

    [breakpoints.down('sm')]: {
      lineHeight: '150%',
    },
  };
});

const Amount = styled(Typography)(({ theme }) => {
  const { typography, palette } = theme;

  return {
    ...typography['body-main/medium'],
    color: palette.system['text-normal'],
    fontSize: 26,
    wordBreak: 'break-word',
    lineHeight: '39px',
    textAlign: 'right',
  };
});

const AmountExtensionLead = styled(Typography)(({ theme }) => {
  const { typography, palette } = theme;

  return {
    ...typography['body-sub/medium'],
    color: palette.system['text-normal'],
    marginRight: 8,
    display: 'inline-block',
    textAlign: 'right',
  };
});

const AmountExtension = styled(Typography)(({ theme }) => {
  const { typography, palette } = theme;

  return {
    ...typography['body-main/medium'],
    color: palette.states.main,
  };
});

const CaptionsWrapper = styled(Box)(({ theme }) => {
  const { breakpoints } = theme;

  return {
    marginTop: 8,
    display: 'none',

    [breakpoints.down('sm')]: {
      display: 'block',
    },
  };
});

export const ResultInfoBig: FC<ResultInfoBigProps> = ({
  title,
  titleExtension,
  amount,
  amountExtension,
  border,
  captions,
}) => (
  <Wrapper
    sx={{
      borderTopWidth: { xs: border ? 1 : 0, sm: 0 },
      borderLeftWidth: { xs: 0, sm: border ? 1 : 0 },
    }}
  >
    <Row>
      <TitleWrapper>
        <Title>{title}</Title>
        {titleExtension && <Title>{titleExtension}</Title>}
      </TitleWrapper>
      <InfoWrapper>
        <Amount>{amount}円</Amount>
        {amountExtension && (
          <AmountExtension>
            <AmountExtensionLead>差額</AmountExtensionLead>
            {amountExtension}
          </AmountExtension>
        )}
      </InfoWrapper>
    </Row>
    {captions && (
      <CaptionsWrapper>
        <ResultCaptions
          caption=""
          captionBold="※シミュレーションのため正確な積立額とは多少異なります。"
        />
      </CaptionsWrapper>
    )}
  </Wrapper>
);
