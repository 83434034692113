import { Box, Typography, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { BREAKPOINT, theme } from '@/theme/theme';

import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { useLocation } from 'react-router-dom';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { SignUpUpper } from './SignUpUpper';

interface Props {
  isPc?: boolean;
  onClick?: () => void;
}

const Contents: FC<Props> = ({ isPc, onClick }) => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: isPc ? '32px' : '32px 16px',
      gap: '16px',
      backgroundColor: 'system.white',
      borderRadius: isPc ? '0 0 12px 12px' : '0',
    }}
  >
    <Typography
      variant="h3"
      sx={{
        color: 'system.text-normal',
        width: '100%',
        pb: isPc ? '24px' : '16px',
        mb: isPc ? '8px' : '0',
        borderBottom: '1px solid',
        borderColor: 'system.separator-light',
      }}
    >
      会員情報登録が完了しました
    </Typography>
    <Typography
      variant="body-main/regular"
      sx={{ color: 'system.text-normal' }}
    >
      続いて、拠出設定に進んでください。
    </Typography>
    <Typography variant="caption/regular" sx={{ color: 'system.text-light' }}>
      ※web持株会サービスの使用には拠出設定が必須です。
    </Typography>
    <PrimaryButton
      onClick={onClick}
      sx={{
        width: isPc ? '162px' : '100%',
        height: '56px',
        mt: isPc ? '24px' : '16px',
        alignSelf: 'center',
        borderRadius: '28px',
      }}
    >
      拠出設定へ進む
    </PrimaryButton>
  </Box>
);

const Wrapper: FC<Props> = ({ isPc, onClick }) => (
  <Box sx={{ maxWidth: '780px', margin: '0 auto' }}>
    <SignUpUpper isSignUp flowOn={2} />
    <Contents isPc={isPc} onClick={onClick} />
  </Box>
);

export const SignUpUserCompleteOrganism: FC<{
  handleClick: () => void;
}> = ({ handleClick }) => {
  const location = useLocation();
  const {
    associationData: { associationName },
  } = location.state as {
    associationData: {
      associationCd: string;
      associationName: string;
    };
  };
  const isPc = useMediaQuery(() => theme.breakpoints.up(BREAKPOINT));
  useHelmetHandler({
    title: '持株会入会登録_会員情報登録完了',
  });

  return (
    <Box
      sx={{
        bgcolor: 'system.background-dark',
        padding: {
          xs: '0 0 60px',
          sm: '0 40px 60px',
          md: '0 40px 60px',
          lg: '0 130px 60px',
        },
      }}
    >
      {associationName && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '40px',
            marginTop: '32px',
          }}
        >
          <Typography
            sx={{
              ...theme.typography.h6,
              color: theme.palette.system['text-light'],
            }}
          >
            {associationName}
          </Typography>
        </Box>
      )}
      <Wrapper isPc={isPc} onClick={handleClick} />
    </Box>
  );
};
