import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { TextInput } from '@/components/atoms/Input/TextInput';
import { Box, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useController,
  UseControllerProps,
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BREAKPOINT, theme } from '@/theme/theme';
import { ReactComponent as Icon } from '@/assets/card.svg';
import {
  MyPageSecuritiesFormValues as FormValues,
  MyPageSecuritiesProps as Props,
  MyPageSecuritiesLabelProps as LabelProps,
} from '@/types/components/MyPageSecurities';

import type { SecuritiesAccount } from '@/components/pages/MyPage/hooks/useMaskoutUser';
import { IsNumeric } from '@/utils/stringProcessor';

const Title: FC = () => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      mb: '24px',
      gap: '4px',
    }}
  >
    <SvgIcon sx={{ fill: () => theme.palette.system.placeholder }}>
      <Icon />
    </SvgIcon>
    <Typography variant="h4" sx={{ color: 'system.text-normal' }}>
      証券口座情報変更
    </Typography>
  </Box>
);

const Label: FC<LabelProps> = ({ isPc, labelCaption, caution }) => (
  <Box
    sx={{
      width: isPc ? '140px' : '100%',
      minWidth: '140px',
      display: 'flex',
      flexDirection: isPc ? 'column' : 'row',
      alignItems: isPc ? 'flex-start' : 'center',
      p: '0',
      gap: isPc ? '0' : '4px',
    }}
  >
    <Typography
      component="span"
      variant="body-main/bold"
      sx={{ color: 'system.text-normal' }}
    >
      {labelCaption}
    </Typography>
    <Typography variant="caption/regular" sx={{ color: 'system.text-light' }}>
      {caution}
    </Typography>
  </Box>
);

const Password = (
  props: UseControllerProps<FormValues> & {
    maxLength: number;
    valueData?: string;
    setAccountNumber: (accountNumber: string) => void;
    setBranchCd: (branchCode: string) => void;
  }
) => {
  const {
    field: { ref, ...rest },
    fieldState,
  } = useController(props);
  const { maxLength, valueData, setAccountNumber, setBranchCd } = props;

  return (
    <TextInput
      {...rest}
      value={valueData}
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
      inputRef={ref}
      onChange={(e) => {
        let isChange = false;
        if (e.target.value === '') isChange = true;
        if (IsNumeric(e.target.value) && e.target.value.length <= maxLength)
          isChange = true;
        if (isChange) {
          rest.onChange(e);
          if (rest.name === 'departmentCode') {
            setBranchCd(e.target.value);
          } else if (rest.name === 'accountNumber') {
            setAccountNumber(e.target.value);
          }
        }
      }}
      sx={{
        [theme.breakpoints.down(BREAKPOINT)]: {
          width: '100%',
        },
      }}
    />
  );
};

const LabelPassword: FC<LabelProps & Props> = ({
  isPc,
  labelCaption,
  children,
  underline = false,
  caution,
}) => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: isPc ? 'row' : 'column',
      alignItems: 'flex-start',
      pb: underline ? '16px' : '0',
      gap: isPc ? '24px' : '8px',
      borderBottom: underline ? '1px solid' : '0',
      borderColor: 'system.separator-light',
    }}
  >
    <Label isPc={isPc} labelCaption={labelCaption} caution={caution} />
    {children}
  </Box>
);

const PrimarySecondary: FC<Props & { methods: UseFormReturn<FormValues> }> = (
  props
) => {
  const {
    isPc,
    methods: { watch },
  } = props;
  const navigate = useNavigate();

  const branchCodeLength = watch('departmentCode').length;
  const branchName = watch('departmentName');
  const accountNumberLength = watch('accountNumber').length;
  return (
    <Box
      sx={{
        width: '100%',
        mt: isPc ? 'calc(40px - 16px)' : '0', // gap分を減算
      }}
    >
      <Box
        sx={{
          margin: '0 auto',
          width: isPc ? '389px' : '100%',
          display: 'flex',
          flexDirection: isPc ? 'row-reverse' : 'column',
          alignItems: isPc ? 'center' : 'flex-start',
          padding: '0px',
          gap: '16px',
        }}
      >
        <PrimaryButton
          type="submit"
          disabled={
            !(accountNumberLength === 7 && branchCodeLength === 3 && branchName)
          }
          sx={{
            width: isPc ? '226px' : '100%',
            height: '56px',
            padding: '20px, 24px, 20px, 26px',
            borderRadius: '28px',
          }}
        >
          証券口座情報を変更する
        </PrimaryButton>
        <SecondaryButton
          sx={{
            width: isPc ? '162px' : '100%',
            height: '56px',
            padding: '20px, 24px, 20px, 26px',
            borderRadius: '28px',
          }}
          onClick={() => navigate('/mypage')}
        >
          変更せずに戻る
        </SecondaryButton>
      </Box>
    </Box>
  );
};

const Contents: FC<
  Props & {
    getDepartmentName: () => void;
    departmentName: string;
    departmentCode: string;
    accountNumber: string;
    methods: UseFormReturn<FormValues>;
    setAccountNumber: (accountNumber: string) => void;
    setBranchCd: (branchCode: string) => void;
  }
> = ({
  isPc,
  getDepartmentName,
  departmentName,
  departmentCode,
  accountNumber,
  methods,
  setAccountNumber,
  setBranchCd,
}) => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: isPc ? 'center' : 'flex-start',
      padding: isPc ? '32px' : '24px 16px',
      gap: isPc ? '16px' : '24px',
      backgroundColor: 'system.white',
      borderRadius: isPc ? '12px' : '0',
    }}
  >
    <LabelPassword isPc={isPc} labelCaption="金融機関名" underline>
      <Typography
        component="span"
        variant="body-main/regular"
        sx={{ color: 'system.text-normal' }}
      >
        東海東京証券
      </Typography>
    </LabelPassword>
    <LabelPassword
      isPc={isPc}
      labelCaption="部店番号"
      underline
      caution="※半角数字3桁"
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: '8px',
          [theme.breakpoints.down(BREAKPOINT)]: {
            width: '100%',
          },
        }}
      >
        <Password
          name="departmentCode"
          rules={{
            required: '入力してください',
            minLength: { value: 3, message: '3桁で入力してください' },
            maxLength: { value: 3, message: '3桁で入力してください' },
          }}
          maxLength={3}
          valueData={departmentCode}
          setAccountNumber={setAccountNumber}
          setBranchCd={setBranchCd}
        />
        <SecondaryButton
          onClick={getDepartmentName}
          sx={{ minWidth: '152px', fontSize: '14px' }}
        >
          部店名を自動で入力
        </SecondaryButton>
      </Box>
    </LabelPassword>
    <LabelPassword isPc={isPc} labelCaption="部店名" underline>
      {departmentName === '' ? (
        <Typography
          component="p"
          variant="body-main/regular"
          sx={{ color: 'system.text-light' }}
        >
          部店名が表示されます
        </Typography>
      ) : (
        <Typography component="p" variant="body-main/regular">
          {departmentName}
        </Typography>
      )}
    </LabelPassword>
    <LabelPassword isPc={isPc} labelCaption="口座番号" caution="※半角数字7桁">
      <Password
        name="accountNumber"
        rules={{
          required: '入力してください',
          minLength: { value: 7, message: '7桁で入力してください' },
          maxLength: { value: 7, message: '7桁で入力してください' },
          validate: IsNumeric,
        }}
        maxLength={7}
        valueData={accountNumber}
        setAccountNumber={setAccountNumber}
        setBranchCd={setBranchCd}
      />
    </LabelPassword>
    <Typography
      component="p"
      variant="body-sub/regular"
      sx={{ color: 'system.text-normal', alignSelf: 'flex-start' }}
    >
      ※特定口座をすでにお持ちの方は、自動的に特定口座が反映されます。
    </Typography>
    <PrimarySecondary isPc={isPc} methods={methods} />
  </Box>
);

const Wrapper: FC<
  Props & {
    getDepartmentName: () => void;
    departmentName: string;
    departmentCode: string;
    accountNumber: string;
    methods: UseFormReturn<FormValues>;
    setAccountNumber: (accountNumber: string) => void;
    setBranchCd: (branchCode: string) => void;
  }
> = ({
  isPc,
  getDepartmentName,
  departmentName,
  departmentCode,
  accountNumber,
  methods,
  setAccountNumber,
  setBranchCd,
}) => (
  <Box sx={{ margin: '0 auto' }}>
    <Title />
    <Contents
      isPc={isPc}
      getDepartmentName={getDepartmentName}
      departmentName={departmentName}
      departmentCode={departmentCode}
      accountNumber={accountNumber}
      methods={methods}
      setAccountNumber={setAccountNumber}
      setBranchCd={setBranchCd}
    />
  </Box>
);

export const MyPageSecuritiesOrganism: FC<{
  userSecuritiesAccount: SecuritiesAccount;
  branchName: string;
  branchCode: string;
  accountNumber: string;
  handleSubmit: (data: FormValues) => void;
  getDepartmentName: (code: string) => object;
  setAccountNumber: (accountNumber: string) => void;
  setBranchCd: (branchCode: string) => void;
}> = ({
  userSecuritiesAccount,
  branchName,
  handleSubmit,
  getDepartmentName,
  branchCode,
  accountNumber,
  setAccountNumber,
  setBranchCd,
}) => {
  const isPc = useMediaQuery(() => theme.breakpoints.up(BREAKPOINT));

  const defaultValues = useMemo(
    () =>
      ({
        departmentCode: userSecuritiesAccount.branchCode ?? '',
        departmentName: userSecuritiesAccount.branchName ?? '',
        accountNumber: userSecuritiesAccount.accountNumber ?? '',
      } as FormValues),
    [userSecuritiesAccount]
  );

  const methods = useForm<FormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (branchName !== '') {
      methods.setValue('departmentName', branchName);
    }
  }, [branchName]);

  useEffect(() => {
    if (branchCode !== '') {
      methods.setValue('departmentCode', branchCode);
    }
    if (branchCode !== '') {
      methods.setValue('accountNumber', accountNumber);
    }
  }, [branchCode, accountNumber]);

  /**
   * getDepartmentCode
   * 部店番号 取得
   */
  const getDepartmentCode = () => {
    const departmentCode = methods.getValues('departmentCode');
    if (departmentCode && departmentCode !== '') {
      getDepartmentName(departmentCode);
    }
  };

  /**
   * onSubmit
   * @param data
   */
  const onSubmit: SubmitHandler<FormValues> = (data) => {
    handleSubmit(data);
  };

  return (
    <Box
      sx={{
        bgcolor: 'system.background',
        padding: {
          xs: '24px 0 86px',
          sm: '48px 40px 386px',
          md: '48px 40px 386px',
          lg: '48px 330px 386px',
        },
      }}
    >
      <FormProvider {...methods}>
        {/**
         * lintエラー公式未対応
         * @see https://react-hook-form.com/api/useform/handlesubmit
         */}
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Wrapper
            isPc={isPc}
            getDepartmentName={getDepartmentCode}
            departmentName={branchName}
            departmentCode={branchCode}
            accountNumber={accountNumber}
            methods={methods}
            setAccountNumber={setAccountNumber}
            setBranchCd={setBranchCd}
          />
        </form>
      </FormProvider>
    </Box>
  );
};
