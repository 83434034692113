import { styled, SxProps, Theme } from '@mui/material';
import { FC, ReactNode } from 'react';

interface LoginProps {
  sx?: SxProps<Theme>;
  children: ReactNode;
}

const MuiBox = styled('div')(({ theme }) => ({
  width: '580px',
  margin: '74px auto',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0 0 40px',
  gap: '32px',
  borderRadius: '12px',
  background: theme.palette.system.main,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: 0,
    borderRadius: '0',
  },
}));

export const LoginWideBox: FC<LoginProps> = ({ sx, children }) => (
  <MuiBox sx={sx}>{children}</MuiBox>
);
