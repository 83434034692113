import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
// import { LoginTitleWithUnderline } from '@/components/molecules/Login/LoginTitleWithUnderline';
import { BREAKPOINT, theme } from '@/theme/theme';
import {
  Box,
  Link,
  Stack,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FC } from 'react';

const MuiBox = styled('div')(() => ({
  width: '780px',
  margin: '74px auto 0',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '32px',
  gap: '24px',
  borderRadius: '12px',
  background: theme.palette.system.main,
  [theme.breakpoints.down(BREAKPOINT)]: {
    width: '100%',
    padding: '32px 16px',
    borderRadius: '0',
    alignItems: 'flex-start',
  },
}));

const Title = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0px',
  gap: '32px',
  [theme.breakpoints.down(BREAKPOINT)]: {
    alignItems: 'flex-start',
  },
}));

const NormalTypography = styled(Typography)(() => ({
  ...theme.typography['body-main/regular'],
  color: theme.palette.system['text-normal'],
  textAlign: 'left',
}));

const Line = styled(Box)({
  height: '1px',
  backgroundColor: theme.palette.system['separator-light'],
  width: '100%',
  [theme.breakpoints.up(BREAKPOINT)]: {
    marginTop: '8px',
  },
});

const CaptionBox = styled(Box)({
  gap: '24px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down(BREAKPOINT)]: {
    alignItems: 'flex-start',
  },
});

export const LoginMailComplete: FC = () => {
  const isPc = useMediaQuery(() => theme.breakpoints.up(BREAKPOINT));

  return (
    <MuiBox>
      {isPc && ''}
      <Title>
        <Typography
          variant="h4"
          sx={{
            color: () => theme.palette.system['text-normal'],
          }}
        >
          メールアドレス確認
        </Typography>
      </Title>
      <NormalTypography>
        ご登録いただいたメールアドレス宛に確認メールを送付いたしました。
      </NormalTypography>
      <Line />
      {isPc ? (
        <CaptionBox>
          <Stack>
            <Typography variant="body-main/regular">
              ※メールが受信できなくなってしまった場合は、ログインIDのリセットが必要になります。
            </Typography>
            <Typography variant="body-main/regular">
              事務局の担当者にご連絡ください。
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="body-main/regular">
              ※メール受信設定をされている場合は、
            </Typography>
            <Typography variant="body-main/regular">
              「nexstocks.jp」からのメールを受信できるようにご設定ください。
            </Typography>
          </Stack>
        </CaptionBox>
      ) : (
        <CaptionBox>
          <Typography variant="body-main/regular" sx={{ textAlign: 'left' }}>
            ※メールが受信できなくなってしまった場合は、ログインIDのリセットが必要になります。
            事務局の担当者にご連絡ください。
          </Typography>
          <Typography variant="body-main/regular" sx={{ textAlign: 'left' }}>
            ※メール受信設定をされている場合は、「nexstocks.jp」からのメールを受信できるようにご設定ください。
          </Typography>
        </CaptionBox>
      )}
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Link href="/login">
          <PrimaryButton
            sx={{
              width: isPc ? '226px' : '100%',
              height: '56px',
              borderRadius: '28px',
            }}
          >
            ログイン画面へ移動する
          </PrimaryButton>
        </Link>
      </Box>
    </MuiBox>
  );
};
