import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
} from '@mui/material';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

interface CheckInputState {
  name: string;
}

type CheckProps = CheckInputState & CheckboxProps;
type ChildProps = ComponentPropsWithoutRef<'input'> & CheckProps;

export const LoginAgreeCheck = forwardRef<HTMLInputElement, ChildProps>(
  ({ name, disabled, ...props }, ref) => (
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            sx={{ p: '0', mr: '8px' }}
            name={name}
            defaultChecked={false}
            {...props}
          />
        }
        label="Web利用規約に同意する"
        componentsProps={{ typography: { variant: 'body-main/regular' } }}
        inputRef={ref}
        disabled={disabled}
        sx={{
          m: '0',
          p: '8px 16px',
          color: 'system.text-normal',
          bgcolor: 'secondary.bg',
          borderRadius: '4px',
        }}
      />
    </FormControl>
  )
);
