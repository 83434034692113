import { theme } from '@/theme/theme';
import { LocationState } from '@/components/pages/Withdrawal/WithdrawalInput';
import { Box, Typography, styled } from '@mui/material';
import { FC } from 'react';
import { InfoRow } from '../infoRow';

interface PdfContentProps {
  title: string;
  data: LocationState;
}

const Container = styled(Box)(() => ({
  marginTop: 24,
  '@media print': {
    marginTop: 24,
  },
}));

const ContainerTitle = styled(Typography)(() => ({
  ...theme.typography.h2,
  fontWeight: 500,
  paddingBottom: 12,
  borderWidth: 0,
  fontSize: 26,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: '#000000',
  [theme.breakpoints.up('sm')]: {
    fontSize: 26,
  },
  '@media print': {
    ...theme.typography.h2,
    fontWeight: 500,
    paddingBottom: 12,
    borderWidth: 0,
    fontSize: 21,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    [theme.breakpoints.up('sm')]: {
      fontSize: 21,
    },
  },
}));

const ContainerContent = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',

  '@media print': {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
}));

const ContainerContenstWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 20,

  '@media print': {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
  },
}));

const DoubleCoulmnRowWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
  '@media print': {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
  },
}));

export const RecessResumeContent: FC<PdfContentProps> = ({ title, data }) => (
  <Container sx={{ marginTop: '40px' }}>
    <ContainerTitle>{title}</ContainerTitle>
    <ContainerContenstWrapper>
      <ContainerContent>
        <InfoRow
          key="現在の所持株数-resume-recess"
          title="現在の所持株数"
          content={`${data.stocksNumber || 0}株`}
        />
        <InfoRow
          key="1口あたり金額-resume-recess"
          title="1口あたり金額"
          content={`${(
            data.associationContribution?.unitAmount || 0
          ).toLocaleString()}円`}
        />
        <DoubleCoulmnRowWrapper>
          <InfoRow
            key="給与-resume-recess"
            title={['給与', '（毎月の拠出）']}
            content={[
              `口数 ${data.userContribution?.monthlyUnit || 0}口`,
              `拠出金額 ${(
                (data.userContribution?.monthlyUnit || 0) *
                (data.associationContribution.unitAmount || 0)
              ).toLocaleString()}円`,
            ]}
            sxProps={{ flex: 1 }}
          />
          <InfoRow
            key="賞与-resume-recess"
            title="賞与"
            content={[
              `口数 ${data.userContribution?.bonusUnit || 0}口`,
              `拠出金額 ${(
                (data.userContribution?.bonusUnit || 0) *
                (data.associationContribution.unitAmount || 0)
              ).toLocaleString()}円`,
            ]}
            sxProps={{ flex: 1 }}
          />
        </DoubleCoulmnRowWrapper>
      </ContainerContent>
    </ContainerContenstWrapper>
  </Container>
);
