// その7

import { styled, useMediaQuery, Theme, Link } from '@mui/material';
import { FC } from 'react';
import { services } from '@/constants/Services/info';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { Path } from '@/constants/Router/path';

const BREAKPOINT = 'sm';

const Wrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    [breakpoints.down(BREAKPOINT)]: {
      gap: '24px',
    },
  };
});

const TitleWrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    gap: '16px',
    alignItems: 'start',
    [breakpoints.down(BREAKPOINT)]: {
      flexDirection: 'column',
    },
  };
});

const Title = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography.h3,
  };
});

const Description = styled('div')(({ theme }) => {
  const { typography, palette } = theme;
  return {
    ...typography['body-main/regular'],
    color: palette.system['text-light'],
  };
});

const ServicesWrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    width: '100%',

    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '20px',
    [breakpoints.down(BREAKPOINT)]: {
      flexDirection: 'column',
    },
  };
});

const ServiceWrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    width: '32%',
    flexDirection: 'column',
    borderRadius: '12px',
    border: '1px solid #DDDDDD',
    overflow: 'hidden',

    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
    },
  };
});

const ServiceTitle = styled('div')(({ theme }) => {
  const { typography, palette } = theme;
  return {
    ...typography['body-main/medium'],
    padding: '16px',
    color: palette.system['text-normal'],
  };
});

const ImgLink = styled(Link)({
  textDecoration: 'none',
});

const Img = styled('img')({
  width: '100%',
});

export const Services: FC = () => {
  const isSp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(BREAKPOINT)
  );
  const width = isSp ? '100%' : '200px';
  const height = isSp ? '56px' : '40px;';
  const sx = { width, height };
  const navigate = useNavigate();

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>サービス</Title>
        <Description>
          会員の皆さまにおすすめのサービスをご紹介します。(外部サイト)
          <br />
          NexStocksと合わせて、ぜひご活用ください。
        </Description>
      </TitleWrapper>
      <ServicesWrapper>
        {services.map((s) => (
          <ServiceWrapper key={s.name}>
            <ImgLink target="_blank" href={s.link} rel="noopener noreferrer">
              <Img src={s.img} />
              <ServiceTitle>{s.name}</ServiceTitle>
            </ImgLink>
          </ServiceWrapper>
        ))}
      </ServicesWrapper>
      <PrimaryButton sx={sx} onClick={() => navigate(Path.SERVICE)}>
        サービスをもっと見る
      </PrimaryButton>
    </Wrapper>
  );
};
