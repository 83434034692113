import { styled } from '@mui/material';
import { FC } from 'react';
import logo from '@/assets/logo.png';
import { BREAKPOINT } from '@/theme/theme';

const Header = styled('header')(({ theme }) => {
  const { palette, breakpoints } = theme;
  return {
    width: '100%',
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: palette.system['background-dark'],
    [breakpoints.down(BREAKPOINT)]: {
      padding: '13px 0',
    },
  };
});

const Img = styled('img')({
  width: '125px',
});

const CompanyInfo = styled('p')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'block',
    marginTop: '32px',
    fontSize: '18px',
    [breakpoints.down(BREAKPOINT)]: {
      marginTop: '24px',
    },
  };
});

interface Props {
  companyName?: string;
}

export const LoginHeader: FC<Props> = ({ companyName }) => (
  <Header>
    <Img src={logo} alt="Logo" />
    {companyName && <CompanyInfo>{companyName}</CompanyInfo>}
  </Header>
);
