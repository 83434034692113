export const userLabels = {
  name: '氏名（漢字）',
  nameKane: '氏名（カナ）',
  birthday: '生年月日',
  address: '住所',
  tel: '電話番号',
  employeeCode: '社員コード',
} as const;

export const securitiesLabels = {
  name: '金融機関名',
  departmentCode: '部店コード',
  departmentName: '部店名',
  accountNumber: '口座情報',
} as const;

export const contributionLabels = {
  status: '稼働/休止',
  contributionStartDate: '積立開始日',
  monthlyIncentiveRatio: '奨励金率',
  payRecessEndDate: '休止開始日',
  // eslint-disable-next-line no-useless-concat
  payUnit: '給与',
} as const;

export const loginLabels = {
  loginId: 'ログインID',
} as const;

export const passwordLabels = {
  password: 'パスワード',
} as const;

export const secretariatsLabel = {
  companyName: '企業名',
  mochikabukaiCode: '持株会コード',
  mochikabukaiName: '持株会名',
} as const;
