import { atom } from 'recoil';

export type SnackbarMessageType = {
  open: boolean;
  messageType?: 'success' | 'error' | 'warning' | 'info';
  messages: string[];
};

export const SnackbarMessageInit: SnackbarMessageType = {
  open: false,
  messageType: 'success',
  messages: [],
};

export const snackbarMessageAtom = atom<SnackbarMessageType>({
  key: 'SNACK_BAR_MESSAGE',
  default: SnackbarMessageInit,
});
