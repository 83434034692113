import { FC, useEffect } from 'react';
import { SignUpContributionCompleteOrganism } from '@/components/organism/SignUp/SignUpContributionComplete';
import { useNavigate } from 'react-router-dom';
import { Path } from '@/constants/Router/path';

export const SignUpContributionComplete: FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);
  const handleClick = () => {
    navigate(Path.LOGIN);
  };
  return <SignUpContributionCompleteOrganism onClick={handleClick} />;
};
