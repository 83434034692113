import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { TextInput } from '@/components/atoms/Input/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useController,
  useForm,
  useFormState,
} from 'react-hook-form';
import * as yup from 'yup';
import {
  MyPageAuthFormValues as FormValues,
  MyPageAuthProps as Props,
} from '@/types/components/MyPageAuth';
import { useNavigate } from 'react-router-dom';
import { BREAKPOINT, theme } from '@/theme/theme';

const FIELD_NAME = 'password' as keyof FormValues;

const Title: FC = () => (
  <Typography variant="h4" sx={{ color: 'system.text-normal' }}>
    パスワード認証
  </Typography>
);

const Description: FC<Props> = (props) => {
  const { isPc } = props;
  return (
    <Typography
      component="p"
      variant="body-main/regular"
      sx={{ color: 'system.text-normal', textAlign: isPc ? 'center' : 'left' }}
    >
      登録情報を変更するために認証が必要です。
      <br />
      パスワードを入力してください。
    </Typography>
  );
};

const TitleDescription: FC<Props> = (props) => {
  const { isPc } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: isPc ? 'center' : 'flex-start',
        padding: '0px',
        gap: isPc ? '24px' : '12px',
        height: isPc ? '96px' : '84px',
      }}
    >
      <Title />
      <Description isPc={isPc} />
    </Box>
  );
};

const Label: FC<Props> = (props) => {
  const { isPc } = props;
  return (
    <Typography
      component="p"
      variant="body-main/bold"
      sx={{ color: 'system.text-normal', marginTop: isPc ? '8px' : '0' }}
    >
      パスワード
    </Typography>
  );
};

const Password: FC = () => {
  const {
    field: { ref, ...rest },
  } = useController({ name: FIELD_NAME });
  const errors = useFormState().errors as {
    [x: string]: { message: string };
  };
  return (
    <TextInput
      {...rest}
      error={!!errors[FIELD_NAME]}
      helperText={errors[FIELD_NAME]?.message}
      inputRef={ref}
      sx={{
        [theme.breakpoints.down(BREAKPOINT)]: {
          width: '100%',
        },
      }}
    />
  );
};

const LabelPassword: FC<Props> = (props) => {
  const { isPc } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isPc ? 'row' : 'column',
        alignItems: 'flex-start',
        padding: '0',
        gap: isPc ? '24px' : '8px',
        width: isPc ? '352px' : '100%',
      }}
    >
      <Label isPc={isPc} />
      <Password />
    </Box>
  );
};

const PrimarySecondary: FC<Props> = (props) => {
  const { isPc } = props;
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isPc ? 'row' : 'column',
        alignItems: isPc ? 'center' : 'flex-start',
        padding: '0px',
        gap: '16px',
        width: isPc ? '260px' : '100%',
        height: isPc ? '56px' : '128px',
      }}
    >
      <SecondaryButton
        sx={{
          width: isPc ? '130px' : '100%',
          height: '56px',
          padding: '20px, 24px, 20px, 26px',
          borderRadius: '28px',
        }}
        onClick={() => navigate(-1)}
      >
        キャンセル
      </SecondaryButton>
      <PrimaryButton
        type="submit"
        sx={{
          width: isPc ? '114px' : '100%',
          height: '56px',
          padding: '20px, 24px, 20px, 26px',
          borderRadius: '28px',
        }}
      >
        認証する
      </PrimaryButton>
    </Box>
  );
};

const PasswordButtons: FC = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0px',
      gap: '24px',
      width: '352px',
    }}
  >
    <LabelPassword isPc />
    <PrimarySecondary isPc />
  </Box>
);

const Caution: FC = () => (
  <Typography
    component="p"
    variant="body-sub/regular"
    sx={{ color: 'system.text-normal' }}
  >
    ※パスワードをお忘れの場合
    <br />
    一度ログアウトして、ログイン画面からパスワードの変更を行ってください。
  </Typography>
);

const Wrapper: FC<Props> = (props) => {
  const { isPc } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        margin: 'auto',
        width: isPc ? '700px' : 'inherit',
        flexDirection: 'column',
        alignItems: isPc ? 'center' : 'flex-start',
        padding: isPc ? '24px' : '24px 16px',
        gap: isPc ? '40px' : '24px',
        backgroundColor: 'system.white',
        borderRadius: isPc ? '12px' : '0',
      }}
    >
      <TitleDescription isPc={isPc} />
      {isPc ? (
        <PasswordButtons />
      ) : (
        <>
          <LabelPassword isPc={isPc} />
          <PrimarySecondary isPc={isPc} />
        </>
      )}
      <Caution />
    </Box>
  );
};

export const MyPageAuthOrganism: FC<{
  handleSubmit: (data: FormValues) => void;
}> = ({ handleSubmit }) => {
  const isPc = useMediaQuery(() => theme.breakpoints.up(BREAKPOINT));
  const schema: yup.SchemaOf<FormValues> = yup.object({
    [FIELD_NAME]: yup.string().required('入力してください'),
  });
  const methods = useForm<FormValues>({
    defaultValues: {
      [FIELD_NAME]: '',
    },
    resolver: yupResolver(schema),
  });
  const onSubmit: SubmitHandler<FormValues> = (data) => {
    handleSubmit(data);
  };

  return (
    <Box
      sx={{
        bgcolor: 'system.background',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: isPc ? 'absolute' : 'relative',
        top: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        bottom: 0,
        padding: '24px 0 60px',
      }}
    >
      <FormProvider {...methods}>
        {/**
         * lintエラー公式未対応
         * @see https://react-hook-form.com/api/useform/handlesubmit
         */}
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Wrapper isPc={isPc} />
        </form>
      </FormProvider>
    </Box>
  );
};
