/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { ApplicationsService } from './services/ApplicationsService';
import { AssociationsService } from './services/AssociationsService';
import { AuthorizationService } from './services/AuthorizationService';
import { HealthCheckService } from './services/HealthCheckService';
import { MemberInformationsService } from './services/MemberInformationsService';
import { ReportsService } from './services/ReportsService';
import { SimulationService } from './services/SimulationService';
import { UsersService } from './services/UsersService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppClient {
  public readonly applications: ApplicationsService;
  public readonly associations: AssociationsService;
  public readonly authorization: AuthorizationService;
  public readonly healthCheck: HealthCheckService;
  public readonly memberInformations: MemberInformationsService;
  public readonly reports: ReportsService;
  public readonly simulation: SimulationService;
  public readonly users: UsersService;

  public readonly request: BaseHttpRequest;

  constructor(
    config?: Partial<OpenAPIConfig>,
    HttpRequest: HttpRequestConstructor = AxiosHttpRequest
  ) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? 'http://localhost:8092',
      VERSION: config?.VERSION ?? '1.0.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.applications = new ApplicationsService(this.request);
    this.associations = new AssociationsService(this.request);
    this.authorization = new AuthorizationService(this.request);
    this.healthCheck = new HealthCheckService(this.request);
    this.memberInformations = new MemberInformationsService(this.request);
    this.reports = new ReportsService(this.request);
    this.simulation = new SimulationService(this.request);
    this.users = new UsersService(this.request);
  }
}
