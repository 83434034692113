import { FC, useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { HomeHeader } from '@/components/organism/HomeHeader';
import { BREAKPOINT } from '@/theme/theme';
import { Path } from '@/constants/Router/path';
import { appClient } from '@/services';
import useSWR from 'swr';
import { ConvertAssociation } from '@/services/convert/association';
import { Association } from '@/types/api/association';
import { ConvertAssociationContact } from '@/services/convert/associationContact';
import { AssociationContact } from '@/types/api/associationContact';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { Contacts } from '../../organism/Contacts/Contact/Contacts';
import { ContactDescription } from './ContactDescription';
import { ReactComponent as QuestionMark } from '../../../assets/question.svg';

const TopPageWrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '100%',
    padding: '33px 40px 32px',
    [breakpoints.down(BREAKPOINT)]: {
      padding: '24px 0px',
    },
    [breakpoints.up('lg')]: {
      padding: '33px 130px 32px',
    },
  };
});

const HomeHeaders = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    [breakpoints.down(BREAKPOINT)]: {
      padding: '0 16px',
    },
  };
});

const BottomPageWrapper = styled('div')(({ theme }) => {
  const { breakpoints, palette } = theme;
  return {
    backgroundColor: palette.system.background,
    width: '100%',
    padding: '32px 40px 80px',
    [breakpoints.down(BREAKPOINT)]: {
      padding: '24px 0 60px',
    },
    [breakpoints.up('lg')]: {
      padding: '32px 130px 80px',
    },
  };
});

const SubTitle = styled('h5')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography.h5,
    margin: '0px',
  };
});

const QuestionWrap = styled('div')({
  padding: '10px 24px',
  backgroundColor: '#FFFFFF',
  borderRadius: '22px',
  display: 'flex',
  gap: '8px',
  marginBottom: '8px',
});

const WellVisitedFAQs = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '100%',
    padding: '24px 32px 18px 32px',
    backgroundColor: '#F7F6F6',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    [breakpoints.down(BREAKPOINT)]: {
      padding: '24px 16px 24px',
      borderRadius: '0px',
      whiteSpace: 'nowrap',
      overflowX: 'scroll',
    },
  };
});

const QuestionsWrap = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
    [breakpoints.down(BREAKPOINT)]: {
      flexWrap: 'nowrap',
    },
  };
});

const Question = styled('span')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-main/regular'],
  };
});

const pcQuestions = [
  '口数は何口まで申請できますか？',
  '引出とはどういう意味ですか？',
  '休止を希望しています。どうすればいいですか？',
  '他の証券会社に口座があるので、そちらの口座に引出を希望しますので手続き方法を教えてください。',
  '口座は何日で開設できますか？',
  'パスワードを変更したい。',
  '住所を変更したい。',
];

export const Contact: FC = () => {
  useHelmetHandler({
    title: 'お問い合わせ',
  });
  const [association, setAssociation] = useState<Association>();
  const [associationContact, setAssociationContact] =
    useState<AssociationContact>();

  // 【API-A-0909】資産情報取得
  // const { user } = useAuthUser();

  // 【API-A-1001】持株会（事務局）情報
  const { data: findAssociationById } = useSWR(
    '/api/associations/{associationCd}',
    () => appClient.associations.findAssociationById()
  );

  // 【API-A-1004】持株会（事務局）問い合わせ情報取得
  const { data: findAssociationContact } = useSWR(
    '/api/associations/contacts',
    () => appClient.associations.findAssociationContact()
  );

  useEffect(() => {
    console.log('findAssociationContact:', findAssociationContact);
    setAssociationContact(ConvertAssociationContact(findAssociationContact));
  }, [findAssociationContact]);

  useEffect(() => {
    setAssociation(ConvertAssociation(findAssociationById));
  }, [findAssociationById]);

  if (association) {
    return (
      <>
        <TopPageWrapper>
          <HomeHeaders>
            <HomeHeader
              title="お問い合わせ"
              links={[
                { path: 'TOP', to: Path.HOME },
                { path: 'お問い合わせ', to: Path.CONTACT },
              ]}
            />
          </HomeHeaders>
          <ContactDescription />
          <WellVisitedFAQs>
            <SubTitle>閲覧数の多いFAQ</SubTitle>
            <QuestionsWrap>
              {pcQuestions.map((q) => (
                <QuestionWrap key={q}>
                  <QuestionMark />
                  <Question>{q}</Question>
                </QuestionWrap>
              ))}
            </QuestionsWrap>
          </WellVisitedFAQs>
        </TopPageWrapper>
        <BottomPageWrapper>
          <Contacts
            association={association}
            associationContact={associationContact}
          />
        </BottomPageWrapper>
      </>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
