/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  PrimaryTableBodyTdMulti,
  PrimaryTableBodyTdSub,
} from '@/components/atoms/Table/PrimaryTableBodyTdMulti';
import { PrimaryTableBodyTdSingle } from '@/components/atoms/Table/PrimaryTableBodyTdSingle';
import { TextWithFlowIcon } from '@/components/molecules/Flow/TextWithFlowIcon';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { PrimaryTable } from '@/components/molecules/Table/PrimaryTable';
import { UserInfo } from '@/components/molecules/Table/UserInfo';
import { HomeHeader } from '@/components/organism/HomeHeader';
import { styled, Typography } from '@mui/material';
import { FC } from 'react';
import { WarmingDeadLine } from '@/components/organism/Apply/WarmingDeadLine';
import { BREAKPOINT } from '@/theme/theme';
import { PrimaryTableBodyTdSingleWithSpNotRed } from '@/components/atoms/Table/PrimaryTableBodyTdSingleWithSpNotRed';
import { PrimaryAndSecondaryButtons } from '@/components/molecules/Buttons/PrimaryAndSecondaryButtons';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { Path } from '@/constants/Router/path';
import { Eventsmemberdeadlines } from '@/types/api/eventsmemberdeadlines';
import { useLocation, useNavigate } from 'react-router-dom';
import { Association } from '@/types/api/association';
import { AssociationContribution } from '@/types/api/associationContribution';
import { User } from '@/types/api/user';
import { UserContribution } from '@/types/api/userContribution';
import { numberFormat } from '@/utils/numberFormat';
import { useSnackbarMessage } from '@/hooks';
import { appClient } from '@/services';
import { Associations } from '@/types/api/associations';
import { ErrorResponse } from '@/types/api/error';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const Wrraper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    marginBottom: '80px',
    padding: '14px 0',
    [breakpoints.up(BREAKPOINT)]: {
      padding: '14px 40px',
    },
    [breakpoints.up('lg')]: {
      padding: '14px 130px',
    },
  };
});

const ContentWrraper = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
});

const Header = styled('div')({ position: 'relative', width: '100%' });

// パンクズ
const links = [
  {
    path: 'TOP',
    to: Path.HOME,
  },
  {
    path: '口数を変更する (賞与)',
    to: Path.BONUS,
  },
];

type LocationState = {
  user: User;
  association: Association;
  associationContribution: AssociationContribution;
  userContribution: UserContribution;
  eventsmemberdeadlines: Eventsmemberdeadlines;
  bonusRate: number;
  isBonus: boolean;
  bonusUnit: number;
  editUnitAmount: number;
  monthlyIncentive: number;
  bonusMultiplierDesignationType:
    | 'UNIT_NUMBER'
    | 'MAGNIFICATION'
    | 'FIXED_MAGNIFICATION';
};

export const BonusConfirm: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { showMessage } = useSnackbarMessage();
  const state = location.state as LocationState;
  useHelmetHandler({
    title: '口数変更(賞与) 入力内容確認',
  });

  const goNext = async () => {
    try {
      const body = {
        applicationDate: new Date().toString(),
        applicationType: 'BONUS_UNIT' as Associations['applicationType'],
        applicationBonusUnit: {
          isBonusUnit: state.isBonus,
          bonusUnit: Number(state.bonusUnit),
          bonusRate: state.bonusRate,
        },
      };

      const response = await appClient.applications.createApplication(body);

      if (response) {
        navigate(Path.BONUS_COMPLETE, {
          state: {
            applicationType: response.applicationType,
            applicationId: response.applicationId,
            applicationDate: response.applicationDate,
          },
        });
      }
    } catch (e) {
      showMessage({
        open: true,
        messageType: 'error',
        messages: [(e as ErrorResponse).data.message],
      });
    }
  };

  const navigateToPrint = () => {
    navigate(`/print`, {
      state: {
        ...state,
        title: '口数を変更する (賞与)',
        contentTitle: '新たな口数設定',
        type: 'BONUS',
      },
    });
  };

  return (
    <Wrraper>
      <Header>
        <HomeHeader
          links={links}
          title="口数を変更する (賞与)"
          subTitle="入力内容確認"
          sx={{ width: '100%' }}
          printOnClick={() => navigateToPrint()}
        />
      </Header>
      <TextWithFlowIcon flowOn={1} widthFull />
      <ContentWrraper>
        <WarmingDeadLine
          eventsmemberdeadlines={state.eventsmemberdeadlines}
          target="BONUS_UNIT"
        />
        <UserInfo
          mochikabukaiCode={state.user.associationCd}
          mochikabukaiName={state.association.associationName}
          nameKanji={state.user.nameKanji}
          nameKana={state.user.nameKana}
          zipcode={state.user.zipcode}
          address1={state.user.address1}
          address2={state.user.address2}
          address3={state.user.address3}
          tel={state.user.tel}
          employeeCd={state.user.employeeCd}
          editable
        />
        <BasicTable
          boxSx={{ width: '100%' }}
          title="現在の口数設定"
          type="contribution"
          unitAmount={state.associationContribution.unitAmount}
          monthlyUnit={state.userContribution.monthlyUnit}
          bonusUnit={state.userContribution.bonusUnit}
          investmentDigit={state.association.investmentDigit}
        />
        <PrimaryTable title="新たな口数設定">
          <PrimaryTableBodyTdSingleWithSpNotRed
            title="1口あたり金額"
            value={`${numberFormat(
              state.associationContribution.unitAmount
            )}円`}
            isRegular
          />
          <PrimaryTableBodyTdSingleWithSpNotRed
            title="奨励金率"
            value={`${state.associationContribution.monthlyIncentiveRatio}%`}
            isRegular
          />
          <PrimaryTableBodyTdMulti title="賞与" row={4}>
            <PrimaryTableBodyTdSub title="拠出金額">
              <Typography variant="body-main/regular">
                {state.isBonus ? 'する' : 'しない'}
              </Typography>
            </PrimaryTableBodyTdSub>
          </PrimaryTableBodyTdMulti>
          {state.isBonus && (
            <>
              <PrimaryTableBodyTdSingle>
                <PrimaryTableBodyTdSub
                  title={
                    state.bonusMultiplierDesignationType === 'UNIT_NUMBER'
                      ? '口数'
                      : '倍率'
                  }
                >
                  <Typography variant="body-main/regular">
                    {state.bonusMultiplierDesignationType === 'UNIT_NUMBER'
                      ? state.bonusUnit
                      : state.bonusRate}
                    {state.bonusMultiplierDesignationType === 'UNIT_NUMBER'
                      ? '口'
                      : '倍'}
                  </Typography>
                </PrimaryTableBodyTdSub>
              </PrimaryTableBodyTdSingle>
              <PrimaryTableBodyTdSingle>
                <PrimaryTableBodyTdSub title="拠出金額">
                  <Typography variant="body-main/regular">
                    {numberFormat(state.editUnitAmount)}円
                  </Typography>
                </PrimaryTableBodyTdSub>
              </PrimaryTableBodyTdSingle>
              <PrimaryTableBodyTdSingle>
                <PrimaryTableBodyTdSub title="奨励金額">
                  <Typography variant="body-main/regular">
                    {state.associationContribution.bonusIncentiveRatio === 0 &&
                    state.associationContribution.bonusIncentiveFixedAmount !==
                      0
                      ? numberFormat(
                          state.associationContribution
                            .bonusIncentiveFixedAmount
                        )
                      : numberFormat(
                          state.monthlyIncentive,
                          {
                            maximumFractionDigits: 0,
                          },
                          true
                        )}
                    円
                  </Typography>
                </PrimaryTableBodyTdSub>
              </PrimaryTableBodyTdSingle>
            </>
          )}
        </PrimaryTable>
        <PrimaryAndSecondaryButtons>
          <SecondaryButton
            sx={{
              display: 'flex',
              margin: 'auto',
              borderRadius: '28px',
              padding: '16px 24px',
            }}
            onClick={() =>
              navigate(Path.BONUS, {
                state: { isBonus: state.isBonus, bonusUnit: state.bonusUnit },
              })
            }
          >
            入力へ戻る
          </SecondaryButton>
          <PrimaryButton
            sx={{
              borderRadius: '28px',
              padding: '16px 24px',
            }}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={() => goNext()}
          >
            申請する
          </PrimaryButton>
        </PrimaryAndSecondaryButtons>
      </ContentWrraper>
    </Wrraper>
  );
};
