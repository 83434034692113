import { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { MaskoutUserProvider } from './hooks/useMaskoutUser';

// MaskoutはMypageのみで使用する
export const MyPage: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MaskoutUserProvider>
      <Outlet />
    </MaskoutUserProvider>
  );
};
