/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MemberInformationsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * 【API-A-1101】持株会員お知らせ一覧取得
   * 持株会員お知らせ一覧取得
   *
   * @returns any memberInformations response
   * @throws ApiError
   */
  public listMemberInformations(): CancelablePromise<{
    memberInformations?: Array<{
      /**
       * お知らせID
       */
      memberInformationId?: number;
      /**
       * 持株会コード
       */
      associationCd?: string;
      /**
       * 表示期間From
       */
      displayPeriodFrom?: string;
      /**
       * 表示期間To
       */
      displayPeriodTo?: string;
      /**
       * お知らせタイトル
       */
      informationTitle?: string;
      /**
       * お知らせ本文
       */
      informationBody?: string;
      /**
       * 重要度
       * - LOW : 低
       * - MIDDLE : 中
       * - HIGH : 高
       *
       */
      priority?: 'LOW' | 'MIDDLE' | 'HIGH';
      /**
       * お知らせステータス
       * - NORMAL : 正常
       * - DROP : 取り下げ
       *
       */
      situationClassification?: 'NORMAL' | 'DROP';
    }>;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/memberinformations',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * @deprecated
   * 【API-A-1102】持株会員お知らせ取得
   * 持株会員お知らせを取得する
   *
   * @param memberInformationId ID of info to fetch
   * @param fields 取得field \
   * fields1,fields2,fields3
   *
   * @returns any memberInformation response
   * @throws ApiError
   */
  public findMemberInformationById(
    memberInformationId: number,
    fields?: string
  ): CancelablePromise<{
    /**
     * お知らせID
     */
    memberInformationId?: number;
    /**
     * 持株会コード
     */
    associationCd?: string;
    /**
     * 表示期間From
     */
    displayPeriodFrom?: string;
    /**
     * 表示期間To
     */
    displayPeriodTo?: string;
    /**
     * お知らせタイトル
     */
    informationTitle?: string;
    /**
     * お知らせ本文
     */
    informationBody?: string;
    /**
     * 重要度
     * - LOW : 低
     * - MIDDLE : 中
     * - HIGH : 高
     *
     */
    priority?: 'LOW' | 'MIDDLE' | 'HIGH';
    /**
     * お知らせステータス
     * - NORMAL : 正常
     * - DROP : 取り下げ
     *
     */
    situationClassification?: 'NORMAL' | 'DROP';
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/memberinformations/{memberInformationId}',
      path: {
        memberInformationId: memberInformationId,
      },
      query: {
        fields: fields,
      },
      errors: {
        400: `bad request`,
      },
    });
  }
}
