import { BREAKPOINT, theme } from '@/theme/theme';
import { FindUser } from '@/types/api/users';
import { Box, Typography, styled } from '@mui/material';
import { MemberInfoGroup } from './MemberInfoGroup';

const Wrapper = styled(Box)(() => {
  const { breakpoints } = theme;
  return {
    margin: '0 auto',
    maxWidth: '100%',
    [breakpoints.up(BREAKPOINT)]: {
      padding: '0 40px',
    },
    [breakpoints.up('lg')]: {
      padding: '0 130px',
    },
  };
});

const TitleWrapper = styled(Box)(() => {
  const { breakpoints } = theme;
  return {
    [breakpoints.down('sm')]: {
      padding: '0 16px',
    },
  };
});

const Title = styled(Typography)(() => {
  const { typography, palette } = theme;

  return {
    ...typography.h5,
    color: palette.system['text-normal'],
  };
});

const MemberInfoBoard = styled(Box)(() => {
  const { breakpoints, palette } = theme;

  return {
    marginTop: 16,
    backgroundColor: palette.system.background,
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'row',
    padding: '8px 16px',

    [breakpoints.down('sm')]: {
      borderRadius: 0,
      flexDirection: 'column',
    },
  };
});

export const MemberInfo = ({
  user,
  associationName,
}: {
  user: FindUser;
  associationName: string;
}) => (
  <Wrapper>
    <TitleWrapper>
      <Title>会員情報</Title>
    </TitleWrapper>
    <MemberInfoBoard>
      <MemberInfoGroup
        data={[
          ['氏名（漢字）', user.nameKanji ?? ''],
          ['持株会コード', user.associationCd ?? ''],
        ]}
      />
      <MemberInfoGroup
        data={[
          ['会員コード', user.memberCd ?? ''],
          ['持株会名', associationName],
        ]}
      />
    </MemberInfoBoard>
  </Wrapper>
);
