import type { UserAsset } from '@/types/api/userAsset';
import type { Application } from '@/types/api/application';

export const userAssetModel = (data: {
  stocksNumber?: number;
  marketValue?: number;
  acquisitionAmount?: number;
  valuationGainLoss?: number;
  complementaryClosingPrice?: number;
  oldStock?: number;
  firstNewStock?: number;
  secondNewStock?: number;
  carriedForwardAmount?: number;
  cumulativeDeposit?: number;
  cumulativeContribution?: number;
  cumulativeIncentives?: number;
  cumulativeDividend?: number;
  cumulativeInterest?: number;
  cumulativeOtherDeposits?: number;
  cumulativeWithdrawal?: number;
  cumulativeOfficeExpenses?: number;
  withdrawSharesCnt?: number;
  purchaseAvarageUnitPrice?: number;
  lastUpdated?: string;
  targetDate?: string;
}) =>
  ({
    stocksNumber: data?.stocksNumber || 0,
    marketValue: data?.marketValue || 0,
    acquisitionAmount: data?.acquisitionAmount || 0,
    valuationGainLoss: data?.valuationGainLoss || 0,
    complementaryClosingPrice: data?.complementaryClosingPrice || '',
    oldStock: data?.oldStock || 0,
    firstNewStock: data?.firstNewStock || 0,
    secondNewStock: data?.secondNewStock || 0,
    carriedForwardAmount: data?.carriedForwardAmount || 0,
    cumulativeDeposit: data?.cumulativeDeposit || 0,
    cumulativeContribution: data?.cumulativeContribution || 0,
    cumulativeIncentives: data?.cumulativeIncentives || 0,
    cumulativeDividend: data?.cumulativeDividend || 0,
    cumulativeInterest: data?.cumulativeInterest || 0,
    cumulativeOtherDeposits: data?.cumulativeOtherDeposits || 0,
    cumulativeWithdrawal: data?.cumulativeWithdrawal || 0,
    cumulativeOfficeExpenses: data?.cumulativeOfficeExpenses || 0,
    withdrawSharesCnt: data?.withdrawSharesCnt || 0,
    purchaseAvarageUnitPrice: data?.purchaseAvarageUnitPrice || 0,
    lastUpdated: data?.lastUpdated || '',
    targetDate: data?.targetDate || '',
  } as UserAsset);

export const applicationModel = (data?: {
  /**
   * 申請書ID
   */
  applicationId?: number;
  /**
   * メンバーID
   */
  memberId?: number;
  /**
   * 持株会コード
   */
  associationCd?: string;
  /**
   * イベント№
   */
  eventNo?: string;
  /**
   * 申請種別
   * - MONTHLY_UNIT: 口数変更(月例)
   * - BONUS_UNIT: 口数変更(賞与)
   * - PART: 一部引出
   * - RECESS: 休会
   * - RESUME: 再開
   * - WITHDRAWAL: 退会
   * - ADMISSION: 新規会員登録
   * - USER: 会員情報変更
   *
   */
  applicationType?:
    | 'MONTHLY_UNIT'
    | 'BONUS_UNIT'
    | 'PART'
    | 'RECESS'
    | 'RESUME'
    | 'WITHDRAWAL'
    | 'ADMISSION'
    | 'USER';
  /**
   * 申請日付
   */
  applicationDate?: string;
  /**
   * 申請ステータス
   * - PREPARATION: 入力中
   * - APPLYING: 申請中
   * - OFFICER_APPROVED: 事務局承認済
   * - BACKOFFICER_APPROVED: 制度G承認済（STARⅣ処理中）
   * - COMPLETED: 完了（システム反映済み）
   * - OFFICER_REJECTED: 却下（事務局）
   * - BACKOFFICER_REJECTED: 却下（制度G）
   * - CANCEL: 申請取り消し（会員）
   *
   */
  applicationStatus?:
    | 'PREPARATION'
    | 'APPLYING'
    | 'OFFICER_APPROVED'
    | 'BACKOFFICER_APPROVED'
    | 'COMPLETED'
    | 'OFFICER_REJECTED'
    | 'BACKOFFICER_REJECTED'
    | 'CANCEL';
  /**
   * 却下コメント
   */
  rejectComment?: string;
  /**
   * 承認・却下者名
   */
  judgeUserName?: string;
  /**
   * 代理申請者名
   */
  proxyApplicantUserName?: string;
  /**
   * 事務局修正者名
   */
  officeUpdateUserName?: string;
  /**
   * 持株制度G修正者名
   */
  backofficeUpdateUserName?: string;
}) =>
  data
    ? ({
        applicationId: data.applicationId || 0,
        memberId: data?.memberId || 0,
        associationCd: data?.associationCd || '',
        eventNo: data?.eventNo || '',
        applicationType: data?.applicationType || 'USER',
        applicationDate: data?.applicationDate || '',
        applicationStatus: data?.applicationStatus || 'CANCEL',
        rejectComment: data?.rejectComment || '',
        judgeUserName: data?.judgeUserName || '',
        proxyApplicantUserName: data?.proxyApplicantUserName || '',
        officeUpdateUserName: data?.officeUpdateUserName || '',
        backofficeUpdateUserName: data?.backofficeUpdateUserName || '',
      } as Application)
    : undefined;

export type UserSecuritiesAccounts = {
  /** @description 部店コード */
  branchCode: string;
  /** @description 部店名 */
  branchName: string;
  /** @description 口座番号 */
  accountNumber: string;
  /** @description 確認 */
  fixedFlg: boolean;
};

/**
 * userSecuritiesAccountsModel
 * @param data
 */
export const userSecuritiesAccountsModel = (data: {
  branchCd?: string;
  branchName?: string;
  accountNumber?: string;
}) =>
  ({
    branchCode: data.branchCd || '',
    branchName: data.branchName || '',
    accountNumber: data.accountNumber || '',
  } as UserSecuritiesAccounts);
