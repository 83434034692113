import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignUpUserCompleteOrganism } from '@/components/organism/SignUp/SignUpUserComplete';
import { Path } from '@/constants/Router/path';

export const SignUpUserComplete: FC = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(Path.CONTRIBUTION_INPUT);
  };
  return <SignUpUserCompleteOrganism handleClick={handleClick} />;
};
