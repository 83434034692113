/* eslint-disable  */
import { User } from '@/types/api/user';
import { Box, styled, Theme, Typography, useMediaQuery } from '@mui/material';
import { FC, ReactNode } from 'react';
import { labels } from '@/constants/UserInfo/labels';
import { TextButton } from '@/components/atoms/Buttons/TextButton';
import { Secretariats } from '@/types/api/secretariats';
import { BREAKPOINT, theme } from '@/theme/theme';
import { useNavigate } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

interface UserInfoProps
  extends Pick<Secretariats, 'mochikabukaiCode' | 'mochikabukaiName'>,
    Pick<
      User,
      | 'nameKanji'
      | 'nameKana'
      | 'zipcode'
      | 'address1'
      | 'address2'
      | 'address3'
      | 'tel'
      | 'employeeCd'
    > {
  editable?: boolean;
}

const Wrraper = styled('div')({
  width: '100%',
});

const MuiBox = styled(Box)(() => {
  const { palette, breakpoints } = theme;
  return {
    minWidth: '375px',
    background: palette.system.background,
    borderRadius: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '24px 16px',
    gap: '16px',
    [breakpoints.up(BREAKPOINT)]: {
      padding: '16px',
      borderRadius: '12px',
    },
  };
});

const MuiHeader = styled(Box)(() => {
  const { palette } = theme;
  return {
    width: '100%',
    height: '37px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${palette.system.separator || '#00000033'}`,
  };
});

const MuiBody = styled(Box)(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.up(BREAKPOINT)]: {
      flexDirection: 'row',
      gap: '16px',
    },
  };
});

const InsiderText = styled(Typography)(() => ({
  ...theme.typography['body-sub/regular'],
  color: theme.palette.system['text-light'],
  marginTop: 16,
}));

const Header: FC<Props & Pick<UserInfoProps, 'editable'>> = ({
  children,
  editable,
}) => {
  const navigate = useNavigate();
  return (
    <MuiHeader>
      <Typography variant="h5" sx={{ color: `system.text-light` }}>
        {children}
      </Typography>
      {editable && (
        <TextButton onClick={() => navigate('/mypage/auth?next=user')}>
          会員情報を変更する
        </TextButton>
      )}
    </MuiHeader>
  );
};

const DlContainer: FC<Props & { isPc: boolean }> = ({ isPc, children }) => (
  <Box
    sx={{
      maxWidth: isPc ? '392px' : '100%',
      minHeight: '34px',
      flexGrow: 1,
    }}
  >
    {children}
  </Box>
);

const Dl: FC<Props & { underline: boolean }> = ({ underline, children }) => (
  <Box
    component="dl"
    sx={{
      width: '100%',
      minWidth: '261px',
      display: 'flex',
      borderBottom: underline ? 1 : 0,
      borderColor: 'system.separator-light',
    }}
  >
    {children}
  </Box>
);

const Dt: FC<Props> = ({ children }) => (
  <Typography
    component="dt"
    variant="body-sub/regular"
    sx={{ color: 'system.text-light', minWidth: '104px' }}
  >
    {children}
  </Typography>
);

const Dd: FC<Props> = ({ children }) => (
  <Typography
    component="dd"
    variant="body-sub/regular"
    sx={{ color: 'system.text-normal' }}
  >
    {children}
  </Typography>
);

export const UserInfo: FC<UserInfoProps> = (props) => {
  const {
    mochikabukaiCode,
    mochikabukaiName,
    nameKanji,
    nameKana,
    zipcode,
    address1,
    address2,
    address3,
    tel,
    employeeCd,
    editable,
  } = props;
  const isPc = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(BREAKPOINT)
  );

  return (
    <Wrraper>
      <MuiBox sx={{ marginBottom: '16px' }}>
        <Header editable={false}>持株会情報</Header>
        <MuiBody>
          <DlContainer isPc={isPc}>
            <Dl underline={!isPc}>
              <Dt>{labels.mochikabukaiCode}</Dt>
              <Dd>{mochikabukaiCode}</Dd>
            </Dl>
          </DlContainer>
          <DlContainer isPc={isPc}>
            <Dl underline={false}>
              <Dt>{labels.mochikabukaiName}</Dt>
              <Dd>{mochikabukaiName}</Dd>
            </Dl>
          </DlContainer>
        </MuiBody>
      </MuiBox>
      <MuiBox>
        <Header editable={editable}>会員情報</Header>
        <MuiBody>
          <DlContainer isPc={isPc}>
            <Dl underline>
              <Dt>{labels.fullName}</Dt>
              <Dd>{`${nameKanji}`}</Dd>
            </Dl>
            <Dl underline>
              <Dt>{labels.fullNameKane}</Dt>
              <Dd>{`${nameKana}`}</Dd>
            </Dl>
            <Dl underline={!isPc}>
              <Dt>{labels.address}</Dt>
              <Dd>
                <Typography
                  component="p"
                  variant="body-sub/regular"
                  sx={{ color: 'system.text-normal' }}
                >{`〒${zipcode}`}</Typography>{' '}
                <Typography
                  component="p"
                  variant="body-sub/regular"
                  sx={{ color: 'system.text-normal' }}
                >{`${address1}${address2}${address3}`}</Typography>
              </Dd>
            </Dl>
          </DlContainer>
          <DlContainer isPc={isPc}>
            <Dl underline>
              <Dt>{labels.tel}</Dt>
              <Dd>{tel}</Dd>
            </Dl>
            <Dl underline={false}>
              <Dt>{labels.employeeCode}</Dt>
              <Dd>{employeeCd}</Dd>
            </Dl>
          </DlContainer>
        </MuiBody>
      </MuiBox>
      <InsiderText>
        本申請はインサイダー情報（未公表の重要事実）を知りながら行うものではありません。
      </InsiderText>
    </Wrraper>
  );
};
