import { styled, useMediaQuery, Theme } from '@mui/material';
import { FC, useState } from 'react';
import { BREAKPOINT } from '@/theme/theme';
import { Application } from '@/types/api/application';
import { useNavigate } from 'react-router-dom';
import { Path } from '@/constants/Router/path';
import { useRecoilState } from 'recoil';
import {
  ApplicationSortBy,
  ApplicationSortKeyState,
} from '@/recoil/atom/application';
import { Paginate } from './Pagination';
import { ApplicationColumn } from './ApplicationColumn';

const Wrapper = styled('div')({
  width: '100%',
});

const TableWrapper = styled('div')(({ theme }) => {
  const { breakpoints, palette } = theme;
  return {
    width: '100%',
    backgroundColor: palette.system.white,
    padding: '32px 60px',
    [breakpoints.down(BREAKPOINT)]: {
      padding: '0px',
    },
    [breakpoints.up('lg')]: {
      padding: '32px 230px',
    },
  };
});

const Table = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '100%',
    border: 'solid 1px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down(BREAKPOINT)]: {
      border: 0,
    },
  };
});

const TableHeader = styled('div')(({ theme }) => {
  const { palette } = theme;
  return {
    color: palette.system['text-light'],
    padding: '16px 24px',
    display: 'flex',
    gap: '24px',
    alignItems: 'center',
  };
});

const Id = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '14%',
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
    },

    '&:hover': {
      cursor: 'pointer',
    },
  };
});

const Type = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '54%',
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
    },

    '&:hover': {
      cursor: 'pointer',
    },
  };
});

const Date = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '25%',
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
    },

    '&:hover': {
      cursor: 'pointer',
    },
  };
});

const Status = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    [breakpoints.up(BREAKPOINT)]: { width: '7%' },

    '&:hover': {
      cursor: 'pointer',
    },
  };
});

const PaginateWrapper = styled('div')(({ theme }) => {
  const { breakpoints, palette } = theme;
  return {
    width: '100%',
    paddingBottom: '33px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: palette.system.white,
    gap: '8px',
    alignItems: 'center',
    [breakpoints.down(BREAKPOINT)]: {
      padding: '32px 60px',
      gap: '11px',
      backgroundColor: palette.system.background,
      flexDirection: 'column',
    },
  };
});

type Props = {
  applications: Application[];
};

export const ApplicationTable: FC<Props> = ({ applications }) => {
  const navigate = useNavigate();
  const [sortKey, setSortKey] = useRecoilState<ApplicationSortBy>(
    ApplicationSortKeyState
  );
  const isSp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(BREAKPOINT)
  );
  const [startPage, setStartPage] = useState<number>(1);
  const handleChange = (value: number) => {
    setStartPage(value);
  };

  return (
    <Wrapper>
      <TableWrapper>
        <Table>
          {!isSp && (
            <TableHeader>
              <Id
                onClick={() => {
                  setSortKey(
                    sortKey === 'APPLICATION_ID_DESC'
                      ? 'APPLICATION_ID_ASC'
                      : 'APPLICATION_ID_DESC'
                  );
                }}
              >
                申請ID
              </Id>
              <Type
                onClick={() => {
                  setSortKey(
                    sortKey === 'APPLICATION_TYPE_DESC'
                      ? 'APPLICATION_TYPE_ASC'
                      : 'APPLICATION_TYPE_DESC'
                  );
                }}
              >
                申請分類
              </Type>
              <Date
                onClick={() => {
                  setSortKey(
                    sortKey === 'APPLICATION_DATE_DESC'
                      ? 'APPLICATION_DATE_ASC'
                      : 'APPLICATION_DATE_DESC'
                  );
                }}
              >
                申請年月日
              </Date>
              <Status
                onClick={() => {
                  setSortKey(
                    sortKey === 'APPLICATION_STATUS_DESC'
                      ? 'APPLICATION_STATUS_ASC'
                      : 'APPLICATION_STATUS_DESC'
                  );
                }}
              >
                状況
              </Status>
            </TableHeader>
          )}
          {applications.length > 10
            ? applications
                .slice(startPage * 10 - 9, startPage * 10 + 1)
                .map((apply) => (
                  <ApplicationColumn
                    key={apply.applicationId}
                    id={String(apply.applicationId)}
                    applicationType={apply.applicationType}
                    applicationDate={apply.applicationDate}
                    applicationStatus={apply.applicationStatus}
                    onClick={() =>
                      navigate(`${Path.APPLICATIONS}/${apply.applicationId}`)
                    }
                  />
                ))
            : applications.map((apply) => (
                <ApplicationColumn
                  key={apply.applicationId}
                  id={String(apply.applicationId)}
                  applicationType={apply.applicationType}
                  applicationDate={apply.applicationDate}
                  applicationStatus={apply.applicationStatus}
                  onClick={() =>
                    navigate(`${Path.APPLICATIONS}/${apply.applicationId}`)
                  }
                />
              ))}
        </Table>
      </TableWrapper>
      <PaginateWrapper>
        <Paginate
          startPage={startPage}
          allRecords={applications.length}
          handleChange={handleChange}
        />
      </PaginateWrapper>
    </Wrapper>
  );
};
