import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { CSSProperties, FC, useState, useEffect } from 'react';
import { BREAKPOINT, theme } from '@/theme/theme';
import { ReactComponent as Icon } from '@/assets/exclamation.svg';

import { appClient } from '@/services';
import { ConvertEventsmemberdeadlines } from '@/services/convert/eventsmemberdeadlines';
import { findDeadLineService } from '@/services/findDeadLineService';
import { SignUpContributionProps as Props } from '@/types/components/SignUpContribution';
import { useLocation } from 'react-router-dom';
import { AssociationContribution } from '@/types/api/associationContribution';
import { Association } from '@/types/api/association';
import { Eventsmemberdeadlines } from '@/types/api/eventsmemberdeadlines';
import useSWR from 'swr';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { SignUpUpper } from './SignUpUpper';

const Title: FC = () => {
  const [eventsmemberdeadlines, setEventsmemberdeadlines] =
    useState<Eventsmemberdeadlines>();

  // 【API-A-1001】持株会（事務局）情報
  const { data: findAssociationEventMemberDeadlines } = useSWR(
    '/api/associations/{associationCd}/eventsmemberdeadlines',
    () => appClient.associations.findAssociationEventMemberDeadlines()
  );
  useEffect(() => {
    setEventsmemberdeadlines(
      ConvertEventsmemberdeadlines(
        findAssociationEventMemberDeadlines?.eventmemberdeadlines
      )
    );
  }, [findAssociationEventMemberDeadlines]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '16px',
        mb: '16px',
      }}
    >
      <Typography
        variant="h4"
        sx={{
          width: '100%',
          color: 'system.text-normal',
          pb: '16px',
          borderBottom: '1px solid',
          borderColor: 'system.separator-light',
        }}
      >
        拠出設定が完了しました
      </Typography>
      {eventsmemberdeadlines && (
        <Typography
          variant="body-main/regular"
          sx={{ color: 'system.text-normal' }}
        >
          入会処理は
          {findDeadLineService({
            deadlines: eventsmemberdeadlines,
            target: 'ADMISSION',
            getMonth: true,
          })}
          以降に行われますが、現時点でもNexStocksへログインいただけます。(一部機能が使えません)
        </Typography>
      )}
    </Box>
  );
};

const Container: FC<{ borderRadius: CSSProperties } & Props> = ({
  isPc,
  borderRadius,
  children,
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      p: isPc ? '32px' : '32px 16px',
      gap: '16px',
      borderRadius: isPc ? borderRadius : '0',
      bgcolor: 'system.white',
    }}
  >
    {children}
  </Box>
);

const Wrapper: FC<{ onClick: () => void } & Props> = ({ isPc, onClick }) => (
  <Box
    sx={{
      maxWidth: '780px',
      m: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      gap: isPc ? '32px' : '24px',
    }}
  >
    <Box>
      <SignUpUpper isSignUp flowOn={3} />
      <Container isPc={isPc} borderRadius={{ borderRadius: '0 0 12px 12px' }}>
        <Title />
        <PrimaryButton
          sx={{
            width: isPc ? '184px' : '100%',
            height: isPc ? '40px' : '56px',
            padding: isPc ? '8px 20px' : '20px, 24px, 20px, 26px',
            borderRadius: isPc ? '20px' : '28px',
          }}
          onClick={onClick}
        >
          ログイン画面へ移動
        </PrimaryButton>
      </Container>
    </Box>
    <Container isPc={isPc} borderRadius={{ borderRadius: '12px' }}>
      <Typography
        variant="h5"
        sx={{
          color: 'system.text-normal',
          pl: '12px',
          mb: isPc ? '24px' : '16px',
          boxShadow: 'inset 2px 0px 0px #FC5145',
        }}
      >
        証券口座が必要になります！
      </Typography>
      {/* <Link
        href={Path.ACCOUNTING_OPEN}
        rel="noreferrer noopener"
        target="_blank"
      >
        <Image />
      </Link> */}
      <Typography
        variant="body-main/medium"
        sx={{ color: 'system.text-normal', mt: isPc ? '0' : '8px' }}
      >
        証券口座は退会時に100株以上保有している場合や、株式を一部引出する際に必須となります。
        <br />
        口座の開設に時間がかかる場合がございますので、お早めの口座開設をお願い致します。
      </Typography>
      <Box sx={{ mt: isPc ? '24px' : '16px' }}>
        <Box
          sx={{ width: '28px', height: isPc ? '72px' : '20px', float: 'left' }}
        >
          <Icon />
        </Box>
        <Typography
          variant="body-main/regular"
          sx={{ color: 'system.text-normal' }}
        >
          口座の開設が完了しましたら、Nexstocksのマイページより口座情報を登録ください。退会や一部引出の申し込みの際に、100株以上保有している場合は初回のみ口座情報の入力が必須となります。
        </Typography>
      </Box>
    </Container>
  </Box>
);

type LocationState = {
  unitAmount: number;
  isBonusUnit: boolean;
  monthlyUnit: number;
  bonusUnit: number;
  bonusRate: number;
  bonusMultiplierDesignationType:
    | 'UNIT_NUMBER'
    | 'MAGNIFICATION'
    | 'FIXED_MAGNIFICATION';
  monthlyIncentiveRatio: number;
  associationContribution: AssociationContribution;
  associationData: Association;
};

export const SignUpContributionCompleteOrganism: FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  const location = useLocation();
  const isPc = useMediaQuery(() => theme.breakpoints.up(BREAKPOINT));
  const state = location.state as LocationState;

  useHelmetHandler({
    title: '持株会入会登録_会員申請完了',
  });

  return (
    <Box
      sx={{
        bgcolor: 'system.background-dark',
        padding: {
          xs: '0 0 60px',
          sm: '0 40px 60px',
          md: '0 40px 60px',
          lg: '0 130px 60px',
        },
      }}
    >
      {state?.associationData.associationName && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '40px',
            marginTop: '32px',
          }}
        >
          <Typography
            sx={{
              ...theme.typography.h6,
              color: theme.palette.system['text-light'],
            }}
          >
            {state?.associationData.associationName}
          </Typography>
        </Box>
      )}
      <Wrapper isPc={isPc} onClick={onClick} />
    </Box>
  );
};
