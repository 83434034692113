import { BREAKPOINT } from '@/theme/theme';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { FC } from 'react';

export const Warming: FC = () => {
  const isPc = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(BREAKPOINT)
  );
  return (
    <Box
      sx={{
        padding: '8px 16px',
        border: '1px solid #D20B0B',
        color: '#D20B0B',
        margin: isPc ? '0' : '40px 16px 0',
        borderRadius: '8px',
      }}
    >
      <Typography
        component="p"
        variant="body-main/bold"
        sx={{ marginBottom: '8px' }}
      >
        売却時の注意事項
      </Typography>
      <Typography variant="body-main/regular">
        会社の株価に重大な影響を与える「重要事実」を知りながら、その重要事実が「公表される前」に、会社の株式の売買等を行うことはできません。売却にあたりましては、必ず事前に会社に許可を得てください。
      </Typography>
    </Box>
  );
};
