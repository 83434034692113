import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignUpUserOrganism } from '@/components/organism/SignUp/SignUpUser';
import { SignUpUserFormFeature } from '@/features/SignUp/SignUpUserFormFeature';

import { Path } from '@/constants/Router/path';

import type { UserFormValues } from '@/types/components/SignUpUser';
import { appClient } from '@/services';
import { Box, Typography } from '@mui/material';
import { theme } from '@/theme/theme';

type AssociationData = {
  associationCd: string;
  associationName: string;
};

export const SignUpUserIndex: FC = () => {
  const navigate = useNavigate();

  const [associationData, setAssociationData] = useState<AssociationData>({
    associationCd: '',
    associationName: '',
  });

  const association = async () => {
    // 【API-A-1001】持株会（事務局）情報
    const response = await appClient.associations.findAssociationById();
    if (response) {
      setAssociationData({
        associationCd: response.associationCd || '',
        associationName: response.associationName || '',
      });
    }
  };

  /**
   * handleSubmit
   */
  const handleSubmit = (input: UserFormValues) => {
    navigate(Path.CREATE_USER_CONFIRM, { state: { input, associationData } });
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    association();
  }, []);

  return (
    <>
      {associationData.associationName && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '40px',
            marginTop: '32px',
          }}
        >
          <Typography
            sx={{
              ...theme.typography.h6,
              color: theme.palette.system['text-light'],
            }}
          >
            {associationData.associationName}
          </Typography>
        </Box>
      )}
      <SignUpUserOrganism>
        <SignUpUserFormFeature
          associationData={associationData}
          handleFormSubmit={handleSubmit}
        />
      </SignUpUserOrganism>
    </>
  );
};
