/* eslint-disable @typescript-eslint/no-misused-promises */
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { useSnackbarMessage } from '@/hooks/useSnackbarMessage';
import { appClient } from '@/services';
import { BREAKPOINT } from '@/theme/theme';
import { FindApplicationById } from '@/types/api/applications';
import { ErrorResponse } from '@/types/api/error';
import { formatDateTime } from '@/utils/dateFunctions';
import { Box, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import { FC } from 'react';

interface Props {
  id: number;
  applicationDate: string;
  applicationType: FindApplicationById['applicationType'];
  onCancel: () => void;
  goNext: () => void;
}

const type = {
  MONTHLY_UNIT: '口数変更',
  BONUS_UNIT: '口数変更',
  PART: '一部引出',
  RECESS: '休止',
  RESUME: '再開',
  WITHDRAWAL: '退会',
  ADMISSION: '新規会員登録',
  USER: '会員情報変更',
};

const styleContainer: SxProps<Theme> = [
  {
    width: '343px',
    height: '409px',
    textAlign: 'center',
    p: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
  },
  (theme) => ({
    [theme.breakpoints.up(BREAKPOINT)]: {
      width: '520px',
      height: '305px',
      p: '32px',
    },
  }),
];

const styleGrayBox: SxProps<Theme> = [
  {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
    gap: '8px',
    width: '295px',
    height: '117px',
    bgcolor: 'system.background-light',
    borderRadius: '8px',
  },
  (theme) => ({
    [theme.breakpoints.up(BREAKPOINT)]: {
      width: '456px',
      height: '85px',
      bgcolor: 'system.background',
    },
  }),
];

export const ApplicationCancel: FC<Props> = (props) => {
  const { id, applicationDate, applicationType, onCancel, goNext } = props;
  const { showMessage } = useSnackbarMessage();
  const isPc = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(BREAKPOINT)
  );
  const primaryWidth = isPc ? '152px' : '295px';
  const secondaryWidth = isPc ? '88px' : '295px';
  const height = isPc ? '40px' : '56px';
  const borderRadius = isPc ? '20px' : '28px';
  const primarySx = { width: primaryWidth, height, borderRadius };
  const secondarySx = { width: secondaryWidth, height, borderRadius };
  const formatApplicationDate = formatDateTime(applicationDate);

  const onClick = async () => {
    try {
      const body = {
        applicationId: id,
      };
      await appClient.applications.applicationCancel(body);
      goNext();
    } catch (e) {
      showMessage({
        open: true,
        messageType: 'error',
        messages: [(e as ErrorResponse).data.message],
      });
    }
  };

  return (
    <Box sx={styleContainer}>
      <Typography variant="h5" sx={{ color: 'primary.normal' }}>
        申請取り消し
      </Typography>
      <Box sx={styleGrayBox}>
        <Typography
          variant="body-sub/regular"
          sx={{ color: 'system.text-light' }}
        >
          申請ID：{id}
        </Typography>
        {isPc ? (
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <Typography
              variant="body-main/medium"
              sx={{ color: 'system.text-normal' }}
            >
              {type[applicationType ?? 'MONTHLY_UNIT']}
            </Typography>
            <Typography
              variant="body-main/medium"
              sx={{ color: 'system.text-normal' }}
            >
              {formatApplicationDate}申請
            </Typography>
          </Box>
        ) : (
          <>
            <Typography
              variant="body-main/medium"
              sx={{ color: 'system.text-normal' }}
            >
              {type[applicationType ?? 'MONTHLY_UNIT']}
            </Typography>
            <Typography
              variant="body-main/medium"
              sx={{ color: 'system.text-normal' }}
            >
              {formatApplicationDate}申請
            </Typography>
          </>
        )}
      </Box>
      <Typography
        component="p"
        variant="body-main/regular"
        sx={{ color: 'system.text-normal' }}
      >
        この申請を取り消しますか？
      </Typography>
      {isPc ? (
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <SecondaryButton sx={secondarySx} onClick={onCancel}>
            閉じる
          </SecondaryButton>
          <PrimaryButton sx={primarySx} onClick={() => onClick()}>
            申請を取り消す
          </PrimaryButton>
        </Box>
      ) : (
        <>
          <SecondaryButton sx={secondarySx} onClick={onCancel}>
            閉じる
          </SecondaryButton>
          <PrimaryButton sx={primarySx} onClick={() => onClick()}>
            申請を取り消す
          </PrimaryButton>
        </>
      )}
    </Box>
  );
};
