import * as yup from 'yup';
import type { UserFormValues } from '@/types/components/SignUpUser';
import { getKataganaRegex, getStarZenkakuRegex } from '@/utils/stringProcessor';
// todo:: 細かいValidationは後で実装する
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const userFormValidateSchema: yup.SchemaOf<UserFormValues> = yup
  .object()
  .shape({
    nameKanji: yup
      .string()
      .required('入力してください')
      .matches(getStarZenkakuRegex, '入力できない文字が含まれています')
      .max(8, '8文字以内で入力してください'),
    nameKanji2: yup
      .string()
      .required('入力してください')
      .matches(getStarZenkakuRegex, '入力できない文字が含まれています')
      .max(9, '9文字以内で入力してください'),
    nameKana: yup
      .string()
      .required('入力してください')
      .matches(getKataganaRegex, 'カタカナで入力してください')
      .max(8, '8文字以内で入力してください'),
    nameKana2: yup
      .string()
      .required('入力してください')
      .matches(getKataganaRegex, 'カタカナで入力してください')
      .max(9, '9文字以内で入力してください'),
    zip: yup.string().required('入力してください'),
    address1: yup.string().required('選択してください'),
    address2: yup
      .string()
      .required('入力してください')
      .matches(getStarZenkakuRegex, '入力できない文字が含まれています'),
    address3: yup
      .string()
      .transform((value: string) => {
        if (typeof value === 'string') {
          return value.replace(/[\s\u3000]+/g, '');
        }
        return value;
      })
      .required('入力してください')
      .matches(getStarZenkakuRegex, '入力できない文字が含まれています'),
    telType: yup.string().required('選択してください'),
    tel: yup.string().required('入力してください'),
    employeeCode: yup.string().required('入力してください'),
    year: yup.string().required('入力してください'),
    month: yup.string().required('入力してください'),
    day: yup.string().required('入力してください'),
  });
