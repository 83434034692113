/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { TextWithFlowIcon } from '@/components/molecules/Flow/TextWithFlowIcon';
import { UserInfo } from '@/components/molecules/Table/UserInfo';
import { HomeHeader } from '@/components/organism/HomeHeader';
import { styled, Typography } from '@mui/material';
import { FC } from 'react';
import { WarmingDeadLine } from '@/components/organism/Apply/WarmingDeadLine';
import { BREAKPOINT } from '@/theme/theme';
import { PrimaryAndSecondaryButtons } from '@/components/molecules/Buttons/PrimaryAndSecondaryButtons';
import { PrimaryTable } from '@/components/molecules/Table/PrimaryTable';
import { PrimaryTableBodyTdMulti } from '@/components/atoms/Table/PrimaryTableBodyTdMulti';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { Association } from '@/types/api/association';
import { AssociationContribution } from '@/types/api/associationContribution';
import { Eventsmemberdeadlines } from '@/types/api/eventsmemberdeadlines';
import { User } from '@/types/api/user';
import { UserContribution } from '@/types/api/userContribution';
import { useLocation, useNavigate } from 'react-router-dom';
import { Path } from '@/constants/Router/path';
import { appClient } from '@/services';
import { useSnackbarMessage } from '@/hooks/useSnackbarMessage';
import { Associations } from '@/types/api/associations';
import { ErrorResponse } from '@/types/api/error';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { Reason } from './RecessIndex';

const Wrraper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    marginBottom: '80px',
    padding: '14px 0',
    width: '100%',
    [breakpoints.up(BREAKPOINT)]: {
      padding: '14px 40px',
    },
    [breakpoints.up('lg')]: {
      padding: '14px 130px',
    },
  };
});

const ContentWrraper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  width: '100%',
});

// パンクズ
const links = [
  {
    path: 'TOP',
    to: Path.HOME,
  },
  {
    path: '休止する',
    to: Path.RECESS,
  },
];

type LocationState = {
  user: User;
  association: Association;
  associationContribution: AssociationContribution;
  userContribution: UserContribution;
  eventsmemberdeadlines: Eventsmemberdeadlines;
  stocksNumber: number;
  reason: Reason;
  otherReasons: string;
};

const reason = {
  LEAVE_ABSENCE: '休職/退職',
  FAMILY_MATTERS: '家族理由',
  ECONOMIC: '経済理由',
  OTHER: 'その他',
};

export const RecessConfirm: FC = () => {
  const navigate = useNavigate();
  useHelmetHandler({
    title: '休会 入力内容確認',
  });

  const location = useLocation();
  const state = location.state as LocationState;
  const { showMessage } = useSnackbarMessage();

  const goNext = async () => {
    try {
      const body = {
        applicationDate: new Date().toString(),
        applicationType: 'RECESS' as Associations['applicationType'],
        applicationRecess: {
          reason: state.reason,
          otherReasons: state.otherReasons,
        },
      };

      const response = await appClient.applications.createApplication(body);

      if (response) {
        navigate(Path.RECESS_COMPLETE, {
          state: {
            applicationType: response.applicationType,
            applicationId: response.applicationId,
            applicationDate: response.applicationDate,
          },
        });
      }
    } catch (e) {
      showMessage({
        open: true,
        messageType: 'error',
        messages: [(e as ErrorResponse).data.message],
      });
    }
  };

  const navigateToPrint = () => {
    navigate(`/print`, {
      state: {
        ...state,
        title: '休止する',
        contentTitle: '休止理由',
        type: 'RECESS',
      },
    });
  };

  return (
    <Wrraper>
      <HomeHeader
        sx={{ width: '100%' }}
        links={links}
        title="休止する"
        subTitle="入力内容確認"
        printOnClick={() => navigateToPrint()}
      />
      <TextWithFlowIcon flowOn={1} widthFull />
      <ContentWrraper>
        <WarmingDeadLine
          eventsmemberdeadlines={state.eventsmemberdeadlines}
          target="RECESS"
        />
        <UserInfo
          mochikabukaiCode={state.user.associationCd}
          mochikabukaiName={state.association.associationName}
          nameKanji={state.user.nameKanji}
          nameKana={state.user.nameKana}
          zipcode={state.user.zipcode}
          address1={state.user.address1}
          address2={state.user.address2}
          address3={state.user.address3}
          tel={state.user.tel}
          employeeCd={state.user.employeeCd}
          editable
        />
        <PrimaryTable title="休止理由">
          <PrimaryTableBodyTdMulti title="休止理由">
            <Typography sx={{ display: 'block' }} variant="body-main/regular">
              {reason[state.reason]}
            </Typography>
            <Typography sx={{ display: 'block' }} variant="body-main/regular">
              {state.otherReasons}
            </Typography>
          </PrimaryTableBodyTdMulti>
        </PrimaryTable>
        <PrimaryAndSecondaryButtons>
          <SecondaryButton
            sx={{
              display: 'flex',
              margin: 'auto',
              borderRadius: '28px',
              padding: '16px 24px',
            }}
            onClick={() =>
              navigate(Path.RECESS, {
                state: {
                  reason: state.reason,
                  otherReasons: state.otherReasons,
                },
              })
            }
          >
            入力へ戻る
          </SecondaryButton>
          <PrimaryButton
            sx={{
              borderRadius: '28px',
              padding: '16px 24px',
            }}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={() => goNext()}
          >
            申請する
          </PrimaryButton>
        </PrimaryAndSecondaryButtons>
      </ContentWrraper>
    </Wrraper>
  );
};
