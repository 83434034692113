import { Path } from '../Router/path';

export const procedures = [
  { key: '1', value: '口数を変更する - 給与 (毎月の拠出)', to: Path.PAY },
  { key: '2', value: '口数を変更する (賞与)', to: Path.BONUS },
  { key: '3', value: '一部を引き出す', to: Path.PART },
  { key: '4', value: '休止する', to: Path.RECESS },
  { key: '5', value: '退会する', to: Path.WITHDRAWAL },
  { key: '6', value: '電子交付サービス', to: Path.REPORT },
];

export const beginners = [
  { key: '1', value: '会員向けご利用ガイド', to: Path.GUIDE },
  { key: '2', value: '持株会のしくみ', to: Path.STRUCTURE },
];
