// その6

import { styled, useMediaQuery, Theme, Link } from '@mui/material';
import { FC } from 'react';
import { ReactComponent as QuestionMark } from '@/assets/question.svg';
import { ReactComponent as RightArrow } from '@/assets/rightArrow.svg';

import { Path } from '@/constants/Router/path';

const BREAKPOINT = 'sm';

const Wrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    [breakpoints.down(BREAKPOINT)]: {
      gap: '24px',
    },
  };
});

const Title = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography.h3,
  };
});

const QuestionsWrap = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    [breakpoints.down(BREAKPOINT)]: {
      flexDirection: 'column',
    },
  };
});

const QuestionWrap = styled('div')(({ theme }) => {
  const { palette } = theme;
  return {
    backgroundColor: palette.system.white,
    borderRadius: '22px',
    padding: '10px 24px',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  };
});

const Question = styled('span')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-main/regular'],
  };
});

const LinkWrap = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    [breakpoints.down(BREAKPOINT)]: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
    },
  };
});

const FAQsLink = styled(Link)({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
});

const LinkTitle = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-main/medium'],
  };
});

const pcQuestions = [
  '口数は何口まで申請できますか？',
  '引出とはどういう意味ですか？',
  '休止を希望しています。どうすればいいですか？',
  '他の証券会社に口座があるので、そちらの口座に引出を希望しますので手続き方法を教えてください。',
  '口座は何日で開設できますか？',
  'パスワードを変更したい。',
  '住所を変更したい。',
];

const spQuestions = [
  '口数は何口まで申請できますか？',
  '引出とはどういう意味ですか？',
  'パスワードを変更したい。',
  '住所を変更したい。',
];

export const Questions: FC = () => {
  const isSp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(BREAKPOINT)
  );

  return (
    <Wrapper>
      <Title>よくあるご質問</Title>
      <QuestionsWrap>
        {isSp ? (
          <>
            {spQuestions.map((q) => (
              <QuestionWrap key={q}>
                <QuestionMark />
                <Question>{q}</Question>
              </QuestionWrap>
            ))}
          </>
        ) : (
          <>
            {pcQuestions.map((q) => (
              <QuestionWrap key={q}>
                <QuestionMark />
                <Question>{q}</Question>
              </QuestionWrap>
            ))}
          </>
        )}
      </QuestionsWrap>
      <LinkWrap>
        <FAQsLink href={Path.FAQ}>
          <LinkTitle>よくあるご質問をもっと見る</LinkTitle>
          <RightArrow />
        </FAQsLink>
      </LinkWrap>
    </Wrapper>
  );
};
