import { Eventsmemberdeadlines } from '@/types/api/eventsmemberdeadlines';

export const ConvertEventsmemberdeadlines = (
  data:
    | Array<{
        applicationType?:
          | 'MONTHLY_UNIT'
          | 'BONUS_UNIT'
          | 'PART'
          | 'RECESS'
          | 'RESUME'
          | 'WITHDRAWAL'
          | 'ADMISSION'
          | 'USER';
        memberDeadlines?: Array<string>;
      }>
    | undefined
) =>
  data
    ? (data.map((item) => ({
        applicationType: item.applicationType || 'MONTHLY_UNIT',
        memberDeadlines: item.memberDeadlines || [],
      })) as Eventsmemberdeadlines)
    : undefined;
