// その4
import { styled, useMediaQuery, Theme, Link } from '@mui/material';
import { FC, useState, useEffect } from 'react';
// TODO:一旦電子交付サービスボタンを無効化し遷移できないように
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ProcedureIcon } from '@/assets/procedureIcon.svg';
import { ReactComponent as PartProcedureIcon } from '@/assets/partProcedureIcon.svg';
import { ReactComponent as PauseIcon } from '@/assets/pauseIcon.svg';
import { ReactComponent as Withdrawal } from '@/assets/withdrawIcon.svg';
import { ReactComponent as ResumeIcon } from '@/assets/resumeIcon.svg';
import { ReactComponent as Arrow } from '@/assets/arrowDown.svg';
import { ReactComponent as Paper } from '@/assets/paper.svg';
import { Eventsmemberdeadlines } from '@/types/api/eventsmemberdeadlines';
import { Path } from '@/constants/Router/path';
import { BasicModal } from '@/components/molecules/Modal/BasicModal.tsx';
import { DeadLine } from '@/components/molecules/Modal/BasicModal.tsx/DeadLine';
import { findDeadLineService } from '@/services/findDeadLineService';
import { useHomeHooks } from '@/components/pages/Home/hooks/useHomeHooks';
import useSWR from 'swr';
import { appClient } from '@/services';
import { ConvertAssociationContribution } from '@/services/convert/associationContribution';
import { AssociationContribution } from '@/types/api/associationContribution';
import { useAuthUser } from '@/hooks/useAuth';
import { useUserApplicationStatus } from '@/hooks/useUserApplicationStatus';

const BREAKPOINT = 'sm';

const Wrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    [breakpoints.down(BREAKPOINT)]: {
      gap: '24px',
    },
  };
});

const TopWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

const TitlesWrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
    [breakpoints.down(BREAKPOINT)]: {
      gap: '24px',
    },
  };
});
const TitleWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
});

const Title = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography.h3,
  };
});

const CustomLink = styled(Link)(({ theme }) => {
  const { breakpoints } = theme;
  return {
    color: theme.palette.secondary.main,
    textDecorationColor: theme.palette.secondary.main,
    [breakpoints.down(BREAKPOINT)]: {
      display: 'block',
    },
    cursor: 'pointer',
  };
});

const ButtonsWrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    gap: '20px',

    [breakpoints.down(BREAKPOINT)]: {
      gap: '8px',
      flexDirection: 'column',
      borderRadius: '0px',
    },
  };
});

const Events = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
    [breakpoints.down(BREAKPOINT)]: {
      gap: '8px',
      width: '100%',
    },
  };
});

const EventsTop = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
    width: '100%',
    [breakpoints.down(BREAKPOINT)]: {
      flexDirection: 'column',
      gap: '8px',
      height: '50%',
    },
  };
});

const EventLink = styled(Link)(({ theme }) => {
  const { typography, breakpoints, palette } = theme;
  return {
    display: 'flex',
    gap: '16px',
    width: '50%',
    ...typography.h6,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: palette.system.main,
    border: '1px solid rgba(0, 0, 0, 0.13)',
    borderRadius: '12px',
    color: 'black',
    textDecoration: 'none',
    padding: '16px 32px',
    [breakpoints.down(BREAKPOINT)]: {
      ...typography['body-main/medium'],
      width: '100%',
      justifyContent: 'space-between',
      padding: '16px 24px',
    },
  };
});

const IconsWrap = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    [breakpoints.down(BREAKPOINT)]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  };
});

const IconWrap = styled('div')({
  width: 'auto',
});

const EventBottom = styled('div')(({ theme }) => {
  const { typography, breakpoints, palette } = theme;
  return {
    display: 'flex',
    width: '100%',
    ...typography.h6,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: palette.system.main,
    border: '1px solid rgba(0, 0, 0, 0.13)',
    borderRadius: '12px',
    height: '40%',
    cursor: 'pointer',

    [breakpoints.down(BREAKPOINT)]: {
      ...typography['body-main/medium'],
      justifyContent: 'space-between',
      padding: '16px 24px',
    },
  };
});

const ServicesWrap = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    [breakpoints.down(BREAKPOINT)]: {
      justifyContent: 'flex-start',
    },
  };
});

type Props = {
  eventsmemberdeadlines: Eventsmemberdeadlines;
};

export const Procedures: FC<Props> = ({ eventsmemberdeadlines }) => {
  const isSp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(BREAKPOINT)
  );

  // TODO:一旦電子交付サービスボタンを無効化し遷移できないように
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const { isListedClassification } = useHomeHooks();

  const [associationContribution, setAssociationContribution] =
    useState<AssociationContribution>();

  // 【API-A-1002】持株会（事務局）拠出情報取得
  const { data: findAssociationContribution } = useSWR(
    '/api/associations/{associationCd}/contributions',
    () => appClient.associations.findAssociationContribution()
  );

  const {
    notOperatePay,
    notOperatePart,
    notOperateRecess,
    notOperateWithdrawal,
  } = useUserApplicationStatus();

  useEffect(() => {
    setAssociationContribution(
      ConvertAssociationContribution(findAssociationContribution)
    );
  }, [findAssociationContribution]);

  // 【API-A-0909】資産情報取得
  const { user, updateUser } = useAuthUser();
  if (!user) {
    updateUser().finally(() => {});
  }

  const isNotOperatePay =
    notOperatePay ||
    associationContribution?.monthlyUnitClassification !== '1' ||
    !(
      user.userStatus === 'NORMAL' ||
      user.userStatus === 'BEING_RESUMEMENT_PROCESSED'
    );
  const isNotOperatePart =
    notOperatePart ||
    !(
      user.userStatus === 'NORMAL' ||
      user.userStatus === 'BEING_RECESSMENT_PROCESSED' ||
      user.userStatus === 'RECESS' ||
      user.userStatus === 'BEING_RESUMEMENT_PROCESSED'
    );
  const isNotOpreateRecess =
    notOperateRecess || !(user.userStatus === 'NORMAL');
  const isNotOpreateWithdrawal =
    notOperateWithdrawal ||
    !(user.userStatus === 'NORMAL' || user.userStatus === 'RECESS');

  return (
    <>
      <Wrapper>
        <TopWrapper>
          <TitlesWrapper>
            <TitleWrapper>
              <Title>各種お手続き</Title>
              <CustomLink onClick={() => setModalOpen(true)}>
                受付締日一覧
              </CustomLink>
            </TitleWrapper>
          </TitlesWrapper>
        </TopWrapper>
        <ButtonsWrapper>
          <Events>
            <EventsTop>
              {/* 口数変更 */}
              <EventLink
                href={isNotOperatePay ? '#' : Path.PAY}
                sx={{
                  opacity: isNotOperatePay ? 0.5 : 1,
                  pointerEvents: isNotOperatePay ? 'none' : undefined,
                }}
              >
                <IconsWrap>
                  {isSp ? (
                    <ProcedureIcon style={{ width: '40px', height: '40px' }} />
                  ) : (
                    <ProcedureIcon />
                  )}
                  <IconWrap>口数を変更する</IconWrap>
                </IconsWrap>
                {isSp && <Arrow />}
              </EventLink>
              {/* 一部を引き出す 上場のみ */}
              {isListedClassification && (
                <EventLink
                  href={isNotOperatePart ? '#' : Path.PART}
                  sx={{
                    opacity: isNotOperatePart ? 0.5 : 1,
                    pointerEvents: isNotOperatePart ? 'none' : undefined,
                  }}
                >
                  <IconsWrap>
                    {isSp ? (
                      <PartProcedureIcon
                        style={{ width: '40px', height: '40px' }}
                      />
                    ) : (
                      <PartProcedureIcon />
                    )}

                    <IconWrap>一部を引き出す</IconWrap>
                  </IconsWrap>
                  {isSp && <Arrow />}
                </EventLink>
              )}

              {/* 休止 */}
              {user.userStatus === 'RECESS' ? (
                <EventLink
                  href={Path.RESUME}
                  sx={{
                    opacity: 1,
                    pointerEvents: undefined,
                  }}
                >
                  <IconsWrap>
                    {isSp ? (
                      <ResumeIcon style={{ width: '40px', height: '40px' }} />
                    ) : (
                      <ResumeIcon style={{ width: '60px', height: '60px' }} />
                    )}
                    <IconWrap>再開する</IconWrap>
                  </IconsWrap>
                  {isSp && <Arrow />}
                </EventLink>
              ) : (
                <EventLink
                  href={isNotOpreateRecess ? '#' : Path.RECESS}
                  sx={{
                    opacity: isNotOpreateRecess ? 0.5 : 1,
                    pointerEvents: isNotOpreateRecess ? 'none' : undefined,
                  }}
                >
                  <IconsWrap>
                    {isSp ? (
                      <PauseIcon style={{ width: '40px', height: '40px' }} />
                    ) : (
                      <PauseIcon style={{ width: '60px', height: '60px' }} />
                    )}
                    <IconWrap>休止する</IconWrap>
                  </IconsWrap>
                  {isSp && <Arrow />}
                </EventLink>
              )}

              {/* 退会 */}
              <EventLink
                href={isNotOpreateWithdrawal ? '#' : Path.WITHDRAWAL}
                sx={{
                  opacity: isNotOpreateWithdrawal ? 0.5 : 1,
                  pointerEvents: isNotOpreateWithdrawal ? 'none' : undefined,
                }}
              >
                <IconsWrap>
                  {isSp ? (
                    <Withdrawal style={{ width: '40px', height: '40px' }} />
                  ) : (
                    <Withdrawal />
                  )}
                  <IconWrap>退会する</IconWrap>
                </IconsWrap>
                {isSp && <Arrow />}
              </EventLink>
            </EventsTop>
            {/* TODO:一旦電子交付サービスボタンを無効化し遷移できないように */}
            <EventBottom>
              <ServicesWrap
                onClick={() => navigate(Path.REPORT, { replace: true })}
              >
                {isSp ? (
                  <Paper style={{ width: '40px', height: '40px' }} />
                ) : (
                  <Paper />
                )}
                <IconWrap>電子交付サービス</IconWrap>
              </ServicesWrap>
              {isSp && <Arrow />}
            </EventBottom>
          </Events>
        </ButtonsWrapper>
      </Wrapper>
      <BasicModal open={modalOpen}>
        <DeadLine
          deadLine={findDeadLineService({
            deadlines: eventsmemberdeadlines,
            target: 'MONTHLY_UNIT',
            targetAll: true,
          })}
          eventsmemberdeadlines={eventsmemberdeadlines}
          onClick={() => setModalOpen(false)}
        />
      </BasicModal>
    </>
  );
};
