import { SecuritiesAccount } from '@/types/api/securitiesAccount';

export const ConvertSecuritiesAccount = (
  data:
    | {
        branchCd?: string | undefined;
        branchName?: string | undefined;
        accountNumber?: string | undefined;
      }
    | undefined
) =>
  data
    ? ({
        name: '東海東京証券',
        departmentCode: data.branchCd || '',
        departmentName: data.branchName || '',
        accountNumber: data.accountNumber || '',
      } as SecuritiesAccount)
    : undefined;
