import { useRecoilState } from 'recoil';

import { sessionOutModalAtom } from '@/recoil/atom/sessionOutModalAtom';
// eslint-disable-next-line import/no-cycle
import { useAuthUser } from './useAuth';

/**
 * useSessionOutModal
 * @example
 * ```ts
 *  const {
 *  handleLogoutModalOpen,
    handleLogoutModalClose,
    handleClickLogout, } = useLogoutModal()
 * ```
 * @category ReactHooks
 */
export const useSessionOutModal = () => {
  const { handleLogout } = useAuthUser();
  // usage Recoil
  const [sessionOutModal, setSessionOutModal] =
    useRecoilState<boolean>(sessionOutModalAtom);

  /**
   * handleSessionOutModalOpen
   */
  const handleSessionOutModalOpen = () => {
    setSessionOutModal(true);
  };

  /**
   * handleSessionOutModalClose
   */
  const handleSessionOutModalClose = () => {
    setSessionOutModal(false);
  };

  /**
   * handleClickLogout
   */
  const handleClickUserLogout = () => {
    handleLogout().finally(() => {});
    handleSessionOutModalClose();
  };

  return {
    sessionOutModal,
    handleSessionOutModalOpen,
    handleSessionOutModalClose,
    handleClickUserLogout,
  };
};
