import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface LoadingProgressProps {
  isOpen: boolean;
}

export const LoadingProgress: React.FC<LoadingProgressProps> = ({
  isOpen = false,
}) => (
  <Backdrop
    open={isOpen}
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  >
    <CircularProgress />
  </Backdrop>
);
