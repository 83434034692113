export const APPLICATION = {
  TYPE: {
    MONTHLY_UNIT: 'MONTHLY_UNIT',
    BONUS_UNIT: 'BONUS_UNIT',
    PART: 'PART',
    RECESS: 'RECESS',
    RESUME: 'RESUME',
    WITHDRAWAL: 'WITHDRAWAL',
    ADMISSION: 'ADMISSION',
    USER: 'USER',
  },
  TYPE_CAMELCASE: {
    MONTHLY_UNIT: 'MonthlyUnit',
    BONUS_UNIT: 'BonusUnit',
    PART: 'Part',
    RECESS: 'Recess',
    RESUME: 'Resume',
    WITHDRAWAL: 'Withdrawal',
    ADMISSION: 'Admission',
    USER: 'User',
  },
  STATUS: {
    PREPARATION: 'PREPARATION',
    APPLYING: 'APPLYING',
    OFFICER_APPROVED: 'OFFICER_APPROVED',
    BACKOFFICER_APPROVED: 'BACKOFFICER_APPROVED',
    COMPLETED: 'COMPLETED',
    OFFICER_REJECTED: 'OFFICER_REJECTED',
    BACKOFFICER_REJECTED: 'BACKOFFICER_REJECTED',
    CANCEL: 'CANCEL',
  },
};

export const USER = {
  STATUS: {
    VARIFIED_MAIL: 'VARIFIED_MAIL',
    PERSONAL_INFORMATION_ENTERED: 'PERSONAL_INFORMATION_ENTERED',
    BEING_ENROLLMENT_PROCESSED: 'BEING_ENROLLMENT_PROCESSED',
    NORMAL: 'NORMAL',
    BEING_RECESSMENT_PROCESSED: 'BEING_RECESSMENT_PROCESSED',
    RECESS: 'RECESS',
    BEING_RESUMEMENT_PROCESSED: 'BEING_RESUMEMENT_PROCESSED',
    BEING_WITHDRAWAL_PROCESSED: 'BEING_WITHDRAWAL_PROCESSED',
    WITHDRAWN: 'WITHDRAWN',
    DEREGISTRATION: 'DEREGISTRATION',
    REJECT_ENROLLMENT: 'REJECT_ENROLLMENT',
    UNREGISTERED: 'UNREGISTERED',
  },
};
