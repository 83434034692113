export const LoginflowLabels = [
  { key: 1, value: 'アカウント登録' },
  { key: 2, value: '多要素認証' },
  { key: 3, value: '会員情報登録' },
  { key: 4, value: '拠出設定' },
];

export const ApplicationsflowLabels = [
  { key: 1, value: '変更内容入力' },
  { key: 2, value: '内容確認' },
  { key: 3, value: '申請完了' },
];
