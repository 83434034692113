import { LoginTitleWithUnderline } from '@/components/molecules/Login/LoginTitleWithUnderline';
import { LoginWideBox } from '@/components/molecules/Login/LoginWideBox';
import { SignUpUpper } from '@/components/organism/SignUp/SignUpUpper';
import { Box, styled, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import {
  // useLocation,
  useNavigate,
} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { validatePassword } from '@/utils/validate/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { theme } from '@/theme/theme';
import { appClient, AUTH_TOKEN, OpenAPI } from '@/services';
import { Path } from '@/constants/Router/path';
import { LoginLabelWithTextInput } from '@/components/molecules/Login/LoginLabelWithTextInput';
import { ErrMessageWithIcon } from '@/components/molecules/Message/ErrMessageWithIcon';
import { LoginLabelWithPasswordInput } from '@/components/molecules/Login/LoginLabelWithPasswordInput';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const SignUpLower = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0px 32px',
  gap: '40px',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: '32px 16px',
    gap: '32px',
  },
}));

const SignUpLowerInner = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: 0,
  gap: '24px',
  width: '100%',
});

const NormalTypography = styled(Typography)(() => ({
  ...theme.typography['body-main/regular'],
  color: theme.palette.system['text-normal'],
  textAlign: 'left',
  '& span': {
    ...theme.typography['body-main/bold'],
    color: theme.palette.secondary.main,
    display: 'block',
  },
}));

const MfaFormBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '8px',
  gap: '24px',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    gap: '24px',
    alignItems: 'flex-start',
    height: '152px',
    '& button': {
      width: '100%',
    },
  },
}));

const errorMessage = ['入力された値が正しくありません。'];

// type AssociationState = {
//   associationCd: string;
//   associationPassword: string;
// };

type FormInputState = {
  loginId: string;
  loginPassword: string;
  reloginPassword: string;
  associationCd: string;
  associationPassword: string;
};

// バリデーションルール
export const schema = yup.object({
  loginId: yup
    .string()
    .required('入力してください')
    .email('有効なメールアドレスを入力してください'),
  loginPassword: yup
    .string()
    .required('入力してください')
    .min(8, '8文字以上で入力してください')
    .matches(/(?=.*[a-z])/, '小文字を含めてください。')
    .matches(/(?=.*[A-Z])/, '大文字を含めてください。')
    .matches(/(?=.*[0-9])/, '数字を含めてください。')
    .matches(
      /(?=.*[.?^$*?!@#%&/,><':;|_~`\]})[{(=+-])/,
      '記号を含めてください。'
    )
    .matches(/^(?!.*[^\x20-\x7e]).+$/, '全角文字は使用できません。'),
  reloginPassword: yup
    .string()
    .required('入力してください')
    .min(8, '8文字以上で入力してください')
    .test(
      'emails-match',
      '入力されたパスワードが一致しません。',
      // eslint-disable-next-line func-names
      function (value) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return value === this.parent.loginPassword;
      }
    ),
  associationCd: yup.string().required(),
  associationPassword: yup.string().required().min(6),
});

export const SignUpAccount: FC = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  // const state = location.state as AssociationState;
  const [isError, setIsError] = useState<boolean>(false);
  useHelmetHandler({
    title: '持株会入会申請 持株会ログイン',
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<FormInputState>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormInputState) => {
    // エラーチェック
    try {
      const responce = await appClient.authorization.authSignup({
        associationCd: data.associationCd,
        associationPassword: data.associationPassword,
        loginId: data.loginId,
        loginPassword: data.loginPassword,
      });
      navigate(Path.SIGNUP_MFA, {
        state: {
          loginId: data.loginId,
          loginPassword: data.loginPassword,
          mfaSession: responce.Session,
        },
      });
    } catch (e) {
      // エラー
      setIsError(true);
    }
  };

  useEffect(() => {
    localStorage.removeItem(AUTH_TOKEN);
    OpenAPI.HEADERS = {};
  }, []);

  return (
    <LoginWideBox>
      <SignUpUpper isSignUp flowOn={0} />
      <SignUpLower>
        <LoginTitleWithUnderline
          isLogin={false}
          title="新規入会"
          description="事務局から発行された持株会コードとパスワードを入力してください。"
        />
        {(errors.associationCd || errors.associationPassword) && (
          <ErrMessageWithIcon errorMessage={errorMessage} isBorder />
        )}
        {isError && <ErrMessageWithIcon errorMessage={errorMessage} />}
        <LoginLabelWithTextInput
          label="持株会コード"
          annotation=""
          error={'' in errors}
          helperText=""
          placeholder="例：123456"
          {...register('associationCd')}
        />
        <LoginLabelWithPasswordInput
          label="パスワード"
          error={'' in errors}
          helperText=""
          placeholder="例：12345678901"
          {...register('associationPassword')}
        />
        <SignUpLowerInner>
          <LoginTitleWithUnderline isLogin title="アカウント登録" />
          <NormalTypography>
            入会手続きを開始します。メールアドレスとパスワードを入力してください。
            <span>
              会員登録直後でもNexStocksへログインいただけます。(一部機能が使えません)
            </span>
          </NormalTypography>
          <NormalTypography>
            ※「nexstocks.jp」からのメールを受信できるように、メール受信設定をご設定ください。
          </NormalTypography>
          <LoginLabelWithTextInput
            label="メールアドレス"
            annotation=""
            error={'loginId' in errors}
            helperText={errors.loginId?.message}
            placeholder="例：abcdef@mail.com"
            {...register('loginId')}
          >
            ※メールアドレスはログインIDとして使用されます。
          </LoginLabelWithTextInput>
          <LoginLabelWithPasswordInput
            label="パスワード"
            placeholder="パスワードを入力"
            error={
              !(
                !watch('loginPassword') ||
                (watch('loginPassword') !== '' &&
                  validatePassword(watch('loginPassword')))
              )
            }
            {...register('loginPassword')}
          >
            {!(
              !watch('loginPassword') ||
              (watch('loginPassword') !== '' &&
                validatePassword(watch('loginPassword')))
            ) && (
              <Box marginBottom="4px">
                <Typography
                  variant="caption/regular"
                  color={theme.palette.states.error}
                >
                  パスワードの形式が正しくありません
                </Typography>
                <br />
              </Box>
            )}
            ※半角英数字8桁以上
            <br />
            ※大文字、小文字、数字、記号をそれぞれ1文字以上使用してください。使用可能な記号は以下の通りです
            <br />
            {
              '^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ ` = + -'
            }
            <br />
            ※自身の名前、生年月日、電話番号、連番など推測しやすい文字列は使用しないでください
          </LoginLabelWithPasswordInput>
          <Box
            display="flex"
            flexDirection="column"
            // gap="4px"
            width="100%"
            alignItems="flex-start"
          >
            <LoginLabelWithPasswordInput
              label="パスワード再入力"
              error={
                !!(
                  watch('reloginPassword') &&
                  watch('loginPassword') !== watch('reloginPassword')
                )
              }
              placeholder="パスワードを入力"
              {...register('reloginPassword')}
            />
            {watch('reloginPassword') &&
              watch('loginPassword') !== watch('reloginPassword') && (
                <Typography
                  variant="caption/regular"
                  color={theme.palette.states.error}
                >
                  入力されたパスワードが一致しません。
                </Typography>
              )}
          </Box>
        </SignUpLowerInner>
        <MfaFormBox>
          <PrimaryButton
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSubmit(onSubmit)}
            disabled={
              errors.associationCd?.message !== undefined ||
              errors.associationPassword !== undefined ||
              !watch('associationCd') ||
              !watch('associationPassword') ||
              !watch('loginId') ||
              errors.loginId?.message !== undefined ||
              !watch('loginPassword') ||
              !watch('reloginPassword') ||
              watch('loginPassword') !== watch('reloginPassword') ||
              watch('loginPassword').length < 8 ||
              !validatePassword(watch('loginPassword'))
            }
          >
            送信する
          </PrimaryButton>
        </MfaFormBox>
      </SignUpLower>
    </LoginWideBox>
  );
};
