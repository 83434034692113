import { CurrentFundingStatus } from '@/components/molecules/BalanceInquiry/CurrentFundingStatus';
import { CurrentInvestmentStatus } from '@/components/molecules/BalanceInquiry/CurrentInvestmentStatus';
import { GeneralInfo } from '@/components/molecules/BalanceInquiry/GeneralInfo';
import { MemberInfo } from '@/components/molecules/BalanceInquiry/MemberInfo';
import { TransactionHistory } from '@/components/molecules/BalanceInquiry/TransactionHistory';
import { HomeHeader } from '@/components/organism/HomeHeader';
import { useAuthUser } from '@/hooks/useAuth';
import { appClient } from '@/services';
import { ConvertAssociation } from '@/services/convert/association';
import { ConvertUserAsset } from '@/services/convert/userAsset';
import { ConvertUserBalancereport } from '@/services/convert/userBalancereport';
import { BREAKPOINT, theme } from '@/theme/theme';
import { Association } from '@/types/api/association';
import { AssociationContribution } from '@/types/api/associationContribution';
import { UserAsset } from '@/types/api/userAsset';
import { UserBalancereport } from '@/types/api/userBalancereport';
import { UserContribution } from '@/types/api/userContribution';
import { Box, styled, Typography } from '@mui/material';
import { FC, useState, useEffect } from 'react';
import { ConvertUserContribution } from '@/services/convert/userContribution';
import { ConvertAssociationContribution } from '@/services/convert/associationContribution';
import useSWR from 'swr';
import { Path } from '@/constants/Router/path';
import { DownloadButton } from '@/components/atoms/Buttons/DownloadButton';
import { PrintSelectedContent } from '@/utils/printHelper';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { Calculations } from '@/utils/calculation';

const links = [
  { path: 'TOP', to: Path.HOME },
  { path: '資産状況', to: Path.ASSETS },
];

const Wrapper = styled(Box)(() => {
  const { breakpoints } = theme;
  return {
    [breakpoints.down('sm')]: {
      padding: '24px 0',
      maxWidth: '100%',
    },
  };
});

const HomeHeaderWrapper = styled(Box)(() => {
  const { breakpoints } = theme;
  return {
    maxWidth: '100%',
    paddingTop: 24,

    '@media print': {
      display: 'none',
    },

    [breakpoints.up(BREAKPOINT)]: {
      padding: '14px 40px',
      marginTop: 32,
    },
    [breakpoints.up('lg')]: {
      padding: '14px 130px',
    },
  };
});

const Page = styled(Box)(() => {
  const { palette } = theme;

  return {
    backgroundColor: palette.system.white,
    width: '100%',
    overflow: 'hidden',
  };
});

const FundingWrapper = styled(Box)(() => {
  const { breakpoints, palette } = theme;

  return {
    marginTop: 40,
    backgroundColor: palette.system.background,

    '@media print': {
      display: 'none',
      marginTop: 0,
    },

    [breakpoints.down('sm')]: {
      marginTop: 24,
      '@media print': {
        display: 'none',
        marginTop: 0,
      },
    },
  };
});

const MemberInfogWrapper = styled(Box)(() => {
  const { breakpoints, palette } = theme;

  return {
    paddingTop: 40,
    paddingBottom: 80,
    backgroundColor: palette.system.white,

    '@media print': {
      display: 'none',
    },

    [breakpoints.down('sm')]: {
      paddingTop: 24,
      paddingBottom: 24,
    },
  };
});

const BalanceControlsContainer = styled(Box)({
  padding: '0 16px',
  '@media print': {
    display: 'none',
  },
  [theme.breakpoints.up(BREAKPOINT)]: {
    padding: '0 40px',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '0 130px',
  },
});

const BalanceControlsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 40,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
});

export const Assets: FC = () => {
  const [userAsset, setUserAsset] = useState<UserAsset>();
  const [userBalancereport, setUserBalancereport] =
    useState<UserBalancereport>();
  const [association, setAssociation] = useState<Association>();
  const [associationContribution, setAssociationContribution] =
    useState<AssociationContribution>();
  const [userContribution, setUserContribution] = useState<UserContribution>();
  useHelmetHandler({
    title: '残高照会',
  });

  const date = new Date();

  const { user } = useAuthUser();

  // 【API-A-1001】持株会（事務局）情報
  const { data: findAssociationById } = useSWR(
    '/api/associations/{associationCd}',
    () => appClient.associations.findAssociationById()
  );

  // 【API-A-0909】資産情報取得
  const { data: findUserAsset } = useSWR('/api/users/assets', () =>
    appClient.users.findUserAsset()
  );

  // 【API-A-0910】残高明細取得
  const { data: findUserBalancereport } = useSWR(
    '/api/users/balancereports',
    () => appClient.users.findUserBalancereport(date.getFullYear())
  );

  // 【API-A-1002】持株会（事務局）拠出情報取得
  const { data: findAssociationContribution } = useSWR(
    '/api/associations/{associationCd}/contributions',
    () => appClient.associations.findAssociationContribution()
  );

  // 【API-A-0905】拠出情報取得
  const { data: findUserContribution } = useSWR('/api/users/contribution', () =>
    appClient.users.findUserContribution()
  );

  useEffect(() => {
    setAssociation(ConvertAssociation(findAssociationById));
    setUserAsset(ConvertUserAsset(findUserAsset));
    setUserBalancereport(ConvertUserBalancereport(findUserBalancereport));
    setAssociationContribution(
      ConvertAssociationContribution(findAssociationContribution)
    );
    setUserContribution(ConvertUserContribution(findUserContribution));
  }, [
    findUserAsset,
    findUserBalancereport,
    findAssociationById,
    findAssociationContribution,
    findUserContribution,
  ]);

  if (
    userAsset &&
    userBalancereport &&
    association &&
    associationContribution &&
    userContribution
  ) {
    return (
      <Page>
        <HomeHeaderWrapper>
          <HomeHeader
            links={links}
            title="資産状況"
            description={[
              'あなたの資産状況になります。',
              <br />,
              '※引出しした株数は含まれていませんので、ご留意ください。',
            ]}
            sx={{
              backgroundColor: theme.palette.system.white,
            }}
          />
        </HomeHeaderWrapper>
        <Wrapper>
          <Box
            id="print_content_general_info"
            sx={{
              '@page': {
                size: 'A4 landscape',
              },
              '@media print': {
                marginTop: '-50px',
              },
            }}
          >
            <GeneralInfo
              date={date}
              stocksNumber={userAsset.stocksNumber}
              marketValue={userAsset.marketValue}
              acquisitionAmount={userAsset.acquisitionAmount}
              valuationGainLoss={userAsset.valuationGainLoss}
              complementaryClosingPrice={userAsset.complementaryClosingPrice}
              investmentDigit={association.investmentDigit}
              targetDate={
                userAsset.targetDate !== ''
                  ? userAsset.targetDate
                  : userAsset.lastUpdated
              }
            />
          </Box>
          <BalanceControlsContainer>
            <BalanceControlsWrapper>
              <Typography variant="h4">残高明細</Typography>
              <DownloadButton
                text="残高明細票ダウンロード"
                onClick={() => PrintSelectedContent()}
              />
            </BalanceControlsWrapper>
          </BalanceControlsContainer>
          <Box id="print_content_wrapper">
            <CurrentInvestmentStatus
              date={date}
              stocksNumber={userAsset.stocksNumber}
              oldStock={userAsset.oldStock}
              firstNewStock={userAsset.firstNewStock}
              secondNewStock={userAsset.secondNewStock}
              carriedForwardAmount={userAsset.carriedForwardAmount}
              cumulativeDeposit={userAsset.cumulativeDeposit}
              cumulativeContribution={userAsset.cumulativeContribution}
              cumulativeIncentives={userAsset.cumulativeIncentives}
              cumulativeDividend={userAsset.cumulativeDividend}
              cumulativeInterest={userAsset.cumulativeInterest}
              cumulativeOtherDeposits={userAsset.cumulativeOtherDeposits}
              cumulativeWithdrawal={userAsset.cumulativeWithdrawal}
              cumulativeOfficeExpenses={userAsset.cumulativeOfficeExpenses}
              withdrawSharesCnt={userAsset.withdrawSharesCnt}
              purchaseAvarageUnitPrice={userAsset.purchaseAvarageUnitPrice}
              investmentDigit={association.investmentDigit}
            />
            <TransactionHistory
              date={date}
              tradeHistories={userBalancereport.tradeHistories}
              rightAllocationHistories={
                userBalancereport.rightAllocationHistories
              }
              investmentDigit={association.investmentDigit}
            />
          </Box>
        </Wrapper>
        <FundingWrapper>
          <CurrentFundingStatus
            monthlyIncentiveRatio={
              associationContribution.monthlyIncentiveRatio
            }
            unitAmount={associationContribution.unitAmount}
            monthlyUnit={userContribution.monthlyUnit}
            bonusUnit={
              associationContribution.bonusMultiplierDesignationType ===
              'FIXED_MAGNIFICATION'
                ? Calculations.bonusUnitAmount(
                    associationContribution,
                    userContribution
                  )
                : userContribution.bonusUnit
            }
            isListed={association.listedClassification === '1'}
          />
        </FundingWrapper>
        <MemberInfogWrapper>
          <MemberInfo
            user={user}
            associationName={association.associationName}
          />
        </MemberInfogWrapper>
      </Page>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
