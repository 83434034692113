import { styled, useMediaQuery, Theme } from '@mui/material';
import { FC } from 'react';
import { BREAKPOINT } from '@/theme/theme';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { FindAssociationById } from '@/types/api/associations';
import { FindApplicationById } from '@/types/api/applications';
import { FindUser } from '@/types/api/users';
import { Pay } from './patterns/Pay';
import { Bonus } from './patterns/Bonus';
import { Part } from './patterns/Part';
import { Recess } from './patterns/Recess';
import { Restart } from './patterns/Restart';
import { Withdrawal } from './patterns/Withdrawal';

const Wrapper = styled('div')(({ theme }) => {
  const { palette, breakpoints } = theme;
  return {
    width: '100%',
    padding: '24px',
    borderRadius: '12px',
    backgroundColor: palette.system.white,
    display: 'flex',
    gap: '32px',
    flexDirection: 'column',
    [breakpoints.down(BREAKPOINT)]: {
      borderRadius: '0px',
      padding: '0px',
      gap: '40px',
    },
  };
});

const Info = styled('div')(({ theme }) => {
  const { breakpoints, palette } = theme;
  return {
    width: '100%',
    backgroundColor: palette.system.background,
    borderRadius: '12px',
    padding: '16px',
    [breakpoints.down(BREAKPOINT)]: {
      borderRadius: '0px',
      padding: '24px 16px',
    },
  };
});

const Title = styled('div')(({ theme }) => {
  const { typography, palette, breakpoints } = theme;
  return {
    ...typography.h5,
    color: palette.system['text-light'],
    paddingBottom: '12px',
    width: '100%',
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    marginBottom: '16px',
    [breakpoints.down(BREAKPOINT)]: {
      paddingBottom: '16px',
    },
  };
});

const Column = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    width: '100%',
    gap: '20px',
    [breakpoints.down(BREAKPOINT)]: {
      flexDirection: 'column',
      gap: '0px',
    },
  };
});

const Border = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    backgroundColor: 'rgba(0, 0, 0, 0.13)',
    padding: '1px',
    marginTop: '8px',
    marginBottom: '8px',
    [breakpoints.down(BREAKPOINT)]: {
      marginTop: '12px',
    },
  };
});

const Item = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-sub/regular'],
    width: '100%',
    display: 'flex',
    gap: '8px',
  };
});

const Label = styled('div')(({ theme }) => {
  const { palette } = theme;
  return {
    color: palette.system['text-light'],
    width: '104px',
  };
});

const Value = styled('div')(({ theme }) => {
  const { palette } = theme;
  return {
    color: palette.system['text-normal'],
  };
});

const LeftBlock = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '50%',
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
    },
  };
});

type Props = {
  user: FindUser;
  association: FindAssociationById;
  isApplying: boolean;
  applicationType: FindApplicationById['applicationType'];
  applicationBonusUnit?: FindApplicationById['applicationBonusUnit'];
  applicationMonthlyUnit?: FindApplicationById['applicationMonthlyUnit'];
  applicationPart?: FindApplicationById['applicationPart'];
  applicationRecess?: FindApplicationById['applicationRecess'];
  applicationResume?: FindApplicationById['applicationResume'];
  applicationWithdrawal?: FindApplicationById['applicationWithdrawal'];
  modalOpen: () => void;
};

export const ApplicationBottom: FC<Props> = ({
  user,
  association,
  isApplying,
  applicationType,
  applicationBonusUnit,
  applicationMonthlyUnit,
  applicationPart,
  applicationRecess,
  applicationResume,
  applicationWithdrawal,
  modalOpen,
}) => {
  const isSp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(BREAKPOINT)
  );
  return (
    <Wrapper id="info">
      <Info>
        <Title>持株会情報</Title>
        <Column>
          <Item>
            <Label>持株会コード</Label>
            <Value>{user.associationCd}</Value>
          </Item>
          {isSp && <Border />}
          <Item>
            <Label>持株会名</Label>
            <Value>{association.associationName}</Value>
          </Item>
        </Column>
      </Info>
      <Info>
        <Title>会員情報</Title>
        <Column>
          <LeftBlock>
            <Item>
              <Label>氏名（漢字）</Label>
              <Value>{user.nameKanji}</Value>
            </Item>
            <Border />
            <Item>
              <Label>氏名(カナ)</Label>
              <Value>{user.nameKana}</Value>
            </Item>
            <Border />
            <Item>
              <Label>住所</Label>
              <Value>
                〒{user?.zipcode}
                <br />
                {user.address1}
                {user.address2}
                {user.address3}
              </Value>
            </Item>
            {isSp && <Border />}
          </LeftBlock>
          <LeftBlock>
            <Item>
              <Label>電話番号</Label>
              <Value>{user.tel}</Value>
            </Item>
            <Border />
            <Item>
              <Label>社員コード</Label>
              <Value>{user.employeeCd}</Value>
            </Item>
          </LeftBlock>
        </Column>
      </Info>

      {/* 口数変更（給与) */}
      {applicationType === 'MONTHLY_UNIT' && applicationMonthlyUnit && (
        <Pay applicationMonthlyUnit={applicationMonthlyUnit} />
      )}

      {/* 口数変更（賞与) */}
      {applicationType === 'BONUS_UNIT' && applicationBonusUnit && (
        <Bonus applicationBonusUnit={applicationBonusUnit} />
      )}

      {/* 一部引き出し */}
      {applicationType === 'PART' && applicationPart && (
        <Part applicationPart={applicationPart} />
      )}

      {/* 休止 */}
      {applicationType === 'RECESS' && applicationRecess && (
        <Recess applicationRecess={applicationRecess} />
      )}

      {/* 再開 */}
      {applicationType === 'RESUME' && applicationResume && (
        <Restart applicationResume={applicationResume} />
      )}

      {/* 退会 */}
      {applicationType === 'WITHDRAWAL' && applicationWithdrawal && (
        <Withdrawal
          applicationWithdrawal={applicationWithdrawal}
          association={association}
        />
      )}
      {!isSp && isApplying && (
        <SecondaryButton
          sx={{ height: '56px', width: '200px', borderRadius: '28px' }}
          onClick={modalOpen}
        >
          この申請を取り消す
        </SecondaryButton>
      )}
    </Wrapper>
  );
};
