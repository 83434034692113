import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { TextInput } from '@/components/atoms/Input/TextInput';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from '@mui/material';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useController, useFormState } from 'react-hook-form';
import { SelectBox, SelectBoxProps } from '@/components/atoms/Input/SelectBox';
import { months, prefectures, years } from '@/constants/choicesForSelectBox';
import {
  SignUpUserProps as Props,
  SignUpUserContainerProps as ContainerProps,
  SignUpUserLabelAndInputProps as LAIProps,
} from '@/types/components/SignUpUser';
import { TermOfService as TermText } from '@/constants/SignUp/termOfService';
import { useMediaQuery } from '@/hooks';
import { IsNumeric, ValidateEmployeeCode } from '@/utils/stringProcessor';
import { theme } from '@/theme/theme';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { SignUpUpper } from './SignUpUpper';

const Title: FC = () => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '16px',
      mt: '8px',
    }}
  >
    <Typography variant="h3" sx={{ color: 'system.text-normal' }}>
      会員情報入力
    </Typography>
    <Typography
      variant="body-main/regular"
      sx={{ color: 'system.text-normal' }}
    >
      本人確認が完了しました。会員情報を入力してください。
    </Typography>
  </Box>
);

const Category: FC<{ children: ReactNode }> = ({ children }) => (
  <Typography
    variant="h5"
    sx={{
      color: 'system/text-normal',
      width: '100%',
      borderBottom: '1px solid red',
      pb: '12px',
      mt: '8px',
    }}
  >
    {children}
  </Typography>
);

const Label: FC<Props> = ({ isPc, flexDirection = 'column', children }) => (
  <Typography
    component="span"
    variant="body-main/bold"
    sx={{
      color: 'system.text-normal',
      width: flexDirection === 'row' || isPc ? '144px' : '100%',
    }}
  >
    {children}
  </Typography>
);

const InsiderText = styled(Typography)(() => ({
  ...theme.typography['body-sub/regular'],
  color: theme.palette.system['text-light'],
}));

const InputValidation = (
  name: string,
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  onChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
) => {
  switch (name) {
    case 'tel':
      if (
        event.target.value === '' ||
        (IsNumeric(event.target.value) && event.target.value.length <= 15)
      )
        onChange(event);
      break;
    case 'zip':
      if (
        event.target.value === '' ||
        (IsNumeric(event.target.value) && event.target.value.length <= 7)
      )
        onChange(event);
      break;
    case 'address2':
    case 'address3':
      if (event.target.value.length <= 50) onChange(event);
      break;
    case 'nameKanji':
    case 'nameKana':
      if (event.target.value.length <= 20) onChange(event);
      break;
    case 'nameKanji2':
    case 'nameKana2':
      if (event.target.value.length <= 20) onChange(event);
      break;
    case 'employeeCode':
      if (
        event.target.value === '' ||
        (ValidateEmployeeCode(event.target.value) &&
          event.target.value.length <= 10)
      )
        onChange(event);
      break;
    default:
      onChange(event);
      break;
  }
};

const Input: FC<{ name: string }> = ({ name }) => {
  const {
    field: { ref, onChange: controllerOnChange, ...rest },
  } = useController({ name });
  const errors = useFormState().errors as {
    [x: string]: { message: string };
  };

  return (
    <TextInput
      {...rest}
      error={!!errors[name]}
      helperText={errors[name]?.message}
      inputRef={ref}
      onChange={(e) => InputValidation(name, e, controllerOnChange)}
    />
  );
};

const LabelAndInput: FC<LAIProps> = ({ label, caution, children }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '4px',
    }}
  >
    {label && (
      <Typography
        variant="body-sub/regular"
        sx={{ color: 'system.text-normal' }}
      >
        {label}
      </Typography>
    )}
    {children}
    {!!caution && (
      <Typography variant="caption/regular" sx={{ color: 'system.text-light' }}>
        {caution}
      </Typography>
    )}
  </Box>
);

const Agree: FC<{ name: string; value: boolean; disabled: boolean }> = ({
  name,
  value,
  disabled,
}) => {
  const {
    field: { ref, ...rest },
  } = useController({ name });
  return (
    <FormControlLabel
      {...rest}
      control={<Checkbox sx={{ p: '0', mr: '8px' }} checked={value} />}
      label="Web利用規約に同意する"
      componentsProps={{ typography: { variant: 'body-main/regular' } }}
      inputRef={ref}
      disabled={disabled}
      sx={{
        m: '0',
        p: '8px 16px',
        color: 'system.text-normal',
        bgcolor: 'secondary.bg',
        borderRadius: '4px',
      }}
    />
  );
};

const Select: FC<SelectBoxProps> = ({ name = '', width, items }) => {
  const {
    field: { ref, ...rest },
  } = useController({ name });
  const errors = useFormState().errors as {
    [x: string]: { message: string };
  };
  return (
    <SelectBox
      {...rest}
      items={items}
      error={!!errors[name]}
      helperText={errors[name]?.message}
      width={width}
      inputRef={ref}
    />
  );
};

const TelType: FC<{ name: string; value: 'MOBILE' | 'HOME' }> = ({
  name,
  value,
}) => {
  const {
    field: { ...rest },
  } = useController({ name });
  return (
    <RadioGroup {...rest} name={name} row value={value}>
      <FormControlLabel
        value="MOBILE"
        control={<Radio />}
        label={
          <Typography
            variant="body-main/regular"
            sx={{ color: 'system.text-normal' }}
          >
            携帯
          </Typography>
        }
      />
      <FormControlLabel
        value="HOME"
        control={<Radio />}
        label={
          <Typography
            variant="body-main/regular"
            sx={{ color: 'system.text-normal' }}
          >
            自宅
          </Typography>
        }
      />
    </RadioGroup>
  );
};

const ItemContainer: FC<{ children: ReactNode }> = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      gap: '8px',
    }}
  >
    {children}
  </Box>
);

const MultiItemContainer: FC<{ children: ReactNode }> = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '8px',
    }}
  >
    {children}
  </Box>
);

const Container: FC<ContainerProps> = ({
  isPc,
  children,
  flexDirection,
  underline = false,
}) => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: flexDirection || isPc ? 'row' : 'column',
      alignItems: 'flex-start',
      pb: underline ? '16px' : '0',
      gap: '8px',
      borderBottom: underline ? '1px solid' : '0',
      borderColor: 'system.separator-light',
    }}
  >
    {children}
  </Box>
);

const TermOfService: FC<Props> = ({ isPc }) => (
  <Box
    id="termOfService"
    sx={{
      height: isPc ? '320px' : '240px',
      overflowY: 'scroll',
      border: '1px solid ',
      borderColor: 'system.separator',
      borderRadius: '8px',
      p: '8px',
    }}
  >
    <Typography variant="body-sub/regular" sx={{ color: 'system.text-normal' }}>
      {TermText.split('\n').reduce<ReactNode[]>(
        (prev, curr, index) => [
          ...prev,
          // eslint-disable-next-line react/no-array-index-key
          <span key={`span_${index}`}>{curr}</span>,
          // eslint-disable-next-line react/no-array-index-key
          <br key={`br_${index}`} />,
        ],
        []
      )}
    </Typography>
  </Box>
);

export type Association = {
  associationCd: string;
  associationName: string;
};

export type SelectBirthDayValue = {
  year: string;
  month: string;
  day: string;
};

type FormContentsProps = Props & {
  association: Association;
  selectBirthValue: SelectBirthDayValue;
  selectPrefValue: string;
  selectTelTypeValue: 'MOBILE' | 'HOME';
  isAgree: boolean;
  dayList: { key: string; value: string }[];
  getAddress: () => void;
  isFormFilled: boolean;
};

export const FormContents: FC<FormContentsProps> = ({
  association = {
    associationCd: '',
    associationName: '',
  },
  selectBirthValue = {
    year: '',
    month: '',
    day: '',
  },
  selectPrefValue,
  selectTelTypeValue = 'MOBILE',
  isAgree = false,
  dayList,
  getAddress,
  isFormFilled,
}) => {
  const { isPc } = useMediaQuery();
  const {
    field: { onChange: onAddress1Change },
  } = useController({ name: 'address1' });
  const [isRead, setIsRead] = useState(false);

  useEffect(() => {
    const scroller = document.getElementById('termOfService');
    if (scroller) {
      scroller.addEventListener('scroll', () => {
        if (
          scroller.scrollHeight - Math.round(scroller.scrollTop) <=
          scroller.clientHeight
        ) {
          setIsRead(true);
        }
      });
    }
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: isPc ? '32px' : '24px 16px',
        gap: isPc ? '40px' : '24px',
        backgroundColor: 'system.white',
        borderRadius: isPc ? '0 0 12px 12px' : '0',
      }}
    >
      <Title />
      <Category>持株会情報</Category>
      <Container flexDirection="row" underline>
        <Label isPc={isPc} flexDirection="row">
          持株会コード
        </Label>
        <Typography
          variant="body-main/regular"
          sx={{ color: 'system/text-normal' }}
        >
          {association.associationCd}
        </Typography>
      </Container>
      <Container flexDirection="row" underline>
        <Label isPc={isPc} flexDirection="row">
          持株会名
        </Label>
        <Typography
          variant="body-main/regular"
          sx={{ color: 'system/text-normal' }}
        >
          {association.associationName}
        </Typography>
      </Container>
      <Category>会員情報</Category>
      <Container isPc={isPc} underline>
        <Label isPc={isPc}>氏名（漢字）</Label>
        <MultiItemContainer>
          <ItemContainer>
            <LabelAndInput caution="※全角最大8文字">
              <Input name="nameKanji" />
            </LabelAndInput>
            <LabelAndInput caution="※全角最大9文字">
              <Input name="nameKanji2" />
            </LabelAndInput>
          </ItemContainer>
        </MultiItemContainer>
      </Container>
      <Container isPc={isPc} underline>
        <Label isPc={isPc}>氏名（カナ）</Label>
        <MultiItemContainer>
          <ItemContainer>
            <LabelAndInput caution="※カタカナ最大8文字">
              <Input name="nameKana" />
            </LabelAndInput>
            <LabelAndInput caution="※カタカナ最大9文字">
              <Input name="nameKana2" />
            </LabelAndInput>
          </ItemContainer>
        </MultiItemContainer>
      </Container>
      <Container isPc={isPc} underline>
        <Label isPc={isPc}>生年月日</Label>
        <MultiItemContainer>
          <ItemContainer>
            <Select
              name="year"
              items={years}
              width="80px"
              value={selectBirthValue.year}
            />
            <Typography
              variant="body-sub/regular"
              sx={{ color: 'system.text-normal', alignSelf: 'center' }}
            >
              年
            </Typography>
            <Select
              name="month"
              items={months}
              width="80px"
              value={selectBirthValue.month}
            />
            <Typography
              variant="body-sub/regular"
              sx={{ color: 'system.text-normal', alignSelf: 'center' }}
            >
              月
            </Typography>
            <Select
              name="day"
              items={dayList}
              width="80px"
              value={selectBirthValue.day}
            />
            <Typography
              variant="body-sub/regular"
              sx={{ color: 'system.text-normal', alignSelf: 'center' }}
            >
              日
            </Typography>
          </ItemContainer>
        </MultiItemContainer>
      </Container>
      <Container isPc={isPc} underline>
        <Label isPc={isPc}>住所</Label>
        <MultiItemContainer>
          <ItemContainer>
            <LabelAndInput label="郵便番号" caution="※ハイフン無し、半角7桁">
              <Input name="zip" />
            </LabelAndInput>
            <SecondaryButton onClick={getAddress} sx={{ mt: '25px' }}>
              住所を自動入力
            </SecondaryButton>
          </ItemContainer>
          <ItemContainer>
            <LabelAndInput label="都道府県">
              <SelectBox
                name="address1"
                items={prefectures}
                value={selectPrefValue}
                width="160px"
                onChange={onAddress1Change}
              />
            </LabelAndInput>
          </ItemContainer>
          <ItemContainer>
            <LabelAndInput label="市区町村" caution="※全角">
              <Input name="address2" />
            </LabelAndInput>
          </ItemContainer>
          <ItemContainer>
            <LabelAndInput label="番地・マンション名など" caution="※全角">
              <Input name="address3" />
            </LabelAndInput>
          </ItemContainer>
        </MultiItemContainer>
      </Container>
      <Container isPc={isPc} underline>
        <Label isPc={isPc}>電話番号</Label>
        <MultiItemContainer>
          <ItemContainer>
            <TelType name="telType" value={selectTelTypeValue} />
          </ItemContainer>
          <ItemContainer>
            <LabelAndInput label="" caution="※半角＋ハイフンなし">
              <Input name="tel" />
            </LabelAndInput>
          </ItemContainer>
        </MultiItemContainer>
      </Container>
      <Container isPc={isPc} underline>
        <Label isPc={isPc}>社員コード</Label>
        <MultiItemContainer>
          <ItemContainer>
            <LabelAndInput caution="※半角英数字最大10文字">
              <Input name="employeeCode" />
            </LabelAndInput>
          </ItemContainer>
        </MultiItemContainer>
      </Container>
      <Category>Web利用規約同意確認</Category>
      <TermOfService isPc={isPc} />
      <Agree name="agree" value={isAgree} disabled={!isRead} />
      <InsiderText>
        本申請はインサイダー情報（未公表の重要事実）を知りながら行うものではありません。
      </InsiderText>
      <PrimaryButton
        type="submit"
        disabled={!isFormFilled}
        sx={{
          width: isPc ? '120px' : '100%',
          padding: '8px 20px',
        }}
      >
        確認へ進む
      </PrimaryButton>
    </Box>
  );
};

export const SignUpUserOrganism: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  useHelmetHandler({
    title: '持株会入会登録_会員情報入力',
  });

  return (
    <Box
      sx={{
        bgcolor: 'system.background-dark',
        padding: {
          xs: '0 0 60px',
          sm: '0 40px 60px',
          md: '0 40px 60px',
          lg: '0 130px 60px',
        },
      }}
    >
      <Box sx={{ maxWidth: '780px', margin: '0 auto' }}>
        <SignUpUpper isSignUp flowOn={2} />
        {children}
      </Box>
    </Box>
  );
};
