import { FC, Suspense } from 'react';
import { useWelcomeFeatureHooks } from '@/features/Home/hooks/useWelcomeFeatureHooks';
import {
  Welcome,
  Messages,
  LowLevelSx,
  HighLevelSx,
  MiddleLevelSx,
} from '@/components/organism/Home/Welcome';
import { useAuthUser } from '@/hooks/useAuth';
import { AccordionBox } from '@/components/organism/Home/MessagesAccordion';
import type { UserSecuritiesAccounts } from '@/components/pages/MyPage/hooks/model';

type Props = {
  isAssociationLoading: boolean;
  // 上場(true) or 非上場(false)
  isListedClassification: boolean;
  userSecuritiesAccounts: UserSecuritiesAccounts | undefined;
  associationCd: string;
};

/**
 * WelcomeFeature
 * @constructor
 */
export const WelcomeFeature: FC<Props> = ({
  isAssociationLoading,
  isListedClassification,
  userSecuritiesAccounts,
  associationCd,
}) => {
  const { user } = useAuthUser();
  const { informationList, fullName, isSecuritiesAccountLoading } =
    useWelcomeFeatureHooks(user);

  return (
    <Welcome
      fullName={fullName}
      isSecuritiesAccountLoading={
        isSecuritiesAccountLoading && isAssociationLoading
      }
      isListedClassification={isListedClassification}
      userSecuritiesAccounts={userSecuritiesAccounts}
      associationCd={associationCd}
    >
      <Suspense fallback={<div />}>
        {informationList && (
          <Messages>
            {informationList.memberInformations?.map((information) => {
              const { priority } = information;
              let levelSx = LowLevelSx;

              if (priority === 'HIGH') levelSx = HighLevelSx;
              if (priority === 'MIDDLE') levelSx = MiddleLevelSx;
              return (
                <AccordionBox
                  key={information.memberInformationId}
                  bgColor={levelSx.backgroundColor}
                  fontColor={levelSx.color}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  message={information}
                />
              );
            })}
          </Messages>
        )}
      </Suspense>
    </Welcome>
  );
};
