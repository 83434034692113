import { formatDate } from '@/utils/dateFunctions';

export type Props = {
  deadlines: {
    applicationType?:
      | 'MONTHLY_UNIT'
      | 'BONUS_UNIT'
      | 'PART'
      | 'RECESS'
      | 'RESUME'
      | 'WITHDRAWAL'
      | 'ADMISSION'
      | 'USER'
      | undefined;
    memberDeadlines?: string[] | undefined;
  }[];
  target:
    | 'MONTHLY_UNIT'
    | 'BONUS_UNIT'
    | 'PART'
    | 'RECESS'
    | 'RESUME'
    | 'WITHDRAWAL'
    | 'ADMISSION'
    | 'USER';
  getMonth?: boolean;
  targetAll?: boolean;
};

export const findDeadLineService = ({
  deadlines,
  target,
  getMonth,
  targetAll,
}: Props) => {
  const day = new Date();
  const targets = [
    'MONTHLY_UNIT',
    'BONUS_UNIT',
    'PART',
    'RECESS',
    'RESUME',
    'WITHDRAWAL',
  ];

  let targetDays = deadlines.find((d) => d.applicationType === target);

  let deadline = '';

  let saveDeadline = ';';
  if (targetAll) {
    targets.forEach((element) => {
      targetDays = deadlines.find((d) => d.applicationType === element);
      saveDeadline =
        targetDays?.memberDeadlines?.find((d) => new Date(d) > day) || '';
      if (
        (saveDeadline !== '' && new Date(deadline) > new Date(saveDeadline)) ||
        deadline === ''
      ) {
        deadline = saveDeadline;
      }
    });
  } else {
    deadline =
      targetDays?.memberDeadlines?.find((d) => new Date(d) > day) || '';
  }

  if (target === 'ADMISSION' || target === 'USER') {
    return '次回';
  }

  if (deadline === '') {
    return '翌年';
  }

  if (getMonth) {
    return `${new Date(deadline).getMonth() + 1}月`;
  }

  return `${formatDate(deadline)}`;
};
