import { Link, styled, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { ReactComponent as WarmingIcon } from '@/assets/warming.svg';
import { BREAKPOINT } from '@/theme/theme';
import { BasicModal } from '@/components/molecules/Modal/BasicModal.tsx';
import { Eventsmemberdeadlines } from '@/types/api/eventsmemberdeadlines';
import { findDeadLineService } from '@/services/findDeadLineService';
import { DeadLine } from '@/components/molecules/Modal/BasicModal.tsx/DeadLine';

const Wrraper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    alignItems: 'flex-start',
    [breakpoints.down(BREAKPOINT)]: {
      padding: '0 18px',
    },
  };
});

const WarmingArea = styled('div')({
  marginLeft: '6px',
});

const CustomLink = styled(Link)(({ theme }) => {
  const { breakpoints } = theme;
  return {
    color: theme.palette.secondary.main,
    textDecorationColor: theme.palette.secondary.main,
    [breakpoints.down(BREAKPOINT)]: {
      display: 'block',
    },
    cursor: 'pointer',
  };
});

interface Props {
  eventsmemberdeadlines: Eventsmemberdeadlines;
  target:
    | 'MONTHLY_UNIT'
    | 'RECESS'
    | 'BONUS_UNIT'
    | 'PART'
    | 'RESUME'
    | 'WITHDRAWAL'
    | 'ADMISSION'
    | 'USER';
}

export const WarmingDeadLine: FC<Props> = ({
  eventsmemberdeadlines,
  target,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  return (
    <>
      <Wrraper>
        <WarmingIcon />
        <WarmingArea>
          <Typography
            variant="body-main/bold"
            sx={{ display: 'block', color: 'states.error' }}
          >
            受付締日：
            {findDeadLineService({
              deadlines: eventsmemberdeadlines,
              target,
            })}
          </Typography>
          <Typography
            variant="body-main/regular"
            sx={{ color: 'states.error' }}
          >
            現在の受付締日を超えた申請は、次回締日での受付となります。
          </Typography>
          <CustomLink onClick={() => setModalOpen(true)}>
            受付締日一覧
          </CustomLink>
        </WarmingArea>
      </Wrraper>

      <BasicModal open={modalOpen}>
        <DeadLine
          deadLine={findDeadLineService({
            deadlines: eventsmemberdeadlines,
            target,
          })}
          eventsmemberdeadlines={eventsmemberdeadlines}
          onClick={() => setModalOpen(false)}
        />
      </BasicModal>
    </>
  );
};
