import { Box, Button, styled } from '@mui/material';
import { FC, ReactNode } from 'react';
import { ReactComponent as RightIcon } from '@/assets/arrowRightPrimary.svg';
import { ReactComponent as LeftIcon } from '@/assets/arrowLeftPrimary.svg';

interface ButtonProps {
  // ボタンサイズ
  size?: 'medium' | 'large';
  // ボタンのテキスト
  children: ReactNode;
  // クリック時の処理
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  // 非活性化
  disabled?: boolean;
  // アイコンの位置
  icon?: 'left' | `right`;
  // スタイル
  sx?: object;
  wrapperSx?: object;
}

const MuiButton = styled(Button)(({ theme }) => {
  const { typography, palette } = theme;
  return {
    ...typography['body-sub/medium'],
    color: palette.primary.normal,
    textDecoration: 'underline',
    padding: '0',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
    '&:disabled': {
      color: palette.system.inactive,
      textDecoration: 'none',
    },
  };
});

const IconWrapper = styled(Box)({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  gap: '4px',
  height: '20px',
  borderRadius: '24px',
});

export const TextButton: FC<ButtonProps> = ({
  children,
  size = 'medium',
  onClick,
  disabled,
  icon = '',
  wrapperSx,
  sx,
}) => {
  if (icon) {
    return (
      <IconWrapper sx={wrapperSx}>
        {icon === 'left' && <LeftIcon />}
        <MuiButton
          variant="text"
          size={size}
          onClick={onClick}
          disabled={disabled}
          sx={sx}
        >
          {children}
        </MuiButton>
        {icon === 'right' && <RightIcon />}
      </IconWrapper>
    );
  }
  return (
    <MuiButton
      variant="text"
      size={size}
      onClick={onClick}
      disabled={disabled}
      sx={sx}
    >
      {children}
    </MuiButton>
  );
};
