import { LoginBox } from '@/components/molecules/Login/LoginBox';
import { LoginTitleWithUnderline } from '@/components/molecules/Login/LoginTitleWithUnderline';
import { FC, useState } from 'react';
import { styled } from '@mui/material';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { TextButton } from '@/components/atoms/Buttons/TextButton';
import { useNavigate } from 'react-router-dom';
import { appClient } from '@/services';
import { Path } from '@/constants/Router/path';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginLabelWithTextInput } from '@/components/molecules/Login/LoginLabelWithTextInput';
import { LoginLabelWithPasswordInput } from '@/components/molecules/Login/LoginLabelWithPasswordInput';
import { ErrMessageWithIcon } from '@/components/molecules/Message/ErrMessageWithIcon';
import { BREAKPOINT } from '@/theme/theme';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { LoginErrorMessage } from './LoginErrorMessage';

const ForgetWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0px',
  gap: '8px',
});

const ButtonBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '8px',
  gap: '24px',
  width: '100%',
  [theme.breakpoints.down(BREAKPOINT)]: {
    padding: 0,
    gap: '24px',
    alignItems: 'flex-start',
    '& button': {
      width: '100%',
    },
  },
}));

// エラーメッセージ
let errorMessage = ['ログインIDまたはパスワードが違います。'];
const lockErrorMessage =
  'ログインに5回失敗したため、一時的にロックされました。\nこのロックは数分後に解除されます。';

// フォーム
type FormInputState = {
  loginId: string;
  loginPassword: string;
};

// バリデーションルール
const schema = yup.object({
  loginId: yup
    .string()
    .required('有効なメールアドレスを入力してください')
    .email('有効なメールアドレスを入力してください'),
  loginPassword: yup.string().required('入力してください').min(6),
});

export const LoginIndex: FC = () => {
  const navigate = useNavigate();
  useHelmetHandler({
    title: 'ログイン',
  });
  const [isError, setIsError] = useState<boolean>(false);
  const [isLockError, setIsLockError] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormInputState>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormInputState) => {
    // バリデーションチェック
    // 画面遷移

    try {
      const signinResponse = await appClient.authorization.authSignin({
        loginId: data.loginId,
        loginPassword: data.loginPassword,
      });

      navigate(Path.LOGIN_MFA, {
        state: {
          loginId: data.loginId,
          loginPassword: data.loginPassword,
          mfaSession: signinResponse.Session,
        },
      });
    } catch (error) {
      // 5回失敗した時のエラーメッセージ
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (error.status === 408) {
        errorMessage = [
          'ログインに5回失敗したため、一時的にロックされました。',
          'このロックは数分後に解除されます。',
        ];
        setIsLockError(true);
        setIsError(false);
      } else {
        setIsError(true);
        setIsLockError(false);
      }
    }
  };

  return (
    <LoginBox>
      <LoginTitleWithUnderline isLogin title="ログイン" />
      {isError && <ErrMessageWithIcon errorMessage={errorMessage} isBorder />}
      {isLockError && (
        <LoginErrorMessage
          message={lockErrorMessage}
          sx={{ marginBottom: 3 }}
        />
      )}
      <LoginLabelWithTextInput
        label="ログインID"
        annotation="※メールアドレス"
        error={'loginId' in errors}
        helperText=""
        placeholder="例：abcdef@mail.com"
        {...register('loginId')}
      />
      <LoginLabelWithPasswordInput
        label="パスワード"
        error={'loginPassword' in errors}
        helperText=""
        placeholder="パスワードを入力"
        {...register('loginPassword')}
      />
      <ButtonBox>
        <PrimaryButton
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleSubmit(onSubmit)}
          disabled={
            errors.loginId?.message !== undefined ||
            errors.loginPassword?.message !== undefined
          }
        >
          ログイン
        </PrimaryButton>
      </ButtonBox>
      <ForgetWrapper>
        <TextButton icon="right" onClick={() => navigate(Path.LOGIN_MAIL)}>
          メールアドレスを忘れた方はこちら
        </TextButton>
        <TextButton icon="right" onClick={() => navigate(Path.LOGIN_PASSWORD)}>
          パスワードを忘れた方はこちら
        </TextButton>
      </ForgetWrapper>
      <ForgetWrapper>
        <TextButton icon="right" onClick={() => navigate(Path.SIGNUP)}>
          新規入会はこちら
        </TextButton>
      </ForgetWrapper>
    </LoginBox>
  );
};
