/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import { DownloadButton } from '@/components/atoms/Buttons/DownloadButton';
import {
  ListDividendPaymentCertificate,
  ListEquitySettlementForm,
} from '@/hooks/api/useReports';
import { styled, Theme, useMediaQuery } from '@mui/material';
import { FC } from 'react';

const BREAKPOINT = 'sm';

const Wrapper = styled('div')(({ theme }) => {
  const { breakpoints, palette } = theme;
  return {
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '12px',
    backgroundColor: palette.system.white,
    width: '100%',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    [breakpoints.down(BREAKPOINT)]: {
      border: 0,
      borderRadius: 0,
    },
  };
});

const Index = styled('div')(({ theme }) => {
  const { palette } = theme;
  return {
    backgroundColor: palette.system.background,
    borderRadius: '8px',
    padding: '8px 16px',
    display: 'flex',
    gap: '16px',
    color: palette.system['text-light'],
  };
});

const Name = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '23%',
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
    },
  };
});

const NameWithStrong = styled('div')(({ theme }) => {
  const { breakpoints, typography } = theme;
  return {
    ...typography['body-main/bold'],
    width: '23%',
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
      marginBottom: '4px',
    },
  };
});

const Description = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '67%',
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
      marginBottom: '16px',
    },
  };
});

const Download = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '20%',
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
    },
  };
});

const Content = styled('div')(({ theme }) => {
  const { breakpoints, typography, palette } = theme;
  return {
    ...typography['caption/regular'],
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px',
    color: palette.system['text-light'],
    [breakpoints.down(BREAKPOINT)]: {
      padding: '0px',
    },
  };
});

const Row = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    width: '100%',
    gap: '16px',
    alignItems: 'start',
    [breakpoints.down(BREAKPOINT)]: {
      flexDirection: 'column',
    },
  };
});

const Border = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '100%',
    margin: '12px 0',
    paddingTop: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.13)',
    [breakpoints.down(BREAKPOINT)]: {
      margin: '16px 0',
    },
  };
});

interface Props {
  dataDividend?: ListDividendPaymentCertificate;
  dataEquity?: ListEquitySettlementForm;
  downloadEquitySettlementFormReport: (
    year: string,
    month: string,
    day: string
  ) => void;
  downloadDividendPaymentCertificateReport: (reportId: number) => void;
  downloadWithdrawalSettlementFormReport: (
    reportType: 'settlement' | 'dividend'
  ) => void;
  isWithdrawn?: boolean;
}

export const ReportTable: FC<Props> = ({
  dataDividend,
  dataEquity,
  downloadEquitySettlementFormReport,
  downloadDividendPaymentCertificateReport,
  downloadWithdrawalSettlementFormReport,
  isWithdrawn,
}) => {
  const isSp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(BREAKPOINT)
  );

  console.log(dataDividend, downloadDividendPaymentCertificateReport);

  return (
    <Wrapper>
      {!isSp && (
        <Index>
          <Name>帳票名</Name>
          <Description>説明</Description>
          <Download>ダウンロード</Download>
        </Index>
      )}
      {dataEquity &&
        dataEquity.reports &&
        dataEquity.reports.map((report, index) => {
          const date = report.contractYmd
            ? new Date(String(report.contractYmd))
            : new Date();
          return (
            <Content key={report.contractYmd}>
              {index !== 0 && <Border />}
              <Row>
                <NameWithStrong>
                  持分精算書({`${date.getMonth() + 1}月${date.getDate()}日`})
                </NameWithStrong>
                <Description>
                  株式を引出しした際の引出株数、取得単価を確認できます。
                </Description>
                <Download>
                  <DownloadButton
                    sx={{ width: '100%' }}
                    onClick={() =>
                      downloadEquitySettlementFormReport(
                        String(date.getFullYear()),
                        ('0' + (date.getMonth() + 1).toString()).slice(-2),
                        ('0' + date.getDate().toString()).slice(-2)
                      )
                    }
                  />
                  {/* {report.contractYmd} */}
                </Download>
              </Row>
            </Content>
          );
        })}
      {isWithdrawn && (
        <>
          <Content>
            {!!dataEquity?.reports && <Border />}
            <Row>
              <NameWithStrong>退会者精算金精算書</NameWithStrong>
              <Description>
                持株会を退会した際に精算金を確認することができます。
              </Description>
              <Download>
                <DownloadButton
                  sx={{ width: '100%' }}
                  onClick={() =>
                    downloadWithdrawalSettlementFormReport('settlement')
                  }
                />
              </Download>
            </Row>
          </Content>
          <Content>
            <Border />
            <Row>
              <NameWithStrong>退会者配当金精算書</NameWithStrong>
              <Description>
                持株会を退会した際に配当金を確認することができます。
              </Description>
              <Download>
                <DownloadButton
                  sx={{ width: '100%' }}
                  onClick={() =>
                    downloadWithdrawalSettlementFormReport('dividend')
                  }
                />
              </Download>
            </Row>
          </Content>
        </>
      )}
      {dataDividend &&
        dataDividend?.reports?.map((report, index) => (
          <Content key={report.dividendPaymentCertificateReportId}>
            {!!(
              isWithdrawn ||
              dataEquity?.reports?.length !== 0 ||
              index !== 0
            ) && <Border />}
            <Row>
              <NameWithStrong>配当金支払証明書兼支払通知書</NameWithStrong>
              <Description>
                1月1日から12月31日までに受け取った配当金に関する証明書です。確定申告に使用できます。
                ※配当金支払証明書兼支払通知書は、今年度分が発行されるまでの期間はダウンロード可能です。
              </Description>
              <Download>
                <DownloadButton
                  sx={{ width: '100%' }}
                  onClick={() =>
                    downloadDividendPaymentCertificateReport(
                      // TODO
                      // report.dividendPaymentCertificateReportId || 0
                      0
                    )
                  }
                />
              </Download>
            </Row>
          </Content>
        ))}
    </Wrapper>
  );
};
