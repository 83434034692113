import { atom } from 'recoil';

export const ApplicationSortKeyState = atom({
  key: 'applicationSortKey',
  default: 'APPLICATION_ID_DESC' as ApplicationSortBy,
});

export type ApplicationSortBy =
  | 'APPLICATION_ID_ASC'
  | 'APPLICATION_ID_DESC'
  | 'APPLICATION_TYPE_ASC'
  | 'APPLICATION_TYPE_DESC'
  | 'APPLICATION_DATE_ASC'
  | 'APPLICATION_DATE_DESC'
  | 'APPLICATION_STATUS_ASC'
  | 'APPLICATION_STATUS_DESC';
