import { AssociationContribution } from '@/types/api/associationContribution';

export const ConvertAssociationContribution = (
  data:
    | {
        associationCd?: string;
        unitAmount?: number;
        monthlyUnitClassification?: string;
        maxMonthlyUnit?: number;
        monthlyPurchaceCalculationClassification?: string;
        bonusUnitClassification?: string;
        maxBonusUnit?: number;
        bonusCalculationMethod?: string;
        bonusMultiple?: number;
        bonusMonth1?: string;
        bonusMonth2?: string;
        bonusMonth3?: string;
        bonusMonth4?: string;
        bonusMonth5?: string;
        bonusMonth6?: string;
        bonusMonth7?: string;
        bonusMonth8?: string;
        bonusMonth9?: string;
        bonusMonth10?: string;
        bonusMonth11?: string;
        bonusMonth12?: string;
        monthlyIncentiveMethod?: string;
        monthlyIncentiveCalculationType?: string;
        monthlyIncentiveCalculationBaseMonth?: string;
        monthlyIncentiveRatio?: number;
        monthlyIncentiveFixedAmount?: number;
        monthlyIncentiveUpperLimit?: number;
        bonusIncentiveMethod?: string;
        bonusIncentiveRatio?: number;
        bonusIncentiveFixedAmount?: number;
        bonusIncentiveUpperLimit?: number;
        bonusReserveClassification?: string;
        bonusMultiplierDesignationType?:
          | 'UNIT_NUMBER'
          | 'MAGNIFICATION'
          | 'FIXED_MAGNIFICATION';
        bonusMultiplierDetails?: Array<number>;
        monthlyPurchaseDateUsageType?: 'STAR' | 'WEB';
        monthlyIncentiveSpecialConditions?: string;
        bonusIncentiveSpecialConditions?: string;
        unitMonth1?: string;
        unitMonth2?: string;
        unitMonth3?: string;
        unitMonth4?: string;
        unitMonth5?: string;
        unitMonth6?: string;
        unitMonth7?: string;
        unitMonth8?: string;
        unitMonth9?: string;
        unitMonth10?: string;
        unitMonth11?: string;
        unitMonth12?: string;
        newUserMonth1?: string;
        newUserMonth2?: string;
        newUserMonth3?: string;
        newUserMonth4?: string;
        newUserMonth5?: string;
        newUserMonth6?: string;
        newUserMonth7?: string;
        newUserMonth8?: string;
        newUserMonth9?: string;
        newUserMonth10?: string;
        newUserMonth11?: string;
        newUserMonth12?: string;
      }
    | undefined
) =>
  data
    ? ({
        associationCd: data.associationCd || '',
        unitAmount: data.unitAmount || 0,
        monthlyUnitClassification: data.monthlyUnitClassification || '',
        maxMonthlyUnit: data.maxMonthlyUnit || 0,
        monthlyPurchaceCalculationClassification:
          data.monthlyPurchaceCalculationClassification || '',
        bonusUnitClassification: data.bonusUnitClassification || '',
        maxBonusUnit: data.maxBonusUnit || 0,
        bonusCalculationMethod: data.bonusCalculationMethod || '',
        bonusMultiple: data.bonusMultiple || 0,
        bonusMonth1: data.bonusMonth1 || '',
        bonusMonth2: data.bonusMonth2 || '',
        bonusMonth3: data.bonusMonth3 || '',
        bonusMonth4: data.bonusMonth4 || '',
        bonusMonth5: data.bonusMonth5 || '',
        bonusMonth6: data.bonusMonth6 || '',
        bonusMonth7: data.bonusMonth7 || '',
        bonusMonth8: data.bonusMonth8 || '',
        bonusMonth9: data.bonusMonth9 || '',
        bonusMonth10: data.bonusMonth10 || '',
        bonusMonth11: data.bonusMonth11 || '',
        bonusMonth12: data.bonusMonth12 || '',
        monthlyIncentiveMethod: data.bonusIncentiveMethod || '',
        monthlyIncentiveCalculationType:
          data.monthlyIncentiveCalculationType || '',
        monthlyIncentiveCalculationBaseMonth:
          data.monthlyIncentiveCalculationBaseMonth || '',
        monthlyIncentiveRatio: data.monthlyIncentiveRatio || 0,
        monthlyIncentiveFixedAmount: data.monthlyIncentiveFixedAmount || 0,
        monthlyIncentiveUpperLimit: data.monthlyIncentiveUpperLimit || 0,
        bonusIncentiveMethod: data.bonusIncentiveMethod || '',
        bonusIncentiveRatio: data.bonusIncentiveRatio || 0,
        bonusIncentiveFixedAmount: data.bonusIncentiveFixedAmount || 0,
        bonusReserveClassification: data.bonusReserveClassification || '',
        bonusMultiplierDesignationType:
          data.bonusMultiplierDesignationType || 'UNIT_NUMBER',
        bonusMultiplierDetails: data.bonusMultiplierDetails || [],
        monthlyPurchaseDateUsageType:
          data.monthlyPurchaseDateUsageType || 'STAR',
        monthlyIncentiveSpecialConditions:
          data.monthlyIncentiveSpecialConditions || '',
        bonusIncentiveSpecialConditions:
          data.bonusIncentiveSpecialConditions || '',
        unitMonth1: data.unitMonth1 || '',
        unitMonth2: data.unitMonth2 || '',
        unitMonth3: data.unitMonth3 || '',
        unitMonth4: data.unitMonth4 || '',
        unitMonth5: data.unitMonth5 || '',
        unitMonth6: data.unitMonth6 || '',
        unitMonth7: data.unitMonth7 || '',
        unitMonth8: data.unitMonth8 || '',
        unitMonth9: data.unitMonth9 || '',
        unitMonth10: data.unitMonth10 || '',
        unitMonth11: data.unitMonth11 || '',
        unitMonth12: data.unitMonth12 || '',
        newUserMonth1: data.newUserMonth1 || '',
        newUserMonth2: data.newUserMonth2 || '',
        newUserMonth3: data.newUserMonth3 || '',
        newUserMonth4: data.newUserMonth4 || '',
        newUserMonth5: data.newUserMonth5 || '',
        newUserMonth6: data.newUserMonth6 || '',
        newUserMonth7: data.newUserMonth7 || '',
        newUserMonth8: data.newUserMonth8 || '',
        newUserMonth9: data.newUserMonth9 || '',
        newUserMonth10: data.newUserMonth10 || '',
        newUserMonth11: data.newUserMonth11 || '',
        newUserMonth12: data.newUserMonth12 || '',
        bonusIncentiveUpperLimit: data.bonusIncentiveUpperLimit || 0,
      } as AssociationContribution)
    : undefined;
