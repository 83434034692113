export const Path = {
  HOME: '/',
  // 申請履歴'
  APPLICATIONS: '/applications',
  // 資産状況
  ASSETS: '/assets',
  // 口数変更（給与）
  PAY: '/pay',
  // 口数変更（給与）確認
  PAY_CONFIRM: '/pay/confirm',
  // 口数変更（給与）完了
  PAY_COMPLETE: '/pay/complete',
  // 口数変更（賞与）
  BONUS: '/bonus',
  // 口数変更（賞与）確認
  BONUS_CONFIRM: '/bonus/confirm',
  // 口数変更（賞与）完了
  BONUS_COMPLETE: '/bonus/complete',
  // 一部引出
  PART: '/part',
  // 一部引出確認
  PART_CONFIRM: '/part/confirm',
  // 一部引出完了
  PART_COMPLETE: '/part/complete',
  // 休止
  RECESS: '/recess',
  // 休止確認
  RECESS_CONFIRM: '/recess/confirm',
  // 休止完了
  RECESS_COMPLETE: '/recess/complete',
  // 再開
  RESUME: '/resume',
  // 再開確認
  RESUME_CONFIRM: '/resume/confirm',
  // 再開完了
  RESUME_COMPLETE: '/resume/complete',
  // 退会
  WITHDRAWAL: '/withdrawal',
  // 退会入力
  WITHDRAWAL_INPUT: '/withdrawal/input',
  // 退会確認
  WITHDRAWAL_CONFIRM: '/withdrawal/confirm',
  // 退会完了
  WITHDRAWAL_COMPLETE: '/withdrawal/complete',
  // シミュレーション
  SIMULATION: '/simulation',
  // 電子交付サービス
  REPORT: '/report',
  // mypage
  MYPAGE: '/mypage',
  // 会員情報変更 パスワード認証
  MYPAGE_USER: '/mypage/auth?next=user',

  // サービス
  SERVICE: '/service',
  // よくあるご質問
  FAQ: '/faq',
  // お問い合わせ
  CONTACT: '/contact',
  // ご利用ガイド
  GUIDE: '/guide',
  // 持株会の仕組み
  STRUCTURE: '/structure',
  // エラーメッセージ画面
  ERROR: '/error',

  NOT_FOUND: '/not-found',

  // ログイン
  LOGIN: '/login',
  // 多要素認証
  LOGIN_MFA: '/login/mfa',
  // メールアドレスを忘れた方
  LOGIN_MAIL: '/login/mail',
  // メールアドレスを忘れた方の完了
  LOGIN_MAIL_COMPLETE: '/login/mail/complete',
  // パスワードを忘れた方
  LOGIN_PASSWORD: '/login/password',
  // パスワードMFA
  LOGIN_PASSWORD_MFA: '/login/password/mfa',
  // パスワード変更
  LOGIN_PASSWORD_CHANGE: '/login/password/change',
  // パスワード変更完了
  LOGIN_PASSWORD_COMPLETE: '/login/password/complete',
  // 会員情報入力
  CREATE_USER_INPUT: '/signup/user',
  // 会員情報確認
  CREATE_USER_CONFIRM: '/signup/user/confirm',
  // 会員情報確認完了
  CREATE_USER_COMPLETE: '/signup/user/complete',
  // 持株会員アカウント登録
  SIGNUP_ACCOUNT: '/signup/account',
  // 持株会員新規入会
  SIGNUP_JOIN: '/signup/join',
  // 持株会員本人メールアドレス確認
  SIGNUP_MAIL: '/signup/mail',
  // 持株会員多要素認証
  SIGNUP_MFA: '/signup/mfa',
  // 他社移行ログイン/ID・PW登録
  EXISTING_LOGIN_USER: '/existing-login/user',

  // 拠出設定入力画面
  CONTRIBUTION_INPUT: '/signup/contribution',
  // 拠出設定確認画面
  CONTRIBUTION_CONFIRM: '/signup/contribution/confirm',
  // 拠出設定完了画面
  CONTRIBUTION_COMPLETE: '/signup/contribution/complete',

  SIGNUP: '/signup',
  EXISTING_LOGIN: '/existing-login',

  // 口座をお持ちでない方はこちらから開設
  ACCOUNTING_OPEN: 'https://www.tokaitokyo.co.jp/kantan/request/index.html',
  // 店舗案内
  STORE_NAVIGATION: 'https://www.tokaitokyo.co.jp/anshin/shop/index.html',
};

// public url
// ログインが不要なページ一覧
export const PUBLIC_PATHS = [
  Path.LOGIN,
  Path.LOGIN_MFA,
  Path.LOGIN_MAIL,
  Path.LOGIN_MAIL_COMPLETE,
  Path.LOGIN_PASSWORD,
  Path.LOGIN_PASSWORD_CHANGE,
  Path.LOGIN_PASSWORD_COMPLETE,
  Path.SIGNUP,
  Path.CREATE_USER_INPUT,
  Path.CREATE_USER_CONFIRM,
  Path.CREATE_USER_COMPLETE,
  Path.SIGNUP_ACCOUNT,
  Path.SIGNUP_JOIN,
  Path.SIGNUP_MAIL,
  Path.SIGNUP_MFA,
  Path.EXISTING_LOGIN,
  Path.EXISTING_LOGIN_USER,
  Path.CONTRIBUTION_INPUT,
  Path.CONTRIBUTION_CONFIRM,
  Path.CONTRIBUTION_COMPLETE,
  Path.ERROR,
];
