/**
 * numberFormat
 * @param num
 * @param  オプションを使い小数点以下の桁数を保持する (※最大 20桁)
 */
export const numberFormat = (
  num: number,
  options: Intl.NumberFormatOptions = { maximumFractionDigits: 20 },
  floor?: boolean
): string => {
  if (floor) {
    return Math.floor(num).toLocaleString(undefined, options);
  }
  return num.toLocaleString(undefined, options);
};
