import { theme } from '@/theme/theme';
import { LocationState } from '@/components/pages/Withdrawal/WithdrawalInput';
import { Box, Typography, styled } from '@mui/material';
import { CSSProperties, FC } from 'react';
import { BountyCalculation } from '@/utils/calculation';
import { InfoRow } from '../infoRow';

interface PdfContentProps {
  title: string;
  data: LocationState;
  type: 'MONTHLY' | 'BONUS';
}

const Container = styled(Box)(() => ({
  marginTop: 24,
  '@media print': {
    marginTop: 24,
  },
}));

const ContainerTitle = styled(Typography)(() => ({
  ...theme.typography.h2,
  fontWeight: 500,
  paddingBottom: 12,
  borderWidth: 0,
  fontSize: 26,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: '#000000',
  [theme.breakpoints.up('sm')]: {
    fontSize: 26,
  },
  '@media print': {
    ...theme.typography.h2,
    fontWeight: 500,
    paddingBottom: 12,
    borderWidth: 0,
    fontSize: 21,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    [theme.breakpoints.up('sm')]: {
      fontSize: 21,
    },
  },
}));

const ContainerContent = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  '@media print': {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
}));

const ContainerContenstWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 20,
  '@media print': {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
  },
}));

const ContainerBorderStyle: CSSProperties = {
  borderRadius: '12px',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '#000000',
  padding: '16px',
};

export const MonthlyAndBonusContent: FC<PdfContentProps> = ({
  title,
  data,
  type,
}) => {
  const getIncentiveRatio = () => {
    const {
      associationContribution: { bonusIncentiveRatio, monthlyIncentiveRatio },
    } = data;
    return type === 'BONUS' ? bonusIncentiveRatio : monthlyIncentiveRatio;
  };

  const getBonusUnitAmounText = () => {
    let text = `口数 ${Number(data.bonusUnitAmount) || 0}口`;
    if (
      data.associationContribution.bonusMultiplierDesignationType !==
      'UNIT_NUMBER'
    ) {
      const rate: number =
        data.associationContribution.bonusMultiplierDesignationType ===
        'FIXED_MAGNIFICATION'
          ? data.associationContribution.bonusMultiplierDetails[0]
          : data.bonusRate;
      text = `${text} (倍率: ${rate}倍)`;
    }
    return text;
  };

  return (
    <Container
      sx={{
        ...ContainerBorderStyle,
        marginTop: '40px',
        '@media print': {
          padding: '12px',
          borderRadius: '6px',
        },
      }}
    >
      <ContainerTitle>{title}</ContainerTitle>
      <ContainerContenstWrapper>
        <ContainerContent>
          <InfoRow
            key="1口あたり金額"
            title="1口あたり金額"
            content={`${data.associationContribution.unitAmount.toLocaleString()}円`}
          />
          <InfoRow
            key="奨励金率"
            title="奨励金率"
            content={`${getIncentiveRatio()}%`}
          />
          <InfoRow
            key="給与"
            title={['給与', '※毎月の拠出']}
            content={[
              `口数 ${data.bonusUnit || 0}口`,
              `拠出金額 ${(
                (data.bonusUnit || 0) * data.associationContribution.unitAmount
              ).toLocaleString()}円`,
              `奨励金額 ${BountyCalculation(
                data.type as 'MONTHLY' | 'BONUS',
                data.associationContribution,
                data.bonusUnit || 0
              ).toLocaleString()}円`,
            ]}
          />

          <InfoRow
            key="賞与"
            title={['賞与']}
            content={
              data.isBonus
                ? [
                    '拠出する',
                    `${getBonusUnitAmounText()}`,
                    `拠出金額 ${(
                      data.bonusContributionAmount *
                      data.associationContribution.unitAmount
                    ).toLocaleString()}円`,
                    `奨励金額 ${data.bonusIncentive.toLocaleString()}円`,
                  ]
                : ['拠出しない']
            }
            disableSeparator
          />
        </ContainerContent>
      </ContainerContenstWrapper>
    </Container>
  );
};
