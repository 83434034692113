/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useLoader } from '@/hooks';
import { useCallback, useEffect } from 'react';
import { appClient, AUTH_TOKEN } from '@/services';
import { Path } from '@/constants/Router/path';
import { useNavigate } from 'react-router-dom';
import { useAuthUser, userModel } from '@/hooks/useAuth';
import { FindUser } from '@/types/api/users';

/**
 * BootStrapPage
 * public側でRoot URLのページ
 * リロードなどの時にpublic側でRootに入ってしまい空白になってしまう際
 * 再検証して遷移先を決める
 */
export const BootStrapPage = () => {
  const navigate = useNavigate();
  const { setUser, setIsAuthenticated } = useAuthUser();
  const { handleLoaderOpen, handleLoaderClose } = useLoader();

  const getUser = useCallback(async () => {
    try {
      const user = await appClient.users.findUser();
      setUser(userModel(user));
      setIsAuthenticated(true);
      // もしデータが取得できたらもう一度Homeへ(private側になる)
      navigate(Path.HOME, { replace: true });
    } catch (e) {
      console.log('BootStrapPage:getUser:Error', e);
      // @ts-ignore
      if (e.status === 401) {
        // ErrorになったらLoginへ遷移させ直す
        setUser({} as FindUser);
        setIsAuthenticated(false);
        localStorage.removeItem(AUTH_TOKEN);
        navigate(Path.LOGIN);
      } else {
        navigate(Path.ERROR);
      }
    }
  }, []);

  useEffect(() => {
    handleLoaderOpen();
    void getUser()
      .then(() => {
        handleLoaderClose();
      })
      .catch(() => {
        handleLoaderClose();
      });
  }, [getUser]);

  return <div />;
};
