import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { BREAKPOINT } from '@/theme/theme';
import { Box, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import { FC } from 'react';

const styleContainer: SxProps<Theme> = [
  {
    width: '343px',
    height: '152px',
    textAlign: 'center',
    p: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
  },
  (theme) => ({
    [theme.breakpoints.up(BREAKPOINT)]: {
      width: '520px',
      height: '152px',
      p: '32px',
    },
  }),
];

type Props = {
  onClick: () => void;
};

export const CancelComplete: FC<Props> = ({ onClick }) => {
  const isPc = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(BREAKPOINT)
  );
  const width = isPc ? '64px' : '295px';
  const height = isPc ? '40px' : '56px';
  const borderRadius = isPc ? '20px' : '28px';

  return (
    <Box sx={styleContainer}>
      <Typography
        component="p"
        variant="body-main/regular"
        sx={{ color: 'system.text-normal' }}
      >
        申請を取り消しました。
      </Typography>
      <PrimaryButton sx={{ width, height, borderRadius }} onClick={onClick}>
        OK
      </PrimaryButton>
    </Box>
  );
};
