/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useEffect } from 'react';
import useSWR from 'swr';
import { appClient, UserDomainService } from '@/services';
import type { UserSecuritiesAccounts } from '@/types/api/userSecuritiesAccounts';

import { FindUser } from '@/types/api/users';
import { userSecuritiesAccountsModel } from './model';

/**
 * useWelcomeFeatureHooks
 * @param user
 */
export const useWelcomeFeatureHooks = (user: FindUser) => {
  const { associationCd = '' } = user;
  const [userSecuritiesAccounts, setUserSecuritiesAccounts] = useState<
    UserSecuritiesAccounts | undefined
  >(undefined);

  // 【API-A-1101】持株会員お知らせ一覧取得
  const { data, error: informationListError } = useSWR(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `/api/memberinformations?associationCd=${associationCd}`,
    () => appClient.memberInformations.listMemberInformations()
  );

  // 【API-A-0906】証券口座情報取得
  const {
    data: userSecuritiesAccountsData,
    error: userSecuritiesAccountsDataError,
  } = useSWR('/api/securitiesaccounts', () =>
    appClient.users.findSecuritiesAccount()
  );

  useEffect(() => {
    if (userSecuritiesAccountsData) {
      const securitiesAccount = userSecuritiesAccountsModel(
        userSecuritiesAccountsData
      );
      setUserSecuritiesAccounts(securitiesAccount);
    }
  }, [userSecuritiesAccountsData]);

  // // data fetching

  return {
    informationList: data,
    informationListError,
    userSecuritiesAccountsDataError,
    fullName: UserDomainService.getFullName(user),
    isSecuritiesAccountLoading:
      !!userSecuritiesAccountsData?.branchCd ||
      userSecuritiesAccountsData?.fixedFlg ||
      !!userSecuritiesAccountsDataError,
    userSecuritiesAccounts,
  };
};
