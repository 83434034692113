import { Box, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import { FC, ReactNode } from 'react';
import { BREAKPOINT } from '@/theme/theme';
import { PrintButton } from '@/components/atoms/Buttons/PrintButton';
import {
  BreadCrumbs,
  Props as BreadCrumbsProps,
} from '../../molecules/Title/BreadCrumbs';

interface HomeHeaderProps {
  title?: string;
  subTitle?: string;
  description?: ReactNode[];
  lastLogin?: string;
  sx?: SxProps<Theme>;
  printOnClick?: () => void;
}

interface Props {
  isPc: boolean;
  children: ReactNode;
}

const Wrraper: FC<Props & { sx: SxProps<Theme> }> = ({
  isPc,
  children,
  sx,
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: isPc ? '0' : '0 16px',
      gap: isPc ? '24px' : '16px',
      ...sx,
    }}
  >
    {children}
  </Box>
);

const Title: FC<Pick<Props, 'children'>> = ({ children }) => (
  <Typography
    variant="h3"
    sx={{ color: 'system.text-normal', whiteSpace: 'nowrap' }}
  >
    {children}
  </Typography>
);

const Titles: FC<Props> = ({ isPc, children }) => (
  <Box
    sx={{
      display: isPc ? 'flex' : 'block',
      flexDirection: 'row',
      alignItems: 'flex-end',
      gap: '8px',
      height: isPc ? '30px' : 'auto',
    }}
  >
    {children}
  </Box>
);

const TitlesButton: FC<{ children: ReactNode; isPc: boolean }> = ({
  isPc,
  children,
}) => (
  <Box
    sx={{
      width: '100%',
      display: isPc ? 'flex' : 'block',

      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      gap: '32px',
    }}
  >
    {children}
  </Box>
);

const DirectionSwitcher: FC<Props & { type: 'description' | 'lastLogin' }> = ({
  isPc,
  children,
  type,
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: isPc ? 'row' : 'column',
      alignItems: type === 'lastLogin' && isPc ? 'flex-end' : 'flex-start',
      gap: type === 'description' ? '16px' : '8px',
    }}
  >
    {children}
  </Box>
);

const LastLogin: FC<{ children: ReactNode }> = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      gap: '8px',
    }}
  >
    {children}
  </Box>
);

export const HomeHeader: FC<HomeHeaderProps & BreadCrumbsProps> = ({
  title,
  subTitle,
  description,
  lastLogin,
  links,
  sx = {},
  printOnClick,
}) => {
  const isPc = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(BREAKPOINT)
  );

  return (
    <Wrraper isPc={isPc} sx={sx}>
      <Box display="flex">
        <BreadCrumbs links={links} />
      </Box>
      {!subTitle && !description && !lastLogin && <Title>{title}</Title>}
      {!!subTitle && (
        <TitlesButton isPc={isPc}>
          <Titles isPc={isPc}>
            <Title>{title}</Title>
            <Typography
              variant="h4"
              sx={{
                color: 'system.text-normal',
                marginTop: isPc ? '0' : '8px',
              }}
            >
              {subTitle}
            </Typography>
          </Titles>
          {isPc && (
            <PrintButton
              onClick={() => {
                if (printOnClick) printOnClick();
              }}
            />
          )}
        </TitlesButton>
      )}
      {!!description && (
        <DirectionSwitcher isPc={isPc} type="description">
          <Title>{title}</Title>
          <Typography
            component="p"
            variant="body-main/regular"
            sx={{ color: 'system.text-normal' }}
          >
            {/**
             * 配列の各要素にidがなく、順番が変わることのない静的な配列のためLintを無効化
             */}
            {/* eslint-disable react/no-array-index-key */}
            {description.map((d, i) => (
              <div key={i}>{d}</div>
            ))}
            {/* eslint-enable react/no-array-index-key */}
          </Typography>
        </DirectionSwitcher>
      )}
      {!!lastLogin && (
        <DirectionSwitcher isPc={isPc} type="lastLogin">
          <Title>{title}</Title>
          <LastLogin>
            <Typography
              variant="body-sub/regular"
              sx={{ color: 'system.text-light' }}
            >
              最終ログイン
            </Typography>
            <Typography
              variant="body-sub/regular"
              sx={{ color: 'system.text-light' }}
            >
              {lastLogin}
            </Typography>
          </LastLogin>
        </DirectionSwitcher>
      )}
    </Wrraper>
  );
};
