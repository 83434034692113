import { Theme, useMediaQuery as useMuiUMediaQuery } from '@mui/material';
import { BREAKPOINT } from '@/theme/theme';

export const useMediaQuery = () => {
  const isPc = useMuiUMediaQuery((theme: Theme) =>
    theme.breakpoints.up(BREAKPOINT)
  );

  const isSp = useMuiUMediaQuery((theme: Theme) =>
    theme.breakpoints.down(BREAKPOINT)
  );

  return { isPc, isSp, BREAKPOINT };
};
