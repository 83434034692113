/* eslint-disable react/no-unused-prop-types */
import { styled, Stack, Pagination, useMediaQuery, Theme } from '@mui/material';
import { FC } from 'react';
import { BREAKPOINT } from '@/theme/theme';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  PaginationPageState,
  PaginationPerPageSizeState,
  PaginationTotalPageState,
  PaginationTotalSizeState,
} from '@/recoil/atom/pagination';

const RecordNumber = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-sub/regular'],
  };
});

type Props = {
  startPage: number;
  allRecords: number;
  handleChange: (value: number) => void;
};

const MuiPagination = styled(Pagination)(({ theme }) => {
  const { palette } = theme;
  return {
    ul: {
      '& .MuiPaginationItem-root': {
        color: palette.secondary.normal,
      },
      button: {
        borderColor: palette.secondary.normal,
        borderWidth: '1px',
        borderStyle: 'solid',
      },
      '& .MuiButtonBase-root.Mui-selected': {
        backgroundColor: palette.secondary.normal,
        color: palette.system.white,
      },
      '& .Mui-disabled': {
        background: 'rgba(0, 0, 0, 0.13)',
        color: palette.system.placeholder,
        borderColor: 'rgba(0, 0, 0, 0.13)',
        opacity: 'unset',
      },
      '& .MuiPaginationItem-ellipsis': {
        fontWeight: 'bold',
        fontSize: 'x-large',
        paddingBottom: '10px',
      },
    },
  };
});

export const Paginate: FC<Props> = () => {
  const isSp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(BREAKPOINT)
  );

  const [recoilPage, setRecoilPage] = useRecoilState(PaginationPageState);
  const totalPage = useRecoilValue(PaginationTotalPageState);
  const totalSize = useRecoilValue(PaginationTotalSizeState);
  const perPageSize = useRecoilValue(PaginationPerPageSizeState);

  return (
    <>
      <RecordNumber>
        {(recoilPage - 1) * perPageSize + 1}〜
        {totalSize > perPageSize ? recoilPage * perPageSize : totalSize}
        件/全
        {totalSize}件
      </RecordNumber>
      {isSp ? (
        <Stack spacing={2}>
          <MuiPagination
            count={totalPage}
            variant="outlined"
            shape="rounded"
            siblingCount={0}
            defaultPage={1}
            page={recoilPage}
            onChange={(_e, page) => {
              setRecoilPage(page);
            }}
          />
        </Stack>
      ) : (
        <Stack spacing={2}>
          <MuiPagination
            count={totalPage}
            variant="outlined"
            shape="rounded"
            siblingCount={2}
            boundaryCount={1}
            defaultPage={1}
            page={recoilPage}
            onChange={(_e, page) => {
              setRecoilPage(page);
            }}
          />
        </Stack>
      )}
    </>
  );
};
