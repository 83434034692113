/* eslint-disable consistent-return */
import { theme } from '@/theme/theme';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';

// Base dataset
const BaseLineDataset = {
  type: 'line' as const,
  borderColor: theme.palette.states.done,
  borderWidth: 1,
  pointWidth: 1,
  pointRadius: 0,
};

const BaseBarDataset = {
  type: 'bar' as const,
  backgroundColor: theme.palette.primary.main,
  categoryPercentage: 1,
  barPercentage: 1,
  order: 2,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GraphDataGenerator = (
  type: GraphType,
  data: { x: string; y: number }[]
): any => {
  if (type === 'Line') {
    return {
      ...BaseLineDataset,
      data,
    } as unknown as ChartJSOrUndefined<
      'line' | 'bar',
      { x: string; y: number }[],
      string
    >;
  }
  if (type === 'Bar') {
    return {
      ...BaseBarDataset,
      data,
    } as unknown as ChartJSOrUndefined<
      'line' | 'bar',
      { x: string; y: number }[],
      string
    >;
  }
};

type GraphType = 'Line' | 'Bar';
