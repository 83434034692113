import { FC } from 'react';
import { Box, Typography, styled } from '@mui/material';

interface MonthSelectionTitleProps {
  title: string;
}

const Wrapper = styled(Box)(({ theme }) => {
  const { breakpoints } = theme;

  return {
    width: 160,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [breakpoints.down('sm')]: {
      width: 96,
    },
  };
});

const Title = styled(Typography)(({ theme }) => {
  const { typography, breakpoints, palette } = theme;

  return {
    ...typography['body-main/bold'],
    color: palette.system['text-normal'],

    [breakpoints.down('sm')]: {
      ...typography['body-sub/bold'],
    },
  };
});

export const MonthSelectionTitle: FC<MonthSelectionTitleProps> = ({
  title,
}) => (
  <Wrapper>
    <Title>{title}</Title>
  </Wrapper>
);
