import {
  Box,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import React, { forwardRef, ComponentPropsWithoutRef, useState } from 'react';
import { ReactComponent as IconOn } from '@/assets/visibilityOn.svg';
import { ReactComponent as IconOff } from '@/assets/visibilityOff.svg';

const LabelAndForm = styled('div')({
  width: '100%',
});

const LabelAnnotation = styled(Typography)(({ theme }) => ({
  color: theme.palette.system['text-light'],
  lineHeight: '24px',
}));

const MuiTextField = styled(TextField)({
  height: 'auto',
  '& .MuiOutlinedInput-root': {
    paddingLeft: '0px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '8px 8px 8px 0px',
    textAlign: 'left',
  },
});

const Footnote = styled(Typography)(({ theme }) => ({
  ...theme.typography['caption/regular'],
  color: theme.palette.system['text-light'],
  paddingTop: '8px',
  textAlign: 'left',
}));

interface TextInputLabel {
  children?: React.ReactNode;
  label: string;
  annotation?: string;
  placeholder?: string;
}

type TextInputWithLabelProps = TextInputLabel & TextFieldProps;
type ChildProps = ComponentPropsWithoutRef<'input'> & TextInputWithLabelProps;

export const LoginLabelWithPasswordInput = forwardRef<
  HTMLInputElement,
  ChildProps
>(({ label, annotation, placeholder, children, ...props }, ref) => {
  const [valueType, setValueType] = useState<string>('password');
  const handleClickShowPassword = () => {
    setValueType(valueType === 'text' ? 'password' : 'text');
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <LabelAndForm>
      <Box display="flex" mb={1}>
        <Typography variant="body-main/bold" mr={1}>
          {label}
        </Typography>
        {annotation && (
          <LabelAnnotation variant="caption/regular">
            {annotation}
          </LabelAnnotation>
        )}
      </Box>
      <MuiTextField
        type={valueType}
        InputProps={{
          style: {
            paddingLeft: '8px',
            color: 'red',
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDown}
              >
                {valueType === 'text' ? <IconOn /> : <IconOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
        ref={ref}
        error={props.error}
        helperText={props.error && props.helperText}
        placeholder={placeholder}
        {...props}
      />
      <Footnote>{children}</Footnote>
    </LabelAndForm>
  );
});
