export const reason = {
  LEAVE_ABSENCE: '退職',
  FAMILY_MATTERS: '家庭の事情',
  ECONOMIC: '経済的理由',
  OTHER: 'その他',
};

export const accountType = {
  SAVINGS: '普通',
  CURRENT: '当座',
  OTHER: '貯蓄',
};
