import { LocationState } from '@/components/pages/Withdrawal/WithdrawalInput';
import { theme } from '@/theme/theme';
import { FindApplicationById } from '@/types/api/applications';
import { Box, Typography, styled } from '@mui/material';
import { CSSProperties, FC } from 'react';
import { InfoRow } from '../infoRow';
import { reason } from './consts';

interface PdfContentProps {
  title: string;
  data: LocationState;
  reasonKey: Exclude<
    FindApplicationById['applicationRecess'],
    undefined
  >['reason'];
}

const Container = styled(Box)(() => ({
  marginTop: 24,
  '@media print': {
    marginTop: 24,
  },
}));

const ContainerTitle = styled(Typography)(() => ({
  ...theme.typography.h2,
  fontWeight: 500,
  paddingBottom: 12,
  borderWidth: 0,
  fontSize: 26,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: '#000000',
  [theme.breakpoints.up('sm')]: {
    fontSize: 26,
  },

  '@media print': {
    ...theme.typography.h2,
    fontWeight: 500,
    paddingBottom: 12,
    borderWidth: 0,
    fontSize: 21,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    [theme.breakpoints.up('sm')]: {
      fontSize: 21,
    },
  },
}));

const ContainerContent = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  '@media print': {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
}));

const ContainerContenstWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 20,
  '@media print': {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
  },
}));

const ContainerBorderStyle: CSSProperties = {
  borderRadius: '12px',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '#000000',
  padding: '16px',
};

export const RecessContent: FC<PdfContentProps> = ({
  title,
  data,
  reasonKey,
}) => (
  <Container
    sx={{
      ...ContainerBorderStyle,
      marginTop: '40px',
      '@media print': {
        padding: '12px',
        borderRadius: '6px',
      },
    }}
  >
    <ContainerTitle>{title}</ContainerTitle>
    <ContainerContenstWrapper>
      <ContainerContent>
        <InfoRow
          key="休止理由-recess"
          title="休止理由"
          content={
            reasonKey === 'OTHER'
              ? [
                  reason.OTHER,
                  data.application?.applicationRecess?.otherReasons as string,
                ]
              : reason[reasonKey || 'LEAVE_ABSENCE']
          }
          disableSeparator
        />
      </ContainerContent>
    </ContainerContenstWrapper>
  </Container>
);
