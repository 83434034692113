import { styled } from '@mui/material';
import { FC } from 'react';
import { Application } from '@/types/api/application';

const Applied = styled('div')({
  border: '1px solid #D20B0B',
  borderRadius: '2px',
  color: '#D20B0B',
  padding: '0 2px',
  width: '96px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Done = styled('div')({
  border: '1px solid #0451A0',
  borderRadius: '2px',
  color: '#0451A0',
  padding: '0 2px',
  width: '96px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const InTheProcess = styled('div')({
  border: '1px solid #049512',
  borderRadius: '2px',
  color: '#049512',
  padding: '0 2px',
  width: '96px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Rejected = styled('div')({
  border: '1px solid #666666',
  borderRadius: '2px',
  color: '#666666',
  padding: '0 2px',
  width: '96px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

type Props = {
  applicationStatus: Application['applicationStatus'];
};

export const StatusLabel: FC<Props> = (props) => {
  const { applicationStatus } = props;
  return (
    <>
      {(applicationStatus === 'APPLYING' ||
        applicationStatus === 'PREPARATION') && <Applied>申請中</Applied>}
      {(applicationStatus === 'OFFICER_APPROVED' ||
        applicationStatus === 'BACKOFFICER_APPROVED') && (
        <InTheProcess>手続中</InTheProcess>
      )}
      {(applicationStatus === 'OFFICER_REJECTED' ||
        applicationStatus === 'BACKOFFICER_REJECTED') && (
        <Rejected>却下</Rejected>
      )}
      {applicationStatus === 'COMPLETED' && <Done>完了</Done>}
    </>
  );
};
