import { AssociationContact } from '@/types/api/associationContact';

export const ConvertAssociationContact = (
  data:
    | {
        associationCd?: string;
        inquiryDepartment?: string;
        inquiryRepresentative?: string;
        inquiryTelExtension?: string;
        inquiryTelOutsideLine?: string;
        inquiryTelEmail?: string;
      }
    | undefined
) =>
  data
    ? ({
        associationCd: data.associationCd || '',
        inquiryDepartment: data.inquiryDepartment || '',
        inquiryRepresentative: data.inquiryRepresentative || '',
        inquiryTelExtension: data.inquiryTelExtension || '',
        inquiryTelOutsideLine: data.inquiryTelOutsideLine || '',
        inquiryTelEmail: data.inquiryTelEmail || '',
      } as AssociationContact)
    : undefined;
