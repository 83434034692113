import { Box } from '@mui/material';
import Modal from '@mui/material/Modal';
import { FC, ReactNode } from 'react';

interface Props {
  open: boolean;
  children: ReactNode;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'system.white',
  borderRadius: '20px',
};

export const BasicModal: FC<Props> = (props) => {
  const { open, children } = props;

  return (
    <Modal
      open={open}
      BackdropProps={{ sx: { bgcolor: 'rgba(0, 0, 0, 0.25)' } }}
    >
      <Box sx={style}>{children}</Box>
    </Modal>
  );
};
