/* eslint-disable  */
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { ReactComponent as UserIcon } from '@/assets/userGrey.svg';
import { ReactComponent as CardIcon } from '@/assets/card.svg';
import { ReactComponent as CoinIcon } from '@/assets/coin.svg';
import { ReactComponent as MailIcon } from '@/assets/mail.svg';
import { ReactComponent as KeyIcon } from '@/assets/key.svg';
import { ReactComponent as BuildingIcon } from '@/assets/building.svg';
import { BREAKPOINT } from '@/theme/theme';
import { Path } from '@/constants/Router/path';
import type { Association } from '@/types/api/association';
import type { UserContribution } from '@/types/api/userContribution';
import type { UserSecuritiesAccounts } from '@/components/pages/MyPage/hooks/model';
import { HomeHeader } from '../HomeHeader';
import { AssociationDomainService } from '@/services';
import { formatDate } from '@/utils/dateFunctions';
import { AssociationContribution } from '@/types/api/associationContribution';
import { FindApplicationById } from '@/types/api/applications';
import { FindUser } from '@/types/api/users';

const links = [
  { path: 'TOP', to: Path.HOME },
  { path: 'マイページ', to: Path.MYPAGE },
];

type WrapperProps = {
  isPc: boolean;
  user: FindUser;
  lastAuthTime: string;
  userSecuritiesAccounts: UserSecuritiesAccounts | undefined;
  userContribution: UserContribution | undefined;
  association: Association | undefined;
  associationContribution: AssociationContribution | undefined;
  isApplyingUser: boolean;
  applyingUserData: FindApplicationById['applicationUser'];
};

const Wrapper: FC<WrapperProps> = (props) => {
  const {
    isPc,
    user,
    lastAuthTime,
    userSecuritiesAccounts,
    userContribution,
    association,
    associationContribution,
    isApplyingUser,
    applyingUserData,
  } = props;

  const boxSx = (theme: Theme) => ({
    padding: '24px 16px',
    [theme.breakpoints.up(BREAKPOINT)]: {
      border: 0,
      padding: '24px',
    },
  });
  const headerSx = (theme: Theme) => ({
    padding: 0,
    alignItems: 'center',
    [theme.breakpoints.up(BREAKPOINT)]: {},
  });
  const bodySx = (theme: Theme) => ({
    padding: 0,
    [theme.breakpoints.up(BREAKPOINT)]: {
      padding: 0,
    },
  });

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateAreas: isPc
          ? `
        'a a'
        'b c'
        'b d'
        'e f'
        'g g'
        `
          : `
        'a'
        'b'
        'c'
        'd'
        'e'
        'f'
        'g'
        `,
        gap: '24px',
      }}
    >
      <HomeHeader
        title="マイページ"
        links={links}
        lastLogin={lastAuthTime}
        sx={{ gridArea: 'a' }}
      />
      {/* @ts-ignore */}
      <BasicTable
        title="会員情報"
        type="mypageUser"
        buttonCaption={isApplyingUser ? '' : '会員情報を変更する'}
        nameKanji={
          isApplyingUser ? applyingUserData?.nameKanji ?? '' : user.nameKanji
        }
        nameKana={
          isApplyingUser ? applyingUserData?.nameKana ?? '' : user.nameKana
        }
        birthday={
          isApplyingUser ? applyingUserData?.birthday ?? '' : user.birthday
        }
        zipcode={
          isApplyingUser ? applyingUserData?.zipcode ?? '' : user.zipcode
        }
        address1={
          isApplyingUser ? applyingUserData?.address1 ?? '' : user.address1
        }
        address2={
          isApplyingUser ? applyingUserData?.address2 ?? '' : user.address2
        }
        address3={
          isApplyingUser ? applyingUserData?.address3 ?? '' : user.address3
        }
        telType={user.telType}
        tel={isApplyingUser ? applyingUserData?.tel ?? '' : user.tel}
        employeeCd={
          isApplyingUser ? applyingUserData?.employeeCd ?? '' : user.employeeCd
        }
        icon={<UserIcon />}
        path="/mypage/auth?next=user"
        boxSx={{ ...boxSx, gridArea: 'b' }}
        headerSx={headerSx}
        bodySx={bodySx}
        isApplyingUser={isApplyingUser}
      />
      {association &&
      AssociationDomainService.isListedClassification(association) ? (
        <BasicTable
          title="証券口座情報"
          type="mypageSecurities"
          buttonCaption={
            userSecuritiesAccounts?.fixedFlg ? '' : '証券口座情報を変更する'
          }
          name="東海東京証券"
          departmentCode={userSecuritiesAccounts?.branchCode || ''}
          departmentName={userSecuritiesAccounts?.branchName || ''}
          accountNumber={userSecuritiesAccounts?.accountNumber || ''}
          associationCd={association.associationCd}
          icon={<CardIcon />}
          path="/mypage/auth?next=securities"
          boxSx={{ ...boxSx, gridArea: 'c' }}
          headerSx={headerSx}
          bodySx={bodySx}
        />
      ) : null}
      {/* @ts-ignore */}
      <BasicTable
        title="拠出状況"
        type="mypageContribution"
        associationContribution={associationContribution}
        contributionStartDate={
          userContribution
            ? formatDate(userContribution.contributionStartDate)
            : ''
        }
        monthlyRecessStartDate={userContribution?.monthlyRecessStartDate || ''}
        monthlyRecessEndDate={userContribution?.monthlyRecessEndDate || ''}
        unitAmount={associationContribution?.unitAmount || 0}
        monthlyUnit={userContribution?.monthlyUnit || 0}
        memberContribution={{ ...userContribution } as UserContribution}
        // @ts-ignore
        monthlyIncentiveRatio={associationContribution?.monthlyIncentiveRatio}
        bonusUnit={userContribution?.bonusUnit || 0}
        icon={<CoinIcon />}
        path="/mypage/auth?next=contribution"
        boxSx={{ ...boxSx, gridArea: 'd' }}
        headerSx={headerSx}
        bodySx={bodySx}
      />
      <BasicTable
        title="ログインID"
        type="mypageLogin"
        // buttonCaption="ログインIDを変更する" TODO: Temporary disabled, enabled it when it becomes necessary
        loginId={user.email ?? ''}
        icon={<MailIcon />}
        path="/mypage/auth?next=id"
        boxSx={{ ...boxSx, gridArea: 'e' }}
        headerSx={headerSx}
        bodySx={bodySx}
      />
      <BasicTable
        title="パスワード"
        type="mypagePassword"
        buttonCaption="パスワードを変更する"
        password="*******"
        icon={<KeyIcon />}
        path="/mypage/auth?next=password"
        boxSx={{ ...boxSx, gridArea: 'f' }}
        headerSx={headerSx}
        bodySx={bodySx}
      />
      <BasicTable
        title="持株会情報"
        type="mypageSecretariats"
        companyName="AAAAAAAAAAAA株式会社"
        mochikabukaiCode={association?.associationCd || ''}
        mochikabukaiName={association?.associationName || ''}
        icon={<BuildingIcon />}
        path="/mypage/auth?next=secretariats"
        boxSx={{ ...boxSx, gridArea: 'g' }}
        headerSx={headerSx}
        bodySx={bodySx}
      />
    </Box>
  );
};

type Props = {
  user: FindUser;
  lastAuthTime: string;
  userSecuritiesAccounts: UserSecuritiesAccounts | undefined;
  userContribution: UserContribution | undefined;
  association: Association | undefined;
  associationContribution: AssociationContribution | undefined;
  isApplyingUser: boolean;
  applyingUserData: FindApplicationById['applicationUser'];
};

export const MyPageIndexOrganism: FC<Props> = (props) => {
  const isPc = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(BREAKPOINT)
  );
  return (
    <Box
      sx={{
        bgcolor: 'system.background',
        padding: {
          xs: '24px 0 60px',
          sm: '33px 40px 80px',
          md: '33px 40px 80px',
          lg: '33px 130px 80px',
        },
      }}
    >
      <Wrapper isPc={isPc} {...props} />
    </Box>
  );
};
