import {
  ApplicationsflowLabels,
  LoginflowLabels,
} from '@/constants/SignUp/flow';
import { BREAKPOINT, theme } from '@/theme/theme';
import {
  styled,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { FC } from 'react';

interface Props {
  // 現在の場所
  flowOn: number;
  // サインアップ or 申請
  isSignUp?: boolean;
  // スタイル
  sx?: SxProps<Theme>;
  widthFull?: boolean;
}

const Stepper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'widthFull',
})<{ widthFull?: boolean }>(({ widthFull }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0',
  gap: '4px',
  [theme.breakpoints.down(BREAKPOINT)]: {
    gap: '8px',
    width: widthFull ? '100%' : '343px',
  },
}));

const IconArea = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px 22px',
  gap: '8px',
  height: '28px',
  [theme.breakpoints.down(BREAKPOINT)]: {
    padding: '0 40px',
    width: '343px',
  },
}));

const Step = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px',
  gap: '10px',
  width: '28px',
  height: '28px',
  background: theme.palette.secondary.main,
  borderRadius: '20px',
  [theme.breakpoints.down(BREAKPOINT)]: {
    width: '24px',
    height: '24px',
  },
}));

const StepNormal = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px',
  gap: '10px',
  width: '28px',
  height: '28px',
  background: theme.palette.system.inactive,
  borderRadius: '20px',
  [theme.breakpoints.down(BREAKPOINT)]: {
    width: '24px',
    height: '24px',
  },
}));

const StepNumber = styled(Typography)(() => ({
  ...theme.typography['body-main/bold'],
  fontSize: '18px',
  color: theme.palette.system.white,
}));

const StepBorder = styled('p')(() => ({
  width: '56px',
  height: '1px',
  background: theme.palette.system.inactive,
  [theme.breakpoints.down(BREAKPOINT)]: {
    width: '48px',
  },
}));

const TextArea = styled('div')(() => ({
  display: 'flex',
  flexdirection: 'row',
  alignItems: 'flex-start',
  padding: '0px',
  height: '22px',
  [theme.breakpoints.down(BREAKPOINT)]: {
    justifyContent: 'center',
    width: '343px',
  },
}));

const StepperText = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '10px',
  width: '100px',
  height: '22px',
  [theme.breakpoints.down(BREAKPOINT)]: {
    width: '88px',
    padding: '1px 2px',
  },
}));

const Text = styled(Typography)(() => ({
  ...theme.typography['body-sub/medium'],
  color: theme.palette.secondary.normal,
  textAlign: 'center',
}));

const TextNormal = styled(Typography)(() => ({
  ...theme.typography['body-sub/medium'],
  color: theme.palette.system.inactive,
  textAlign: 'center',
}));

export const TextWithFlowIcon: FC<Props> = ({
  flowOn,
  isSignUp = false,
  sx,
  widthFull,
}) => {
  let widthList: string[] = [];
  let flowTexts: { key: number; value: string }[] = [];
  if (isSignUp) {
    widthList = ['70px', '70px', '84px', '56px'];
    flowTexts = LoginflowLabels;
  } else {
    widthList = ['84px', '56px', '56px'];
    flowTexts = ApplicationsflowLabels;
  }

  const isPc = useMediaQuery(() => theme.breakpoints.up(BREAKPOINT));
  return (
    <Stepper sx={sx} widthFull={widthFull}>
      <IconArea>
        {flowTexts.map((item, index) => (
          <React.Fragment key={item.key}>
            {flowOn >= index ? (
              <Step sx={{ opacity: flowOn > index ? 0.3 : 1 }}>
                <StepNumber>{item.key}</StepNumber>
              </Step>
            ) : (
              <StepNormal>
                <StepNumber>{item.key}</StepNumber>
              </StepNormal>
            )}
            {item.key !== flowTexts.length ? (
              <StepBorder
                sx={{
                  backgroundColor:
                    flowOn > index
                      ? theme.palette.secondary.main
                      : theme.palette.system.inactive,
                  opacity: flowOn > index ? 0.3 : 1,
                }}
              />
            ) : (
              ''
            )}
          </React.Fragment>
        ))}
      </IconArea>

      <TextArea>
        {flowTexts.map((item, index) => (
          <StepperText key={item.key}>
            {flowOn >= index ? (
              <Text
                sx={{
                  width: !isPc && widthList[index] ? widthList[index] : '100px',
                  opacity: flowOn > index ? 0.3 : 1,
                }}
              >
                {item.value}
              </Text>
            ) : (
              <TextNormal
                sx={{
                  width: !isPc && widthList[index] ? widthList[index] : '100px',
                }}
              >
                {item.value}
              </TextNormal>
            )}
          </StepperText>
        ))}
      </TextArea>
    </Stepper>
  );
};
