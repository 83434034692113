import { Complete } from '@/components/organism/Apply/Complete';
import { Path } from '@/constants/Router/path';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

// パンクズ
const links = [
  {
    path: 'TOP',
    to: Path.HOME,
  },
  {
    path: '休止する',
    to: Path.RECESS,
  },
];

type LocationState = {
  applicationType?:
    | 'MONTHLY_UNIT'
    | 'BONUS_UNIT'
    | 'PART'
    | 'RECESS'
    | 'RESUME'
    | 'WITHDRAWAL'
    | 'ADMISSION'
    | 'USER';
  applicationId?: number;
  applicationDate?: string;
};

export const RecessComplete: FC = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  useHelmetHandler({
    title: '休会 申請完了',
  });

  return (
    <Complete
      links={links}
      id={state.applicationId}
      category={state.applicationType}
      date={state.applicationDate}
    />
  );
};
