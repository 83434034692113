import {
  styled,
  Theme,
  useMediaQuery,
  Typography,
  SvgIcon,
} from '@mui/material';
import { FC, useState } from 'react';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails, {
  AccordionDetailsProps,
} from '@mui/material/AccordionDetails';
import { BREAKPOINT } from '@/theme/theme';
import { ReactComponent as Plus } from '@/assets/plus.svg';
import { ReactComponent as Minus } from '@/assets/minus.svg';
import { MemberInformations } from '@/types/api/memberInformations';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    sx={{
      '& .MuiAccordionSummary-root': {
        minHeight: 0,
      },
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(0deg)',
      },
    }}
    disableGutters
    elevation={0}
    {...props}
  />
))(({ theme }) => {
  const { breakpoints } = theme;
  return {
    borderRadius: '4px',
    '&:before': {
      display: 'none',
    },
    padding: '8px 16px',
    [breakpoints.down(BREAKPOINT)]: {
      padding: '12px 16px',
    },
  };
});

const OpenWrap = styled('div')({
  display: 'flex',
  alignItems: 'center',
  // gap: '6px',
});

const OpenWord = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['caption/regular'],
    display: 'flex',
    flexWrap: 'nowrap',
  };
});

const Title = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-sub/bold'],
  };
});

const Description = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-sub/regular'],
    color: '#222222',
  };
});

const AccordionDetails = styled((props: AccordionDetailsProps) => (
  <MuiAccordionDetails {...props} />
))(() => ({
  padding: '0px',
}));

interface Props {
  message: MemberInformations;
  fontColor: string;
  bgColor: string;
}

export const AccordionBox: FC<Props> = ({ message, fontColor, bgColor }) => {
  const isSp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(BREAKPOINT)
  );

  const memberInformationId = String(message.memberInformationId);

  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      event.preventDefault();
      setExpanded(newExpanded ? panel : false);
    };

  const AccordionSummaryOpen = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={
        <OpenWrap sx={{ minWidth: '56px' }}>
          <SvgIcon
            viewBox="-3 -3 24 24"
            sx={{
              path: { stroke: fontColor },
            }}
          >
            <Minus />
          </SvgIcon>
          {isSp ? (
            <OpenWord sx={{ color: fontColor }}>閉じる</OpenWord>
          ) : (
            <OpenWord sx={{ color: fontColor }}>メッセージを閉じる</OpenWord>
          )}
        </OpenWrap>
      }
      {...props}
    />
  ))({
    flexDirection: 'row',
    gap: '6px',
    marginBottom: '8px',
    padding: '0px',
  });

  const AccordionSummaryClose = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={
        <OpenWrap sx={{ minWidth: '56px' }}>
          <SvgIcon viewBox="-3 -3 24 24" sx={{ path: { stroke: fontColor } }}>
            <Plus />
          </SvgIcon>
          {isSp ? (
            <OpenWord sx={{ color: fontColor }}>開く</OpenWord>
          ) : (
            <OpenWord sx={{ color: fontColor }}>メッセージを開く</OpenWord>
          )}
        </OpenWrap>
      }
      {...props}
    />
  ))({
    flexDirection: 'row',
    gap: '6px',
    padding: '0px',
  });

  return (
    <Accordion
      sx={{ backgroundColor: bgColor, color: fontColor }}
      expanded={expanded === memberInformationId}
      onChange={handleChange(memberInformationId)}
    >
      {expanded === memberInformationId ? (
        <AccordionSummaryOpen>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '0px',
            }}
          >
            <Title>{message.informationTitle}</Title>
          </Typography>
        </AccordionSummaryOpen>
      ) : (
        <AccordionSummaryClose>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Title>{message.informationTitle}</Title>
          </Typography>
        </AccordionSummaryClose>
      )}
      <AccordionDetails>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Description>{message.informationBody}</Description>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
