import { useRecoilState } from 'recoil';
import {
  snackbarMessageAtom,
  SnackbarMessageType,
} from '@/recoil/atom/snackbarMessageAtom';

export const useSnackbarMessage = () => {
  // usage Recoil
  const [snackbarMessage, setSnackbarMessage] =
    useRecoilState<SnackbarMessageType>(snackbarMessageAtom);

  const showMessage = (message: SnackbarMessageType) => {
    setSnackbarMessage(message);
  };

  const hideMessage = () => {
    setSnackbarMessage({
      open: false,
      messages: [],
    });
  };

  return {
    snackbarMessage,
    showMessage,
    hideMessage,
  };
};
