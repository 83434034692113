import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { UserFormValues as FormValues } from '@/types/components/SignUpUser';
import { yupResolver } from '@hookform/resolvers/yup';
import { userFormValidateSchema } from '@/utils/validate/userFormSchema';

const initialValues: FormValues = {
  nameKanji: '',
  nameKanji2: '',
  nameKana: '',
  nameKana2: '',
  zip: '',
  address1: '',
  address2: '',
  address3: '',
  telType: 'MOBILE',
  tel: '',
  employeeCode: '',
  year: '',
  month: '',
  day: '',
};

export const SignUpUser: FC = () => {
  const methods = useForm<FormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(userFormValidateSchema),
    mode: 'onChange',
  });

  return (
    <FormProvider {...methods}>
      <Outlet />
    </FormProvider>
  );
};
