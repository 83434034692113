/* eslint-disable @typescript-eslint/no-unsafe-call */
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { TextInput } from '@/components/atoms/Input/TextInput';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  SvgIcon,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useController,
  UseControllerProps,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BREAKPOINT, theme } from '@/theme/theme';
import {
  MyPageUserProps as Props,
  MyPageUserContainerProps as ContainerProps,
  MyPageUserFormValues as FormValues,
  MyPageUserLabelAndInputProps as LATProps,
} from '@/types/components/MyPageUser';
import { ReactComponent as Icon } from '@/assets/userGrey.svg';
import { SelectBox, SelectBoxProps } from '@/components/atoms/Input/SelectBox';
import { months, prefectures, years } from '@/constants/choicesForSelectBox';
import { useMaskoutUser } from '@/components/pages/MyPage/hooks/useMaskoutUser';
import { Address } from '@/hooks/useGetAddressApi';
import { format } from 'date-fns';
import { getDayList } from '@/utils/dateFunctions';
import {
  IsNumeric,
  IsStarZenkaku,
  ValidateKatagana,
} from '@/utils/stringProcessor';

const Title: FC = () => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      mb: '24px',
      gap: '4px',
    }}
  >
    <SvgIcon sx={{ fill: () => theme.palette.system.placeholder }}>
      <Icon />
    </SvgIcon>
    <Typography variant="h4" sx={{ color: 'system.text-normal' }}>
      会員情報変更
    </Typography>
  </Box>
);

const Label: FC<Props> = ({ isPc, children }) => (
  <Typography
    component="span"
    variant="body-main/bold"
    sx={{ color: 'system.text-normal', minWidth: isPc ? '144px' : '100%' }}
  >
    {children}
  </Typography>
);

const RGroup: FC<RadioGroupProps> = ({ name = '', children, ...props }) => {
  const {
    field: { ref, ...rest },
  } = useController({ name });

  return (
    <RadioGroup sx={{ gap: 2 }} ref={ref} {...rest} {...props}>
      {children}
    </RadioGroup>
  );
};

const Select: FC<SelectBoxProps> = ({ name = '', items, width }) => {
  const {
    field: { ref, ...rest },
  } = useController({ name });

  return (
    <SelectBox
      {...rest}
      items={items}
      width={width}
      error={rest.value === ''}
      helperText="選択してください"
      inputRef={ref}
    />
  );
};

const InputValidation = (
  name: string,
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  onChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
) => {
  switch (name) {
    case 'tel':
      if (
        event.target.value === '' ||
        (IsNumeric(event.target.value) && event.target.value.length <= 15)
      )
        onChange(event);
      break;
    case 'zip':
      if (
        event.target.value === '' ||
        (IsNumeric(event.target.value) && event.target.value.length <= 7)
      )
        onChange(event);
      break;
    case 'nameKanji':
    case 'nameKana':
      if (event.target.value.length <= 30) onChange(event);
      break;
    case 'address2':
      if (event.target.value.length <= 50) onChange(event);
      break;
    default:
      onChange(event);
      break;
  }
};

const Input = (props: UseControllerProps<FormValues>) => {
  const {
    field: { ref, onChange: controllerOnChange, ...rest },
  } = useController(props);
  const { name } = props;
  let isCheckValue = true;
  let errText = "入力できない文字が含まれています";
  if (name === 'nameKanji' || name === 'address2' || name === 'address3') {
    isCheckValue = IsStarZenkaku(rest.value.replace(/[\s\u3000]+/g, ''));
    if (name === 'nameKanji' && isCheckValue) {
      isCheckValue = rest.value.length <= 20
      errText = '20文字以内で入力してください'
    }
  } else if (name === 'nameKana') {
    isCheckValue = ValidateKatagana(
      rest.value.replace(/[\s\u3000]+/g, '')
    );
    if (isCheckValue) {
      isCheckValue = rest.value.length <= 20
      errText = '20文字以内で入力してください'
    }
  }
  return (
    <TextInput
      {...rest}
      error={rest.value === '' || !isCheckValue}
      helperText={
        rest.value === ''
          ? '入力してください': errText
      }
      inputRef={ref}
      sx={{
        width: '100%',
        [theme.breakpoints.down(BREAKPOINT)]: {
          width: '100%',
        },
        '& .MuiOutlinedInput-root': {
          backgroundColor: 'white',
        },
      }}
      onChange={(e) => InputValidation(name, e, controllerOnChange)}
    />
  );
};

const LabelAndInput: FC<LATProps> = ({ label, caution, children, width }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '4px',
      width,
      [theme.breakpoints.down(BREAKPOINT)]: {
        width: '100%',
      },
    }}
  >
    <Typography
      variant="body-sub/regular"
      sx={{ color: 'system.text-normal', minWidth: '144px' }}
    >
      {label}
    </Typography>
    {children}
    {!!caution && (
      <Typography variant="caption/regular" sx={{ color: 'system.text-light' }}>
        {caution}
      </Typography>
    )}
  </Box>
);

const ItemContainer: FC<{ children: ReactNode; width?: string }> = ({
  children,
  width,
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      gap: '8px',
      width,
      [theme.breakpoints.down(BREAKPOINT)]: {
        width: '100%',
      },
    }}
  >
    {children}
  </Box>
);

const MultiItemContainer: FC<{ children: ReactNode; width?: string }> = ({
  children,
  width,
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '8px',
      width,
      [theme.breakpoints.down(BREAKPOINT)]: {
        width: '100%',
      },
    }}
  >
    {children}
  </Box>
);

const Container: FC<ContainerProps> = ({
  isPc,
  children,
  underline = false,
}) => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: isPc ? 'row' : 'column',
      alignItems: 'flex-start',
      pb: underline ? '16px' : '0',
      gap: '8px',
      borderBottom: underline ? '1px solid' : '0',
      borderColor: 'system.separator-light',
    }}
  >
    {children}
  </Box>
);

const PrimarySecondary: FC<Props & { getError: boolean }> = ({
  isPc,
  getError,
}) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          margin: '0 auto',
          width: isPc ? '389px' : '100%',
          display: 'flex',
          flexDirection: isPc ? 'row-reverse' : 'column',
          alignItems: isPc ? 'center' : 'flex-start',
          padding: '0px',
          gap: '16px',
        }}
      >
        <PrimaryButton
          type="submit"
          sx={{
            width: isPc ? '194px' : '100%',
            height: '56px',
            padding: '20px, 24px, 20px, 26px',
            borderRadius: '28px',
          }}
          disabled={!getError}
        >
          会員情報を変更する
        </PrimaryButton>
        <SecondaryButton
          sx={{
            width: isPc ? '162px' : '100%',
            height: '56px',
            padding: '20px, 24px, 20px, 26px',
            borderRadius: '28px',
          }}
          onClick={() => navigate('/mypage')}
        >
          変更せずに戻る
        </SecondaryButton>
      </Box>
    </Box>
  );
};

const Contents: FC<
  Props & {
    getAddress: () => void;
    dayList: { key: string; value: string }[];
    getError: boolean;
  }
> = ({ isPc, getAddress, dayList, getError }) => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: isPc ? 'center' : 'flex-start',
      padding: isPc ? '32px' : '24px 16px',
      gap: isPc ? '40px' : '24px',
      backgroundColor: 'system.white',
      borderRadius: isPc ? '12px' : '0',
    }}
  >
    <Container isPc={isPc} underline>
      <Label isPc={isPc}>氏名（漢字）</Label>
      <MultiItemContainer width="240px">
        <ItemContainer>
          <LabelAndInput caution="※姓と名の間に空白を入れてください">
            <Input name="nameKanji" />
          </LabelAndInput>
        </ItemContainer>
      </MultiItemContainer>
    </Container>
    <Container isPc={isPc} underline>
      <Label isPc={isPc}>氏名（カナ）</Label>
      <MultiItemContainer width="240px">
        <ItemContainer>
          <LabelAndInput caution="※カタカナ・姓と名の間に空白を入れてください">
            <Input name="nameKana" />
          </LabelAndInput>
        </ItemContainer>
      </MultiItemContainer>
    </Container>
    <Container isPc={isPc} underline>
      <Label isPc={isPc}>生年月日</Label>
      <MultiItemContainer>
        <ItemContainer>
          <Select items={years} name="birthdayYear" width="80px" />
          <Typography
            variant="body-sub/regular"
            sx={{ color: 'system.text-normal', alignSelf: 'center' }}
          >
            年
          </Typography>
          <Select items={months} width="80px" name="birthdayMonth" />
          <Typography
            variant="body-sub/regular"
            sx={{ color: 'system.text-normal', alignSelf: 'center' }}
          >
            月
          </Typography>
          <Select items={dayList} width="80px" name="birthdayDay" />
          <Typography
            variant="body-sub/regular"
            sx={{ color: 'system.text-normal', alignSelf: 'center' }}
          >
            日
          </Typography>
        </ItemContainer>
      </MultiItemContainer>
    </Container>
    <Container isPc={isPc} underline>
      <Label isPc={isPc}>住所</Label>
      <MultiItemContainer width="100%">
        <ItemContainer>
          <LabelAndInput label="郵便番号" caution="※半角7桁" width="160px">
            <Input name="zip" />
          </LabelAndInput>
          <SecondaryButton
            onClick={() => getAddress()}
            sx={{ mt: '25px', minWidth: '138px', fontSize: '14px' }}
          >
            住所を自動入力
          </SecondaryButton>
        </ItemContainer>
        <ItemContainer>
          <LabelAndInput label="都道府県">
            <Select items={prefectures} width="160px" name="address1" />
          </LabelAndInput>
        </ItemContainer>
        <ItemContainer>
          <LabelAndInput label="市区町村" width="240px" caution="※全角">
            <Input name="address2" />
          </LabelAndInput>
        </ItemContainer>
        <ItemContainer width="100%">
          <LabelAndInput
            label="番地・マンション名など"
            width="100%"
            caution="※全角"
          >
            <Input name="address3" />
          </LabelAndInput>
        </ItemContainer>
      </MultiItemContainer>
    </Container>
    <Container isPc={isPc}>
      <Label isPc={isPc}>電話番号</Label>
      <MultiItemContainer>
        <ItemContainer>
          <RGroup name="telType" row>
            <FormControlLabel
              value="MOBILE"
              control={<Radio />}
              label={
                <Typography
                  variant="body-main/regular"
                  sx={{ color: 'system.text-normal' }}
                >
                  携帯
                </Typography>
              }
            />
            <FormControlLabel
              value="HOME"
              control={<Radio />}
              label={
                <Typography
                  variant="body-main/regular"
                  sx={{ color: 'system.text-normal' }}
                >
                  自宅
                </Typography>
              }
            />
          </RGroup>
        </ItemContainer>
        <ItemContainer>
          <LabelAndInput label="" caution="※半角＋ハイフンなし">
            <Input name="tel" rules={{ required: '入力してください' }} />
          </LabelAndInput>
        </ItemContainer>
      </MultiItemContainer>
    </Container>
    <PrimarySecondary isPc={isPc} getError={getError} />
  </Box>
);

const Wrapper: FC<
  Props & {
    getAddress: () => void;
    dayList: { key: string; value: string }[];
    getError: boolean;
  }
> = ({ isPc, getAddress, dayList, getError }) => (
  <Box sx={{ width: '100%', margin: '0 auto' }}>
    <Title />
    <Contents
      isPc={isPc}
      getAddress={getAddress}
      dayList={dayList}
      getError={getError}
    />
  </Box>
);

export const TelType = ['MOBILE', 'HOME'] as const;

export const MyPageUserOrganism: FC<{
  addressData: Address;
  handleSubmit: (data: FormValues) => void;
  getAddress: (zip: FormValues['zip']) => void;
}> = ({ addressData, handleSubmit, getAddress }) => {
  const navigate = useNavigate();
  const isPc = useMediaQuery(() => theme.breakpoints.up(BREAKPOINT));
  const [dayList, setDayList] = useState([{ key: '', value: '' }]);
  const [birthdayDayBackUp, setBirthdayDayBackUp] = useState('');
  const { maskoutUser, password } = useMaskoutUser();
  const defaultValues = useMemo(() => {
    const birthdayList =
      Object.keys(maskoutUser).length && maskoutUser.birthday !== ''
        ? format(new Date(maskoutUser.birthday), 'yyyy-MM-dd').split('-')
        : ['', '', ''];
    setBirthdayDayBackUp(birthdayList[2]);

    return {
      nameKanji: maskoutUser?.nameKanji || '',
      nameKana: maskoutUser?.nameKana || '',
      birthdayYear: birthdayList[0],
      birthdayMonth: birthdayList[1],
      birthdayDay: birthdayList[2],
      zip: maskoutUser?.zipcode || '',
      address1: maskoutUser?.address1 || '',
      address2: maskoutUser?.address2 || '',
      address3: maskoutUser.address3 || '',
      telType: maskoutUser?.telType || 'MOBILE',
      tel: maskoutUser?.tel || '',
    };
  }, [maskoutUser]);

  const methods = useForm<FormValues>({
    defaultValues,
  });
  const birthdayDay = methods.watch('birthdayDay');

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    handleSubmit(data);
  };

  const getError = () =>
    !!methods.watch('nameKanji') &&
    !!methods.watch('nameKana') &&
    !!methods.watch('birthdayYear') &&
    !!methods.watch('birthdayMonth') &&
    !!methods.watch('birthdayDay') &&
    !!methods.watch('zip') &&
    !!methods.watch('address1') &&
    !!methods.watch('address2') &&
    !!methods.watch('address3') &&
    !!methods.watch('tel') &&
    !!IsStarZenkaku(
      methods.getValues('nameKanji').replace(/[\s\u3000]+/g, '')
    ) &&
    methods.getValues('nameKanji').length <= 20 &&
    !!ValidateKatagana(
      methods.getValues('nameKana').replace(/[\s\u3000]+/g, '')
    ) &&
    methods.getValues('nameKana').length <= 20 &&
    !!IsStarZenkaku(methods.getValues('address2')) &&
    !!IsStarZenkaku(methods.getValues('address3').replace(/[\s\u3000]+/g, ''));

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (addressData && addressData.length > 0) {
      methods.setValue('address1', addressData[0].pref);
      methods.setValue('address2', addressData[0].city + addressData[0].town);
      methods.setValue('address3', '');
    }
  }, [addressData]);

  useEffect(() => {
    setBirthdayDayBackUp(birthdayDay);
  }, [birthdayDay]);

  useEffect(() => {
    methods.setValue('birthdayDay', '');
    setDayList(
      getDayList(
        Number(methods.watch('birthdayYear')),
        Number(methods.watch('birthdayMonth'))
      )
    );
    methods.setValue('birthdayDay', birthdayDayBackUp);
  }, [methods.watch('birthdayYear'), methods.watch('birthdayMonth')]);

  useEffect(() => {
    if (!password) navigate('/mypage/auth?next=user');
  }, [maskoutUser]);

  return (
    <Box
      sx={{
        bgcolor: 'system.background',
        padding: {
          xs: '29px 0 60px',
          sm: '53px 40px 60px',
          md: '53px 230px 60px',
          lg: '53px 330px 60px',
        },
      }}
    >
      <FormProvider {...methods}>
        {/**
         * lintエラー公式未対応
         * @see https://react-hook-form.com/api/useform/handlesubmit
         */}
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Wrapper
            isPc={isPc}
            getAddress={() => getAddress(methods.getValues('zip'))}
            dayList={dayList}
            getError={getError()}
          />
        </form>
      </FormProvider>
    </Box>
  );
};
