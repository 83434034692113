import { logoutModalAtom } from '@/recoil/atom/logoutModalAtom';
import { useRecoilState } from 'recoil';

import { useAuthUser } from './useAuth';

/**
 * useLogoutModal
 * @example
 * ```ts
 *  const {
 *  handleLogoutModalOpen,
    handleLogoutModalClose,
    handleClickLogout, } = useLogoutModal()
 * ```
 * @category ReactHooks
 */
export const useLogoutModal = () => {
  const { handleLogout } = useAuthUser();
  // usage Recoil
  const [logoutModal, setLogoutModalOpen] =
    useRecoilState<boolean>(logoutModalAtom);

  /**
   * handleLogoutModalOpen
   */
  const handleLogoutModalOpen = () => {
    setLogoutModalOpen(true);
  };

  /**
   * handleLogoutModalClose
   */
  const handleLogoutModalClose = () => {
    setLogoutModalOpen(false);
  };

  /**
   * handleClickLogout
   */
  const handleClickLogout = () => {
    handleLogout().finally(() => {});
    handleLogoutModalClose();
  };

  return {
    logoutModal,
    handleLogoutModalOpen,
    handleLogoutModalClose,
    handleClickLogout,
  };
};
