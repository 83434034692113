export const TermOfService = `第1条(規約の目的)
本規約は、利用者が東海東京証券株式会社(以下、「当社」という。)の提供する持株会インターネットサービス(以下、「本サービス」という。)を利用する際の取扱いに関する詳細を定めることを目的とし、利用者と当社の間に適用されます。

第2条(定義)
本規約で用いる用語の意義は、次の通りとします。
(1)利用者とは、利用者が籍を置いている企業の持株会に入会している当該持株会の会員をいいます。
(2)持株会コード及び会員コードとは、パスワードと組み合わせて利用者とその他の者を識別するために用いられる符号をいいます。
(3)パスワードとは、持株会コード及び会員コードと組み合わせて利用者とその他の者を識別するために用いられる符号をいいます。

第3条(法令等の遵守)
1.利用者は本サービスの利用に当たり、本規約によるほか、本サービスの利用に関し適用される全ての法令、諸規則を遵守するものとします。
2.利用者が前項の規約に違反した場合、当社の判断により本サービスのご利用を制限、又は停止する場合があります。この場合、利用者は当社に対し、異議申立て、拒絶理由の開示、その他一切の請求を行わないものとします。

第4条(利用者資格の失効)
利用者が次の各号のいずれかに該当する場合、当社は当該利用者に対し、何ら催告なく資格を停止、又は取消すことができます。
(1)公序良俗に反する行為、若しくはそのおそれがある行為
(2)犯罪行為、又は犯罪行為に結びつく行為、若しくはそのおそれがある行為
(3)本サービスの登録情報の改ざんや不正使用を行なう行為、若しくはそのおそれがある行為
(4)所属企業の持株会を退会した場合
(5)パスワード等の不正使用を行なう行為、若しくはそのおそれがある行為
(6)本サービスの運営を妨害する行為、若しくはそのおそれがある行為
(7)その他、当社が不適切と判断した場合

第5条(本サービスの内容)
1.当社が利用者に提供する持株会に関する本サービスのうち照会サービスの内容は、以下に定める通りとします。
(1)利用者の持株残高の照会
2.当社は、当社の都合により、利用者に通知することなく、本サービスで提供する照会サービス及び本サービスの利用に必要なソフトウェア等を変更することがあります。
3.利用者が本サービスを利用する事で得た情報により生じた利益、及び損失は全て利用者に帰属し、当社は、一切の責めを負わないものとします。

第6条(ネットワークの環境)
1.利用者は、企業ネットワーク及びインターネット等を通じて本サービスを利用するものとします。
2.利用者は本サービスを利用するためのネットワーク機器及びソフトウェア等を自己の負担において準備するものとします。

第7条(本サービス利用時の認証)
1.当社は、利用者が本サービスを利用する際の本人認証を所定の持株会コード・会員コード、パスワードにより行います。
2.利用者は、本サービスの利用にあたり、パスワードについては全面的な管理責任を負うものとします。
3.利用者は、自己の責に帰すべき事由によりパスワードの不正使用等が発生した場合、その為に生じた損害全てを負担し、当社はその責任を負いません。

第8条(利用時間)
利用者が本サービスを利用できる時間は、当社が別途定めるものとします。詳細は各企業の持株会事務局へお知らせします。

第9条(情報利用の制限)
利用者は本サービスにより取得した情報は、利用者が投資資料としてのみ使用するものとし、次の各号の行為を行わないものとします。
(1)本サービスにより取得した情報を、営業に利用する行為
(2)本サービスにより取得した情報を、第三者へ提供する目的で加工及び再利用する行為
(3)利用者の持株会コード・会員コード、パスワードを第三者の利用に供する行為

第10条(個人情報の取扱い)
利用者の個人情報の取扱いについては、当社のホームページをご覧ください。

第11条(免責事項)
1.通信機器、通信回路、コンピュータ等のシステム機器等の損害若しくは瑕疵及びこれらを通じた情報伝達システム等の障害、瑕疵並びに第三者による妨害、侵入、情報改ざん等による、いわゆるシステム障害により本サービスの提供が出来なくなった場合、当社はその責任を一切負わないものとします。
2.本サービスの提供について、当社に故意又は過失なく本サービスの伝達遅延及びその誤謬・欠陥が生じた場合について、当社はその責任を負いません。
3.本サービスの利用に際し、端末を通じて利用者から送信された持株会コード・会員コード、パスワードにより、当社が当該利用者を真正な利用者自身のログインであると認識した場合は、このログインによる不正使用があっても、そのために生じた損害について当社はその責任を一切負わないものとします。
4.利用者が使用するネットワーク機器等が原因で本サービスの一部又は全部が利用できないために生じた損害について当社はその責任を負いません。又、かかる場合において必要となる費用等は利用者が負担するものとします。
5.利用者の過失等により生じた損害について当社はその責任を負いません。又、かかる場合において必要となる費用等は利用者が負担するものとします。
6.やむを得ない理由により、故意又は過失なく本サービスを停止、若しくは中止又は廃止したことによる損害等について、当社はその責任を一切負わないものとします。
第12条(規約の変更)
1.本規約は法令の変更、監督官庁の指示、又は当社が必要として認めた場合には利用者の同意を得ることなく、いつでも、本サービス内容及び本規約の内容を変更することができるものとします。
2.前各項の場合であっても当社は利用者に事前に告知する義務を負いません。
第13条(準拠法、裁判管轄)
本規約の準拠法は日本法とします。又、本サービス又は本規約に関連して当社と利用者との間で生じた紛争については東京地方裁判所を第一審専属管轄裁判所とします。

私は、以下の内容を確認した上で書面等の電子交付等を電磁的方法でなされることを承諾します。

１． 電子交付等
電子交付とは、金融商品取引法等で金融商品取引業者（当社）からお客様への交付が義務付けられております各種交付書面等を、書面による交付に代えて電子情報処理組織（当社の使用に係るコンピュータとお客様の使用に係るコンピュータとを電気通信回線で接続した電子情報処理組織をいいます。以下同じ。）を使用する方法（以下「電磁的方法」といいます。）により交付することをいいます。
契約締結前交付書面、各種報告書等は、お客様のログインページ（ログインＩＤ、パスワード等入力後に掲載されるお客様専用ページをいいます。以下同じ。）で閲覧いただきます。ご提供する書面等は、お客様のログインページにＰＤＦファイル等で提供し保護しています。
２． 電子交付等の対象書面の種類
対象書面の種類は、民間事業者等が行う書面の保存等における情報通信の技術の利用に関する法律、金融商品取引法、投資信託及び投資法人に関する法律、金融商品取引業に関する内閣府令、金融商品取引所受託契約準則、自主規制法人関係諸規則その他関係法令・諸規則により電磁的方法による交付等が認められている書面、並びにその他当社とお客様の権利・義務に関する書類のうち、次の各号に掲げるものとします。

① 契約締結前交付書面「上場有価証券等書面の契約締結前交付書面」等の 金融商品取引法第37条の3の規定に基づきお客様に交付する書面
② 取引報告書
③ 取引残高報告書
④ 交付目論見書等
⑤ 外国証券情報
⑥ 運用報告書
⑦ 特定口座年間取引報告書
⑧ 上場株式配当金等支払通知書
⑨ 配当金、権利処理等お支払いのご案内
⑩ 当社における取引その他の取扱いに関するご通知
⑪ その他法令等により電磁的方法におる提供が認められているもの

３． 電子交付等の同意
お客様は、証券総合取引口座開設のお申込みに際し、事前に書面等の電子交付等による閲覧方法の内容をご承諾いただいたうえお申込みいただき、対象書面の電子交付等にご同意いただきます。なお、この同意は、対象とする全ての書面等について「一括して」行っていただきます。

４． 電子交付等の方法
電子交付等による対象書面は、Portal Document Format（以下「PDF」といいます。）の形式により提供いたします。電子交付等を受けるには、お客様にご利用いただくコンピュータのＯＳ、ＣＰＵ等が、当社が推奨する環境に適合していることを前提とします。
また、PDF形式による対象書面の記載事項をご覧いただくため、お客様には、予め当社が推奨するバージョン以上の「Adobe Reader」等のPDF閲覧用ソフトをインターネットからダウンロードしご利用いただきます。なお、ご利用いただいている「Adobe Reader」がバージョンアップ（プログラムの改定）した場合でも、電子交付は継続しますので、バージョンアップしたものをダウンロードしていただきます。

５． ホームページ等で確認できる事項
お客様は、当社公式ページまたはお客様のログインページで対象書面の記載事項を閲覧できるほか、お客様のログインページで対象書面の記載事項の交付履歴を確認できます。

６． 電子交付の解約
電子交付等は、次の各号に該当する場合には解約されるものとします。
（1）電子交付等が終了した場合
（2）証券総合取引口座の解約の事由に該当した場合、又はやむを得ない事由により当社が電子交付等の解約を申し出た場合
（3）当社が電子交付等を終了した場合
（4）その他当社が必要と判断した場合

７． 免責事項
法令等の変更や監督官庁の指示その他の必要な事態が発生した場合等、何らかの理由が生じた場合、あるいは当社が必要と判断した場合には、当社は電子交付ではなく、紙媒体による書面の交付等を行う場合があります。

次に掲げる場合はお客様に生じた損害については、その責を負わないものとします、ただし、当社の故意又は重過失による場合を除きます。
（1）通信機器、通信回線、コンピュータシステム機器の障害又は混雑による情報伝達の遅延、不能、誤作動、誤操作等により、電子交付等の全て又は一部の提供ができなくなった場合。
（2）お客様自身で入力したか否かにかかわらず、予め当社に届けられている内容と一致し、お客様以外の第三者が電子交付等を利用した場合。
（3）天変地異、政変、外貨事情の急変、外国為替市場の閉鎖等、不可抗力と認められる事由により、各種情報の提供等が遅延し、又は不能になった場合。
（4）その他当社の責めに帰さない事由によりお客様に生じた損害

以 上
`;
