/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { theme } from '@/theme/theme';
import { LocationState } from '@/components/pages/Withdrawal/WithdrawalInput';
import { Box, Typography, styled } from '@mui/material';
import { CSSProperties, FC } from 'react';
import { InfoRow } from '../infoRow';

interface PdfContentProps {
  title: string;
  data: LocationState;
}

const Container = styled(Box)(() => ({
  marginTop: 24,
  '@media print': {
    marginTop: 24,
  },
}));

const ContainerTitle = styled(Typography)(() => ({
  ...theme.typography.h2,
  fontWeight: 500,
  paddingBottom: 12,
  borderWidth: 0,
  fontSize: 26,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: '#000000',
  [theme.breakpoints.up('sm')]: {
    fontSize: 26,
  },
  '@media print': {
    ...theme.typography.h2,
    fontWeight: 500,
    paddingBottom: 12,
    borderWidth: 0,
    fontSize: 21,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    [theme.breakpoints.up('sm')]: {
      fontSize: 21,
    },
  },
}));

const ContainerContent = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  '@media print': {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
}));

const ContainerContenstWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 20,
  '@media print': {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
  },
}));

const ContainerBorderStyle: CSSProperties = {
  borderRadius: '12px',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '#000000',
  padding: '16px',
};

export const PartContent: FC<PdfContentProps> = ({ title, data }) => {
  const { stocksNumber, payUnit } = data;

  const getRemainingStocks = (): string => {
    if (!stocksNumber || !payUnit) return '0';

    return (stocksNumber - payUnit || 0).toLocaleString(undefined, {
      maximumFractionDigits: data.association.investmentDigit,
    });
  };

  return (
    <Container
      sx={{
        ...ContainerBorderStyle,
        marginTop: '40px',
        '@media print': {
          padding: '12px',
          borderRadius: '6px',
        },
      }}
    >
      <ContainerTitle>{title}</ContainerTitle>
      <ContainerContenstWrapper>
        <ContainerContent>
          <InfoRow
            key="現在の保有株数-part"
            title="現在の保有株数"
            content={`${stocksNumber}株`}
          />
          <InfoRow
            key="引出株数-part"
            title="引出株数"
            content={`${payUnit || 0}株`}
          />
          <InfoRow
            key="残り株数=part"
            title="残り株数"
            content={`${getRemainingStocks()}株`}
            disableSeparator
          />
        </ContainerContent>
      </ContainerContenstWrapper>
    </Container>
  );
};
