import { FC, useState } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { SelectBox } from '@/components/atoms/Input/SelectBox';
import { months, simulationYears } from '@/constants/choicesForSelectBox';
import { theme } from '@/theme/theme';

import type { SelectChangeEvent } from '@mui/material';

interface MonthOrYearSelectionProps {
  trail: string;
  marginTop?: number;
  type: 'month' | 'year';
  disabled: boolean;
  onChange?: (e: SelectChangeEvent<any>) => void;
}

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: 16,
});

const Trail = styled(Typography)(() => {
  const { typography, palette } = theme;

  return {
    ...typography['body-sub/regular'],
    color: palette.system['text-normal'],
    marginLeft: 8,
  };
});

export const MonthOrYearSelection: FC<MonthOrYearSelectionProps> = ({
  trail,
  marginTop,
  type,
  disabled,
  onChange,
}) => {
  const [value, setValue] = useState('');
  return (
    <Wrapper
      sx={{
        marginTop: { xs: marginTop, sm: 0 },
      }}
    >
      <SelectBox
        width="80px"
        disabled={disabled}
        value={value}
        items={type === 'month' ? months : simulationYears}
        backgroundColor={theme.palette.system.white}
        onChange={(e: SelectChangeEvent<any>) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          e.target.value && setValue(e.target.value as string);
          if (onChange) {
            onChange(e);
          }
        }}
      />
      <Trail>{trail}</Trail>
    </Wrapper>
  );
};
