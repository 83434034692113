const date = new Date();

// NUM=値 LEN=桁数
function zeroPadding(NUM: number, LEN: number) {
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  return (Array(LEN).join('0') + NUM).slice(-LEN);
}

export const years = Array.from({ length: 100 }, (_, i) => ({
  key: String(date.getFullYear() - 100 + i),
  value: date.getFullYear() - 100 + i,
}));

export const simulationYears = Array.from(
  { length: date.getFullYear() - 1996 },
  (_, i) => ({
    key: String(1997 + i),
    value: 1997 + i,
  })
);

export const months = Array.from({ length: 12 }, (_, i) => ({
  key: String(zeroPadding(i + 1, 2)),
  value: 1 + i,
}));

export const days = Array.from({ length: 31 }, (_, i) => ({
  key: String(zeroPadding(i + 1, 2)),
  value: 1 + i,
}));

export const prefecturesBase = [
  { key: '1', value: '北海道' },
  { key: '2', value: '青森県' },
  { key: '3', value: '岩手県' },
  { key: '4', value: '宮城県' },
  { key: '5', value: '秋田県' },
  { key: '6', value: '山形県' },
  { key: '7', value: '福島県' },
  { key: '8', value: '茨城県' },
  { key: '9', value: '栃木県' },
  { key: '10', value: '群馬県' },
  { key: '11', value: '埼玉県' },
  { key: '12', value: '千葉県' },
  { key: '13', value: '東京都' },
  { key: '14', value: '神奈川県' },
  { key: '15', value: '新潟県' },
  { key: '16', value: '富山県' },
  { key: '17', value: '石川県' },
  { key: '18', value: '福井県' },
  { key: '19', value: '山梨県' },
  { key: '20', value: '長野県' },
  { key: '21', value: '岐阜県' },
  { key: '22', value: '静岡県' },
  { key: '23', value: '愛知県' },
  { key: '24', value: '三重県' },
  { key: '25', value: '滋賀県' },
  { key: '26', value: '京都府' },
  { key: '27', value: '大阪府' },
  { key: '28', value: '兵庫県' },
  { key: '29', value: '奈良県' },
  { key: '30', value: '和歌山県' },
  { key: '31', value: '鳥取県' },
  { key: '32', value: '島根県' },
  { key: '33', value: '岡山県' },
  { key: '34', value: '広島県' },
  { key: '35', value: '山口県' },
  { key: '36', value: '徳島県' },
  { key: '37', value: '香川県' },
  { key: '38', value: '愛媛県' },
  { key: '39', value: '高知県' },
  { key: '40', value: '福岡県' },
  { key: '41', value: '佐賀県' },
  { key: '42', value: '長崎県' },
  { key: '43', value: '熊本県' },
  { key: '44', value: '大分県' },
  { key: '45', value: '宮崎県' },
  { key: '46', value: '鹿児島県' },
  { key: '47', value: '沖縄県' },
];
export const prefectures = [
  { key: '北海道', value: '北海道' },
  { key: '青森県', value: '青森県' },
  { key: '岩手県', value: '岩手県' },
  { key: '宮城県', value: '宮城県' },
  { key: '秋田県', value: '秋田県' },
  { key: '山形県', value: '山形県' },
  { key: '福島県', value: '福島県' },
  { key: '茨城県', value: '茨城県' },
  { key: '栃木県', value: '栃木県' },
  { key: '群馬県', value: '群馬県' },
  { key: '埼玉県', value: '埼玉県' },
  { key: '千葉県', value: '千葉県' },
  { key: '東京都', value: '東京都' },
  { key: '神奈川県', value: '神奈川県' },
  { key: '新潟県', value: '新潟県' },
  { key: '富山県', value: '富山県' },
  { key: '石川県', value: '石川県' },
  { key: '福井県', value: '福井県' },
  { key: '山梨県', value: '山梨県' },
  { key: '長野県', value: '長野県' },
  { key: '岐阜県', value: '岐阜県' },
  { key: '静岡県', value: '静岡県' },
  { key: '愛知県', value: '愛知県' },
  { key: '三重県', value: '三重県' },
  { key: '滋賀県', value: '滋賀県' },
  { key: '京都府', value: '京都府' },
  { key: '大阪府', value: '大阪府' },
  { key: '兵庫県', value: '兵庫県' },
  { key: '奈良県', value: '奈良県' },
  { key: '和歌山県', value: '和歌山県' },
  { key: '鳥取県', value: '鳥取県' },
  { key: '島根県', value: '島根県' },
  { key: '岡山県', value: '岡山県' },
  { key: '広島県', value: '広島県' },
  { key: '山口県', value: '山口県' },
  { key: '徳島県', value: '徳島県' },
  { key: '香川県', value: '香川県' },
  { key: '愛媛県', value: '愛媛県' },
  { key: '高知県', value: '高知県' },
  { key: '福岡県', value: '福岡県' },
  { key: '佐賀県', value: '佐賀県' },
  { key: '長崎県', value: '長崎県' },
  { key: '熊本県', value: '熊本県' },
  { key: '大分県', value: '大分県' },
  { key: '宮崎県', value: '宮崎県' },
  { key: '鹿児島県', value: '鹿児島県' },
  { key: '沖縄県', value: '沖縄県' },
];

export const reasonsForRecess = [
  {
    key: '',
    value: '休止理由を選択してください',
  },
  {
    key: 'LEAVE_ABSENCE',
    value: '休職/退職',
  },
  {
    key: 'FAMILY_MATTERS',
    value: '家族理由',
  },
  {
    key: 'ECONOMIC',
    value: '経済理由',
  },
  {
    key: 'OTHER',
    value: 'その他',
  },
];

export const reasonsForWithdrawal = [
  {
    key: '',
    value: '退会理由を選択してください',
  },
  {
    key: 'LEAVE_ABSENCE',
    value: '退職',
  },
  {
    key: 'FAMILY_MATTERS',
    value: '家庭の事情',
  },
  {
    key: 'ECONOMIC',
    value: '経済的理由',
  },
  {
    key: 'OTHER',
    value: 'その他（理由を記入）',
  },
];
export const reasonsForWithdrawalSp = [
  {
    key: '',
    value: '選択してください',
  },
  {
    key: '1',
    value:
      '退会理由。退会理由。退会理由。退会理由。退会理由。退会理由。退会理由。',
  },
  {
    key: '2',
    value:
      '退会理由。退会理由。退会理由。退会理由。退会理由。退会理由。退会理由。',
  },
  {
    key: '3',
    value:
      '退会理由。退会理由。退会理由。退会理由。退会理由。退会理由。退会理由。',
  },
  {
    key: '4',
    value:
      '退会理由。退会理由。退会理由。退会理由。退会理由。退会理由。退会理由。',
  },
  {
    key: '5',
    value:
      '退会理由。退会理由。退会理由。退会理由。退会理由。退会理由。退会理由。',
  },
];
