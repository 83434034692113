import { AssociationContribution } from '@/types/api/associationContribution';
import { UserContribution } from '@/types/api/userContribution';

export const BountyCalculation = (
  type: 'MONTHLY' | 'BONUS',
  data: AssociationContribution,
  bonusUnit: number
): number => {
  let result = 0;

  if (type === 'BONUS') {
    if (data.bonusIncentiveRatio === 0) result = data.bonusIncentiveFixedAmount;
    const tempResult =
      (bonusUnit * data.unitAmount * data.bonusIncentiveRatio) / 100;
    result =
      tempResult > data.bonusIncentiveUpperLimit
        ? data.bonusIncentiveUpperLimit
        : tempResult;
  } else {
    if (data.monthlyIncentiveRatio === 0)
      result = data.monthlyIncentiveFixedAmount;
    const tempResult =
      (bonusUnit * data.unitAmount * data.monthlyIncentiveRatio) / 100;
    result =
      tempResult > data.monthlyIncentiveUpperLimit
        ? data.monthlyIncentiveUpperLimit
        : tempResult;
  }

  return result;
};

export const BonusFixedMultiplierCalculation = (
  type: 'MONTHLY' | 'BONUS',
  data: AssociationContribution,
  unitAmount: number
) => {
  let result = 0;
  let totalUnit = 0;

  if (type === 'BONUS') {
    totalUnit = unitAmount * data.bonusMultiplierDetails[0];
    const tempResult =
      (totalUnit * data.unitAmount * data.monthlyIncentiveRatio) / 100;
    result =
      tempResult > data.bonusIncentiveUpperLimit
        ? data.bonusIncentiveUpperLimit
        : tempResult;
  }
  return result;
};

export const Calculations = {
  bonusUnitAmount: (
    associationContributions: AssociationContribution,
    memberContributions: UserContribution
  ): number => {
    let result = 0;
    switch (associationContributions.bonusMultiplierDesignationType) {
      case 'FIXED_MAGNIFICATION':
        if (memberContributions.bonusRecessClassification === '1')
          return result;
        result =
          Number(memberContributions.monthlyUnit) *
          Number(associationContributions.bonusMultiplierDetails);
        return result;
      default:
        return result;
    }
  },
};
