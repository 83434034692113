import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { TextInput } from '@/components/atoms/Input/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, SvgIcon, Theme, Typography, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useController,
  useForm,
  useFormState,
} from 'react-hook-form';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { BREAKPOINT } from '@/theme/theme';
import {
  MyPageIdProps as Props,
  MyPageIdLabelProps as LabelProps,
} from '@/types/components/MyPageId';
import { ReactComponent as Icon } from '@/assets/mail.svg';

import { FindUser, UpdateUserLoginid } from '@/types/api/users';

const FIELD_NAME = 'email' as keyof UpdateUserLoginid;

const Title: FC = () => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      mb: '24px',
      gap: '4px',
    }}
  >
    <SvgIcon sx={{ fill: (theme: Theme) => theme.palette.system.placeholder }}>
      <Icon />
    </SvgIcon>
    <Typography variant="h4" sx={{ color: 'system.text-normal' }}>
      ログインID変更
    </Typography>
  </Box>
);

const Label: FC<LabelProps> = ({ isPc, labelCaption }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: isPc ? 'column' : 'row',
      alignItems: isPc ? 'flex-start' : 'center',
      p: '0',
      gap: isPc ? '0' : '4px',
    }}
  >
    <Typography
      component="span"
      variant="body-main/bold"
      sx={{ color: 'system.text-normal' }}
    >
      {labelCaption}
    </Typography>
    <Typography
      component="span"
      variant="caption/regular"
      sx={{ color: 'system.text-light' }}
    >
      ※メールアドレス
    </Typography>
  </Box>
);

const LoginId: FC = () => {
  const {
    field: { ref, ...rest },
  } = useController({ name: FIELD_NAME });
  const errors = useFormState().errors as {
    [x: string]: { message: string };
  };
  return (
    <TextInput
      {...rest}
      error={!!errors[FIELD_NAME]}
      helperText={errors[FIELD_NAME]?.message}
      inputRef={ref}
    />
  );
};

const LabelLoginId: FC<LabelProps & Props> = ({
  isPc,
  labelCaption,
  children,
  underline = false,
}) => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: isPc ? 'row' : 'column',
      alignItems: 'flex-start',
      pb: underline ? '16px' : '0',
      gap: isPc ? '24px' : '8px',
      borderBottom: underline ? '1px solid' : '0',
      borderColor: 'system.separator-light',
    }}
  >
    <Label isPc={isPc} labelCaption={labelCaption} />
    {children}
  </Box>
);

const PrimarySecondary: FC<Props> = (props) => {
  const { isPc } = props;
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          margin: '0 auto',
          width: isPc ? '404px' : '100%',
          display: 'flex',
          flexDirection: isPc ? 'row-reverse' : 'column',
          alignItems: isPc ? 'center' : 'flex-start',
          padding: '0px',
          gap: '16px',
        }}
      >
        <PrimaryButton
          type="submit"
          sx={{
            width: isPc ? '226px' : '100%',
            height: '56px',
            padding: '20px, 24px, 20px, 26px',
            borderRadius: '28px',
          }}
        >
          認証コードの入力へ進む
        </PrimaryButton>
        <SecondaryButton
          sx={{
            width: isPc ? '162px' : '100%',
            height: '56px',
            padding: '20px, 24px, 20px, 26px',
            borderRadius: '28px',
          }}
          onClick={() => navigate('/mypage')}
        >
          変更せずに戻る
        </SecondaryButton>
      </Box>
    </Box>
  );
};

const Contents: FC<Props> = (props) => {
  const { isPc, user } = props;
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: isPc ? '32px' : '24px 16px',
        gap: isPc ? '16px' : '24px',
        backgroundColor: 'system.white',
        borderRadius: isPc ? '12px' : '0',
      }}
    >
      <LabelLoginId
        isPc={isPc}
        labelCaption="現在のログインID"
        underline
        user={user}
      >
        <Typography
          component="span"
          variant="body-main/regular"
          sx={{ color: 'system.text-normal' }}
        >
          {user?.email}
        </Typography>
      </LabelLoginId>
      <LabelLoginId isPc={isPc} labelCaption="新しいログインID" user={user}>
        <LoginId />
      </LabelLoginId>
      <Typography variant="body-sub/regular" m="40px 0">
        ※「認証コードの入力へ進む」を押すと、新しいメールアドレス宛に認証コードが届きます。
        <br />
        ※次の画面に、届いた認証コードを入力してください。
      </Typography>
      <PrimarySecondary isPc={isPc} user={user} />
    </Box>
  );
};

const Wrapper: FC<Props> = ({ isPc, user }) => (
  <Box sx={{ maxWidth: '780px', margin: '0 auto' }}>
    <Title />
    <Contents isPc={isPc} user={user} />
  </Box>
);

export const MyPageIdOrganism: FC<{
  user: FindUser;
  handleSubmit: (data: UpdateUserLoginid) => void;
}> = ({ user, handleSubmit }) => {
  const isPc = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(BREAKPOINT)
  );
  const schema: yup.SchemaOf<UpdateUserLoginid> = yup.object({
    [FIELD_NAME]: yup
      .string()
      .email('メールアドレスを入力してください')
      .required('入力してください'),
  });
  const methods = useForm<UpdateUserLoginid>({
    defaultValues: {
      [FIELD_NAME]: '',
    },
    resolver: yupResolver(schema),
  });
  const onSubmit: SubmitHandler<UpdateUserLoginid> = (data) => {
    handleSubmit(data);
  };

  return (
    <Box
      sx={{
        bgcolor: 'system.background',
        padding: {
          xs: '24px 0 86px',
          sm: '48px 40px 386px',
          md: '48px 40px 386px',
          lg: '48px 130px 386px',
        },
      }}
    >
      <FormProvider {...methods}>
        {/**
         * lintエラー公式未対応
         * @see https://react-hook-form.com/api/useform/handlesubmit
         */}
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Wrapper isPc={isPc} user={user} />
        </form>
      </FormProvider>
    </Box>
  );
};
