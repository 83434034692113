import { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BasicModal } from '@/components/molecules/Modal/BasicModal.tsx';
import { MyPageSecuritiesOrganism } from '@/components/organism/MyPage/MyPageSecurities';
import { MyPageSecuritiesFormValues as FormValues } from '@/types/components/MyPageSecurities';
import { appClient } from '@/services';
import { useLoader, useSnackbarMessage, useSessionOutModal } from '@/hooks';
import { Path } from '@/constants/Router/path';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { BREAKPOINT } from '@/theme/theme';
import useSWR from 'swr';

import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { useMaskoutUser } from './hooks/useMaskoutUser';
import { useMyPageHooks } from './hooks/useMyPageHooks';

const styleContainer: SxProps<Theme> = [
  {
    width: '343px',
    height: '184px',
    textAlign: 'center',
    p: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    outline: '0px',
  },
  (theme) => ({
    [theme.breakpoints.up(BREAKPOINT)]: {
      width: '520px',
      height: '176px',
      p: '32px',
    },
  }),
];

/**
 * MyPageSecurities
 * @constructor
 */
export const MyPageSecurities: FC = () => {
  const navigate = useNavigate();
  const { handleSessionOutModalOpen } = useSessionOutModal();
  const { maskoutUserSecuritiesAccount, setPassword } = useMaskoutUser();
  const { handleLoaderOpen, handleLoaderClose } = useLoader();
  const { userSecuritiesAccounts, userSecuritiesAccountsMutate } =
    useMyPageHooks();
  const { showMessage } = useSnackbarMessage();
  // 部店名
  const [departmentName, setDepartmentName] = useState<string>('');
  const [branchCd, setBranchCd] = useState<string>('');
  const [accountNumber, setAccountNumber] = useState<string>('');
  const { password } = useMaskoutUser();
  const [isPassword, setIsPassword] = useState<boolean>(false);
  useHelmetHandler({
    title: 'マイページ 振替口座情報変更',
  });

  // 【API-A-0906】証券口座情報取得
  const { data: findUserSecuritiesInformationsMaskout } = useSWR(
    '/api/users/securitiesaccounts/maskout',
    () => appClient.users.findUserSecuritiesInformationsMaskout({ password })
  );

  /**
   * createSecuritiesAccount
   * @param data
   */
  const createSecuritiesAccount = async (data: FormValues) => {
    try {
      await appClient.users.createSecuritiesAccount({
        branchCode: data.departmentCode,
        branchName: data.departmentName,
        accountNumber: data.accountNumber,
      });
      // swrのキャッシュの更新
      await userSecuritiesAccountsMutate();
      setPassword('');
      handleLoaderClose();
      showMessage({ open: true, messages: ['証券口座情報を作成しました'] });
      navigate(`${Path.MYPAGE}`);
    } catch (e) {
      handleLoaderClose();
      console.error(e);
      showMessage({
        open: true,
        messageType: 'error',
        messages: ['証券口座情報作成に失敗しました'],
      });
    }
  };
  /**
   * updateSecuritiesAccount
   * @param data
   */
  const updateSecuritiesAccount = async (data: FormValues) => {
    try {
      await appClient.users.updateSecuritiesAccount({
        branchCode: data.departmentCode,
        branchName: data.departmentName,
        accountNumber: data.accountNumber,
      });
      // swrのキャッシュの更新
      await userSecuritiesAccountsMutate();
      setPassword('');
      handleLoaderClose();
      showMessage({ open: true, messages: ['証券口座情報を変更しました'] });
      navigate(`${Path.MYPAGE}`);
    } catch (e) {
      handleLoaderClose();
      console.error(e);
      showMessage({
        open: true,
        messageType: 'error',
        messages: ['証券口座情報変更に失敗しました'],
      });
    }
  };
  /**
   * handleSubmit
   * @param data
   */
  const handleSubmit = async (data: FormValues) => {
    handleLoaderOpen();
    if (Object.values(userSecuritiesAccounts ?? {}).find((value) => value)) {
      await updateSecuritiesAccount(data);
    } else {
      await createSecuritiesAccount(data);
    }
  };

  useEffect(() => {
    setDepartmentName(findUserSecuritiesInformationsMaskout?.branchName || '');
    setBranchCd(findUserSecuritiesInformationsMaskout?.branchCd || '');
    setAccountNumber(
      findUserSecuritiesInformationsMaskout?.accountNumber || ''
    );
  }, [findUserSecuritiesInformationsMaskout]);

  useEffect(() => {
    if (password === '') setIsPassword(true);
  }, [password]);

  /**
   * getDepartmentName
   */
  // eslint-disable-next-line consistent-return
  const getDepartmentName = async (departmentCode: string) => {
    try {
      const res = await appClient.users.findBranch(departmentCode);
      setDepartmentName(res.branchName as string);
    } catch (error) {
      console.error(error);
      if (error) {
        // 認証切れの場合session out modalを表示
        if (error === 'ApiError: Unauthorized') {
          return handleSessionOutModalOpen();
        }
        // その他のエラーの場合はエラーメッセージを表示
        showMessage({
          open: true,
          messageType: 'error',
          messages: ['部店名の取得に失敗しました'],
        });
      }
    }
  };

  return (
    <>
      <MyPageSecuritiesOrganism
        userSecuritiesAccount={maskoutUserSecuritiesAccount}
        branchName={departmentName}
        branchCode={branchCd}
        accountNumber={accountNumber}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        handleSubmit={(data) => handleSubmit(data)}
        getDepartmentName={(departmentCode: string) =>
          getDepartmentName(departmentCode)
        }
        setAccountNumber={setAccountNumber}
        setBranchCd={setBranchCd}
      />
      <BasicModal open={isPassword}>
        <Box sx={styleContainer}>
          <Typography
            component="p"
            variant="body-main/regular"
            sx={{ color: 'system.text-normal' }}
          >
            再度パスワード認証を行う必要があります。
            <br />
            マイページTOPから再度操作を行なってください。
          </Typography>
          <PrimaryButton onClick={() => navigate(`${Path.MYPAGE}`)}>
            マイページTOPへ移動
          </PrimaryButton>
        </Box>
      </BasicModal>
    </>
  );
};
