import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { LoginBox } from '@/components/molecules/Login/LoginBox';
import { LoginTitleWithUnderline } from '@/components/molecules/Login/LoginTitleWithUnderline';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { Box, Link, styled, Typography } from '@mui/material';
import { FC } from 'react';

const NormalTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography['body-main/regular'],
  color: theme.palette.system['text-normal'],
  textAlign: 'left',
}));

export const LoginPasswordComplete: FC = () => {
  useHelmetHandler({
    title: 'パスワード再設定 変更完了',
  });

  return (
    <LoginBox>
      <LoginTitleWithUnderline isLogin title="パスワード変更完了" />
      <NormalTypography>
        パスワードの変更が完了しました。 ログイン画面へ移動してください。
      </NormalTypography>
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Link href="/login">
          <PrimaryButton>ログイン画面へ移動する</PrimaryButton>
        </Link>
      </Box>
    </LoginBox>
  );
};
