import { styled } from '@mui/material';
import { FC } from 'react';
import { BREAKPOINT } from '@/theme/theme';
import { AccordionBox } from './AcordionBox';
import { questions } from '../../../../constants/Questions';

const Wrap = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const QuestionWrap = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    borderRadius: '12px',
    width: '100%',
    padding: '24px 24px 5px',
    backgroundColor: 'white',
    [breakpoints.down(BREAKPOINT)]: {
      padding: '24px 0px 0px',
      borderRadius: '0px',
    },
  };
});
const QuestionTitleWrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '100%',
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.2)',
    paddingBottom: '16px',
    [breakpoints.down(BREAKPOINT)]: {
      paddingLeft: '16px',
    },
  };
});

const QuestionTitleWrap = styled('h5')(({ theme }) => {
  const { palette, typography } = theme;
  return {
    ...typography.h5,
    color: palette.secondary.main,
    margin: '0px',
  };
});

export const FAQs: FC = () => (
  <Wrap>
    {questions.map((item) => (
      <QuestionWrap key={item.title}>
        <QuestionTitleWrapper>
          <QuestionTitleWrap>{item.title}</QuestionTitleWrap>
        </QuestionTitleWrapper>
        {item.questions.map((question) => (
          <AccordionBox question={question} key={question.question} />
        ))}
      </QuestionWrap>
    ))}
  </Wrap>
);
