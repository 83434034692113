/* eslint-disable @typescript-eslint/no-misused-promises */
import { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import {
  useFindAssociationContribution,
  useMediaQuery,
  useSnackbarMessage,
} from '@/hooks';
import {
  Label,
  Row,
  SignUpContributionOrganism,
  SubLabel,
  Title,
  Value,
} from '@/components/organism/SignUp/SignUpContribution';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { numberFormat } from '@/utils/numberFormat';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { Path } from '@/constants/Router/path';
import { appClient } from '@/services';
import { BountyCalculation } from '@/utils/calculation';
import { AssociationContribution } from '@/types/api/associationContribution';
import { Association } from '@/types/api/association';
import { theme } from '@/theme/theme';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

type LocationState = {
  unitAmount: number;
  isBonusUnit: boolean;
  monthlyUnit: number;
  bonusUnit: number;
  bonusRate: number;
  bonusMultiplierDesignationType:
    | 'UNIT_NUMBER'
    | 'MAGNIFICATION'
    | 'FIXED_MAGNIFICATION';
  monthlyIncentiveRatio: number;
  associationContribution: AssociationContribution;
  associationData: Association;
};

export const SignUpContributionConfirm: FC = () => {
  const { isPc } = useMediaQuery();
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationState;
  const { showMessage } = useSnackbarMessage();

  useHelmetHandler({
    title: '持株会入会登録 拠出設定確認',
  });

  const { data: associationContribution } = useFindAssociationContribution();

  const handleSubmit = async () => {
    try {
      const body = {
        isBonusUnit: state.isBonusUnit,
        monthlyUnit: state.monthlyUnit,
        bonusUnit: state.isBonusUnit ? state.bonusUnit : 0,
        bonusRate: state.isBonusUnit ? state.bonusRate : 0,
      };
      await appClient.users.setupUserContribution(body);
      navigate(Path.CONTRIBUTION_COMPLETE, {
        state: {
          associationData: state.associationData,
        },
      });
    } catch (e) {
      showMessage({
        open: true,
        messageType: 'error',
        messages: ['会員情報変更に失敗しました'],
      });
    }
  };

  const contribution = useMemo(
    () =>
      state.bonusMultiplierDesignationType === 'UNIT_NUMBER'
        ? state.bonusUnit
        : state.bonusRate * state.monthlyUnit,
    [state]
  );

  if (associationContribution) {
    return (
      <>
        {state.associationData.associationName && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '40px',
              marginTop: '32px',
            }}
          >
            <Typography
              sx={{
                ...theme.typography.h6,
                color: theme.palette.system['text-light'],
              }}
            >
              {state.associationData.associationName}
            </Typography>
          </Box>
        )}
        <SignUpContributionOrganism>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: isPc ? '32px' : '32px 16px',
              gap: '16px',
              backgroundColor: 'system.white',
              borderRadius: isPc ? '0 0 12px 12px' : '0',
            }}
          >
            <Title isPc={isPc} />
            <Row>
              <Label>1口あたり金額</Label>
              <Typography
                variant="body-main/bold"
                sx={{ color: 'system/text-normal' }}
              >
                {numberFormat(state.unitAmount)}円
              </Typography>
            </Row>
            <Row>
              <Label>奨励金率</Label>
              <Typography
                variant="body-main/bold"
                sx={{ color: 'system/text-normal' }}
              >
                {state.monthlyIncentiveRatio}%
              </Typography>
            </Row>
            <Row>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Label>給与</Label>
                <Typography
                  variant="caption/regular"
                  sx={{ color: 'system.text-light' }}
                >
                  ※毎月の拠出
                </Typography>
              </Box>
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <SubLabel>口数</SubLabel>
                  <Typography
                    variant="body-sub/regular"
                    sx={{ color: 'system.text-normal' }}
                  >
                    {state.monthlyUnit}口
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <SubLabel>拠出金額</SubLabel>
                  <Value>
                    {numberFormat(state.unitAmount * state.monthlyUnit)}
                  </Value>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <SubLabel>奨励金額</SubLabel>
                  <Value>
                    {numberFormat(
                      BountyCalculation(
                        'MONTHLY',
                        state.associationContribution,
                        state.monthlyUnit ?? 0
                      ),
                      { maximumFractionDigits: 0 },
                      true
                    )}
                  </Value>
                </Box>
              </Box>
            </Row>

            {associationContribution?.bonusReserveClassification === 'NONE' ||
            associationContribution?.bonusUnitClassification === '2' ? null : (
              <Row>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Label>賞与</Label>
                </Box>
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
                >
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                  >
                    <SubLabel>拠出</SubLabel>
                    {state.isBonusUnit ? 'する' : 'しない'}
                  </Box>
                  {state.isBonusUnit && (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <SubLabel>口数</SubLabel>
                        <Typography
                          variant="body-sub/regular"
                          sx={{ color: 'system.text-normal' }}
                        >
                          {state.bonusUnit}口
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <SubLabel>拠出金額</SubLabel>
                        <Value>
                          {numberFormat(contribution * state.unitAmount)}
                        </Value>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <SubLabel>奨励金額</SubLabel>
                        <Value>
                          {numberFormat(
                            BountyCalculation(
                              'BONUS',
                              state.associationContribution,
                              contribution ?? 0
                            ),
                            { maximumFractionDigits: 0 },
                            true
                          )}
                        </Value>
                      </Box>
                    </>
                  )}
                </Box>
              </Row>
            )}
            <Box
              sx={{
                width: isPc ? '320px' : '100%',
                display: 'flex',
                flexDirection: isPc ? 'row-reverse' : 'column',
                alignItems: isPc ? 'center' : 'flex-start',
                p: '0px',
                mt: '24px',
                gap: '16px',
              }}
            >
              <PrimaryButton
                sx={{
                  width: isPc ? '184px' : '100%',
                  height: isPc ? '40px' : '56px',
                  padding: isPc ? '8px 20px' : '20px, 24px, 20px, 26px',
                  borderRadius: isPc ? '20px' : '28px',
                }}
                type="submit"
                onClick={() => handleSubmit()}
              >
                拠出設定を確定する
              </PrimaryButton>
              <SecondaryButton
                sx={{
                  width: isPc ? '120px' : '100%',
                  height: isPc ? '40px' : '56px',
                  padding: isPc ? '8px 20px' : '20px, 24px, 20px, 26px',
                  borderRadius: isPc ? '20px' : '28px',
                }}
                onClick={() =>
                  navigate(Path.CONTRIBUTION_INPUT, {
                    state: {
                      isBonusUnit: state.isBonusUnit,
                      monthlyUnit: state.monthlyUnit,
                      bonusUnit: state.bonusUnit,
                      bonusRate: state.bonusRate,
                    },
                  })
                }
              >
                入力に戻る
              </SecondaryButton>
            </Box>
          </Box>
        </SignUpContributionOrganism>
      </>
    );
  }
  return null;
};
