import { BREAKPOINT } from '@/theme/theme';
import {
  styled,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';

interface TableProps {
  children: React.ReactNode;
  // テーブルのタイトル
  title: string;
}

const MuiTableContainer = styled(TableContainer)(({ theme }) => {
  const { breakpoints } = theme;
  return {
    borderRadius: '12px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    [breakpoints.down(BREAKPOINT)]: {
      borderRadius: '0',
    },
  };
});

const MuiTable = styled(Table)(() => ({
  padding: '16px',
  width: '100%',
  height: '56px',
}));

const MuiTableHead = styled(TableHead)(({ theme }) => {
  const { palette } = theme;
  return {
    background: palette.primary.gradation,
  };
});

const MuiTableBody = styled(TableBody)(({ theme }) => {
  const { palette, typography, breakpoints } = theme;
  return {
    th: {
      ...typography['body-main/bold'],
      background: palette.primary.light,
      [breakpoints.down(BREAKPOINT)]: {
        lineHight: '20px',
      },
    },
  };
});

const MuiTableCell = styled(TableCell)(({ theme }) => {
  const { palette, typography } = theme;
  return {
    ...typography.h4,
    border: '1px solid rgba(0, 0, 0, 0.1)',
    color: palette.system.white,
  };
});

export const PrimaryTable = ({ children, title }: TableProps) => (
  <MuiTableContainer>
    <MuiTable aria-label="customized table">
      <MuiTableHead>
        <TableRow>
          <MuiTableCell colSpan={2}>{title}</MuiTableCell>
        </TableRow>
      </MuiTableHead>
      <MuiTableBody>{children}</MuiTableBody>
    </MuiTable>
  </MuiTableContainer>
);
