import { FC } from 'react';
import { Typography } from '@mui/material';
import { PrimaryTable } from '@/components/molecules/Table/PrimaryTable';
import {
  PrimaryTableBodyTdMulti,
  PrimaryTableBodyTdSub,
} from '@/components/atoms/Table/PrimaryTableBodyTdMulti';
import { PrimaryTableBodyTdSingle } from '@/components/atoms/Table/PrimaryTableBodyTdSingle';
import { appClient } from '@/services';
import useSWR from 'swr';
import { FindApplicationById } from '@/types/api/applications';

const accountType = {
  SAVINGS: '普通',
  CURRENT: '当座',
  OTHER: '貯蓄',
};

export const AdditionalOption: FC<
  Exclude<FindApplicationById['applicationWithdrawal'], undefined>
> = ({ addPurchaseSharesNum, transferAmount, remarks, institutionType }) => {
  // 【API-A-1005】持株会（事務局）振込先口座情報取得
  const { data: officeAssociationAccount } = useSWR(
    '/api/associations/officeassociationsaccounts',
    () => appClient.associations.findOfficeAssociationAccount()
  );

  if (officeAssociationAccount) {
    return (
      <PrimaryTable title="精算方法">
        <PrimaryTableBodyTdMulti
          title="売上単位"
          SecondaryTitle="未満株式分"
          sx={{ display: 'flex', flexDirection: 'column' }}
        >
          <Typography variant="body-main/regular">持株会に売却</Typography>
          <Typography variant="body-sub/regular" sx={{ color: '#666666' }}>
            買増株数 {addPurchaseSharesNum}株
          </Typography>
        </PrimaryTableBodyTdMulti>
        <PrimaryTableBodyTdMulti title="振込金額">
          <Typography variant="body-main/bold" sx={{ color: '#D20B0B' }}>
            {transferAmount}円
          </Typography>
        </PrimaryTableBodyTdMulti>
        <PrimaryTableBodyTdMulti title="備考">
          <Typography variant="body-main/bold" sx={{ color: '#D20B0B' }}>
            {remarks}
          </Typography>
        </PrimaryTableBodyTdMulti>
        {institutionType === 'OTHER' && (
          <>
            <PrimaryTableBodyTdMulti title="振込先口座" row={6}>
              <PrimaryTableBodyTdSub title="金融機関名">
                <Typography>
                  {officeAssociationAccount.financialInstitutionName}
                </Typography>
              </PrimaryTableBodyTdSub>
            </PrimaryTableBodyTdMulti>
            <PrimaryTableBodyTdSingle>
              <PrimaryTableBodyTdSub title="支店コード">
                <Typography>{officeAssociationAccount.bankBranchCd}</Typography>
              </PrimaryTableBodyTdSub>
            </PrimaryTableBodyTdSingle>
            <PrimaryTableBodyTdSingle>
              <PrimaryTableBodyTdSub title="支店名">
                <Typography>
                  {officeAssociationAccount.bankBranchName}
                </Typography>
              </PrimaryTableBodyTdSub>
            </PrimaryTableBodyTdSingle>
            <PrimaryTableBodyTdSingle>
              <PrimaryTableBodyTdSub title="種別">
                <Typography>
                  {
                    accountType[
                      officeAssociationAccount.accountType ?? 'SAVINGS'
                    ]
                  }
                </Typography>
              </PrimaryTableBodyTdSub>
            </PrimaryTableBodyTdSingle>
            <PrimaryTableBodyTdSingle>
              <PrimaryTableBodyTdSub title="口座番号">
                <Typography>
                  {officeAssociationAccount.accountNumber}
                </Typography>
              </PrimaryTableBodyTdSub>
            </PrimaryTableBodyTdSingle>
            <PrimaryTableBodyTdSingle>
              <PrimaryTableBodyTdSub title="口座名義">
                <Typography>{officeAssociationAccount.accountName}</Typography>
              </PrimaryTableBodyTdSub>
            </PrimaryTableBodyTdSingle>
          </>
        )}
        {institutionType === 'YUCHO' && (
          <>
            <PrimaryTableBodyTdMulti title="金融機関の種類">
              <Typography>ゆうちょ銀行</Typography>
            </PrimaryTableBodyTdMulti>
            <PrimaryTableBodyTdMulti title="店番・番号">
              <Typography variant="body-sub/regular">{`${
                officeAssociationAccount.accountYuchoSymbol ?? ''
              }-${
                officeAssociationAccount.accountYuchoNumber ?? ''
              }`}</Typography>
            </PrimaryTableBodyTdMulti>
          </>
        )}
      </PrimaryTable>
    );
  }
  return null;
};
