import { FC } from 'react';
import { Box, Typography, styled } from '@mui/material';

interface ResultCaptionProps {
  caption: string;
  captionBold: string;
}

const TitleWrapper = styled(Box)(({ theme }) => {
  const { breakpoints } = theme;

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',

    [breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  };
});

const Caption = styled(Typography)(({ theme }) => {
  const { typography, palette } = theme;

  return {
    ...typography['caption/regular'],
    color: palette.system['text-light'],
  };
});

const CaptionBold = styled(Typography)(({ theme }) => {
  const { typography, palette } = theme;

  return {
    ...typography['caption/bold'],
    color: palette.system['text-light'],
    marginTop: 8,
  };
});

export const ResultCaptions: FC<ResultCaptionProps> = ({
  caption,
  captionBold,
}) => (
  <TitleWrapper>
    <Caption>{caption}</Caption>
    <CaptionBold>{captionBold}</CaptionBold>
  </TitleWrapper>
);
