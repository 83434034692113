import { FC } from 'react';
import { theme } from '@/theme/theme';
import { Box, Typography, styled, SxProps } from '@mui/material';

interface BalanceInquiryHeadingProps {
  title: string;
  subtitle?: string;
  sx?: SxProps;
}

const TitleWrapper = styled(Box)(() => {
  const { breakpoints } = theme;

  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  };
});

const Title = styled(Typography)(() => {
  const { palette, typography } = theme;

  return {
    ...typography.h6,
    color: palette.system['text-normal'],
  };
});

const Subtitle = styled(Typography)(() => {
  const { breakpoints, palette, typography } = theme;

  return {
    ...typography['body-main/regular'],
    color: palette.system['text-light'],
    marginLeft: 8,

    [breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: 8,
    },
  };
});

export const BalanceInquiryHeading: FC<BalanceInquiryHeadingProps> = ({
  title,
  subtitle,
  sx,
}) => (
  <TitleWrapper sx={{ ...sx }}>
    <Title>{title}</Title>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
  </TitleWrapper>
);
