import { FC } from 'react';

export const KeyboardArrowDownIcon: FC = () => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 15 15"
    fill="#FFF1EB"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14 4.5L8 11L2 4.5" stroke="#ED5045" strokeWidth="1" />
  </svg>
);
