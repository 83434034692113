import { FC } from 'react';
import { MyPageUserOrganism } from '@/components/organism/MyPage/MyPageUser';
import { MyPageUserFormValues as FormValues } from '@/types/components/MyPageUser';
import { useAuthUser } from '@/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { Path } from '@/constants/Router/path';
import { appClient } from '@/services';

import { useGetAddressApi, useLoader, useSnackbarMessage } from '@/hooks';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { useMaskoutUser } from './hooks/useMaskoutUser';

export const MyPageUser: FC = () => {
  const navigate = useNavigate();
  const { user, updateUser } = useAuthUser();
  const { setPassword } = useMaskoutUser();
  const { showMessage } = useSnackbarMessage();
  const { handleLoaderOpen, handleLoaderClose } = useLoader();
  // [GetAddressApi]郵便番号から住所を取得するカスタムフック
  const { addressData, getAddress } = useGetAddressApi();
  useHelmetHandler({
    title: 'マイページ 会員情報変更',
  });

  /**
   * handleSubmit
   * @param data
   */
  const handleSubmit = async (data: FormValues) => {
    handleLoaderOpen();
    try {
      const body = {
        memberId: user.memberId,
        userStatus: user.userStatus,
        registeredType: user.registeredType,
        nameKanji: data.nameKanji,
        nameKana: data.nameKana,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        birthday: `${data.birthdayYear}-${data.birthdayMonth}-${data.birthdayDay}`,
        zipcode: data.zip,
        address1: data.address1,
        address2: data.address2,
        address3: data.address3,
        tel: data.tel,
        // eslint-disable-next-line
        telType: data.telType,
      };
      await appClient.users.updateUser(body);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      await updateUser();
      setPassword('');
      handleLoaderClose();
      showMessage({ open: true, messages: ['会員情報を変更しました'] });
      navigate(`${Path.MYPAGE}`);
    } catch (e) {
      handleLoaderClose();
      showMessage({
        open: true,
        messageType: 'error',
        messages: ['会員情報変更に失敗しました'],
      });
    }
  };

  const handleGetAddress = (zip: FormValues['zip']) => {
    if (zip === '') return;
    getAddress(zip);
  };

  return (
    <MyPageUserOrganism
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      handleSubmit={handleSubmit}
      getAddress={handleGetAddress}
      addressData={addressData}
    />
  );
};
