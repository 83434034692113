import { FC } from 'react';
import { styled } from '@mui/material';
import { HomeHeader } from '@/components/organism/HomeHeader';
import { SharedContact } from '@/components/organism/Contacts/sharedContact';
import { BREAKPOINT } from '@/theme/theme';
import { Path } from '@/constants/Router/path';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { FAQs } from '../../organism/Contacts/FAQs/FAQs';

const TopPageWrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '100%',
    padding: '33px 40px 32px',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    [breakpoints.down(BREAKPOINT)]: {
      padding: '24px 0px',
      gap: '24px',
    },
    [breakpoints.up('lg')]: {
      padding: '33px 130px 32px',
    },
  };
});

const HeaderWrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    [breakpoints.down(BREAKPOINT)]: {
      padding: '0 16px',
    },
  };
});

const BottomPageWrapper = styled('div')(({ theme }) => {
  const { breakpoints, palette } = theme;
  return {
    backgroundColor: palette.system.background,
    width: '100%',
    padding: '32px 40px 80px',
    [breakpoints.down(BREAKPOINT)]: {
      padding: '24px 0 60px',
    },
    [breakpoints.up('lg')]: {
      padding: '33px 130px 80px',
    },
  };
});

export const FAQ: FC = () => {
  useHelmetHandler({
    title: 'よくあるご質問',
  });
  return (
    <>
      <TopPageWrapper>
        <HeaderWrapper>
          <HomeHeader
            title="よくあるご質問"
            links={[
              { path: 'TOP', to: Path.HOME },
              { path: 'よくあるご質問', to: Path.FAQ },
            ]}
          />
        </HeaderWrapper>
        <SharedContact />
      </TopPageWrapper>
      <BottomPageWrapper>
        <FAQs />
      </BottomPageWrapper>
    </>
  );
};
