import {
  styled,
  Stack,
  Box,
  Typography,
  Divider,
  useMediaQuery,
} from '@mui/material';
import { BREAKPOINT, theme } from '@/theme/theme';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SignupImg1 } from '@/assets/signupImg1.svg';
import { ReactComponent as SignupImg2 } from '@/assets/signupImg2.svg';
import { ReactComponent as CheckRed } from '@/assets/checkRed.svg';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const SignUpWrapper = styled(Stack)({
  width: '100%',
  gap: '32px',
  alignItems: 'center',
});

const SignUpContainer = styled(Stack)({
  width: '880px',
  padding: '32px',
  gap: '32px',
  backgroundColor: theme.palette.system.white,
  borderRadius: '12px',
  [theme.breakpoints.down(BREAKPOINT)]: {
    width: '100%',
    padding: '32px 16px',
    borderRadius: '0px',
  },
});

const TopTitle = styled(Box)({
  paddingLeft: '12px',
  borderLeft: '2px solid',
  borderColor: theme.palette.primary.normal,
});

const SubTitle = styled(Box)({
  ...theme.typography.h5,
  paddingBottom: '12px',
  width: '100%',
  borderBottom: '1px solid',
  borderColor: theme.palette.primary.normal,
});

const Content = styled(Stack)({
  flexDirection: 'row',
  gap: '16px',
  [theme.breakpoints.down(BREAKPOINT)]: {
    flexDirection: 'column',
    gap: '22px',
    width: '100%',
  },
});

const BottomWrapper = styled(Stack)({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '16px',
  paddingTop: '40px',
  paddingBottom: '40px',
  backgroundColor: theme.palette.system.white,
  marginBottom: '80px',
  [theme.breakpoints.down(BREAKPOINT)]: {
    padding: '32px 16px',
    marginBottom: '60px',

    width: '100%',
  },
});
const advantages = [
  '奨励金がある',
  '配当金がある',
  '給与天引きで手間がかからない',
  'ドルコスト平均法が使える',
  '少額から株を取得できる',
  '会社の成長により株価アップが期待できる',
];

const disadvantages = [
  '給与と投資先のリスク集中',
  '売却に時間がかかる',
  '株価が上がるとは限らない',
  '株主優待がもらえない（個人名義に変更後は優待を受けられます）',
];

const Title = styled('h1')({
  ...theme.typography.h3,
  'margin-block-start': 0,
  'margin-block-end': 0,
  h1: {
    marginBlockStart: 0,
  },
});

const Description = styled('h2')({
  ...theme.typography.h6,
});

export const SignUpBox: FC = () => {
  const navigate = useNavigate();
  useHelmetHandler({
    title: '持株会の仕組み',
  });
  const isSp = useMediaQuery(() => theme.breakpoints.down(BREAKPOINT));
  return (
    <SignUpWrapper>
      <SignUpContainer>
        <Stack spacing={3}>
          <Stack spacing={2}>
            <TopTitle>
              <Title>NexStocks 新規入会ご案内</Title>
            </TopTitle>
            <Description>
              東海東京証券株式会社の持株会Webサービス『NexStocks』の入会ページです。
            </Description>
          </Stack>
          <Divider
            sx={{ backgroundColor: theme.palette.system['separator-light'] }}
          />
          <Stack>
            <Typography variant="body-main/bold" sx={{ lineHeight: '180%' }}>
              下の「入会手続きに進む」ボタンから、入会手続きにお進みください。
            </Typography>
            <Typography>
              (手続きには事務局から発行された持株会コードとパスワードが必要になります。)
            </Typography>
          </Stack>
          <PrimaryButton
            sx={{ width: isSp ? '100%' : '178px', height: '56px' }}
            onClick={() => navigate('/signup/account')}
          >
            入会手続きに進む
          </PrimaryButton>
        </Stack>
      </SignUpContainer>
      <SignUpContainer>
        <Typography color={theme.palette.system['text-light']}>
          持株会・NexStocksについてご紹介します。ご入会前にぜひご一読ください。
        </Typography>
        <Typography variant="h4">持株会の概要</Typography>
        <Stack gap="12px">
          <SubTitle>給与天引きによる資金拠出</SubTitle>
          <Content>
            <Stack gap="12px">
              <Typography
                variant="body-main/regular"
                sx={{ lineHeight: '180%', width: '100%' }}
              >
                <span style={{ fontWeight: 'bold' }}>毎月の給与天引</span>
                および
                <span style={{ fontWeight: 'bold' }}>年２回の賞与天引き</span>
                により資金を拠出しています。
              </Typography>
              <Typography
                variant="caption/regular"
                color={theme.palette.system['text-light']}
                sx={{ lineHeight: '180%' }}
              >
                ※従業員持株会の場合1,000円（1口）単位で１口から積立が可能です。
                <br />
                ※賞与積立を行っていない持株会もございます。社内の規約をご確認ください。
                <br />
                ※株式取得の詳細につきましては、「③株式の取得（購入等）について」をご参照ください。
                <br />
                ※持株会内で取得・保有する株式（持分）については
                <span style={{ fontWeight: 'bold' }}>100株単位</span>
                で引出すことができます。詳細につきましては、「⑥株式または現金の一部引出しについて」をご参照ください。（上場企業のみ）
              </Typography>
            </Stack>
            <SignupImg1 width="100%" />
          </Content>
        </Stack>
        <Stack gap="12px">
          <SubTitle>奨励金</SubTitle>
          <Content>
            <Stack gap="12px">
              <Typography
                variant="body-main/regular"
                sx={{ lineHeight: '180%' }}
              >
                会社から「奨励金」として皆様の拠出金に対して
                <span style={{ fontWeight: 'bold' }}>
                  拠出の都度「奨励金率(％)」分支給
                </span>
                されています。
              </Typography>
              <Typography
                variant="body-main/regular"
                sx={{ lineHeight: '180%' }}
              >
                <span style={{ fontWeight: 'bold' }}>
                  例:
                  <br />
                  毎月「10口（ 10,000円）奨励金率10% の拠出の場合
                </span>
                <br />
                その拠出額（給与天引きの額）に加え会社から奨励金として「1,000円」が支給されますので、その合計額である「11,000円」が毎月の拠出金・奨励金合計となります。
              </Typography>
            </Stack>
            <SignupImg2 width="100%" />
          </Content>
        </Stack>
        <Stack gap="12px">
          <SubTitle>財産形成</SubTitle>
          <Typography sx={{ lineHeight: '180%' }}>
            持株会は福利厚生制度の一環であるため、奨励金という形で株式の取得資金を給付することによって、従業員である皆様の財産形成に寄与することが奨励金支給の目的となっています。（奨励金は給与所得として課税対象となります。）
          </Typography>
          <Typography
            variant="caption/regular"
            color={theme.palette.system['text-light']}
            sx={{ lineHeight: '180%' }}
          >
            ※役員持株会や取引先持株会には奨励金は出ません。また、従業員持株会であっても奨励金が付与されない場合がございます。
          </Typography>
        </Stack>
        <Stack gap="12px">
          <SubTitle>再投資</SubTitle>
          <Typography>
            配当金は再投資（株式購入資金に充当）されます。
          </Typography>
        </Stack>
        <Stack gap="12px">
          <SubTitle>株価上昇</SubTitle>
          <Typography sx={{ lineHeight: '180%' }}>
            「会社の成長」及び将来的な「株式の上場」（非上場企業）とともに
            <span style={{ fontWeight: 'bold' }}>株価の上昇が期待</span>
            できます。
          </Typography>
        </Stack>
      </SignUpContainer>
      <SignUpContainer>
        <Typography variant="h4">持株会のメリット・デメリット</Typography>
        <Stack gap="12px">
          <SubTitle>メリット（従業員持株会）</SubTitle>
          <Stack gap="12px">
            {advantages.map((a) => (
              <Box key={a} display="flex" gap="12px" alignItems="center">
                <CheckRed />
                <Typography variant="body-main/bold">{a}</Typography>
              </Box>
            ))}
          </Stack>
        </Stack>
        <Stack gap="12px">
          <SubTitle>デメリット（従業員持株会）</SubTitle>
          <Stack gap="12px">
            {disadvantages.map((d) => (
              <Typography variant="body-main/regular">・ {d}</Typography>
            ))}
          </Stack>
        </Stack>
      </SignUpContainer>
      <BottomWrapper>
        <Typography>
          下のボタンからNexStocks入会手続きにお進みください。
        </Typography>
        <PrimaryButton
          sx={{ width: isSp ? '100%' : '178px', height: '56px' }}
          onClick={() => navigate('/signup/account')}
        >
          入会手続きに進む
        </PrimaryButton>
      </BottomWrapper>
    </SignUpWrapper>
  );
};
