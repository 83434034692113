import { BREAKPOINT } from '@/theme/theme';
import {
  Box,
  styled,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { forwardRef, ComponentPropsWithoutRef } from 'react';

const LabelAndForm = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
});

const LabelAnnotation = styled(Typography)(({ theme }) => ({
  color: theme.palette.system['text-light'],
  lineHeight: '24px',
}));

const MuiTextField = styled(TextField)({
  height: 'auto',
  '& .MuiOutlinedInput-root': {
    paddingLeft: '8px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '8px 8px 8px 0px',
    textAlign: 'left',
  },
});

const Footnote = styled(Typography)(({ theme }) => ({
  ...theme.typography['caption/regular'],
  color: theme.palette.system['text-light'],
  paddingTop: '8px',
  textAlign: 'left',
}));

interface TextInputLabel {
  children?: React.ReactNode;
  label: string;
  annotation?: string;
  placeholder?: string;
  flat?: boolean;
}

type TextInputWithLabelProps = TextInputLabel & TextFieldProps;
type ChildProps = ComponentPropsWithoutRef<'input'> & TextInputWithLabelProps;

export const LoginLabelWithTextInput = forwardRef<HTMLInputElement, ChildProps>(
  (
    { label, annotation, placeholder, flat = false, children, ...props },
    ref
  ) => {
    const isPc = useMediaQuery((theme: Theme) =>
      theme.breakpoints.up(BREAKPOINT)
    );

    return (
      <LabelAndForm
        sx={{
          flexDirection: flat && isPc ? 'row' : 'column',
        }}
      >
        <Box
          display="flex"
          sx={{
            minWidth: flat ? '100px' : 'auto',
            paddingBottom: flat && isPc ? 0 : '8px',
            marginTop: flat ? '8px' : 0,
          }}
        >
          <Typography variant="body-main/bold" mr={1}>
            {label}
          </Typography>
          {annotation && (
            <LabelAnnotation variant="caption/regular">
              {annotation}
            </LabelAnnotation>
          )}
        </Box>
        <MuiTextField
          type="text"
          fullWidth
          ref={ref}
          error={props.error}
          helperText={props.error && props.helperText}
          placeholder={placeholder}
          {...props}
        />
        <Footnote>{children}</Footnote>
      </LabelAndForm>
    );
  }
);
