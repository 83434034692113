import { styled, Theme, SxProps } from '@mui/material';
import { FC, useEffect, useState } from 'react';

const Badge = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    borderRadius: '2px',
    alignItems: 'center',
    ...typography['body-sub/medium'],
    borderWidth: '1px',
    borderStyle: 'solid',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  };
});

interface Props {
  status: number;
  sx?: SxProps<Theme>;
}

type Class = {
  word: string;
  color: string;
};

export const ApplicationBadge: FC<Props> = ({ status, sx }) => {
  const [type, setType] = useState<Class>();

  const classifyType = () => {
    switch (status) {
      case 1:
        setType({ word: '申請中', color: '#D20B0B' });
        break;
      case 2:
        setType({ word: '手続中', color: '#049512' });
        break;
      case 3:
        setType({ word: '完了', color: '#0451A0' });
        break;
      case 4:
        setType({ word: '却下', color: '#666666' });
        break;
      case 5:
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    classifyType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  if (!type) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <Badge style={{ color: type.color, borderColor: type.color }} sx={sx}>
      {type.word}
    </Badge>
  );
};
