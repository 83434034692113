import axios from 'axios';
import type { PostCodeAddress } from '@/types/api/postCodeAddress';

import { env } from '@/config/env';

const postCodeApiKey = env.API_KEY.POST_CODE;
const postCodeJPURl = 'https://apis.postcode-jp.com/api/v5';

export class PostcodeJPService {
  /**
   * getAddress
   * @param postCode {string} post code
   */
  public static async getAddress(
    postCode: string
  ): Promise<PostCodeAddress[] | undefined> {
    if (postCode === '') {
      return Promise.resolve(undefined);
    }
    try {
      const url = `${postCodeJPURl}/postcodes/${postCode}?apikey=${postCodeApiKey}`;
      const response = await axios.get(url);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
