import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  SelectChangeEvent,
  styled,
  SvgIcon,
  useMediaQuery,
} from '@mui/material';
import { WarningText } from '@/components/molecules/Simulation/WarningText';
import { SimulationHeader } from '@/components/molecules/Simulation/Header';
import { AccumulationRow } from '@/components/organism/Simulation/Accumulation';
import { MonthSelectionRow } from '@/components/organism/Simulation/MonthSelection';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { SimulationErrorMessageWithIcon } from '@/components/molecules/Message/SimulationErrorMessageWithIcon';
import { HomeHeader } from '@/components/organism/HomeHeader';
import { ReactComponent as RefreshIcon } from '@/assets/refresh.svg';
import { Path } from '@/constants/Router/path';
import { useAuthUser } from '@/hooks/useAuth';
import { useFindAssociationById } from '@/hooks';
import { differenceInMonths } from 'date-fns';
import { appClient } from '@/services';
import { ConvertAssociation } from '@/services/convert/association';
import { Association } from '@/types/api/association';
import useSWR from 'swr';
import { useNavigate } from 'react-router-dom';
import { ConvertAssociationContribution } from '@/services/convert/associationContribution';
import { AssociationContribution } from '@/types/api/associationContribution';
import { theme } from '@/theme/theme';
import { IsNumeric } from '@/utils/stringProcessor';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { SimulateResultProps, SimulationResult } from './SimulationResult';

const links = [
  { path: 'TOP', to: Path.HOME },
  { path: '積立シミュレーション', to: Path.SIMULATION },
];

const Wrapper = styled(Box)(() => {
  const { breakpoints, palette } = theme;

  return {
    padding: 40,
    backgroundColor: palette.system.white,
    borderRadius: 3,
    margin: '32px auto 0',
    maxWidth: 980,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      padding: 16,
      maxWidth: '100%',
      margin: '24px auto 0',
    },
  };
});

const HomeHeaderWrapper = styled(Box)(() => {
  const { breakpoints } = theme;
  return {
    maxWidth: 980,
    margin: '0 auto',
    paddingTop: 32,
    [breakpoints.down('sm')]: {
      maxWidth: '100%',
      paddingTop: 24,
    },
  };
});

const Page = styled(Box)(() => {
  const { palette } = theme;

  return {
    backgroundColor: palette.system.background,
    width: '100%',
  };
});

type SimulationInputStates = {
  monthlyAmount: number;
  bonusAmount: number;
  bonusMonth1: number;
  bonusMonth2: number;
  accumulationFromYear: string;
  accumulationFromMonth: string;
  accumulationToYear: string;
  accumulationToMonth: string;
};

type SimulationInputResultStates = {
  accumulationFrom: string;
  accumulationTo: string;
};

export const Simulation = () => {
  const shouldFourLine = useMediaQuery(theme.breakpoints.down('sm'));
  const [error, setError] = useState(false);
  const [simulationResult, setSimulationResult] =
    useState<SimulateResultProps | null>(null);
  const [simulationInputResultStates, setSimulationInputResultStates] =
    useState<SimulationInputResultStates>({
      accumulationFrom: '',
      accumulationTo: '',
    });
  const [inputStates, setInputStates] = useState<SimulationInputStates>({
    monthlyAmount: 0,
    bonusAmount: 0,
    bonusMonth1: 0,
    bonusMonth2: 0,
    accumulationFromYear: '',
    accumulationFromMonth: '',
    accumulationToYear: '',
    accumulationToMonth: '',
  });
  const [fundingYear, setFundingYear] = useState(0);
  const [fundingMonth, setFundingMonth] = useState(0);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const { user } = useAuthUser();
  const { associationCd = '' } = user;
  const { associationData } = useFindAssociationById(associationCd);
  const [association, setAssociation] = useState<Association>();
  const [associationContribution, setAssociationContribution] =
    useState<AssociationContribution>();
  const navigate = useNavigate();
  useHelmetHandler({
    title: simulationResult
      ? '積立シミュレーション 入力後'
      : '積立シミュレーション 入力前',
  });

  // 【API-A-1001】持株会（事務局）情報
  const { data: findAssociationById } = useSWR(
    '/api/associations/{associationCd}',
    () => appClient.associations.findAssociationById()
  );

  // 【API-A-1002】持株会（事務局）拠出情報取得
  const { data: findAssociationContribution } = useSWR(
    '/api/associations/{associationCd}/contributions',
    () => appClient.associations.findAssociationContribution()
  );

  useEffect(() => {
    setAssociation(ConvertAssociation(findAssociationById));
    setAssociationContribution(
      ConvertAssociationContribution(findAssociationContribution)
    );
  }, [findAssociationById, findAssociationContribution]);

  useEffect(() => {
    if (
      association &&
      association.listedClassification &&
      association.listedClassification !== '1'
    ) {
      navigate(`/`);
    }
  }, [association]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getSimulationResultIcon = () => {
    if (simulationResult) {
      return (
        <SvgIcon
          sx={{
            width: 24,
            height: 24,
          }}
        >
          <RefreshIcon />
        </SvgIcon>
      );
    }
    return undefined;
  };

  const fromToValidate = (status: SimulationInputStates): boolean => {
    if (
      status.accumulationFromYear !== '' &&
      status.accumulationFromMonth !== '' &&
      status.accumulationToYear !== '' &&
      status.accumulationToMonth !== ''
    ) {
      return true;
    }
    return false;
  };

  const validateAll = (status: SimulationInputStates): boolean => {
    if (status.monthlyAmount > 0 && fromToValidate(status)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (fromToValidate(inputStates)) {
      // 差分の月数を計算
      const resultDiff =
        differenceInMonths(
          new Date(
            Number(inputStates.accumulationToYear),
            Number(inputStates.accumulationToMonth),
            1
          ),
          new Date(
            Number(inputStates.accumulationFromYear),
            Number(inputStates.accumulationFromMonth),
            1
          )
        ) + 1;
      // 年を計算
      const year = Math.floor(resultDiff / 12);
      // 月の数値を計算
      const month = Math.floor(resultDiff) % 12;
      setFundingYear(year);
      setFundingMonth(month);
    }
    if (validateAll(inputStates)) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [inputStates]);

  const onChangeMonthlyAmount = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (IsNumeric(event.target.value) && event.target.value.length <= 3) {
      const monthlyAmount = Number(event.target.value) * 1000;
      setInputStates({ ...inputStates, monthlyAmount });
    } else if (event.target.value === '') {
      const monthlyAmount = 0;
      setInputStates({ ...inputStates, monthlyAmount });
    } else {
      const monthlyAmount = 999 * 1000;
      setInputStates({ ...inputStates, monthlyAmount });
    }
  };

  const onChangeBonusAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (IsNumeric(event.target.value) && event.target.value.length <= 3) {
      const bonusAmount = Number(event.target.value) * 1000;
      setInputStates({ ...inputStates, bonusAmount });
    } else if (event.target.value === '') {
      const bonusAmount = 0;
      setInputStates({ ...inputStates, bonusAmount });
    } else {
      const bonusAmount = 999 * 1000;
      setInputStates({ ...inputStates, bonusAmount });
    }
  };

  const onChangeBonusMonth1 = (event: SelectChangeEvent<string>) => {
    const bonusMonth1 = Number(event.target.value);
    setInputStates({ ...inputStates, bonusMonth1 });
  };

  const onChangeBonusMonth2 = (event: SelectChangeEvent<string>) => {
    const bonusMonth2 = Number(event.target.value);
    setInputStates({ ...inputStates, bonusMonth2 });
  };

  const onChangeStartYear = (event: SelectChangeEvent<string>) => {
    const accumulationFromYear = event.target.value;
    setInputStates({ ...inputStates, accumulationFromYear });
  };

  const onChangeStartMonth = (event: SelectChangeEvent<string>) => {
    const accumulationFromMonth = event.target.value;
    setInputStates({ ...inputStates, accumulationFromMonth });
  };

  const onChangeEndYear = (event: SelectChangeEvent<string>) => {
    const accumulationToYear = event.target.value;
    setInputStates({ ...inputStates, accumulationToYear });
  };

  const onChangeEndMonth = (event: SelectChangeEvent<string>) => {
    const accumulationToMonth = event.target.value;
    setInputStates({ ...inputStates, accumulationToMonth });
  };

  const handleClickSimulationResult = async () => {
    const body = {
      monthlyAmount: inputStates.monthlyAmount,
      bonusAmount: inputStates.bonusAmount,
      bonusMonth1: inputStates.bonusMonth1,
      bonusMonth2: inputStates.bonusMonth2,
      accumulationFrom: `${inputStates.accumulationFromYear}-${inputStates.accumulationFromMonth}`,
      accumulationTo: `${inputStates.accumulationToYear}-${inputStates.accumulationToMonth}`,
    };

    try {
      const result = await appClient.simulation.simulate(body);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSimulationResult(result);
      setSimulationInputResultStates({
        accumulationFrom: body.accumulationFrom,
        accumulationTo: body.accumulationTo,
      });
    } catch (e) {
      console.error(e);
      setError(true);
      setSimulationResult(null);
    }
  };

  const getWarningText = useCallback((): string[] => {
    // eslint-disable-next-line react-hooks/rules-of-hooks

    if (shouldFourLine) {
      return [
        'もし、持株会に入って',
        '毎月積み立てていたとしたら、',
        'これまでの積立金額と評価額は',
        '一体どれくらいになっているでしょう？',
      ];
    }
    return [
      'もし、持株会に入って毎月積み立てていたとしたら、',
      'これまでの積立合計金額と評価額は一体どれくらいになっているでしょう？',
    ];
  }, [shouldFourLine]);

  getWarningText();
  // Main render
  if (
    association &&
    association.listedClassification === '1' &&
    associationContribution &&
    associationData
  ) {
    return (
      <Page>
        <HomeHeaderWrapper>
          <HomeHeader
            links={links}
            title="積立シミュレーション"
            description={[
              '積立額に悩んだら、シミュレーターを使って過去のデータを見てみましょう。あなたの未来の積立額と評価額をイメージする手助けになるはずです。',
            ]}
          />
        </HomeHeaderWrapper>
        <Wrapper>
          <WarningText text={getWarningText()} />
          <SimulationHeader
            stockCode={associationData.stockCode}
            market={association.marketType}
            bountyRate={associationContribution.monthlyIncentiveRatio}
          />
          <AccumulationRow
            error={error}
            monthly={inputStates.monthlyAmount}
            bonus={inputStates.bonusAmount}
            onChangeMonthlyAmount={onChangeMonthlyAmount}
            onChangeBonusAmount={onChangeBonusAmount}
          />
          <MonthSelectionRow
            error={error}
            title="ボーナス月"
            type="BonusMonth"
            onChangeBonusMonth1={onChangeBonusMonth1}
            onChangeBonusMonth2={onChangeBonusMonth2}
          />
          <MonthSelectionRow
            error={error}
            title="積立期間"
            subtitle={`${fundingYear}年${fundingMonth}ヶ月`}
            type="AccumulationPeriod"
            onChangeStartYear={onChangeStartYear}
            onChangeStartMonth={onChangeStartMonth}
            onChangeEndYear={onChangeEndYear}
            onChangeEndMonth={onChangeEndMonth}
          />
          {error && (
            <SimulationErrorMessageWithIcon message="株価データが不足しているため、現在シミュレーションをご利用いただけません。" />
          )}
          <PrimaryButton
            disabled={
              fundingMonth < 0 ||
              fundingYear < 0 ||
              (submitDisabled && !association.allowSimulationFlg)
            }
            startIcon={getSimulationResultIcon()}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={() => handleClickSimulationResult()}
            sx={{
              marginTop: 3,
              width: { xs: '100%', sm: 'auto' },
              paddingY: '14.5px',
              borderRadius: 7,
            }}
          >
            {simulationResult
              ? '再シミュレーションする'
              : 'シミュレーションする'}
          </PrimaryButton>
          {simulationResult && (
            <SimulationResult
              simulation={simulationResult}
              accumulationFrom={simulationInputResultStates.accumulationFrom}
              accumulationTo={simulationInputResultStates.accumulationTo}
            />
          )}
        </Wrapper>
      </Page>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
