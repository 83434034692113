import { Box, Typography, SvgIcon, styled } from '@mui/material';
import { ReactComponent as CautionIcon } from '@/assets/cautionGrey.svg';
import {
  getLoginErrorMessage,
  LoginErrorMessageType,
} from '@/constants/Login/ErrorMessages';
import { useLocation } from 'react-router-dom';

const Wrapper = styled(Box)({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
  padding: 16,
});

const Title = styled(Typography)(({ theme }) => {
  const { typography, palette } = theme;

  return {
    ...typography.h3,
    color: palette.system['text-normal'],
    marginTop: 24,
  };
});

const Description = styled(Typography)(({ theme }) => {
  const { typography, palette } = theme;

  return {
    ...typography['body-main/regular'],
    color: palette.system['text-normal'],
    marginTop: 24,
    textAlign: 'center',
  };
});

type MessageTypeState = {
  type: LoginErrorMessageType;
};

export const LoginError = () => {
  const location = useLocation();
  const state = location.state as MessageTypeState;
  const type = state?.type ? state?.type : 'system_error';

  return (
    <Wrapper>
      <SvgIcon sx={{ width: 60, height: 60 }}>
        <CautionIcon />
      </SvgIcon>
      <Title>{getLoginErrorMessage(type).title}</Title>
      <Description>{getLoginErrorMessage(type).description}</Description>
      {getLoginErrorMessage(type).description2 && (
        <Description sx={{ marginTop: 0 }}>
          {getLoginErrorMessage(type).description2}
        </Description>
      )}
    </Wrapper>
  );
};
