import { BREAKPOINT } from '@/theme/theme';
import { Association } from '@/types/api/association';
import { AssociationContact } from '@/types/api/associationContact';
import { styled } from '@mui/material';
import { FC } from 'react';

const BORDERCOLOR = '1px solid rgba(0, 0, 0, 0.13)';

const ContactInfoWrap = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const ContactInfoTitle = styled('div')(({ theme }) => {
  const { typography, breakpoints } = theme;
  return {
    ...typography.h4,
    [breakpoints.down(BREAKPOINT)]: {
      margin: '0 16px',
    },
  };
});

const ContactInfoBody = styled('div')(({ theme }) => {
  const { breakpoints, palette } = theme;
  return {
    width: '100%',
    borderRadius: '12px',
    backgroundColor: palette.system.white,
    padding: '24px 32px',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    [breakpoints.down(BREAKPOINT)]: {
      borderRadius: '0px',
      padding: '24px 16px',
      gap: '40px',
    },
  };
});

const ContactInfoTop = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const TopTitle = styled('div')(({ theme }) => {
  const { breakpoints, typography } = theme;
  return {
    ...typography.h5,
    paddingBottom: '12px',
    borderBottom: 'solid 1px #ED5045;',
    width: '100%',
    [breakpoints.down(BREAKPOINT)]: {
      paddingBottom: '13px',
    },
  };
});

const RepresentativeWrap = styled('div')({
  width: '100%',
  borderBottom: BORDERCOLOR,
});

const Representative = styled('div')({
  display: 'flex',
  gap: '8px',
  marginBottom: '17px',
});

const Label = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-main/bold'],
    width: '144px',
  };
});

const LightLabel = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-sub/regular'],
    width: '144px',
  };
});

const Content = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-main/regular'],
  };
});

const InfoWrap = styled('div')({
  width: '100%',
});

const Contents = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    gap: '8px',
    width: '50%',
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
      paddingBottom: '17px',
      borderBottom: BORDERCOLOR,
    },
  };
});

const LastContents = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    gap: '8px',
    width: '50%',
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
      paddingBottom: '17px',
    },
  };
});

const Items = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    gap: '20px',
    [breakpoints.down(BREAKPOINT)]: {
      flexDirection: 'column',
      gap: '18px',
    },
  };
});

const BottomTitle = styled('div')(({ theme }) => {
  const { breakpoints, typography } = theme;
  return {
    ...typography.h5,
    paddingBottom: '12px',
    borderBottom: BORDERCOLOR,
    width: '100%',
    [breakpoints.down(BREAKPOINT)]: {
      paddingBottom: '13px',
    },
  };
});

type Props = {
  association: Association;
  associationContact?: AssociationContact;
};

export const Contacts: FC<Props> = ({ association, associationContact }) => (
  <ContactInfoWrap>
    <ContactInfoTitle>FAQで解決しない場合のお問い合わせ先</ContactInfoTitle>
    <ContactInfoBody>
      <ContactInfoTop>
        <TopTitle>持株会事務局連絡先</TopTitle>
        <RepresentativeWrap>
          <Representative>
            <Label>担当者</Label>
            <Content>{associationContact?.inquiryRepresentative}</Content>
          </Representative>
        </RepresentativeWrap>
        <InfoWrap>
          <Items>
            <Contents>
              <Label>メールアドレス</Label>
              <Content>{associationContact?.inquiryTelEmail}</Content>
            </Contents>
            <Contents>
              <Label>電話番号</Label>
              <Content sx={{ flex: 1 }}>
                {associationContact?.inquiryTelOutsideLine && (
                  <Content>
                    外線 {associationContact?.inquiryTelOutsideLine}
                  </Content>
                )}
                {associationContact?.inquiryTelExtension && (
                  <Content>
                    内線 {associationContact?.inquiryTelExtension}
                  </Content>
                )}
              </Content>
            </Contents>
          </Items>
        </InfoWrap>
      </ContactInfoTop>
      <ContactInfoTop>
        <BottomTitle>持株会事務局情報</BottomTitle>
        <InfoWrap>
          <Items>
            <Contents>
              <LightLabel>企業名</LightLabel>
              <Content>{association.associationName}</Content>
            </Contents>
            <LastContents>
              <LightLabel>持株会名</LightLabel>
              <Content>{association.associationName}</Content>
            </LastContents>
          </Items>
        </InfoWrap>
      </ContactInfoTop>
    </ContactInfoBody>
  </ContactInfoWrap>
);
