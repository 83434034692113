import { styled, useMediaQuery, Theme, Link, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { BREAKPOINT } from '@/theme/theme';
import { PrintButton } from '@/components/atoms/Buttons/PrintButton';
import { ReactComponent as AlertOops } from '@/assets/alertOops.svg';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { formatDateTime } from '@/utils/dateFunctions';
import { appClient } from '@/services';
import { ConvertEventsmemberdeadlines } from '@/services/convert/eventsmemberdeadlines';
import { Eventsmemberdeadlines } from '@/types/api/eventsmemberdeadlines';
import useSWR from 'swr';
import { findDeadLineService } from '@/services/findDeadLineService';
import { BasicModal } from '@/components/molecules/Modal/BasicModal.tsx';
import { DeadLine } from '@/components/molecules/Modal/BasicModal.tsx/DeadLine';
import { ApplicationBtn } from '../ApplicationIndex/ApplicationBtn';
import { ApplicationBadge } from '../ApplicationIndex/ApplicationBadge';

const Wrapper = styled('div')(({ theme }) => {
  const { palette, breakpoints } = theme;
  return {
    width: '100%',
    padding: '24px',
    backgroundColor: palette.system.white,
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    [breakpoints.down(BREAKPOINT)]: {
      borderRadius: '0px',
      padding: '24px 16px',
      gap: '24px',
    },
  };
});
const TopWrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    [breakpoints.down(BREAKPOINT)]: {
      gap: '16px',
    },
  };
});

const Titles = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography.h4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  };
});
const Border = styled('div')({
  width: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.13)',
  paddingTop: '1px',
});

const Info = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const Ids = styled('div')(({ theme }) => {
  const { typography } = theme;

  return {
    ...typography['body-sub/regular'],
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  };
});

const Types = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    gap: '16px',
    [breakpoints.down(BREAKPOINT)]: {
      gap: '8px',
      flexDirection: 'column',
    },
  };
});

const Wrap = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-main/medium'],
  };
});

const BottomWrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    [breakpoints.down(BREAKPOINT)]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  };
});

const SvgBox = styled('div')({
  width: '20px',
  height: '20px',
});

const Alert = styled('div')(({ theme }) => {
  const { palette, typography, breakpoints } = theme;
  return {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    ...typography['body-main/bold'],
    color: palette.states.error,
    [breakpoints.down(BREAKPOINT)]: {
      alignItems: 'start',
    },
  };
});

const MuiLink = styled(Link)(({ theme }) => {
  const { typography, palette, breakpoints } = theme;
  return {
    ...typography['body-sub/medium'],
    color: palette.secondary.normal,
    textDecorationColor: palette.secondary.normal,
    cursor: 'pointer',
    [breakpoints.down(BREAKPOINT)]: {
      marginLeft: '28px',
    },
  };
});

const ProxyApplicantUser = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-main/regular'],
    placeSelf: 'flex-start',
    padding: '8px',
    background: '#EFFBFC',
    color: '#0B5D61',
  };
});

const Reject = styled('div')(({ theme }) => {
  const { palette } = theme;
  return {
    padding: '24px',
    background: palette.system['background-light'],
  };
});

interface Props {
  id: number;
  applicationType:
    | 'MONTHLY_UNIT'
    | 'BONUS_UNIT'
    | 'PART'
    | 'RECESS'
    | 'RESUME'
    | 'WITHDRAWAL'
    | 'ADMISSION'
    | 'USER';
  applicationDate: string;
  applicationStatus:
    | 'PREPARATION'
    | 'APPLYING'
    | 'OFFICER_APPROVED'
    | 'BACKOFFICER_APPROVED'
    | 'COMPLETED'
    | 'OFFICER_REJECTED'
    | 'BACKOFFICER_REJECTED'
    | 'CANCEL';
  proxyApplicantUserName?: string;
  officeUpdateUserName?: string;
  isApplying: boolean;
  rejectComment?: string;
  officeJudgeDt?: string;
  modalOpen: () => void;
  printOnClick: () => void;
}

const type = {
  MONTHLY_UNIT: '口数変更',
  BONUS_UNIT: '口数変更',
  PART: '一部引出',
  RECESS: '休会',
  RESUME: '再開',
  WITHDRAWAL: '退会',
  ADMISSION: '新規会員登録',
  USER: '会員情報変更',
};

const status = {
  PREPARATION: 1,
  APPLYING: 1,
  OFFICER_APPROVED: 2,
  BACKOFFICER_APPROVED: 2,
  COMPLETED: 3,
  OFFICER_REJECTED: 4,
  BACKOFFICER_REJECTED: 4,
  CANCEL: 5,
};

export const ApplicationMiddle: FC<Props> = ({
  id,
  applicationType,
  applicationDate,
  applicationStatus,
  proxyApplicantUserName,
  officeUpdateUserName,
  isApplying,
  rejectComment,
  officeJudgeDt,
  modalOpen,
  printOnClick,
}) => {
  const [eventsmemberdeadlines, setEventsmemberdeadlines] =
    useState<Eventsmemberdeadlines>();
  const isSp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(BREAKPOINT)
  );
  const [isOpen, setIsOpen] = useState(false);

  // 【API-A-1001】持株会（事務局）情報
  const { data: findAssociationEventMemberDeadlines } = useSWR(
    '/api/associations/{associationCd}/eventsmemberdeadlines',
    () => appClient.associations.findAssociationEventMemberDeadlines()
  );
  useEffect(() => {
    setEventsmemberdeadlines(
      ConvertEventsmemberdeadlines(
        findAssociationEventMemberDeadlines?.eventmemberdeadlines
      )
    );
  }, [findAssociationEventMemberDeadlines]);

  return (
    <>
      <Wrapper>
        <TopWrapper>
          <Titles>
            申請詳細
            {!isSp && <PrintButton onClick={() => printOnClick()} />}
          </Titles>
          {isSp && (
            <PrintButton
              sx={{ width: '100%' }}
              onClick={() => printOnClick()}
            />
          )}
          <Border />
          <Info>
            <Ids>
              <ApplicationBadge
                status={status[applicationStatus]}
                sx={{ width: '48px' }}
              />
              申請ID：{id}
            </Ids>
            <Types>
              <Wrap>{type[applicationType]}</Wrap>
              <Wrap>{formatDateTime(applicationDate)} 申請</Wrap>
            </Types>
          </Info>
        </TopWrapper>
        {proxyApplicantUserName && (
          <ProxyApplicantUser>
            ※代理申請された申請です。代理申請者：{proxyApplicantUserName}
          </ProxyApplicantUser>
        )}
        {officeUpdateUserName && (
          <ProxyApplicantUser>
            ※事務局で修正された申請です。修正者：{officeUpdateUserName}
          </ProxyApplicantUser>
        )}
        {isApplying && eventsmemberdeadlines && (
          <BottomWrapper>
            <Alert>
              <SvgBox>
                <AlertOops />
              </SvgBox>
              申請内容は
              {findDeadLineService({
                deadlines: eventsmemberdeadlines,
                target: applicationType,
                getMonth: true,
              })}
              受付締日以降に反映されます。
            </Alert>
            <MuiLink onClick={() => setIsOpen(true)}>受付締日一覧</MuiLink>
          </BottomWrapper>
        )}
        {rejectComment && (
          <>
            <Reject>
              <Typography
                component="p"
                variant="body-main/bold"
                sx={{ marginBottom: '8px' }}
              >
                {officeJudgeDt
                  ? '制度グループよりコメント'
                  : '事務局よりコメント'}
              </Typography>
              <Typography component="p" variant="body-main/regular">
                {rejectComment}
              </Typography>
            </Reject>
            <BottomWrapper>
              <Alert>
                <SvgBox>
                  <AlertOops />
                </SvgBox>
                再申請する場合は、新たに申請を作成してください。
              </Alert>
            </BottomWrapper>
            <ApplicationBtn applicationType={applicationType} />
          </>
        )}
        {isSp && isApplying && (
          <SecondaryButton
            sx={{ height: '56px', borderRadius: '28px' }}
            onClick={modalOpen}
          >
            この申請を取り消す
          </SecondaryButton>
        )}
      </Wrapper>
      {eventsmemberdeadlines && (
        <BasicModal open={isOpen}>
          <DeadLine
            deadLine={findDeadLineService({
              deadlines: eventsmemberdeadlines,
              target: 'MONTHLY_UNIT',
            })}
            eventsmemberdeadlines={eventsmemberdeadlines}
            onClick={() => setIsOpen(false)}
          />
        </BasicModal>
      )}
    </>
  );
};
