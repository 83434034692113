export const getLoginErrorMessage = (
  type: LoginErrorMessageType
): LoginErrorMessage => {
  switch (type) {
    case 'system_error':
      return {
        title: 'システムエラーが発生しました。',
        description:
          '恐れ入りますが、しばらく経ってから再度アクセスしてください。',
      };
    case 'expired_url':
      return {
        title: '有効期限切れのURLです。',
        description: '事務局にお問い合わせください。',
      };
    case 'invalid_url':
      return {
        title: '無効なURLです。',
        description: 'URLを再度ご確認ください。',
      };
    case 'maintenance':
      return {
        title: 'メンテナンス中です。',
        description: `メンテナンスが終了するまでお待ちください。`,
        description2:
          '予定時間：0000年00月00日 (月) 00:00 〜 0000年00月00日 (月) 00:00',
      };
    default:
      return {
        title: 'Login Error',
        description: 'Unknown error has occurred',
      };
  }
};

export type LoginErrorMessageType =
  | 'system_error'
  | 'expired_url'
  | 'invalid_url'
  | 'maintenance';
type LoginErrorMessage = {
  title: string;
  description: string;
  description2?: string;
};
