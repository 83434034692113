/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import {
  Box,
  styled,
  Stack,
  Typography,
  useMediaQuery,
  Link,
} from '@mui/material';
import { theme, BREAKPOINT } from '@/theme/theme';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { HomeHeader } from '@/components/organism/HomeHeader';
import { ReactComponent as Guide1 } from '@/assets/guide1.svg';
import { ReactComponent as Guide1Gray } from '@/assets/guide1Gray.svg';
import { ReactComponent as Guide2 } from '@/assets/guide2.svg';
import { ReactComponent as Guide2Gray } from '@/assets/guide2Gray.svg';
import { ReactComponent as Guide3 } from '@/assets/guide3.svg';
import { ReactComponent as Guide3Gray } from '@/assets/guide3Gray.svg';
import { ReactComponent as Guide4 } from '@/assets/guide4.svg';
import { ReactComponent as Guide4Gray } from '@/assets/guide4Gray.svg';
import { ReactComponent as Guide5 } from '@/assets/guide5.svg';
import { ReactComponent as Guide5Gray } from '@/assets/guide5Gray.svg';
import { ReactComponent as Guide6 } from '@/assets/guide6.svg';
import { ReactComponent as Guide6Gray } from '@/assets/guide6Gray.svg';
import { ReactComponent as Guide7 } from '@/assets/guide7.svg';
import { ReactComponent as Guide7Gray } from '@/assets/guide7Gray.svg';
import { ReactComponent as Guide8 } from '@/assets/guide8.svg';
import { ReactComponent as Guide8Gray } from '@/assets/guide8Gray.svg';
import { ReactComponent as Guide9 } from '@/assets/guide9.svg';
import { ReactComponent as Guide9Gray } from '@/assets/guide9Gray.svg';
import { ReactComponent as Guide1Img } from '@/assets/guide1Img.svg';
import { ReactComponent as Guide1Img2 } from '@/assets/guide1Img2.svg';
import { ReactComponent as Guide6Img } from '@/assets/guide6Img.svg';
import { ReactComponent as Guide6Img2 } from '@/assets/guide6Img2.svg';
import { ReactComponent as Guide7Img } from '@/assets/guide7Img.svg';
import { ReactComponent as Guide7Img2 } from '@/assets/guide7Img2.svg';
import { Path } from '@/constants/Router/path';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const TopWrapper = styled(Box)({
  width: '100%',
  padding: '33px 40px 42px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  backgroundColor: theme.palette.system.background,
  [theme.breakpoints.down(BREAKPOINT)]: {
    padding: '24px 16px',
    gap: '16px',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '33px 130px 42px',
  },
});

const BottomWrapper = styled(Box)({
  display: 'flex',
  gap: '20px',
  padding: '0 40px 120px',
  backgroundColor: theme.palette.system.background,
  width: '100%',
  [theme.breakpoints.down(BREAKPOINT)]: {
    padding: '0 0 60px',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '0 130px 120px',
  },
});

const ColumWrapper = styled(Stack)({
  backgroundColor: theme.palette.system.white,
  padding: '32px',
  gap: '32px',
  width: '100%',
  borderRadius: '12px',
  [theme.breakpoints.down(BREAKPOINT)]: {
    padding: '32px 16px',
    borderRadius: '0px',
  },
});

const Title = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const SubTitle = styled(Box)({
  fontSize: '20px',
  fontWeight: 500,
  paddingBottom: '13px',
  borderBottom: '1px solid',
  borderColor: theme.palette.primary.normal,
  width: '100%',
});

const ContentWithImages = styled(Box)({
  display: 'flex',
  gap: '16px',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  [theme.breakpoints.down(BREAKPOINT)]: {
    flexDirection: 'column',
    gap: '24px',
  },
});

type IndexProps = {
  index: number;
  onIntersection?: (index: number) => void;
  children: ReactNode;
  progress: number;
};

const IndexContainer: React.FC<IndexProps> = ({
  index,
  onIntersection,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const target = document.querySelector(`#link${index}`);
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          if (onIntersection !== undefined) {
            onIntersection(index);
          }
        }
      },
      { rootMargin: '0px 0px -78% 0px' }
    );

    if (ref.current === null) return;
    observer.observe(target || ref.current);
    const { current } = ref;

    // eslint-disable-next-line consistent-return
    return () => {
      observer.unobserve(current);
    };
  }, []);

  return <ColumWrapper ref={ref}>{children}</ColumWrapper>;
};

const MuiBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const IndexBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '8px 16px',
  backgroundColor: theme.palette.system['background-light'],
});

export const Guide: FC = () => {
  useHelmetHandler({
    title: 'ご利用ガイド',
  });
  const isSp = useMediaQuery(() => theme.breakpoints.down(BREAKPOINT));
  const ref = useRef<HTMLDivElement>(null);
  const [progress, setProgress] = useState(0);
  const intersectCallback = (index: number) => {
    setProgress(index);
  };
  const offset = '150';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <TopWrapper>
        <Box width="100%" display="flex">
          <HomeHeader
            title="ご利用ガイド"
            description={['NexStocksの使い方をご説明します！']}
            links={[
              { path: 'TOP', to: '/' },
              { path: 'ご利用ガイド', to: Path.GUIDE },
            ]}
          />
        </Box>
      </TopWrapper>
      <BottomWrapper>
        <Stack spacing={4} width="100%">
          <IndexContainer
            index={1}
            onIntersection={intersectCallback}
            progress={progress}
          >
            <Title ref={ref} id="link1">
              <Guide1 />
              <Typography variant="h4">持株会の概要</Typography>
            </Title>
            <Stack gap="12px">
              <SubTitle>給与天引きによる資金拠出</SubTitle>
              <ContentWithImages>
                <Stack gap="12px">
                  <Typography variant="body-main/regular">
                    <span style={{ fontWeight: 'bold' }}>毎月の給与天引</span>
                    および{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      年２回の賞与天引き
                    </span>
                    により資金を拠出しています。
                  </Typography>
                  <Typography
                    color={theme.palette.system['text-light']}
                    variant="caption/regular"
                  >
                    ※従業員持株会の場合1,000円（1口）単位で１口から積立が可能です。
                    <br />
                    ※賞与積立を行っていない持株会もございます。社内の規約をご確認ください。
                    <br />
                    ※株式取得の詳細につきましては、「③株式の取得（購入等）について」をご参照ください。
                    <br />
                    ※持株会内で取得・保有する株式（持分）については{' '}
                    <span style={{ fontWeight: 'bold' }}>100株単位</span>
                    で引出すことができます。詳細につきましては、「⑥株式または現金の一部引出しについて」をご参照ください。（上場企業のみ）
                  </Typography>
                </Stack>
                <Box>
                  <Guide1Img width={380} height={240} />
                </Box>
              </ContentWithImages>
            </Stack>
            <Stack gap="12px">
              <SubTitle>奨励金</SubTitle>
              <ContentWithImages>
                <Stack gap="12px">
                  <Typography variant="body-main/regular">
                    会社から「奨励金」として皆様の拠出金に対して
                    <span style={{ fontWeight: 'bold' }}>
                      拠出の都度「奨励金率(％)」分支給
                    </span>
                    されています。
                  </Typography>
                  <Typography variant="body-main/regular">
                    <span style={{ fontWeight: 'bold' }}>例:</span>
                    <br />
                    <span style={{ fontWeight: 'bold' }}>
                      毎月「10口（ 10,000円）奨励金率10% の拠出の場合
                    </span>
                    <br />
                    その拠出額（給与天引きの額）に加え会社から奨励金として「1,000円」が支給されますので、その合計額である「11,000円」が毎月の拠出金・奨励金合計となります。
                  </Typography>
                </Stack>
                <Box>
                  <Guide1Img2 width={380} height={240} />
                </Box>
              </ContentWithImages>
            </Stack>
            <Stack gap="12px">
              <SubTitle>財産形成</SubTitle>
              <Stack gap="12px">
                <Typography variant="body-main/regular">
                  持株会は福利厚生制度の一環であるため、奨励金という形で株式の取得資金を給付することによって、従業員である皆様の財産形成に寄与することが奨励金支給の目的となっています。（奨励金は給与所得として課税対象となります。）
                </Typography>
                <Typography
                  color={theme.palette.system['text-light']}
                  variant="caption/regular"
                >
                  ※役員持株会や取引先持株会には奨励金は出ません。また、従業員持株会であっても奨励金が付与されない場合がございます。
                </Typography>
              </Stack>
            </Stack>
            <Stack gap="12px">
              <SubTitle>再投資</SubTitle>
              <Typography variant="body-main/regular">
                配当金は再投資（株式購入資金に充当）されます。
              </Typography>
            </Stack>
            <Stack gap="12px">
              <SubTitle>株価上昇</SubTitle>
              <Typography variant="body-main/regular">
                「会社の成長」及び将来的な「株式の上場」（非上場企業）とともに
                <span style={{ fontWeight: 'bold' }}>株価の上昇が期待</span>
                できます。
              </Typography>
            </Stack>
          </IndexContainer>
          <IndexContainer
            index={2}
            onIntersection={intersectCallback}
            progress={progress}
          >
            <Title ref={ref} id="link2">
              <Guide2 />
              <Typography variant="h4">入会対象者について</Typography>
            </Title>
            <Stack gap="12px">
              <SubTitle>従業員持株会</SubTitle>
              <Stack gap="12px">
                <Typography variant="body-main/regular">
                  会社およびその子会社の「従業員」
                </Typography>
                <Typography variant="caption/regular">
                  従業員：あなたが所属している会社の就業規則に定める者。
                </Typography>
                <Typography
                  variant="caption/regular"
                  color={theme.palette.system['text-light']}
                >
                  ※詳しくは持株会の規約をご確認ください。
                </Typography>
              </Stack>
            </Stack>
            <Stack gap="12px">
              <SubTitle>役員持株会</SubTitle>
              <Stack gap="12px">
                <Typography variant="body-main/regular">
                  会社およびその子会社の「役員」
                </Typography>
                <Typography variant="caption/regular">
                  役員：持株会の規約をご確認ください。
                </Typography>
              </Stack>
            </Stack>
            <Stack gap="12px">
              <SubTitle>取引先持株会</SubTitle>
              <Stack gap="12px">
                <Typography variant="body-main/regular">
                  「会社と取引がある法人および個人」
                </Typography>
                <Typography variant="caption/regular">
                  ※入会資格のない方からの申請は却下させていただきます。
                </Typography>
              </Stack>
            </Stack>
          </IndexContainer>
          <IndexContainer
            index={3}
            onIntersection={intersectCallback}
            progress={progress}
          >
            <Title ref={ref} id="link3">
              <Guide3 />
              <Typography variant="h4">株式の取得（購入等）について</Typography>
            </Title>
            <Stack gap="12px">
              <SubTitle>上場企業</SubTitle>
              <Stack gap="12px">
                <Typography variant="body-main/regular" display="flex">
                  <li />
                  <Box>
                    毎月定められた買付日に金融商品取引所（証券取引所）から時価にて株式を取得（購入）致します。
                  </Box>
                </Typography>
                <Typography variant="body-main/regular" display="flex">
                  <li />
                  <Box>
                    各会員の取得株式数は、株式取得時の各会員の資金残高に応じて決定されます。
                  </Box>
                </Typography>
              </Stack>
            </Stack>
            <Stack gap="12px">
              <SubTitle>非上場企業</SubTitle>
              <Stack gap="12px">
                <Typography variant="body-main/regular" display="flex">
                  <li />
                  <Box>
                    株式の取得（購入等）の取扱いは以下となります。
                    <br />
                    持株会が株式を取得するのは供給がある場合（持株会外部からの株式譲受（購入）、第三者割当増資の引受等）のみとなります。
                  </Box>
                </Typography>
                <Typography variant="body-main/regular" display="flex">
                  <li />
                  <Box>
                    各会員の取得株式数は、株式取得時の各会員の資金残高に応じて決定されます。
                  </Box>
                </Typography>
                <Typography variant="body-main/regular" display="flex">
                  <li />
                  <Box>
                    株式を取得するまでは、現金等（銀行預金等）で管理されます。
                  </Box>
                </Typography>
              </Stack>
            </Stack>
            <Typography
              variant="caption/regular"
              color={theme.palette.system['text-light']}
            >
              （ご参考）“ドル・コスト平均法”
              <br />
              ドル・コスト平均法とは、「定期的・継続的」に「一定額」の株式を買付ける方法です。したがって、株価が低いときは多く、高いときは少ない株数を購入します。全体で見れば平均購入単価を低く抑えられる可能性が高く、一般的に、長期投資に向いている方法といわれています。
            </Typography>
          </IndexContainer>
          <IndexContainer
            index={4}
            onIntersection={intersectCallback}
            progress={progress}
          >
            <Title ref={ref} id="link4">
              <Guide4 />
              <Typography variant="h4">口数変更について</Typography>
            </Title>
            <Typography variant="body-main/regular">
              会員の方は口数の増加・減少を行うことができます。毎月の締日までに、持株会事務局に申請を行うことにより原則翌月分より新口数の拠出となります。
            </Typography>
          </IndexContainer>
          <IndexContainer
            index={5}
            onIntersection={intersectCallback}
            progress={progress}
          >
            <Title ref={ref} id="link5">
              <Guide5 />
              <Typography variant="h4">拠出の休止・再開について</Typography>
            </Title>
            <Stack gap="12px">
              <Typography variant="body-main/regular" display="flex">
                <li />
                <Box>
                  事故、病気等やむを得ない事由が生じた場合は、毎月の締日までに持株会事務局に申請を行うことにより原則翌月分より拠出を休止することができます。
                </Box>
              </Typography>
              <Typography variant="body-main/regular" display="flex">
                <li />
                <Box>
                  休止事由が消滅した場合、毎月の締日までに、持株会事務局に申請を行うことにより原則翌月分より拠出を再開することができます。
                </Box>
              </Typography>
            </Stack>
          </IndexContainer>
          <IndexContainer
            index={6}
            onIntersection={intersectCallback}
            progress={progress}
          >
            <Title ref={ref} id="link6">
              <Guide6 />
              <Typography variant="h4">
                株式または現金の一部引出について
              </Typography>
            </Title>
            <Stack gap="12px">
              <SubTitle>上場企業</SubTitle>
              <ContentWithImages>
                <Stack gap="12px">
                  <Typography variant="body-main/regular" display="flex">
                    <li />
                    <Box>
                      持株会内で取得し保有する株式（持分）は、単元株である100株単位で引出すことができます。（株式の引出しをご希望される際は、事務局までご連絡ください。）
                    </Box>
                  </Typography>
                  <Typography
                    variant="caption/regular"
                    color={theme.palette.system['text-light']}
                    display="flex"
                  >
                    <Box>※</Box>
                    <Box>
                      <span style={{ fontWeight: 'bold' }}>
                        引出しに先立って事務委託証券会社である東海東京証券に会員個人名義での
                        <Link
                          color={theme.palette.system.link}
                          sx={{
                            textDecorationColor: theme.palette.system.link,
                          }}
                          href={Path.ACCOUNTING_OPEN}
                          target="_blank"
                        >
                          証券総合口座の開設
                        </Link>
                        が必要となりますのでご留意ください。
                      </span>
                      <br />
                      （東海東京証券以外の証券会社に既に証券口座をお持ちの場合でも、東海東京証券での口座開設が必要となります。）
                    </Box>
                  </Typography>
                  <Typography variant="body-main/regular" display="flex">
                    <li />
                    <Box>
                      持株会からの現金（持分）の引出しはできません。いわゆる「社内預金制度」とは異なりますのでご留意をお願いいたします。
                      <br />
                      現金化をお考えの会員様は、株式の引出後、ご自身で株式を市場売却することにより、現金化することが可能です。
                      毎月の給与天引および年２回の賞与天引きにより資金を拠出しています。
                    </Box>
                  </Typography>
                </Stack>
                <Box>
                  <Guide6Img />
                </Box>
              </ContentWithImages>
            </Stack>
            <Stack gap="12px">
              <SubTitle>非上場企業</SubTitle>
              <ContentWithImages>
                <Stack gap="12px">
                  <Typography variant="body-main/regular" display="flex">
                    <li />
                    <Box>
                      持株会内で取得し保有する株式（持分）は、非上場の期間、原則として引出すことはできません。
                    </Box>
                  </Typography>
                  <Typography variant="body-main/regular" display="flex">
                    <li />
                    <Box>
                      持株会内での現金（繰越金）の引出しもできません。いわゆる「社内預金制度」とは異なりますのでご留意をお願いいたします。
                    </Box>
                  </Typography>
                </Stack>
                <Box>
                  <Guide6Img2 />
                </Box>
              </ContentWithImages>
            </Stack>
          </IndexContainer>
          <IndexContainer
            index={7}
            onIntersection={intersectCallback}
            progress={progress}
          >
            <Title ref={ref} id="link7">
              <Guide7 />
              <Typography variant="h4">退会について</Typography>
            </Title>
            <Stack gap="12px">
              <SubTitle>上場企業</SubTitle>
              <ContentWithImages>
                <Stack gap="12px">
                  <Typography variant="body-main/regular" display="flex">
                    <li />
                    <Box>
                      退会は随時できますが、原則として一旦退会すると再入会できません。
                    </Box>
                  </Typography>
                  <Typography variant="body-main/regular" display="flex">
                    <li />
                    <Box>
                      退会精算の取扱いは以下となります。
                      <br />
                      100株単位の株式は本人名義の証券総合口座へ株式を振替える方法（一部引出しと同様の方法）により精算し、
                      100株未満の部分は退会処理を行う月の買付日の株価により現金精算をいたします。
                    </Box>
                  </Typography>
                </Stack>
                <Box>
                  <Guide7Img />
                </Box>
              </ContentWithImages>
              <Box
                width="100%"
                sx={{
                  display: 'flex',
                  gap: '10px',
                  backgroundColor: theme.palette.system.background,
                  borderRadius: '4px',
                  padding: '16px',
                }}
              >
                <Typography>(例)</Typography>
                <Stack gap="10px">
                  <Typography>
                    退会時持分株式（株式残高）：450.359332株
                    <br />
                    繰越金（現金残高）：650円
                    <br />
                    退会月○日の株価：3,500円（※1）
                  </Typography>
                  <Typography>
                    <span style={{ fontWeight: 'bold' }}> ⇒「株式400株」</span>
                    (※2)
                    <span style={{ fontWeight: 'bold' }}>
                      及び「現金176,970円」
                    </span>
                    （※3、※4）
                  </Typography>
                </Stack>
              </Box>
              <Typography
                variant="caption/regular"
                color={theme.palette.system['text-light']}
              >
                ※1
                退会精算計算を分かりやすくするために一例として示す株価であり、実際は退会する月の持株会買付日（○日）の株価となります。
                <br />
                ※2 100株単位＝400株は個人名義の証券総合口座へ株式振替
                <br />
                ※3 176,970円（50.359332株×＠3,500円）＋650円
                ＊1円未満は切り捨てとなります。
                <br />
                ※4
                退会精算に係る資金振込手数料が実際にはかかり、当該費用は会員負担となりますのでご留意ください。{' '}
                <br />
              </Typography>
            </Stack>
            <Stack gap="12px">
              <SubTitle>非上場企業</SubTitle>
              <ContentWithImages>
                <Stack gap="12px">
                  <Typography variant="body-main/regular" display="flex">
                    <li />
                    <Box>
                      退会は随時できますが、原則として一旦退会すると再入会できません。
                    </Box>
                  </Typography>
                  <Typography variant="body-main/regular" display="flex">
                    <li />
                    <Box>
                      退会精算は、非上場の期間、原則として全て現金にてなされます。退会精算株価は「理事会が決定した価格」となります。
                      <br />
                      （ただし、役員昇格等の事由により退会する場合等やむを得ないと理事長が判断した場合は、株式持分の全部または一部を退会者に交付する方法で退会することができます。）
                    </Box>
                  </Typography>
                </Stack>
                <Box>
                  <Guide7Img2 />
                </Box>
              </ContentWithImages>
              <Box
                width="100%"
                sx={{
                  display: 'flex',
                  gap: '10px',
                  backgroundColor: theme.palette.system.background,
                  borderRadius: '4px',
                  padding: '16px',
                }}
              >
                <Typography>(例)</Typography>
                <Stack gap="10px">
                  <Typography>
                    退会時持分 株式（株式残高）：505.3593株
                    <br />
                    繰越金（現金残高）：84,000円
                    <br />
                    理事会が決定した価格：500円（※1）
                  </Typography>
                  <Typography>
                    <span style={{ fontWeight: 'bold' }}>
                      {' '}
                      ⇒退会精算金額 336,679円
                    </span>
                    （※2、※3）
                  </Typography>
                </Stack>
              </Box>
              <Typography
                variant="caption/regular"
                color={theme.palette.system['text-light']}
              >
                ※1
                退会精算計算を例示するためだけの株価であり、実際の株価とは関係ありませんのでご留意ください。
                <br />
                ※2 336,679円（505.3593株×＠500円）＋84,000円 （
                1円未満の端数は切り捨てとなります。）
                <br />
                ※3
                退会精算に係る資金振込手数料が実際にはかかり、当該費用は会員負担となりますのでご留意ください。
              </Typography>
            </Stack>
          </IndexContainer>
          <IndexContainer
            index={8}
            onIntersection={intersectCallback}
            progress={progress}
          >
            <Title ref={ref} id="link8">
              <Guide8 />
              <Typography variant="h4">残高及び取引内容の確認</Typography>
            </Title>
            <Stack gap="12px">
              <Typography variant="body-main/regular" display="flex">
                <li />
                <Box>
                  持株会にて取得した株式は、形式上、持株会理事長名義となります。
                </Box>
              </Typography>
              <Typography variant="body-main/regular" display="flex">
                <li />
                <Box>持株会WEBサービスから随時確認することが可能です。</Box>
              </Typography>
            </Stack>
          </IndexContainer>
          <IndexContainer
            index={9}
            onIntersection={intersectCallback}
            progress={progress}
          >
            <Title ref={ref} id="link9">
              <Guide9 />
              <Typography variant="h4">主な重要事項</Typography>
            </Title>
            <Stack gap="12px">
              <SubTitle>価格変動リスク</SubTitle>
              <Typography variant="body-main/regular">
                従業員持株会は当社の株式を金融商品取引所（証券取引所）にて購入し、購入した株式の価格も市場動向により変動します。株式には、このような「価格変動リスク」があるため、拠出した金額についてその元本を保証するものではありませんのでご留意ください。
              </Typography>
            </Stack>
            <Stack gap="12px">
              <SubTitle>
                インサイダー取引規制、自社株売買の事前申請について（上場企業のみ）
              </SubTitle>
              <Typography>
                持株会から株式を振り替える行為はインサイダー取引規制の適用除外となりますが、振り替えた株式を市場で売却する行為はインサイダー取引規制の対象となります。（会社の株価に重大な影響を与える「重要事実」を知りながら、その重要事実が「公表される前に」、会社株式の売買等をすることはできません。金融商品取引法第166条、第177条）
                <br />
                そのため、自社株を売買するためには、会社内の規程に則り、管理部門等の事前承認が必要となりますので、所定の手続きを行った上で売却するようにお願いします。また、知得している情報等によっては、一定期間売買ができない可能性もございますのでご留意ください。
              </Typography>
            </Stack>
          </IndexContainer>
        </Stack>
        {!isSp && (
          <Box minWidth="280px">
            <Stack spacing={1} width="280px" position="fixed">
              <AnchorLink
                href="#link1"
                offset={offset}
                style={{ textDecoration: 'none' }}
              >
                <IndexBox
                  sx={{
                    backgroundColor:
                      progress === 1
                        ? theme.palette.system.white
                        : theme.palette.system['background-light'],
                  }}
                >
                  <MuiBox>
                    {progress === 1 ? (
                      <Guide1 width={24} height={24} />
                    ) : (
                      <Guide1Gray />
                    )}
                  </MuiBox>
                  <Typography
                    variant="body-sub/regular"
                    sx={{
                      color:
                        progress === 1
                          ? theme.palette.primary.normal
                          : theme.palette.system['text-light'],
                      fontWeight: progress === 1 ? 500 : 400,
                    }}
                  >
                    持株会の概要
                  </Typography>
                </IndexBox>
              </AnchorLink>
              <AnchorLink
                href="#link2"
                offset={offset}
                style={{ textDecoration: 'none' }}
              >
                <IndexBox
                  sx={{
                    backgroundColor:
                      progress === 2
                        ? theme.palette.system.white
                        : theme.palette.system['background-light'],
                  }}
                >
                  <MuiBox>
                    {progress === 2 ? (
                      <Guide2 width={24} height={24} />
                    ) : (
                      <Guide2Gray />
                    )}
                  </MuiBox>
                  <Typography
                    variant="body-sub/regular"
                    sx={{
                      color:
                        progress === 2
                          ? theme.palette.primary.normal
                          : theme.palette.system['text-light'],
                      fontWeight: progress === 2 ? 500 : 400,
                    }}
                  >
                    入会対象者について
                  </Typography>
                </IndexBox>
              </AnchorLink>
              <AnchorLink
                href="#link3"
                offset={offset}
                style={{ textDecoration: 'none' }}
              >
                <IndexBox
                  sx={{
                    backgroundColor:
                      progress === 3
                        ? theme.palette.system.white
                        : theme.palette.system['background-light'],
                  }}
                >
                  <MuiBox>
                    {progress === 3 ? (
                      <Guide3 width={24} height={24} />
                    ) : (
                      <Guide3Gray />
                    )}
                  </MuiBox>
                  <Typography
                    variant="body-sub/regular"
                    sx={{
                      color:
                        progress === 3
                          ? theme.palette.primary.normal
                          : theme.palette.system['text-light'],
                      fontWeight: progress === 3 ? 500 : 400,
                    }}
                  >
                    株式の取得（購入等）について
                  </Typography>
                </IndexBox>
              </AnchorLink>
              <AnchorLink
                href="#link4"
                offset={offset}
                style={{ textDecoration: 'none' }}
              >
                <IndexBox
                  sx={{
                    backgroundColor:
                      progress === 4
                        ? theme.palette.system.white
                        : theme.palette.system['background-light'],
                  }}
                >
                  <MuiBox>
                    {progress === 4 ? (
                      <Guide4 width={24} height={24} />
                    ) : (
                      <Guide4Gray />
                    )}
                  </MuiBox>
                  <Typography
                    variant="body-sub/regular"
                    sx={{
                      color:
                        progress === 4
                          ? theme.palette.primary.normal
                          : theme.palette.system['text-light'],
                      fontWeight: progress === 4 ? 500 : 400,
                    }}
                  >
                    口数変更について
                  </Typography>
                </IndexBox>
              </AnchorLink>
              <AnchorLink
                href="#link5"
                offset={offset}
                style={{ textDecoration: 'none' }}
              >
                <IndexBox
                  sx={{
                    backgroundColor:
                      progress === 5
                        ? theme.palette.system.white
                        : theme.palette.system['background-light'],
                  }}
                >
                  <MuiBox>
                    {progress === 5 ? (
                      <Guide5 width={24} height={24} />
                    ) : (
                      <Guide5Gray />
                    )}
                  </MuiBox>
                  <Typography
                    variant="body-sub/regular"
                    sx={{
                      color:
                        progress === 5
                          ? theme.palette.primary.normal
                          : theme.palette.system['text-light'],
                      fontWeight: progress === 5 ? 500 : 400,
                    }}
                  >
                    拠出の休止・再開について
                  </Typography>
                </IndexBox>
              </AnchorLink>
              <AnchorLink
                href="#link6"
                offset={offset}
                style={{ textDecoration: 'none' }}
              >
                <IndexBox
                  sx={{
                    backgroundColor:
                      progress === 6
                        ? theme.palette.system.white
                        : theme.palette.system['background-light'],
                  }}
                >
                  <MuiBox>
                    {progress === 6 ? (
                      <Guide6 width={24} height={24} />
                    ) : (
                      <Guide6Gray />
                    )}
                  </MuiBox>

                  <Typography
                    variant="body-sub/regular"
                    sx={{
                      color:
                        progress === 6
                          ? theme.palette.primary.normal
                          : theme.palette.system['text-light'],
                      fontWeight: progress === 6 ? 500 : 400,
                    }}
                  >
                    株式または現金の一部引出について
                  </Typography>
                </IndexBox>
              </AnchorLink>
              <AnchorLink
                href="#link7"
                offset={offset}
                style={{ textDecoration: 'none' }}
              >
                <IndexBox
                  sx={{
                    backgroundColor:
                      progress === 7
                        ? theme.palette.system.white
                        : theme.palette.system['background-light'],
                  }}
                >
                  <MuiBox>
                    {progress === 7 ? (
                      <Guide7 width={24} height={24} />
                    ) : (
                      <Guide7Gray />
                    )}
                  </MuiBox>
                  <Typography
                    variant="body-sub/regular"
                    sx={{
                      color:
                        progress === 7
                          ? theme.palette.primary.normal
                          : theme.palette.system['text-light'],
                      fontWeight: progress === 7 ? 500 : 400,
                    }}
                  >
                    退会について
                  </Typography>
                </IndexBox>
              </AnchorLink>
              <AnchorLink
                href="#link8"
                offset={offset}
                style={{ textDecoration: 'none' }}
              >
                <IndexBox
                  sx={{
                    backgroundColor:
                      progress === 8
                        ? theme.palette.system.white
                        : theme.palette.system['background-light'],
                  }}
                >
                  <MuiBox>
                    {progress === 8 ? (
                      <Guide8 width={24} height={24} />
                    ) : (
                      <Guide8Gray />
                    )}
                  </MuiBox>
                  <Typography
                    variant="body-sub/regular"
                    sx={{
                      color:
                        progress === 8
                          ? theme.palette.primary.normal
                          : theme.palette.system['text-light'],
                      fontWeight: progress === 8 ? 500 : 400,
                    }}
                  >
                    残高及び取引内容の確認
                  </Typography>
                </IndexBox>
              </AnchorLink>
              <AnchorLink
                href="#link9"
                offset={offset}
                style={{ textDecoration: 'none' }}
              >
                <IndexBox
                  sx={{
                    backgroundColor:
                      progress === 9
                        ? theme.palette.system.white
                        : theme.palette.system['background-light'],
                  }}
                >
                  <MuiBox>
                    {progress === 9 ? (
                      <Guide9 width={24} height={24} />
                    ) : (
                      <Guide9Gray />
                    )}
                  </MuiBox>
                  <Typography
                    variant="body-sub/regular"
                    sx={{
                      color:
                        progress === 9
                          ? theme.palette.primary.normal
                          : theme.palette.system['text-light'],
                      fontWeight: progress === 9 ? 500 : 400,
                    }}
                  >
                    主な注意事項
                  </Typography>
                </IndexBox>
              </AnchorLink>
            </Stack>
          </Box>
        )}
      </BottomWrapper>
    </>
  );
};
