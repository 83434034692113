/* eslint-disable consistent-return, @typescript-eslint/ban-ts-comment */
import { LoginBox } from '@/components/molecules/Login/LoginBox';
import { LoginTitleWithUnderline } from '@/components/molecules/Login/LoginTitleWithUnderline';
import { FC, useState } from 'react';
import { PopoverWithText } from '@/components/molecules/Popover/PopoverWithText';
import { ReactComponent as QuestionMarkIcon } from '@/assets/questionMarkIcon.svg';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { styled, Theme, Typography, useMediaQuery } from '@mui/material';
import { TextButton } from '@/components/atoms/Buttons/TextButton';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { Path } from '@/constants/Router/path';
import { appClient, OpenAPI } from '@/services';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { ErrMessageWithIcon } from '@/components/molecules/Message/ErrMessageWithIcon';
import { LoginLabelWithTextInput } from '@/components/molecules/Login/LoginLabelWithTextInput';
import { BREAKPOINT } from '@/theme/theme';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const ResendWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '24px',
  gap: '16px',
  background: theme.palette.system['background-light'],
  borderRadius: '8px',
}));

const ResendText = styled(Typography)(({ theme }) => ({
  ...theme.typography['body-sub/regular'],
  color: theme.palette.system['text-light'],
  textAlign: 'left',
}));

const NormalTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography['body-main/regular'],
  color: theme.palette.system['text-normal'],
  textAlign: 'left',
}));

const ButtonBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '8px',
  gap: '24px',
  width: '100%',
  [theme.breakpoints.down(BREAKPOINT)]: {
    padding: 0,
    gap: '24px',
    alignItems: 'flex-start',
    '& button': {
      width: '100%',
    },
  },
}));

// エラーメッセージ
const errorMessage = [
  '認証に失敗しました。',
  '入力されたコードが間違っていないかご確認ください。',
];

// フォーム
type FormInputState = {
  mfaCode: string;
};

// バリデーションルール
export const schema = yup.object({
  mfaCode: yup.string().required('入力してください'),
});

type LoginState = {
  loginId: string;
  associationCd: string;
  employeeCd: string;
  birthday: string;
  mfaSession: string;
};

export const LoginPasswordMfa: FC = () => {
  const isPc = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LoginState;
  const [isError, setIsError] = useState<boolean>(false);
  useHelmetHandler({
    title: '多要素認証入力',
  });

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<FormInputState>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  });

  // @ts-ignore
  const onSubmit = async (data: FormInputState) => {
    // バリデーションチェック
    // 画面遷移
    try {
      await appClient.authorization.authMfaConfirm({
        loginId: state.loginId,
        mfaCode: data.mfaCode,
        mfaSession: state.mfaSession,
      });
      // パスワード変更画面へ遷移する
      navigate(Path.LOGIN_PASSWORD_CHANGE, {
        state: {
          loginId: state.loginId,
        },
      });
    } catch (e) {
      // エラー
      setIsError(true);
    }
  };

  // MFAコード再送信
  const handleReMfaCode = async () => {
    try {
      OpenAPI.HEADERS = {};
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const response = await appClient.authorization.authidentification({
        loginId: state.loginId,
        associationCd: state.associationCd,
        employeeCd: state.employeeCd,
        birthday: state.birthday,
      });
      setIsError(false);
      setValue('mfaCode', '');
      if (response.Session !== undefined) {
        state.mfaSession = response.Session;
      }
    } catch {
      // エラー
      if (state === null) {
        navigate(Path.LOGIN);
      }
    }
  };

  return (
    <LoginBox>
      <LoginTitleWithUnderline isLogin title="多要素認証">
        <PopoverWithText icon={<QuestionMarkIcon />}>
          第三者からのアクセスを防ぎ、セキュリティを強化するために必要な認証です。
        </PopoverWithText>
      </LoginTitleWithUnderline>
      <div>
        <NormalTypography>
          メールアドレス宛に認証コードを送信しました。
        </NormalTypography>
        <NormalTypography>
          メールに掲載されたコードを下の入力欄に入力してください。
        </NormalTypography>
      </div>
      {isError && <ErrMessageWithIcon errorMessage={errorMessage} isBorder />}
      <LoginLabelWithTextInput
        label="認証コード"
        annotation=""
        error={'mfaCode' in errors}
        helperText={errors.mfaCode?.message}
        placeholder="認証コードを入力"
        flat={false}
        {...register('mfaCode')}
      />
      <ButtonBox>
        <PrimaryButton
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleSubmit(onSubmit)}
          disabled={errors.mfaCode?.message !== undefined}
        >
          認証する
        </PrimaryButton>
      </ButtonBox>
      <ResendWrapper>
        <ResendText>
          ※認証コードが届いていない場合は、下の「認証コードを再送信する」ボタンからコードを再送信してください。
        </ResendText>
        {isPc && (
          <TextButton
            onClick={(e) => {
              e.preventDefault();
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              handleReMfaCode();
            }}
          >
            認証コードを再送信する
          </TextButton>
        )}
        {!isPc && (
          <SecondaryButton
            sx={{ width: '100%' }}
            onClick={(e) => {
              e.preventDefault();
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              handleReMfaCode();
            }}
          >
            認証コードを再送信する
          </SecondaryButton>
        )}
      </ResendWrapper>
    </LoginBox>
  );
};
