import { styled, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface TitleProps {
  // ログイン
  isLogin: boolean;
  // タイトル
  title: string;
  // 説明文
  description?: string;
  // 子要素
  children?: ReactNode;
}

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0px',
  gap: '16px',
  width: '100%',
});

const Title = styled('div')(({ theme }) => ({
  width: '100%',
  paddingBottom: '24px',
  margin: 0,
  borderBottom: '1px solid',
  borderColor: theme.palette.system['separator-light'],
}));

const InnerTitle = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 0,
  gap: '8px',
});

const RegisterTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0px 0px 0px 12px',
  gap: '8px',
  width: '100%',
  background: theme.palette.system.white,
  boxShadow: 'inset 2px 0px 0px #FC5145',
}));

const LoginDescription = styled('div')(({ theme }) => ({
  ...theme.typography['body-main/regular'],
  color: theme.palette.system['text-light'],
  textAlign: 'left',
}));

const Description = styled('div')(({ theme }) => ({
  ...theme.typography['body-main/regular'],
  color: theme.palette.system['text-light'],
  textAlign: 'left',
}));

const MuiTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
}));

export const LoginTitleWithUnderline: FC<TitleProps> = ({
  isLogin,
  title,
  description,
  children,
}) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {isLogin ? (
      <Wrapper>
        <Title>
          <InnerTitle>
            <MuiTypography>{title}</MuiTypography>
            {children}
          </InnerTitle>
        </Title>
        {description && <LoginDescription>{description}</LoginDescription>}
      </Wrapper>
    ) : (
      <>
        <RegisterTitle>
          <InnerTitle>
            <MuiTypography>{title}</MuiTypography>
            {children}
          </InnerTitle>
        </RegisterTitle>
        {description && <Description>{description}</Description>}
      </>
    )}
  </>
);
