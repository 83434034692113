// openapi-typescript-codegenから自動生成されたファイル
import { AppClient } from './apiServices';
import { OpenAPI } from './apiServices/core/OpenAPI';

export type { OpenAPIConfig } from './apiServices/core/OpenAPI';
export type { Error } from './apiServices/models/Error';

export const AUTH_TOKEN = 'authToken';
export const authToken = () => {
  const token = localStorage.getItem(AUTH_TOKEN);
  if (token) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const pToken = JSON.parse(token);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return `Bearer ${pToken.IdToken as string}`;
  }
  return '';
};
OpenAPI.HEADERS = {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  Authorization: authToken(),
};

const appClient = new AppClient(OpenAPI);
export { appClient, OpenAPI };

// domain service
export { ApplicationsDomainService } from './applicationsDomainService';
export { AssociationDomainService } from './associationDomainService';
export { UserDomainService } from './userDomainService';

// third party services
export { PostcodeJPService } from './postcodeJPService';

// shared service
export { StorageService } from './storageService';
