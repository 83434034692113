import { BREAKPOINT } from '@/theme/theme';
import { styled, TableRow, TableCell, Typography } from '@mui/material';

interface TableProps {
  title?: string;
  value: string;
  row?: number;
  isRegular?: boolean;
}

const MuiTableCellTh = styled(TableCell)(({ theme }) => {
  const { palette, typography, breakpoints } = theme;
  return {
    ...typography['body-main/bold'],
    color: palette.system['text-normal'],
    background: palette.primary.light,
    width: '170px',
    textAlign: 'center',
    [breakpoints.down(BREAKPOINT)]: {
      display: 'none',
    },
  };
});

const SpWrraper = styled('div')({
  display: 'flex',
});

const StyledTableRow = styled(TableRow)(({ theme }) => {
  const { palette, breakpoints } = theme;
  return {
    '.displayPc': {
      display: 'table-cell',
    },
    '.displaySp': {
      display: 'none',
    },
    '&:nth-of-type(even)': {
      background: palette.system['background-light'],
    },
    '&:last-child td, &:last-child th': {
      border: 'none',
    },
    [breakpoints.down(BREAKPOINT)]: {
      '.displayPc': {
        display: 'none',
      },
      '.displaySp': {
        display: 'table-cell',
      },
    },
  };
});

export const PrimaryTableBodyTdSingleWithSpNotRed = ({
  title = '',
  value,
  row = 1,
  isRegular,
}: TableProps) => {
  const typographyWeight = isRegular ? 'body-main/regular' : 'body-main/bold';
  return (
    <StyledTableRow>
      <MuiTableCellTh rowSpan={row}>{title}</MuiTableCellTh>
      <TableCell className="displayPc">
        <Typography variant={typographyWeight}>{value}</Typography>
      </TableCell>
      <TableCell className="displaySp" colSpan={2}>
        <SpWrraper>
          <Typography variant={typographyWeight} sx={{ width: '130px' }}>
            {title}
          </Typography>
          <Typography variant={typographyWeight}>{value}</Typography>
        </SpWrraper>
      </TableCell>
    </StyledTableRow>
  );
};
