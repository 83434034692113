import { APPLICATION } from '@/constants/index';
import {
  ListApplications,
  ListApplicationsItem,
} from '@/types/api/applications';

export class ApplicationsDomainService {
  /**
   * isApplyingStatus
   * @description Status 申請中
   * @param application
   * @returns {boolean}
   */
  public static isApplyingStatus(application: ListApplicationsItem): boolean {
    return application.applicationStatus === APPLICATION.STATUS.APPLYING;
  }

  /**
   * isApprovedStatus
   * @description Status 事務局承認済み or 制度G承認済（STARⅣ処理中）
   * @param application
   * @returns {boolean}
   */
  public static isApprovedStatus(application: ListApplicationsItem): boolean {
    return (
      application.applicationStatus === APPLICATION.STATUS.OFFICER_APPROVED ||
      application.applicationStatus === APPLICATION.STATUS.BACKOFFICER_APPROVED
    );
  }

  /**
   * isApplicationDisabled
   * @description 申請中
   * （applicationStatus：APPLYING / OFFICER_APPROVED / BACKOFFICER_APPROVED）の場合
   * 休止・再開・月例口数変更・賞与口数変更 申請不可
   * @param applications
   * @param applicationType
   * @returns {boolean}
   */
  public static isApplicationDisabled(
    applications: ListApplications['applications'] = [],
    applicationType: ListApplicationsItem['applicationType'] = 'MONTHLY_UNIT'
  ): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const found = applications.find(
      (application) =>
        application.applicationType === applicationType &&
        (this.isApplyingStatus(application) ||
          this.isApprovedStatus(application))
    );

    return found !== undefined;
  }

  /**
   * convertApplicationTypeText
   * todo: 型変更
   * - MONTHLY_UNIT: 口数変更(月例)
   * - BONUS_UNIT: 口数変更(賞与)
   * - PART: 一部引出
   * - RECESS: 休会
   * - RESUME: 再開
   * - WITHDRAWAL: 退会
   * - ADMISSION: 新規会員登録
   * - USER: 会員情報変更
   * @param applicationType
   */

  public static convertApplicationTypeText(applicationType: string) {
    switch (applicationType) {
      case APPLICATION.TYPE.MONTHLY_UNIT:
        return '口数変更';
      case APPLICATION.TYPE.BONUS_UNIT:
        return '口数変更';
      case APPLICATION.TYPE.PART:
        return '一部引出';
      case APPLICATION.TYPE.RECESS:
        return '休止';
      case APPLICATION.TYPE.RESUME:
        return '再開';
      case APPLICATION.TYPE.WITHDRAWAL:
        return '退会';
      case APPLICATION.TYPE.ADMISSION:
        return '新規会員登録';
      case APPLICATION.TYPE.USER:
        return '会員情報変更';
      default:
        return '';
    }
  }
}
