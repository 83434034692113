/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ApplicationsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * 【API-A-0501】申請履歴一覧取得
   * 申請履歴一覧を取得する
   *
   * @param page 取得ページ番号
   *
   * @param perPageSize 1ページあたり件数
   *
   * @param sortKey ソートキー
   * - APPLICATION_ID_ASC: 申請書ID昇順
   * - APPLICATION_ID_DESC: 申請書ID降順
   * - APPLICATION_TYPE_ASC: 申請書種別昇順
   * - APPLICATION_TYPE_DESC: 申請書種別降順
   * - APPLICATION_DATE_ASC: 申請日時昇順
   * - APPLICATION_DATE_DESC: 申請日時降順
   * - APPLICATION_STATUS_ASC: 申請ステータス昇順
   * - APPLICATION_STATUS_DESC: 申請ステータス降順
   *
   * @returns any applications response
   * @throws ApiError
   */
  public listApplications(
    page?: number,
    perPageSize?: number,
    sortKey?:
      | 'APPLICATION_ID_ASC'
      | 'APPLICATION_ID_DESC'
      | 'APPLICATION_TYPE_ASC'
      | 'APPLICATION_TYPE_DESC'
      | 'APPLICATION_DATE_ASC'
      | 'APPLICATION_DATE_DESC'
      | 'APPLICATION_STATUS_ASC'
      | 'APPLICATION_STATUS_DESC'
  ): CancelablePromise<
    {
      applications?: Array<{
        /**
         * 申請書ID
         */
        applicationId?: number;
        /**
         * メンバーID
         */
        memberId?: number;
        /**
         * 持株会コード
         */
        associationCd?: string;
        /**
         * イベント№
         */
        eventNo?: string;
        /**
         * 申請種別
         * - MONTHLY_UNIT: 口数変更
         * - BONUS_UNIT: 口数変更（非推奨）
         * - PART: 一部引出
         * - RECESS: 休会
         * - RESUME: 再開
         * - WITHDRAWAL: 退会
         * - ADMISSION: 新規会員登録
         * - USER: 会員情報変更
         *
         */
        applicationType?:
          | 'MONTHLY_UNIT'
          | 'BONUS_UNIT'
          | 'PART'
          | 'RECESS'
          | 'RESUME'
          | 'WITHDRAWAL'
          | 'ADMISSION'
          | 'USER';
        /**
         * 申請日付
         */
        applicationDate?: string;
        /**
         * 申請ステータス
         * - PREPARATION: 入力中
         * - APPLYING: 申請中
         * - OFFICER_APPROVED: 事務局承認済
         * - BACKOFFICER_APPROVED: 制度G承認済（STARⅣ処理中）
         * - COMPLETED: 完了（システム反映済み）
         * - OFFICER_REJECTED: 却下（事務局）
         * - BACKOFFICER_REJECTED: 却下（制度G）
         * - CANCEL: 申請取り消し（会員）
         *
         */
        applicationStatus?:
          | 'PREPARATION'
          | 'APPLYING'
          | 'OFFICER_APPROVED'
          | 'BACKOFFICER_APPROVED'
          | 'COMPLETED'
          | 'OFFICER_REJECTED'
          | 'BACKOFFICER_REJECTED'
          | 'CANCEL';
        /**
         * 却下コメント
         */
        rejectComment?: string;
        /**
         * 承認・却下者名
         */
        judgeUserName?: string;
        /**
         * 代理申請者名
         */
        proxyApplicantUserName?: string;
        /**
         * 事務局修正者名
         */
        officeUpdateUserName?: string;
        /**
         * 持株制度G修正者名
         */
        backofficeUpdateUserName?: string;
      }>;
    } & {
      /**
       * 現在ページ番号
       */
      currentPage?: number;
      /**
       * 総ページ数
       */
      totalPage?: number;
      /**
       * １ページあたり件数
       */
      perPageSize?: number;
      /**
       * 総件数
       */
      totalSize?: number;
    }
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/applications',
      query: {
        page: page,
        perPageSize: perPageSize,
        sortKey: sortKey,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-A-0503】申請登録
   * 最大2件 \
   * 変更のないものは返却しない \
   * 0件の場合はエラーとする
   *
   * @param requestBody application request
   * @returns any application response
   * @throws ApiError
   */
  public createApplication(requestBody: {
    /**
     * 申請日付
     */
    applicationDate?: string;
    /**
     * 申請種別
     * - MONTHLY_UNIT: 口数変更
     * - BONUS_UNIT: 口数変更（非推奨）
     * - PART: 一部引出
     * - RECESS: 休会
     * - RESUME: 再開
     * - WITHDRAWAL: 退会
     * - ADMISSION: 新規会員登録
     * - USER: 会員情報変更
     *
     */
    applicationType?:
      | 'MONTHLY_UNIT'
      | 'BONUS_UNIT'
      | 'PART'
      | 'RECESS'
      | 'RESUME'
      | 'WITHDRAWAL'
      | 'ADMISSION'
      | 'USER';
    applicationBonusUnit?: {
      /**
       * 賞与拠出有無
       */
      isBonusUnit?: boolean;
      /**
       * 賞与拠出口数
       */
      bonusUnit?: number;
      /**
       * 賞与_口数(x倍)
       */
      bonusRate?: number;
    };
    applicationMonthlyUnit?: {
      /**
       * 月例拠出口数
       */
      monthlyUnit?: number;
      /**
       * 賞与拠出有無
       */
      isBonusUnit?: boolean;
      /**
       * 賞与拠出口数
       */
      bonusContributionUnit?: number;
      /**
       * 賞与_口数(x倍)
       */
      bonusRate?: number;
    };
    applicationPart?: {
      /**
       * 引き出し株数
       */
      sharesNum?: number;
    };
    applicationRecess?: {
      /**
       * 休会/退会理由
       * - LEAVE_ABSENCE: 休職/退職
       * - FAMILY_MATTERS: 家族理由
       * - ECONOMIC: 経済理由
       * - OTHER: その他
       *
       */
      reason?: 'LEAVE_ABSENCE' | 'FAMILY_MATTERS' | 'ECONOMIC' | 'OTHER';
      /**
       * 休会理由(その他)
       */
      otherReasons?: string;
    };
    applicationWithdrawal?: {
      /**
       * 休会/退会理由
       * - LEAVE_ABSENCE: 休職/退職
       * - FAMILY_MATTERS: 家族理由
       * - ECONOMIC: 経済理由
       * - OTHER: その他
       *
       */
      reason?: 'LEAVE_ABSENCE' | 'FAMILY_MATTERS' | 'ECONOMIC' | 'OTHER';
      /**
       * 退会理由(その他)
       */
      otherReasons?: string;
      generalAccount?: {
        /**
         * 金融機関コード
         *
         */
        institutionCode?: string;
        /**
         * 金融機関名
         *
         */
        institutionName?: string;
        /**
         * 支店コード
         *
         */
        bankBranchCode?: string;
        /**
         * 支店名
         *
         */
        bankBranchName?: string;
        /**
         * アカウント種別
         * - SAVINGS: 普通
         * - CURRENT: 当座
         * - OTHER: 貯蓄
         *
         */
        accountType?: 'SAVINGS' | 'CURRENT' | 'OTHER';
        /**
         * 口座番号
         *
         */
        accountNumber?: string;
      };
      /**
       * 買い増し有無
       */
      isAdditionalOption?: boolean;
      /**
       * 金融機関種類
       * - YUCHO: ゆうちょ銀行
       * - OTHER: ゆうちょ銀行以外
       *
       */
      institutionType?: 'YUCHO' | 'OTHER';
      /**
       * 記号（ゆうちょ銀行）
       */
      accountYuchoSymbol?: string;
      /**
       * 番号(ゆうちょ銀行)
       */
      accountYuchoNumber?: string;
    };
  }): CancelablePromise<{
    /**
     * 申請書ID
     */
    applicationId?: number;
    /**
     * メンバーID
     */
    memberId?: number;
    /**
     * 持株会コード
     */
    associationCd?: string;
    /**
     * イベント№
     */
    eventNo?: string;
    /**
     * 申請種別
     * - MONTHLY_UNIT: 口数変更
     * - BONUS_UNIT: 口数変更（非推奨）
     * - PART: 一部引出
     * - RECESS: 休会
     * - RESUME: 再開
     * - WITHDRAWAL: 退会
     * - ADMISSION: 新規会員登録
     * - USER: 会員情報変更
     *
     */
    applicationType?:
      | 'MONTHLY_UNIT'
      | 'BONUS_UNIT'
      | 'PART'
      | 'RECESS'
      | 'RESUME'
      | 'WITHDRAWAL'
      | 'ADMISSION'
      | 'USER';
    /**
     * 申請日付
     */
    applicationDate?: string;
    /**
     * 申請ステータス
     * - PREPARATION: 入力中
     * - APPLYING: 申請中
     * - OFFICER_APPROVED: 事務局承認済
     * - BACKOFFICER_APPROVED: 制度G承認済（STARⅣ処理中）
     * - COMPLETED: 完了（システム反映済み）
     * - OFFICER_REJECTED: 却下（事務局）
     * - BACKOFFICER_REJECTED: 却下（制度G）
     * - CANCEL: 申請取り消し（会員）
     *
     */
    applicationStatus?:
      | 'PREPARATION'
      | 'APPLYING'
      | 'OFFICER_APPROVED'
      | 'BACKOFFICER_APPROVED'
      | 'COMPLETED'
      | 'OFFICER_REJECTED'
      | 'BACKOFFICER_REJECTED'
      | 'CANCEL';
    /**
     * 却下コメント
     */
    rejectComment?: string;
    /**
     * 承認・却下者名
     */
    judgeUserName?: string;
    /**
     * 代理申請者名
     */
    proxyApplicantUserName?: string;
    /**
     * 事務局修正者名
     */
    officeUpdateUserName?: string;
    /**
     * 持株制度G修正者名
     */
    backofficeUpdateUserName?: string;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/applications',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-A-0502】申請情報取得
   * 持株会員の申請情報取得を取得する
   *
   * @param applicationId ID of application to fetch
   * @param fields 取得field \
   * fields1,fields2,fields3
   *
   * @returns any application part response
   * @throws ApiError
   */
  public findApplicationById(
    applicationId: number,
    fields?: string
  ): CancelablePromise<{
    /**
     * 申請書ID
     */
    applicationId?: number;
    /**
     * メンバーID
     */
    memberId?: number;
    /**
     * 持株会コード
     */
    associationCd?: string;
    /**
     * イベント№
     */
    eventNo?: string;
    /**
     * 申請種別
     * - MONTHLY_UNIT: 口数変更
     * - BONUS_UNIT: 口数変更（非推奨）
     * - PART: 一部引出
     * - RECESS: 休会
     * - RESUME: 再開
     * - WITHDRAWAL: 退会
     * - ADMISSION: 新規会員登録
     * - USER: 会員情報変更
     *
     */
    applicationType?:
      | 'MONTHLY_UNIT'
      | 'BONUS_UNIT'
      | 'PART'
      | 'RECESS'
      | 'RESUME'
      | 'WITHDRAWAL'
      | 'ADMISSION'
      | 'USER';
    /**
     * 申請日付
     */
    applicationDate?: string;
    /**
     * 申請ステータス
     * - PREPARATION: 入力中
     * - APPLYING: 申請中
     * - OFFICER_APPROVED: 事務局承認済
     * - BACKOFFICER_APPROVED: 制度G承認済（STARⅣ処理中）
     * - COMPLETED: 完了（システム反映済み）
     * - OFFICER_REJECTED: 却下（事務局）
     * - BACKOFFICER_REJECTED: 却下（制度G）
     * - CANCEL: 申請取り消し（会員）
     *
     */
    applicationStatus?:
      | 'PREPARATION'
      | 'APPLYING'
      | 'OFFICER_APPROVED'
      | 'BACKOFFICER_APPROVED'
      | 'COMPLETED'
      | 'OFFICER_REJECTED'
      | 'BACKOFFICER_REJECTED'
      | 'CANCEL';
    /**
     * 却下コメント
     */
    rejectComment?: string;
    /**
     * 事務局承認・却下者名
     */
    officeJudgeUserName?: string;
    /**
     * 代理申請者名
     */
    proxyApplicantUserName?: string;
    /**
     * 事務局修正者名
     */
    officeUpdateUserName?: string;
    /**
     * 持株制度G修正者名
     */
    backofficeUpdateUserName?: string;
    /**
     * 事務局承認日時
     */
    officeJudgeDt?: string;
    /**
     * 制度G承認日時
     */
    backofficeJudgeDt?: string;
    applicationBonusUnit?: {
      /**
       * 賞与拠出有無区分
       */
      isBonusUnit?: boolean;
      /**
       * 賞与拠出口数
       */
      bonusUnit?: number;
      /**
       * 賞与_口数(x倍)
       */
      bonusRate?: number;
    };
    applicationMonthlyUnit?: {
      /**
       * 月例(給与)_口数
       */
      monthlyUnit?: number;
      /**
       * 変更前月例口数
       */
      preMonthlyUnit?: number;
      /**
       * 賞与拠出有無区分
       */
      isBonusUnit?: boolean;
      /**
       * 変更前賞与拠出有無区分
       */
      preIsBonusUnit?: boolean;
      /**
       * 賞与拠出口数
       */
      bonusContributionUnit?: number;
      /**
       * 変更前賞与拠出口数
       */
      preBonusContributionUnit?: number;
      /**
       * 賞与_口数(x倍)
       */
      bonusRate?: number;
      /**
       * 変更前賞与_口数(x倍)
       */
      preBonusRate?: number;
      /**
       * 月例口数変更フラグ
       */
      monthlyChangeFlg?: boolean;
      /**
       * 賞与口数変更フラグ
       */
      bonusChangeFlg?: boolean;
    };
    applicationAdmission?: {
      /**
       * 会員コード
       */
      userCode?: string;
      /**
       * 社員コード
       */
      employeeCd?: string;
      /**
       * メールアドレス
       */
      email?: string;
      /**
       * 氏名（漢字）
       */
      nameKanji?: string;
      /**
       * 氏名（カナ）
       */
      nameKana?: string;
      /**
       * 生年月日
       */
      birthday?: string;
      /**
       * 郵便番号
       */
      zipcode?: string;
      /**
       * 都道府県名
       */
      address1?: string;
      /**
       * 市区町村
       */
      address2?: string;
      /**
       * 番地・その他住所
       */
      address3?: string;
      /**
       * 電話種類
       * - MOBILE：携帯
       * - HOME：自宅
       *
       */
      telType?: 'MOBILE' | 'HOME';
      /**
       * 電話番号
       */
      tel?: string;
      /**
       * 月例(給与)_口数
       */
      monthlyUnit?: number;
      /**
       * 賞与拠出有無区分
       */
      isBonusUnit?: string;
      /**
       * 賞与拠出口数
       */
      bonusUnit?: string;
      /**
       * 賞与_口数(x倍)
       */
      bonusRate?: number;
    };
    applicationPart?: {
      sharesNum?: number;
      leftSharesNum?: number;
    };
    applicationRecess?: {
      /**
       * 休会/退会理由
       * - LEAVE_ABSENCE: 休職/退職
       * - FAMILY_MATTERS: 家族理由
       * - ECONOMIC: 経済理由
       * - OTHER: その他
       *
       */
      reason?: 'LEAVE_ABSENCE' | 'FAMILY_MATTERS' | 'ECONOMIC' | 'OTHER';
      /**
       * 休会理由(その他)
       */
      otherReasons?: string;
    };
    applicationResume?: {
      /**
       * 所持株総数
       */
      sharesNum?: number;
      /**
       * 月例(給与)_口数
       */
      monthlyUnit?: number;
      /**
       * 賞与_拠出有無
       */
      bonusContributionFlg?: boolean;
      /**
       * 賞与_口数
       */
      bonusUnit?: number;
      /**
       * 賞与_口数(x倍)
       */
      bonusRate?: number;
    };
    applicationUser?: {
      /**
       * 会員コード
       */
      memberCd?: string;
      /**
       * 社員コード
       */
      employeeCd?: string;
      /**
       * 氏名（漢字）
       */
      nameKanji?: string;
      /**
       * 氏名（カナ）
       */
      nameKana?: string;
      /**
       * 生年月日
       */
      birthday?: string;
      /**
       * 郵便番号
       */
      zipcode?: string;
      /**
       * 都道府県名
       */
      address1?: string;
      /**
       * 市区町村
       */
      address2?: string;
      /**
       * 番地・その他住所
       */
      address3?: string;
      /**
       * 電話番号
       */
      tel?: string;
      /**
       * 電話種類
       * - MOBILE：携帯
       * - HOME：自宅
       *
       */
      telType?: 'MOBILE' | 'HOME';
    };
    applicationWithdrawal?: {
      /**
       * 休会/退会理由
       * - LEAVE_ABSENCE: 休職/退職
       * - FAMILY_MATTERS: 家族理由
       * - ECONOMIC: 経済理由
       * - OTHER: その他
       *
       */
      reason?: 'LEAVE_ABSENCE' | 'FAMILY_MATTERS' | 'ECONOMIC' | 'OTHER';
      /**
       * 退会理由(その他)
       */
      otherReasons?: string;
      generalAccount?: {
        /**
         * 金融機関コード
         *
         */
        institutionCode?: string;
        /**
         * 金融機関名
         *
         */
        institutionName?: string;
        /**
         * 支店コード
         *
         */
        bankBranchCode?: string;
        /**
         * 支店名
         *
         */
        bankBranchName?: string;
        /**
         * アカウント種別
         * - SAVINGS: 普通
         * - CURRENT: 当座
         * - OTHER: 貯蓄
         *
         */
        accountType?: 'SAVINGS' | 'CURRENT' | 'OTHER';
        /**
         * 口座番号
         *
         */
        accountNumber?: string;
      };
      /**
       * 買増有無
       */
      isAdditionalOption?: boolean;
      /**
       * 振込金額
       */
      transferAmount?: number;
      /**
       * 備考
       */
      remarks?: string;
      /**
       * 金融機関種類
       * - YUCHO: ゆうちょ銀行
       * - OTHER: ゆうちょ銀行以外
       *
       */
      institutionType?: 'YUCHO' | 'OTHER';
      /**
       * 記号（ゆうちょ銀行）
       */
      accountYuchoSymbol?: string;
      /**
       * 番号(ゆうちょ銀行)
       */
      accountYuchoNumber?: string;
      /**
       * 買増株数
       */
      addPurchaseSharesNum?: number;
    };
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/applications/{applicationId}',
      path: {
        applicationId: applicationId,
      },
      query: {
        fields: fields,
      },
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-A-0504】申請_キャンセル
   * 持株会員申請書をキャンセルする
   * 持株会員締日を過ぎていないこと
   *
   * @param requestBody application cancel request
   * @returns any application response
   * @throws ApiError
   */
  public applicationCancel(requestBody: {
    applicationId: number;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/applications/cancel',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }

  /**
   * 【API-A-0505】各種申請PDF
   * 各種申請PDF
   *
   * @param requestBody application pdf download request
   * @returns binary application response
   * @returns any unexpected error
   * @throws ApiError
   */
  public applicationPdfDownload(requestBody: {
    /**
     * 申請書ID
     */
    applicationId?: number;
    /**
     * 申請日付
     */
    applicationDate?: string;
    /**
     * 申請種別
     * - MONTHLY_UNIT: 口数変更
     * - BONUS_UNIT: 口数変更（非推奨）
     * - PART: 一部引出
     * - RECESS: 休会
     * - RESUME: 再開
     * - WITHDRAWAL: 退会
     * - ADMISSION: 新規会員登録
     * - USER: 会員情報変更
     *
     */
    applicationType?:
      | 'MONTHLY_UNIT'
      | 'BONUS_UNIT'
      | 'PART'
      | 'RECESS'
      | 'RESUME'
      | 'WITHDRAWAL'
      | 'ADMISSION'
      | 'USER';
    applicationBonusUnit?: {
      /**
       * 賞与拠出有無
       */
      isBonusUnit?: boolean;
      /**
       * 賞与拠出口数
       */
      bonusUnit?: number;
      /**
       * 賞与_口数(x倍)
       */
      bonusRate?: number;
    };
    applicationMonthlyUnit?: {
      /**
       * 月例拠出口数
       */
      monthlyUnit?: number;
    };
    applicationPart?: {
      /**
       * 引き出し株数
       */
      sharesNum?: number;
      /**
       * 残り株数
       */
      leftSharesNum?: number;
    };
    applicationRecess?: {
      /**
       * 休会/退会理由
       * - LEAVE_ABSENCE: 休職/退職
       * - FAMILY_MATTERS: 家族理由
       * - ECONOMIC: 経済理由
       * - OTHER: その他
       *
       */
      reason?: 'LEAVE_ABSENCE' | 'FAMILY_MATTERS' | 'ECONOMIC' | 'OTHER';
      /**
       * 休会理由(その他)
       */
      otherReasons?: string;
    };
    applicationWithdrawal?: {
      /**
       * 休会/退会理由
       * - LEAVE_ABSENCE: 休職/退職
       * - FAMILY_MATTERS: 家族理由
       * - ECONOMIC: 経済理由
       * - OTHER: その他
       *
       */
      reason?: 'LEAVE_ABSENCE' | 'FAMILY_MATTERS' | 'ECONOMIC' | 'OTHER';
      /**
       * 退会理由(その他)
       */
      otherReasons?: string;
      generalAccount?: {
        /**
         * 金融機関コード
         *
         */
        institutionCode?: string;
        /**
         * 金融機関名
         *
         */
        institutionName?: string;
        /**
         * 支店コード
         *
         */
        bankBranchCode?: string;
        /**
         * 支店名
         *
         */
        bankBranchName?: string;
        /**
         * アカウント種別
         * - SAVINGS: 普通
         * - CURRENT: 当座
         * - OTHER: 貯蓄
         *
         */
        accountType?: 'SAVINGS' | 'CURRENT' | 'OTHER';
        /**
         * 口座番号
         *
         */
        accountNumber?: string;
      };
      /**
       * 買い増し有無
       */
      isAdditionalOption?: boolean;
      /**
       * 金融機関種類
       * - YUCHO: ゆうちょ銀行
       * - OTHER: ゆうちょ銀行以外
       *
       */
      institutionType?: 'YUCHO' | 'OTHER';
      /**
       * 記号（ゆうちょ銀行）
       */
      accountYuchoSymbol?: string;
      /**
       * 番号(ゆうちょ銀行)
       */
      accountYuchoNumber?: string;
      /**
       * 買増株数
       */
      addPurchaseSettlementAmount?: number;
    };
    applicationResume?: {
      /**
       * 所持株数
       */
      sharesNum?: number;
      /**
       * 月例口数
       */
      monthlyUnit?: number;
      /**
       * 賞与口数
       */
      bonusUnit?: number;
    };
  }): CancelablePromise<
    | Blob
    | {
        /**
         * Error code
         */
        code: number;
        /**
         * Error message
         */
        message: string;
      }
  > {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/applications/downlaod',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `bad request`,
      },
    });
  }
}
