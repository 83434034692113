import { UserAsset } from '@/types/api/userAsset';

export const ConvertUserAsset = (
  data:
    | {
        stocksNumber?: number;
        marketValue?: number;
        acquisitionAmount?: number;
        valuationGainLoss?: number;
        complementaryClosingPrice?: number;
        oldStock?: number;
        firstNewStock?: number;
        secondNewStock?: number;
        carriedForwardAmount?: number;
        cumulativeDeposit?: number;
        cumulativeContribution?: number;
        cumulativeIncentives?: number;
        cumulativeDividend?: number;
        cumulativeInterest?: number;
        cumulativeOtherDeposits?: number;
        cumulativeWithdrawal?: number;
        cumulativeOfficeExpenses?: number;
        withdrawSharesCnt?: number;
        purchaseAvarageUnitPrice?: number;
        lastUpdated?: string;
        targetDate?: string;
      }
    | undefined
) =>
  data
    ? ({
        stocksNumber: data.stocksNumber || 0,
        marketValue: data.marketValue || 0,
        acquisitionAmount: data.acquisitionAmount || 0,
        valuationGainLoss: data.valuationGainLoss || 0,
        complementaryClosingPrice: data.complementaryClosingPrice || 0,
        oldStock: data.oldStock || 0,
        firstNewStock: data.firstNewStock || 0,
        secondNewStock: data.secondNewStock || 0,
        carriedForwardAmount: data.carriedForwardAmount || 0,
        cumulativeDeposit: data.cumulativeDeposit || 0,
        cumulativeContribution: data.cumulativeContribution || 0,
        cumulativeIncentives: data.cumulativeIncentives || 0,
        cumulativeDividend: data.cumulativeDividend || 0,
        cumulativeInterest: data.cumulativeInterest || 0,
        cumulativeOtherDeposits: data.cumulativeOtherDeposits || 0,
        cumulativeWithdrawal: data.cumulativeWithdrawal || 0,
        cumulativeOfficeExpenses: data.cumulativeOfficeExpenses || 0,
        withdrawSharesCnt: data.withdrawSharesCnt || 0,
        purchaseAvarageUnitPrice: data.purchaseAvarageUnitPrice || 0,
        lastUpdated: data.lastUpdated || '',
        targetDate: data.targetDate || '',
      } as UserAsset)
    : undefined;
