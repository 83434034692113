import { Settings } from '@/constants/TextInput/Settings';
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as IconOn } from '@/assets/visibilityOn.svg';
import { ReactComponent as IconOff } from '@/assets/visibilityOff.svg';
import {
  TextInputSetting,
  TextInputSettings,
  TextInputState,
} from '@/types/components/TextInput';

export const TextInput: FC<TextFieldProps> = (props) => {
  const { name, helperText, error = false, ...rest } = props;
  const { pathname } = useLocation();
  let targetPage = pathname as keyof TextInputSettings;
  // 動的Pathを回避。以下のページはtokenがランダム文字列になるため固定のPathをセット
  // ただし「/existing-login/user」は固定Pathのため変更しない
  // /existing-login/:token
  if (
    typeof targetPage === 'string' &&
    !targetPage.indexOf('/existing-login/user/')
  ) {
    targetPage = '/existing-login/';
  }
  const pageSetting = Settings[targetPage];
  if (pageSetting === undefined) throw new Error('Page settings not found');
  const commonSetting = pageSetting.common;
  const targetField = name as keyof TextInputSetting;
  const fieldSetting = pageSetting[targetField];
  if (fieldSetting === undefined) throw new Error(`${targetField} not found`);
  const renderFlag = useRef(false);
  const [values, setValues] = useState<TextInputState>({
    type: 'text',
    showIcon: false,
  });

  useEffect(() => {
    if (!renderFlag.current) {
      const hasState = (
        setting: TextInputSetting
      ): setting is { state: TextInputState } =>
        Object.prototype.hasOwnProperty.call(setting, 'state');

      const state = {
        ...values,
        ...(hasState(commonSetting) ? { ...commonSetting.state } : {}),
        ...(hasState(fieldSetting) ? { ...fieldSetting.state } : {}),
      };

      setValues(state);
    }
    renderFlag.current = true;
  }, [values, commonSetting, fieldSetting]);

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      type: values.type === 'text' ? 'password' : 'text',
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <TextField
      type={values.type}
      {...commonSetting}
      {...fieldSetting}
      error={error}
      helperText={error && helperText}
      InputProps={{
        endAdornment: values.showIcon ? (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.type === 'text' ? <IconOn /> : <IconOff />}
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      {...rest}
    />
  );
};
