import { ReactComponent as ArrowIcon } from '@/assets/keyboardArrowDownIcon.svg';
import {
  Box,
  MenuItem,
  MenuItemProps,
  Select,
  SelectProps,
  styled,
  SvgIconProps,
} from '@mui/material';
import { forwardRef, ComponentPropsWithoutRef, FC } from 'react';

const MuiSelect = styled(Select)(({ theme }) => {
  const { typography, palette } = theme;

  return {
    ...typography['body-main/medium'],
    padding: '8px',
    height: '40px',

    '&&> .MuiSelect-select.MuiOutlinedInput-input': {
      padding: '0 16px 0 0',
    },
    '&> fieldset': {
      border: '1px solid',
      borderColor: palette.system.placeholder,
      borderRadius: '4px',
    },
    '&.Mui-disabled': {
      opacity: 0.4,
    },
  };
});

const MuiOption = styled(MenuItem)(({ theme }) => {
  const { typography, palette } = theme;
  return {
    ...typography['body-main/medium'],
    color: palette.system['text-normal'],
  };
});

interface SelectLabel {
  items: MenuItemProps<'li', { key: string; value: string | number }>[];
}

type SelectLabelProps = SelectLabel & SelectProps;
type ChildProps = ComponentPropsWithoutRef<'select'> & SelectLabelProps;

const CustomSvgIcon: FC<SvgIconProps> = (props) => <ArrowIcon {...props} />;

export const LoginSelect = forwardRef<HTMLInputElement, ChildProps>(
  ({ items, ...props }, ref) => (
    <Box position="relative">
      <MuiSelect
        IconComponent={CustomSvgIcon}
        defaultValue=""
        sx={{ width: '80px' }}
        error={props.error}
        ref={ref}
        {...props}
      >
        {items.map((item) => {
          const { key } = item;
          return (
            <MuiOption key={key} value={key}>
              {item.value}
            </MuiOption>
          );
        })}
      </MuiSelect>
    </Box>
  )
);
