import { theme } from '@/theme/theme';
import { numberFormat } from '@/utils/numberFormat';
import { Box, Typography, styled } from '@mui/material';
import { FundingInfoGroup } from './FundingInfoGroup';

const Wrapper = styled(Box)(() => {
  const { breakpoints, palette } = theme;

  return {
    marginTop: 16,
    borderRadius: 12,
    backgroundColor: palette.system.white,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.system.separator,
    maxWidth: '100%',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',

    [breakpoints.down('sm')]: {
      borderRadius: 0,
      marginTop: 8,
    },
  };
});

const HeaderWrapper = styled(Box)(() => {
  const { palette } = theme;

  return {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.system.separator,
    paddingBottom: 16,
    display: 'flex',
    flexDirection: 'row',
  };
});

const HeaderTextKeyWrapper = styled(Box)({
  width: 144,
});

const HeaderTextValueWrapper = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const HeaderTextKey = styled(Typography)(() => {
  const { typography, palette } = theme;

  return {
    ...typography['body-sub/bold'],
    color: palette.system['text-normal'],
  };
});

const HeaderTextValue = styled(Typography)(() => {
  const { typography, palette } = theme;

  return {
    ...typography['body-main/regular'],
    color: palette.system['text-normal'],
  };
});

const InfoGroupWrapper = styled(Box)(() => {
  const { breakpoints } = theme;

  return {
    display: 'flex',
    flexDirection: 'row',

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  };
});

export const FundingInfoBoard = ({
  monthlyIncentiveRatio,
  unitAmount,
  monthlyUnit,
  bonusUnit,
}: {
  monthlyIncentiveRatio: number;
  unitAmount: number;
  monthlyUnit: number;
  bonusUnit: number;
}) => (
  <Wrapper>
    <HeaderWrapper>
      <HeaderTextKeyWrapper>
        <HeaderTextKey>1口あたり金額</HeaderTextKey>
      </HeaderTextKeyWrapper>
      <HeaderTextValueWrapper>
        <HeaderTextValue>{numberFormat(unitAmount)}円</HeaderTextValue>
      </HeaderTextValueWrapper>
    </HeaderWrapper>
    <HeaderWrapper sx={{ paddingTop: '16px' }}>
      <HeaderTextKeyWrapper>
        <HeaderTextKey>奨励金率</HeaderTextKey>
      </HeaderTextKeyWrapper>
      <HeaderTextValueWrapper>
        <HeaderTextValue>{monthlyIncentiveRatio}%</HeaderTextValue>
      </HeaderTextValueWrapper>
    </HeaderWrapper>
    <InfoGroupWrapper>
      <FundingInfoGroup
        title="給与"
        subtitle="※毎月の拠出"
        numberOfWords={numberFormat(monthlyUnit)}
        amountTitle="拠出設定"
        amount={numberFormat(unitAmount * monthlyUnit)}
        monthlyIncentive={numberFormat(
          ((unitAmount * monthlyUnit) / 100) * monthlyIncentiveRatio,
          { maximumFractionDigits: 0 },
          true
        )}
      />
      <FundingInfoGroup
        title="賞与"
        numberOfWords={numberFormat(bonusUnit)}
        amountTitle="拠出設定"
        amount={numberFormat(unitAmount * bonusUnit)}
        monthlyIncentive={numberFormat(
          ((unitAmount * bonusUnit) / 100) * monthlyIncentiveRatio,
          { maximumFractionDigits: 0 },
          true
        )}
      />
    </InfoGroupWrapper>
  </Wrapper>
);
