import { LoginBox } from '@/components/molecules/Login/LoginBox';
import { LoginTitleWithUnderline } from '@/components/molecules/Login/LoginTitleWithUnderline';
import { FC, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Box, styled, Typography } from '@mui/material';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { appClient } from '@/services';
import { Path } from '@/constants/Router/path';
import { LoginLabelWithPasswordInput } from '@/components/molecules/Login/LoginLabelWithPasswordInput';
import * as yup from 'yup';
import { theme } from '@/theme/theme';
import { useForm } from 'react-hook-form';
import { validatePassword } from '@/utils/validate/validation';
import { ErrMessageWithIcon } from '@/components/molecules/Message/ErrMessageWithIcon';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const NormalTypography = styled(Typography)(() => ({
  ...theme.typography['body-main/regular'],
  color: theme.palette.system['text-normal'],
  textAlign: 'left',
}));

const LabelWithInput = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0px',
  width: '100%',
});

const TemporaryWrapper = styled(Typography)(() => ({
  ...theme.typography['caption/regular'],
  color: theme.palette.system['text-light'],
  textAlign: 'left',
}));

// エラーメッセージ
const errorMessage = ['パスワードを正しい形式で入力してください。'];

// バリデーション
export const schema = yup.object({
  password1: yup
    .string()
    .required('入力してください')
    .min(8, '8文字以上で入力してください')
    .matches(/(?=.*[a-z])/, '小文字を含めてください。')
    .matches(/(?=.*[A-Z])/, '大文字を含めてください。')
    .matches(/(?=.*[0-9])/, '数字を含めてください。')
    .matches(
      /(?=.*[.?^$*?!@#%&/,><':;|_~`\]})[{(=+-])/,
      '記号を含めてください。'
    )
    .matches(
      /^[0-9a-zA-Z.?^$*?!@#%&/,><':;|_~`\]})[{(=+-]+$/,
      '半額英数字記号のみ入力してください。'
    ),
  password2: yup
    .string()
    .required('入力してください')
    .test(
      'emails-match',
      '入力されたパスワードが一致しません。',
      // eslint-disable-next-line func-names
      function (value) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return value === this.parent.password1;
      }
    ),
});

type FormInputState = {
  password1: string;
  password2: string;
};

type LocationState = {
  loginId: string;
};

export const LoginPasswordChange: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationState;
  const [isError, setIsError] = useState<boolean>(false);
  useHelmetHandler({
    title: 'パスワード再設定 パスワード変更',
  });

  const { handleSubmit, register, watch } = useForm<FormInputState>();

  const onSubmit = async (data: FormInputState) => {
    // 画面遷移
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      await appClient.authorization.authPasswordReset({
        mail: state.loginId,
        newpassword: data.password1,
      });
      navigate(Path.LOGIN_PASSWORD_COMPLETE);
    } catch {
      // エラー
      setIsError(true);
    }
  };

  useEffect(() => {
    if (state !== null && state.loginId === '') {
      navigate(Path.LOGIN_PASSWORD);
    }
  }, [state]);

  return (
    <>
      <Outlet />
      <LoginBox>
        <LoginTitleWithUnderline isLogin title="パスワード変更" />
        <NormalTypography>
          新しいパスワードを入力してください。
        </NormalTypography>
        {isError && <ErrMessageWithIcon errorMessage={errorMessage} isBorder />}
        <LabelWithInput>
          <LoginLabelWithPasswordInput
            label="新しいパスワード"
            error={
              !(
                !watch('password1') ||
                (watch('password1') !== '' &&
                  validatePassword(watch('password1')))
              )
            }
            placeholder="パスワードを入力"
            {...register('password1')}
          />

          {!(
            !watch('password1') ||
            (watch('password1') !== '' && validatePassword(watch('password1')))
          ) && (
            <Typography
              variant="caption/regular"
              color={theme.palette.states.error}
              marginBottom="4px"
            >
              パスワードの形式が正しくありません
            </Typography>
          )}
          <TemporaryWrapper>
            ※半角英数字8桁以上
            <br />
            ※大文字、小文字、数字、記号をそれぞれ1文字以上使用してください。使用可能な記号は以下の通りです
            <br />
            {
              '^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ ` = + -'
            }
            <br />
            ※自身の名前、生年月日、電話番号、連番など推測しやすい文字列は使用しないでください
          </TemporaryWrapper>
        </LabelWithInput>
        <LabelWithInput>
          <LoginLabelWithPasswordInput
            label="新しいパスワード(再入力)"
            error={
              !!(
                watch('password2') && watch('password1') !== watch('password2')
              )
            }
            placeholder="パスワードを入力"
            {...register('password2')}
          />
          {watch('password2') && watch('password1') !== watch('password2') && (
            <Typography
              variant="caption/regular"
              color={theme.palette.states.error}
            >
              入力されたパスワードが一致しません。
            </Typography>
          )}
        </LabelWithInput>
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <PrimaryButton
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSubmit(onSubmit)}
            disabled={
              !watch('password1') ||
              !watch('password2') ||
              watch('password1') !== watch('password2') ||
              watch('password1').length < 8 ||
              !validatePassword(watch('password1'))
            }
          >
            変更する
          </PrimaryButton>
        </Box>
      </LoginBox>
    </>
  );
};
