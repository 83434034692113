/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
// その３

import { styled, useMediaQuery, Theme } from '@mui/material';
import { FC } from 'react';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import type { UserAsset } from '@/types/api/userAsset';
import { Path } from '@/constants/Router/path';
import { AssetsTable } from './AssetsTable';

const BREAKPOINT = 'sm';

const Wrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    [breakpoints.down(BREAKPOINT)]: {
      gap: '16px',
    },
  };
});

const TopWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

const BottomWrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    [breakpoints.down(BREAKPOINT)]: {
      gap: '24px',
    },
  };
});

const TitleWrapper = styled('div')({
  display: 'flex',
  gap: '16px',
  width: '100%',
});

const Title = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography.h3,
    whiteSpace: 'nowrap',
  };
});

const DateComponents = styled('div')(({ theme }) => {
  const { typography, palette } = theme;
  return {
    ...typography['body-main/regular'],
    display: 'flex',
    alignItems: 'flex-end',
    color: palette.system['text-light'],
  };
});

const Descrition = styled('div')(({ theme }) => {
  const { typography, palette } = theme;
  return {
    ...typography['body-sub/regular'],
    color: palette.system['text-light'],
  };
});

interface Props {
  date: string;
  asset: UserAsset;
  investmentDigit: number;
}

export const AssetsStatus: FC<Props> = ({ date, asset, investmentDigit }) => {
  const isSp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(BREAKPOINT)
  );
  const width = isSp ? '100%' : '152px';
  const height = isSp ? '56px' : '40px';
  const borderRadius = isSp ? '28px' : '20px';
  const sx = { width, height, borderRadius };

  const navigate = useNavigate();

  const move = () => {
    navigate(Path.ASSETS);
  };

  const getTargetDate = () => {
    console.log(asset.targetDate);
    if (asset.targetDate) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      return (
        asset.targetDate.replace('-', '').slice(0, 4) +
        '年' +
        Number(asset.targetDate.replace('-', '').slice(4, 6)) +
        '月'
      );
    }
    const today = new Date();
    if (today.getMonth() !== 0) {
      return today.getFullYear() + '年' + today.getMonth() + '月';
    }
    return today.getFullYear() - 1 + '年12月';
  };

  return (
    <Wrapper>
      <TopWrapper>
        <TitleWrapper>
          <Title>資産状況</Title>
          <DateComponents>{date}現在</DateComponents>
        </TitleWrapper>
        <AssetsTable
          stocksNumber={asset.stocksNumber}
          marketValue={asset.marketValue}
          acquisitionAmount={asset.acquisitionAmount}
          valuationGainLoss={asset.valuationGainLoss}
          investmentDigit={investmentDigit}
        />
      </TopWrapper>
      <BottomWrapper>
        <Descrition>
          ※時価評価額は{getTargetDate()}
          の終値、
          {Math.floor(asset.complementaryClosingPrice).toLocaleString()}
          円で計算しています
        </Descrition>
        <PrimaryButton sx={sx} onClick={() => move()}>
          詳しく見る
        </PrimaryButton>
      </BottomWrapper>
    </Wrapper>
  );
};
