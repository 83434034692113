/* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-use-before-define */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { appClient } from '@/services';

import type { User } from '@/types/api/user';

type Context = ReturnType<typeof useProviderMaskoutUser>;

const maskoutUserContext = createContext({} as Context);

export type SecuritiesAccount = {
  branchCode: string;
  branchName: string;
  accountNumber: string;
};

type MaskOutTargetType = 'USER_INFO' | 'SECURITIES_ACCOUNT';

/**
 * useProviderMaskoutUser
 */
const useProviderMaskoutUser = () => {
  const [maskoutUser, setmaskOutUser] = useState<User>({} as User);
  const [maskoutUserSecuritiesAccount, setMaskoutUserSecuritiesAccount] =
    useState<SecuritiesAccount>({} as SecuritiesAccount);

  const [maskoutTarget, setMaskoutTarget] =
    useState<MaskOutTargetType>('USER_INFO');
  const [password, setPassword] = useState<string>('');

  /**
   * getmaskoutApi
   * Maskoutの情報を取得する
   * @param postPassword
   * @param maskOutTargetType
   */
  const getmaskoutApi = async (postPassword: string) => {
    try {
      const response = await appClient.users.findMaskoutInformations({
        password: postPassword,
      });

      if (maskoutTarget === 'USER_INFO') {
        // @ts-ignore
        setmaskOutUser(userModel(response));
      }
      if (maskoutTarget === 'SECURITIES_ACCOUNT') {
        // @ts-ignore
        setMaskoutUserSecuritiesAccount(securitiesAccountModel(response));
      }
      return response;
    } catch (e) {
      console.log(e);
      return e;
    }
  };

  useEffect(() => {
    if (password !== '') {
      getmaskoutApi(password)
        .then((r) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          console.log(r)
        )
        .catch((e) => {
          console.log(e);
          // todo: error handling
        });
    }
  }, [password]);

  /**
   * handleSetMaskoutTarget
   */
  const handleSetMaskoutTarget = (nextLink: string | null) => {
    if (nextLink === 'securities') {
      setMaskoutTarget('SECURITIES_ACCOUNT');
    } else {
      setMaskoutTarget('USER_INFO');
    }
  };

  return {
    maskoutUser,
    maskoutUserSecuritiesAccount,
    password,
    setPassword,
    handleSetMaskoutTarget,
  };
};

/**
 * useMaskoutUser
 */
export const useMaskoutUser = () => useContext(maskoutUserContext);

/**
 * MaskoutUserProvider
 * @param children
 * @constructor
 */
export const MaskoutUserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const maskoutUser = useProviderMaskoutUser();
  return (
    <maskoutUserContext.Provider value={maskoutUser}>
      {children}
    </maskoutUserContext.Provider>
  );
};

/**
 * userModel
 * @param data
 */
export const userModel = (data: {
  memberId?: number;
  userId?: string;
  email?: string;
  associationCd?: string;
  memberCd?: string;
  userStatus?:
    | 'VARIFIED_MAIL'
    | 'PERSONAL_INFORMATION_ENTERED'
    | 'BEING_ENROLLMENT_PROCESSED'
    | 'NORMAL'
    | 'BEING_RECESSMENT_PROCESSED'
    | 'RECESS'
    | 'BEING_RESUMEMENT_PROCESSED'
    | 'BEING_WITHDRAWAL_PROCESSED'
    | 'WITHDRAWN'
    | 'DEREGISTRATION'
    | 'REJECT_ENROLLMENT'
    | 'BAN';
  registeredType?: 'WEB' | 'STAR';
  companyCd?: string;
  officeCd?: string;
  affiliationCd?: string;
  employeeCode?: string;
  nameKanji?: string;
  nameKana?: string;
  birthday?: string;
  zipcode?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  tel?: string;
  telType?: 'MOBILE' | 'HOME';
  joinDt?: string;
  withdrawalDt?: string;
}) =>
  ({
    memberId: data.memberId || null,
    userId: data.userId || '',
    email: data.email || '',
    associationCd: data.associationCd || '',
    memberCd: data.memberCd || '',
    userStatus: data.userStatus || '',
    companyCd: data.companyCd || '',
    officeCd: data.officeCd || '',
    affiliationCd: data.affiliationCd || '',
    employeeCd: data.employeeCode || '',
    nameKanji: data.nameKanji || '',
    nameKana: data.nameKana || '',
    birthday: data.birthday || '',
    zipcode: data.zipcode || '',
    address1: data.address1 || '',
    address2: data.address2 || '',
    address3: data.address3 || '',
    address4: data.address4 || '',
    telType: data.telType || '',
    tel: data.tel || '',
    joinDt: data.joinDt || null,
    withdrawalDt: data.withdrawalDt || null,
  } as User);

export const securitiesAccountModel = (data: {
  branchCode?: string;
  branchName?: string;
  accountNumber?: string;
}) =>
  ({
    branchCode: data.branchCode || '',
    branchName: data.branchName || '',
    accountNumber: data.accountNumber || '',
  } as SecuritiesAccount);
