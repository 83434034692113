import { styled, useMediaQuery, Theme, Link, Box } from '@mui/material';
import { FC } from 'react';
import { services } from '@/constants/Services/info';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';

const BREAKPOINT = 'sm';
const ServicesWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const ServiceWrapper = styled('div')(({ theme }) => {
  const { palette, breakpoints } = theme;
  return {
    display: 'flex',
    gap: '24px',
    padding: '24px',
    backgroundColor: palette.system.main,
    borderRadius: '12px',
    [breakpoints.down(BREAKPOINT)]: {
      gap: '16px',
      flexDirection: 'column',
    },
  };
});

const ImgWrapper = styled(Link)(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '30%',
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
    },
  };
});

const Img = styled('img')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '100%',
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
    },
  };
});

const RightInfo = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '70%',
    justifyContent: 'space-between',
    [breakpoints.down(BREAKPOINT)]: {
      gap: '16px',
      width: '100%',
    },
  };
});

const Title = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography.h5,
    width: '100%',
  };
});

const Description = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-sub/regular'],
    textAlign: 'left',
  };
});

const Button = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    minWidth: '120px',
    [breakpoints.down(BREAKPOINT)]: {
      width: '100%',
    },
  };
});
export const Services: FC = () => {
  const isSp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(BREAKPOINT)
  );
  const width = isSp ? '100%' : '120px';
  const height = isSp ? '56px' : '40px';
  const borderRadius = isSp ? '28px' : '20px';
  const sx = { width, height, borderRadius };

  return (
    <ServicesWrapper>
      {services.map((service) => (
        <ServiceWrapper key={service.name}>
          <ImgWrapper
            href={service.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img src={service.img} />
          </ImgWrapper>
          <RightInfo>
            <Box display="flex" gap="8px" flexDirection="column">
              <Title>{service.name}</Title>
              <Description>
                {service.description.map((i) => (
                  <span key={i}>
                    {i}
                    <br />
                  </span>
                ))}
              </Description>
            </Box>
            <Box
              width="100%"
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button>
                <Link
                  target="_blank"
                  href={service.link}
                  sx={{ width: '100%' }}
                >
                  <PrimaryButton size="large" sx={sx}>
                    詳しく見る
                  </PrimaryButton>
                </Link>
              </Button>
            </Box>
          </RightInfo>
        </ServiceWrapper>
      ))}
    </ServicesWrapper>
  );
};
