import { Box, styled, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { SignUpContributionProps as Props } from '@/types/components/SignUpContribution';
import { BREAKPOINT } from '@/theme/theme';
// import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { SignUpUpper } from './SignUpUpper';

export const SignUpContributionOrganism: FC<Props> = ({ children }) => (
  <Box
    sx={{
      bgcolor: 'system.background-dark',
      padding: {
        xs: '0 0 60px',
        sm: '0 40px 60px',
        md: '0 40px 60px',
        lg: '0 130px 60px',
      },
    }}
  >
    <Box sx={{ maxWidth: '780px', margin: '0 auto' }}>
      <SignUpUpper isSignUp flowOn={3} />
      {children}
    </Box>
  </Box>
);

export const Title: FC<{ isPc: boolean }> = ({ isPc }) => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '16px',
      mb: '16px',
    }}
  >
    <Typography
      variant="h3"
      sx={{
        width: '100%',
        color: 'system.text-normal',
        pb: isPc ? '0' : '16px',
        borderBottom: isPc ? '0' : '1px solid',
        borderColor: 'system.separator-light',
      }}
    >
      拠出設定
    </Typography>
    <Typography
      variant="body-main/regular"
      sx={{ color: 'system.text-normal' }}
    >
      給与(毎月の拠出)と賞与の積立口数を設定します。
    </Typography>
  </Box>
);

export const Row: FC<{ children: ReactNode }> = ({ children }) => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      alignItems: 'flex-start',
      pb: '16px',
      borderBottom: '1px solid',
      borderColor: 'system.separator-light',
    }}
  >
    {children}
  </Box>
);

export const Label: FC<{ children: ReactNode }> = ({ children }) => (
  <Typography
    variant="body-main/bold"
    sx={{
      color: 'system.text-normal',
      width: '144px',
    }}
  >
    {children}
  </Typography>
);

export const SubLabel: FC<{ children: ReactNode }> = ({ children }) => (
  <Typography
    variant="body-sub/regular"
    sx={{
      color: 'system.text-normal',
      width: '72px',
    }}
  >
    {children}
  </Typography>
);

export const Value: FC<{ children: ReactNode }> = ({ children }) => (
  <Typography
    sx={{
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '150%',
      color: 'system.text-normal',
    }}
  >
    {children} 円
  </Typography>
);

export const MuiBox = styled(Box)(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down(BREAKPOINT)]: {
      display: 'block',
    },
  };
});
