import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
  /**
   * 項目のラベル
   */
  label: string | ReactNode;
  /**
   * 項目のラベル
   */
  subLabel?: string;
  /**
   * 項目の値
   */
  value: string | number;
  /**
   * 項目の値
   */
  subValue?: string | number;
  /**
   * 下線の有無
   */
  underline: boolean;
  /**
   * カラム数
   */
  column?: 1 | 2;
  /**
   * PCかどうか
   */
  isPc?: boolean;
}

interface DlProps {
  /**
   * 子コンポーネント(dt & dd)
   */
  children: ReactNode;
}

interface DtProps {
  /**
   * 項目のラベル
   */
  subLabel?: string;
  /**
   * 項目のラベル
   */
  children: ReactNode;
}

interface DdProps {
  /**
   * 項目の値
   */
  subValue?: string | number;
  /**
   * 項目の値
   */
  children: ReactNode;
}

const Dd: FC<DdProps> = ({ subValue, children }) => {
  if (subValue) {
    return (
      <Typography
        component="dd"
        variant="body-sub/regular"
        sx={{ color: 'system.text-normal' }}
      >
        <Typography
          component="p"
          variant="body-sub/regular"
          sx={{ color: 'system.text-normal' }}
        >
          {children}
        </Typography>
        <Typography
          component="p"
          variant="body-sub/regular"
          sx={{ color: 'system.text-normal' }}
        >
          {subValue}
        </Typography>
      </Typography>
    );
  }
  return (
    <Typography
      component="dd"
      variant="body-sub/regular"
      sx={{ color: 'system.text-normal', wordBreak: 'break-all' }}
    >
      {children}
    </Typography>
  );
};

const Dt: FC<DtProps> = ({ subLabel, children }) => {
  if (subLabel) {
    return (
      <Typography
        component="dt"
        variant="body-sub/regular"
        sx={{ color: 'system.text-light', minWidth: '104px' }}
      >
        <Typography
          component="p"
          variant="body-sub/regular"
          sx={{ color: 'system.text-light' }}
        >
          {children}
        </Typography>
        <Typography
          component="p"
          variant="caption/regular"
          sx={{ color: 'system.text-light' }}
        >
          {subLabel}
        </Typography>
      </Typography>
    );
  }
  return (
    <Typography
      component="dt"
      variant="body-sub/regular"
      sx={{ color: 'system.text-light', minWidth: '104px' }}
    >
      {children}
    </Typography>
  );
};

const Dl: FC<DlProps> = ({ children }) => (
  <Box
    component="dl"
    sx={{
      width: '100%',
      minWidth: '343px',
      margin: 0,
      display: 'flex',
      gap: '8px',
    }}
  >
    {children}
  </Box>
);

export const MypageDlContainer: FC<Props> = (props) => {
  const {
    label,
    value,
    underline,
    subValue,
    subLabel,
    column = 1,
    isPc,
  } = props;

  return (
    <Box
      sx={{
        width: column === 2 && isPc ? '49%' : '100%',
        minHeight: '30px',
        pb: '8px',
        flexGrow: 1,
        borderBottom: underline ? 1 : 0,
        borderColor: 'system.separator-light',
      }}
    >
      <Dl>
        <Dt subLabel={subLabel}>{label}</Dt>
        <Dd subValue={subValue}>{value}</Dd>
      </Dl>
    </Box>
  );
};
