import { styled, Typography } from '@mui/material';
import { FC, useState } from 'react';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails, {
  AccordionDetailsProps,
} from '@mui/material/AccordionDetails';
import { ReactComponent as PlusButton } from '@/assets/plusButton.svg';
import { ReactComponent as MinusButton } from '@/assets/minusButton.svg';
import { BREAKPOINT } from '@/theme/theme';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    sx={{
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    }}
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => {
  const { breakpoints, palette } = theme;
  return {
    background: palette.system.background,
    borderRadius: '12px',
    margin: '0 0 8px 0',
    padding: '12px 16px',
    '&:before': {
      display: 'none',
    },
    [breakpoints.down(BREAKPOINT)]: {
      padding: '0 16px',
    },
  };
});

const AccordionSummaryOpen = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<MinusButton />} {...props} />
))({
  flexDirection: 'row',
  gap: '24px',
  padding: '0px',
});

const AccordionSummaryClose = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<PlusButton />} {...props} />
))({
  flexDirection: 'row',
  gap: '24px',
  padding: '0px',
});

const AccordionDetails = styled((props: AccordionDetailsProps) => (
  <MuiAccordionDetails {...props} />
))(() => ({
  padding: '0px',
  marginLeft: '16px',
}));

const QuestionText = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-sub/bold'],
  };
});

const AnswerTextsWrap = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

const AnswerTextWrap = styled('div')(({ theme }) => {
  const { typography } = theme;
  return {
    ...typography['body-sub/regular'],
  };
});

interface Question {
  question: string;
  answer: string;
}
interface Props {
  question: Question;
}

export const AccordionBox: FC<Props> = ({ question }) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      event.preventDefault();
      setExpanded(newExpanded ? panel : false);
    };
  return (
    <Accordion
      expanded={expanded === question.question}
      onChange={handleChange(question.question)}
    >
      {expanded === question.question ? (
        <AccordionSummaryOpen>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <QuestionText>{question.question}</QuestionText>
          </Typography>
        </AccordionSummaryOpen>
      ) : (
        <AccordionSummaryClose>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <QuestionText>{question.question}</QuestionText>
          </Typography>
        </AccordionSummaryClose>
      )}
      <AccordionDetails>
        <Typography
          sx={{
            display: 'flex',
            gap: '8px',
            paddingRight: '16px',
            alignItems: 'start',
            paddingBottom: '19px',
          }}
        >
          <AnswerTextsWrap>
            <AnswerTextWrap>{question.answer}</AnswerTextWrap>
          </AnswerTextsWrap>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
