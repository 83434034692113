import { FC, useEffect } from 'react';
import {
  styled,
  Box,
  Stack,
  useMediaQuery,
  Typography,
  Divider,
  Link,
} from '@mui/material';
import { HomeHeader } from '@/components/organism/HomeHeader';
import { ReactComponent as Img1Pc } from '@/assets/img1pc.svg';
import { ReactComponent as Img1Sp } from '@/assets/img1sp.svg';
import { ReactComponent as Img2Pc } from '@/assets/img2pc.svg';
import { ReactComponent as Img2Sp } from '@/assets/img2sp.svg';
import { ReactComponent as CheckRed } from '@/assets/checkRed.svg';
import Img3Pc from '@/assets/img3pc.png';
import Img3Sp1 from '@/assets/img3sp1.png';
import Img3Sp2 from '@/assets/img3sp2.png';
import { theme, BREAKPOINT } from '@/theme/theme';
import { Path } from '@/constants/Router/path';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';

const TopWrapper = styled(Box)({
  width: '100%',
  padding: '33px 40px 42px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  backgroundColor: theme.palette.system.background,
  [theme.breakpoints.down(BREAKPOINT)]: {
    padding: '24px 16px',
    gap: '16px',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '33px 130px 42px',
  },
});

const BottomWrapper = styled(Stack)({
  gap: '32px',
  padding: '0 40px 120px',
  backgroundColor: theme.palette.system.background,
  width: '100%',
  [theme.breakpoints.down(BREAKPOINT)]: {
    padding: '0 0 60px',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '0 130px 120px',
  },
});

const ColumWrapper = styled(Stack)({
  backgroundColor: theme.palette.system.white,
  padding: '32px',
  gap: '32px',
  width: '100%',
  borderRadius: '12px',
  [theme.breakpoints.down(BREAKPOINT)]: {
    padding: '32px 16px',
    borderRadius: '0px',
  },
});

const SubTitle = styled(Box)({
  fontSize: '20px',
  fontWeight: 500,
  paddingBottom: '13px',
  borderBottom: '1px solid',
  borderColor: theme.palette.primary.normal,
  width: '100%',
});

const MuiBox = styled(Box)({
  display: 'flex',
  backgroundColor: theme.palette.system.background,
  borderRadius: '4px',
  padding: '16px',
  gap: '16px',
  [theme.breakpoints.down(BREAKPOINT)]: {
    gap: '8px',
    flexDirection: 'column',
  },
});

const Title = styled(Box)({
  ...theme.typography['body-sub/regular'],
  width: '127px',
});

const Value = styled(Box)({
  ...theme.typography['body-sub/bold'],
});

const TableBox = styled(Stack)({
  border: '1px solid',
  borderColor: theme.palette.system['separator-light'],
  borderRadius: '4px',
  [theme.breakpoints.down(BREAKPOINT)]: {
    borderRadius: '12px',
  },
});

const TableHead = styled(Box)({
  display: 'flex',
  [theme.breakpoints.down(BREAKPOINT)]: {
    flexDirection: 'column',
  },
});

const HeadCell = styled(Box)({
  display: 'flex',
  gap: '16px',
  padding: '16px',
  backgroundColor: '#EFFBFC',
  width: '100%',
  [theme.breakpoints.down(BREAKPOINT)]: {
    justifyContent: 'space-between',
  },
});

const TableRow = styled(Box)({
  display: 'flex',
  width: '100%',
  [theme.breakpoints.down(BREAKPOINT)]: {
    flexDirection: 'column',
  },
});

const RowCell = styled(Stack)({
  display: 'flex',
  padding: '16px',
  width: '100%',
  alignItems: 'flex-end',
  [theme.breakpoints.down(BREAKPOINT)]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '21px 16px',
  },
});

const MuiTypography = styled(Typography)({
  lineHeight: '180%',
});

const Img = styled('img')({
  width: '100%',
});

export const Structure: FC = () => {
  useHelmetHandler({
    title: '持株会の仕組み',
  });
  const isSp = useMediaQuery(() => theme.breakpoints.down(BREAKPOINT));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TopWrapper>
        <Box width="100%" display="flex">
          <HomeHeader
            title="持株会のしくみ"
            description={[
              '持株会のしくみや、持株会投資のメリット・デメリットなどについて学べます。',
            ]}
            links={[
              { path: 'TOP', to: '/' },
              { path: '持株会のしくみ', to: '/structure' },
            ]}
          />
        </Box>
      </TopWrapper>
      <BottomWrapper>
        <ColumWrapper>
          <MuiTypography variant="h4">従業員持株会について</MuiTypography>
          <Stack gap="12px">
            <SubTitle>従業員持株会とは</SubTitle>
            <MuiTypography>
              従業員持株会は、従業員が自分の勤めている企業の株式を定期的に購入し、従業員の中長期的な資産形成を支援する制度です。
              （持株会には従業員持株会の他に、役員を対象にした役員持株会・取引先持株会などがあります。）
            </MuiTypography>
          </Stack>
          <Stack gap="12px">
            <SubTitle>従業員持株会のしくみ</SubTitle>
            <MuiTypography>
              従業員持株会は民法に基づいて設立される組合で、従業員の資産形成を支援する制度です。
              <br />
              従業員持株会は、給与もしくは賞与から天引きされた従業員一人一人の投資金額をまとめ、窓口となって自社株を購入しています。
            </MuiTypography>
          </Stack>
          <Stack width="100%" alignItems="center">
            {isSp ? <Img1Sp width="100%" /> : <Img1Pc width="100%" />}
          </Stack>
        </ColumWrapper>
        <ColumWrapper>
          <MuiTypography variant="h4">
            持株会のメリット・デメリットについて
          </MuiTypography>
          <Stack gap="12px">
            <SubTitle>メリット</SubTitle>
            <Stack spacing={3}>
              <Box>
                <Box display="flex" gap="8px" alignItems="center">
                  <CheckRed />
                  <MuiTypography variant="body-main/bold">
                    奨励金がある
                  </MuiTypography>
                </Box>
                <MuiTypography>
                  会社からの補助を「奨励金」といいます。
                  <br />
                  持株会によって異なりますが、毎月の拠出金に対して10％の「奨励金」がもらえるケースもあります。近年の低金利の状況では、持株会も有効な運用先になります。
                </MuiTypography>
              </Box>
              <Box>
                <Box display="flex" gap="8px" alignItems="center">
                  <CheckRed />
                  <MuiTypography variant="body-main/bold">
                    配当金がある
                  </MuiTypography>
                </Box>
                <MuiTypography>
                  会社の業績により持株会が購入した、持株会の中で保有している株式も配当金がもらえます。
                  <br />
                  ただし、配当金は原則自動的に次に株式を購入する資金（再投資）になります。（現金での支給はありません）
                </MuiTypography>
              </Box>
              <Box>
                <Box display="flex" gap="8px" alignItems="center">
                  <CheckRed />
                  <MuiTypography variant="body-main/bold">
                    給与天引きで手間がかからない
                  </MuiTypography>
                </Box>
                <MuiTypography>
                  持株会なら一度手続きすれば自動的に給与（賞与）から拠出できるので、手間がかかりません。
                </MuiTypography>
              </Box>
              <Box>
                <Box display="flex" gap="8px" alignItems="center">
                  <CheckRed />
                  <MuiTypography variant="body-main/bold">
                    ドルコスト平均法が使える
                  </MuiTypography>
                </Box>
                <MuiTypography>
                  ドルコスト平均法とは、毎月決まった日に決まった金額で株式を購入し続けることで平均取得単価を下げる方法です。
                  <br />
                  株価の高い月は購入株数が少なく、逆に株価の低い月は購入株数が多くなります。持株会でも同じ効果が期待できます。
                </MuiTypography>
              </Box>
              {isSp ? (
                <Box display="flex" width="100%">
                  <Img2Sp width="100%" />
                </Box>
              ) : (
                <Stack
                  spacing={1}
                  p={2}
                  border="1px solid"
                  borderRadius="12px"
                  borderColor={theme.palette.system['separator-light']}
                >
                  <MuiTypography variant="body-sub/bold">
                    ※ドルコスト平均法とは
                  </MuiTypography>
                  <MuiTypography variant="body-sub/regular">
                    ＜ドルコスト平均法のメリット＞
                    <br />
                    購入価格を平準化することで結果的に大幅な損失を回避できる。
                  </MuiTypography>
                  <MuiTypography variant="body-sub/regular">
                    ＜ドルコスト平均法のデメリット＞ <br />・
                    ドルコスト平均法は長期的に投資を続けることで利益が出せるように設計されている。
                    <br />・
                    そのため、短期的な売買で大きな利益を得たい場合には向いていない。
                  </MuiTypography>
                  <MuiTypography variant="body-sub/regular">
                    購入価格を一定に保つことで以下の効果が得られます。
                    <br /> ・ 価格が低い時、購入量（株数）が増加
                    <br /> ・ 価格が高い時、購入量（株数）は減少
                  </MuiTypography>
                  <MuiTypography variant="body-sub/regular">
                    一方、価格に関係なく常に一定の量（株数）を購入していくと毎回違った金額で購入となります。
                    <br />
                    ドルコスト平均法による購入方法は全体の平均購入単価を平準化させる効果があり、長期的な資産形成を行っていく上で有効な方法の一つと考えられます。
                    <br />
                    ※ドルコスト平均法は将来の収益を約束したり、相場下落時における損失を防止するものではありません。
                  </MuiTypography>
                  <Box>
                    <Img2Pc width="100%" />
                  </Box>
                </Stack>
              )}
              <Box>
                <Box display="flex" gap="8px" alignItems="center">
                  <CheckRed />
                  <MuiTypography variant="body-main/bold">
                    少額から株を取得できる
                  </MuiTypography>
                </Box>
                <MuiTypography>
                  通常、最低単元（100株）の株を買うには数万円～数十万円と大きなお金が必要です。持株会は1,000円から参加できるので、気軽にはじめられます。
                </MuiTypography>
              </Box>
              <Box>
                <Box display="flex" gap="8px" alignItems="center">
                  <CheckRed />
                  <MuiTypography variant="body-main/bold">
                    会社の成長により株価アップが期待できる
                  </MuiTypography>
                </Box>
                <MuiTypography>
                  自分達の頑張りにより業績がアップし、ひいては株価もアップした場合、積立した金額よりも大きな利益を得る事ができる可能性があります。
                </MuiTypography>
              </Box>
            </Stack>
          </Stack>
          <Stack gap="12px">
            <SubTitle>デメリット</SubTitle>
            <Stack spacing={3}>
              <Box>
                <MuiTypography variant="body-main/bold">
                  ・ 給与と投資先のリスク集中
                </MuiTypography>
                <MuiTypography>
                  持株会最大のデメリットは、給与と投資先が同一になることでリスクが集中することです。
                  <br />
                  多くの会社員にとって、収入の大半を占めるのが給与でしょう。その給与で自社株を買うと、給与と投資先が同一になり、リスクが集中することになります。
                  <br />
                  会社の業績が悪化したら、収入だけでなく資産までダメージを受けてしまうかもしれませんので、リスクが過度に集中しないよう、持株会は投資先のひとつとして考え、ほかの投資先にも資産を分散することをおすすめします。
                </MuiTypography>
              </Box>
              <Box>
                <MuiTypography variant="body-main/bold">
                  ・ 現金化（売却）に時間がかかる
                </MuiTypography>
                <MuiTypography>
                  通常の株式の売買と違い、持株会で購入した株式を売却するには時間がかかります。
                </MuiTypography>
              </Box>
              <Box>
                <MuiTypography variant="body-main/bold">
                  ・ 株価が上がるとは限らない
                </MuiTypography>
                <MuiTypography>
                  どの投資にもいえることですが、プラスになるとは限りません。10％の奨励金がついているにもかかわらず、株価によっては、元本割れするケースもあります。
                </MuiTypography>
              </Box>
              <Box>
                <MuiTypography variant="body-main/bold">
                  ・ 株主優待がもらえない
                </MuiTypography>
                <MuiTypography>
                  株主優待は株式投資の楽しみの一つですが、持株会の中で保有している株式については株主優待がもらえません。（個人名義に変更後は優待を受けられます）
                </MuiTypography>
              </Box>
            </Stack>
          </Stack>
        </ColumWrapper>
        <ColumWrapper>
          <MuiTypography variant="h4">
            例を基に持株会投資を学んでみましょう！
          </MuiTypography>
          <Stack gap="12px">
            <SubTitle>投資シミュレーション例</SubTitle>
            <MuiBox>
              <Stack spacing={1} width="100%">
                <Box display="flex" gap="16px">
                  <Title>銘柄</Title>
                  <Value>8616</Value>
                  <Value>東海東京フィナンシャル・ホールディングス</Value>
                </Box>
                <Box display="flex" gap="16px">
                  <Title>期間</Title>
                  <Value>1997年1月～2022年3月</Value>
                </Box>
                <Box display="flex" gap="16px">
                  <Title>毎月の積立額</Title>
                  <Value>10,000円</Value>
                </Box>
              </Stack>
              <Stack spacing={1} width="100%">
                <Box display="flex" gap="16px">
                  <Title>ボーナス時の積立額</Title>
                  <Value>20,000円</Value>
                </Box>
                <Box display="flex" gap="16px">
                  <Title>奨励金率</Title>
                  <Value>10%</Value>
                </Box>
              </Stack>
            </MuiBox>
            {!isSp && (
              <Stack spacing={1} alignItems="flex-end">
                <MuiTypography variant="caption/bold">
                  ※指定期間の投資成果は、過去の月別の平均株価を元にシミュレーションしております。
                </MuiTypography>
                <MuiTypography variant="caption/regular">
                  ※シミュレーションのため正確な積立額とは多少異なります。
                </MuiTypography>
              </Stack>
            )}
            <TableBox>
              <TableHead>
                <HeadCell
                  sx={{
                    borderTopRightRadius: isSp ? '12px' : '0px',
                    borderTopLeftRadius: isSp ? '12px' : '4px',
                  }}
                >
                  <MuiTypography variant="body-main/medium">
                    拠出金累計 (積立合計金額)
                  </MuiTypography>
                  <MuiTypography fontSize="26px" fontWeight={500}>
                    4,030,000円
                  </MuiTypography>
                </HeadCell>

                {isSp ? (
                  <Divider />
                ) : (
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      backgroundColor: theme.palette.system['separator-light'],
                    }}
                  />
                )}
                <HeadCell sx={{ borderTopRightRadius: isSp ? '0px' : '4px' }}>
                  <MuiTypography variant="body-main/medium">
                    積立期間終了時点の評価額
                  </MuiTypography>
                  <Stack alignItems="flex-end">
                    <MuiTypography fontSize="26px" fontWeight={500}>
                      9,197,097円
                    </MuiTypography>
                    <Box display="flex" gap="8px">
                      <MuiTypography variant="body-sub/medium">
                        差額
                      </MuiTypography>
                      <MuiTypography
                        variant="body-sub/medium"
                        color={theme.palette.states.error}
                      >
                        5,167,097円(+228.22%)
                      </MuiTypography>
                    </Box>
                  </Stack>
                </HeadCell>
                {isSp && (
                  <Stack spacing={1} p={2} sx={{ backgroundColor: '#EFFBFC' }}>
                    <MuiTypography
                      variant="caption/regular"
                      color={theme.palette.system['text-light']}
                    >
                      {' '}
                      ※指定期間の投資成果はXXXX年X月の月中平均、0,000円で計算しています。
                    </MuiTypography>
                    <MuiTypography
                      variant="caption/bold"
                      color={theme.palette.system['text-light']}
                    >
                      {' '}
                      ※指定期間の投資成果は、過去の月別の平均株価を元にシミュレーションしております。
                    </MuiTypography>
                  </Stack>
                )}
              </TableHead>
              <Divider />
              <TableRow>
                <RowCell>
                  <MuiTypography
                    variant="body-sub/medium"
                    width={isSp ? '50%' : '100%'}
                  >
                    奨励金累計
                  </MuiTypography>
                  <MuiTypography fontWeight={400} fontSize="20px">
                    403,000円
                  </MuiTypography>
                </RowCell>
                {isSp ? (
                  <Divider />
                ) : (
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      backgroundColor: theme.palette.system['separator-light'],
                    }}
                  />
                )}
                <RowCell>
                  <MuiTypography
                    variant="body-sub/medium"
                    width={isSp ? '50%' : '100%'}
                  >
                    配当金累計
                  </MuiTypography>
                  <MuiTypography fontWeight={400} fontSize="20px">
                    4,194,097円
                  </MuiTypography>
                </RowCell>
                {isSp ? (
                  <Divider />
                ) : (
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      backgroundColor: theme.palette.system['separator-light'],
                    }}
                  />
                )}
                <RowCell>
                  <MuiTypography
                    variant="body-sub/medium"
                    width={isSp ? '50%' : '100%'}
                  >
                    積立額累計
                  </MuiTypography>
                  <MuiTypography fontWeight={400} fontSize="20px">
                    8,627,097円
                  </MuiTypography>
                </RowCell>
                {isSp ? (
                  <Divider />
                ) : (
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      backgroundColor: theme.palette.system['separator-light'],
                    }}
                  />
                )}
                <RowCell>
                  <MuiTypography
                    variant="body-sub/medium"
                    width={isSp ? '50%' : '100%'}
                  >
                    取得株数
                  </MuiTypography>
                  <MuiTypography fontWeight={400} fontSize="20px">
                    22,821.59株
                  </MuiTypography>
                </RowCell>
              </TableRow>
            </TableBox>
            {isSp ? (
              <Stack gap="12px">
                <Img src={Img3Sp1} />
                <Img src={Img3Sp2} />
              </Stack>
            ) : (
              <Img src={Img3Pc} />
            )}
          </Stack>
          <Stack gap="12px">
            <SubTitle>シミュレーション内容の読み解き</SubTitle>
            <Stack spacing={3}>
              <MuiTypography>
                上記が東海東京証券の持株会で積み立てたときのシミュレーション例です。
                <br />
                見ていただくと分かるように、奨励金は拠出金に対し10％の支給があり近年の低金利の状況と比較すると、魅力的です。それと同時に、シミュレーション例では配当金が多く支払われていることがわかるかと思います。
                <br />
                このように、奨励金も大きいですが、長期的な視点でみると（配当金が支払われる会社の場合）配当金を再投資することで得られる株が積み上がり、配当金額も大きなものとなることがあります。
                <br />
                （※配当金額には会社ごとに大きく違うので自社の配当金をご確認ください。）
              </MuiTypography>
              <MuiTypography>
                しかし奨励金の利回りの高さは魅力的ですが、従業員持株会制度を活用するにあたっては注意すべきこともあります。
                <br />
                一般的に株式は、売買高が極端に少ない銘柄でなければ、取引所ですぐに売却し、原則3営業日目には現金化できます。
                <br />
                しかし、従業員持株会制度で保有している株式の場合、自社内で担当する部署や役職によっては売却に制限がかかることがあり、また決算発表前の一定期間は売却ができない期間が設けられている場合があるため、自分の売りたいタイミングですぐに売却できない可能性があります。
              </MuiTypography>
              <MuiTypography>
                その他にも持株会の株式を売却のために引き出すためには東海東京証券の証券口座が必要になります。証券口座がなくて売れない！ということもあるので、早いうちに開設しておくことをオススメします。
                <br />
                ＊東海東京証券の証券口座は引出しや退会の際に必須になります。
              </MuiTypography>
              <Box>
                <Link
                  href={Path.ACCOUNTING_OPEN}
                  sx={{
                    color: theme.palette.system.link,
                    textDecorationColor: theme.palette.system.link,
                  }}
                  target="_blank"
                >
                  インターネット口座開設はこちら
                </Link>
              </Box>
              <MuiTypography>
                株価は企業業績にも影響を受けます。
                <br />
                株価が低い時には拠出金累計と評価額があまり変わらないときもありますが、2022年度3月の株価427円と株価が1000円近くあったときを比べると、投資期間が長いにも関わらず2022年度3月の評価額は拠出金累計の2倍程度ですが、株価が1000円近いときには評価額は拠出金累計の4倍程度あったことが分かります。
                <br />
                いくら従業員持株会に奨励金や配当金があったとしても自社の株価の状況がかなり重要になります。
              </MuiTypography>
              <MuiTypography>
                万が一、自社の業績が悪化した場合、自社の株価が下がって自分の資産が目減りするのと同時に、給与や賞与の減額などの可能性もあります。
                <br />
                奨励金は確かに魅力的ですが、従業員持株会制度での自社株購入は個別銘柄への一極集中投資になってしまうという点ではリスクが高いといえます。社内預金などがあればそちらも利用しておくなどの資産のバランスも考えましょう。
                株式投資においては、分散投資をすることが重要です。
                <br />
                例えば、自社が輸出メインの企業であれば輸入メインの企業を、自社が新興IT企業ならば老舗企業を、というように、業種やサービスが自社とはなるべく異なる企業、つまり値動きが違う企業の株式を購入することもリスク分散につながります。
              </MuiTypography>
              <MuiTypography>
                リスク分散のためにも、ぜひバランスの良い投資をすることを考えましょう。
              </MuiTypography>
            </Stack>
          </Stack>
        </ColumWrapper>
      </BottomWrapper>
    </>
  );
};
