import { Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { PrimaryTable } from '@/components/molecules/Table/PrimaryTable';
import {
  PrimaryTableBodyTdMulti,
  PrimaryTableBodyTdSub,
} from '@/components/atoms/Table/PrimaryTableBodyTdMulti';
import { PrimaryTableBodyTdSingle } from '@/components/atoms/Table/PrimaryTableBodyTdSingle';
import { PrimaryTableBodyTdSingleWithSpNotRed } from '@/components/atoms/Table/PrimaryTableBodyTdSingleWithSpNotRed';
import { appClient } from '@/services';
import { ConvertAssociationContribution } from '@/services/convert/associationContribution';
import { AssociationContribution } from '@/types/api/associationContribution';
import useSWR from 'swr';
import { numberFormat } from '@/utils/numberFormat';
import { FindApplicationById } from '@/types/api/applications';
import { BountyCalculation } from '@/utils/calculation';

interface Props {
  applicationBonusUnit: FindApplicationById['applicationBonusUnit'];
}

export const Bonus: FC<Props> = ({ applicationBonusUnit }) => {
  const [associationContribution, setAssociationContribution] =
    useState<AssociationContribution>();

  // 【API-A-0909】資産情報取得
  // const { user } = useAuthUser();

  // 【API-A-1002】持株会（事務局）拠出情報取得
  const { data: findAssociationContribution } = useSWR(
    '/api/associations/{associationCd}/contributions',
    () => appClient.associations.findAssociationContribution()
  );

  useEffect(() => {
    setAssociationContribution(
      ConvertAssociationContribution(findAssociationContribution)
    );
  }, [findAssociationContribution]);

  if (associationContribution) {
    return (
      <PrimaryTable title="新たな口数設定(賞与)">
        <PrimaryTableBodyTdSingleWithSpNotRed
          title="1口あたり金額"
          value={`${numberFormat(associationContribution.unitAmount)}円`}
        />
        <PrimaryTableBodyTdSingleWithSpNotRed
          title="奨励金率"
          value={`${associationContribution.monthlyIncentiveRatio}%`}
        />
        <PrimaryTableBodyTdMulti title="給与" subTitle="*毎月の拠出" row={4}>
          <PrimaryTableBodyTdSub title="拠出">
            <Typography>
              {applicationBonusUnit?.isBonusUnit ? 'する' : 'しない'}
            </Typography>
          </PrimaryTableBodyTdSub>
        </PrimaryTableBodyTdMulti>
        {applicationBonusUnit?.isBonusUnit && (
          <>
            <PrimaryTableBodyTdSingle>
              <PrimaryTableBodyTdSub title="口数">
                <Typography>{applicationBonusUnit.bonusUnit}株</Typography>
              </PrimaryTableBodyTdSub>
            </PrimaryTableBodyTdSingle>
            <PrimaryTableBodyTdSingle>
              <PrimaryTableBodyTdSub title="拠出金額">
                <Typography>
                  {numberFormat(
                    associationContribution.unitAmount *
                      (applicationBonusUnit?.bonusUnit ?? 0)
                  )}
                  円
                </Typography>
              </PrimaryTableBodyTdSub>
            </PrimaryTableBodyTdSingle>
            <PrimaryTableBodyTdSingle>
              <PrimaryTableBodyTdSub title="奨励金額">
                <Typography>
                  {numberFormat(
                    BountyCalculation(
                      'BONUS',
                      associationContribution,
                      applicationBonusUnit?.bonusUnit ?? 0
                    ),
                    { maximumFractionDigits: 0 },
                    true
                  )}
                  円
                </Typography>
              </PrimaryTableBodyTdSub>
            </PrimaryTableBodyTdSingle>
          </>
        )}
      </PrimaryTable>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
