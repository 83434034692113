import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { BREAKPOINT } from '@/theme/theme';
import {
  Box,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  Stack,
} from '@mui/material';
import { FC } from 'react';

const styleContainer: SxProps<Theme> = [
  {
    width: '343px',
    // height: '184px',
    textAlign: 'center',
    p: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
  },
  (theme) => ({
    [theme.breakpoints.up(BREAKPOINT)]: {
      width: '100%',
      // height: '174px',
      p: '32px',
    },
  }),
];

interface LogoutProps {
  handleClickLogout: () => void;
}

export const SessionOut: FC<LogoutProps> = ({ handleClickLogout }) => {
  useHelmetHandler({
    title: 'タイムアウト',
  });
  const isPc = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(BREAKPOINT)
  );
  const primaryWidth = isPc ? '184px' : '295px';
  const height = isPc ? '40px' : '40px';
  const borderRadius = isPc ? '20px' : '28px';
  const primarySx = { width: primaryWidth, height, borderRadius };
  return (
    <Box sx={styleContainer}>
      <Stack>
        <Typography
          variant="body-main/regular"
          sx={{ color: 'system.text-normal' }}
        >
          一定時間操作がなかったため、 自動的に ログアウトしました。
        </Typography>
        <Typography
          variant="body-main/regular"
          sx={{ color: 'system.text-normal' }}
        >
          再度ログインしてください。
        </Typography>
      </Stack>

      {isPc ? (
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <PrimaryButton sx={primarySx} onClick={handleClickLogout}>
            ログイン画面へ移動
          </PrimaryButton>
        </Box>
      ) : (
        <PrimaryButton sx={primarySx} onClick={handleClickLogout}>
          OK
        </PrimaryButton>
      )}
    </Box>
  );
};
