import { BREAKPOINT } from '@/theme/theme';
import { styled, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';

interface ButtonProps {
  children: ReactNode;
  sx?: SxProps;
}

const Wrapper = styled('div')(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    margin: 'auto',
    'button:first-of-type': {
      marginRight: '16px',
    },
    [breakpoints.down(BREAKPOINT)]: {
      display: 'block',
      width: '100%',
      padding: '0 16px',
      'button:first-of-type': {
        marginRight: '0',
        marginBottom: '16px',
      },
      button: {
        display: 'block',
        width: '100%',
      },
    },
  };
});

export const PrimaryAndSecondaryButtons: FC<ButtonProps> = ({
  children,
  sx,
}) => <Wrapper sx={sx}>{children}</Wrapper>;
