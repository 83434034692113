/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { PrimaryTableBodyTdMulti } from '@/components/atoms/Table/PrimaryTableBodyTdMulti';
import { TextWithFlowIcon } from '@/components/molecules/Flow/TextWithFlowIcon';
import { BasicTable } from '@/components/molecules/Table/BasicTable';
import { PrimaryTable } from '@/components/molecules/Table/PrimaryTable';
import { UserInfo } from '@/components/molecules/Table/UserInfo';
import { HomeHeader } from '@/components/organism/HomeHeader';
import { Box, styled, Typography, Stack } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { WarmingDeadLine } from '@/components/organism/Apply/WarmingDeadLine';
import { useHomeHooks } from '@/components/pages/Home/hooks/useHomeHooks';
import { BREAKPOINT, theme } from '@/theme/theme';
import { PrimaryAndSecondaryButtons } from '@/components/molecules/Buttons/PrimaryAndSecondaryButtons';
import { SecondaryButton } from '@/components/atoms/Buttons/SecondaryButton';
import { PrimaryButton } from '@/components/atoms/Buttons/PrimaryButton';
import { Path } from '@/constants/Router/path';
import { useNavigate, useLocation } from 'react-router-dom';
import { appClient } from '@/services';
import useSWR from 'swr';
import { Association } from '@/types/api/association';
import { ConvertAssociation } from '@/services/convert/association';
import { useSnackbarMessage } from '@/hooks/useSnackbarMessage';
import { ErrorResponse } from '@/types/api/error';
import { CreateApplication } from '@/types/api/applications';
import { useHelmetHandler } from '@/hooks/useHelmetHandler';
import { LocationState } from './WithdrawalInput';

const Wrraper = styled('div')(() => {
  const { breakpoints } = theme;
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    marginBottom: '80px',
    padding: '14px 0',
    [breakpoints.up(BREAKPOINT)]: {
      padding: '14px 40px',
    },
    [breakpoints.up('lg')]: {
      padding: '14px 130px',
    },
  };
});

const ContentWrraper = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
});

const Header = styled('div')({ position: 'relative', width: '100%' });

// パンクズ
const links = [
  {
    path: 'TOP',
    to: Path.HOME,
  },
  {
    path: '退会する',
    to: Path.WITHDRAWAL,
  },
];

const reason = {
  LEAVE_ABSENCE: '退職',
  FAMILY_MATTERS: '家庭の事情',
  ECONOMIC: '経済的理由',
  OTHER: 'その他',
};

const accountType = {
  SAVINGS: '普通',
  CURRENT: '当座',
  OTHER: '貯蓄',
};

export const WithdrawalConfirm: FC = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const state = location.state as LocationState;
  const { showMessage } = useSnackbarMessage();
  const { isListedClassification } = useHomeHooks();

  const [association, setAssociation] = useState<Association>();
  useHelmetHandler({
    title: '退会 入力内容確認',
  });

  // 【API-A-1005】持株会（事務局）振込先口座情報取得
  const { data: officeAssociationAccount } = useSWR(
    '/api/associations/{associationCd}/contributions',
    () => appClient.associations.findOfficeAssociationAccount()
  );

  const { data: findAssociationById } = useSWR(
    '/api/associations/{associationCd}',
    () => appClient.associations.findAssociationById()
  );

  useEffect(() => {
    setAssociation(ConvertAssociation(findAssociationById));
  }, [findAssociationById]);

  const goNext = async () => {
    try {
      const { branchCode, branchName, accountNumber, findSecuritiesAccount } =
        state;
      const body = {
        applicationDate: new Date().toString(),
        applicationType: 'WITHDRAWAL' as CreateApplication['applicationType'],
        applicationWithdrawal: {
          ...state.requestParams,
        },
      };

      if (
        !findSecuritiesAccount?.branchCd &&
        branchCode &&
        branchName &&
        accountNumber
      ) {
        await appClient.users.createSecuritiesAccount({
          branchCode: branchCode.toString(),
          branchName: state.branchName,
          accountNumber: accountNumber.toString(),
        });
      }
      const response = await appClient.applications.createApplication(body);

      if (response) {
        navigate(Path.WITHDRAWAL_COMPLETE, {
          state: {
            applicationType: response.applicationType,
            applicationId: response.applicationId,
            applicationDate: response.applicationDate,
          },
        });
      }
    } catch (e) {
      showMessage({
        open: true,
        messageType: 'error',
        messages: [(e as ErrorResponse).data.message],
      });
    }
  };

  const navigateToPrint = () => {
    navigate(`/print`, {
      state: {
        ...state,
        title: '退会する',
        contentTitle: '',
        type: 'WITHDRAWAL',
      },
    });
  };

  return (
    <Wrraper>
      <Header>
        <HomeHeader
          links={links}
          title="退会する"
          subTitle="入力内容確認"
          sx={{ width: '100%' }}
          printOnClick={() => navigateToPrint()}
        />
      </Header>
      <TextWithFlowIcon flowOn={1} widthFull />
      <ContentWrraper>
        <WarmingDeadLine
          eventsmemberdeadlines={state.eventsmemberdeadlines}
          target="WITHDRAWAL"
        />
        <UserInfo
          mochikabukaiCode={state.user.associationCd}
          mochikabukaiName={state.association.associationName}
          nameKanji={state.user.nameKanji}
          nameKana={state.user.nameKana}
          zipcode={state.user.zipcode}
          address1={state.user.address1}
          address2={state.user.address2}
          address3={state.user.address3}
          tel={state.user.tel}
          employeeCd={state.user.employeeCd}
          editable
        />
        {isListedClassification && (
          <BasicTable
            title="証券口座"
            type="securities"
            name="東海東京証券"
            departmentCode="******"
            departmentName="******"
            accountNumber="******"
            boxSx={{ padding: '0' }}
          />
        )}
        <PrimaryTable title="退会理由">
          <PrimaryTableBodyTdMulti title="退会理由" row={1} spWidth="130px">
            <Typography variant="body-main/regular" sx={{ display: 'block' }}>
              {reason[state.requestParams?.reason ?? 'LEAVE_ABSENCE']}
            </Typography>
            <Typography variant="body-main/regular" sx={{ dispaly: 'block' }}>
              {state.requestParams?.reason === 'OTHER' &&
                (state.requestParams?.otherReasons ?? '')}
            </Typography>
          </PrimaryTableBodyTdMulti>
        </PrimaryTable>
        {state.association.withdrawalLessThanSettlementUsageClassification ===
          '1' && (
          <PrimaryTable title="精算方法">
            <PrimaryTableBodyTdMulti
              title="売買単位"
              SecondaryTitle="未満株式持分"
              row={1}
              spWidth="130px"
            >
              <Typography variant="body-main/regular">
                {state.requestParams?.isAdditionalOption
                  ? '臨時拠出により買増し'
                  : '持株会に売却（単元株以上は証券口座に移管）'}
              </Typography>
              {state.requestParams?.isAdditionalOption && (
                <>
                  <Box sx={{ color: 'system.text-light' }}>
                    <Box>
                      <Typography
                        variant="body-sub/regular"
                        sx={{ marginRight: '8px' }}
                      >
                        買い増し株数
                      </Typography>
                      <Typography variant="body-sub/bold">
                        {`${(
                          100 -
                          (state.userAsset.stocksNumber % 100)
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: association?.investmentDigit,
                        })}株`}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="body-sub/regular"
                        sx={{ marginRight: '8px' }}
                      >
                        拠出金額
                      </Typography>
                      <Typography variant="body-sub/bold">
                        {`${(
                          state.associationContribution.unitAmount *
                          (100 - (state.userAsset.stocksNumber % 100))
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: association?.investmentDigit,
                        })}円`}
                      </Typography>
                    </Box>
                  </Box>
                  {officeAssociationAccount && (
                    <Box sx={{ color: 'system.text-light' }}>
                      <Typography
                        variant="body-sub/regular"
                        sx={{ marginRight: '8px' }}
                      >
                        振込口座
                      </Typography>
                      <Typography variant="body-sub/bold">
                        {officeAssociationAccount.financialInstitutionName}
                        {officeAssociationAccount.bankBranchName}
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </PrimaryTableBodyTdMulti>
          </PrimaryTable>
        )}
        <PrimaryTable title="精算金振込口座(会員名義口座)">
          <PrimaryTableBodyTdMulti
            title="金融機関の種類"
            row={1}
            spWidth="130px"
          >
            <Typography variant="body-main/regular">
              {state.requestParams?.institutionType === 'OTHER'
                ? 'ゆうちょ銀行以外'
                : 'ゆうちょ銀行'}
            </Typography>
          </PrimaryTableBodyTdMulti>
          {state.requestParams?.institutionType === 'OTHER' && (
            <>
              <PrimaryTableBodyTdMulti
                title="金融機関コード"
                row={1}
                spWidth="130px"
              >
                <Typography variant="body-main/regular">
                  {state.requestParams?.generalAccount?.institutionCode ?? ''}
                </Typography>
              </PrimaryTableBodyTdMulti>
              <PrimaryTableBodyTdMulti
                title="金融機関名"
                row={1}
                spWidth="130px"
              >
                <Typography variant="body-main/regular">
                  {state.requestParams?.generalAccount?.institutionName ?? ''}
                </Typography>
              </PrimaryTableBodyTdMulti>
              <PrimaryTableBodyTdMulti
                title="支店コード"
                row={1}
                spWidth="130px"
              >
                <Typography variant="body-main/regular">
                  {state.requestParams.generalAccount?.bankBranchCode ?? ''}
                </Typography>
              </PrimaryTableBodyTdMulti>
              <PrimaryTableBodyTdMulti title="支店名" row={1} spWidth="130px">
                <Typography variant="body-main/regular">
                  {state.requestParams.generalAccount?.bankBranchName ?? ''}
                </Typography>
              </PrimaryTableBodyTdMulti>
              <PrimaryTableBodyTdMulti title="預金種別" row={1} spWidth="130px">
                <Typography variant="body-main/regular">
                  {
                    accountType[
                      state.requestParams.generalAccount?.accountType ??
                        'SAVINGS'
                    ]
                  }
                </Typography>
              </PrimaryTableBodyTdMulti>
              <PrimaryTableBodyTdMulti title="口座番号" row={1} spWidth="130px">
                <Typography variant="body-main/regular">
                  {state.requestParams.generalAccount?.accountNumber ?? ''}
                </Typography>
              </PrimaryTableBodyTdMulti>
            </>
          )}
          {state.requestParams?.institutionType === 'YUCHO' && (
            <PrimaryTableBodyTdMulti title="店番・番号" row={1} spWidth="130px">
              <Typography variant="body-main/regular">
                {state.requestParams.accountYuchoSymbol} -{' '}
                {state.requestParams.accountYuchoNumber}
              </Typography>
            </PrimaryTableBodyTdMulti>
          )}
        </PrimaryTable>
        <Stack
          padding="8px 16px"
          border="1px solid"
          borderColor={theme.palette.states.error}
          borderRadius="8px"
          color={theme.palette.states.error}
          sx={{
            [theme.breakpoints.down(BREAKPOINT)]: {
              margin: '0 16px',
            },
          }}
        >
          <Typography variant="body-main/bold">売却時の注意事項 </Typography>
          <Typography variant="body-main/regular">
            会社の株価に重大な影響を与える「重要事実」を知りながら、その重要事実が「公表される前」に、会社の株式の売買等を行うことはできません。売却にあたりましては、必ず事前に会社に許可を得てください。
          </Typography>
        </Stack>
        <PrimaryAndSecondaryButtons>
          <SecondaryButton
            sx={{
              display: 'flex',
              margin: 'auto',
              borderRadius: '28px',
              padding: '16px 24px',
            }}
            onClick={() => navigate(Path.WITHDRAWAL_INPUT, { state })}
          >
            入力へ戻る
          </SecondaryButton>
          <PrimaryButton
            sx={{
              borderRadius: '28px',
              padding: '16px 24px',
            }}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={() => goNext()}
          >
            申請する
          </PrimaryButton>
        </PrimaryAndSecondaryButtons>
      </ContentWrraper>
    </Wrraper>
  );
};
